<template>
  <div :class="'select-field '+(customClass ? customClass+' ': '')+(selectOpen ? 'open': '')+(theme ? ' theme-'+theme : '')+(size ? ' size-'+size : '')" v-on:click="openFilter" v-click-outside="hideFilter">
    <input v-if="name" type="hidden" :name="name" v-model="selectedOption.value"/>
    <span class="selected-option flex my-flex-row flex-s1">{{selectedOption.name}}</span>
    <div class="selections">
      <span class="item flex my-flex-row flex-s1" :class="item.value == selectedOption.value ? 'selected' : '' " v-for="(item, index) in options" :key="'select-item-'+index" v-on:click="selectFilter(item.value, item.name)"><span>{{item.name}}</span></span>
    </div>
    <span class="icon">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5 9.6006L12.374 17.1756C12.3271 17.2285 12.2695 17.2708 12.205 17.2998C12.1406 17.3288 12.0707 17.3437 12 17.3437C11.9293 17.3437 11.8594 17.3288 11.795 17.2998C11.7305 17.2708 11.6729 17.2285 11.626 17.1756L5.5 9.6006" stroke="#1F4743" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
    <span class="error-text" v-if="visibleError && errorText" >{{errorText}}</span>
  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';




export default {
  props: ['options', 'theme', 'size', 'name', 'customClass', 'value'],
  data() {
    return {
      visibleError: false,
      errorText: null,
      selectOpen: false,
      skipOpen: false,
      selectedOption: {"name": null, "value": null}
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  mounted(){
    
    this.init();
  },
  created(){
    this.$watch(
      () => this.value,
      (toParams, previousParams) => {
        this.selectedOption = this.getOptionByValue(toParams);
      }
    );
    this.$watch(
      () => this.options,
      (toParams, previousParams) => {
        if (!this.selectedOption.name){
          this.init();
        }else{
          this.safeCheckSelectedOption();
        }

      }
    );
  },
  methods: {
    safeCheckSelectedOption(){
      var ok = false;
      for (var i=0; i<this.options.length; i++){
        if (this.options[i].value == this.selectedOption.value){
          var ok = true;
          break;
        }
      }
      if(!ok){
        this.selectedOption = this.options[0];
        this.$emit('selected', this.selectedOption.value);
        this.visibleError = false;
      }
    },
    getOptionByValue: function(val){
      if (this.options){
        for (var i=0; i<this.options.length; i++){
          if (this.options[i].value == val){
            return this.options[i];
          }
        }
        return this.options[0];
      }
      return null;
    },
    init: function(){
      if (this.options && this.options.length>0){
        this.selectedOption = this.options[0];
      }
      if (this.value){
        this.selectedOption = this.getOptionByValue(this.value);
      }
    },
    openFilter: function(){
      if (!this.selectOpen && !this.skipOpen){
        this.selectOpen = !this.selectOpen;
      }else{
        if (this.skipOpen){
          this.skipOpen = false;
        }
      }
    },
    hideFilter: function(){
      if (this.selectOpen){
        this.selectOpen = false;
      }
    },
    selectFilter: function(val, name){
      // alert(this.selectedOption.value+" - "+val);
      if (this.selectedOption.value != val){
        this.selectedOption = {"name": name, "value": val};
        this.$emit('selected', val);
        this.visibleError = false;
      }
      this.skipOpen = true;
      this.hideFilter();
    },
    showError: function(text){
      if (text){
        this.errorText = text;
      }
      this.visibleError = true;
    }
  },
 
}
</script>
<style lang="scss">
@import "~@/styles/variables.scss";
.select-field{
  position: relative;
  width: 100%;
  border: 1px solid #C7E5E4;
  box-sizing: border-box;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
  height: 44px;
  text-align: left;

  &.size-big{
    height: 56px;
    .selected-option{
      height: 56px;
      // padding-top: 16px;
      // padding-bottom: 20px;
      padding-top: 0px;
      padding-bottom: 4px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 16px;
      line-height: 20px;


    }
    .selections{
      .item{
        font-size: 16px;
        line-height: 20px;
        padding-left: 20px;
        padding-right: 20px;

        height: 56px;
        padding-top: 16px;
        padding-bottom: 20px;

        padding-top: 0px;
        padding-bottom: 4px;

        &:first-child{
          height: 56px;
          padding-top: 16px;
          padding-bottom: 20px;
        }
      }
    }
  }

  &.theme-nr1{
    border: 1px solid #1F4743;
    .selections{
      //BORDER
      border: 1px solid #000;
      box-sizing: border-box;
      margin-left: -1px;
      margin-right: -1px;
      margin-top: -1px;
      width: calc(100% + 2px);
    }
  }

  .icon{
    position: absolute;
    top: 50%;
    right: 16px;
    margin-top: -12px;
    transition: all 300ms;
    -webkit-transition: all 300ms;
  }
  .selected-option{
      box-sizing: border-box;
      border-radius: 10px;
      padding: 12px;
      padding-left: 12px;
      padding-right: 12px;
      // padding-top: 10px;
      // padding-bottom: 14px;
      padding-top: 0px;
      padding-bottom: 4px;
      // display:block;
      display:flex;
      cursor: pointer;
      height: 44px;
      margin-left: 0px;
      margin-right: 0px;
      border-radius: 6px;

      font-size: 14px;
      line-height: 18px;

      font-weight: 400;
      
      color: #1F4743;
  }
  .selections{
    z-index: 10;
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    border-radius: 10px;
    overflow: hidden;
    height: 100%;

    max-height: 400px;
    overflow-y: scroll;

    display: none;
    .item{
      box-sizing: border-box;
      border-radius: 10px;
      padding: 12px;
      padding-top: 10px;
      padding-bottom: 14px;
      padding-left: 12px;
      padding-right: 12px;
      // display:block;
      display: flex;
      cursor: pointer;
      height: 44px;
      margin-left: 0px;
      margin-right: 0px;
      border-radius: 6px;

      font-size: 14px;
      line-height: 18px;

      font-weight: 400;
      
      color: #1F4743;

      &.selected{
        background: rgba(105, 189, 182, 0.09);
      }
      // &:first-child{
      //   background: transparent;
      //   height: 44px;
      //   padding-top: 10px;
      //   padding-bottom: 14px;
      // }
    }
  }

  &.open{
    border: 1px solid transparent;
    
    .icon{
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);

    }
    .selections{
      display: block;
      background: #FFFFFF;
      box-shadow: 0px 20px 40px -20px rgba(31, 72, 68, 0.24);
      padding-bottom: 8px;
      height: auto;
      .item{
        &:hover{
          background: rgba(105, 189, 182, 0.09);  
        }
        // &:first-child{
        //   &:hover{
        //     background: transparent;
        //   }
        // }
      }
    }
  }
  .error-text{
    font-size: 12px;
    padding-left: 4px;
    display: block;
    text-align: left;
    color: #DF4343;
    margin-top: 4px;
  }
}
</style>