<template>
  <div :class="'privat-erhverv-select-block bg-'+bg ">
    <div class="flex flex-s9">
      <div class="side-left flex flex-column flex-s9">
        <h2 class="title">{{title}}</h2>
        <div class="buttons">
          <router-link :class="'link-button-item b-'+index" :data-index="index"  @mouseover.native="itemMouseOver(index, $event)" @mouseleave.native="itemMouseLeave(index, $event)" :to="button.link.url + (childURL ? childURL : '') " v-for="(button, index) in buttons" :key="'link-'+button.link.title" >
            <span :data-index="index" >
              <span class="label">
                {{button.link.title}}
              </span><span class="icon">
                <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.4766 1L30.4766 7L24.4766 13" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M30 7H2" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
            </span>
          </router-link>  
        </div>
      </div>
      <div class="side-right">
        <div class="buttons">
          <router-link :class="'b-'+index" :data-index="index"  @mouseover.native="itemMouseOver(index, $event)" @mouseleave.native="itemMouseLeave(index, $event)"  :to="button.link.url + (childURL ? childURL : '') " v-for="(button, index) in buttons" :key="'img-'+button.link.title" >
            <img :data-index="index"  :src="button.image.sizes.large" :alt="button.image.info ? button.image.info.alt : ''" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';

export default {
  props: ['title', 'buttons', 'childURL', 'bg'],
  data() {
    return {
      
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  mounted(){
    
   
  },
  methods: {
    itemMouseOver: function(index,e){
      // console.log(e);
      // console.log(this);
      // var index = e.target.getAttribute("data-index");
      

      var buttons = document.querySelectorAll(".privat-erhverv-select-block .buttons a");
      for(var i=0; i<buttons.length; i++){
        var item = buttons[i];
        if (item.classList.contains("b-"+index)){
          item.style.zIndex = 2;
          item.classList.add("selected");
        }else{
          item.style.zIndex = 1;
          item.classList.add("hidden");
          item.classList.remove("selected");
        }
      }
      // var selected_buttons = document.querySelectorAll(".privat-erhverv-select-block .buttons a.b-"+index);
      
      // for(var i=0; i<selected_buttons.length; i++){
      //   var item = selected_buttons[i];
      //   item.classList.add("selected");
      // }
      
    },
    itemMouseLeave: function(index, e){
      // console.log(e.fromElement);
      // console.log(e.target);
      // var index = e.target.getAttribute("data-index");
      // alert(index);
      var buttons = document.querySelectorAll(".privat-erhverv-select-block .buttons a");
      for(var i=0; i<buttons.length; i++){
        var item = buttons[i];
        item.classList.remove("selected");
        item.classList.remove("hidden");
      }
      // var selected_buttons = document.querySelectorAll(".privat-erhverv-select-block .buttons a.b-"+index);
      // for(var i=0; i<selected_buttons.length; i++){
      //   var item = selected_buttons[i];
      //   item.classList.remove("selected");
      // }
    }
  },
  created() {
   
   
  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.privat-erhverv-select-block{
  // height:456px;
  padding: 20px;
  box-sizing: border-box;
  
  border-radius: 40px;
  &.bg-light-green{
    background: $color-super-light-green;
  }  
  .flex{
    height: 100%;
  }
}

.title{
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  color: #1F4743;
  margin-bottom: 20px;
}
.side-left{
  width: 50%;
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 110px;
  padding-top: 60px;
  padding-bottom: 60px;

  .buttons{
    a{
      opacity: 1;
      span{
        opacity: 1;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }

      &.selected{
        .icon{
          transform: translateX(4px);
          -webkit-transform: translateX(4px);
        }
      }
      &.hidden{
        span{
          opacity: 0.6;
        }
      }
    }
  }
}
.side-right{
  width: 50%;
  padding-left: 10px;
  box-sizing: border-box;
  .buttons{
    position: relative;
    height: 100%;
    a{
      position: absolute;
      width: calc(50% - 10px);
      height: 100%;
      top: 0px;
      display: inline-block;
      line-height: 0px;
      border-radius: 20px;
      overflow: hidden;

      opacity: 1;
      -webkit-transition: width 0.3s ease, opacity 0.3s ease;
      -moz-transition: width 0.3s ease, opacity 0.3s ease;
      -o-transition: width 0.3s ease, opacity 0.3s ease;
      -ms-transition: width 0.3s ease, opacity 0.3s ease;
      transition: width 0.3s ease, opacity 0.3s ease;

      &.selected{
        width: 100%;
      }
      &.hidden{
        width: 0%;
        opacity: 0;
      }

      &:first-child{
        left: 0px;
      }
      &:last-child{
        right: 0px;
      }
      img{
        border-radius: 20px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }
  }
}

@media (max-width: 1000px) {
  .side-right{
    display: none;
  }
  .side-left{
    width: 100%;
    padding: 32px;
  }
}
@media (max-width: #{$screen-tablet}) {
  
  .privat-erhverv-select-block{
    border-radius: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .side-left{
    width: 100%;
    padding: 0px;
    max-width: 424px;
    margin-left: auto;
    margin-right: auto;
    .title{
      font-weight: 700;
      font-size: 32px;
      line-height: 130%;
      text-align: center;
    }
  }
  
}

@media (max-width: #{$screen-mobile}) {
  
  .privat-erhverv-select-block{
    border-radius: 20px;
    padding-top: 28px;
    padding-bottom: 20px;
  }
  .side-left{
    width: 100%;
    padding: 0px;
    .title{
      font-weight: 700;
      font-size: 28px;
      line-height: 130%;
      margin-bottom: 16px;
    }
  }
  
}

</style>