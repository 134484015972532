var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gallery-section noselect"},[(_vm.images.length<=1)?_c('div',{staticClass:"single-image"},[_c('div',{staticClass:"big-image"},_vm._l((_vm.images),function(image,index){return _c('div',{key:'car-gallery-big-image-'+index},[_c('img',{attrs:{"src":image,"alt":"image"}})])}),0)]):_c('div',{staticClass:"gallery"},[_c('div',{staticClass:"big-image"},[_c('swiper',{ref:"carGallerySlider",attrs:{"slides-per-view":_vm.slidesPerView,"space-between":_vm.slidesSpacing,"loop":false,"slideVisibleClass":"visible-slide","watchSlidesVisibility":true,"navigation":{
            prevEl: '.prev',
            nextEl: '.next',
          },"pagination":{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          }},on:{"swiper":_vm.onSwiper,"slideChange":_vm.onSlideChange}},[_vm._l((_vm.images),function(image,index){return _c('swiper-slide',{key:'car-gallery-big-image-'+index,staticClass:"slide",class:{test_2: false}},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":image,"alt":"image"}})])])}),_c('div',{staticClass:"prev"}),_c('div',{staticClass:"next"}),_c('div',{staticClass:"swiper-pagination"}),_c('div',{staticClass:"mask mask-right"}),_c('div',{staticClass:"mask mask-left"})],2)],1),_c('div',{staticClass:"thumbnails-wrap flex my-flex-row flex-s4"},[_c('span',{staticClass:"prev-button",on:{"click":function($event){return _vm.prevClick($event)}}},[_c('svg',{attrs:{"width":"44","height":"44","viewBox":"0 0 44 44","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M24.3994 28.5L16.8244 22.374C16.7715 22.3271 16.7292 22.2695 16.7002 22.205C16.6712 22.1406 16.6563 22.0707 16.6563 22C16.6563 21.9293 16.6712 21.8594 16.7002 21.795C16.7292 21.7305 16.7715 21.6729 16.8244 21.626L24.3994 15.5","stroke":"#69BDB6","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('div',{ref:"thumbnails",staticClass:"thumbnails"},[_c('div',{ref:"thumbnailsInner",staticClass:"inner",style:('transform: translateX('+_vm.thumbnailSlidePX+'px); '+
            '-webkit-transform: translateX('+_vm.thumbnailSlidePX+'px); ')},_vm._l((_vm.images),function(image,index){return _c('div',{key:'car-gallery-image-'+index,class:'thumbnail' + (_vm.currentPage == index ? ' active' : ''),on:{"click":function($event){return _vm.thumbnailClick(index, $event)}}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":image,"alt":"image"}})])])}),0)]),_c('span',{staticClass:"next-button",on:{"click":function($event){return _vm.nextClick($event)}}},[_c('svg',{attrs:{"width":"44","height":"44","viewBox":"0 0 44 44","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M19.6006 15.5L27.1756 21.626C27.2285 21.6729 27.2708 21.7305 27.2998 21.795C27.3288 21.8594 27.3438 21.9293 27.3438 22C27.3438 22.0707 27.3288 22.1406 27.2998 22.205C27.2708 22.2695 27.2285 22.3271 27.1756 22.374L19.6006 28.5","stroke":"#69BDB6","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }