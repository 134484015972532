<template>
  <div class="block car-list-section fade-on-enter">
    <div class="wrap">
      <div class="car-list flex my-flex-row my-flex-wrap flex-s8">
        <CarItem :car="car" :index="index" @toggleLikeCar="toggleLikeCar" v-for="(car, index) in carlist" :key="'car-'+car.id" />
      </div>
      <div class="more-button-wrap">
        <a v-if="allCarsURL.indexOf('http') === 0" class="button see-more-cars" :href="allCarsURL">{{buttonLabel}}</a>
        <router-link v-else class="button see-more-cars" :to="allCarsURL" >{{buttonLabel}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';

import AbAxios from "../../../components/leasing/Showroom/src/common/ab_axios_leasing";


export default {
  props: ['cmsGlobalData', 'cmsPageData', 'leasingType', 'allCarsURL', 'carCount'],
  data() {
    return {
      axios: null,
      loading: true,
      data: null,
      carlist: [],
      buttonLabel: "Se alle"
    }
  },
  async created(){
    if (this.axios == null){
      this.axios = new AbAxios();
    }

    let count = 8;
    if (this.carCount){
      count = this.carCount;
    }

    let finalUrls = [];
    if (this.leasingType === "erhverv") {
      finalUrls.push("leasing/Corporate/vehicles?size=" + count);
    } else {
      finalUrls.push("leasing/Consumer/vehicles?size=" + count);
      finalUrls.push("leasing/Consumer/vehicles?used=true&size=" + count);
    }

    const th = this;
    for(const key in finalUrls) {
      await this.axios.abGet(finalUrls[key], true, function successCallback(response) {
        const cars = response.data.data;
        if (cars) {
          th.carlist = th.carlist.concat(cars);
        }
      }, function errorCallback(error) {
        console.log(error);
      });
    }

    if (this.leasingType === "privat") {
      //randomize cars
      this.carlist = this.carlist.reduce((acc, el) => {
        Math.random() > 0.5 ? acc.push(el) : acc.unshift(el);
        return acc;
      }, []);

      //get number of cars
      this.carlist = this.carlist.slice(0, count);
    }

    th.loading = false;
  },
  methods: {
    toggleLikeCar: function(id){
    },
  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.car-list{
  position: relative;
  margin-left: -10px;
  margin-right: -10px;
  width: calc(100% + 20px);
}
.car-list-section{
  .more-button-wrap{
    margin-top: 60px;
    text-align: center;
  }
  margin-bottom: 80px;
}
@media (max-width: 1000px) {
  .car-list{
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
    .car-item{
      width: calc(33.33% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
    }
  }
}
@media (max-width: #{$screen-tablet}) {
  .car-list{
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
    .car-item{
      width: calc(50% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
    }
  }
}


@media (max-width: #{$screen-mobile}) {
  .car-list{
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    .car-item{
      width: 100%;
      margin: 0px;
      margin-bottom: 20px;
    }
  }
}
</style>