<template>
  <section class="section long-scroll-section">
    <div class="side">
      <div class="wrap">
        <div class="side-inner">
          <h2 class="section-title">{{title}}</h2>
          <div class="slides">
            <div :class="'item transition-all '+(index==activeSlideIndex ? 'active' : '')" v-for="(item, index) in slides" :data-index="index" :key="'long-slide-'+index" >
              <img class="image" :src="item.image.sizes.large" :alt="item.image.info ? item.image.info.alt : ''" />
              <p class="title">{{item.title}}</p>
              <div class="content" v-html="item.content"></div>
              <div class="links" v-if="item.links && item.links.length>0">
                <div v-for="(link, index2) in item.links" :key="'long-slide-'+index+'-link-'+index2" >
                  <a :href="link.link.url" :target="link.link.target"  >{{link.link.title}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="image-side">
      <div class="image-wrap flex my-flex-row flex-s1">
        <div class="image">
          <img v-if="selectedImageURL" class="selected-image" :src="selectedImageURL" :alt="selectedImageALT" @load="onSelectedImgLoad"/>
          <img v-if="lastImageURL" class="last-image" :src="lastImageURL" :alt="selectedImageALT"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export default {
  props: ['title', 'slides'],
  data() {
    return {
      selectedImageURL: null,
      selectedImageALT: null,
      lastImageURL: null,
      activeSlideIndex: 0,
      allScrollTrigers: Array(),
      timeout1: null,
      timeout2: null
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  beforeDestroy(){
    if (this.timeout1) clearTimeout(this.timeout1);
    if (this.timeout1) clearTimeout(this.timeout2);

    window.removeEventListener("resize", this.onResize);

    for(var i=0; i<this.allScrollTrigers.length; i++){
      this.allScrollTrigers[i].kill();
    }
  },
  
  mounted(){
    var th = this;
    this.scrollAnimation();
    this.imageChangeAnimation();

    this.$nextTick(() => {
      this.onResize();
    });

    this.timeout1 = setTimeout(function(){
      th.onResize();
      clearTimeout(th.timeout1);
    },1000);
    this.timeout2 = setTimeout(function(){
      th.onResize();
      clearTimeout(th.timeout2);
    },4000);

    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize(){
      var image = document.querySelector(".long-scroll-section .image-side .image");
      var vh = window.innerHeight;
      document.documentElement.style.setProperty('--long-slider-image-margins', (vh-image.clientHeight)/2 + "px");
      document.documentElement.style.setProperty('--long-slider-image-margins-neg', (-vh+image.clientHeight)/2 + "px");
      document.documentElement.style.setProperty('--long-slider-side-paddings-top', (image.clientHeight)/2 + "px");
      document.documentElement.style.setProperty('--long-slider-side-paddings-bottom', (image.clientHeight)/2 + "px");


      for(var i=0; i<this.allScrollTrigers.length; i++){
        this.allScrollTrigers[i].refresh();
      }

    },
    onSelectedImgLoad() {
      // alert("a");
      var th = this;

      gsap.to('.selected-image', { duration: 0, opacity: 0, onComplete: function(){}});
      gsap.to('.selected-image', { duration: 0.3, opacity: 1, onComplete: function(){}});

      th.onResize();
      // setTimeout(function(){
      //   var a = th.selectedImageURL;
      //   th.selectedImageURL = "";
      //   th.selectedImageURL = a+"?a";
      // },2000);
    },
    setActiveImage(img, alt){
      this.lastImageURL = this.selectedImageURL+"";
      gsap.to('.selected-image', { duration: 0, opacity: 0, onComplete: function(){}});
      gsap.to('.last-image', { duration: 0, opacity: 1, onComplete: function(){}});
      this.selectedImageURL = img;
      this.selectedImageALT = alt;
      
    },
    setSelectedImages() {
      if (this.slides[0]){
        this.selectedImageURL = this.slides[0].image.sizes.large;
      }
      this.lastImageURL = "";
    },
    scrollAnimation() {
      var st = ScrollTrigger.create({
        // markers: {startColor: "green", endColor: "red", fontSize: "12px"},
        trigger: '.long-scroll-section .image-side',

        scrub: false,
        start: '0px 0px',
        end: '100% 100%',
        // start: function(){
        //     return "-="+(window.innerHeight*startPercent)+"";
        // },
        // end: function(){
        //     return "+="+(window.innerHeight*endPercent)+"";
        // },
        
        // onToggle: self => {
        //     if (self.isActive){
        //       if (self.direction == -1){
        //         document.querySelector(".long-scroll-section .image-side").classList.add("fixed").remove("bottom");
        //       }
        //       if (self.direction == 1){
        //         document.querySelector(".long-scroll-section .image-side").classList.add("fixed").remove("bottom");
        //       } 
        //     }else{

        //     }
        // },
        onEnter: self =>{
          var elem = document.querySelector(".long-scroll-section .image-side");
          elem.classList.add("fixed");
          elem.classList.remove("bottom");
        },
        onEnterBack: self =>{
          var elem = document.querySelector(".long-scroll-section .image-side");
          elem.classList.add("fixed");
          elem.classList.remove("bottom");
        },
        onLeave: self =>{
          var elem = document.querySelector(".long-scroll-section .image-side");
          elem.classList.remove("fixed");
          elem.classList.add("bottom");
        },
        onLeaveBack: self =>{
          var elem = document.querySelector(".long-scroll-section .image-side");
          elem.classList.remove("fixed");
          elem.classList.remove("bottom");
        },
        onUpdate: self => {
            // if (self.progress*1 == 1 && self.direction==1){
            //     th.setState({
            //         leftWrapClassList: "bottom",
            //         leftWrapStyle: {width:leftComponent.clientWidth}
            //     });
            // }
            // if (self.direction)
            // console.log(self.progress);
            // console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
        }
      });
      this.allScrollTrigers.push(st);
    },

    imageChangeAnimation() {
      var th = this;
      var items = document.querySelectorAll(".long-scroll-section .slides .item");
      
      for (var i=0; i<items.length; i++){
        var st = ScrollTrigger.create({
          // markers: {startColor: "green", endColor: "red", fontSize: "12px"},
          trigger: items[i],

          scrub: false,
          start: '0% 50%',
          end: '100% 50%',
         
          onEnter: self =>{
            th.setActiveImage(
              self.trigger.querySelector(".image").getAttribute("src"),
              self.trigger.querySelector(".image").getAttribute("alt")
            );
            this.activeSlideIndex = self.trigger.getAttribute("data-index");
            

          },
          onEnterBack: self =>{
            th.setActiveImage(
              self.trigger.querySelector(".image").getAttribute("src"),
              self.trigger.querySelector(".image").getAttribute("alt")
            );
            this.activeSlideIndex = self.trigger.getAttribute("data-index");
          },
          
        });
        this.allScrollTrigers.push(st);
      }
      


    }
  },
  created() {
    var th = this;
    this.$watch(
      () => this.slides,
      (toParams, previousParams) => {
        this.setSelectedImages();
      }
    );
    this.setSelectedImages();

   
  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";



.long-scroll-section{
  position: relative;
  margin-top: 120px;
  margin-bottom: 120px;

  .side{
    width: 100%;
    // padding-bottom: 50vh;
    padding-top: 48px;
    // padding-top: var(--long-slider-side-paddings-top);
    // padding-top: 120px;
    padding-bottom: var(--long-slider-side-paddings-bottom);
    .side-inner{
      width: 50%;
      padding-right: 122px;
      box-sizing: border-box;
    }

    .section-title{
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
      color: #1F4743;
      margin-bottom: 80px;

      // margin-bottom: 500px;
    }

    .slides{
      .item{
        opacity: 0.3;
        margin-bottom: 120px;
        &:last-child{
          margin-bottom: 0px;
        }
        &.active{
          opacity: 1;
        }
        // margin-bottom: 500px;
        .title{
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 130%;
          color: #1F4743;
          margin-bottom: 18px;
        }
        .content{
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          color: #1F4743;
        }
        .links{
          margin-top: 28px;
          a{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 135%;
            text-decoration-line: underline;
            color: #69BDB6;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 4px;
          }
        }
        .image{
          display:none;
          border-radius: 20px;
          overflow: hidden;
          width: 100%;
          margin-bottom: 24px;
          img{
            width: 100%;
          }
        }

        
      }
    }
  }


  .image-side{
    position: absolute;
    right: 0px;
    top: 0px;
    width: 50%;
    height: 100%;
    bottom: 0px;
    z-index: -10;
    // background: #FFF;
    top: 0px;
    box-sizing: content-box;
    padding-top: var(--long-slider-image-margins);
    padding-bottom: var(--long-slider-image-margins);
    margin-top: var(--long-slider-image-margins-neg);

    .image-wrap{
      position: absolute;
      // top: 12.5vh;
      top: 0;
      // height: 75vh;
      // max-height: 768px;
      height: 100vh;
      width: 100%;
      // background: #AAA;
      .image{
        max-width: 590px;
        max-height: 768px;
        width: 100%;
        margin-left: 10px;
        margin-right: 32px;
        // background: #FAA;
        // height: 100%;
        height: 75vh;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        
        img{
          border-radius: 20px;
          height: 100%;
          width: 100%;
          object-fit: cover;
          position: absolute;
          top: 0px;
          left: 0px;
          bottom: 0px;
          right: 0px;

          &.selected-image{
            z-index: 10;
          }
        }
      }
    }
    &.fixed{
      .image-wrap{
        position: fixed;
        right: 0px;
        height: 100vh;
        width: 50%;
      }
    }
    &.bottom{
      .image-wrap{
        position: absolute;
        bottom: 0px;
        top: auto;
      }
    }
  }
}

@media (max-width: 1000px) {
  .long-scroll-section{
    margin-top: 64px;
    margin-bottom: 64px;
    
    .side{
      .side-inner{
        width:100%;
        padding-right: 0px;
      }
      .slides{
        .item{
          opacity: 1;
          .image{
            display: block;
          }

          
        }
      }
    }
    .image-side{
      display: none;
    }
  }
}


</style>