import { Component, Vue } from 'vue-property-decorator';
import template from './cookie-disclaimer.vue';

@Component({
    mixins: [template],
})
export default class ComponentCookieDisclaimer extends Vue {

    // get GetCurrentRouteName() {
    //     return this.$route.name;
    // }
}