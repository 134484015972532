import { render, staticRenderFns } from "./App.vue?vue&type=template&id=61b9c885&scoped=true"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"
import style0 from "./style.scss?vue&type=style&index=0&id=61b9c885&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61b9c885",
  null
  
)

export default component.exports