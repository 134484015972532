<template>
    <div ref="modal" id="myModal" class="dialog modal" @click="closeOnModalClick()">
        <div class="modal-content text-center">
            <div class="card">
                <div class="card-header">
                    <h1>{{ requestName }}</h1>
                </div>

                <div v-if="responseRecieved">
                    <p v-if="emailSent">Vi har modtaget din forespørgsel og vender tilbage hurtigst muligt.</p>
                    <p v-else>Der skete desværre en fejl.<br>Prøv igen eller kontakt os.</p>
                </div>

                <div id="cardBodyModal" class="card-body">
                    <div v-if="spinnerShown">
                        <p class="spinnerdesc">Sender formular data</p>
                        <div class="spinner">
                            <div class="double-bounce1"></div>
                            <div class="double-bounce2"></div>
                        </div>
                    </div>

                    <div v-if="!requested && request == this.requestIds[0]">
                        <div class="row">
                            <div class="col fields">
                                <p>Leasingaftale nummer</p>
                                <input id="BestillingsarkDebitorskifteLeasingNumber" v-model="BestillingsarkDebitorskifte.LeasingNumber">

                                <p>Dato for debitorskifte</p>
                                <p class="fielddesc fielddeschighlighted">(standard vil være løbende måned + 1 måned)</p>

                                <ejs-datepicker
                                      class="datepicker"
                                      :placeholder="datePlaceholder"
                                      :firstDayOfWeek="dateFirstDay"
                                      :showClearButton="false"
                                      :allowEdit="false"
                                      :format="dateFormat"
                                      :renderDayCell="disableDateNotFirst"
                                      :showTodayButton="false"
                                      locale='da'
                                      v-model="BestillingsarkDebitorskifte.Date"
                                      cssClass="custom-datepicker">
                                </ejs-datepicker>

                                <p>CVR.nr. på udtrædende debitor</p>
                                <input v-model="BestillingsarkDebitorskifte.ExitingDebitor">

                                <p class="fieldsubheader">Indtrædende debitor</p>
                                <p>Navn</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorName">
                                <p>CVR</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorCVR">
                                <p>Mail</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorMail">
                                <p>Reg. nr. og konto nr. til BS</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorAccount">
                                <p>Tlf. nr.</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorPhone">
                                <p>Hvem er underskriver (jf. tegningsregler)</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorSigner">
                                <p>Mail på underskriver(e)</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorMails">
                                <p>Forsikringsselskab</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorInsurance">
                                <p>Kautionist</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorSurety">
                                <p>Navn</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorSuretyName">
                                <p>CVR</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorSuretyCvr">
                                <p>E-mail</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorSuretyEmail">
                                <p>Tlf. nr.</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorSuretyPhone">
                                <p>Adresse</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorSuretyAddress">
                                <p>Postnummer</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorSuretyZip">

                                <p>Gebyr (gebyret tilfalder banken og kan ændres)</p>
                                <input v-model="BestillingsarkDebitorskifte.DebitorFee">
                                <p>Gebyr for omregistrering i DMR (gælder kun ved indregistreret køretøjer)</p>
                                <input v-model="BestillingsarkDebitorskifte.ReRegistrationFee">

                                <p>Allonge sendes til</p>
                                <select v-model="BestillingsarkDebitorskifte.Allonge">
                                    <option value="Kunde" selected="selected">Kunde</option>
                                    <option value="Rådgiver">Rådgiver</option>
                                </select>

                                <p>Særlige bemærkninger</p>
                                <textarea v-model="BestillingsarkDebitorskifte.Remarks"></textarea>
                            </div>
                        </div>
                    </div>

                    <div v-if="!requested && request == this.requestIds[1]">
                        <div class="row">
                            <div class="col fields">
                                <p>Leasingaftale nummer</p>
                                <input id="BestillingsarkIndfrielserLeasingNumber" v-model="BestillingsarkIndfrielser.LeasingNumber">
                                <p>Indfrielsesdato</p>
                                <p class="fielddesc">(altid den sidste i en måned og ikke længere frem end den førstkommende sidste)</p>
                                <ejs-datepicker
                                  class="datepicker"
                                  :placeholder="datePlaceholder"
                                  :firstDayOfWeek="dateFirstDay"
                                  :showClearButton="false"
                                  :allowEdit="false"
                                  :format="dateFormat"
                                  :renderDayCell="disableDateNotLast"
                                  :showTodayButton="false"
                                  locale='da'
                                  v-model="BestillingsarkIndfrielser.RedemptionDate"
                                  cssClass="custom-datepicker">
                                </ejs-datepicker>
                                <p>Tilbagediskontering af fremtidige ydelser (med kunderente)</p>
                                <select v-model="BestillingsarkIndfrielser.BackDiscounting">
                                  <option value="Ja" selected="selected">Ja</option>
                                  <option value="Nej">Nej</option>
                                </select>
                                <p>Indfrielsesgebyr (kan kun opkræves, hvis der ikke tilbagediskonteres med kunderente)</p>
                                <input v-model="BestillingsarkIndfrielser.RedemptionFee">

                                <p class="fieldsubheader">Betalingsadvis udstedes til</p>
                                <p>CVR</p>
                                <input v-model="BestillingsarkIndfrielser.PaymentAdviceCVR">
                                <p>Firmanavn</p>
                                <input v-model="BestillingsarkIndfrielser.PaymentAdviceCompanyName">
                                <p>Mail</p>
                                <input v-model="BestillingsarkIndfrielser.PaymentAdviceMail">

                                <p>Særlige bemærkninger</p>
                                <textarea v-model="BestillingsarkIndfrielser.Remarks"></textarea>
                            </div>
                        </div>
                    </div>

                    <div v-else-if="!requested && request == this.requestIds[2]">
                        <div class="row">
                            <div class="col fields">
                                <p>Leasingaftale nummer</p>
                                <input id="BestillingsarkForlaengelserLeasingNumber" v-model="BestillingsarkForlaengelser.LeasingNumber">
                                <p>CVR.nr. på debitor</p>
                                <input v-model="BestillingsarkForlaengelser.DebitorCVR">

                                <p style="font-weight: bold">Marginalen fra tidligere aftale bibeholdes.</p>
                                <p><br></p>
                                <p>Aftalen forlænges med x antal måneder</p>
                                <input v-model="BestillingsarkForlaengelser.ProlongMonths">
                                <p>Indtast enten ny ydelse eller ny restværdi (Vi beregner enten ny restværdi eller ny ydelse)</p>
                                <select v-model="BestillingsarkForlaengelser.ResidualType">
                                    <option value="Restværdi" selected="selected">Restværdi</option>
                                    <option value="Ydelse">Ydelse</option>
                                </select>
                                <p>Kr.</p>
                                <input v-model="BestillingsarkForlaengelser.ResidualValue">

                                <p>Ekspeditionsgebyr</p>
                                <p class="fielddesc">(Gebyret tilfalder banken og kan ændres)</p>
                                <input v-model="BestillingsarkForlaengelser.HandlingFee">
                                <p>Skal omkostninger medfinansieres</p>
                                <select v-model="BestillingsarkForlaengelser.CostsCofinanced">
                                    <option value="Ja" selected="selected">Ja</option>
                                    <option value="Nej">Nej</option>
                                </select>
                                <p>Hvem er underskriver (jf. tegningsregler)</p>
                                <input v-model="BestillingsarkForlaengelser.DebitorSigner">
                                <p>Mail på underskriver(e)</p>
                                <input v-model="BestillingsarkForlaengelser.DebitorMails">
                                <p>Allonge sendes til</p>
                                <select v-model="BestillingsarkForlaengelser.Allonge">
                                  <option value="Kunde" selected="selected">Kunde</option>
                                  <option value="Rådgiver">Rådgiver</option>
                                </select>

                                <p>Særlige bemærkninger</p>
                                <textarea v-model="BestillingsarkForlaengelser.Remarks"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-footer">
                    <div class="text-center">
                        <button v-if="!requested" type="button" class="btn-solid-secondary" v-on:click="submit()">Send
                        </button>
                        <button v-if="requested && emailSent" type="button" class="btn-link-secondary" v-on:click="closeModal()">
                            Luk
                        </button>
                    </div>
                </div>

                <span class="close-button" v-on:click="closeModal()">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 17L17 7" stroke="#4C6C69" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M17 17L7 7" stroke="#4C6C69" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AbAxiosLeasingReporting from './BankloginAxiosLeasingReporting';
import localforage from 'localforage';
import { DatePickerPlugin } from '@syncfusion/ej2-vue-calendars';
import { L10n, loadCldr } from '@syncfusion/ej2-base';
import moment from 'moment';

Vue.use(DatePickerPlugin);

// Datepicker customization
loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/da/ca-gregorian.json'),
  require('cldr-data/main/da/numbers.json'),
  require('cldr-data/main/da/timeZoneNames.json'),
);

L10n.load({
  'da': {
    'datepicker': { today: 'I dag'},
    'daterangepicker': { startLabel: 'Start dato', endLabel: 'Slut dato', applyText: 'Vælg', cancelText: 'Annuller', selectedDays: 'Valgte datoer', days: 'dage' }
  }
});

@Component({
    name: 'dialog-component',
    components: {},
})

export default class Dialog extends Vue {
    $refs: {
        modal: HTMLElement;
    };

    //datepicker
    datePlaceholder: string = 'Vælg dato';
    dateFirstDay: number = 1;
    dateFormat = 'dd-MM-yyyy';

    abAxios = new AbAxiosLeasingReporting();

    request: string = '';
    requestName: string = '';
    error: boolean = false;

    requestIds = [
      "Bestillingsark-Debitorskifte",
      "Bestillingsark-indfrielser",
      "Bestillingsark-forlaengelser"
    ]

    // Storing modal inputs
    BestillingsarkDebitorskifte: any = {
      LeasingNumber: '',
      Date: '',
      ExitingDebitor: '',
      DebitorName: '',
      DebitorCVR: '',
      DebitorMail: '',
      DebitorAccount: '',
      DebitorPhone: '',
      DebitorSigner: '',
      DebitorMails: '',
      DebitorInsurance: '',
      DebitorSurety: '',
      DebitorSuretyName: '',
      DebitorSuretyCvr: '',
      DebitorSuretyEmail: '',
      DebitorSuretyPhone: '',
      DebitorSuretyAddress: '',
      DebitorSuretyZip: '',
      DebitorFee: '2500',
      ReRegistrationFee: '380',
      Allonge: 'Kunde',
      Remarks: ''
    };

    BestillingsarkIndfrielser: any = {
        LeasingNumber: '',
        RedemptionDate: '',
        BackDiscounting: 'Ja',
        RedemptionFee: '',
        PaymentAdviceCVR: '',
        PaymentAdviceCompanyName: '',
        PaymentAdviceMail: '',
        Remarks: ''
    };

    BestillingsarkForlaengelser: any = {
        LeasingNumber: '',
        DebitorCVR: '',
        ProlongMonths: '',
        ResidualType: '',
        ResidualValue: '',
        HandlingFee: '2995',
        CostsCofinanced: 'Ja',
        DebitorSigner: '',
        DebitorMails: '',
        Allonge: 'Kunde',
        Remarks: ''
    };

    requested: boolean = false;
    emailSent: boolean = false;
    responseRecieved: boolean = false;
    spinnerShown: boolean = false;

    public disableDateNotFirst(args: { date: string | number | Date; isDisabled: boolean; }) {
      const date = new Date(args.date);
      const now = new Date();
      const diff = now.getTime() - date.getTime();

      if (diff > 0 || date.getDate() != 1) {
        args.isDisabled = true;
      }
    }

    public disableDateNotLast(args: { date: string | number | Date; isDisabled: boolean; }) {
      const date = new Date(args.date)
      const numberOfDaysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      if (numberOfDaysInMonth != date.getDate()) {
        args.isDisabled = true;
      }
    }

    // When the user clicks the button, open the modal
    public openModal(request: string, requestName: string) {
        this.request = request;
        this.requestName = requestName;
        this.$refs.modal.style.display = 'block';

        //scroll to top of modal
        const cardDiv = document.querySelector('.card-body');
        cardDiv.scrollTop = 0;

        //focus first element
        setTimeout(() => {
            if (request == this.requestIds[0]) {
                document.getElementById('BestillingsarkDebitorskifteLeasingNumber').focus();
            } else if (request == this.requestIds[1]) {
                document.getElementById('BestillingsarkIndfrielserLeasingNumber').focus();
            } else if (request == this.requestIds[2]) {
                document.getElementById('BestillingsarkForlaengelserLeasingNumber').focus();
            }
        }, 300);
    }

    // When the user clicks on <span> (x), close the modal
    public closeModal() {
        this.$refs.modal.style.display = 'none';
        this.reset();
    }

    private closeOnModalClick() {
        if (event && event.target === this.$refs.modal) {
            this.closeModal();
        }
    }

    // Resetting when closing
    private reset() {
        this.BestillingsarkDebitorskifte = {
            LeasingNumber: '',
            Date: '',
            ExitingDebitor: '',
            DebitorName: '',
            DebitorCVR: '',
            DebitorMail: '',
            DebitorAccount: '',
            DebitorPhone: '',
            DebitorSigner: '',
            DebitorMails: '',
            DebitorInsurance: '',
            DebitorSurety: '',
            DebitorSuretyName: '',
            DebitorSuretyCvr: '',
            DebitorSuretyEmail: '',
            DebitorSuretyPhone: '',
            DebitorSuretyAddress: '',
            DebitorSuretyZip: '',
            DebitorFee: '2500',
            ReRegistrationFee: '380',
            Allonge: 'Kunde',
            Remarks: ''
        };

        this.BestillingsarkIndfrielser = {
            LeasingNumber: '',
            RedemptionDate: '',
            BackDiscounting: 'Ja',
            RedemptionFee: '',
            PaymentAdviceCVR: '',
            PaymentAdviceCompanyName: '',
            PaymentAdviceMail: '',
            Remarks: ''
        };

        this.BestillingsarkForlaengelser = {
            LeasingNumber: '',
            DebitorCVR: '',
            ProlongMonths: '',
            ResidualType: '',
            ResidualValue: '',
            HandlingFee: '2995',
            CostsCofinanced: 'Ja',
            DebitorSigner: '',
            DebitorMails: '',
            Allonge: 'Kunde',
            Remarks: ''
        };

        this.request = '';
        this.requested = false;
        this.spinnerShown = false;
        this.responseRecieved = false;
        this.emailSent = false;
    }

    // Mail submit
    private async submit() {
        this.responseRecieved = false;
        const token: any = await localforage.getItem('BankloginToken');
        let bodyFormData = {
            'token': token.token,
            'subject': this.requestName,
            'body': 'Formularen "' + this.requestName + '" blev udfyldt med følgende informationer\n\n'
        };

      bodyFormData.body += 'Afsender: ' + token.bank_name + ' ' + token.email + '\n\n';

      if (this.request == this.requestIds[0]) {
          let date = '';
          if (this.BestillingsarkDebitorskifte.Date != '') {
            date = moment(this.BestillingsarkDebitorskifte.Date).format('DD/MM/YYYY');
          }

            bodyFormData.body +=
              'Leasingaftale nummer: ' + this.BestillingsarkDebitorskifte.LeasingNumber + '\n' +
              'Dato for debitorskifte: ' + date + '\n' +
              'CVR.nr. på udtrædende debitor: ' + this.BestillingsarkDebitorskifte.ExitingDebitor + '\n\n' +
              'Indtrædende debitor\n\n' +
              'Navn: ' + this.BestillingsarkDebitorskifte.DebitorName + '\n' +
              'CVR: ' + this.BestillingsarkDebitorskifte.DebitorCVR + '\n' +
              'Mail: ' + this.BestillingsarkDebitorskifte.DebitorMail + '\n' +
              'Reg. nr. og konto nr. til BS: ' + this.BestillingsarkDebitorskifte.DebitorAccount + '\n' +
              'Tlf. nr.: ' + this.BestillingsarkDebitorskifte.DebitorPhone + '\n' +
              'Hvem er underskriver (jf. tegningsregler): ' + this.BestillingsarkDebitorskifte.DebitorSigner + '\n' +
              'Mail på underskriver(e): ' + this.BestillingsarkDebitorskifte.DebitorMails + '\n' +
              'Forsikringsselskab: ' + this.BestillingsarkDebitorskifte.DebitorInsurance + '\n\n' +
              'Kautionist: ' + this.BestillingsarkDebitorskifte.DebitorSurety + '\n' +
              'Navn: ' + this.BestillingsarkDebitorskifte.DebitorSuretyName + '\n' +
              'CVR: ' + this.BestillingsarkDebitorskifte.DebitorSuretyCvr + '\n' +
              'E-mail: ' + this.BestillingsarkDebitorskifte.DebitorSuretyEmail + '\n' +
              'Tlf. nr.: ' + this.BestillingsarkDebitorskifte.DebitorSuretyPhone + '\n' +
              'Adresse: ' + this.BestillingsarkDebitorskifte.DebitorSuretyAddress + '\n' +
              'Postnummer: ' + this.BestillingsarkDebitorskifte.DebitorSuretyZip + '\n\n' +
              'Gebyr: ' + this.BestillingsarkDebitorskifte.DebitorFee + '\n' +
              'Gebyr for omregistrering i DMR: ' + this.BestillingsarkDebitorskifte.ReRegistrationFee + '\n' +
              'Allonge sendes til: ' + this.BestillingsarkDebitorskifte.Allonge + '\n' +
              'Særlige bemærkninger:\n' + this.BestillingsarkDebitorskifte.Remarks;
        } else if (this.request == this.requestIds[1]) {
          let date = '';
          if (this.BestillingsarkIndfrielser.RedemptionDate != '') {
            date = moment(this.BestillingsarkIndfrielser.RedemptionDate).format('DD/MM/YYYY');
          }

            bodyFormData.body +=
              'Leasingaftale nummer: ' + this.BestillingsarkIndfrielser.LeasingNumber + '\n' +
              'Indfrielsesdato: ' + date + '\n' +
              'Tilbagediskontering af fremtidige ydelser (med kunderente): ' + this.BestillingsarkIndfrielser.BackDiscounting + '\n' +
              'Indfrielsesgebyr (kan kun opkræves, hvis der ikke tilbagediskonteres med kunderente): ' + this.BestillingsarkIndfrielser.RedemptionFee + '\n\n' +
              'Betalingsadvis udstedes til\n\n' +
              'CVR: ' + this.BestillingsarkIndfrielser.PaymentAdviceCVR + '\n' +
              'Firmanavn: ' + this.BestillingsarkIndfrielser.PaymentAdviceCompanyName + '\n' +
              'Mail: ' + this.BestillingsarkIndfrielser.PaymentAdviceMail + '\n' +
              'Særlige bemærkninger:\n' + this.BestillingsarkIndfrielser.Remarks;
        } else if (this.request == this.requestIds[2]) {
            bodyFormData.body +=
              'Leasingaftale nummer: ' + this.BestillingsarkForlaengelser.LeasingNumber + '\n' +
              'CVR.nr. på debitor: ' + this.BestillingsarkForlaengelser.DebitorCVR + '\n' +
              'Marginalen fra tidligere aftale bibeholdes.\n\n' +
              'Aftalen forlænges med x antal måneder: ' + this.BestillingsarkForlaengelser.ProlongMonths + '\n' +
              'Indtast enten ny ydelse eller ny restværdi: ' + this.BestillingsarkForlaengelser.ResidualType + '\n' +
              'Kr: ' + this.BestillingsarkForlaengelser.ResidualValue + '\n\n' +
              'Ekspeditionsgebyr: ' + this.BestillingsarkForlaengelser.HandlingFee + '\n' +
              'Skal omkostninger medfinansieres: ' + this.BestillingsarkForlaengelser.CostsCofinanced + '\n\n' +
              'Hvem er underskriver (jf. tegningsregler): ' + this.BestillingsarkForlaengelser.DebitorSigner + '\n' +
              'Mail på underskriver(e): ' + this.BestillingsarkForlaengelser.DebitorMails + '\n' +
              'Allonge sendes til: ' + this.BestillingsarkForlaengelser.Allonge + '\n\n' +
              'Særlige bemærkninger:\n' + this.BestillingsarkForlaengelser.Remarks;
        }

        this.requestMail(bodyFormData);
    }

    requestMail(bodyFormData) {
        const _self = this;

        _self.spinnerShown = true;
        _self.requested = true;

        this.abAxios.sendFormMail(bodyFormData, function succesCallback () {
            _self.spinnerShown = false;
            _self.responseRecieved = true;
            _self.emailSent = true;
        }, function errorCallback(){
            _self.spinnerShown = false;
            _self.responseRecieved = true;
            _self.emailSent = false;
        });
    }

    mounted() {
        this.$refs.modal.style.display = 'none';
        this.reset();
        window.scrollTo(0, 0);
    }
}
</script>

<style lang="scss">
@import "calendar";
@import "datepicker";
//@import "newStyle";
.dialog.modal {
    background: rgba(225, 242, 240, 0.75);

    .modal-content {
        max-width: 760px;
        width: 100%;
        border: none;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 32px 64px -24px rgba(31, 72, 68, 0.16);
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 152px;
        max-height: calc(100% - 304px);

        .card {
            border-radius: 20px;
            box-shadow: none;
            padding: 48px;
            margin-bottom: 0px;
            //overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .card-header {
            padding: 0;
            background: none;
            border: none;

            h1 {
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 130%;
                text-align: center;
                color: #1F4743;
            }

            margin-bottom: 28px;
        }

        .card-body {
            padding: 0;
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 400px;
            -ms-overflow-style: none;
            scrollbar-width: auto;
            scrollbar-color: #69BDB6 #ffffff;

            .errorMsg {
                padding: 24px;
                padding-top: 12px;
                background-color: rgba(105, 189, 182, 0.09);

                p {
                    margin-top: 12px;
                }
            }
        }

        .btn-outline-secondary,
        .btn-link-secondary,
        .btn-solid-secondary {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            color: #FFFFFF;
            border: none;
            box-sizing: border-box;
            padding: 32px;
            padding-top: 11px;
            padding-bottom: 14px;
            background: #69BDB6;
            border-radius: 100px;
            margin-left: 8px;
            margin-right: 8px;
            margin-top: 20px;

            &:disabled {
                background: #C7E5E4;
            }
        }

        .card-footer {
            padding: 0px;
            background: none;
            border: none;
        }

        .fields {
            max-width: 320px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            text-align: left;
            padding: 0px;

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 135%;
                color: #1F4743;
                text-align: left;
                margin-bottom: 8px;
            }

            p.fieldsubheader {
                font-size: 18px;
                font-weight: bold;
            }
        }

        textarea,
        select,
        input {
            width: 100%;
            box-sizing: border-box;
            border: 1px solid #C7E5E4;
            border-radius: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #1F4743;
            padding: 20px;
            padding-top: 16px;
            margin-bottom: 30px;
        }

        textarea {
            min-height: 130px;
        }

        .close-button {
            cursor: pointer;
            display: block;
            position: absolute;
            top: 28px;
            right: 28px;
            width: 24px;
            height: 24px;
        }

        .fielddesc {
            font-size: 13px !important;
            margin-top: -10px;
        }

      .fielddeschighlighted {
          font-size: 15px !important;
          font-weight: bold !important;
          color: red !important;
      }
    }

    .spinnerdesc {
        margin-bottom: 20px;
    }
}

.card-body::-webkit-scrollbar {
  width: 10px;
}

.card-body::-webkit-scrollbar-track {
  background: #ffffff;
}

.card-body::-webkit-scrollbar-thumb {
  background-color: #69BDB6;
  border-radius: 6px;
  border: 3px solid #ffffff;
}
</style>
