<template>
  <div>

    <b-row v-if="false" class="my-5" align-h="center">
      
      <b-col cols="12" class="mt-5">
        <div
          class="login-container container bgcolor-white py-4"
          style="max-width: 588px; max-height: 588px"
        >
          
          <div class="row">
            <div class="col text-center">
              <p class="primary-font__bold__xlarge lineBelow">Log på</p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-10">
              <form v-on:submit="onSubmit" class="form__fields__container">
                <div class="form-group form__item">
                  <label for="userName">Brugernavn</label>
                  <input
                    id="userName"
                    class="form-control"
                    type="text"
                    v-model="userName"
                    v-on:keyup.enter="login()"
                    @focus="resetUserNameError()"
                  />
                  <p class="secondary-font__regular color-primary__red">
                    {{ userNameErrLabel }}
                  </p>
                </div>
                <div class="form-group form__item">
                  <label for="password">Kodeord</label>
                  <input
                    id="password"
                    class="form-control"
                    type="password"
                    v-model="password"
                    v-on:keyup.enter="login()"
                    @focus="resetPasswordError()"
                  />
                  <p class="secondary-font__regular color-primary__red">
                    {{ passwordErrLabel }}
                  </p>
                </div>
              </form>

              <div class="row mt-4 mb-4 align_center text-center">
                <div class="col-12">
                  <b-button
                    pill
                    variant="info"
                    class="custom-button button"
                    @click="login()"
                    >Log på</b-button
                  >
                </div>
              </div>

              <p class="secondary-font__large text-center pt-4">
                Har du glemt kodeordet? Kontakt os på tlf. 70 60 70 10.
              </p>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>


    <LoginView v-if="businessUser" :view="'leasingpro-login'" @submit="onCustomSubmit"/>

    
    <b-row v-else id="proapp">
      <b-col cols="12">
        <router-view :key="$route.path" />
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
.leasingpro-login-box {
  font-size: 16px;
  background: rgb(213, 237, 250);
  min-height: 350px;
  .form-control {
    margin: 20px 0;
    padding: 20px;
    font-size: 16px;
  }
}
// Buttons
.custom-button {
  font-size: 14px;
  color: white;
  background: rgb(97, 157, 173);
  padding: 10px 20px;
  border-color: white;
  border-width: 2px;
  font-family: "Noto Serif TC", serif !important;
  letter-spacing: 0.05em;
  box-shadow: none !important;
  &.white-bg {
    background: none;
    border-color: rgb(97, 157, 173);
    color: #053951;
    border-width: 1px;
    &:hover {
      border-color: unset;
    }
  }
  &:hover {
    border-color: white;
    text-decoration: none;
  }
  &.disabled {
    background: rgb(97, 157, 173);
  }
}
</style>

<script lang="ts">
"use strict";
import Vue from "vue";
import { Component, Provide, Watch } from "vue-property-decorator";
import EpiRouting from "./EpiRouting";
import selfserviceStore from "./store";
import Router, { Route } from "vue-router";
import * as localForage from "localforage";

//import GlobalContentHandler from "./providers/GlobalContentHandler";
import { UPDATE_MODEL_BY_URL } from "./episerver/store/modules/epiContent";
// import { UPDATE_AUTH } from "./episerver/store/modules/authenticationContext";

// import VueScrollTo from "vue-scrollto";

//enables page editor support
import epiEditor from "./episerver/editor/pageEditorSupport";

//app support
const baseUrl = "";

epiEditor.enablePageEditorSupport(selfserviceStore);
//registers all epi pages and blocks with vue
import "./sharedcomponents/epiComponents/RegisterEpiComponents";
import "./components/overview/leasing/components/RegisterEpiComponents";
import "./components/contentApi/components/RegisterEpiComponents";


import eventBus from '../../eventBus';
import LoginView from  "@/views/logins/logins";

import AbAxios from "../leasing/Showroom/src/common/ab_axios_leasing";
import UserData from "../leasing/Showroom/src/components/providers/UserData";
import { CustomerSpec } from "../leasing/Showroom/src/components/providers/UserData";
import AbLightbox from "../leasing/Showroom/src/common/ab_lightbox";
import AbGtm from "../leasing/Showroom/src/common/ab_gtm";

export const enum StorageType {
  USERNAME = "leasing_userName",
  CUST_TYPE = "leasing_cust_type",
  CUST_SPEC = "leasing_cust_spec",

  //generale variabler til api-services
  TOKEN = "token",
  LOGIN_REQUIRED = "loginRequired",
}

// Vue.use(VueScrollTo);

const toggleEditAttributes = (el, binding, vnode) => {
  const siteIsEditable = selfserviceStore.state.epiContext.isEditable;
  const componentIsEditable = !vnode.context.epiDisableEditing;

  if (siteIsEditable && componentIsEditable) {
    el.setAttribute("data-epi-edit", binding.value);
  } else {
    el.removeAttribute("data-epi-edit");
  }
};
Vue.directive("epi-edit", {
  bind: toggleEditAttributes,
  update: toggleEditAttributes,
});

const router = new Router({
  linkActiveClass: "active-child",
  linkExactActiveClass: "active",
  // scrollBehavior(to, from, savedPosition) {
  //     return { x: 0, y: 0 };
  // },
  mode: "history",
});
//legacy dynamisk routing, must be set before router.beforeEach else they will be called twice on first load
new EpiRouting().addRoutes(router, selfserviceStore);
import { sync } from "vuex-router-sync";
sync(selfserviceStore, router);

router.beforeEach((to, from, next) => {
  // URL is updated by vue-route-sync, and when time travelling with the
  // debugger we don't want to trigger a model commit as the model is already
  // part of the store holding the url update.

  if (
    !selfserviceStore.state.epiContent.model ||
    selfserviceStore.state.epiContent.model.url !==
      baseUrl + to.fullPath.replace("#", "")
  ) {
    selfserviceStore.dispatch(
      UPDATE_MODEL_BY_URL,
      baseUrl + to.fullPath.replace("#", "")
    );
  }

  next();
});

// Authentication Check
// Gets the Authentication expire time and saves it in the store, then checks if current user is still Authenticated when changing route
// router.beforeEach((to, from, next) => {
//     const path = to.fullPath.replace("#", "");
//     // Only run when not in App and currently when this URL "/mitalmbrand/bilforsikring" is used
//     if (!path.startsWith("/beregn/bilforsikring")) {
//         if (!selfserviceStore.state.authenticationContext.isLoaded || !selfserviceStore.state.authenticationContext.authenticated) {
//             selfserviceStore.dispatch(UPDATE_AUTH);
//         } else if (selfserviceStore.state.authenticationContext.isLoaded && selfserviceStore.state.authenticationContext.authenticated) {
//             const currentTime = new Date().getTime();
//             if (currentTime > selfserviceStore.state.authenticationContext.expire) {
//                 window.location.reload();
//             }
//         }
//     }
//     next();
// });

Vue.use(Router);

@Component({
  name: "webview-leasingpro-app",
  components: {
    LoginView
  },
  store: selfserviceStore,
  router: router,
})
export default class App extends Vue {
  isBusiness: boolean = false;
  preUrlStr: string = "";
  businessUser: boolean = true;
  isLoggedIn: boolean = false;
  business_email: string = "";
  business_password: string = "";
  business_checkbox: boolean = false;

  userName: string = "";
  password: string = "";

  userData: UserData = this.$store.getters.getUserData;
  abLightbox = new AbLightbox();
  abGtm = new AbGtm();

  abAxios = new AbAxios(
    // "/security/oauth/token",
    "/security/login",
    "application/json; charset=utf-8",
    ""
  );

  // loginServiceUrl: string = "/security/oauth/token";
  loginServiceUrl: string = "/security/login";

  userNameErrLabel: string = "";
  passwordErrLabel: string = "";

  onCustomSubmit(data){
    this.userName = data.username;
    this.password = data.password;
    this.login();
  }
  onSubmit(evt) {

    

    evt.preventDefault();
  }

  resetUserNameError() {
    this.userNameErrLabel = "";
  }

  resetPasswordError() {
    this.passwordErrLabel = "";
  }

  resetLabels() {
    this.userNameErrLabel = "";
    this.passwordErrLabel = "";
  }

  validateSubmit() {
    this.resetLabels();
    let validated: boolean = true;

    if (this.userName === undefined || this.userName === "") {
      this.userNameErrLabel = "Du mangler at angive brugernavn";
      validated = false;
    }

    if (this.password === undefined || this.password === "") {
      this.passwordErrLabel = "Du mangler at angive kodeord";
      validated = false;
    }

    return validated;
  }

  login() {
    if (this.validateSubmit()) {

      sessionStorage.removeItem("closedLeasingProPopup");

      this.validateViaRest(this.loginServiceUrl);
    }
  }

  validateViaRest(serviceUrl: string) {
    var self = this;

    let headers: any = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        "Basic YWxtYnJhbmQtYnl0ZWxhYi10ZXN0OjAwMjdjYWMwLTQ0OGMtNDRkMy04YjdiLTFhNjk5NDkwY2FhOQ==",
      "x-csrf-token": "",
    };

    let formData = new FormData();
    formData.append("grant_type", "password");
    //formData.append("client_id", "almbrand-bytelab-test");
    formData.append("subsystem", "leasingpro");
    formData.append("username", this.userName);
    formData.append("password", this.password);

    this.abAxios.abPostWithHeaders(
      serviceUrl,
      formData,
      headers,
      true,
      function successCallback(response: any) {
        // saving token
        localForage.setItem(StorageType.TOKEN, response.data); //OPENDO-4735 .access_token
        self.isLoggedIn = true;
        eventBus.$emit("leasingpro-loggedin");

        self.hasLoggedIn().then((value) => {
          self.businessUser = false;
        });
      },
      function errorCallback(error: any) {
        //console.log(error);
        let errorRes = error.response,
          statusCode = errorRes.status,
          statusText = errorRes.statusText,
          errorMsg =
            "Login fejlede. Noget gik galt.. " +
            "[" +
            statusCode +
            " - " +
            statusText +
            "]";
        self.abGtm.triggerGtmEvent(
          "fejl",
          "Login",
          "[" + self.abGtm.getUtcTimestamp() + "] " + errorMsg
        );

        self.abLightbox.standardLightText(
          "Login fejlede",
          "Brugernavn eller kodeord er forkert... </br> Ring til os på Tlf. <a class='popup__content__phone-link' href='tel: 70607010'> 70 60 70 10</a>"
        );
      }
    );
  }
  async hasLoggedIn() {
    // console.log("hasloggedin");
    return await localForage
      .getItem(StorageType.TOKEN)
      .then((value) => {
        if (value !== null) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  mounted() {
    if (
      !this.isBusiness &&
      window.location.href.indexOf("customertype=business") > -1
    ) {
      this.isBusiness = true;
    }

    router.afterEach((to, from) => {
      this.buildTracking(to);
    });

    this.handleHoverOnMobile();
  }

  buildTracking(to: Route) {
    try {
      let path = to.path;
      if (path.endsWith("/")) {
        path = path.slice(0, -1);
      }

      if (to.path.startsWith("/mitalmbrand")) {
        this.preUrlStr = "";
      } else {
        this.preUrlStr = "/mitalmbrand";
      }
    } catch (err) {}
  }

  handleHoverOnMobile() {
    function hasTouch() {
      return (
        "ontouchstart" in document.documentElement ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    }

    if (hasTouch()) {
      // remove all :hover stylesheets
      try {
        // prevent exception on browsers not supporting DOM styleSheets properly
        for (var si in document.styleSheets) {
          var styleSheet = document.styleSheets[si] as any;
          if (!styleSheet.rules) continue;

          for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
            if (!styleSheet.rules[ri].selectorText) continue;

            if (styleSheet.rules[ri].selectorText.match(":hover")) {
              styleSheet.deleteRule(ri);
            }
          }
        }
      } catch (ex) {}
    }
  }

  skipContent(e) {
    const pageContent = document.querySelector(".page__content");
    const firstTabindex = pageContent.querySelector(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    ) as HTMLElement;

    if (firstTabindex !== null && e.keyCode === 13) {
      firstTabindex.focus();
      e.stopPropagation();
    }
  }
}
</script>


<style lang="scss">
@import "newStyle";

// @import "style";
.leasingpro-styling-new{
  // @import "style";
  -ms-overflow-style: scrollbar;
  font-size: var(--font-size-modifier, 62.5%);
  height: 100%;
  

  @import "calendar";
  
}

</style>
