<template>

    <div class="content-box" v-if="calculatedContract.Leasing !== undefined">

        <div class="row no-gutters mb-3">
            <div class="col text-center">
                <span class="primary-font__bold__xlarge" v-if="!isCorporate">Leasingforslag privat</span>
                <span class="primary-font__bold__xlarge" v-else>Leasingforslag erhverv</span>
            </div>
        </div>


        <div class="row no-gutters">
            <div class="col text-center">
                <span class="primary-font__bold__xlarge">{{detailedCar.vehicle.make.name +" "+ detailedCar.vehicle.model.name}} </span>
                <span class="primary-font__bold__xlarge color-primary__blue font-italic lineBelow">{{detailedCar.vehicle.variant}}</span>
            </div>
        </div>

        <div class="row align_center no-gutters">
            <div class="col-6">
                <progressive-img class="img-fluid -loading" :src="detailedCar.vehicle.images[0]" alt="" />
            </div>
            <div class="col-6">
                <div class="d-flex my-flex-wrap justify-content-end pikto-container">
                    <div class="mr-3 pikto-box">
                        <img src="/ui/assets/svg/piktogrammer_braendstof.svg" style="height: 40px" />
                        <p class="secondary-font__regular">{{detailedCar.vehicle.fuel_type}}</p>
                    </div>
                    <div class="mr-3 pikto-box">
                        <img src="/ui/assets/svg/piktogrammer_forbrug.svg" style="height: 40px" />
                        <p class="secondary-font__regular">{{detailedCar.vehicle.fuel_economy}} km/l</p>
                    </div>
                    <div class="mr-3 pikto-box">
                        <img src="/ui/assets/svg/piktogrammer_speedometer.svg" style="height: 40px" />
                        <p class="secondary-font__regular">{{detailedCar.vehicle.horse_power}}HK</p>
                    </div>
                    <div class="mr-3 pikto-box">
                        <img src="/ui/assets/svg/piktogrammer_motor.svg" style="height: 40px" />
                        <p class="secondary-font__regular">{{detailedCar.vehicle.engine_size}} liter</p>
                    </div>
                    <div class="mr-3 pikto-box">
                        <img src="/ui/assets/svg/piktogrammer_doere.svg" style="height: 40px" />
                        <p class="secondary-font__regular">{{detailedCar.vehicle.doors}}-dørs</p>
                    </div>
                    <div class="mr-3 pikto-box">
                        <img src="/ui/assets/svg/piktogrammer_anhaenger.svg" style="height: 40px" />
                        <p class="secondary-font__regular">{{detailedCar.vehicle.trailer_max_weight}} kg</p>
                    </div>
                    <div class="mr-3 pikto-box">
                        <img src="/ui/assets/svg/piktogrammer_Energi.svg" style="height: 40px" />
                        <p class="secondary-font__regular">{{detailedCar.vehicle.energy}}</p>
                    </div>
                    <!-- 
                    <div class="mr-3 pikto-box" v-if="customerType === 'Corporate'">
                        <img src="/ui/assets/svg/Piktogrammer_sikkerhed.svg" style="height: 40px" />
                        <p class="secondary-font__regular">{{detailedCar.vehicle.BasicVehicleInformation.EuroNCAPStars}}</p>
                    </div>
                    -->
                </div>
            </div>
        </div>

        <div class="row mt-1 no-gutters">
            <div class="col-12">
                <span class="primary-font__bold__xlarge">
                    Din leasingpris
                </span>
            </div>
        </div>
        <div class="row secondary-font__regular">
            <div class="col-7">
                Leasingperiode
            </div>
            <div class="col-5 text-right">
                <span>{{$store.getters.getCreatedContract.Contract.Period}} måneder</span>
            </div>
        </div>
        <div class="row secondary-font__regular">
            <div class="col-7">
                Kilometer
            </div>
            <div class="col-5 text-right">
                <span>{{formatPrice($store.getters.getCreatedContract.Contract.Leasing.AnnualMileage)}} km. om året</span>
            </div>
        </div>


        <div v-if="!isCorporate">
            <div class="row no-gutters mt-1">
                <div class="col-12">
                    <span class="primary-font__bold__large color-primary__dark-blue">
                        Førstegangsydelse
                    </span>
                </div>
            </div>
            <div class="row secondary-font__regular" v-if="calculatedContract.Leasing.DownPayment != null">
                <div class="col-7">
                    Engangsydelse
                </div>
                <div class="col-5 text-right">
                    <span>{{formatPrice(calculatedContract.Leasing.DownPayment.IncVat) + ' kr.'}}</span>
                </div>
            </div>
            <div class="row secondary-font__regular" v-if="calculatedContract.Leasing.InitialFeeVatPrice != null">
                <div class="col-7">
                    Stiftelsesomk.
                </div>
                <div class="col-5 text-right">
                    <span>{{formatPrice(calculatedContract.Leasing.InitialFeeVatPrice.IncVat) + ' kr.'}}</span>
                </div>
            </div>
            <div class="row secondary-font__regular" v-if="calculatedContract.Leasing.InsuranceAdministration != null">
                <div class="col-7">
                    Forsikringsadmin.
                </div>
                <div class="col-5 text-right">
                    <span>{{formatPrice(calculatedContract.Leasing.InsuranceAdministration) + ' kr.'}}</span>
                </div>
            </div>
            <div class="row primary-font__bold" v-if="calculatedContract.Leasing.PrePaymentVatPrice.IncVat != null">
                <div class="col-7">
                    I alt
                </div>
                <div class="col-5 text-right">
                    <span>{{formatPrice(calculatedContract.Leasing.PrePaymentVatPrice.IncVat) + ' kr.'}}</span>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row secondary-font__regular" v-if="calculatedContract.Leasing.TaxValueInclVat">
                <div class="col-7">
                    Beskatningsværdi
                </div>
                <div class="col-5 text-right">
                    <span>{{formatPrice(calculatedContract.Leasing.TaxValueInclVat)}} kr.</span>
                </div>
            </div>
            <div class="row secondary-font__regular" v-if="calculatedContract.Leasing.TaxValueInclVatMonthly != null">
                <div class="col-7">
                    Månedlig beskatning (inkl. miljøtillæg)
                </div>
                <div class="col-5 text-right">
                    <span>{{formatPrice(calculatedContract.Leasing.TaxValueInclVatMonthly)}} kr.</span>
                </div>
            </div>
        </div>


        <div class="row no-gutters mt-1">
            <div class="col-7">
                <span class="primary-font__bold__large color-primary__dark-blue">
                    Månedlig ydelse inkl. service
                </span>
            </div>
            <!--<div class="col-5 text-right">
                <span>{{formatPrice(basketMonthlyPaymentWithoutExtra)}} kr.</span>
            </div>-->
        </div>
        <!--<div class="row secondary-font__regular">
            <div class="col-7">
                Tilvalgt ekstraudstyr
            </div>
            <div class="col-5 text-right">
                <span>{{formatPrice(basketEquipmentPriceTotal)}} kr.</span>
            </div>
        </div>
        <div class="row secondary-font__regular" v-for="item in basketSelectedItems">
            <div class="col-7">
                <span>{{item.Name}}</span>
            </div>
            <div class="col-5 text-right">
                <span>{{formatPrice(item.MonthlyPrice)}} kr.</span>
            </div>
        </div>-->
        <div class="row primary-font__bold" v-if="calculatedContract.Leasing.TotalMonthlyPaymentVatPrice != null">
            <div class="col-7">
                <span>I alt</span>
            </div>
            <div class="col-5 text-right">
                <span v-if="!isCorporate">{{formatPrice(calculatedContract.Leasing.TotalMonthlyPaymentVatPrice.IncVat) + ' kr.'}}</span>
                <span v-else>{{formatPrice(calculatedContract.Leasing.TotalMonthlyPaymentVatPrice.ExVat) + ' kr.'}}</span>
            </div>
        </div>

        <div v-if="!isCorporate">

            <div class="row secondary-font__regular" v-if="calculatedContract.Leasing.FuelUsageMonthlyVatPrice != null">
                <div class="col-7">
                    Anslået brændstof
                </div>
                <div class="col-5 text-right">
                    <span v-if="isCorporate">{{formatPrice(calculatedContract.Leasing.FuelUsageMonthlyVatPrice.ExVat)}} kr.</span>
                    <span v-else>{{formatPrice(calculatedContract.Leasing.FuelUsageMonthlyVatPrice.IncVat)}} kr.</span>
                </div>
            </div>
            <div class="row secondary-font__regular" v-if="calculatedContract.Leasing.OwnerFee != null">
                <div class="col-7">
                    Anslået grøn ejerafgift
                </div>
                <div class="col-5 text-right">
                    <span>{{formatPrice(calculatedContract.Leasing.OwnerFee/12) + ' kr.'}}</span>
                </div>
            </div>

            <div class="row no-gutters mt-1">
                <div class="col-12">
                    <span class="primary-font__bold__large color-primary__dark-blue">
                        Totalomkostninger
                    </span>
                </div>
            </div>
            <div class="row secondary-font__regular" v-if="calculatedContract.Leasing.PrePayment != null">
                <div class="col-7">
                    Førstegangsydelse
                </div>
                <div class="col-5 text-right">
                    <span>{{formatPrice(calculatedContract.Leasing.PrePayment) + ' kr.'}}</span>
                </div>
            </div>
            <div class="row secondary-font__regular" v-if="calculatedContract.Leasing.TotalMonthlyPaymentVatPrice != null">
                <div class="col-7">
                    Månedlig ydelse
                </div>
                <div class="col-5 text-right">
                    <span v-if="!isCorporate">{{formatPrice(calculatedContract.Leasing.TotalMonthlyPaymentVatPrice.IncVat) + ' kr.'}}</span>
                    <span v-else>{{formatPrice(calculatedContract.Leasing.TotalMonthlyPaymentVatPrice.ExVat) + ' kr.'}}</span>
                </div>
            </div>
            <div class="row secondary-font__regular" v-if="!isCorporate && calculatedContract.Leasing.MonthlyPaymentAdministrationFee">
                <div class="col-7">
                    Månedlig rateadmin.
                </div>
                <div class="col-5 text-right">
                    <span class="secondary-font__large">{{formatPrice(calculatedContract.Leasing.MonthlyPaymentAdministrationFee.IncVat) + ' kr.'}}</span>
                </div>
            </div>
            <div class="row secondary-font__regular" v-if="!isCorporate && calculatedContract.Leasing.VehicleReturnFee != null">
                <div class="col-7">
                    Tilbageleveringsgebyr
                </div>
                <div class="col-5 text-right">
                    <span>{{formatPrice(calculatedContract.Leasing.VehicleReturnFee.IncVat) + ' kr.'}}</span>
                </div>
            </div>
            <div v-if="isCorporate && calculatedContract.Leasing.VehicleMonthlyPaymentVat != null">
                <div class="row secondary-font__regular">
                    <div class="col-7">
                        Moms
                    </div>
                    <div class="col-5 text-right">
                        <span>{{formatPrice(calculatedContract.Leasing.VehicleMonthlyPaymentVat)}} kr.</span>
                    </div>
                </div>
                <div class="row secondary-font__regular" v-if="calculatedContract.Leasing.VatDeductionPeriods[0] !== undefined">
                    <div class="col-7">
                        Momsfradrag {{calculatedContract.Leasing.VatDeductionPeriods[0].FromMonth  +" - "+ calculatedContract.Leasing.VatDeductionPeriods[0].ToMonth}} mdr.
                    </div>
                    <div class="col-5 text-right">
                        <span>{{formatPrice(calculatedContract.Leasing.VatDeductionPeriods[0].VatDeduction)}} kr.</span>
                    </div>
                </div>
                <div class="row secondary-font__regular" v-if="calculatedContract.Leasing.VatDeductionPeriods[1] !== undefined">
                    <div class="col-7">
                        Momsfradrag {{calculatedContract.Leasing.VatDeductionPeriods[1].FromMonth  +" - "+ calculatedContract.Leasing.VatDeductionPeriods[1].ToMonth}} mdr.
                    </div>
                    <div class="col-5 text-right">
                        <span>{{formatPrice(calculatedContract.Leasing.VatDeductionPeriods[1].VatDeduction)}} kr.</span>
                    </div>
                </div>
                <div class="row secondary-font__regular" v-if="calculatedContract.Leasing.VatDeductionPeriods[2] !== undefined">
                    <div class="col-7">
                        Momsfradrag {{calculatedContract.Leasing.VatDeductionPeriods[2].FromMonth  +" - "+ calculatedContract.Leasing.VatDeductionPeriods[2].ToMonth}} mdr.
                    </div>
                    <div class="col-5 text-right">
                        <span>{{formatPrice(calculatedContract.Leasing.VatDeductionPeriods[2].VatDeduction)}} kr.</span>
                    </div>
                </div>
            </div>
            <div class="row primary-font__bold__large color-primary__blue" v-if="!isCorporate">
                <div class="col-7">
                    I alt ({{$store.getters.getCreatedContract.Contract.Period}}) måneder
                </div>
                <div class="col-5 text-right">
                    <span>{{formatPrice(basketCompletePayment) + ' kr.'}}</span>
                </div>
            </div>

        </div>
        <div v-else>
            <div class="row py-2">
                <div class="col-12 text-center">
                    <span class="secondary-font__small color-secondary__grey-darker font-italic">Alle priser er ekskl. moms.</span>
                </div>
            </div>
        </div>

    </div>

</template>

<script lang="ts">
    import Vue from "vue";
    import { Component, Emit, Inject, Model, Prop, Provide, Watch } from "vue-property-decorator";

    import AbAmount from '../../common/ab_amount';

    @Component({
        name: 'leasing-print-component'
    })
    export default class Print extends Vue {

        @Prop() detailedCar: any;
        @Prop() calculatedContract: any;
        @Prop() basketSelectedItems: any[];
        @Prop() basketCompletePayment: number;
        @Prop() basketEquipmentPriceTotal: number;
        @Prop() basketMonthlyPaymentWithoutExtra: number;
        @Prop() isCorporate: boolean;

        abAmount = new AbAmount();

        formatPrice(value: any) {
            if (value === undefined) {
                return 0;
            }

            return this.abAmount.format(value);
        }

    }

</script>

<style lang="scss">
    @import "../../abdk/styles/legacy/style.scss";

    .print-area {
        display: none;
    }

    @media print {
        .print-area {
            display: block;
        }

        .no-print-area, .dealer-header, .abbreadcrumb, footer, .topnotification__wrapper, .grid-1-1, .grid-1-2, .grid-1-2-c, .footer__content, .menu__wrapper {
            display: none;
        }

    }
</style>
