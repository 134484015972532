<template>

  
  <div class="soft-conversion-block">
    <div class="wrap">
      <div class="block">
        <span class="block-bg"></span>
        <div class="left-side">
          <div class="text-box">
            <h2>{{textTitle}}</h2>
            <div class="text" v-html="textText"></div>
            <ul v-if="textList">
              <li v-for="(item, index) in textList" :key="'acb-list-item-'+index" >{{item.text ? item.text : item}}</li>
            </ul>
          </div>
        </div><div class="right-side">
          <img class="icon" :src="require('@/assets/icons/icon-conversion.svg')" alt="icon" />
          <h2 class="block-title">{{title}}</h2>
          
          <div class="form-wrap" id="article-conversion-form-wrap">
          
            <form action="https://opendo.us1.list-manage.com/subscribe/post-json?u=b83fc74923d4150ebb2826300&id=cd867b1276&f_id=00e26ae2f0&c=?" method="post" id="mc-embedded-subscribe-form-article-conversion" name="mc-embedded-subscribe-form-article-conversion" class="hidden" target="_blank" novalidate>
              <div id="mc_embed_signup_scroll">
                <h2>Subscribe</h2>

                <input type="hidden" name="u" value="b83fc74923d4150ebb2826300">
                <input type="hidden" name="id" value="cd867b1276">
                <!--<input type="hidden" name="f_id" value="00e26ae2f0">-->

                <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                <div class="mc-field-group">
                  <label for="mce-EMAIL">Email  <span class="asterisk">*</span>
                </label>
                  <input type="email" v-model="emailValue" name="EMAIL" class="required email" id="mce-EMAIL">
                </div>
                <div class="mc-field-group">
                  <label for="mce-FNAME">Navn </label>
                  <input type="text" v-model="nameValue" name="FNAME" class="" id="mce-FNAME">
                </div>
                <div class="mc-field-group">
                  <label for="mce-MMERGE5">Firmanavn </label>
                  <input type="text" v-model="corporationValue" name="MMERGE5" class="" id="mce-MMERGE5">
                </div>
                <div class="mc-field-group size1of2">
                  <label for="mce-PHONE">Telefon </label>
                  <input type="text" name="PHONE" class="" v-model="phoneValue" id="mce-PHONE">
                  <span id="mce-PHONE-HELPERTEXT" class="helper_text"></span>                  
                </div>

                <div class="mc-field-group">
                  <label for="mce-MMERGE6">CVR-nummer </label>
                  <input type="text" v-model="cvrValue"  name="MMERGE6" class="" id="mce-MMERGE6">
                  <span id="mce-MMERGE6-HELPERTEXT" class="helper_text"></span>
                </div>
                <div hidden="true"><input type="hidden" name="tags" value="4426069,5780669"></div>
                <div id="mce-responses" class="clear">
                  <div class="response" id="mce-error-response" style="display:none"></div>
                  <div class="response" id="mce-success-response" style="display:none"></div>
                </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b83fc74923d4150ebb2826300_cd867b1276" tabindex="-1" value=""></div>
                <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
                <input type="hidden" name="c" value="mailchimpArticleConversionResponse">     
                

              </div>
            </form>

            
            <div class="double-field">
              <div class="field">
                <InputField :placeholder="'Fornavn'" :name="'name'" :value="nameValue" type="text" theme="nr2" @focus="onFocusNameField" @changed="onChangedNameField" />
                <span class="error-label" v-if="nameErrLabel">{{nameErrLabel}}</span>
              </div>
              <div class="field">
                <InputField :placeholder="'Telefon'" :name="'company'" :value="phoneValue" type="text" theme="nr2" @focus="onFocusPhoneField" @changed="onChangedPhoneField"  />
                <span class="error-label" v-if="phoneErrLabel">{{phoneErrLabel}}</span>
              </div>
            </div>
            <div class="double-field">
              <div class="field">
                <InputField :placeholder="'Email'" :name="'email'" :value="emailValue" type="text" theme="nr2" @focus="onFocusEmailField" @changed="onChangedEmailField" />
                <span class="error-label" v-if="emailErrLabel">{{emailErrLabel}}</span>
              </div>
              <div class="field">
                <InputField :placeholder="'Virksomhed'" :name="'company'" :value="corporationValue" type="text" theme="nr2" @focus="onFocusCorporationField" @changed="onChangedCorporationField"  />
                <span class="error-label" v-if="corporationErrLabel">{{corporationErrLabel}}</span>
              </div>
            </div>
            <div class="field">
              <InputField :placeholder="'CVR-nummer'" :name="'cvr'" :value="cvrValue" type="text" theme="nr2" @focus="onFocusCVRField" @changed="onChangedCVRField"  />
              <span class="error-label" v-if="cvrErrLabel">{{cvrErrLabel}}</span>
            </div>

            <p v-if="mailchimpResponseVisible" :class="'mailchimp-response '+mailchimpResponseType">{{mailchimpResponse}}</p>

            <div class="button-wrap" v-if="button && button.url">
              <a class="button" :href="button.url" :target="button.target" v-on:click="mailchimpSubmit">{{button.title}}</a>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>

</template>

<script>


import gsap from 'gsap';
import eventBus from '../../../eventBus';
import InputField from "@/components/ui/input-field/InputField.vue";

export default {
  props: ['block'],
  components: {
    'InputField' : InputField
  },
  data() {
    return {
      title: "Bliv ringet op",
      
      button: {"url": "#", "title": "Bliv ringet op", "target": null},
      

      textTitle: "Skal vi tage en snak?",
      textText: "<p>Har du brug for rådgivning omkring leasing, eller ved ud allerede hvilken leasing løsning du ønsker, så sidder vi klar til at hjælpe dig.</p>",
      textList: [
        "Vi hjælper med spørgsmål til leasing",
        "Vi kan lave skræddersyede og konkurrencedygtige tilbud",
        "Vi kan opfylde drømmen om den fede firmabil"
      ],
     



      nameErrLabel: '',
      companyErrLabel: '',
      emailErrLabel: '',
      corporationErrLabel: '',
      phoneErrLabel: '',
      cvrErrLabel: '',

      nameValue: '',
      companyValue: '',
      emailValue: '',
      corporationValue: '',
      phoneValue: '',
      cvrValue: '',



      

      mailchimpResponseVisible: false,
      mailchimpResponseType: null,
      mailchimpResponse: null,

      loading: false,

    }
  },

  
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
    window.removeEventListener('mailchimpArticleConversionVueResponse', this.onMailchimpResponse);
  },
  beforeMount(){
    
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
    
  },
  methods: {
    onMailchimpResponse: function(e){

      this.loading = false;

      if(window.mailchimpArticleConversionVueResponse){
        var result = window.mailchimpArticleConversionVueResponse.result;
        var msg = window.mailchimpArticleConversionVueResponse.msg;

        // a1@a.com is an invalid email address and cannot be imported.

        
        if (msg.indexOf("Please enter a value")>=0){
          this.mailchimpResponse = "Please fill out fields";
        }else if (msg.indexOf("This email cannot be added to this list")>=0){
          this.mailchimpResponse = "This email cannot be added to this list";
        }else{
          this.mailchimpResponse = msg;
        }

        this.mailchimpResponseVisible = true;
        
        this.mailchimpResponseType = result;
        // alert(result+" "+msg);
        if (result == "error"){
          
          // this.$refs.emailField.showError(msg);
          // this.success = true;
        }
        if (result == "success"){

          this.mailchimpResponse = "Tak for din henvendelse. Vi vil ringe dig op hurtigst muligt.";
          this.success = true;
        }




      }
      // alert(window.mailchimpArticleConversionVueResponse);
      // alert("yas");
    },
    prepareContent: function(){
      console.log(this.block);
      if (this.block){
        if(this.block.title){
          this.textTitle = this.block.title;
        }
        if(this.block.description){
          this.textDescription = this.block.description;
        }
        if(this.block.list){
          this.textList = this.block.list;
        }
        // this.description = this.block.description.value;
      }
      

      
    },
    onFocusNameField(){
      this.mailchimpResponseVisible = false;
    },
    onChangedNameField(v){
      this.nameValue = v;
    },
    onFocusCompanyField(){
      this.mailchimpResponseVisible = false;
    },
    onChangedCompanyField(v){
      this.companyValue = v;
    },
    onFocusEmailField(){
      this.mailchimpResponseVisible = false;
    },
    onChangedEmailField(v){
      this.emailValue = v;
    },

    onFocusCorporationField(){
      this.mailchimpResponseVisible = false;
    },
    onChangedCorporationField(v){
      this.corporationValue = v;
    },

    onFocusCVRField(){
      this.mailchimpResponseVisible = false;
    },
    onChangedCVRField(v){
      this.cvrValue = v;
    },

    onFocusPhoneField(){
      this.mailchimpResponseVisible = false;
    },
    onChangedPhoneField(v){
      this.phoneValue = v;
    },
    
    
    mailchimpSubmit(e){
      e.preventDefault();
      if (!this.loading){
        this.loading = true;
        this.mailchimpResponseVisible = false;
        document.querySelector("#article-conversion-form-wrap form input[type='submit']").click();
      }
    },
    getDimensions() {
      // this.width = document.documentElement.clientWidth;
      // this.height = document.documentElement.clientHeight;

      
      // this.newsDirection = 'vertical';
      // if(this.width< 1000){
      //   this.newsDirection = 'horizontal';
      // }
    
    },
  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );

    window.addEventListener('mailchimpArticleConversionVueResponse', this.onMailchimpResponse);

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
form.hidden{
  display: none;
}
.soft-conversion-block{
  margin-top: 124px;
  margin-bottom: 124px;
  padding-top: 64px;
  padding-bottom: 64px;
  
  .block{
    position: relative;
    .block-bg{
      display: block;
      position: absolute;
      width: 100%;
      top: 0px;
      bottom: 66px;
      background: rgba(105, 189, 182, 0.09);
      border-radius: 20px;
      z-index: 0;
    }
  }
  .text-box{
    padding-top: 82px;
    padding-left: 60px;
    padding-right: 90px;
    padding-bottom: 166px;
    h2{
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
      color: #1F4743;
      margin-bottom: 16px;
    }
    .text{
      margin-bottom: 52px;
      ::v-deep p{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #4C6C69;
      }
    }
    
    ul{


      
      li{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 135%;
        color: #4C6C69;
        margin-bottom: 21px;

        padding-left: 25px;
        position: relative;
        font-style: normal;

        &::before{
            content: "";
            display: block;
            position: absolute;
            left: 8px;
            top: 0.5em;
            margin-top: 1px;
            width: 5px;
            height: 5px;
			      border-radius: 3px;
            background-color: #69BDB6;
        }
        &:last-child{
          margin-bottom: 0px;
        }
      }
    }
  }
  .block-title{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #1F4743;
    margin-bottom: 20px;
  }
  .block-description{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #4C6C69;
  }
  .block{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .right-side{
    background: #FFFFFF;
    box-shadow: 0px 11px 41px -10px rgba(31, 72, 68, 0.09);
    border-radius: 20px;
    margin-right: 54px;
    width: 508px;
    padding: 48px;
    box-sizing: border-box;
    z-index: 1;
    position: relative;
    margin-top: 50px;
    .icon{
      margin-bottom: 24px;
      margin-left: -20px;
    }
  }
  .left-side{
    position: relative;
    z-index: 1;
    display: block;
    width: calc(100% - 444px - 54px);
  }
  .button-wrap{
    text-align: center;
    margin-top: 32px;
  }
  .form-wrap{
    margin-top: 32px;
  }

  .field{
    margin-bottom: 16px;
  }
  .double-field{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .field{
      width: calc(50% - 8px);
      
    }
  }


  .mailchimp-response{
    &.error{
      color: #DF4343;
    }
    &.success{
      color: #69BDB6;
    }
    &.info{
      color: #69BDB6;
    }
    max-width: 570px;
    font-size: 12px;
    padding-left: 4px;
    display: block;
    text-align: left;
    margin-top: 4px;
  }
}


@media (max-width: 1100px) {
  .soft-conversion-block{
    .text-box{
       padding-right: 60px;
    }
    .right-side{
      width: 480px;
    }
  }
}

@media (max-width: 1000px) {
  .soft-conversion-block{
   
    .block{
      display: block;
    }
    .text-box{
       padding: 48px;
    }
    .form-wrap{
      max-width: 482px;
      margin-left: auto;
      margin-right: auto;
    }
    .right-side{
      margin: 0px;
      margin-left: 32px;
      margin-right: 32px;
      width: auto;
      text-align: center;
    }
    .left-side{
      display: block;
      width: 100%;
      margin: 0px;
    }

    .block-title{
      font-size: 24px;
      line-height: 130%;
      margin-bottom: 12px;
    }
    .block-description{
      font-size: 16px;
      line-height: 135%;
    }
  }
}

@media (max-width: #{$screen-tablet}) {
  .soft-conversion-block{
    margin-top: 96px;
    margin-bottom: 96px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

}
@media (max-width: 700px) {
  .soft-conversion-block{
   
    
    .text-box{
       padding: 48px;
    }
    .right-side{
      margin: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }
    .left-side{
      display: block;
      width: 100%;
      margin: 0px;
    }

  }
}

@media (max-width: #{$screen-mobile}) {
 .soft-conversion-block{
    margin-top: 112px;
    margin-bottom: 112px;
    padding-top: 0px;
    padding-bottom: 0px;
    .text-box{
      padding: 32px 20px;
      padding-bottom: 48px;

      h2{
        font-size: 32px;
        line-height: 130%;
      }
      .text{
        margin-bottom: 24px;
      }
      p{
        font-size: 16px;
        line-height: 24px;
      }
      ul{        
        li{
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 12px;
        }
      }
    }
    
    .right-side{
      padding: 32px 20px;
    
    }
    .left-side{

    }
  }
}

</style>