<template>
  <div class="main about-page-main">
    

    <section class="section fade-on-enter hero-section">
      <div class="wrap">
        <div class="flex my-flex-row my-flex-nowrap flex-s3">
          <div v-if="heroBlock.image" class="image">
            <img :src="heroBlock.image.sizes.large" :alt="heroBlock.image.info ? heroBlock.image.info.alt : ''" />
          </div>
          <div class="side">
            <h1 class="h1-1 title" >{{heroBlock.title}}</h1>
            <div class="content" v-html="heroBlock.content"></div>
            <a class="button" :href="'/faq'" v-on:click="onFAQButtonClick" >{{heroBlock.button_title}}</a>
            <!--<router-link :class="'button'" :to="'/faq'" >{{heroBlock.button_title}}</router-link>-->
          </div>
        </div>
      </div>
    </section>

    <section class="section fade-on-enter contact-options-section">
      <div class="wrap">
        <div class="container flex my-flex-row my-flex-nowrap flex-s3">
          <h2 class="h2-1 big-title">{{optionsBlock.title}}</h2>
          <div class="options flex my-flex-row my-flex-nowrap flex-s3">
            <div class="option" v-for="(item, index) in optionsBlock.options" :key="'options-'+index" >
              <p class="title">{{item.title}}</p>
              <div class="content" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section fade-on-enter employees-section">
      <div class="wrap">
        <h2 class="h1-1 section-title">{{employeesBlock.title}}</h2>
        <form autocomplete="off" method="post" action="" v-on:submit.prevent>
          <div class="filter-bar flex my-flex-row my-flex-nowrap flex-s1">
            <div class="search-wrap">
              <span class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.399 14.8449C11.4636 14.9687 11.5444 15.0873 11.6388 15.1978L17.4492 21.9808C18.1469 22.7971 19.378 22.8914 20.1941 22.1937L21.32 21.2294C22.1362 20.5317 22.2306 19.3005 21.5329 18.4845L15.7225 11.7015C15.6281 11.591 15.5257 11.494 15.4126 11.4078C16.7191 8.90788 16.4605 5.75898 14.5129 3.48513C11.9591 0.503105 7.46865 0.155654 4.48672 2.70927C1.50469 5.26308 1.15445 9.75354 3.70821 12.7355C5.65582 15.0091 8.72684 15.7525 11.399 14.8447L11.399 14.8449ZM14.373 13.1154L13.247 14.0798C12.8188 14.4462 12.7703 15.0926 13.1339 15.521L18.1526 21.38C18.5162 21.8056 19.1654 21.8567 19.5909 21.4905L20.7169 20.5261C21.1451 20.1625 21.1936 19.5133 20.83 19.0877L15.8113 13.2288C15.4477 12.8005 14.7985 12.752 14.373 13.1156V13.1154ZM5.08754 3.41249C2.49332 5.63482 2.19176 9.53797 4.41409 12.1322C6.63382 14.729 10.5396 15.0308 13.1338 12.8083C15.728 10.5886 16.0296 6.6828 13.8099 4.08858C11.5876 1.49436 7.6844 1.18996 5.0873 3.41249H5.08754Z" fill="#1F4743" stroke="#1F4743" stroke-width="0.75"/>
                </svg>
              </span>
              <input v-model="search_text" class="search-field" type="text" name="search" placeholder="Søg efter medarbejder" />
            </div>
            <div class="filter-wrap noselect">
              <select-field :options="filterOptions" size="big" v-on:selected="filterSelected" />
            </div>
          </div>
        </form>
        <div class="employees-wrap">
          <div v-for="(group, index) in employeesOutput" :key="'employee-group-'+index" v-if="group.employees.length>0">
            <p class="h2-1 category-title" >{{group.name}}</p>
            <div class="employees-list flex my-flex-row my-flex-wrap flex-s2">  
              <div class="item" v-for="item in group.employees" :key="'employee-'+item.ID" >
                <div class="image">
                  <img :src="item.thumbnail.sizes.medium_large" :alt="item.thumbnail.info ? item.thumbnail.info.alt : ''">
                </div>
                <p class="position">{{item.position}}</p>
                <p class="name">{{item.name}}</p>
                <p class="email">{{item.email}}</p>
                <p class="phone">{{item.phone}}</p>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>




    <span v-if="cmsPageData && !cmsPageData.loaded"></span>
  </div>
</template>
<script>


import eventBus from '../../eventBus';

export default {
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      thisSlug: "",
      jobsSlug: "jobs",
      heroBlock: { title: null },
      employeesBlock: { title: null },
      optionsBlock: { title: null },
      employees: null,
      employeesOutput: null,
      search_text: null,
      selectedFilterVal: null,
      filterOptions: null
    }
  },
  beforeMount(){
    // alert(footerData.fields.copyright);
    // this.copyright = "b";
  },
  created(){
    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {
        
        this.thisSlug = this.cmsPageData.page.url;
        this.heroBlock = this.cmsPageData.page.contacts.hero;
        this.employeesBlock = this.cmsPageData.page.contacts.employees;
        this.employees = this.cmsPageData.page.contacts.employees.employees_list;
        this.optionsBlock = this.cmsPageData.page.contacts.contact_options;
        
        this.filterOptions = this.cmsPageData.page.contacts.employees.employee_tags;
        
        for(var i=0; i<this.filterOptions.length; i++){
          this.filterOptions[i].value = this.filterOptions[i].term_id; 
        }
        this.filterOptions.unshift({"name": "Alle Områder", "value": 0});
       

        this.prepareEmployees();
      }
    );

    this.$watch(
      () => this.search_text,
      (toParams, previousParams) => {
        this.prepareEmployees();
      }
    );

  },
  mounted(){
    
  },
  methods: {
    onFAQButtonClick: function(e){
      e.preventDefault();


      var key = "menuSwitch";
      var savedSwitchState = sessionStorage.getItem(key);
      if (savedSwitchState){
        this.$router.push({ path: '/'+savedSwitchState+'/faq', hash: '#faq' })
      }else{
        eventBus.$emit('open-ask-popup', {"type": "faq", "deeplink": "faq"});
      }

      
      

      // var items = document.querySelectorAll(".nav-item");
      // for(var i=0; i<items.length; i++){
      //   if (items[i].getAttribute("href").includes("/faq")){
      //     items[i].click();
      //     break;
      //   }
      // }

    },
    prepareEmployees: function(){
      var ret = [];
      for (var i=0; i<this.employeesBlock.employee_tags.length; i++){
        var item = this.employeesBlock.employee_tags[i];

        if (this.selectedFilterVal && item.term_id != this.selectedFilterVal){
          //Skip category
        }else{
          var emps = [];
          for(var j=0; j<this.employees.length; j++){
            var emp = this.employees[j];
            for(var l=0; l<emp.tags.length; l++){
              if (emp.tags[l].term_id == item.term_id){

                //Search Part
                if (this.checkEmployeeSearchText(emp)){
                  emps.push(emp);
                  break;
                }
                
              }
            }
          }
          ret.push({"name" : item.name, "term_id" : item.term_id, "employees": emps});
        }
      }
      this.employeesOutput = ret;
    },

    checkEmployeeSearchText: function(employee){
      var s = this.search_text;
      var found = !s;
      var text = employee.name.toLowerCase()+" "+employee.email.toLowerCase()+" "+employee.phone.toLowerCase();
      if (text.indexOf(s)>=0){
        found = true;
      }

      return found;
    },

    filterSelected: function(val){
      this.selectedFilterVal = val;

      this.prepareEmployees();
    }
    
  }
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.main{
  // height: 600px;
}

.hero-section{
  margin-bottom:80px;
  margin-top:60px;
  .image{
    width: 50%;
    border-radius: 20px;
    overflow: hidden;
    line-height: 0px;
    padding-right: 60px;
    box-sizing: border-box;
    img{
      border-radius: 20px;
    }
  }
  .side{
    padding-top: 36px;
    width: 50%;
    padding-left: 20px;
    box-sizing: border-box;


    .title{
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
    }
    .content{
      max-width:430px;

      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #4C6C69;
    }
    .button{
      margin-top: 60px;
    }
  }
}

.contact-options-section{
  margin-bottom: 80px;
  .container{
    // background: $color-super-light-green;
    margin: 0px;
    background: rgba(105, 189, 182, 0.09);
    border-radius: 20px;  
    padding: 40px;
    padding-bottom: 80px;
  }
  .big-title{
    max-width: 369px;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    width: 369px;
  }
  .options{
    width: 100%;
    width: calc(100% - 369px);
    .option{
      width: 50%;
      padding-right: 70px;
      box-sizing: border-box;
    }
    .title{
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: #1F4743;
      margin-bottom: 16px;
    }
    .content{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      color: #1F4743;

    
      
    }
    .content ::v-deep p{
      margin-top: 16px;
    }
  }
}


.employees-section{
  margin-left: 20px;
  margin-right: 20px;
  background: rgba(105, 189, 182, 0.09);
  border-radius: 20px;
  padding-top: 96px;
  padding-bottom: 1px;
  margin-bottom: 20px;

  .wrap{
    max-width: 1060px;
  }
  .section-title{
    text-align: center;

    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;

    margin-bottom: 40px;
  }

  .filter-bar{
    margin-bottom: 64px;
    .search-wrap{
      position: relative;
      width: 70%;
      padding-right: 10px;
      box-sizing: border-box;
      .search-field{
        padding-left: 60px;
        width: 100%;
      }
      .icon{
        pointer-events: none;
        position: absolute;
        top: 50%;
        margin-top: -12px;
        left: 20px;
      }
    }
    .filter-wrap{
      position: relative;
      width: 30%;
      padding-left: 10px;
      box-sizing: border-box;
      
    }
  }
}



.employees-wrap{
  .category-title{
    font-weight: 700;
    font-size: 28px;
    line-height: 130%;
    text-align: center;
    margin-bottom: 40px;
  }
}
.employees-list{
  margin-left: -10px;
  margin-right: -10px;
  .item{
    width: calc(25% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 60px;
    .image{
      height: 150px;
      border-radius: 20px;
      overflow: hidden;
      margin-bottom: 16px;
      img{
        border-radius: 20px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
    }
    .position{
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 120%;
      color: #69BDB6;
      margin-bottom: 2px;
    }
    .name{
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: #1F4743;
      margin-bottom:20px;
    }
    .email{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      color: #1F4743;
      opacity: 0.8;
    }
    .phone{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      color: #1F4743;
      opacity: 0.8;
    }
  }
}



@media (max-width: 1000px) {
  .hero-section{
    .image{
      width: 35%;
      height: 400px;
      img{
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }
    .side{
      width: 65%;
      .title{
        
      }
      .content{
        
        ::v-deep p{
        
        }
      }
      .button{
        
      }
    }
  }

  .contact-options-section{

    .container{
      display: block;
      padding-bottom: 60px;
    }
    .big-title{
      max-width: none;
      margin-bottom: 40px;
      width: auto;
    }
    .options{
      width: 100%;
      .option{
        padding-right: 56px;
      }
      .title{
      
      }
      .content{
      
      }
      .content ::v-deep p{
      
      }
    }
  }

  .employees-list{
    .item{
      width: calc(33.33% - 20px);
    }
  }

}
@media (max-width: #{$screen-tablet}) {
  .hero-section{
    .flex{
      // display: block;
      -ms-flex-direction: column;
		  flex-direction: column;
    }
    .image{
      width: 100%;
      padding: 0px;
      height: auto;
      img{
        height: auto;
      }
    }
    .side{
      width: 100%;
      .title{
        
      }
      .content{
        
        ::v-deep p{
        
        }
      }
      .button{
        
      }
    }
  }

  
  .employees-list{
    .item{
      width: calc(50% - 20px);
    }
  }

   .employees-section{
   

    .filter-bar{
      display: block;
      .search-wrap{
        padding-right: 0px;
        width: 100%;
      }
      .filter-wrap{
        padding-left: 0px;
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}

@media (max-width: #{$screen-mobile}) {
  .hero-section{
    .side{
      .title{
        margin-bottom: 12px;
        font-size: 32px;
        line-height: 130%;
      }
      .content{
        font-size: 16px;
        line-height: 130%;
      }
    }
  }
  .contact-options-section{

    .container{
      display: block;
      padding-bottom: 60px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 40px;
    }
    .big-title{
      max-width: none;
      margin-bottom: 0px;
      width: auto;

      font-weight: 700;
      font-size: 28px;
      line-height: 120%;
    }
    .options{
      display: block;
      .option{
        padding-right: 0px;
        margin-top: 40px;
      }
      .title{
        font-size: 18px;
        line-height: 133%;
      }
      .content{
      
      }
      .content ::v-deep p{
      
      }
    }
  }



  .employees-section{
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 56px;
    .section-title{
      font-weight: 700;
      font-size: 32px;
      line-height: 130%;
    }
  }

  .employees-wrap{
    .category-title{
      font-size: 24px;
      line-height: 130%;
      margin-bottom: 20px;
    }
  }
  

}

@media (max-width: 500px) {
  .employees-list{

    .item{
      width: 100%;
      max-width: 234px;
      margin-left: auto;
      margin-right: auto;  
      text-align: center;
      margin-bottom: 32px;

      .name{
        font-size: 18px;
        line-height: 130%;
        margin-bottom: 12px;
      }
      .email{
        font-size: 16px;
        line-height: 135%;
      }
      .phone{
        font-size: 16px;
        line-height: 135%;
      }
    }
  }
 
}


</style>