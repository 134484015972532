import { Component, Vue } from 'vue-property-decorator';

import template from './PinField.vue';

@Component({
  mixins: [template],
  props: ['size', 'name', 'value', 'customClass']
})
export default class PinField extends Vue {

}