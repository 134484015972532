<template>
  <div class="logos-slider flex my-flex-row my-flex-wrap flex-s1">

    <swiper ref="partnersSlider"
      :slides-per-view="slidesPerView"
      :space-between="slidesSpacing"
      :loop="false"
      slideVisibleClass = "visible-slide"
      :watchSlidesVisibility = "true"
      class="partners-slider"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: true,
      }"
      :navigation="{
        prevEl: '.prev',
        nextEl: '.next',
      }"
      :pagination="withPagination ? { clickable: true, el: '.pagination' } : false"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="(slide, index) in slidesData" :key="'partners-slide-'+index"
        class="slide"
        :class="{test_2: false}"
      >

        <div class="logo-slide flex my-flex-wrap my-flex-row flex-s2">
            <div class="logo-item" v-for="logo in slide" :key="'partners-logo-'+logo.logo.ID" :class="logo.logo_green && logo.logo_green.url ? ' with-hover': ''" >
              <a target="_blank" v-if="logo.link" :href="logo.link" >
                <img :src="logo.logo.url" :alt="logo.logo.info ? logo.logo.info.alt : ''" @load="onLogoImgLoad"  />
                <img class="green" v-if="logo.logo_green && logo.logo_green.url" :src="logo.logo_green.url" :alt="logo.logo.info ? logo.logo.info.alt : ''" @load="onLogoImgLoad"  />
              </a>
              <span v-else>
                <img :src="logo.logo.url" :alt="logo.logo.info ? logo.logo.info.alt : ''" @load="onLogoImgLoad"  />
                <img class="green" v-if="logo.logo_green && logo.logo_green.url" :src="logo.logo_green.url" :alt="logo.logo.info ? logo.logo.info.alt : ''" @load="onLogoImgLoad"  />
              </span>
            </div>
        </div>
      </swiper-slide>

      <div class="navigation-wrap">
        <div class="pagination"></div>
        <div class="buttons-wrap">
          <div class="prev" >

            <svg v-if="smallButtons" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1" y="1" width="30" height="30" rx="15" stroke="currentColor" stroke-width="2"/>
              <path d="M17.9995 10.5833L11.687 15.6883C11.6429 15.7274 11.6077 15.7754 11.5835 15.8291C11.5594 15.8828 11.5469 15.9411 11.5469 16C11.5469 16.0589 11.5594 16.1171 11.5835 16.1708C11.6077 16.2246 11.6429 16.2726 11.687 16.3116L17.9995 21.4166" stroke="currentColor"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg v-else width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24.3994 15.5L16.8244 21.626C16.7715 21.6729 16.7292 21.7305 16.7002 21.795C16.6712 21.8594 16.6562 21.9293 16.6562 22C16.6562 22.0707 16.6712 22.1406 16.7002 22.205C16.7292 22.2695 16.7715 22.3271 16.8244 22.374L24.3994 28.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <circle cx="22" cy="22" r="20" stroke="currentColor" stroke-width="2" />
            </svg>

          </div>
          <div class="next" >
            <svg v-if="smallButtons" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1" y="1" width="30" height="30" rx="15" stroke="currentColor" stroke-width="2"/>
              <path d="M14.0005 10.5833L20.313 15.6883C20.3571 15.7274 20.3923 15.7754 20.4165 15.8291C20.4406 15.8828 20.4531 15.9411 20.4531 16C20.4531 16.0589 20.4406 16.1171 20.4165 16.1708C20.3923 16.2246 20.3571 16.2726 20.313 16.3116L14.0005 21.4166" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg v-else width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.6006 15.5L27.1756 21.626C27.2285 21.6729 27.2708 21.7305 27.2998 21.795C27.3288 21.8594 27.3438 21.9293 27.3438 22C27.3438 22.0707 27.3288 22.1406 27.2998 22.205C27.2708 22.2695 27.2285 22.3271 27.1756 22.374L19.6006 28.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <circle cx="22" cy="22" r="20" stroke="currentColor" stroke-width="2" />
            </svg>

          </div>
        </div>
      </div>

      <!--<div class="mask mask-right"></div>
      <div class="mask mask-left"></div> -->
    </swiper>


  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';

import { Navigation, Pagination, Autoplay } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

// Import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination, Autoplay])


export default {
  props: ['logos', 'withPagination', 'smallButtons', 'logosInSlide'],
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      modules: [Pagination]
    }
  },
  data() {
    return {
      slidesData: [],
      logosPerSlide: 8,
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      slidesPerView: 1,
      slidesSpacing: 0,
      loadedLogos: 0,
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());

  },
  mounted(){
    this.prepareSlides();

    var th = this;
    // setTimeout(function(){
    //   th.logosPerSlide = 2;
    // }, 3000);

  },
  created(){
    this.$watch(
      () => this.logos,
      (toParams, previousParams) => {
        this.prepareSlides();
      }
    );
    this.$watch(
      () => this.logosPerSlide,
      (toParams, previousParams) => {
        this.prepareSlides();
      }
    );
    this.$watch(
      () => this.logosInSlide,
      (toParams, previousParams) => {
        this.logosPerSlide = this.logosInSlide;
        this.prepareSlides();
      }
    );
    if (this.logosInSlide){
      this.logosPerSlide = this.logosInSlide;
    }
  },
  methods: {
    prepareSlides: function(){
      if (this.logos){
        var perSlide = this.logosPerSlide;
        var ret = [];
        for(var i=0; i<this.logos.length; i++){
          var item = this.logos[i];
          var slide = Math.floor((i) / perSlide);
          if (!ret[slide]){
            ret[slide] = [];
          }
          ret[slide].push(item);
        }
        this.slidesData = ret;
        this.$nextTick(() => {
          if (this.$refs.partnersSlider.$el.swiper.update){
            this.$refs.partnersSlider.$el.swiper.update();
          }
        })
      }

    },
    getDimensions() {
      // this.width = document.documentElement.clientWidth;
      // this.height = document.documentElement.clientHeight;

      // this.slidesPerView = 3;
      // if(this.width< 1000){
      //   this.slidesPerView = 2;
      // }
      // if(this.width< 500){
      //   this.slidesPerView = 1;
      // }
      // // if ()
    },
    onSwiper (swiper) {
      // console.log(swiper)
    },
    onSlideChange () {
      // console.log('slide change')
    },
    onLogoImgLoad (){
      this.loadedLogos++;

      if (this.logos){
        if (this.loadedLogos >= this.logos.length){
          var th = this;

            if (th.$refs.partnersSlider.$el.swiper.update){
              th.$refs.partnersSlider.$el.swiper.update();
              th.$refs.partnersSlider.$el.swiper.update();
            }

        }
      }
    }
  },

}
</script>
<style lang="scss">
@import "~@/styles/variables.scss";
.logos-slider{
  .swiper-container{
    width: 100%;
    width: calc(100% + 96px);
    max-width: none;
    margin-left: -48px;
    margin-right: -48px;
  }
  .partners-slider{
    margin-top: 60px;
  }
  .logo-slide{
    padding-left: 48px;
    padding-right: 48px;
    box-sizing: border-box;
  }
  .logo-item{
    // display: inline-block;
    // vertical-align: middle;
    // height: 40px;
    // margin-right: 64px;
    opacity: 0.48;
    width: 25%;
    height: 64px;
    padding: 0px 12px;
    margin-bottom: 24px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    transition: all 300ms;
    img{
      height: 100%;
      width: auto;
      object-fit: contain;
      box-sizing: border-box;
      transition: all 300ms;
      opacity: 1;
      &.green{
        position: absolute;
        opacity: 0;
        left: 12px;
        right: 12px;
        top: 0px;
        width: calc(100% - 24px);
      }
    }
    &.with-hover{
      img{
        opacity: 0;
        &.green{
          opacity: 1;
        }
      }
    }
    &.with-hover:hover{
      opacity: 1;
      img{
        opacity: 1;
        &.green{
          opacity: 0;
        }
      }
    }
  }


  .swiper-container{
    // margin-left: 85px;
    // margin-right: 85px;
    position: relative;
    // padding-left: 85px;
    // padding-right: 85px;
    // padding-top: 24px;
    // -webkit-mask-image: -webkit-gradient(linear, 94% 0%, 100% 0%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));

    .mask{
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: 84px;

      display: block;
      z-index: 10;

    }
    .mask-right{
      right: 0px;
      // background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 20%, #FFFFFF 40%, #FFFFFF 100%);
      background: linear-gradient(-90deg,
        rgb(255,255,255) 0%,
        rgba(255,255,255, 0.738) 19%,
        rgba(255,255,255, 0.541) 34%,
        rgba(255,255,255, 0.382) 47%,
        rgba(255,255,255, 0.278) 56.5%,
        rgba(255,255,255, 0.194) 65%,
        rgba(255,255,255, 0.126) 73%,
        rgba(255,255,255, 0.075) 80.2%,
        rgba(255,255,255, 0.042) 86.1%,
        rgba(255,255,255, 0.021) 91%,
        rgba(255,255,255, 0.008) 95.2%,
        rgba(255,255,255, 0.002) 98.2%,
        rgba(255,255,255, 0) 100%
      );


    }
    .mask-left{
      left: 0px;
      // background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 20%, #FFFFFF 40%, #FFFFFF 100%);
      background: linear-gradient(90deg,
        rgb(255,255,255) 0%,
        rgba(255,255,255, 0.738) 19%,
        rgba(255,255,255, 0.541) 34%,
        rgba(255,255,255, 0.382) 47%,
        rgba(255,255,255, 0.278) 56.5%,
        rgba(255,255,255, 0.194) 65%,
        rgba(255,255,255, 0.126) 73%,
        rgba(255,255,255, 0.075) 80.2%,
        rgba(255,255,255, 0.042) 86.1%,
        rgba(255,255,255, 0.021) 91%,
        rgba(255,255,255, 0.008) 95.2%,
        rgba(255,255,255, 0.002) 98.2%,
        rgba(255,255,255, 0) 100%
      );


    }

    .prev,
    .next{
      margin-top: 36px;
      position: relative;
      margin-right: 10px;
      color: #69BDB6;
      z-index: 20;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      circle,path, rect{
        transition: all 300ms;
        -webkit-transition: all 300ms;
        stroke: #69BDB6;
      }
      &.swiper-button-disabled{

        pointer-events: none;
        circle{
          opacity: 0.12;
        }
        path{
          opacity: 0.24;
        }
        rect{
          opacity: 0.24;
        }
      }
      &:hover{

        circle{
          opacity: 1;
          stroke: #1F4743;
        }
        path{
          opacity: 1;
          stroke: #1F4743;
        }
        rect{
          opacity: 1;
          stroke: #1F4743;
        }
      }
    }
    .prev{
      margin-left: 48px;

    }
  }
}

@media (max-width: 1200px) {

}


@media (max-width: #{$screen-tablet}) {
	.logos-slider{
		.partners-slider{
			margin-top: 28px;
		}
		.swiper-container .prev,
		.swiper-container .next{
			margin-top: 28px;
		}
	}
}
@media (max-width: 400px) {
  .logos-slider{
		.logo-item{
      width: 33.33%;
    }
	}
}
</style>