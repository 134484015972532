import ConfigModel from "./configModel";

export default {
    SET_NAME(state: ConfigModel, name: string) {
        state.name = name;
    },
    SET_LEASINGPRO_USER_PRESENT(state: ConfigModel, userPresent: boolean) {
        state.userPresent = userPresent;
    },
    SET_LEASINGPRO_ADMIN_DASHBOARD(state: ConfigModel, adminDashboard: boolean) {
        state.adminDashboard = adminDashboard;
    },
    SET_LEASINGPRO_INITIAL_CARSEARCH(state: ConfigModel, initialCarSearch: string) {
        state.initialCarSearch = initialCarSearch;
    },
    SET_LEASINGPRO_ADMIN: (state: ConfigModel, adminData: Object) => {
        state.adminData = Object.assign({}, adminData);
    },
    SET_BUSINESS_CUSTOMER: (state: ConfigModel, businessCustomer: Object) => {
        state.businessCustomer = Object.assign({}, businessCustomer);
    },
    SET_EPI_ROUTING(state: ConfigModel, epiRouting: []) {
        state.epiRouting = epiRouting;
    },
    SET_USABILLA_COUNT(state: ConfigModel) {
        state.usabilla.count = state.usabilla.count + 1;
    },
    SET_USABILLA_MOUNTED(state: ConfigModel) {
        state.usabilla.mounted = state.usabilla.mounted + 1;
    },
}
