<template>

  
  <div class="test-area-block" ref="testAreaBlock">
    <div class="block" :style="blockHeight ? 'min-height: '+blockHeight+'px' : '' ">
      <div class="wrap">
        <div class="left-side">    
          <h2 class="block-title">{{title}}</h2>
          <div class="block-description" v-html="description"></div>
          <div class="button-wrap" v-if="button && button.url">
            <a class="secondary-button" :href="button.url" :target="button.target">{{button.title}}</a>
          </div>
        </div>
      </div>
      <div class="right-side">
        <div class="cars-slider-wrap" :style="blockHeight ? 'min-height: '+blockHeight+'px' : '' ">
          <div class="bg">
            <svg width="826" height="387" viewBox="0 0 826 387" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M330 71C355.076 57.3938 443.458 0 561.979 0C680.5 0 826 57.5 826 203C826 254 805 380.5 657 386C509 391.5 452.587 346.872 253.869 363.643C55.1513 380.414 -52.7359 238.156 26.2899 154.533C92.0001 85 166.768 159.57 330 71Z" fill="#E8F5F4"/>
            </svg>
          </div>
          
          <div class="cars-images" :class="'cars-images-'+index+' count-'+slide.carImages.length" v-for="(slide, index) in slider" :key="'cars-images-list-1-'+index">
            <div class="car-image" v-for="(item, index2) in slide.carImages" :key="'cars-images-list-1-'+index+'-'+index2">
              <img class="image" :src="item" alt="car image" @load="onImgLoad" />
            </div>            
          </div>
          


          <swiper
            ref="newsSlider"
            :slides-per-view="slidesPerView"
            :space-between="slidesSpacing"
            :loop="false"
            slideVisibleClass = "visible-slide"
            :watchSlidesVisibility = "true"
            :direction = "direction ? direction : 'horizontal'"
            :autoHeight = "false"
            :observer = "true"
            :observeParents = "true"
            :class="'slider'"
            :navigation="{
              prevEl: '.prev',
              nextEl: '.next',
            }"
            
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            
          >
            <swiper-slide
              v-for="(slide, index) in slider" :key="'cars-sb-list-1-'+index"
              class="slide"
              
              :class="{test_2: false}"
            >
              
              <div class="card" :data-index="index">
                

                  <div class="content">
                    <p class="date">{{slide.article.date2}}</p>
                    <p class="title">{{slide.article.post_title}}</p>
                    <p class="description" v-html="slide.article.short_excerpt"></p>
                    <router-link class="button" :to="'/'+newsSlug+'/'+slide.article.post_name" >Læs artikel</router-link>
                  </div>
                
              </div>
            </swiper-slide>


            <div class="navigation" :class="totalSlides <= 1 ? ' hidden' : ''">
              <div class="prev" >
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.3994 15.5L16.8244 21.626C16.7715 21.6729 16.7292 21.7305 16.7002 21.795C16.6712 21.8594 16.6562 21.9293 16.6562 22C16.6562 22.0707 16.6712 22.1406 16.7002 22.205C16.7292 22.2695 16.7715 22.3271 16.8244 22.374L24.3994 28.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <circle cx="22" cy="22" r="20" stroke="currentColor" stroke-width="2" />
                </svg>
              </div>
              <div class="next" >
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.6006 15.5L27.1756 21.626C27.2285 21.6729 27.2708 21.7305 27.2998 21.795C27.3288 21.8594 27.3438 21.9293 27.3438 22C27.3438 22.0707 27.3288 22.1406 27.2998 22.205C27.2708 22.2695 27.2285 22.3271 27.1756 22.374L19.6006 28.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <circle cx="22" cy="22" r="20" stroke="currentColor" stroke-width="2" />
                </svg>
              </div>
            </div>
            
            <!--<div v-if="!noSideGradient" class="mask mask-right" :class="whiteSideGradient? ' white': ''"></div>
            <div v-if="!noSideGradient" class="mask mask-left" :class="whiteSideGradient? ' white': ''"></div>-->
          </swiper>
         
        </div>
      </div>
    </div>
  </div>

</template>

<script>


import {gsap, Quad} from 'gsap';
import eventBus from '../../../eventBus';
import { Navigation, Pagination, Scrollbar } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination, Scrollbar])

export default {
  props: ['block'],
  components: {
    Swiper,
    SwiperSlide,
    Scrollbar
  },
  setup() {
    return {
      modules: [Pagination, Scrollbar]
    }
  },
  data() {
    return {
      title: "TestOmrådet",
      description: "<p>Hvor fede er de forskellige firmabiler i forhold til hinanden? Hvor meget køreglæde og brugsværdi får du for pengene? Det kan du blive klogere på her i TestOmrådet, som er fyldt med spændende dueller, interessante sammenligninger og gruppetests.</p>",
      button: {"url": "#", "title": "Tilmeld nyhedsbrev", "target": null},
      slider: [],
      newsSlug: "nyheder",
      slidesPerView: 1,
      direction: null,
      slidesSpacing: 20,
      blockHeight: null,
      activeSlide: 0,
      imgsLoaded: 0,

      totalSlides: 0,

    }
  },

  
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  beforeMount(){
    
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
  },
  methods: {
    
    prepareContent: function(){
      this.title = this.block.title.value;
      this.description = this.block.description.value;

      if (this.block.button && this.block.button.value){
        this.button = this.block.button.value;
      }
      
      var slider = [];
      if (this.block.slider){
        var slideCount = this.block.slider.value;
        var c = 0;
        for (var i=0; i<slideCount; i++){
          var carImage1 = this.block['slider_'+i+'_car_images_image_1'].value.url;
          var carImage2 = this.block['slider_'+i+'_car_images_image_2'].value.url;
          var carImage3 = this.block['slider_'+i+'_car_images_image_3'].value.url;

          var article = this.block['slider_'+i+'_article'].value[0];
          var carImages = [];

          if(carImage1) carImages.push(carImage1);
          if(carImage2) carImages.push(carImage2);
          if(carImage3) carImages.push(carImage3);


        // console.log("a");
        // console.log(carImages);
          // var iconTitle = this.block['slider_'+i+'_title'].value;
          // var iconDescription = this.block['slider_'+i+'_description'].value;
          slider.push({'carImages': carImages, 'article': article}); 
        }
      }
      this.slider = slider;
      // console.log("zz");
      // console.log(this.slider);
      this.totalSlides = this.slider.length;


      this.$nextTick(function(){

       
        if (this.$refs.testAreaBlock){
          if (this.$refs.testAreaBlock.querySelector(".cars-images")){
            this.$refs.testAreaBlock.querySelector(".cars-images").classList.add("visible")
            this.$refs.testAreaBlock.querySelector(".cars-images").classList.add("active");
          }
        }

        
      });

      

    },
    getDimensions() {
      // this.width = document.documentElement.clientWidth;
      // this.height = document.documentElement.clientHeight;
      // this.newsDirection = 'vertical';
      // if(this.width< 1000){
      //   this.newsDirection = 'horizontal';
      // }

      if (this.$refs.testAreaBlock){

        var cimages = this.$refs.testAreaBlock.querySelectorAll(".cars-images");
        for(var i=0; i<cimages.length; i++){
          cimages[i].classList.add("f-visible");
        }


        var images = this.$refs.testAreaBlock.querySelectorAll(".car-image");
        var ch = document.querySelector(".test-area-block .bg").clientHeight + 100;

        for(var i=0; i<images.length; i++){
          var img = images[i];
          var style = img.currentStyle || window.getComputedStyle(img);
          var ih = img.clientHeight + parseInt(style.bottom) + parseInt(style.marginBottom);
          if (ch < ih){
            ch = ih;
          }
        }
        // document.querySelector(".test-area-block .bg")
        // var ch = document.querySelector(".test-area-block .bg").clientHeight + 100;

        this.blockHeight = ch;

        var cimages = this.$refs.testAreaBlock.querySelectorAll(".cars-images");
        for(var i=0; i<cimages.length; i++){
          cimages[i].classList.remove("f-visible");
        }
    
      }
    },
    onSwiper (swiper) {


      
      // alert("a");
      // this.onSlideChange(swiper);

      // alert(swiper.activeIndex);


      
      // var swiper = this.$refs.newsSlider.$el.swiper;
      // var slide = swiper.slides[swiper.activeIndex];

      // alert(slide);
    },
    onSlideChange (swiper) {
      
      this.getDimensions();

      var slide = swiper.slides[swiper.activeIndex];
      var index = slide.querySelector(".card").getAttribute("data-index");
      // console.log(index);
      this.activeSlide = index;

      var speed = 0.4;
      // console.log(this.$refs.testAreaBlock);
      
      var last_images = this.$refs.testAreaBlock.querySelector(".cars-images.active");

      var images = this.$refs.testAreaBlock.querySelector(".cars-images-"+index);
      if (last_images){
        
        var cars = last_images.querySelectorAll(".car-image");
        for(var i=0; i<cars.length; i++){
          var car = cars[i];
          // gsap.to(car, { ease: Quad.easeOut, duration: 0, x: images.clientWidth, y:-i*60, opacity: 1, onComplete: function(){}});
          gsap.killTweensOf(car);
          gsap.to(car, { ease: Quad.easeOut, duration: speed, x: -400, y:0, opacity: 0, delay: i*0.1, onComplete: function(){}});
        }
        gsap.killTweensOf(last_images);
        gsap.to(last_images, { ease: Quad.easeOut, duration: speed, delay: cars.length*0.1, onComplete: function(){
          this._targets[0].closest(".cars-images").classList.remove("visible");
        }});
        last_images.classList.remove("active");

      }
      if (images){
        images.classList.add("visible");
        images.classList.add("active");
        var cars = images.querySelectorAll(".car-image");
        for(var i=0; i<cars.length; i++){
          var car = cars[i];
          gsap.killTweensOf(car);
          gsap.to(car, { ease: Quad.easeOut, duration: 0, x: images.clientWidth, y:-i*60, opacity: 1, onComplete: function(){}});
          gsap.to(car, { ease: Quad.easeOut, duration: speed, x: 0, y:0, opacity: 1, delay: i*0.1 + speed, onComplete: function(){}});
        }
        
        

      }
      
    },
    onImgLoad (){
      this.imgsLoaded++;
      this.getDimensions();
      // this.thumbnailsLoaded++;
      // if (this.logos){
      //   if (this.thumbnailsLoaded >= this.logos.length){
          // var th = this;
          
            // th.$refs.newsSlider.$el.swiper.update();
            // th.$refs.partnersSlider.$el.swiper.update();
          
      //   }
      // }
    }
  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.test-area-block{
  overflow: hidden;
  margin-bottom: 124px;
  margin-top: 124px;
  padding-top: 72px;
  padding-bottom: 72px;
  .block{
    position: relative;
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    
    min-height: 530px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .secondary-button{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    color: #69BDB6;
    display: inline-block;
    vertical-align: top;
    padding-right: 50px;
    box-sizing: border-box;
    position: relative;

    &:after{
      content: "";
      display: block;
      background-image: url('/ui/assets/icons/arrow-green.svg');
      height: 14px;
      width: 32px;
      position: absolute;
      top: 2px;
      right: 0px;
      background-repeat: no-repeat;
      background-position: 0% 50%;
      transition: all 300ms;
      -webkit-transition: all 300ms;
    }
    &:hover{
      &:after{
        transform: translateX(4px);
        -webkit-transform: translateX(4px);
      }
    }
  }
  .left-side{
    width: 40%;
    padding-right: 32px;
    box-sizing: border-box;
  }
  .right-side{
    width: 60%;
    position: absolute;
    right: 0px;
    bottom: 0px;
    max-width: 1300px;

    .cars-slider-wrap{
      height: 400px;
      display: flex;
      align-items: flex-end;
    }
    .bg{
      position: absolute;
      right: 0px;
      bottom: 100px;
      padding-top: 46.78787879%;
      height: 0px;
      display: block;
      width: 100%;
      width: calc(100% - 100px);
      

      svg{
        position: absolute;
        top:0px;
        left: 0px;
        width: 100%;
        height: 100%;
      }

    }
  }
  .cars-images{
    display: none;
    width: 100%;
    position: absolute;
    &.f-visible,
    &.visible{
      display: block
    }
    &.count-1{
      .car-image{
        width: 74%;
      }
      .car-image:nth-of-type(1){
        left: 13%;
      }
    }
    &.count-2{
      .car-image{
        width: 74%;
        margin-left: 50px;
      }
      .car-image:nth-of-type(1){
        left: 0%;
        margin-bottom: 26px;
      }
      .car-image:nth-of-type(2){
        left: 32%;
      }
    }
    &.count-3{
      .car-image{
        width: 64%;
      }
      .car-image:nth-of-type(1){
        left: 0%;
        margin-bottom: 40px;
      } 
      .car-image:nth-of-type(2){
        left: 25%;
        margin-bottom: 20px;
      }
      .car-image:nth-of-type(3){
        left: 50%;
        
      }
    }
    .car-image{
      position: absolute;
      bottom: 135px;
      left: 0px;
      margin-left: 100px;
    }
  }
  .slider{
    padding-left: 100px;
    -webkit-mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100px, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 1) 100%);
    
    ::v-deep .swiper-wrapper{
      align-items: flex-end;
    }

    .navigation{
      position: absolute;
      display: block;
      right: 112px;
      // bottom: 28px;
      bottom: 50px;
      height: 44px;
      width: 100px;

      &.hidden{
        display: none;
      }
    }
    .prev,
    .next{
      position: absolute;
      color: #69BDB6;
      top: 50%;
      margin-top: -22px;
      z-index: 20;
      cursor: pointer;
      circle,path{
        transition: all 300ms;
        -webkit-transition: all 300ms;
        stroke: #69BDB6;
      }
      &.swiper-button-disabled{
        
        pointer-events: none;
        circle{
          opacity: 0.12;
        }
        path{
          opacity: 0.24;
        }
      }
      &:hover{

        circle{
          opacity: 1;
          stroke: #1F4743;
        }
        path{
          opacity: 1;
          stroke: #1F4743;
        }
      }
    }
    .prev{
      left: 0px;
    }
    .next{
      right: 0px;
    }
  }
  .swiper-container{
    padding-bottom: 22px;
    margin-bottom: -22px;
  }
  .slide{
    .card{
      background: #FFFFFF;
      // box-shadow: 0px 10px 22px -9px rgba(31, 72, 68, 0.12);
      box-shadow: 0px 10px 22px -9px rgba(31, 72, 68, 0.12);
      border-radius: 20px;
      padding: 20px;
      padding-bottom: 32px;
      max-width: 490px;
      border: none;

      .date{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 133%;
        color: #4C6C69;
        margin-bottom: 8px;
      }
      .title{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 130%;
        color: #1F4743;
        margin-bottom: 4px;
      }
      .description{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        color: #1F4743;
        
      }
      .button{
        margin-top: 21px;
      }
    }
  }
  .block-title{
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #1F4743;
    margin-bottom: 20px;

  }
  .block-description{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: #4C6C69;
    margin-bottom: 40px;
  }
}


@media (max-width: 2000px) {

  .test-area-block{
    .block{
      justify-content: center;
    }
  }
}


@media (max-width: 1000px) {
  .test-area-block{
    .block{
      display: block;
    }
    .left-side{
      width: 100%;
    }
    .right-side{
      width: 100%;
      position: relative;
      .bg{
        top: 30px;
        width: 100%;
        margin-right: -40px;
        bottom: auto;
      }
    }
    .slider{
      padding-left: 20px;
      padding-right: 20px;
      -webkit-mask-image: none;
      margin-bottom: 0px;
      padding-bottom: 64px;
      .navigation{
        bottom: 0px;
        right: 20px;
      }
    }
    .cars-slider-wrap{
      position: relative;
      box-sizing: content-box;
      padding-top: 148px;
    }
    .cars-images{
      top: 40px;
      .car-image{
        top: 0px;
        bottom: auto;
        margin-left: 20px;
      }
    }
    .slide{
      .card{
        margin: auto;
      }
    }
    .block-description{
      margin-bottom: 24px;
    }
  }
}


@media (max-width: #{$screen-tablet}) {
  .test-area-block{
    margin-bottom: 96px;
    margin-top: 96px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

}
@media (max-width: 700px) {

}

@media (max-width: #{$screen-mobile}) {
  .test-area-block{
    margin-bottom: 112px;
    margin-top: 112px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

</style>