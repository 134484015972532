import { Component, Vue } from 'vue-property-decorator';

import template from './header.vue';

@Component({
  mixins: [template],
  props: ['cmsGlobalData', 'cmsPageData']
})
export default class ComponentHeader extends Vue {
  get GetLeasingProUserLoggedIn(){
    if (this.$route.matched[0]){
      if (this.$route.matched[0].instances){
        if (this.$route.matched[0].instances.default){
          if ((this.$route.matched[0].instances.default as any).isLoggedIn){
            return true;
          }
        }
      }
    }
    return false;
  }
  get GetCurrentRouteName() {
    return this.$route.name;
  }
}