import * as localForage from "localforage";

import AbAxios from '../../common/ab_axios_leasing';
import AbGtm from '../../common/ab_gtm';
import AbHeader from "../../shared/abHeader.vue";
import AbLightbox from '../../common/ab_lightbox';
import AppHeader from "../../AppHeader.vue";
import CarCard from './CarCard.vue';
import CarItemMakeModel from '../../../../../../components/ui/car-item-make-model/CarItemMakeModel';
import CarListHelper from "./CarListHelper";
import CarSlider from '../../../../../../components/ui/car-slider/CarSlider';
import { Component } from "vue-property-decorator";
import DropdownFilter from '../../../../../../components/ui/dropdown-filter/DropdownFilter';
import QuickSearchBar from '../ui/quick-search-bar/QuickSearchBar'
import SelectField from '../../../../../../components/ui/select-field/select-field';
import UserData from '../providers/UserData';
import Utils from '../providers/Utils';
import Vue from 'vue';
import eventBus from '../../../../../../eventBus';
import { isNullOrUndefined } from 'is-what';
import router from "@/router";
import template from './CarList.vue'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies, { expires: ''})

export interface filterItem {
    name: string,
    range: number[],
    order: number
    selected: boolean
}

@Component({
    name: 'leasing-carlist-component',
    mixins: [template],
    components: { AppHeader, AbHeader, CarCard, QuickSearchBar, CarItemMakeModel, CarSlider, DropdownFilter, SelectField}
})

export default class CarList extends Vue {

    userData: UserData = this.$store.getters.getUserData;

    cmsGlobalData: any = this.$store.getters.getCMSGlobalData;
    
    utils = new Utils();
    helper = new CarListHelper();
    abLightbox = new AbLightbox();
    abGtm = new AbGtm();
    carsIsLoaded: number = 0;
    hasMarkeFilter: boolean = false;

    abAxios = new AbAxios(this.userData.serviceUrl, "application/json; charset=utf-8", this.userData.csrftoken);

    getConsumerVehiclesServiceUrl: string = this.userData.epiData.vehicleServiceUrl ?? "/leasing/Consumer/vehicles";
    getReleasingVehiclesServiceUrl: string = this.userData.epiData.vehicleServiceUrl ?? "/leasing/Consumer/vehicles";
    getCorporateVehiclesServiceUrl: string = this.userData.epiData.vehicleServiceUrl ?? "/leasing/Corporate/vehicles";

    //rest service parametrer
    isCorporate: boolean = false;
    filterAdded: boolean = false;


    mainTitle: string = "";

    currentPage: number = 1;
    lastPage: number = 10;
    pagination: any = [1,2,".",5];

    //rå data fra bytelab uden type, da strukturen er stor og kompleks
    cachedCars = [];
    //bliver filtreret på personbil/varebil
    cars = [];
    filteredCars = [];
    filteredCarsInPage = [];
    preFilterCarGroups = [];
    combinedCars = [];

    isReleasing: boolean = false;
    isViewInPreFilterMode: boolean = false;
    backFromCarView: boolean = false;
    isFullFilterOpen: boolean = false;
    resultCount: number = null;


    responsesToWait = 0;
    typeOptions = [
        {'group': 'type', 'value': 'type-stationcar', 'name': 'Stationcar', 'icon': 'stationcar', "checked": false, "count": 0},
        {'group': 'type', 'value': 'type-cabriolet', 'name': 'Cabriolet', 'icon': 'cabriolet', "checked": false, "count": 0},
        {'group': 'type', 'value': 'type-suv', 'name': 'SUV', 'icon': 'suv', "checked": false, "count": 0},
        {'group': 'type', 'value': 'type-hatchback', 'name': 'Hatchback', 'icon': 'hatchback', "checked": true, "count": 0},
        {'group': 'type', 'value': 'type-coupe', 'name': 'Coupé', 'icon': 'coupe', "checked": false, "count": 0},
        {'group': 'type', 'value': 'type-sedan', 'name': 'Sedan', 'icon': 'sedan', "checked": false, "count": 0},
        {'group': 'type', 'value': 'type-mini', 'name': 'Mini', 'icon': 'mini', "checked": false, "count": 0},
        {'group': 'type', 'value': 'type-varebil', 'name': 'Varebil', 'icon': 'varebil', "checked": false, "count": 0},
    ];
    maerkeOptions = [
        {'group': 'marke', 'value': 'marke-audi', 'name': 'Audi', "checked": false, "count": 0, 'children':[
            {'group': 'marke-child', 'value': 'marke-audi-bmw', 'name': 'BMW', "checked": false, "count": 0},
            {'group': 'marke-child', 'value': 'marke-audi-fiat', 'name': 'Fiat', "checked": false, "count": 0},
            {'group': 'marke-child', 'value': 'marke-audi-jeep', 'name': 'Jeep', "checked": false, "count": 0},
            {'group': 'marke-child', 'value': 'marke-audi-nissan', 'name': 'Nissan', "checked": false, "count": 0},    
        ]},
        {'group': 'marke', 'value': 'marke-bmw', 'name': 'BMW', "checked": false, "count": 0},
        {'group': 'marke', 'value': 'marke-fiat', 'name': 'Fiat', "checked": false, "count": 0},
        {'group': 'marke', 'value': 'marke-jeep', 'name': 'Jeep', "checked": false, "count": 0, 'children':[
            {'group': 'marke-child', 'value': 'marke-jeep-bmw', 'name': 'BMW', "checked": false, "count": 0},
            {'group': 'marke-child', 'value': 'marke-jeep-fiat', 'name': 'Fiat', "checked": false, "count": 0},
            {'group': 'marke-child', 'value': 'marke-jeep-jeep', 'name': 'Jeep', "checked": false, "count": 0},
            {'group': 'marke-child', 'value': 'marke-jeep-nissan', 'name': 'Nissan', "checked": false, "count": 0},    
        ]},
        {'group': 'marke', 'value': 'marke-nissan', 'name': 'Nissan', "checked": false, "count": 0},
        {'group': 'marke', 'value': 'marke-peugeot', 'name': 'Peugeot', "checked": false, "count": 0},

    ];
    engineOptions = [
        {'group': 'engine', 'value': 'engine-el', 'name': 'El', "checked": false, "count": 0},
        {'group': 'engine', 'value': 'engine-elbenzin', 'name': 'ElBenzinPlugin', "checked": false, "count": 0},
        {'group': 'engine', 'value': 'engine-benzin', 'name': 'Benzin', "checked": false, "count": 0}
    ];

    gearOptions = [
        {'group': 'gear', 'value': 'gear-auto', 'name': 'Automat', "checked": false, "count": 0},
        {'group': 'gear', 'value': 'gear-manual', 'name': 'Manuel', "checked": false, "count": 0}
    ];

    newOrUsedOptions = [
        {'group': 'new-used', 'value': 'new-used-new', 'name': 'Ny', "checked": false, "count": 0},
        {'group': 'new-used', 'value': 'new-used-used', 'name': 'Brugt', "checked": false, "count": 0}
    ];

    energyMarkOptions = [
        {'group': 'energy', 'value': 'energy-appp', 'name': 'A+++', "checked": false, "count": 0},
        {'group': 'energy', 'value': 'energy-app', 'name': 'A++', "checked": false, "count": 0},
        {'group': 'energy', 'value': 'energy-ap', 'name': 'A+', "checked": false, "count": 0},
        {'group': 'energy', 'value': 'energy-a', 'name': 'A', "checked": false, "count": 0},
        {'group': 'energy', 'value': 'energy-b', 'name': 'B', "checked": false, "count": 0},
        {'group': 'energy', 'value': 'energy-c', 'name': 'C', "checked": false, "count": 0},
        {'group': 'energy', 'value': 'energy-d', 'name': 'D', "checked": false, "count": 0}
    ];

    allOptions = {
        'typeOptions' : this.typeOptions,
        'maerkeOptions' : this.maerkeOptions,
        'engineOptions' : this.engineOptions,
        'gearOptions' : this.gearOptions,
        'newOrUsedOptions' : this.newOrUsedOptions,
        'energyMarkOptions' : this.energyMarkOptions,
    }



    
    sortingOptions = [
        {'value': 'mark-asc', 'name': 'Mærke (stigende)'},
        {'value': 'mark-desc', 'name': 'Mærke (faldende)'},
        {'value': 'payment-asc', 'name': 'Ydelse (stigende)'},
        {'value': 'payment-desc', 'name': 'Ydelse (faldende)'},
        {'value': 'downpayment-asc', 'name': 'Førstegangsydelse (stigende)'},
        {'value': 'downpayment-desc', 'name': 'Førstegangsydelse (faldende)'},
        // {'value': 'asc', 'name': 'Ydelse (stigende)'},
        // {'value': 'desc', 'name': 'Ydelse (faldende)'},
    ];
    selectedSortingValue = this.sortingOptions[0].value;

    
    monthlyInitFrom = 0;
    monthlyInitTo = 0;
    firstInitFrom = 0;
    firstInitTo = 0;
    taxInitFrom = 0;
    taxInitTo = 0;

    monthlyCurrentFrom = 0;
    monthlyCurrentTo = 0;
    firstCurrentFrom = 0;
    firstCurrentTo = 0;
    taxCurrentFrom = 0;
    taxCurrentTo = 0;

    monthlyIntervalFrom = 0;
    monthlyIntervalTo = 1000;
    firstIntervalFrom = 0;
    firstIntervalTo = 100;
    taxIntervalFrom = 0;
    taxIntervalTo = 100;

    savedIntervals = null;


    
    selectedFilterTags = [];//[{"key":"a", "name":"a"}, {"key":"b", "name":"b"}];
    preFilterCarGroupsData = [];

    mounted(): void {
        var th = this;
        
        if (this.isCorporate){
            this.mainTitle = "Erhvervsleasingbiler";
        }else{
            this.mainTitle = "Privatleasingbiler";
        }

        // setTimeout(function(){
            
        //     // th.maerkeOptions[0].checked = false;
        //     // th.monthlyInitFrom = 22;
        //     // th.firstInitFrom = 41;
            
        //     th.maerkeOptions[0].checked = true;

        // },2000);

        // setTimeout(function(){
            
        //     th.allOptions.maerkeOptions[1].checked = true;
        //     // th.maerkeOptions[0].checked = true;
        //     // th.monthlyIntervalFrom = 100;
        //     // th.monthlyIntervalTo = 200;

        // },3000);
        
        // th.maerkeOptions[0].checked = true;
        
        // Vue.directive('flickit', {
        //     inserted: function (el, binding) {
        //         let flkty = new Flickity(el, {
        //             contain: true,
        //             groupCells: true,
        //             wrapAround: false,
        //             pageDots: true,
        //             selectedAttraction: 0.2,
        //             friction: 0.8,
        //             arrowShape: {
        //                 x0: 10,
        //                 x1: 55, y1: 45,
        //                 x2: 60, y2: 40,
        //                 x3: 20
        //             }
        //         });
        //     }
        // });

        // alert("a");

        
        // var queryTags="";
        // for(var i=0; i<this.selectedFilterTags.length; i++){
        //     var tag = this.selectedFilterTags[i];
        //     if (i>0) queryTags+=",";
        //     queryTags+=tag.key;
        // }


        th.cmsGlobalData = th.$store.getters.getCMSGlobalData;
        th.initPreFilterGroups();
        eventBus.$on("cmsGlobalDataSet", function(e){
            th.cmsGlobalData = th.$store.getters.getCMSGlobalData;
            th.initPreFilterGroups();
         });
    }

    initPreFilterGroups(){
        
        if (this.cmsGlobalData){
            if (this.isCorporate){
                if (this.cmsGlobalData.prefilter_groups_erhverv){
                    this.preFilterCarGroupsData = this.cmsGlobalData.prefilter_groups_erhverv;
                }
            }else{
                if (this.cmsGlobalData.prefilter_groups_privat){
                    this.preFilterCarGroupsData = this.cmsGlobalData.prefilter_groups_privat;
                }
            }
        }
        
    }

    prepareCarGroups(){
        var th = this;
        
        
        var group1cars = [];
        var group2cars = [];
        var group3cars = [];

        for(var i=0; i<this.cars.length; i++){
            if (i<6){
                group1cars.push(this.cars[i]);
            }else if (i<12){
                group2cars.push(this.cars[i]);
            }else if (i<18){
                group3cars.push(this.cars[i]);
            }
        }

        group1cars.sort((a, b) => a.make.name.localeCompare(b.make.name));
        group2cars.sort((a, b) => a.make.name.localeCompare(b.make.name));
        group3cars.sort((a, b) => a.make.name.localeCompare(b.make.name));


        var group1 = {"title" : "Populære biler", "cars" : group1cars};
        var group2 = {"title" : "Nyeste biler", "cars" : group2cars};
        var group3 = {"title" : "Hurtig levering", "cars" : group3cars};

        var havePrefilter = false;
        this.preFilterCarGroups = [];
        for(var i=0; i<this.preFilterCarGroupsData.length; i++){
                
            var groupCars = [];
            for(var j=0; j<this.cars.length; j++){
                if (this.cars[j].tags){
                    for(var k=0; k<this.cars[j].tags.length; k++){
                        if (!this.cars[j].tags[k].deleted && this.cars[j].tags[k].name == this.preFilterCarGroupsData[i].tag){
                            groupCars.push(this.cars[j]);
                        }
                    }
                }
            } 
            if (groupCars.length>0){
                havePrefilter = true;
                this.preFilterCarGroups.push({"title" : this.preFilterCarGroupsData[i].title, "cars" : groupCars})
            }
        }


        //URL FILTER
        if (this.$route.query){
            if (this.$route.query.filter){
                havePrefilter = false;
            }
        }

        

        
        localForage.getItem("backFromCarViewOLD", function(err, data) {
            localForage.removeItem("backFromCarViewOLD");
            if (data) {
                let filterKey = "carListViewInPreFilterModePrivate";
                if (th.isCorporate) {
                    filterKey = "carListViewInPreFilterModeCorporate";
                }

                const filterCookie = th.$cookies.get(filterKey);

                if (filterCookie) {
                    // if (data2 && data2['savedIntervals']) {
                    if (filterCookie.savedIntervals) {
                        th.savedIntervals = filterCookie.savedIntervals;
                    }
                    // if (data2 && data2['monthlyCurrentFrom']) { th.monthlyCurrentFrom = data2['monthlyCurrentFrom']; th.monthlyInitFrom = data2['monthlyCurrentFrom']; }
                    // if (data2 && data2['monthlyCurrentTo']) { th.monthlyCurrentTo = data2['monthlyCurrentTo']; th.monthlyInitTo = data2['monthlyCurrentTo']; }
                    // if (data2 && data2['firstCurrentFrom']) { th.firstCurrentFrom = data2['firstCurrentFrom']; th.firstInitFrom = data2['firstCurrentFrom']; }
                    // if (data2 && data2['firstCurrentTo']) { th.firstCurrentTo = data2['firstCurrentTo']; th.firstInitTo = data2['firstCurrentTo']; }
                    // if (data2 && data2['taxCurrentFrom']) { th.taxCurrentFrom = data2['taxCurrentFrom']; th.taxInitFrom = data2['taxCurrentFrom']; }
                    // if (data2 && data2['taxCurrentTo']) { th.taxCurrentTo = data2['taxCurrentTo']; th.taxInitTo = data2['taxCurrentTo']; }

                    if (filterCookie.selectedFilterTags) {
                         th.selectedFilterTags = filterCookie.selectedFilterTags;
                    }
                    if (filterCookie.isViewInPreFilterMode &&
                        filterCookie.isViewInPreFilterMode === false) {
                        th.backFromCarView = true;
                    } else {
                        th.backFromCarView = false;
                    }

                    th.preparePossibleFilterOptions();

                    setTimeout(function() {
                        th.prepareFilteredCars();
                    }, 500)

                    if (havePrefilter && !th.backFromCarView) {
                        th.isViewInPreFilterMode = true;
                        th.saveFilterStateForLater();

                    }

                    // th.changedFilter();
                }
            }else{
                if (havePrefilter && !th.backFromCarView){
                    th.isViewInPreFilterMode = true;
                    th.saveFilterStateForLater();
                }
            }
        });


        

        // alert(this.isViewInPreFilterMode);


        // console.log("a");
        // console.log(this);
        // alert(this.$props.cmsGlobalData);


        // this.preFilterCarGroups = [group1, group2, group3];
        

    }

    goToPage(page){
        this.currentPage = page;
        this.preparePagiantion();
    }
    preparePagiantion(){
        if (this.hasMarkeFilter) {
            var size = 1;
            var pages = [];

            var perPage = 24;

            this.lastPage = Math.ceil(this.resultCount / perPage);

            var from = (this.currentPage - 1) * perPage;
            var to = from + perPage;

            this.filteredCarsInPage = this.filteredCars.slice(from, to);

            if (this.currentPage > size + 1) {
                pages.push(1);
            }
            if (this.currentPage > size + 2) {
                pages.push(".");
            }
            for (var i = this.currentPage - size; i <= this.currentPage + size; i++) {
                if (i > 0 && i <= this.lastPage) {
                    pages.push(i);
                }
            }
            i--;

            if (i < this.lastPage - 1) {
                pages.push(".");
                i++;
            }
            if (i < this.lastPage) {
                pages.push(this.lastPage);
            }
            this.pagination = pages;
        }
    }


    scrollToTop(e){
        e.preventDefault();

        let ele = (document.querySelector("#scroll-start") as HTMLElement);
        if (!isNullOrUndefined(ele)) {
            let VueScrollTo = require('vue-scrollto');
            VueScrollTo.scrollTo(ele);
        }
    }

    sortingChanged(val){
        // console.log(val);
        this.selectedSortingValue = val;
        this.sortCars(val);

        this.preparePagiantion();
    }

    sortCars(val){
        let objToSort = this.hasMarkeFilter ? this.filteredCars : this.combinedCars;
        if (val == "mark-asc"){
            objToSort.sort((a, b) => (a.make.name+a.model.name).localeCompare(b.make.name+b.model.name));
        }

        if (val == "mark-desc"){
            objToSort.sort((a, b) => (b.make.name+b.model.name).localeCompare(a.make.name+a.model.name));
        }

        if (val == "payment-asc"){
            objToSort.sort((a, b) => (a.price.monthly_payment.total) - (b.price.monthly_payment.total));
        }

        if (val == "payment-desc"){
            objToSort.sort((a, b) => (b.price.monthly_payment.total) - (a.price.monthly_payment.total));
        }

        if (val == "downpayment-asc"){
            objToSort.sort((a, b) => (a.price.downpayment.total) - (b.price.downpayment.total));
        }

        if (val == "downpayment-desc"){
            objToSort.sort((a, b) => (b.price.downpayment.total) - (a.price.downpayment.total));
        }

        if (this.hasMarkeFilter) {
            this.filteredCars = objToSort;
        } else {
            this.combinedCars = objToSort;
        }
    }

    removeSelectedTags(){
        for(var i=0; i<this.selectedFilterTags.length; i++){
            var item = this.getFilterItemByKey(this.selectedFilterTags[i].key);
            if (item){
                item.checked = false;
            }
        }
        this.selectedFilterTags = [];
        
        this.$nextTick(function(){
            this.changedFilter(null);
        });
    }
    removeSelectedTag(tag: any){
        for(let i=0; i<this.selectedFilterTags.length; i++){
            if (this.selectedFilterTags[i].key == tag.key){
                let item = this.getFilterItemByKey(tag.key);
                if (item){
                    item.checked = false;
                }
                this.selectedFilterTags.splice(i, 1);
            }
        }

        let filterArgs = [];
        for (const f of this.selectedFilterTags) {
            filterArgs.push(f.key);
        }

        let args = null;

        if (filterArgs.length) {
            args = {
                'value': filterArgs.join(','),
                'checked': true
            };
        }

        this.$nextTick(function(){
            this.changedFilter(args);
        });
    }

    changedFilter(args: any){
        if (this.$refs.fullFilterForm){
            let data = new FormData(this.$refs.fullFilterForm as HTMLFormElement);

            if (args != undefined) {
                let item = this.getFilterItemByKey(args.value);

                const dropdownElements = this.$refs.fullFilterFormMaerke as Vue;
                const children = dropdownElements.$children;

                for (const child of children) {
                    const typedChild = child as any;

                    const valueParts = item.value.split('-')
                    const checkValue = valueParts[0] + '-' + valueParts[1];
                    let checkValue2 = checkValue + '-' + valueParts[2];
                    if (valueParts.length > 3) {
                        checkValue2 = checkValue2 + '-' + valueParts[3];
                    }

                    if (typedChild.value == checkValue) {
                        typedChild.checked = true;
                        typedChild.$emit("changed", {'value': checkValue, 'checked': true});

                        const typedChildChildren = typedChild.$children;

                        for (const typedChildChild of typedChildChildren) {
                            if (typedChildChild.value == checkValue2) {
                                typedChildChild.checked = true;
                                typedChildChild.$emit("changed", {'value': checkValue2, 'checked': true});
                            }
                        }
                    }
                }
                item.checked = true;
            }

            let data2 = new FormData(this.$refs.fullFilterForm as HTMLFormElement);
            for (let entry of data2) {
                const key = entry[0] as string;
                const value = entry[1] as string;

                const item = this.getFilterItemByKey(value);

                if (item){
                    if (item.parent){
                        //Remove parent children
                        var parent = this.getFilterItemByKey(item.parent);
                        if (parent){
                            if (!data2.get(item.parent)){

                                if (parent.children){
                                    for(var j=0; j<parent.children.length; j++){
                                        parent.children[j].checked = false;
                                    }
                                    continue;
                                }
                            }
                        }
                    }
                    item.checked = true;
                }
            }

            setTimeout(() => {
                // if (!this.hasMarkeFilter) {
                    this.prepareSelectedFilterTagsFromFormData();
                // }
                this.prepareFilteredCars();
            }, 100)
        } else {
            console.log("changed 2")
            this.prepareSelectedFilterTags();
            this.prepareFilteredCars();
        }
    }

    getCheckedFilterItems(){
        var ret = [];
        for (const groupKey in this.allOptions) {
            var group = this.allOptions[groupKey];
            for(var j=0; j<group.length; j++){
                var item = group[j];
                if (item.checked){
                    ret.push(item);
                }
                if (item.children){
                    for(var k=0; k<item.children.length; k++){
                        var item2 = item.children[k];
                        if (item2.checked){
                            ret.push(item2);
                        }
                    }
                }
            }
        }
        return ret;
    }
    getFilterItemByKey(key){
        for (const groupKey in this.allOptions) {
            var group = this.allOptions[groupKey];
            for(var j=0; j<group.length; j++){
                var item = group[j];
                if (item.value == key){
                    return item;
                }
                if (item.children){
                    for(var k=0; k<item.children.length; k++){
                        var item2 = item.children[k];
                        if (item2.value == key){
                            return item2;
                        }
                    }
                }
            }
        }
        return null;
    }

    prepareSelectedFilterTags(){

        var data = this.getCheckedFilterItems();
        this.selectedFilterTags = [];
        
        
        for(var i=0; i<data.length; i++){
            var item = data[i];
            
            if (item){
                var key = item.value;
                var title = item.name;
                var group = item.group;
                var parent = item.parent;
                this.selectedFilterTags.push({"group":group, "key":key, "name":title, "parent": parent});
            }
                
        }
    }

    prepareSelectedFilterTagsFromFormData(){
        if (this.$refs.fullFilterForm){
            let data = new FormData(this.$refs.fullFilterForm as HTMLFormElement);

            this.selectedFilterTags = [];

            for (let entry of data) {
                var key = entry[0] as string;
                var value = entry[1] as string;

                if (["monthly-interval-from",
                    "monthly-interval-to",
                    "first-interval-from",
                    "first-interval-to",
                    "tax-interval-from",
                    "tax-interval-to"].includes(key)){

                    if (key == "monthly-interval-from") { this.monthlyCurrentFrom = parseFloat(value); }
                    if (key == "monthly-interval-to") { this.monthlyCurrentTo = parseFloat(value); }
                    if (key == "first-interval-from") { this.firstCurrentFrom = parseFloat(value); }
                    if (key == "first-interval-to") { this.firstCurrentTo = parseFloat(value); }
                    if (key == "tax-interval-from") { this.taxCurrentFrom = parseFloat(value); }
                    if (key == "tax-interval-to") { this.taxCurrentTo = parseFloat(value); }

                    continue;
                }

                var title = "";
                var group = "";
                var parent = "";
                var item = this.getFilterItemByKey(value);

                if (item){
                    title = item.name;
                    group = item.group;
                    parent = item.parent;
                    if (parent){
                        if(data.get(parent)){
                            this.selectedFilterTags.push({"group":group, "key":key, "name":title, "parent": parent});
                        }
                    }else{
                        this.selectedFilterTags.push({"group":group, "key":key, "name":title, "parent": parent});
                    }
                }
            }
        }
    }

    getVehiclesViaRest(url: string) {
        var self = this;
        
        this.abAxios.abGetWithToken(url, false, function successCallback(response: any) {
        self.responsesToWait--;
        self.handleGetVehicleResponse(response.data);
        if (self.responsesToWait == 0){
            self.carsIsLoaded = 1;
        }
        }, function errorCallback(error: any) {
                self.carsIsLoaded = 2;
                if (!isNullOrUndefined(error.response)) {
                    let errorRes = error.response,
                        statusCode = errorRes.status,
                        statusText = errorRes.statusText,
                        errorMsg = "Listen af biler kunne ikke hentes. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
                    self.abGtm.triggerGtmEvent("fejl", "getVehicles", '[' + self.abGtm.getUtcTimestamp() + '] ' + errorMsg);
                } else {
                    self.abGtm.triggerGtmEvent("fejl", "getVehicles", '[' + self.abGtm.getUtcTimestamp() + '] ' + error);
                }
                //rediret til fejl side efter 3 forsøg
    
                if (error.response.status == 401) {
                    UserData.logout().then(() => {
                        router.push({
                            name: 'logins'
                        })
                    })
                    // var url = window.location.href.substring(0, window.location.href.indexOf("#") + 1)
                    // url = url + "/logins";
                    // window.location.href = url;
                    self.abLightbox.standardLightText("Du er ikke længere logget ind.", "<p>Du er blevet logget ud, og bedes derfor logge ind igen.</p>");
                }
                // else {
                //     self.abLightbox.standardLightText("Listen af biler kunne ikke hentes", "Noget gik galt.. </br></br>" + error + "</br></br> Ring til os på Tlf. <a class='popup__content__phone-link' href='tel: 70607010'> 70 60 70 10</a>");
                // }
            });
        }

    handleGetVehicleResponse(response: any): void {
        if (!this.cachedCars){ this.cachedCars = []; }
        if (this.cachedCars){
            this.cachedCars = this.cachedCars.concat(response.data);
        }

        if (this.responsesToWait == 0){
            this.cars = this.cachedCars.concat([]);
        }

        this.prepareCarGroups();
        this.preparePossibleFilterOptions();

        this.readQueryFilter();

        this.preparePossibleFilterOptions();

        setTimeout(() => {
            this.prepareFilteredCars();
        }, 500)
    }

    

    addOptionItem(options, item){
        var add = true;
        for(var i=0; i<options.length; i++){
            if (options[i].value == item.value){
                add = false;
                break;
            }
        }
        if (add){
            options.push(item);
        }
    }

    resetOptionItemsCount(options){
        for(var i=0; i<options.length; i++){
            options[i].count = 0;
        }
    }
    addOptionItemCountByName(options, item){
        for(var i=0; i<options.length; i++){
            if (options[i].name == item){
                options[i].count++;
                break;
            }
        }
    }
    addOptionItemCountByValue(options, item){
        for(var i=0; i<options.length; i++){
            if (options[i].value == item){
                options[i].count++;
                break;
            }
        }
    }
   

    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
    replaceAll(str, find, replace) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    }
    //copy of func used in CarItemMakeModel
    stringifyValue(val){
        val = val.toLowerCase();
        val = this.replaceAll(val, "+", "p");
        val = val.replace(/é/g, "e");
        val = val.replace(/[^a-zA-Z0-9 ]/g, ""); //RemoveSpecialChars
        val = val.replace(/ /g, "-");
        
        return val;
    }
    getCarTypeIconByName(name){
        if (name=='Stationcar') { return 'stationcar'; }
        if (name=='Cabriolet') { return 'cabriolet'; }
        if (name=='SUV') { return 'suv'; }
        if (name=='Hatchback') { return 'hatchback'; }
        if (name=='Coupé') { return 'coupe'; }
        if (name=='Sedan') { return 'sedan'; }
        if (name=='Mini') { return 'mini'; }
        if (name=='Varebil') { return 'varebil'; }
        if (name=='Kassevogn') { return 'varebil'; }
        if (name=='MPV') { return 'mpv'; }
    }
    addModelToMarke(marke_value, item){
        for(var i=0; i<this.maerkeOptions.length; i++){
            if (this.maerkeOptions[i].value == marke_value){
                // console.log(this.maerkeOptions[i].children);
                if (!this.maerkeOptions[i].children){
                    this.maerkeOptions[i].children = [];
                }

                this.addOptionItem(this.maerkeOptions[i].children, item);

                break;
            }
        }
    }
    addFilterAttributeForCar(car, value){
        if (!car.filter){
            car.filter = [];
        }
        if (!car.filter.includes(value)){
            car.filter.push(value);
        }
    }

    preparePossibleFilterOptions(){

        // this.addOptionItem();

        this.typeOptions = [];
        this.maerkeOptions = [];
        this.engineOptions = [];
        this.gearOptions = [];
        this.newOrUsedOptions = [];
        this.energyMarkOptions = [];


        
        var minMonthly = 99999999;
        var maxMonthly = 0;

        var minFirst = 99999999;
        var maxFirst = 0;

        var minTax = 99999999;
        var maxTax = 0;

        for(var i=0; i<this.cars.length; i++){
            var item = {};
            var value = "";

            if (this.cars[i].price){
                if (this.cars[i].price.monthly_payment){
                    if (this.cars[i].price.monthly_payment.total){
                        if (this.cars[i].price.monthly_payment.total<minMonthly){
                            minMonthly = this.cars[i].price.monthly_payment.total;
                        }
                        if (this.cars[i].price.monthly_payment.total>maxMonthly){
                            maxMonthly = this.cars[i].price.monthly_payment.total;
                        }
                    }
                }
                if (this.cars[i].price.downpayment){
                    if (!isNaN(this.cars[i].price.downpayment.total)){
                        if (this.cars[i].price.downpayment.total<minFirst){
                            minFirst = this.cars[i].price.downpayment.total;
                        }
                        if (this.cars[i].price.downpayment.total>maxFirst){
                            maxFirst = this.cars[i].price.downpayment.total;
                        }
                    }
                }
                if (this.cars[i].price.tax){
                    if (this.cars[i].price.tax.monthly){
                        if (this.cars[i].price.tax.monthly<minTax){
                            minTax = this.cars[i].price.tax.monthly;
                        }
                        if (this.cars[i].price.tax.monthly>maxTax){
                            maxTax = this.cars[i].price.tax.monthly;
                        }
                    }
                }
            }

            if (this.cars[i].energy){
                value = 'energy-'+this.stringifyValue(this.cars[i].energy);
                item = {'group': 'energy', 'value': value, 'name': this.cars[i].energy, "checked": false, "count": 0};
                this.addOptionItem(this.energyMarkOptions, item);
                this.addFilterAttributeForCar(this.cars[i], value);
            }
            if (this.cars[i].new_car){
                value = 'new-used-new';
                item = {'group': 'new-used', 'value': value, 'name': 'Ny', "checked": false, "count": 0};
                this.addOptionItem(this.newOrUsedOptions, item);
                this.addFilterAttributeForCar(this.cars[i], value);
            }
            if (this.cars[i].used){
                value = 'new-used-used';
                item = {'group': 'new-used', 'value': value, 'name': 'Brugt', "checked": false, "count": 0};
                this.addOptionItem(this.newOrUsedOptions, item);
                this.addFilterAttributeForCar(this.cars[i], value);
            }
            if (this.cars[i].transmission_type){
                value = 'gear-'+this.stringifyValue(this.cars[i].transmission_type);
                item = {'group': 'gear', 'value': value, 'name': this.cars[i].transmission_type, "checked": false, "count": 0};
                this.addOptionItem(this.gearOptions, item);
                this.addFilterAttributeForCar(this.cars[i], value);
            }
            if (this.cars[i].fuel_type){
                value = 'engine-'+this.stringifyValue(this.cars[i].fuel_type);
                var ftype = this.cars[i].fuel_type;
                if (ftype == "ElBenzinPlugin"){
                    ftype = "Plug in hybrid benzin";
                }
                if (ftype == "ElDieselPlugin"){
                    ftype = "Plug in hybrid diesel";
                }
                item = {'group': 'engine', 'value':  value, 'name': ftype, "checked": false, "count": 0};
                this.addOptionItem(this.engineOptions, item);
                this.addFilterAttributeForCar(this.cars[i], value);
            }
            if (this.cars[i].frame_type){
                value = 'type-'+this.stringifyValue(this.cars[i].frame_type);
                item = {'group': 'type', 'value': value, 'icon': this.getCarTypeIconByName(this.cars[i].frame_type), 'name': this.cars[i].frame_type, "checked": false, "count": 0};
                this.addOptionItem(this.typeOptions, item);
                this.addFilterAttributeForCar(this.cars[i], value);
            }

            if (this.cars[i].make){
                if (this.cars[i].make.name){
                    var marke_value = 'marke-'+this.stringifyValue(this.cars[i].make.name);
                    item = {'group': 'marke', 'value': marke_value, 'name': this.cars[i].make.name, "checked": false, "count": 0, 'children': []};
                    this.addOptionItem(this.maerkeOptions, item);
                    this.addFilterAttributeForCar(this.cars[i], marke_value);
                    // this.addOptionItem(this.maerkeOptions, item);
                    if (this.cars[i].model){
                        if (this.cars[i].model.name){
                            value = marke_value+'-'+this.stringifyValue(this.cars[i].model.name);
                            item = {'group': 'marke-child', 'parent': marke_value, 'value': value, 'name': this.cars[i].model.name, "checked": false, "count": 0};
                            this.addFilterAttributeForCar(this.cars[i], value);
                            this.addModelToMarke(marke_value,item);
                        }
                    }
                }
                
            }

            // {'value': 'marke-audi', 'name': 'Audi', "checked": false, "count": 0, 'children':[
            //     {'value': 'marke-audi-bmw', 'name': 'BMW', "checked": false, "count": 0},
            // ]};

            
            
        }

        this.allOptions = {
            'typeOptions' : this.typeOptions,
            'maerkeOptions' : this.maerkeOptions,
            'engineOptions' : this.engineOptions,
            'gearOptions' : this.gearOptions,
            'newOrUsedOptions' : this.newOrUsedOptions,
            'energyMarkOptions' : this.energyMarkOptions,
        }


        for(var i=0; i<this.selectedFilterTags.length; i++){
            var item2 = this.getFilterItemByKey(this.selectedFilterTags[i].key);
            if (item2){
                item2.checked = true;
            }
        }

        
        // if (this.monthlyCurrentFrom !== 0) minMonthly = this.monthlyCurrentFrom;
        // if (this.monthlyCurrentTo !== 0) maxMonthly = this.monthlyCurrentTo;
        // if (this.firstCurrentFrom !== 0) minFirst = this.firstCurrentFrom;
        // if (this.firstCurrentTo !== 0) maxFirst = this.firstCurrentTo;
        // if (this.taxCurrentFrom !== 0) minTax = this.taxCurrentFrom;
        // if (this.taxCurrentTo !== 0) maxTax = this.taxCurrentTo;


        
        // if (this.monthlyCurrentFrom > 0) this.monthlyInitFrom = this.monthlyCurrentFrom;
        // if (this.monthlyCurrentTo > 0) this.monthlyInitTo = this.monthlyCurrentTo;
        // if (this.firstCurrentFrom > 0) this.firstInitFrom = this.firstCurrentFrom;
        // if (this.firstCurrentTo > 0) this.firstInitTo = this.firstCurrentTo;
        // if (this.taxCurrentFrom > 0) this.taxInitFrom = this.taxCurrentFrom;
        // if (this.taxCurrentTo > 0) this.taxInitTo = this.taxCurrentTo;
        
        this.monthlyIntervalFrom = minMonthly;
        this.monthlyIntervalTo = maxMonthly;
        this.firstIntervalFrom = minFirst;
        this.firstIntervalTo = maxFirst;
        this.taxIntervalFrom = minTax;
        this.taxIntervalTo = maxTax;
        
        this.monthlyCurrentFrom = minMonthly;
        this.monthlyCurrentTo = maxMonthly;
        this.firstCurrentFrom = minFirst;
        this.firstCurrentTo = maxFirst;
        this.taxCurrentFrom = minTax;
        this.taxCurrentTo = maxTax;


        // alert(JSON.stringify(this.savedIntervals));
        // this.monthlyInitFrom = 5000;
        // this.monthlyCurrentFrom = 5000;
        if (this.savedIntervals){
            if (this.savedIntervals.monthlyCurrentFrom){
                this.monthlyInitFrom = this.savedIntervals.monthlyCurrentFrom;
                this.monthlyCurrentFrom = this.savedIntervals.monthlyCurrentFrom;
            }
            if (this.savedIntervals.monthlyCurrentTo){
                this.monthlyInitTo = this.savedIntervals.monthlyCurrentTo;
                this.monthlyCurrentTo = this.savedIntervals.monthlyCurrentTo;
            }

            if (this.savedIntervals.firstCurrentFrom){
                this.firstInitFrom = this.savedIntervals.firstCurrentFrom;
                this.firstCurrentFrom = this.savedIntervals.firstCurrentFrom;
            }
            if (this.savedIntervals.firstCurrentTo){
                this.firstInitTo = this.savedIntervals.firstCurrentTo;
                this.firstCurrentTo = this.savedIntervals.firstCurrentTo;
            }
            
        }
        
        


        this.typeOptions.sort((a, b) => a.name.localeCompare(b.name));
        this.engineOptions.sort((a, b) => a.name.localeCompare(b.name));
        this.gearOptions.sort((a, b) => a.name.localeCompare(b.name));
        this.newOrUsedOptions.sort((a, b) => a.name.localeCompare(b.name));
        this.maerkeOptions.sort((a, b) => a.name.localeCompare(b.name));

        for (var i=0; i<this.maerkeOptions.length; i++){
            if (this.maerkeOptions[i].children){
                this.maerkeOptions[i].children.sort((a, b) => a.name.localeCompare(b.name));
            }
        }
        
        //Sort energy
        this.energyMarkOptions.sort((a, b) => a.name.localeCompare(b.name));
        this.energyMarkOptions.sort((a, b) => b.name.length - a.name.length);
        
    }


    readQueryFilter(){
        // console.log("routis");
        // console.log(this.$route.query);

        if (this.$route.query){
            if (this.$route.query.filter){
                
                var filter = this.$route.query.filter as string;
                var tags = filter.split(",");

                // th.selectedFilterTags = data2['selectedFilterTags'];
                
                var selectedTags = Array();
                var ok = true;
                for(var i=0; i<tags.length; i++){
                    var tag = tags[i];
                    var tagObj = this.getFilterItemByKey(tag);

                    if (tagObj){
                        // selectedTags.push(tagObj);

                        selectedTags.push({"group":tagObj.group, "key":tagObj.value, "name":tagObj.name, "parent": tagObj.parent});

                    }else{
                        ok = false;
                    }
                    // alert(tag);
                    // alert(JSON.stringify(tagObj));
                }
                if (ok){
                    // alert(JSON.stringify(selectedTags));
                    this.selectedFilterTags = selectedTags;

                    // this.preparePossibleFilterOptions();
                    // this.prepareFilteredCars();
                }
                
            }
        }
    }

    carFitsFilter(car, selectedTags, skipGroups = []){

        // this.monthlyIntervalFrom = minMonthly;
        // this.monthlyIntervalTo = maxMonthly;

        // this.firstIntervalFrom = minFirst;
        // this.firstIntervalTo = maxFirst;

        // this.taxIntervalFrom = minTax;
        // this.taxIntervalTo = maxTax;

        var fit = true;

        if (car.price){
            if (car.price.monthly_payment){
                if (!(car.price.monthly_payment.total <= this.monthlyCurrentTo &&
                    car.price.monthly_payment.total >= this.monthlyCurrentFrom)){
                    fit = false;
                }
            }
            if (car.price.downpayment){
                if (!(car.price.downpayment.total <= this.firstCurrentTo &&
                    car.price.downpayment.total >= this.firstCurrentFrom)){
                    fit = false;
                }
            }
            if (car.price.tax){
                if (!(car.price.tax.monthly <= this.taxCurrentTo &&
                    car.price.tax.monthly >= this.taxCurrentFrom)){
                    fit = false;
                }
                
            }
        }

        
        if (selectedTags.length>0){
            var groups = {};
            for(var i=0 ;i<selectedTags.length; i++){
                var tag = selectedTags[i];
                
                if (skipGroups.length>0 && skipGroups.includes(tag.group)){ continue; }
                
                if (!groups[tag.group]) { groups[tag.group] = []; }
                groups[tag.group].push(tag);
            }

            if (groups['marke']){
                var haveMarke = false;
                for(var i=0 ;i<groups['marke'].length; i++){
                    var tag = groups['marke'][i];
                    
                    if (car.filter && car.filter.includes(tag.key)){
                        haveMarke = true;
                        if (groups['marke-child']){
                            var haveParent = false;
                            var childFit  = false;
                            for(var j=0; j<groups['marke-child'].length; j++){
                                if (groups['marke-child'][j].parent == tag.key){
                                    haveParent = true;
                                    if (car.filter.includes(groups['marke-child'][j].key)){
                                        childFit = true;
                                    }
                                }
                            }
                            if (haveParent && !childFit){
                                fit = false;
                                break;
                            }
                        }
                    }
                }
                if (!haveMarke){
                    fit = false;
                }
                
            }

            if(!fit){
                return fit;
            }


            
            for (let group in groups) {
                
                if (['marke', 'marke-child'].includes(group)){
                    continue;
                }
                var group_fit = false;

                for(var i=0 ;i<groups[group].length; i++){
                    var tag = groups[group][i];
                    if (car.filter && car.filter.includes(tag.key)){
                        group_fit = true;
                        break;
                    }
                }
                if (!group_fit){
                    fit = false;
                    break;
                }
            }    

            // console.log(groups);
            // if (car.filter && !car.filter.includes(tag.key)){
            //     fit = false;
            //     break;
            // }
        }

        return fit;
        
    }

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    prepareFilteredCars(){
        this.filteredCars = [];
        this.combinedCars = [];
        this.currentPage = 1;

        const urlFilter = this.getParameterByName('filter', document.location.href);
        const dashOccurrences = urlFilter == null ? 0 : urlFilter.split('-').length - 1;
        this.hasMarkeFilter = urlFilter?.length && urlFilter.includes('marke') && dashOccurrences >= 2;

        this.doUpdateFilterCounts();

        if (!this.hasMarkeFilter) {
            for (const c of this.filteredCars) {
                const key = c.make.name + c.model.name;
                let foundCar = this.combinedCars.find(d => d.key == key);
                if (foundCar == undefined) {
                    this.combinedCars.push({
                        id: c.id,
                        key: key,
                        make: c.make,
                        model: c.model,
                        name: c.make.name + ' ' + c.model.name,
                        image: c.images.length ? c.images[0] : '',
                        frontend_configuration_url: c.frontend_configuration_url,
                        soldDate: c.soldDate,
                        reservedDate: c.reservedDate,
                        count: 1,
                        price: {
                            downpayment: { total: c.price.downpayment.total },
                            monthly_payment: { total: c.price.monthly_payment.total },
                            tax: { taxable_value: c.price.tax.taxable_value}
                        }
                    });
                } else {
                    const carObject = this.combinedCars.find(arrayItem => arrayItem.key == key);
                    carObject.count += 1;

                    //set image and price from cheapest car in make+model
                    if (c.price.downpayment.total < carObject.price.downpayment.total) {
                        carObject.price.downpayment.total = c.price.downpayment.total;
                        carObject.image = c.images.length ? c.images[0] : '';
                    }
                    if (c.price.monthly_payment.total < carObject.price.monthly_payment.total) {
                        carObject.price.monthly_payment.total = c.price.monthly_payment.total;
                    }
                    if (c.price.tax.taxable_value < carObject.price.tax.taxable_value) {
                        carObject.price.tax.taxable_value = c.price.tax.taxable_value;
                    }
                }
            }
        }

        this.sortCars(this.selectedSortingValue);

        this.resultCount = this.filteredCars.length;

        this.preparePagiantion();

        this.$nextTick(function(){
            this.$nextTick(function(){
                eventBus.$emit("handlePageAnimations");
            });
        });

        this.saveFilterStateForLater();
    }

    doUpdateFilterCounts() {
        let noTypeCars = [];
        let noEnergyCars = [];
        let noNewOrUsedCars = [];
        let noEngineCars = [];
        let noGearCars = [];
        let noMaekeCars = [];

        for (const element of this.cars) {
            if (this.carFitsFilter(element, this.selectedFilterTags)) {
                this.filteredCars.push(element);
            }
            if (this.carFitsFilter(element, this.selectedFilterTags, ["type"])) {
                noTypeCars.push(element);
            }
            if (this.carFitsFilter(element, this.selectedFilterTags, ["energy"])) {
                noEnergyCars.push(element);
            }
            if (this.carFitsFilter(element, this.selectedFilterTags, ["engine"])) {
                noEngineCars.push(element);
            }
            if (this.carFitsFilter(element, this.selectedFilterTags, ["gear"])) {
                noGearCars.push(element);
            }
            if (this.carFitsFilter(element, this.selectedFilterTags, ["new-used"])) {
                noNewOrUsedCars.push(element);
            }
            if (this.carFitsFilter(element, this.selectedFilterTags, ["marke"])) {
                noMaekeCars.push(element);
            }
        }

        this.resetOptionItemsCount(this.typeOptions);
        this.resetOptionItemsCount(this.energyMarkOptions);
        this.resetOptionItemsCount(this.newOrUsedOptions);
        this.resetOptionItemsCount(this.maerkeOptions);
        this.resetOptionItemsCount(this.engineOptions);
        this.resetOptionItemsCount(this.gearOptions);
        for(const element of this.maerkeOptions) {
            this.resetOptionItemsCount(element.children);
        }

        //ADD COUNT ON SELECTED FILTERS
        for(const element of noEnergyCars) {
            this.addOptionItemCountByName(this.energyMarkOptions, element.energy);
        }
        for(const element of noTypeCars) {
            this.addOptionItemCountByName(this.typeOptions, element.frame_type);
        }
        for(const element of noEngineCars) {
            let value = 'engine-' + this.stringifyValue(element.fuel_type);
            this.addOptionItemCountByValue(this.engineOptions, value);
        }
        for(const element of noGearCars) {
            this.addOptionItemCountByName(this.gearOptions, element.transmission_type);
        }
        for(const element of noNewOrUsedCars) {
            if (element.new_car) {
                this.addOptionItemCountByValue(this.newOrUsedOptions, 'new-used-new');
            }
            if (element.used){
                this.addOptionItemCountByValue(this.newOrUsedOptions, 'new-used-used');
            }

        }
        for(const element of noMaekeCars) {
            this.addOptionItemCountByName(this.maerkeOptions, element.make.name);
            for (let j = 0; j < this.maerkeOptions.length; j++) {
                let children = this.maerkeOptions[j].children;
                this.addOptionItemCountByName(children, element.model.name);
            }
        }
    }

    onMobileFilterClick(){
        this.isViewInPreFilterMode = false;
        this.isFullFilterOpen = true;

        this.saveFilterStateForLater();
    }

    saveFilterStateForLater(){
        var queryTags="";
        for(var i=0; i<this.selectedFilterTags.length; i++){
            var tag = this.selectedFilterTags[i];
            if (i>0) queryTags+=",";
            queryTags+=tag.key;
        }

        if (queryTags.length) {
            this.$router.push({path: this.$route.path, query: {filter: queryTags}});
        } else {
            this.$router.push({path: this.$route.path});
        }
        
        // $router.replace({ path: $route.path, query: { plan: 'private' } }
        var data = {
            "isViewInPreFilterMode" : this.isViewInPreFilterMode,
            "selectedFilterTags" : this.selectedFilterTags,
            
            "savedIntervals" : {
                "monthlyCurrentFrom" : this.monthlyCurrentFrom,
                "monthlyCurrentTo" : this.monthlyCurrentTo,
                "firstCurrentFrom" : this.firstCurrentFrom,
                "firstCurrentTo" : this.firstCurrentTo,
                "taxCurrentFrom" : this.taxCurrentFrom,
                "taxCurrentTo" : this.taxCurrentTo
            }
        }

        let filterKey = "carListViewInPreFilterModePrivate";
        if (this.isCorporate) {
            filterKey = "carListViewInPreFilterModeCorporate";
        }

        // localForage.setItem(filterKey, data);

        this.$cookies.set(filterKey, data, 0);
    }

    setAllOptions(options, checked){
        for(var i=0; i<options.length; i++){
            options[i].checked = checked;
        }
    }
    setOption(options, value, checked){
        for(var i=0; i<options.length; i++){
            if (options[i].value == value){
                options[i].checked = checked;
            }
        }
    }
    onPrefilterChanged(data){
        if (data.monthly && data.monthly.to){
            this.monthlyInitFrom = data.monthly.from;
            this.monthlyInitTo = data.monthly.to;
            this.monthlyCurrentFrom = data.monthly.from;
            this.monthlyCurrentTo = data.monthly.to;
        }


        this.setAllOptions(this.typeOptions, false);
        this.setAllOptions(this.maerkeOptions, false);
        this.setAllOptions(this.engineOptions, false);

        if (data.type){
            for(var i=0; i<data.type.length; i++){
                this.setOption(this.typeOptions, data.type[i].value, true);
            }
        }
        if (data.maerke){
            for(var i=0; i<data.maerke.length; i++){
                this.setOption(this.maerkeOptions, data.maerke[i].value, true);
            }
        }
        if (data.engine){
            for(var i=0; i<data.engine.length; i++){
                this.setOption(this.engineOptions, data.engine[i].value, true);
            }
        }

        
        

        this.$nextTick(function(){
            this.changedFilter(null);
        });
    }
    onPreFilterSearchClick(data){
        // alert(JSON.stringify(data));
        if (data.monthly && data.monthly.to){
            //Have preselected Monthly interval
            this.monthlyInitFrom = data.monthly.from;
            this.monthlyInitTo = data.monthly.to;
            this.monthlyCurrentFrom = data.monthly.from;
            this.monthlyCurrentTo = data.monthly.to;
        }
        if (data.type){
            for(var i=0; i<data.type.length; i++){
                this.setOption(this.typeOptions, data.type[i].value, true);
            }
        }
        if (data.maerke){
            for(var i=0; i<data.maerke.length; i++){
                this.setOption(this.maerkeOptions, data.maerke[i].value, true);
            }
        }
        if (data.engine){
            for(var i=0; i<data.engine.length; i++){
                this.setOption(this.engineOptions, data.engine[i].value, true);
            }
        }
        
        this.isViewInPreFilterMode = false;

        this.saveFilterStateForLater();
    }

    openFullFilter(e){
        if (e) e.preventDefault();
        this.isFullFilterOpen = true;
    }

    closeFullFilter(e){
        if (e) e.preventDefault();
        this.isFullFilterOpen = false;
    }
    resizeFilterTagsListScrollbar(){
        
        if (this.$refs.filterTagsList){
            var el = (this.$refs.filterTagsList as HTMLElement);
            var sl = el.scrollLeft;
            var sw = el.scrollWidth;
            var ew = el.offsetWidth;

            if (sw<=ew){
                if (this.$refs.tagListScroll){
                    (this.$refs.tagListScrollbar as HTMLElement).classList.add("hidden");
                }
            }else{
                if (this.$refs.tagListScroll){
                    (this.$refs.tagListScrollbar as HTMLElement).classList.remove("hidden");

                    (this.$refs.tagListScroll as HTMLElement).style.left = (sl/sw*100)+'%';
                    (this.$refs.tagListScroll as HTMLElement).style.width = (ew/sw*100)+'%';
                }
            }
        }
    }
    filterTagsListScroll(e){
        this.resizeFilterTagsListScrollbar();
    }

    // UI SELECTIONS


    // Get Filtered Cars
    GetFilteredList() {
        return this.filteredCars;
    }

    // UI SELECTIONS END

    beforeMount(): void {
        let ele = (document.querySelector("#scroll-start") as HTMLElement);
        if (!isNullOrUndefined(ele)) {
            let VueScrollTo = require('vue-scrollto');
            VueScrollTo.scrollTo(ele);
        }

        this.userData.showMiniFormFunc(this.userData.epiData.hideElementId);

        //epi-server specifik data
        this.isCorporate = this.userData.isCorporate;
        this.isReleasing = this.userData.isUsed;
    }

    get filteredList() {
        return this.GetFilteredList();
    }

    created() {
        
        var th = this;
        this.$watch(
        () => this.selectedFilterTags,
        (toParams, previousParams) => {

            this.saveFilterStateForLater();
            
            this.$nextTick(function(){
                this.resizeFilterTagsListScrollbar();
            });
        }
        );
        this.$watch(
        () => this.isViewInPreFilterMode,
        (toParams, previousParams) => {
            if (toParams === false){
                this.$nextTick(function(){
                    this.changedFilter(null);
                });
            }
        }
        );

        this.responsesToWait = 2;
        setTimeout(() => {
            if (this.userData.epiData.leasingType == "Erhvervsleasing") {
                this.getVehiclesViaRest(this.getCorporateVehiclesServiceUrl+"?size=1000");
                this.getVehiclesViaRest(this.getCorporateVehiclesServiceUrl+"?used=true&size=1000");
            }
            else if (this.userData.epiData.leasingType == "Forhandler") {
                this.getVehiclesViaRest(this.getConsumerVehiclesServiceUrl+"?size=1000");
                this.getVehiclesViaRest(this.getConsumerVehiclesServiceUrl+"?used=true&size=1000");
            }
            else if (this.userData.epiData.leasingType == "Releasing") {
                this.getVehiclesViaRest(this.getReleasingVehiclesServiceUrl+"?size=1000");
                this.getVehiclesViaRest(this.getReleasingVehiclesServiceUrl+"?used=true&size=1000");
            }
            else {
                this.getVehiclesViaRest(this.getConsumerVehiclesServiceUrl+"?size=1000");
                this.getVehiclesViaRest(this.getConsumerVehiclesServiceUrl+"?used=true&size=1000");
            }
        }, 500);
        
        window.addEventListener('resize', this.handleResize);
        window.removeEventListener('popstate', this.popstate);
    }

    popstate() {
        // localForage.setItem("backFromCarView", true);
        
    }

    destroyed() {
        window.addEventListener('popstate', this.popstate);
        
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize(){
        this.resizeFilterTagsListScrollbar();
    }
}
