import { Component, Vue } from 'vue-property-decorator';

import template from './header-banner.vue';

@Component({
  mixins: [template],
  props: ['cmsGlobalData', 'cmsPageData']
})
export default class ComponentHeader extends Vue {

  get GetCurrentRouteName() {
    return this.$route.name;
  }
}