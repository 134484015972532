<template>
    <div class="fixed-bottom bottom-content basket abTotalPriceBar" id="basket" :class="{'basket-hidden': hideBasket}">
        <div class="footer-content pb-4" v-if="calculatedContract.Leasing !== undefined">

            <div class="row">
                <div class="col-12 text-md-center no-gutters">
                    <div class="url__menu text-nowrap" @click="toggle" role="button" v-if="!isCorporate">
                        <img :src="toogleIcon" class="mr-1 mb-1" style="width:15px" />
                        <span>{{toogleText}}</span>
                    </div>
                    <div v-if="isCorporate" class="pt-3 pb-2"><span class="secondary-font__regular font-italic">Alle priser er ekskl. moms.</span></div>
                </div>
            </div>

            <div class="row mx-1">
                <div class="col-12 col-md-6 col-lg-7 col-xl-8 show-vehicle-details">
                    <div class="row">
                        <span class="col-12 secondary-font__xlarge mb-md-2">
                            {{detailedCar.make.name +" "+detailedCar.model.name +" "+ detailedCar.variant}}
                        </span>
                        <span class="col-12 secondary-font__xlarge mb-md-2">
                            {{$store.getters.getCreatedContract.Contract.Period}} måneder leasingperiode
                        </span>
                        <span class="col-12 secondary-font__xlarge">
                            {{formatPrice($store.getters.getCreatedContract.Contract.Leasing.AnnualMileage)}} km. om året
                        </span>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-5 col-xl-4">

                    <div class="row align_center">
                        <div class="col-6 text-left">
                            <span class="secondary-font__xlarge">Månedlig ydelse</span>
                        </div>
                        <div class="col-6 text-right text-nowrap">
                            <span class="primary-font__bold__large animate-totalMonthlyPayment"></span>
                        </div>
                    </div>

                    <div class="row align_center">
                        <div class="col-6 text-left">
                            <span class="secondary-font__xlarge">Førstegangsydelse</span>
                        </div>
                        <div class="col-6 text-right text-nowrap">
                            <div class="primary-font__bold__large animate-downPayment"></div>
                        </div>
                    </div>

                </div>


            </div>

        </div>

        <transition name="slide">
            <div class="basket-content text-left secondary-font__large" v-if="isOpen">

              <div class="row mb-5 mt-3 mx-1 py-3"><div class="col-12 border-line"></div></div>

              <div class="row mx-1 pt-2">
                <!-- 1. COLUMN -->
                <div :class="details_column_specification">
                  <div class="row pb-3">
                    <div class="col-12"><span class="font-weight-bold">Specifikationer</span></div>
                  </div>
                  <div class="row" v-if="detailedCar.fuel_type.toLowerCase() != 'el'">
                    <div class="col-7"><span>Brændstofsforbrug</span></div>
                    <div class="col-5 text-right"><span>{{ detailedCar.fuel_economy }} km/l</span></div>
                  </div>
                  <div class="row" v-if="detailedCar.fuel_type.toLowerCase() == 'el'">
                    <div class="col-7"><span>Rækkevidde</span></div>
                    <div class="col-5 text-right"><span>{{ getRange() }} km</span></div>
                  </div>
                  <div class="row">
                    <div class="col-7"><span>CO<sub>2</sub> udledning</span></div>
                    <div class="col-5 text-right"><span>{{ detailedCar.co2 }} g/km</span></div>
                  </div>
                  <div class="row">
                    <div class="col-7"><span>Euro NCAP</span></div>
                    <div class="col-5 text-right"><span>{{ detailedCar.ncap }}</span></div>
                  </div>
                  <div class="row">
                    <div class="col-7"><span>Max påhængsvægt</span></div>
                    <div class="col-5 text-right"><span>{{ detailedCar.trailer_max_weight ? detailedCar.trailer_max_weight : '0' }} kg</span></div>
                  </div>
                </div>

                <!-- 2. COLUMN -->
                <div :class="details_column_specification" v-if="false">
                  <template v-if="!isCorporate">
                    <div class="row pb-3">
                      <div class="col-12"><span class="font-weight-bold">Førstegangsydelse</span></div>
                    </div>
                    <div class="row" v-if="calculatedContract.Leasing.DownPayment != null">
                      <div class="col-7"><span>Engangsydelse</span></div>
                      <div class="col-5 text-right"><span>{{formatPrice(calculatedContract.Leasing.DownPayment.IncVat) + ' kr.'}}</span></div>
                    </div>
                    <div class="row" v-if="calculatedContract.Leasing.InitialFeeVatPrice != null">
                      <div class="col-7"><span class="secondary-font__large">Stiftelsesomk.</span></div>
                      <div class="col-5 text-right"><span>{{formatPrice(calculatedContract.Leasing.InitialFeeVatPrice.IncVat) + ' kr.'}}</span></div>
                    </div>
                    <div class="row" v-if="calculatedContract.Leasing.InsuranceAdministration != null">
                      <div class="col-7"><span>Forsikringsadmin.</span></div>
                      <div class="col-5 text-right"><span>{{formatPrice(calculatedContract.Leasing.InsuranceAdministration) + ' kr.'}}</span></div>
                    </div>
                    <div class="row pt-3" v-if="calculatedContract.Leasing.PrePaymentVatPrice">
                      <div class="col-7"><span>I alt</span></div>
                      <div class="col-5 text-right"><span>{{formatPrice(calculatedContract.Leasing.PrePaymentVatPrice.IncVat) + ' kr.'}}</span></div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="row" v-if="calculatedContract.Leasing.TaxValueInclVat != null">
                      <div class="col-7"><span>Beskatningsværdi</span></div>
                      <div class="col-5 text-right"><span>{{formatPrice(calculatedContract.Leasing.TaxValueInclVat)}} kr.</span></div>
                    </div>
                    <div class="row" v-if="calculatedContract.Leasing.TaxValueInclVatMonthly">
                      <div class="col-7"><span>Månedlig beskatning<br/>(inkl. miljøtillæg)</span></div>
                      <div class="col-5 text-right"><span>{{formatPrice(calculatedContract.Leasing.TaxValueInclVatMonthly)}} kr.</span></div>
                    </div>
                  </template>

                </div>

                <!-- 3. COLUMN -->
                <div :class="details_column_specification">
                  <div class="row pb-3">
                    <div class="col-12"><span class="font-weight-bold">Månedlig ydelse</span></div>
                  </div>
                  <div class="row">
                    <div class="col-7"><span>I alt</span></div>
                    <div class="col-5 text-right"><span>{{formatPrice(calculatedContract.Leasing.TotalMonthlyPaymentVatPrice[isCorporate ? 'ExVat' : 'IncVat']) + ' kr.'}}</span></div>
                  </div>
                  <div class="row">
                    <div class="col-12"><span class="font-italic">(inkl. service, vejhjælp og ekstraudstyr)</span></div>
                  </div>
                  <div class="row pt-3">
                    <div class="col-7"><span class="mr-2">Anslået brændstof</span>
                      <tooltip-component :header="'Anslået brændstof'"
                                         :body="'Årlig kilometer divideret med forbrug, gange literpris for brændstof, divideret med 12 måneder.<br /><br />Eksempel: 15.000 km/år / 16,9 km/l = 888 l/år x ' + formatPrice(calculatedContract.Leasing.TcOFuelPriceGasoline) + ' kr/l = ' + formatPrice(calculatedContract.Leasing.TcOFuelPriceGasoline * 888) + ' kr/år svarende til '+ formatPrice(calculatedContract.Leasing.TcOFuelPriceGasoline * 888 / 12) + ' kr/måned'">
                      </tooltip-component>
                    </div>
                    <div class="col-5 text-right"><span>{{formatPrice(calculatedContract.Leasing.FuelUsageMonthlyVatPrice[isCorporate ? 'ExVat' : 'IncVat']) + ' kr.'}}</span></div>
                  </div>
                  <div class="row">
                    <div class="col-7"><span class="mr-2">Anslået grøn ejerafgift</span>
                      <tooltip-component :header="'Anslået grøn ejerafgift'"
                                         :body="'Grøn ejerafgift, beregnes ud fra bilens brændstofforbrug og opkræves halvårligt hos leasingtageren. Grøn ejerafgift divideret med 6 måneder.<br /><br />Eksempel: 640 kr. / 6 måneder = 106,66 kr.'">
                      </tooltip-component>
                    </div>
                    <div class="col-5 text-right"><span>{{formatPrice(calculatedContract.Leasing.OwnerFee/6) + ' kr.'}}</span></div>
                  </div>
                  <div class="row pt-3" v-if="isCorporate">
                    <div class="col-12 font-italic"><span>Alle priser er inkl. moms.</span></div>
                  </div>
                </div>

                <!-- 4. COLUMN -->
                <div :class="details_column_specification">
                  <div class="row">
                    <div class="col-12 pb-3">
                      <span class="font-weight-bold mr-2">Totalomkostninger</span>
                      <tooltip-component :header="'Totalomkostninger'"
                                         :body="'Summen udregnes sådan: Md. ydelse og administrationshonorar ganget med leasingperioden (antal md.), plus førstegangsydelse og tilbageleveringsgebyr.'">
                      </tooltip-component>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-7"><span>Førstegangsydelse</span></div>
                    <div class="col-5 text-right"><span>{{formatPrice(calculatedContract.Leasing.PrePayment) + ' kr.'}}</span></div>
                  </div>
                  <div class="row">
                    <div class="col-7"><span>Månedlig ydelse</span></div>
                    <div class="col-5 text-right">
                      <span>{{formatPrice(calculatedContract.Leasing.TotalMonthlyPaymentVatPrice[isCorporate ? 'ExVat' : 'IncVat']) + ' kr.'}}</span></div>
                  </div>
                  <div class="row" v-if="!isCorporate">
                    <div class="col-7"><span>Månedlig rateadmin.</span></div>
                    <div class="col-5 text-right"><span>{{formatPrice(calculatedContract.Leasing.MonthlyPaymentAdministrationFee.IncVat) + ' kr.'}}</span></div>
                  </div>
                  <div class="row" v-if="!isCorporate">
                    <div class="col-7"><span>Tilbageleveringsgebyr</span></div>
                    <div class="col-5 text-right"><span>{{formatPrice(calculatedContract.Leasing.VehicleReturnFee.IncVat) + ' kr.'}}</span></div>
                  </div>
                  <div class="row pt-3" v-if="!isCorporate">
                    <div class="col-7"><span>I alt ({{$store.getters.getCreatedContract.Contract.Period}}) måneder</span></div>
                    <div class="col-5 text-right"><span>{{formatPrice(basketCompletePayment) + ' kr.'}}</span></div>
                  </div>
                  <div class="row" v-if="isCorporate">
                    <div class="col-7"><span class="secondary-font__large">Moms</span></div>
                    <div class="col-5 text-right"><span>{{formatPrice(calculatedContract.Leasing.VehicleMonthlyPaymentVat)}} kr.</span></div>
                  </div>
                  <template v-if="isCorporate" v-for="i in [0,1,2]">
                    <div class="row" v-if="calculatedContract.Leasing.VatDeductionPeriods[i] !== undefined">
                      <div class="col-7"><span>Momsfradrag {{calculatedContract.Leasing.VatDeductionPeriods[i].FromMonth +" - "+ calculatedContract.Leasing.VatDeductionPeriods[i].ToMonth}} mdr.</span></div>
                      <div class="col-5 text-right"><span>{{formatPrice(calculatedContract.Leasing.VatDeductionPeriods[i].VatDeduction)}} kr.</span></div>
                    </div>
                  </template>
                </div>

              </div>

            </div>
        </transition>
    </div>
</template>
<script lang="ts">
    import Vue from "vue";
    import { Component, Emit, Inject, Model, Prop, Provide, Watch } from "vue-property-decorator";
    import anime from 'animejs';
    import Tooltipcomponent from '../../shared/tooltip/Tooltip.vue';

    import AbAmount from '../../common/ab_amount';

    @Component({
        name: 'leasing-basket-component',
        components: {
            'tooltip-component': Tooltipcomponent
        }
    })
    export default class Basket extends Vue {
        
        @Prop() detailedCar: any;
        @Prop() calculatedContract: any;
        @Prop() basketSelectedItems: any[];
        @Prop() basketCompletePayment: number;
        @Prop() basketEquipmentPriceTotal: number;
        @Prop() basketMonthlyPaymentWithoutExtra: number;
        @Prop() isCorporate: boolean;

        details_column_specification: string = 'col-md-6 col-lg-4 col-12 mb-5';
        //details_column_specification: string = 'col-sm-6 col-md-4 col-12 mb-5';

        //toggle til styring af indkøbskurv visning
        hideBasket: boolean = true;
        //indkøbskurv toggle knap
        isOpen: boolean = false;
        toogleText: string = 'Se detaljer';
        toogleIcon = require("@/components/leasing/Showroom/public/ui/assets/svg/bullet_plus_blue.svg");

        animated: any = {
            downPayment: '',
            totalMonthlyPayment: ''
        };

        abAmount = new AbAmount();

        getRange() {
          let range: string = '0';
          if (this.detailedCar && Array.isArray(this.detailedCar.standard_equipment)){
            let regex = /^rækkevidde\s+(\d+)$/gi;
            for (let itm of this.detailedCar.standard_equipment){
              let m: Array<string> = [...itm.matchAll(regex)];
              if (m.length > 0){
                range = m[0][1];
                break;
              }
            }
          }
          return range;
        }

        formatPrice(value: any) {
            if (value === undefined) {
                return 0;
            }

            return this.abAmount.format(value);
        }

        /*PRICE BASKET START */
        open() {
            this.toogleText = 'Skjul detaljer';
            this.toogleIcon = require("@/components/leasing/Showroom/public/ui/assets/svg/bullet-negative_blue.svg");
            this.isOpen = true;
        }
        close() {
            this.toogleText = 'Se detaljer';
            this.toogleIcon = require("@/components/leasing/Showroom/public/ui/assets/svg/bullet_plus_blue.svg");
            this.isOpen = false;
        }
        toggle() {
            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        }
    /*PRICE BASKET END */


        @Watch('calculatedContract.Leasing.PrePaymentVatPrice.IncVat')
        downPaymentAnimateIncVat(newValue: number, oldValue: number) {
            // console.log("Dennis", "anime failed", newValue, oldValue)
            if (newValue === undefined || newValue === oldValue || this.isCorporate) {
                return;
            }
            if (oldValue !== undefined) {
                this.animated.downPayment = oldValue;
            }

            anime({
                targets: this.animated,
                downPayment: newValue,
                round: true,
                easing: 'easeInOutExpo',
                update: function (a: any) {
                    var el = document.querySelector('.animate-downPayment');
                    let objFormat = { "locale": "da", "maxDigits": 0 };
                    el.innerHTML = a.animations[0].currentValue.toLocaleString(objFormat.locale, { maximumFractionDigits: objFormat.maxDigits }) + ' kr.';
                }
            });
        }

        @Watch('calculatedContract.Leasing.PrePaymentVatPrice.ExVat')
        downPaymentAnimateExVat(newValue: number, oldValue: number) {
            // console.log("Dennis", "anime failed", newValue, oldValue)
            if (newValue === undefined || newValue === oldValue || !this.isCorporate) {
                return;
            }
            if (oldValue !== undefined) {
                this.animated.downPayment = oldValue;
            }

            anime({
                targets: this.animated,
                downPayment: newValue,
                round: true,
                easing: 'easeInOutExpo',
                update: function (a: any) {
                    var el = document.querySelector('.animate-downPayment');
                    let objFormat = { "locale": "da", "maxDigits": 0 };
                    el.innerHTML = a.animations[0].currentValue.toLocaleString(objFormat.locale, { maximumFractionDigits: objFormat.maxDigits }) + ' kr.';
                }
            });
        }

        @Watch('calculatedContract.Leasing.TotalMonthlyPaymentInclVat')
        totalMonthlyPaymentInclVatAnimate(newValue: number, oldValue: number) {
            // console.log("Dennis", "anime failed", newValue, oldValue)
            if (newValue === undefined || newValue === oldValue || this.isCorporate) {
                return;
            }
            if (oldValue !== undefined) {
                this.animated.totalMonthlyPayment = oldValue;
            }

            anime({
                targets: this.animated,
                totalMonthlyPayment: newValue,
                round: true,
                easing: 'easeInOutExpo',
                update: function (a: any) {
                    var el = document.querySelector('.animate-totalMonthlyPayment');
                    let objFormat = { "locale": "da", "maxDigits": 0 };
                    el.innerHTML = a.animations[0].currentValue.toLocaleString(objFormat.locale, { maximumFractionDigits: objFormat.maxDigits }) + ' kr.';
                }
            });
        }

        @Watch('calculatedContract.Leasing.TotalMonthlyPayment')
        totalMonthlyPaymentAnimate(newValue: number, oldValue: number) {
            // console.log("Dennis", "anime failed", newValue, oldValue)
            if (newValue === undefined || newValue === oldValue || !this.isCorporate) {
                return;
            }
            if (oldValue !== undefined) {
                this.animated.totalMonthlyPayment = oldValue;
            }

            anime({
                targets: this.animated,
                totalMonthlyPayment: newValue,
                round: true,
                easing: 'easeInOutExpo',
                update: function (a: any) {
                    var el = document.querySelector('.animate-totalMonthlyPayment');
                    let objFormat = { "locale": "da", "maxDigits": 0 };
                    el.innerHTML = a.animations[0].currentValue.toLocaleString(objFormat.locale, { maximumFractionDigits: objFormat.maxDigits }) + ' kr.';
                }
            });
        }

        created() {
            window.addEventListener('scroll', () => this.handleScroll());
            // console.log("Dennis", "Created", this.calculatedContract)
        }

        destroyed() {
            window.removeEventListener('scroll', () => this.handleScroll());
        }

        handleScroll() {
            /*           
              var rect = div.getBoundingClientRect ();
              x = rect.left;
              y = rect.top;
              w = rect.right - rect.left;
              h = rect.bottom - rect.top;
            */
            let height = document.documentElement.clientHeight;
            //Vis menu fra div med følgende id
            let _breakShow = document.querySelector('#breakShow');
            if (_breakShow !== null) {
                var rect = _breakShow.getBoundingClientRect();
                //console.log(rect.top + " < " + height);
                if (rect.top < height) {
                    this.hideBasket = false;

                    //Sørg for at Contact elementet bevæger sig relativt til basket-bjælken for denne kontekst
                    let contactElm = document.getElementById("abContact__levelOne");
                    if (contactElm !== null && contactElm !== undefined) {
                        contactElm.classList.add('abContact__moveUpOverStickyBottom--leasing')
                    }

                    //Skjul menu fra div med følgende id
                    let _breakHide = document.querySelector('.footer__nav');
                    if (_breakHide !== null) {
                        let { top } = _breakHide.getBoundingClientRect();
                        if (top < height) {
                            this.hideBasket = true;

                            //Sørg for at Contact elementet bevæger sig relativt til basket-bjælken for denne kontekst
                            if (contactElm !== null && contactElm !== undefined) {
                                contactElm.classList.remove('abContact__moveUpOverStickyBottom--leasing')
                            }

                        } else {
                            this.hideBasket = false;

                            if (contactElm !== null && contactElm !== undefined) {
                                //Sørg for at Contact elementet bevæger sig relativt til basket-bjælken for denne kontekst
                                contactElm.classList.add('abContact__moveUpOverStickyBottom--leasing')
                            }
                        }
                    }
                    
                } else {
                    this.hideBasket = true;

                    //Sørg for at Contact elementet bevæger sig relativt til basket-bjælken for denne kontekst
                    let contactElm = document.getElementById("abContact__levelOne");
                    if (contactElm !== null && contactElm !== undefined) {
                        contactElm.classList.remove('abContact__moveUpOverStickyBottom--leasing')
                    }
                }
            }
        }

    }

</script>
<style lang="scss" scoped>
@import "../../abdk/styles/legacy/style.scss";

    .show-vehicle-details {
        text-align: left;
        @media (max-width: $medium-width) {
            display: none;
        }
    }

    .bottom-content {
        @media (max-width: $medium-width) {
            /**scroll enable i mobil skærm størrelse*/
            max-height: 500px; overflow-y: scroll;
        }
    }

    .footer-content {
        //padding: 0.5em 1em 1em;
        //max-width: 1600px;
        //margin-right: auto;
        //margin-left: auto;
    }

    .basket-content {
        //padding: 1em;
        //max-width: 1600px;
        //margin-right: auto;
        //margin-left: auto;
    }

    .border-line {
        border-top: 1px solid $color-secondary;
    }

    /**Indkøbskurv transitions*/
    .slide-enter-active, .slide-leave-active {
        transition: margin-bottom .4s linear;
    }

    .slide-enter, .slide-leave-to {
        margin-bottom: -200px;
    }

    .slide-enter-to, .slide-leave {
        margin-bottom: 0px;
    }


    /**Indkøbskurv fade in/ fade out
    @keyframes show {
        0% {
            display: none;
            opacity: 0;
        }

        1% {
            display: block;
        }

        100% {
            display: block;
            opacity: 1;
            z-index: 10;
        }
    }

    @keyframes hide {
        0% {
            display: block;
            opacity: 1;
        }

        99% {
            display: block;
        }

        100% {
            display: none;
            opacity: 0;
            z-index: -1;
        }
    }

    .basket {
        animation: show 500ms linear;
        animation-fill-mode: forwards;
    }

    .basket-hidden {
        animation: hide 500ms linear;
        animation-fill-mode: forwards;
    }*/


    .basket {
        display: block;
        /* Sæt z-index sådan at Contactelementets level 2 kommer til at stå ovenpå Basket elementet*/
        z-index: 1 !important;
        background: #053951;
        color: white;

    }

    .basket-hidden {
        display: none;
    }

    // Temporary hiding column 1
    .hideMe {
        display: none;
    }

</style>
