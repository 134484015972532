import * as localForage from "localforage";

import AbCookieBanner from "./ab_cookiebanner";
import Axios from "axios";
import AxiosRetry from 'axios-retry';
import LeasingCustomer from "@/components/leasingpro/components/overview/leasing/store/modules/leasingContext";
import dataSource from "./store/modules/dataSource";
import { isNullOrUndefined } from "is-what";

/**
 * axios openAPI Generator https://openapi-generator.tech/docs/generators/typescript-axios
 */
class AbAxiosLeasingPro {
    source: any;

    private axios = Axios.create({
        timeout: 60000,
    });
    private headers;
    private abaxiosRetry = AxiosRetry;
    private abCookieHandler = new AbCookieBanner();

    private csrfToken: string;
    private baseUrl: string;

    private superAdmin = {
        company: '',
        cvr: '',
        name: '',
        isAdmin: false,
        activeDashboard: false,
        relations: [
            {
                company: '',
                cvr: '',
            }
        ]
    }

    constructor(baseUrl: string = window.location.origin, contentType: string = 'application/json; charset=utf-8', csrf: string = null, xapi: string = null) {
        // this.abAxios.defaults.baseURL = location.origin + baseUrl;
        // console.log("window.location.hostname=" + window.location.hostname);
        if (window.location.hostname === 'www-stage.opendo.dk') {
            this.axios.defaults.baseURL = 'https://api-stage.opendo.dk';
        } else if (window.location.hostname === 'www-test.opendo.dk' || window.location.hostname === "localhost") {
            this.axios.defaults.baseURL = 'https://api-test.opendo.dk';
        } else {
            this.axios.defaults.baseURL = 'https://api.opendo.dk';
        }
        // console.log('Selected host: ' + this.axios.defaults.baseURL);

        this.axios.defaults.headers.common['Content-Type'] = contentType;
        this.csrfToken = csrf;
    }

    private setupAxios(contentType: string = 'application/json') {
        this.headers = {
            "x-csrf-token": this.csrfToken,
            'x-sales-channel': 'web',
            post: {
                'Content-Type': contentType,
                'Accept': 'application/json',
            }
        };

        this.axios.interceptors.request.use((config:any) => {
            config.headers = this.getHeaders();
            if (config.method === 'post' || config.method === 'put' || config.method === 'delete') {
                // console.log('Intercept request', config);
                this.abaxiosRetry(this.axios, { retries: 0 });
                config.timeout = 20000;
            }
            return config;
        });
    }

    private async buildSecurityHeader(contentType: string = "application/json") {
        let headers: any = null;

        await localForage.getItem("token").then((token) => {
            if (token) {
                headers = {
                    "headers": {
                        // "x-csrf-token": this.csrfToken,
                        "Authorization": "Bearer " + token,
                        'Content-Type': contentType
                    }
                }
            }
        }).catch((err) => {
            console.log(err);
        });

        return headers;
    }

    private getHeaders(): Object {
        const custNum = this.abCookieHandler.getCookie("custNum");
        if (!isNullOrUndefined(custNum) && custNum.length > 0) {
            this.headers["x-use-test-customer-number"] = this.abCookieHandler.getCookie("custNum");
        } else {
            delete this.headers["x-use-test-customer-number"];
        }
        return this.headers;
    }

    public async getLeasingUserProfile(): Promise<any> {
        let headers = await this.buildSecurityHeader();
        return await this.axios.get('/leasing-reporting/profile', headers);
    }

    // GET REPORTS
    public async getReport(reportID: string, dateTime_from, dateTime_to, dateTime_fixed, fileType: string, cvr: string) {
        var self = this;
        const path = '/leasing-reporting/reports/' + reportID + '?from=' + dateTime_from + '&to=' + dateTime_to + '&fixed=' + dateTime_fixed + '&fileType=' + fileType + '&cvr=' + cvr;
        let headers = await this.buildSecurityHeader();

        let navigator: any;
        navigator = window.navigator;

        return this.axios.get(path, {
            headers: headers.headers,
            responseType: 'arraybuffer',
            timeout: 600000,
        })
            .then((response) => {
                if (navigator && navigator.msSaveOrOpenBlob) {
                    const url = navigator.msSaveOrOpenBlob(new Blob([response.data]));
                    return { response, url };
                } else {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    return { response, url };
                }
            })
            .catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                    self.handleApiError(thrown);
                    return thrown;
                }
            })
    }

    // GET CARS DATA
    public async getCars(cvr: string) {
        var self = this;
        const path = '/leasing-reporting/carStatistics/fleetVehicleList/' + cvr;
        let headers = await this.buildSecurityHeader();

        return await this.axios.get(path, headers)
            .then((response) => {
                return response;
            })
            .catch(function (thrown) {
                self.handleApiError(thrown);
                return thrown;
            })
    }

    // GET FUEL DATA
    public async getFuel() {
        var self = this;
        let headers = await this.buildSecurityHeader();
        let cvr = LeasingCustomer.state.leasingCustomer.cvr;
        const path = '/leasing-reporting/fuelconsumptionvehiclelist/' + cvr;

        return await this.axios.get(path, headers)
            .then((response) => {
                return response;
            })
            .catch(function (thrown) {
                self.handleApiError(thrown);
                return thrown;
            })
    }
    // GET KM DATA
    public async getKm() {
        var self = this;
        let headers = await this.buildSecurityHeader();
        let cvr = LeasingCustomer.state.leasingCustomer.cvr;
        const path = '/leasing-reporting/kmdeviationsvehiclelist/' + cvr;

        return await this.axios.get(path, headers)
            .then((response) => {
                return response;
            })
            .catch(function (thrown) {
                self.handleApiError(thrown);
                return thrown;
            })
    }

    // GET ADMIN RLEATIONS DATA
    public async getAdminRelations() {
        var self = this;
        let headers = await this.buildSecurityHeader();
        const path = '/leasing-reporting/carStatistics/admin/companies';

        return this.axios.get(path, {
            headers: headers.headers,
            responseType: 'json',
        })
        .then((response) => {
            // Storing Admin Relations
            this.superAdmin.relations = response.data.relations;
            return response;
        })
        .catch(function (thrown) {
            self.handleApiError(thrown);
            return thrown;
        })
    }

    // GET FLEET
    public getFleet(cvr: string) {
        var self = this;
        const path = this.baseUrl + '/leasing-reporting/api/carStatistics/fleetDevelopment/' + cvr;

        return this.axios.get(path, {
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        })
            .then((response) => {
                return response;
            })
            .catch(function (thrown) {
                self.handleApiError(thrown);
                return thrown;
            })
    }

    // GET FLEET
    public getFleetCharts(apiPath: string, cvr: string, parameters: object = {}) {
        var self = this;
        const path = this.baseUrl + '/leasing-reporting/api/' + apiPath + '/' + cvr;

        return this.axios.get(path, {
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            params: parameters
        })
            .then((response) => {
                return response;
            })
            .catch(function (thrown) {
                self.handleApiError(thrown);
                return thrown;
            })
    }

    // GET FUEL DATA
    public async getFuelData(cvr: string, noMonths: number) {
        var self = this;
        const path = this.baseUrl + '/leasing-reporting/api/carStatistics/fleetFuelConsumptionDevelopment/' + cvr + '?numberOfMonths=' + noMonths;
        let headers = await this.buildSecurityHeader();

        return this.axios.get(path, headers)
            .then((response) => {
                return response.data;
            })
            .catch(function (thrown) {
                self.handleApiError(thrown);
                return thrown;
            })
    }

    // GET BLOCK DATA
    public async getBlockData(chartId: string) {
        var self = this;
        let headers = await this.buildSecurityHeader();
        let cvr = LeasingCustomer.state.leasingCustomer.cvr;
        // console.log(chartId)
        let path = '';

        // Mainpage
        if (chartId === 'fleet_development_card') {
            path = '' + cvr
        } else if (chartId === 'fuel_consumption_card') {
            path = '/leasing-reporting/fuelconsumptioncardgraph/' + cvr + '?numberOfMonths=3'
        } else if (chartId === 'km_deviations_card') {
            path = '/leasing-reporting/kmdeviationscard/' + cvr

            // Fuel
        } else if (chartId === 'benzin_consumption_graph') {
            path = '/leasing-reporting/benzinconsumptiongraph/' + cvr + '?numberOfMonths=12'
        } else if (chartId === 'diesel_consumption_graph') {
            path = '/leasing-reporting/dieselconsumptiongraph/' + cvr + '?numberOfMonths=12'
        } else if (chartId === 'fuel_consumption_deviation_graph') {
            path = '/leasing-reporting/fuelconsumptiondeviationgraph/' + cvr
        } else if (chartId === 'fleet_consumption_costs_graph') {
            path = '/leasing-reporting/consumptioncostsgraph/' + cvr

            // CO2
        } else if (chartId === 'co2_emission_per_brand') {
            path = '/leasing-reporting/co2emissionforbrandsgraph/' + cvr
        } else if (chartId === 'co2_distribution') {
            path = '/leasing-reporting/co2distributiongraph/' + cvr + '?years=2'
        } else if (chartId === 'fleet_energy_composition_graph') {
            path = '/leasing-reporting/fleetenergyclasscompositiongraph/' + cvr + '?years=2'
        } else if (chartId === 'expected_co2_emission') {
            path = '/leasing-reporting/expectedco2emissiongraph/' + cvr
        } else if (chartId === 'realized_co2_emission') {
            path = '/leasing-reporting/realizedco2emissiongraph/' + cvr
        } else if (chartId === 'co2_deviation') {
            path = '/leasing-reporting/co2deviationgraph/' + cvr
        } else if (chartId === 'total_co2_emission') {
            path = '/leasing-reporting/totalco2emissiongraph/' + cvr + '?years=2'
        } else if (chartId === 'fleet_co2_emission_graph') {
            path = '/leasing-reporting/co2emissionandenergyclasscard/' + cvr

            // KM
        } else if (chartId === 'km') {
            path = '/leasing-reporting/kmdeviations/' + cvr
        } else {
            path = 'error'
            console.log(chartId)
        }
        return await this.axios.get(path, headers)
            .then((response) => {
                return response;
            })
            .catch(function (thrown) {
                self.handleApiError(thrown);
                return thrown;
            })
    }

    // Send Mails
    public async sendMail(data, cvr, succesCallback, errorFallback) {
        var self = this;
        let headers = await this.buildSecurityHeader();
        const path = '/leasing-reporting/carStatistics/message/' + cvr;
        this.axios({
            method: 'post',
            url: path,
            data: data,
            headers: headers.headers
        })
            .then((response) => {
                succesCallback(response);
            })
            .catch(function (thrown) {
                self.handleApiError(thrown);
                errorFallback(thrown);
            })
    }

    private handleApiError(error: any) {
        if (!isNullOrUndefined(error.response) && error.response.status === 401) {
            localForage.removeItem("token").then(function () {
                window.location.reload();
                //router.replace('/login');
            })
            throw new Error('401 Unauthorized');
        }
    }
}

export default AbAxiosLeasingPro;
