import { Vue, Component } from "vue-property-decorator";
import template from "./Leasing.vue"
import ShowRoom from '../Showroom/src/App.vue'

@Component({
    mixins:[template],
    components: {
        ShowRoom
    }
  
})
export default class PrivatLeasingShowroom extends Vue {

}