import { Component, Vue } from 'vue-property-decorator';

import template from './LongSlider.vue';

@Component({
  mixins: [template],
  props: ['title', 'slides']
})
export default class PrivatErhvervSelectBlock extends Vue {

}