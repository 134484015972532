class AbCookieBanner {

    private container = document.getElementById("cookiebanner-container")
    private acceptButton = document.getElementById("cookiebanner-acceptbutton");

    constructor() {
        this.init();
    }

    /**
     * Returns the value of a cookie by name if it exists
     * @param cookieName
     */
     public getCookie(cookieName) {
        var i, x, y, cookies = document.cookie.split(";");
        for (i = 0; i < cookies.length; i++) {
            x = cookies[i].substr(0, cookies[i].indexOf("="));
            y = cookies[i].substr(cookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x == cookieName) {
                return unescape(y);
            }
        }
     }

    /**
     * Sets a cookie with the desired name, value and expiration
     * @param name
     * @param value
     * @param exdays
     */
    public setCookie(name: string, value: string, exdays: any) {
        if (typeof (value) !== 'undefined' && name != null) {
            var exdate = new Date();
            exdate.setTime(exdate.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString()) + ";path=/";
            document.cookie = name + "=" + c_value;
        }
    }

    private init(): void {
        if (this.container === null || this.container === undefined || this.acceptButton === null || this.acceptButton === undefined) return;
        if (document.cookie) {
            if (this.getCookie("AB_AC") == undefined) {
                document.onclick = (e) => {
                    this.cookieCallback(e);
                }
            } else if (this.container) {
                // Hack to remove cookie container if cookie can be found, but cookie panel is rendered(?)
                // remove the banner - something is wrong
                this.container.classList.add("cookiebanner__outro");
            }
        } else {
            document.onclick = (e) => {
                this.cookieCallback(e);
            }
        } 
    }


    private isReferrerSame() {
        if (document.referrer) {
            return document.referrer.startsWith(window.location.origin);
        }
        return false;
    }

    private cookieCallback(e) {
        try {
            let target = e.target || e.srcElement;
            if (target.tagName !== "A" && target.tagName !== "BUTTON") {
                if (!this.isReferrerSame()) {
                    return ;
                }
            }
        } catch (err) {
            console.error(err);
        }

        try {
            this.setCookie("AB_AC", "true", 365);
            this.container.classList.add("cookiebanner__outro");
            document.removeEventListener("click", this.cookieCallback);
        } catch(err_) {
            console.error(err_);
            // remove the banner - something is wrong
            try {
                this.container.classList.add("cookiebanner__outro");
            } catch(error_) {
                console.error(error_);
            }
        }
    }


}
export default AbCookieBanner;
