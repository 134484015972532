<template>
  <div v-if="loggedIn">
    <BLHeader/>
    <div class="page-content">
      <div class="wrap fade-on-enter op-fade-in-animation">
        <div class="top-bar">
          <h1 class="h1-1 title">Henvisningsmodul</h1>
          <div class="description">
            <p>Her finder du svar på typiske spørgsmål vi får fra bankrågiverne.</p>
            <p>På Opendo.dk kan du finde svar på de spørgsmål private og erhverskunder stiller om Opendo og leasing.</p>
          </div>
        </div>
        <faq-list :items="faqs" :tags="null"/>
      </div>
    </div>
    <BottomInfoBar :open="openBotomBar" :text="bottomBarText" @onClose="onCloseBottomBar" />
  </div>
</template>
<script>

import BLHeader from "./BLHeader.vue";
import BottomInfoBar from "@/components/ui/bottom-info-bar/BottomInfoBar.vue";
import bankloginData from "../BankloginData";
import FAQList from "../../../components/ui/faq-list/faq-list";

export default {
  components: {
    BottomInfoBar : BottomInfoBar,
    BLHeader : BLHeader,
    "faq-list" : FAQList
  },
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      loggedIn: false,
      openBotomBar: false,
      bottomBarText: '',
      faqs: [
        {'ID': 1, 'post_title': "Er det forventet at jeg som bankrådgiver ved noget om biler eller leasing?", 'content': "Er det forventet at jeg som bankrådgiver ved noget om biler eller leasing?"},
        {'ID': 2, 'post_title': "Er det forventet at jeg som bankrådgiver ved noget om biler eller leasing?", 'content': "Er det forventet at jeg som bankrådgiver ved noget om biler eller leasing?"}
      ]
    }
  },
  beforeMount(){
    
  },
  created(){
    var th = this;
    th.loggedIn = bankloginData.loggedIn;

    if (!bankloginData.loggedIn){
      bankloginData.recoverFromToken(function(){
        th.loggedIn = bankloginData.loggedIn;
      },function(){
        th.loggedIn = bankloginData.loggedIn;
        th.$router.replace({ 'name': "logins" });
      });
    }

  },
  mounted(){
    var th = this;
    
    


    
    
  },
  methods: {
    
    onCloseBottomBar: function(e){
      var th = this;
      this.openBotomBar = false;
    }
    
  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.page-content{
  background: rgba(105, 189, 182, 0.09);
  border-radius: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-bottom: 140px;
}
.top-bar{
  text-align: center;
  margin-bottom: 60px;
  .title{
    margin-bottom: 40px;
  }
  .description{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 133%;
    
    text-align: center;
    color: #4C6C69;

    max-width: 690px;
    margin-left: auto; 
    margin-right: auto;
    p{
      margin-bottom: 1em;
    }
  }
}

</style>