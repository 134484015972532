<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import SsoFormComponent from "@/components/forms/SsoFormComponent.vue";

@Component({
  components: {SsoFormComponent}
})
export default class ExtensionForm extends Vue {
}
</script>

<template>
  <div>
    <sso-form-component requestId="Bestillingsark-forlaengelser" title="Forlængelse - finansiel leasing"></sso-form-component>
  </div>
</template>
