<template>

  
  <div class="document-list-block">
    <div class="wrap">
      <div class="block">
        <div class="left-side">
          <div class="image-wrap" v-if="image">
            <img :src="image" :alt="imageAlt"/>
          </div>
        </div><div class="right-side">
          <h2 class="block-title">{{title}}</h2>
          <div class="block-description" v-html="description"></div>

          <div class="list">
            <div class="list-item" v-for="(item, index) in items" :key="'doc-list-b-'+index">
              <h3 class="title">{{item.title}}</h3>
              <div class="description" v-html="item.description"></div>
              <div class="links" v-if="item.documents && item.documents.length>0">
                <div class="link-wrap" v-for="(doc, index2) in item.documents" :key="'doc-list-b-'+index+'-link-'+index2" >
                  <div v-if="(doc.type=='file') && (doc.file.title=='Bestillingsark-Debitorskifte'||
                       doc.file.title=='Bestillingsark-indfrielser'||doc.file.title=='Bestillingsark-forlaengelser')">
                    <div class="formlink" v-on:click="$refs.documentModal.openModal(doc.file.title, item.title)">
                      Udfyld formular - klik her
                    </div>
                  </div>
                  <div v-else>
                    <a v-if="doc.type=='link'" :href="doc.link.url" :target="doc.link.target">{{doc.link.title}}</a>
                    <a v-if="doc.type=='file'" :href="doc.file.url" :target="'_blank'">{{doc.file.title}}</a>
                  </div>
                </div>
              </div>              
            </div>
          </div>
        </div>
        
      </div>
    </div>

    <modal-component ref="documentModal" class="modalDocuments"/>
  </div>
</template>

<script>


import gsap from 'gsap';
import eventBus from '../../../eventBus';
import Modal from '@/views/banklogin/DocumentFormModals.vue';

export default {
  props: ['block'],
  components: {
      'modal-component': Modal
  },
  data() {
    return {
      title: "Få tilbud og nyheder som den første",
      image: null,
      imageAlt: null,
      description: "<p>Tilmeld dig vores nyhedsbrev, og få gode råd og seneste nyt fra Opendo direkte i indbakken.</p>",
      items: null

    }
  },

  
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  beforeMount(){
    
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    
  },
  methods: {
    
    prepareContent: function(){
      this.title = this.block.title.value;
      this.description = this.block.description.value;
      
      // this.image = this.block.image.value.sizes.large.url;
      this.image = this.block.image.value.sizes && this.block.image.value.sizes.large ? this.block.image.value.sizes.large.url : null;

      if (this.image && this.block.image.value.info){
        this.imageAlt = this.block.image.value.info.alt;
      }

      var items = [];
      if (this.block.items){
        // console.log(this.block.faq);
        var itemsCount = this.block.items.value;
        var c = 0;
        for (var i=0; i<itemsCount; i++){
          
          var itemTitle = this.block['items_'+i+'_title'].value;
          var itemDescription = this.block['items_'+i+'_description'].value;
          
          
          var documentsCount = this.block['items_'+i+'_documents'].value;

          var documents = [];
          for (var j=0; j<documentsCount; j++){

            var type = this.block['items_'+i+'_documents_'+j+'_type'];
            var file = this.block['items_'+i+'_documents_'+j+'_file'];
            var link = this.block['items_'+i+'_documents_'+j+'_link'];

            if (type) type = type.value;
            if (file) file = file.value;
            if (link) link = link.value;

            documents.push({"type": type, "file": file, "link": link});

          }
          
          items.push({ "title": itemTitle, "description": itemDescription, "documents": documents});
          
        }
      }
      this.items = items;
    },
    
    getDimensions() {
      // this.width = document.documentElement.clientWidth;
      // this.height = document.documentElement.clientHeight;

      
      // this.newsDirection = 'vertical';
      // if(this.width< 1000){
      //   this.newsDirection = 'horizontal';
      // }
    
    },
  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );


  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


.document-list-block{
  margin-top: 80px;
  margin-bottom: 124px;
  .block{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: relative;
  }

  .left-side{
    position: relative;
    width: 45%;
    padding-right: 120px;
    box-sizing: border-box;
    
    .image-wrap{
      position: sticky;
      top: 0px;
      border-radius: 20px;
      img{
        border-radius: 20px;
        width: 100%
      }
    }
  }
  .right-side{
    position: relative;
    width: 55%;
    padding-top: 80px;
    // height: 2000px;
    .block-title{
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 130%;
      color: #1F4743;
      margin-bottom: 24px;
    }
    .block-description{
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 133%;
      color: #4C6C69;
      margin-bottom: 64px;
      ::v-deep a{
        color: #69BDB6;
      }
    }

    .list{
      .list-item{
        display: block;
        margin-bottom: 64px;
        &:last-child{
          margin-bottom: 0px;
        }
      }
      .title{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 130%;
        color: #1F4743;
        margin-bottom: 8px;
      }
      .description{
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 133%;
        color: #4C6C69;
        margin-bottom: 32px;
      }
      .links{
        .link-wrap{
          margin-bottom: 16px;
          &:last-child{
            margin-bottom: 0px;
          }
        }
        a{
          display: inline-block;
          vertical-align: top;
          
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 120%;
          color: #69BDB6;
          position: relative;
          padding-right: 30px;
          

          &:after{
            content: "";
            display: block;
            background-image: url('/ui/assets/icons/file-download-icon.svg');
            width: 18px;
            height: 20px;
            // margin-left: 12px;
            position: absolute;
            right: 0px;
            top: -1px;
            background-repeat: no-repeat;
            background-position: 50% 50%;


          }
          
        }
      }
    }

  }
}

@media (max-width: 1000px) {
  .document-list-block{
    .left-side{
      padding-right: 64px;
    }
  }
}
@media (max-width: 800px) {
  .document-list-block{
    .block{
      display: block;
    }
    .left-side{
      width: 100%;
      padding: 0px;
      margin-bottom: 36px;
    }
    .right-side{
      width: 100%;
      padding: 0px;
    }
  }
}

.formlink {
  cursor: pointer;
  color: #69BDB6;
  font-style: normal;
  font-weight: 700;
}

</style>