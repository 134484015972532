<template>

  
  <div class="car-bubble-block">
    <div class="block" :class="'position-'+position">
      <div class="car-bg">
        <div class="bg">
          <svg class="left-svg" width="826" height="386" viewBox="0 0 826 386" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M496.24 71C471.164 57.3938 382.782 0 264.261 0C145.74 0 0.240234 57.5 0.240234 203C0.240234 254 21.2402 380.5 169.24 386C317.24 391.5 373.653 346.872 572.371 363.643C771.089 380.414 878.976 238.156 799.95 154.533C734.24 85 659.473 159.57 496.24 71Z" fill="#E8F5F4"/>
          </svg>
          <svg class="right-svg" width="826" height="387" viewBox="0 0 826 387" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M330 71C355.076 57.3938 443.458 0 561.979 0C680.5 0 826 57.5 826 203C826 254 805 380.5 657 386C509 391.5 452.587 346.872 253.869 363.643C55.1513 380.414 -52.7359 238.156 26.2899 154.533C92.0001 85 166.768 159.57 330 71Z" fill="#E8F5F4"/>
          </svg>
        </div>
        <div class="car-image" v-if="image">
          <img class="image" :src="image" :alt="imageAlt" />
        </div>
      </div>
      <div class="wrap">
        <div class="bubble">    
          <h2 class="title">{{title}}</h2>
          <div class="description" v-html="description"></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>


import gsap from 'gsap';
import eventBus from '../../../eventBus';

export default {
  props: ['block'],
  components: {

  },
  data() {
    return {
      title: "TestOmrådet",
      description: "<p>Hvor fede er de forskellige firmabiler i forhold til hinanden? Hvor meget køreglæde og brugsværdi får du for pengene? Det kan du blive klogere på her i TestOmrådet, som er fyldt med spændende dueller, interessante sammenligninger og gruppetests.</p>",
      image: null,
      imageAlt: null,
      position: null,
    }
  },

  
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  beforeMount(){
    
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();

  },
  methods: {
    
    prepareContent: function(){
      this.title = this.block.title.value;
      this.description = this.block.description.value;
      if (this.block.image && this.block.image.value && this.block.image.value.sizes){
        this.image = this.block.image.value.sizes.large.url;
        this.imageAlt = this.block.image.value.info ? this.block.image.value.info.alt : '';
      }
      this.position = this.block.position.value;
    },
    getDimensions() {
      // this.width = document.documentElement.clientWidth;
      // this.height = document.documentElement.clientHeight;

      
      // this.newsDirection = 'vertical';
      // if(this.width< 1000){
      //   this.newsDirection = 'horizontal';
      // }
    
    },
  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.car-bubble-block{
    margin-top: 64px;
    margin-bottom: 64px;
    overflow: hidden;
}

.block{
// overflow: hidden;
  height: 430px;

  display: flex;
  align-items: center;

  position: relative;
  
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
.car-bg{
  position: absolute;
  z-index: 0;
  .bg{
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 89%;
    z-index: 1;
    svg{
      height: 100%;
      width: auto;
    }
  }
  .car-image{
    z-index: 2;
    position: relative;
    max-width: 723px;
    width: 100%;
  }
}
.bubble{
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 11px 41px -10px rgba(31, 72, 68, 0.09);
  border-radius: 20px;
  max-width: 590px;
  width: 100%;
  padding: 40px;
  padding-top: 32px;
  z-index: 1;
  .title{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #1F4743;
    margin-bottom: 12px;
  }
  .description{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 133%;
    color: #1F4743;
  }
}

.block{
  &.position-left{
    .car-bg{
      left: 0px;
      .car-image{
        
        img{
          transform: translateX(-80px);
        }
      }
      .bg{
        .left-svg{
          display: block;
        }
        .right-svg{
          display: none;
        }
        svg{
          // transform: scaleX(-1);
        }
        width: auto;
        left: 53px;
      }
    }
    .bubble{
      float: right;
      margin-right: 50px;
    }
  }
  &.position-right{
    .car-bg{
      right: 0px;
      .car-image{
        
        img{
          transform: translateX(80px);
        }
      }
      .bg{
        .left-svg{
          display: none;
        }
        .right-svg{
          display: block;
        }
        svg{
          float: right;
        }
        width: auto;
        right: 53px;
      }
    }
    .bubble{
      float: left;
      margin-left: 50px;
    }
  }
}

@media (max-width: 1000px) {
  .car-bubble-block{
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .block{
    height: auto;
    &.position-left{
      align-items: flex-start;
      flex-direction: column;
      .car-bg{
        width: 100%;
        .bg{
          left: 0px;
          overflow: hidden;
          bottom: -64px;
          svg{
            float: left;
            margin-left: -10%;
          }
        }
        .car-image{
          margin-right: auto;
        }
      }
    }
    &.position-right{
      align-items: flex-end;
      flex-direction: column;
      .car-bg{
        width: 100%;
        .bg{
          right: 0px;
          overflow: hidden;
          bottom: -64px;
          svg{
            float: right;
            margin-right: -10%;
          }
        }
        .car-image{
          margin-left: auto;
        }
      }
    }
  }
  .car-bg{
    position: relative;

  }
}

@media (max-width: #{$screen-tablet}) {
  .bubble{
    padding: 32px;
    padding-top: 24px;
    .title{
      font-size: 28px;
      line-height: 130%;
    }
  }
  .block{
    &.position-left{
      .bubble{
        float: none;
        max-width: 100%;
        margin-right: 0px;
      }
    }
    &.position-right{
      .bubble{
        float: none;
        max-width: 100%;
        margin-left: 0px;
      }
    }
  }
}
@media (max-width: 700px) {
  .block{
    &.position-left{
      .car-bg{
        .bg{
          svg{
            margin-left: -20%;
          }
        }
        .car-image{
        }
      }
    }
    &.position-right{
      .car-bg{
        .bg{
          svg{
            margin-right: -20%;
          }
        }
        .car-image{
        }
      }
    }
  }
  
  
}

@media (max-width: #{$screen-mobile}) {
  .bubble{
    padding: 16px;
    padding-top: 24px;
    padding-bottom: 32px;
    .title{
      font-size: 20px;
      line-height: 130%;
      margin-bottom: 8px;
    }
    .description{
      font-size: 16px;
      line-height: 135%;
    }
  }
}

</style>