<template>
    <div>
        <vue-recaptcha ref="recaptcha"
                       @verify="onVerify"
                       @expired="onExpired"
                       :sitekey="almbrandSitekey"
                       size="invisible">
        </vue-recaptcha>
    </div>
</template>

<script lang="ts">
    import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
    import VueRecaptcha from 'vue-recaptcha';

    @Component({
        name: 'recaptcha-component',
        components: {
            'vue-recaptcha': VueRecaptcha,
        }
    })
    export default class RecaptchaComponent extends Vue {

        @Prop() private almbrandSitekey: string;

        public mounted(): void {
            this.addScript();
        }

        addScript() {
            let script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=da`;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        }

        onSubmit() {
            (this.$refs.recaptcha as any).execute();
        }

        onVerify(response: any) {
            //console.log('Verify: ' + response);
            this.$emit('RecaptchaChanged', response);
        }
        onExpired() {
            console.log('Recaptcha Expired');
        }
        resetRecaptcha() {
            (this.$refs.recaptcha as any).reset();
        }
    }

</script>

<style></style>
