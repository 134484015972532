<template>
    <input type ='text' v-model="internalValue" ref="autocomplete" :placeholder="placeholderValue"/>
</template>

<script lang="ts">

    import Awesomplete from 'awesomplete';

    import AddressAutocomplete from '../../../common/ab_address_autocomplete';
    import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from "vue-property-decorator";

    @Component({
        name: 'autocomplete-component',
        components: {
            Awesomplete
        }
    })

    export default class AutocompleteComponent extends Vue {

        @Prop() postNum: boolean;
        @Prop() address: boolean;
        @Prop() setting: any;

        internalValue: string = "";
        awesomplete: any = null;
        placeholderValue: string = "";
        autocompleteRawData: any = "";

        AddrAutocomplete = new AddressAutocomplete();

        mounted() {
            let self = this;
            let input = this.$refs.autocomplete as HTMLInputElement;

            this.awesomplete = new Awesomplete(input,
                {
                    minChars: 2,
                    maxItems: 20,
                    autoFirst: true
            });

            if (this.postNum) {
                this.placeholderValue = '';
            }
            else if (this.address) {
                this.placeholderValue = '';
            }
            else {
                this.awesomplete = new Awesomplete(input, this.setting);
            }

            input.addEventListener('awesomplete-select', () => {
                this.$emit('select');
            })
            input.addEventListener('awesomplete-selectcomplete', () => {
                if (this.postNum) {
                    this.$emit('selectcomplete', this.findPostNum(input.value));
                } else {
                    this.$emit('selectcomplete', this.findAddress(input.value));
                }
            })
            input.addEventListener('awesomplete-open', () => {
                this.$emit('open');
            })
            input.addEventListener('awesomplete-close', () => {
                this.$emit('close');
            })
            input.addEventListener('awesomplete-highlight', () => {
                this.$emit('highlight');
            })
        }


        @Watch('internalValue')
        async internalValueChanged(newValue: any, oldValue: any) {
            let self = this;
            if (this.postNum) {
                await this.AddrAutocomplete.autocompletePostNum(this.$refs.autocomplete,
                    function successCallback(response: any) {
                        self.handleResponse(response);
                    }
                )
            } else if (this.address) {
                await this.AddrAutocomplete.autocompleteAddress(this.$refs.autocomplete,
                    function successCallback(response: any) {
                        self.handleResponse(response);
                    }
                )
            }
        }

        handleResponse(response: any) {
            this.autocompleteRawData = response.data;

            let list = {};
            if (this.postNum) {
                list = this.autocompleteRawData.map(x => x.tekst);
            } else {
                list = this.autocompleteRawData.map(x => x.forslagstekst);
            }
            this.awesomplete.list = list;
            this.awesomplete.evaluate();

            this.$emit('inputChanged', this.internalValue);
        }

        findPostNum(selectedPostnum: string): any {
            for (let data of this.autocompleteRawData) {
                if (data.tekst === selectedPostnum) {
                    return data;
                }
            }
            return undefined;
        }

        findAddress(selectedAddress: string): any {
            for (let data of this.autocompleteRawData) {
                if (data.forslagstekst === selectedAddress) {
                    return data;
                }
            }
            return undefined;
        }

        public getValue() {
            return (this.$refs.autocomplete as HTMLInputElement).value;
        }

    }

    
</script>
