import ConfigModel from "@/components/leasingpro/store/configModel";
import AbAxios from "@/components/leasingpro/episerver/ab_axios_leasingPro";
import { ActionContext } from "vuex";

const Windowproxy = window as any;
const dataLayer = Windowproxy.dataLayer;

//actions for the customer agreement module
export const UPDATE_LEASING_CUSTOMER = "leasingContext/UPDATE_LEASING_CUSTOMER";
export const UPDATE_LEASING_RELATIONS = "leasingContext/UPDATE_LEASING_RELATIONS"
export const UPDATE_LEASING_VEHICLE = "leasingContext/UPDATE_LEASING_VEHICLE"
export const UPDATE_LEASING_FUEL = "leasingContext/UPDATE_LEASING_FUEL"
export const UPDATE_LEASING_KM = "leasingContext/UPDATE_LEASING_KM"


export interface LeasingContextState {
    userPresent: boolean;
    customerRelationLoaded: boolean;
    vehiclesLoaded: boolean;
    fuelListLoaded: boolean;
    kmListLoaded: boolean;
    noData: boolean;
    leasingCustomer: LeasingCustomer;
    customerRelation: Relations[];
    vehicles: any;
    fuelList: any;
    kmList: any;
    abVehicleListTimeStamp: Date;
    sheetFiltering: string;
}



const state: LeasingContextState = {
    userPresent: false,
    customerRelationLoaded: false,
    vehiclesLoaded: false,
    fuelListLoaded: false,
    kmListLoaded: false,
    noData: false,
    leasingCustomer: null,
    customerRelation: [],
    vehicles: [],
    fuelList: [],
    kmList: [],
    abVehicleListTimeStamp: null,
    sheetFiltering: '',
};

export interface LeasingCustomer {
    company: string;
    cvr: string;
    name: string;
    super_admin: boolean;
    activeDashboard: boolean;
}

export interface Relations {
    company: string;
    cvr: string;
}

export const UPDATE_CUSTOMER = "leasingContext/UPDATE_CUSTOMER";
const UPDATE_RELATIONS = "leasingContext/UPDATE_RELATIONS";
const UPDATE_VEHICLE = "leasingContext/UPDATE_VEHICLE";
const UPDATE_FUELLIST = "leasingContext/UPDATE_FUELLIST";
const UPDATE_KMLIST = "leasingContext/UPDATE_KMLIST";
export const RESET_VEHICLE = "leasingContext/RESET_VEHICLE";




const mutations = {
    [UPDATE_CUSTOMER](state: LeasingContextState, resp: LeasingCustomer) {
        // Storing Admin Profile
        state.leasingCustomer = {
            company: resp.company,
            cvr: resp.cvr,
            name: resp.name,
            super_admin: resp.super_admin,
            activeDashboard: resp.super_admin,
        },
            state.userPresent = true;
        // if(resp.company) {
        //     if(!resp.super_admin) {
        //         dataLayer.push({'event': 'track-vp', 'virtualPath': 'leasingpro/userlogin/'+ resp.company});
        //     } else {
        //         dataLayer.push({'event': 'track-vp', 'virtualPath': 'leasingpro/userlogin/superadmin/'+ resp.company});
        //     }
        // } else {
        //     dataLayer.push({'event': 'track-vp', 'virtualPath': 'leasingpro/userlogin/timeout'});
        // }
    },
    [UPDATE_RELATIONS](state: LeasingContextState, resp: Relations[]) {
        // Storing Admin Profile
        state.customerRelation = resp;
        state.customerRelationLoaded = true;
    },
    [UPDATE_FUELLIST](state: LeasingContextState, resp: any) {
        state.fuelList = resp;
        state.fuelListLoaded = true;
    },
    [UPDATE_KMLIST](state: LeasingContextState, resp: any) {
        state.kmList = resp;
        state.kmListLoaded = true;
    },
    [UPDATE_VEHICLE](state: LeasingContextState, resp: any) {
        // Storing Admin Profile
        // let temp = resp.carStatisticsSnapshotDate.split("-").reverse().join("-");
        state.abVehicleListTimeStamp = new Date();

        let today = new Date().getTime();

        resp.vehicles.forEach(function (vehicle) {
            let endDate: any;

            if (vehicle.leasingContract && vehicle.leasingContract.periodEnd) {
                endDate = Date.parse(vehicle.leasingContract.periodEnd.split("-").reverse().join("-"));
            }
            else if (vehicle.carStatisticsVehicle) {

                if (vehicle.carStatisticsVehicle.leasingEnd) {
                    endDate = Date.parse(vehicle.carStatisticsVehicle.leasingEnd.split("-").reverse().join("-"));
                }
                else if (vehicle.carStatisticsVehicle.calculatedPeriodEndDate) {
                    endDate = Date.parse(vehicle.carStatisticsVehicle.calculatedPeriodEndDate.split("-").reverse().join("-"));
                }
            }
            let differenceTime = Math.abs(endDate - today);
            let differenceDays = Math.ceil(differenceTime / (1000 * 60 * 60 * 24));

            if (endDate < today) {
                vehicle.expireCheck = 0;
            } else {
                vehicle.expireCheck = differenceDays;
            }
            state.vehicles.push(vehicle);
        });

        state.vehiclesLoaded = true;
    },
    [RESET_VEHICLE](state: LeasingContextState) {
        // Storing Admin Profile
        state.vehiclesLoaded = false;
        state.fuelListLoaded = false;
        state.fuelList = [];
        state.vehicles = [];
        state.abVehicleListTimeStamp = null;
    },
    updateKmFilter(state, payload) {
        state.sheetFiltering = payload;
    }
};

const getApi = function (rootState: ConfigModel) {
    if (rootState.configEpi) {
        return new AbAxios(
            rootState.configEpi.serviceUrl,
            "application/json",
            rootState.configEpi.csrfToken);
    }
    //for support on almband.dk website
    const getcsrfToken = () => document.querySelector('meta[name="csrfToken"]').getAttribute('content');
    return new AbAxios(
        "/leasing-reporting/",
        "application/json",
        getcsrfToken());
};

const actions = {
    async [UPDATE_LEASING_CUSTOMER]({
        commit,
        rootState
    }: ActionContext<LeasingContextState, ConfigModel>): Promise<void> {

        // if (!rootState?.epiContext?.inEditMode && !state.userPresent) {
        if (!rootState.epiContext.inEditMode && !state.userPresent) {

            try {
                const api = getApi(rootState);
                const response = await api.getLeasingUserProfile();

                if (response.status === 200) {
                    commit(UPDATE_CUSTOMER, response.data);
                } else {
                    state.userPresent = false;
                }
            } catch (error) {
                state.userPresent = false;
                state.noData = true;
                console.log("error when fetching agreements", error);
            }
        }
    },
    async [UPDATE_LEASING_RELATIONS]({
        commit,
        rootState
    }: ActionContext<LeasingContextState, ConfigModel>): Promise<void> {

        // if (!rootState?.epiContext?.inEditMode && !state.customerRelationLoaded) {
        if (!rootState.epiContext.inEditMode && !state.customerRelationLoaded) {

            try {
                // console.log(rootState)
                const api = getApi(rootState);
                const response = await api.getAdminRelations();
                // console.log(response)

                if (response.status === 200) {
                    state.noData = false;
                    commit(UPDATE_RELATIONS, response.data.relations);
                } else {
                    state.noData = true;
                }
            } catch (error) {
                state.customerRelationLoaded = false;
                console.log("error when fetching agreements", error);
            }
        }
        // else if (rootState?.epiContext?.inEditMode) {
        else if (rootState.epiContext.inEditMode) {
            const relations: Relations[] = [
                { company: "test", cvr: "150024" }
            ];
            commit(UPDATE_RELATIONS, relations);
        }

    },
    async [UPDATE_LEASING_VEHICLE]({
        commit,
        rootState
    }: ActionContext<LeasingContextState, ConfigModel>): Promise<void> {
        // if (!rootState?.epiContext?.inEditMode && !state.vehiclesLoaded) {
        if (!rootState.epiContext.inEditMode && !state.vehiclesLoaded) {

            try {
                if (false) {
                    const data = require("@/components/leasingpro/json/serviceproxy/biler.json");
                    commit(UPDATE_VEHICLE, data);
                } else {
                    if (!state.vehiclesLoaded) {
                        const api = getApi(rootState);
                        const response = await api.getCars(state.leasingCustomer.cvr);

                        if (response.status === 200) {
                            state.noData = false;
                            commit(UPDATE_VEHICLE, response.data);
                        } else {
                            state.noData = true;
                        }
                    }
                }

            } catch (error) {
                state.vehiclesLoaded = false;
                console.log("error when fetching agreements", error);
            }
        }
    },
    async [UPDATE_LEASING_FUEL]({
        commit,
        rootState
    }: ActionContext<LeasingContextState, ConfigModel>): Promise<void> {
        // if (!rootState?.epiContext?.inEditMode && !state.fuelListLoaded) {
        if (!rootState.epiContext.inEditMode && !state.fuelListLoaded) {

            try {
                if (!state.fuelListLoaded) {
                    const api = getApi(rootState);
                    const response = await api.getFuel();

                    if (response.status === 200) {
                        state.noData = false;
                        commit(UPDATE_FUELLIST, response.data);
                    } else {
                        state.noData = true;
                    }
                }
            } catch (error) {
                state.fuelListLoaded = false;
                console.log("error when fetching agreements", error);
            }
        }
    },
    async [UPDATE_LEASING_KM]({
        commit,
        rootState
    }: ActionContext<LeasingContextState, ConfigModel>): Promise<void> {
        // if (!rootState?.epiContext?.inEditMode && !state.kmListLoaded) {
        if (false) {
            const data = require("@/components/leasingpro/json/serviceproxy/km.json");
            commit(UPDATE_KMLIST, data);
        } else {
            if (!rootState.epiContext.inEditMode && !state.kmListLoaded) {

                try {
                    if (!state.kmListLoaded) {
                        const api = getApi(rootState);
                        const response = await api.getKm();

                        if (response.status === 200) {
                            state.noData = false;
                            commit(UPDATE_KMLIST, response);
                        } else {
                            state.noData = true;
                        }
                    }
                } catch (error) {
                    state.kmListLoaded = false;
                    console.log("error when fetching agreements", error);
                }
            }
        }
    },
    async ["dataSource/GET_REQUIREMENTS/CVR"]({ }: ActionContext<LeasingContextState, any>, config: { requirement: string, type: string }) {
        return state.leasingCustomer.cvr;
    },
};

export default {
    state,
    mutations,
    actions
};
