<template>
  <div class="main landing-page-main">
    

    <homepage-hero :heroBlock="heroBlock" />

    

    

    <div class="homepage-news-green-section">
      <news-section :articles="articles" :button="newsBlock.button_title" :title="newsBlock.title"  />
    </div>

    

    <section class="section fade-on-enter partners-section">
      <div class="wrap">
        <div class="partners-section-content flex my-flex-row flex-s9">
          <div class="side-image"> 
            <img :src="partnersBlock.image.sizes.large" :alt="partnersBlock.image.info ? partnersBlock.image.info.alt : ''" />
          </div>
          <div class="side-content">
            <h2 class="title h2-1">{{partnersBlock.title}}</h2>
            <div class="description">
              {{partnersBlock.description}}
            </div>

            <partners-slider :logos="patnersLogos" />
            
          </div>
        </div>
      </div>
    </section>



    <section class="section fade-on-enter interested-section">
      <div class="wrap">
        
        <privat-erhverv-select-block :title="interestedBlock.title" :buttons="interestedBlock.buttons" :bg="'light-green'" />

      </div>
    </section>

  </div>
</template>
<script>
export default {
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      thisSlug: "",
      newsSlug: "nyheder",
      heroBlock: {title: ""},
      newsBlock: {title: "", button_title: ""},
      partnersBlock: {image: {sizes: {large: ""}}},
      interestedBlock: {title: ""},
      articles: null,
      patnersLogos: null,
    }
  },
  beforeMount(){
    // alert(footerData.fields.copyright);
    // this.copyright = "b";
  },
  created(){
    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {
        this.thisSlug = this.cmsPageData.page.url;
        if (this.cmsPageData.page.homepage){
          this.heroBlock = this.cmsPageData.page.homepage.hero;
          this.newsBlock = this.cmsPageData.page.homepage.news;
          this.partnersBlock = this.cmsPageData.page.homepage.partners;
          this.interestedBlock = this.cmsPageData.page.homepage.interested;
          this.articles = this.cmsPageData.page.homepage.news_list;
          this.patnersLogos = this.cmsPageData.page.homepage.partners_logos;
        }
      }
    );
  },
  mounted(){
    
  },
  methods: {

  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.main{
  // height: 600px;
}

.homepage-news-green-section{
  position: relative;
  background: $color-super-light-green;
  padding-top: 175px;
  padding-bottom: 155px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 40px;
  margin-top: -52px;
  margin-bottom: -52px;
}


.interested-section{
  margin-bottom: 160px;
}



@media (max-width: #{$screen-news-full-width}) {
  .homepage-news-green-section{
    background: none;
    padding-top: 132px;
    margin-left: 0px;
    margin-right: 0px;
  }
}



@media (max-width: #{$screen-mobile}) {
  .interested-section{
    margin-bottom: 64px;
  }
}




</style>