<template>
  <div>
    <BankloginAuth @submit="onCustomSubmit" />
    <BottomInfoBar :open="openBotomBar" :text="bottomBarText" @onClose="onCloseBottomBar" />
    
  </div>
</template>
<script>

import BottomInfoBar from "@/components/ui/bottom-info-bar/BottomInfoBar.vue";
import bankloginData from "./BankloginData";


export default {
  components: {
    BottomInfoBar : BottomInfoBar
  },
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      openBotomBar: false,
      bottomBarText: ''
    }
  },
  beforeMount(){
    
  },
  created(){

  },
  mounted(){
    var th = this;
    


    
    
  },
  methods: {
    onCustomSubmit: function(e){
      var th = this;
      if (e.type=="setPassword"){
        th.bottomBarText = "Din bruger er nu oprettet! Du kan logge ind med din mail og valgte adgangskode.";
        th.openBotomBar = true;  
      }
      if (e.type=="failedSetPassword"){
        th.bottomBarText = "Kunne ikke godkende kodeord.";
        th.openBotomBar = true;  
      }
      if (e.type=="loginFailed"){
        // th.bottomBarText = "Failed login.";
        // th.openBotomBar = true;  
      }
      if (e.type=="loginSuccess"){
        th.bottomBarText = "Godkendt login.";
        th.openBotomBar = true;  
      }
      if (e.type=="recoverSuccess"){
        th.bottomBarText = "Godkendt gendannelse.";
        th.openBotomBar = true;  
      }
      if (e.type=="recoverFailed"){
        // th.bottomBarText = "Bad token.";
        // th.openBotomBar = true;  
      }
      
      if (e.type=="forgotPasswordSuccess"){
        th.bottomBarText = "Verifikationsmail sendt.";
        th.openBotomBar = true;  
      }
      if (e.type=="forgotPasswordFailed"){
        th.bottomBarText = "Kunne ikke sende gendannelsesmail.";
        th.openBotomBar = true;  
      }
      if (e.type=="resetPasswordSuccess"){
        th.bottomBarText = "Kodeord gendannet.";
        th.openBotomBar = true;  
      }
      if (e.type=="failedResetPassword"){
        th.bottomBarText = "Kunne ikke gendanne kodeord.";
        th.openBotomBar = true;  
      }
      if (e.type=="userValidated"){
        th.bottomBarText = "Din mail "+e.email+" er verificeret.";
        th.openBotomBar = true;  
      }
      
      if (e.type=="failedUserRegister"){

        if (e.error_type == "exists"){
          th.bottomBarText = "Bruger eksisterer allerede.";
          th.openBotomBar = true;  
        }
        // if (e.error_type == "no-domain"){
        //   th.bottomBarText = "Ikke gyldig emailadresse.";
        // th.openBotomBar = true;  
        // }
        
      }
      if (e.type=="failedUserValidation"){
        th.bottomBarText = "Kunne ikke verificere.";
        th.openBotomBar = true;  
      }
      if (e.type=="resendValidationEmailSuccess"){
        th.bottomBarText = "Email er gensendt til "+e.email+".";
        th.openBotomBar = true;  
      }

      if (e.type=="resendValidationEmailFailed"){
        th.bottomBarText = "Kunne ikke gensende mail.";
        th.openBotomBar = true;  
      }

      
      

      
      
    },
    onCloseBottomBar: function(e){
      var th = this;
      this.openBotomBar = false;
    }
    
  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";



</style>