<template>
  <div :class="'prefilter-field'+(customClass ? ' '+customClass: '')+(selectOpen ? ' open': '')+(align ? ' align-'+align: '')" v-on:click="toggleFilter" v-click-outside="hideFilter">

    


    
    <div class="field">
      <span class="icon">
        <img v-if="icon=='brand'" src="@/assets/showroom-icons/brand.svg" />
        <img v-if="icon=='car-retro'" src="@/assets/showroom-icons/car-retro.svg" />
        <img v-if="icon=='clean-car-gas'" src="@/assets/showroom-icons/clean-car-gas.svg" />
        <img v-if="icon=='monthly'" src="@/assets/showroom-icons/monthly.svg" />
      </span>
      <span class="label">{{label}}</span>
      <span class="selected-option">{{selectedItemsLabel}}</span>

      <span class="arrow-icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 10.2568L12.1181 14.8318C12.0734 14.8847 12.0186 14.927 11.9572 14.956C11.8958 14.985 11.8292 15 11.7619 15C11.6946 15 11.628 14.985 11.5666 14.956C11.5052 14.927 11.4504 14.8847 11.4057 14.8318L6.52381 10.2568" stroke="#4C6C69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </span>
    </div>

    <div class="dropdown">
      <div v-if="type=='multiselect'" class="multiselect-items">
        <span :class="'item'+(item.disabled || item.count<=0 ? ' disabled' : '')+(item.icon ? ' with-icon' : '')+(isSelected(item.value) ? ' selected' : '')" v-for="(item, index) in options" :key="'select-item-'+index" v-on:click="selectItem(item.value, item.name)">
          <span class="icon" v-if="item.icon">
            <img v-if="item.icon=='cabriolet'" src="@/assets/showroom-icons/cars/cabriolet.svg" alt="cabriolet" />
            <img v-if="item.icon=='coupe'" src="@/assets/showroom-icons/cars/coupe.svg" alt="coupe" />
            <img v-if="item.icon=='hatchback'" src="@/assets/showroom-icons/cars/hatchback.svg" alt="hatchback" />
            <img v-if="item.icon=='mini'" src="@/assets/showroom-icons/cars/mini.svg" alt="mini" />
            <img v-if="item.icon=='sedan'" src="@/assets/showroom-icons/cars/sedan.svg" alt="sedan" />
            <img v-if="item.icon=='stationcar'" src="@/assets/showroom-icons/cars/stationcar.svg" alt="stationcar" />
            <img v-if="item.icon=='suv'" src="@/assets/showroom-icons/cars/suv.svg" alt="suv" />
            <img v-if="item.icon=='varebil'" src="@/assets/showroom-icons/cars/varebil.svg" alt="varebil" />
            <img v-if="item.icon=='Kassevogn'" src="@/assets/showroom-icons/cars/varebil.svg" alt="varebil" />
            <img v-if="item.icon=='kassevogn'" src="@/assets/showroom-icons/cars/varebil.svg" alt="varebil" />
            <img v-if="item.icon=='mpv'" src="@/assets/showroom-icons/cars/mpv.svg" alt="mpv" />
          </span>
          <span class="label">{{item.name}} ({{item.count}})</span>
          <span class="check-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 7.25685L9.1181 15.8318C9.07342 15.8847 9.01857 15.927 8.95717 15.956C8.89577 15.985 8.82922 16 8.7619 16C8.69459 16 8.62803 15.985 8.56664 15.956C8.50524 15.927 8.45039 15.8847 8.40571 15.8318L4.52381 12.2568" stroke="#1F4743" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
        </span>
      </div>
      <div v-if="type=='interval'" class="interval-wrap">
        <span class="title">Månedlig ydelse</span>
        <IntervalField :name="name" :from="fromValue" :to="toValue" :updateTrigger="selectOpen" @changed="intervalChanged"/>
      </div>
    </div>
    
    <div v-if="type=='multiselect'">
      <input v-if="name" type="hidden" :name="name+'[]'" :value="option.value" v-for="(option, index) in selectedOptions" :key="'option-'+name+'-'+index" />
    </div>
    
  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';




export default {
  props: ['options', 'label', 'placeholder', 'align', 'type', 'from', 'to', 'name', 'customClass', 'icon'],
  data() {
    return {
      selectOpen: false,
      selectedOptions: [],
      fromValue: 0,
      toValue: 100,
      interval: {'from': '', 'to': ''},
      selectedItemsLabel: null,
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  mounted(){
    this.fromValue = this.from;
    this.toValue = this.to;
    this.init();
  },
  created(){
    this.$watch(
      () => this.from,
      (toParams, previousParams) => {
        this.fromValue = toParams;
      }
    );
    this.$watch(
      () => this.to,
      (toParams, previousParams) => {
        this.toValue = toParams;
      }
    );
    this.$watch(
      () => this.options,
      (toParams, previousParams) => {
        this.init();
      }
    );
  },
  methods: {
    init: function(){


      this.preparePlaceholder();

      // if (this.options){
        
      //   this.$watch(
      //     () => this.options,
      //     (toParams, previousParams) => {
            
            
      //     }
      //   );
      // }
    },
    preparePlaceholder: function(){
      if (this.type=="multiselect"){
        if (this.selectedOptions && this.selectedOptions.length>0){
          var label = this.selectedOptions[0].name;
          if (this.selectedOptions.length>1){
            label+=" +"+(this.selectedOptions.length-1);
          }
          this.selectedItemsLabel = label;
        }else{
          this.selectedItemsLabel = this.placeholder;
        }
      }else if(this.type=="interval"){
        
        if (this.interval.from && this.interval.to){
          var label = this.interval.from.toFixed(2)+" - "+this.interval.to.toFixed(2);
          this.selectedItemsLabel = label;
        }else{
          this.selectedItemsLabel = this.placeholder;
        }
      }

    },
    toggleFilter: function(e){
      var fh = this.$el.offsetHeight;

      this.$el.querySelector(".dropdown").style.marginTop = (fh+16)+"px"
      if (this.type=="multiselect" && !e.target.closest(".dropdown")){
        this.selectOpen = !this.selectOpen;
      }

      if (this.type=="interval" && !e.target.closest(".dropdown")){
        this.selectOpen = !this.selectOpen;
      }
    },
    hideFilter: function(){
      if (this.$el.classList.contains("skip-outside-click")){
        this.$el.classList.remove("skip-outside-click");
      }else{
        if (this.selectOpen){
          this.selectOpen = false;
        }
      }
    },
    selectItem: function(val, name){
      var add = true;
      do{
        var skip = false;
        for (var i=0; i<this.selectedOptions.length; i++){
          if (this.selectedOptions[i].value == val){
            skip = true;
            add = false;
            this.selectedOptions.splice(i, 1);
            break;
          }
        }
      }while(skip);
      if (add){
        this.selectedOptions.push({"name": name, "value": val});
      }

      this.preparePlaceholder();

      this.$emit('changed', this.selectedOptions);
    },
    isSelected: function(val){
      for (var i=0; i<this.selectedOptions.length; i++){
        if (this.selectedOptions[i].value == val){
          return true;
        }
      }
      return false;
    },
    intervalChanged: function(val){
      this.interval = val;
      this.preparePlaceholder();
      this.$emit('changed', val);
    }
  },
 
}
</script>
<style lang="scss">
@import "~@/styles/variables.scss";
.prefilter-field{
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  text-align: left;
  cursor: pointer;

  background: transparent;
  border-radius: 12px;

  // width: 20%;
  min-height: 64px;

  

  &:before{
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    
    content: "";
    position: absolute;
    display: block;
    top: 8px;
    left: -8px;
    bottom: 8px;
    width: 2px;
    background: rgba(105, 189, 182, 0.09);
    // background: #F00;




  }

  &:first-child{
    &:before{
      opacity: 0;
    }
  }
  &.open{
    .field{
      background: rgba(105, 189, 182, 0.09);
    }
  }
  &:hover{

    .field{
      background: rgba(105, 189, 182, 0.09);
    }

    + .prefilter-field:before,
    &:before{
      opacity: 0;
    }
  }

  .field{
    padding-top: 11px;
    padding-left: 46px;
    padding-right: 48px;
    padding-bottom: 14px;
    box-sizing: border-box;

    background: transparent;
    border-radius: 12px;

    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;

    min-height: 64px;
    height: 100%;

    .arrow-icon{
      position: absolute;
      top: 50%;
      right: 20px;
      margin-top: -12px;
      transition: all 300ms;
      -webkit-transition: all 300ms;
    }
    .label{
      display: block;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 133%;
      letter-spacing: -0.01em;
      color: #1F4743;
      margin-bottom: 1px;
    }
    .icon{
      display: block;
      position: absolute;
      top: 11px;
      left: 12px;
    }
    .selected-option{
      display: block;

      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      color: #4C6C69;
    }
  }
  
 
  &.align-right{
    .dropdown{
      right: 0px;
      left: auto;
    }  
  }
  .dropdown{
    z-index: 10;
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    border-radius: 20px;
    overflow: hidden;
    cursor: default;
    display: none;

    .multiselect-items{
      .item{
        position: relative;
        box-sizing: border-box;
        border-radius: 16px;
        padding: 12px;
        padding-top: 15px;
        padding-bottom: 19px;
        padding-left: 24px;
        padding-right: 64px;
        display:block;
        cursor: pointer;
        min-height: 56px;
        margin-left: 0px;
        margin-right: 0px;

       
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        color: #4C6C69;

        margin-top: 8px;


        // -webkit-transition: background 0.3s ease;
        // -moz-transition: background 0.3s ease;
        // -o-transition: background 0.3s ease;
        // -ms-transition: background 0.3s ease;
        // transition: background 0.3s ease;

        &.with-icon{
          padding-left: 84px;
        }
        .check-icon{
          position: absolute;
          top: 50%;
          right: 24px;
          margin-top: -12px;
          display: block;
          opacity: 0;

          transform: scale(0);
          -webkit-transform: scale(0);

          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
        .icon{
          position: absolute;
          top: 50%;
          left: 16px;
          width: 48px;
          height: 48px;
          margin-top: -24px;
          display: block;
          opacity: 0.5;

          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
        &.selected{
          background: rgba(105, 189, 182, 0.09);
          color: #1F4743;
          .check-icon{
            transform: scale(1);
            -webkit-transform: scale(1);
            opacity: 1;
            display: block;
          }
          .icon{
            opacity: 1;
          }
        }
        &:hover{
          color: #1F4743;
          background: rgba(105, 189, 182, 0.09);  
          .icon{
            opacity: 1;
          }
        }
        &:first-child{
          margin-top: 0px;
        }
        &.disabled{
          opacity: 0.5;
          pointer-events: none;
        }
        
      }
    }
    .interval-wrap{
      padding-left: 32px;
      padding-right: 32px;
      .title{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 130%;
        text-align: center;
        color: #1F4743;
        text-align: center;
        display: block;
        padding-top: 20px;
      }
      .interval-field{
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }
  }

  &.open{
    // border: 1px solid transparent;
    
    .field{
      .arrow-icon{
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);

      }
    }
    .dropdown{
      display: block;
      box-sizing: border-box;
      background: #FFFFFF;
      box-shadow: 0px 20px 40px -20px rgba(31, 72, 68, 0.24);
      padding: 8px;
      margin-top: 80px;
      height: auto;
      width: 387px;
      
    }
  }
}
</style>