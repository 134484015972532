import { Component, Vue } from 'vue-property-decorator';

import template from './SelectField.vue';

@Component({
  mixins: [template],
  props: ['options', 'theme', 'size', 'name', 'customClass']
})
export default class SelectField extends Vue {

}