<template>
  <div>

    <section v-if="heroBlock" class="section fade-on-enter hero-section op-fade-in-animation">
      <div class="top-part">
        <div class="wrap">
            <div v-if="heroBlock.logo" class="logo">
              <img :src="heroBlock.logo.sizes.large" :alt="heroBlock.logo.info ? heroBlock.logo.info.alt : ''" />
            </div>
            <h1 class="h1-2 title" >{{heroBlock.title}}</h1>
            <div class="content" v-html="heroBlock.text"></div>    
        </div>
      </div>
      <div class="bottom-part">
        <div class="wrap">
          <div v-if="heroBlock.image" class="image">
            <img :src="heroBlock.image.sizes.large" :alt="heroBlock.image.info ? heroBlock.image.info.alt : ''" />
          </div>
        </div>
      </div>
    </section>
    

    
    

    <section v-if="accordion" class="section fade-on-enter accordion-section">
      <div class="wrap">
        <h2 class="h2-1">{{accordion.title}}</h2>
        <div class="top-content" v-html="accordion.content"></div>
        <div class="accordion-wrap">
          <AccordionList v-if="accordionList" :items="accordionList"/>
        </div>
      </div>
    </section>

    <section v-if="contactsBlock" class="section fade-on-enter benefits-section">
      <div class="wrap">
        <div class="container flex my-flex-row my-flex-nowrap flex-s3">
          <h2 class="h2-1 big-title">{{contactsBlock.title}}</h2>
          <div class="benefits flex my-flex-row my-flex-wrap flex-s3">
            <div class="benefit" v-for="(item, index) in contactsBlock.contacts" :key="'benefits-'+index" >
              <p class="title">{{item.title}}</p>
              <div class="conent">
                <div v-if="item.phone">
                  <a class="email" :href="'mailto:'+item.email">{{item.email}}</a>
                </div>
                <div v-if="item.phone">
                  <a class="phone" :href="'tel:'+item.phone">{{item.phone}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


     <section v-if="footerBlock" class="section fade-on-enter subpage-footer-section">
      <div class="wrap">
        <div class="container flex my-flex-row my-flex-nowrap flex-s1">
          <div v-if="footerBlock.image" class="image">
            <img :src="footerBlock.image.sizes.large"  :alt="footerBlock.image.info ? footerBlock.image.info.alt : ''" />
          </div>
          <div class="side">
            <h2 class="h2-1 big-title">{{footerBlock.title}}</h2>
            

            <div class="buttons">
              <!--<router-link class="item link-button-item" :to="item.link.url" v-for="(item, index) in footerBlock.links" :key="'links-'+index" >
                <span class="label">
                  {{item.link.title}}
                </span><span class="icon">
                  <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.4766 1L30.4766 7L24.4766 13" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M30 7H2" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
              </router-link>-->

              <a class="item link-button-item" :href="item.link.url" :target="item.link.target" v-for="(item, index) in footerBlock.links" :key="'links-'+index" >
                <span class="label">
                  {{item.link.title}}
                </span><span class="icon">
                  <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.4766 1L30.4766 7L24.4766 13" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M30 7H2" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
              </a>
            </div>
            
          </div>
        </div>
      </div>
    </section>



  </div>
</template>
<script>

import BottomInfoBar from "@/components/ui/bottom-info-bar/BottomInfoBar.vue";
import AccordionList from "@/components/ui/accordion-list-v2/AccordionList.vue";

export default {
  components: {
    AccordionList
  },
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
        heroBlock: {
          title: null,
          text: null,
          logo: null,
        },
        contactsBlock: {
          contacts: null
        },
        accordion:{
          title: null,
          accordion: null
        },
        footerBlock: {
          image: null,
          links: null,
          title: null
        },
        accordionList: null
    }
  },
  beforeMount(){
    
  },
  created(){
    this.$watch(
        () => this.$props.cmsGlobalData,
        (toParams, previousParams) => {
          this.setCMSGlobalData();
        }
    );
    this.$watch(
        () => this.$props.cmsPageData,
        (toParams, previousParams) => {
          this.setCMSPageData();
        }
    );
    this.setCMSGlobalData();
    this.setCMSPageData();
  },
  mounted(){
    var th = this;
    // console.log(this);
  },
  methods: {
    setCMSGlobalData(){
      // this.title = this.cmsGlobalData.page_404.title;
      // this.subTitle = this.cmsGlobalData.page_404.subtitle;
      // this.listTitle = this.cmsGlobalData.page_404.list_title;
      // this.links = this.cmsGlobalData.page_404.list;
    },
    setCMSPageData(){
      // this.thisSlug = this.cmsPageData.page.url;
      this.heroBlock = this.cmsPageData.page.subpage.hero;
      this.accordion = this.cmsPageData.page.subpage.accordion;
      this.contactsBlock = this.cmsPageData.page.subpage.contact;
      this.footerBlock = this.cmsPageData.page.subpage.footer;
      
      this.filterAccordionList();

    },
    filterAccordionList(){
      if (this.accordion && this.accordion.accordion){
        var list = [];
        for(var i=0; i<this.accordion.accordion.length; i++){
          var item = this.accordion.accordion[i];
          item.ID = (i+1);
          list.push(item);
        }
        this.accordionList = list;
        // console.log(this.accordionList);
      }
    }
  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.hero-section{
  margin-bottom: 100px;
  
  .top-part{
    background: #E8F5F4;
    border-radius: 40px;
    padding-bottom: 170px;
    text-align: center;
    padding-top: 80px;
    .logo{
      height: 25px;
      margin-bottom: 8px;
      img{
        max-height: 100%;
        max-width: auto;
      }
    }
    .wrap{
      max-width: 710px;
      margin-left: auto;
      margin-right: auto;
    }
    .content{
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #4C6C69;
      margin-top: 40px;
      ::v-deep p{
        margin-top: 1.4rem;
      }
    }
  }
  .bottom-part{
    text-align: center;
    margin-top: -90px;
  }
  .image{
    overflow: hidden;
    border-radius: 20px;
    img{
      border-radius: 20px;
    }
    
  }
}

.subpage-footer-section{
  margin-bottom: 140px;
  .container{
    margin: 0px;
    background: rgba(105, 189, 182, 0.09);
    border-radius: 20px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 100%;
  }
  .image{
    border-radius: 20px;
    overflow: hidden;
    width: 50%;
    img{
      border-radius: 20px;
    }
    
  }
  .side{
    width: 50%;
    padding-left: 10%;
    padding-right: 5%;
  }
  .buttons{
    margin-top: 30px;
  }
  .link-button-item{
    margin-top: 0px;
  }
}
.benefits-section{
  margin-bottom: 80px;
  .container{
    // background: $color-super-light-green;
    margin: 0px;
    background: rgba(105, 189, 182, 0.09);
    border-radius: 20px;  
    padding: 60px;
    padding-top: 80px;
    padding-bottom: 32px;
    padding-right: 40px;

    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .big-title{
    max-width: 283px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #1F4743;
    width: 283px;
  }
  .benefits{
    width: 100%;
    width: calc(100% - 283px);
    .benefit{
      width: 50%;
      padding-left: 65px;
      padding-right: 20px;
      box-sizing: border-box;
      margin-bottom: 48px;
    }
    .title{
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: #1F4743;
      margin-bottom: 16px;
    }
    .content{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      color: #1F4743;
    }
    .content ::v-deep p{
      margin-top: 16px;
    }
    a{
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 135%;
      /* or 22px */

      /* Opendo/Brand darkest */
      color: #1F4743;

    }
  }
}
.accordion-section{
  margin-bottom: 140px;
  .wrap{
    max-width: 794px;
  }
  .accordion-wrap{
    margin-top: 60px;
  }
  .top-content{
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #4C6C69;
    ::v-deep p{
      display: block;
      margin-top: 1em;
    }
  }
}
@media (max-width: 1000px) {
  .hero-section{
    
  }
  .benefits-section{

    .container{
      display: block;
      padding-bottom: 60px;
    }
    .big-title{
      max-width: none;
      margin-bottom: 40px;
      width: auto;
    }
    .benefits{
      width: 100%;
      .benefit{
        padding-right: 56px;
        padding-left: 0px;
      }
      .title{
      
      }
      .content{
      
      }
      .content ::v-deep p{
      
      }
    }
  }
  .subpage-footer-section{
    .image{
      display: none;
    }
    .side{
      padding: 40px;
      padding-top: 40px;
      padding-bottom: 30px;
      width: 100%;
    }
  }
}

@media (max-width: #{$screen-tablet}) {
  .hero-section{
    
  }
}

@media (max-width: #{$screen-mobile}) {
  .hero-section{
    margin-bottom: 32px;
    .top-part{
      padding-top: 40px;
      padding-bottom: 130px;
      padding-bottom: 40px;
      
      .content{
        margin-top: 10px;
        font-size: 16px;
        line-height: 130%;
      }
    }

    .bottom-part{
      display: none;
    }
  }
  .accordion-section{
    .top-content{
      font-size: 16px;
      line-height: 130%;
    }
  }
  .benefits-section{

    .container{
      display: block;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 40px;
    }
    .big-title{
      max-width: none;
      margin-bottom: 0px;
      width: auto;

      font-weight: 700;
      font-size: 28px;
      line-height: 120%;
    }
    .benefits{
      display: block;
      .benefit{
        padding-right: 0px;
        margin-top: 40px;
        width: 100%;
      }
      .title{
      
      }
      .content{
      
      }
      .content ::v-deep p{
      
      }
    }
  }
  .subpage-footer-section{
    .buttons{
      margin-top: 10px;
    }
    .side{
      padding: 0px;
      padding-top: 10px;
      padding-bottom: 0px;
    }
  }
}

</style>
