import { Component, Vue } from "vue-property-decorator";

// import InputField from "../../components/ui/input-field/input-field";
import BankloginAuth from "./BankloginAuth.vue";
import template from "./Banklogin.vue";

@Component({
    mixins:[template],
    name: "Banklogin",
    props: ['cmsGlobalData', 'cmsPageData'],
    components: {
        // 'input-field': InputField,
        BankloginAuth
    }
  
})
export default class Banklogin extends Vue {
}