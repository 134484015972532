<template>
  <div class="main about-page-main">
    

    <section class="section fade-on-enter hero-section">
      <div class="wrap">
        <div class="flex my-flex-row my-flex-nowrap flex-s3">
          <div v-if="heroBlock.image" class="image">
            <img :src="heroBlock.image.sizes.large" :alt="heroBlock.image.info ? heroBlock.image.info.alt : ''" />
          </div>
          <div class="side">
            <h1 class="h1-1 title" >{{heroBlock.title}}</h1>
            <div class="content" v-html="heroBlock.content"></div>
            <a v-if="heroBlock.button" class="button" :href="'#'" v-on:click="scrollToFAQClick" >{{heroBlock.button.title}}</a>
          </div>
        </div>
      </div>
    </section>

    <section v-if="benefitsBlock" class="section fade-on-enter benefits-section">
      <div class="wrap">
        <div class="container flex my-flex-row my-flex-nowrap flex-s3">
          <h2 class="h2-1 big-title">{{benefitsBlock.title}}</h2>
          <div class="benefits flex my-flex-row my-flex-wrap flex-s3">
            <div class="benefit" v-for="(item, index) in benefitsBlock.benefits" :key="'benefits-'+index" >
              <p class="title">{{item.title}}</p>
              <div class="content" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section fade-on-enter media-text-section first-media-text-section" v-if="mediaTextBlock">
      <div class="wrap">
        <div class="flex my-flex-row my-flex-nowrap flex-s3">
          <div v-if="mediaTextBlock.image" class="image">
            <img :src="mediaTextBlock.image.sizes.large" :alt="mediaTextBlock.image.info ? mediaTextBlock.image.info.alt : ''" />
          </div>
          <div class="side">
            <div v-if="mediaTextBlock.icon" class="icon">
              <img :src="mediaTextBlock.icon.url" alt="icon" />
            </div>
            <h2 class="title">{{mediaTextBlock.title}}</h2>
            <div class="content" v-html="mediaTextBlock.content"></div>
            <div class="button-wrap" v-if="mediaTextBlock.button">
              <a v-if="mediaTextBlock.button.target == '_blank'" class="button" :href="mediaTextBlock.button.url" :target="mediaTextBlock.button.target" >{{mediaTextBlock.button.title}}</a>
              <router-link v-if="mediaTextBlock.button.target != '_blank'" class="button" :to="mediaTextBlock.button.url" >{{mediaTextBlock.button.title}}</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <long-slider v-if="longSliderBlock" :title="longSliderBlock.title" :slides="longSliderBlock.slider"/>

    <section class="section fade-on-enter faq-list-section" ref="faqBlock" v-if="faqBlock">
      <div class="wrap">
        <h2 class="section-title">{{faqBlock.title}}</h2>
        <faq-list :items="faqs" :tags="faqTags"/>
        <div class="button-wrap" v-if="faqBlock.button">
          <router-link class="button" :to="'../erhverv/faq'" >{{faqBlock.button.title}}</router-link>
        </div>
      </div>
    </section>
    
    
    <span v-if="cmsPageData && !cmsPageData.loaded"></span>
  </div>
</template>
<script>
export default {
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      thisSlug: "",
      heroBlock: { title: "Private FAQ" },
      benefitsBlock: { title: "" },
      mediaTextBlock: null,
      faqBlock: null,
      faqs: null,
      faqTags: null,
      longSliderBlock: null
    }
  },
  beforeMount(){
    // alert(footerData.fields.copyright);
    // this.copyright = "b";
  },
  created(){
    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {
        
        // this.thisSlug = this.cmsPageData.page.url;
        this.heroBlock = this.cmsPageData.page.private_faq.hero;
        this.benefitsBlock = this.cmsPageData.page.private_faq.benefits;
        this.mediaTextBlock = this.cmsPageData.page.private_faq.first;
        this.faqBlock = this.cmsPageData.page.private_faq.faq;
        this.longSliderBlock = this.cmsPageData.page.private_faq.long_slider;

        this.faqs = this.cmsPageData.page.private_faq.faq_list;
        this.faqTags = this.cmsPageData.page.private_faq.tags;
        // this.historyBlock = this.cmsPageData.page.about.history;
        // this.partnersBlock = this.cmsPageData.page.about.partners;
        // this.jobsBlock = this.cmsPageData.page.about.jobs;
        // this.patnersLogos = this.cmsPageData.page.about.partners_logos;

        // this.jobs = this.cmsPageData.page.about.jobs.jobs_list;
        
      }
    );
    if (this.$route.hash){
      if (this.$route.hash=="#faq"){
        this.$nextTick(function(){
          var th = this;
          var a = setTimeout(function(){
            th.scrollToFAQ();
            clearTimeout(a);
          },100);
        });
      }
    }
  },
  mounted(){
    
  },
  methods: {
    scrollToFAQClick: function(e){
      e.preventDefault();
      this.scrollToFAQ();
    },
    scrollToFAQ: function(){
      let VueScrollTo = require('vue-scrollto');
      VueScrollTo.scrollTo(this.$refs.faqBlock);
    }
  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


.hero-section{
  margin-bottom:80px;
  margin-top:60px;
  .image{
    width: 50%;
    border-radius: 20px;
    overflow: hidden;
    line-height: 0px;
    padding-right: 60px;
    box-sizing: border-box;
    img{
      border-radius: 20px;
    }
  }
  .side{
    padding-top: 36px;
    width: 50%;
    padding-left: 20px;
    box-sizing: border-box;


    .title{
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
    }
    .content{
      max-width:430px;

      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #4C6C69;
    }
    .button{
      margin-top: 60px;
    }
  }
}


.benefits-section{
  margin-bottom: 80px;
  .container{
    // background: $color-super-light-green;
    margin: 0px;
    background: rgba(105, 189, 182, 0.09);
    border-radius: 20px;  
    padding: 60px;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-right: 40px;
  }
  .big-title{
    max-width: 283px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #1F4743;
    width: 283px;
  }
  .benefits{
    width: 100%;
    width: calc(100% - 283px);
    .benefit{
      width: 50%;
      padding-left: 65px;
      padding-right: 20px;
      box-sizing: border-box;
      margin-bottom: 48px;
    }
    .title{
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: #1F4743;
      margin-bottom: 16px;
    }
    .content{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      color: #1F4743;

    
      
    }
    .content ::v-deep p{
      margin-top: 16px;
    }
  }
}





.media-text-section{
  margin-bottom: 120px;
  .image{
    border-radius: 20px;
    img{
      border-radius: 20px;
    }
  }
  .title{
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #1F4743;
    margin-bottom: 20px;
  }
  .icon{
    margin-bottom: 32px;
  }
  .content{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #4C6C69;
    ::v-deep p{
      margin-top: 16px;
    }
  }
  .button-wrap{
    margin-top: 60px;
  }
}
.first-media-text-section{
  margin-top: 120px;
  margin-bottom: 80px;
  .image{
    margin-right: 100px;
    max-width: 407px;
  }
  .side{
    max-width: 590px;
    margin-top: 80px;
  }
}



.faq-list-section{
  background: rgba(105, 189, 182, 0.09);
  border-radius: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-top: 96px;
  padding-bottom: 96px;
  .section-title{
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    color: #1F4743;
    margin-bottom: 60px;
  }
  .button-wrap{
    margin-top: 80px;
    text-align: center;
  }
}




@media (max-width: 1000px) {
  .hero-section{
    .image{
      width: 35%;
      height: 400px;
      img{
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }
    .side{
      width: 65%;
      .title{
        
      }
      .content{
        
        ::v-deep p{
        
        }
      }
      .button{
        
      }
    }
  }


  .benefits-section{

    .container{
      display: block;
      padding-bottom: 60px;
    }
    .big-title{
      max-width: none;
      margin-bottom: 40px;
      width: auto;
    }
    .benefits{
      width: 100%;
      .benefit{
        padding-right: 56px;
        padding-left: 0px;
      }
      .title{
      
      }
      .content{
      
      }
      .content ::v-deep p{
      
      }
    }
  }

  .first-media-text-section{
    .flex{
      // padding: 48px;
      // padding-bottom: 80px;
    }
  }



}
@media (max-width: #{$screen-tablet}) {
  .hero-section{
    .flex{
      // display: block;
      -ms-flex-direction: column;
		  flex-direction: column;
    }
    .image{
      width: 100%;
      padding: 0px;
      height: auto;
      img{
        height: auto;
      }
    }
    .side{
      width: 100%;
      .title{
        
      }
      .content{
        
        ::v-deep p{
        
        }
      }
      .button{
        
      }
    }
  }
  .first-media-text-section{
    .flex{
      display: block;
    }
    .image{
      margin: 0px;
      max-width: none;
    }
    .side{
      max-width: none;
      margin-top: 40px;
    }
  }
}

@media (max-width: #{$screen-mobile}) {
  .hero-section{
    .side{
      .title{
        margin-bottom: 12px;
        font-size: 32px;
        line-height: 130%;
      }
      .content{
        font-size: 16px;
        line-height: 130%;
      }
    }
  }
  .benefits-section{

    .container{
      display: block;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 40px;
    }
    .big-title{
      max-width: none;
      margin-bottom: 0px;
      width: auto;

      font-weight: 700;
      font-size: 28px;
      line-height: 120%;
    }
    .benefits{
      display: block;
      .benefit{
        padding-right: 0px;
        margin-top: 40px;
        width: 100%;
      }
      .title{
      
      }
      .content{
      
      }
      .content ::v-deep p{
      
      }
    }
  }
  .media-text-section{
    margin-bottom: 80px;
  }
  .first-media-text-section{
    margin-top: 80px;
    .wrap{
      padding-left: 0px;
      padding-right: 0px;
    }
    .flex{
      padding: 20px;
      padding-bottom: 40px;
      padding-top: 40px;
    }
  }
}

</style>