import { Component, Vue } from 'vue-property-decorator';

import CheckboxField from '../checkbox-field/CheckboxField.vue';
import template from './CheckboxFieldWithPrice.vue';

@Component({
  mixins: [template],
  props: ['theme', 'size', 'name', 'value', 'children', 'customClass'],
  components: {
    'CheckboxField': CheckboxField
  }
})
export default class CheckboxFieldWithPrice extends Vue {

}