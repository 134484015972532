import { Component, Vue } from 'vue-property-decorator';

import template from './CarItem.vue';

@Component({
  mixins: [template],
  props: ['car', 'liked', 'index', 'businessType']
})
export default class CarItem extends Vue {

}