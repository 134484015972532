<template>
  <div :class="'car-item '+(likedCarIDs.includes(car.id) ? 'liked' : '')">
    <div class="car-bubble" v-if="(car.soldDate != null || car.reservedDate != null) && carBusinessType=='privat'">
      <span>{{car.soldDate != null ? "Solgt" : car.reservedDate != null ? "Reserveret" : ""}}</span>
    </div>
    <router-link class="flex flex-column flex-s5" :to="carURL" v-if="carBusinessType=='erhverv' || carBusinessType=='forhandler'">
      <div class="image" v-if="car.image"><img :src="car.image" alt="image" />
        <span class="electric-icon" v-if="car.fuel_type && car.fuel_type=='El'">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#E1F2F0"/>
          <path d="M13.5 22.5972L18.8792 22.0394L17.5345 31.3596C17.2046 33.8567 17.7355 33.2134 18.8792 31.3596L26.5 17.9969L21.1206 18.8848L22.2413 9.01539C22.6845 6.55737 22.5744 6.1084 21.1206 9.01539L13.5 22.5972Z" fill="#1F4743" stroke="#1F4743" stroke-linejoin="round"/>
          </svg>
        </span>
        <div class="car-tags flex"  v-if="carImageTags && carImageTags.length>0">
          <span :class="'tag'" v-if="tag" v-for="(tag, index) in carImageTags" :key="'car-tag-'+car.id+'-'+index" >{{tag}}</span>
        </div>
      </div>
      <div class="image" v-if="car.images && car.images[0]"><img :src="car.images[0]" alt="image" />
        <span class="electric-icon" v-if="car.fuel_type && car.fuel_type=='El'">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#E1F2F0"/>
          <path d="M13.5 22.5972L18.8792 22.0394L17.5345 31.3596C17.2046 33.8567 17.7355 33.2134 18.8792 31.3596L26.5 17.9969L21.1206 18.8848L22.2413 9.01539C22.6845 6.55737 22.5744 6.1084 21.1206 9.01539L13.5 22.5972Z" fill="#1F4743" stroke="#1F4743" stroke-linejoin="round"/>
          </svg>
        </span>
        <div class="car-tags flex"  v-if="carImageTags && carImageTags.length>0">
          <span :class="'tag'" v-if="tag" v-for="(tag, index) in carImageTags" :key="'car-tag-'+car.id+'-'+index" >{{tag}}</span>
        </div>
      </div>
      <div class="car-content flex flex-column flex-s6">
        <div>
          <p class="title">{{car.make.name}} {{car.model.name}}</p>
          <span class="like-icon" v-on:click="toggleLikeCar(car.id, $event)">
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.0004 19L2.47805 9.85662C1.72905 9.08675 1.23469 8.09383 1.0651 7.0187C0.8955 5.94356 1.05928 4.84079 1.5332 3.86679V3.86679C1.89057 3.13177 2.41261 2.49462 3.05629 2.00784C3.69998 1.52105 4.4469 1.19857 5.23551 1.06695C6.02411 0.935336 6.83184 0.998357 7.59212 1.25082C8.35241 1.50329 9.0435 1.93797 9.60845 2.51906L11.0004 3.95L12.3923 2.51906C12.9573 1.93797 13.6484 1.50329 14.4087 1.25082C15.1689 0.998357 15.9767 0.935336 16.7653 1.06695C17.5539 1.19857 18.3008 1.52105 18.9445 2.00784C19.5882 2.49462 20.1102 3.13177 20.4676 3.86679C20.9409 4.84042 21.1044 5.94253 20.935 7.01705C20.7655 8.09157 20.2718 9.08403 19.5236 9.85388L11.0004 19Z" stroke="#4C6C69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>

          <p class="sub-title" v-if="car.number_of_vehicles>1">Se {{car.number_of_vehicles}} varianter</p>
          <p class="sub-title" v-if="car.variant">{{car.variant}}</p>
        </div>
        <div>
          <p class="benefit-price">Førstegangsydelse fra {{formatPrice(car.price.downpayment.total)}} kr.</p>
          <p class="taxable-price" v-if="carBusinessType=='erhverv'">Beskatningsgrundlag fra {{formatPrice(car.price.tax.taxable_value)}} kr.</p>
          <p class="monthly-title">Månedlig ydelse fra</p>
          <p class="monthly-price">{{formatPrice(car.price.monthly_payment.total)}} kr.</p>
        </div>
      </div>
    </router-link>
    <a class="flex flex-column flex-s5" :href="carURL" v-if="carBusinessType=='privat'">
          <div class="image" v-if="car.image"><img :src="car.image" alt="image" />
              <span class="electric-icon" v-if="car.fuel_type && car.fuel_type=='El'">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#E1F2F0"/>
          <path d="M13.5 22.5972L18.8792 22.0394L17.5345 31.3596C17.2046 33.8567 17.7355 33.2134 18.8792 31.3596L26.5 17.9969L21.1206 18.8848L22.2413 9.01539C22.6845 6.55737 22.5744 6.1084 21.1206 9.01539L13.5 22.5972Z" fill="#1F4743" stroke="#1F4743" stroke-linejoin="round"/>
          </svg>
        </span>
              <div class="car-tags flex"  v-if="carImageTags && carImageTags.length>0">
                  <span :class="'tag'" v-if="tag" v-for="(tag, index) in carImageTags" :key="'car-tag-'+car.id+'-'+index" >{{tag}}</span>
              </div>
          </div>
          <div class="image" v-if="car.images && car.images[0]"><img :src="car.images[0]" alt="image" />
              <span class="electric-icon" v-if="car.fuel_type && car.fuel_type=='El'">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#E1F2F0"/>
          <path d="M13.5 22.5972L18.8792 22.0394L17.5345 31.3596C17.2046 33.8567 17.7355 33.2134 18.8792 31.3596L26.5 17.9969L21.1206 18.8848L22.2413 9.01539C22.6845 6.55737 22.5744 6.1084 21.1206 9.01539L13.5 22.5972Z" fill="#1F4743" stroke="#1F4743" stroke-linejoin="round"/>
          </svg>
        </span>
              <div class="car-tags flex"  v-if="carImageTags && carImageTags.length>0">
                  <span :class="'tag'" v-if="tag" v-for="(tag, index) in carImageTags" :key="'car-tag-'+car.id+'-'+index" >{{tag}}</span>
              </div>
          </div>
          <div class="car-content flex flex-column flex-s6">
              <div>
                  <p class="title">{{car.make.name}} {{car.model.name}}</p>
                  <span class="like-icon" v-on:click="toggleLikeCar(car.id, $event)">
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.0004 19L2.47805 9.85662C1.72905 9.08675 1.23469 8.09383 1.0651 7.0187C0.8955 5.94356 1.05928 4.84079 1.5332 3.86679V3.86679C1.89057 3.13177 2.41261 2.49462 3.05629 2.00784C3.69998 1.52105 4.4469 1.19857 5.23551 1.06695C6.02411 0.935336 6.83184 0.998357 7.59212 1.25082C8.35241 1.50329 9.0435 1.93797 9.60845 2.51906L11.0004 3.95L12.3923 2.51906C12.9573 1.93797 13.6484 1.50329 14.4087 1.25082C15.1689 0.998357 15.9767 0.935336 16.7653 1.06695C17.5539 1.19857 18.3008 1.52105 18.9445 2.00784C19.5882 2.49462 20.1102 3.13177 20.4676 3.86679C20.9409 4.84042 21.1044 5.94253 20.935 7.01705C20.7655 8.09157 20.2718 9.08403 19.5236 9.85388L11.0004 19Z" stroke="#4C6C69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
                  <p class="sub-title" v-if="car.number_of_vehicles>1">Se {{car.number_of_vehicles}} varianter</p>
                  <p class="sub-title" v-if="car.variant">{{car.variant}}</p>
              </div>
              <div>
                  <p class="benefit-price">Førstegangsydelse fra {{formatPrice(car.price.downpayment.total)}} kr.</p>
                  <p class="taxable-price" v-if="carBusinessType=='erhverv'">Beskatningsgrundlag fra {{formatPrice(car.price.tax.taxable_value)}} kr.</p>
                  <p class="monthly-title">Månedlig ydelse fra</p>
                  <p class="monthly-price">{{formatPrice(car.price.monthly_payment.total)}} kr.</p>
              </div>
          </div>
      </a>
  </div>
</template>

<script>
import eventBus from '../../../eventBus';
import AbAmount from '../../leasing/Showroom/src/common/ab_amount';

export default {
  props: ['car', 'liked', 'index', 'businessType'],
  data() {
    return {
      likedCarIDs: Array(),
      likedCarIDsPrivat: Array(),
      likedCarIDsErhverv: Array(),
      carBusinessType: null,
      carURL: "",
      abAmount: null,
      carImageTags: null
    }
  },
  beforeMount(){
    
  },
  created(){
    this.abAmount = new AbAmount();
    this.$watch(
      () => this.businessType,
      (toParams, previousParams) => {
        if (this.businessType){
          this.carBusinessType = this.businessType;
          this.setCarURL();
        }
      }
    );

    // this.car.webSection.banner = "Pilot Light Pack; Plus Pack; Metallak; ";
    if (this.car.webSection.banner){
      
      var tags = this.car.webSection.banner.trim().split(";");
      var filteredTags = [];
      for (var i=0; i<tags.length; i++){
        var t = tags[i].trim();
        if (t && t!=""){
          filteredTags.push(t);
        }
      }
      filteredTags.reverse();
      this.carImageTags = filteredTags;
    }
  },
  mounted(){
    this.loadLikes();

    if (!this.businessType){
      if (['CarListErhverv','ErhvervLeasing'].includes(this.$route.name)){
        this.carBusinessType = 'erhverv';
      }else if (['DealerCarList','DealerCarCalc'].includes(this.$route.name)){
        this.carBusinessType = 'forhandler';
      }else{
        this.carBusinessType = 'privat';
      }
    }else{
      this.carBusinessType = this.businessType;
    }

    this.setCarURL();
  },
  methods: {
    setCarURL: function(){
      //if the car has a custom url defined (from car block) use that and don't check on routes
      if (this.car.customUrl != undefined) {
        this.carURL = this.car.customUrl;
      } else {
        let routes = this.$router.getRoutes();
        if (this.carBusinessType) {
          for (const element of routes) {
            if (this.carBusinessType == "erhverv") {
              if (element.name == "CarListErhverv") {
                this.carURL = element.path + this.car.id;
              }
            } else if (this.carBusinessType == "forhandler") {
              if (element.name == "DealerCarList") {
                this.carURL = element.path + this.car.id;
              }
            } else {
              if (element.name == "CarListPrivat") {
                if (this.car.frontend_configuration_url != null &&
                  this.car.frontend_configuration_url.length) {
                  this.carURL = this.car.frontend_configuration_url;
                } else {
                  this.carURL = element.path + this.car.id;
                }
              }
            }
          }
        }
      }
    },
    toggleLikeCar: function(id,e){
      e.preventDefault();
      this.loadLikes();
      
      if (this.likedCarIDs.includes(id)){
        var index = this.likedCarIDs.indexOf(id);
        if (index !== -1) {
          this.likedCarIDs.splice(index, 1);
        }
      }else{
        this.likedCarIDs.push(id);
      }

      if (this.carBusinessType == "privat"){
        if (this.likedCarIDsPrivat.includes(id)){
          var index = this.likedCarIDsPrivat.indexOf(id);
          if (index !== -1) {
            this.likedCarIDsPrivat.splice(index, 1);
          }
        }else{
          this.likedCarIDsPrivat.push(id);
        }
      }

      if (this.carBusinessType == "erhverv"){
        if (this.likedCarIDsErhverv.includes(id)){
          var index = this.likedCarIDsErhverv.indexOf(id);
          if (index !== -1) {
            this.likedCarIDsErhverv.splice(index, 1);
          }
        }else{
          this.likedCarIDsErhverv.push(id);
        }
      }


      this.saveLikes();

      this.$emit('toggleLikeCar', id);

      eventBus.$emit("car-likes-update");
    },
    saveLikes: function(){
      localStorage.setItem('carLikes', JSON.stringify(this.likedCarIDs));
      localStorage.setItem('carLikesPrivat', JSON.stringify(this.likedCarIDsPrivat));
      localStorage.setItem('carLikesErhverv', JSON.stringify(this.likedCarIDsErhverv));
    },
    loadLikes: function(){
      var likes = localStorage.getItem('carLikes');
      if (likes){
        this.likedCarIDs = JSON.parse(likes);
      }
      likes = localStorage.getItem('carLikesPrivat');
      if (likes){
        this.likedCarIDsPrivat = JSON.parse(likes);
      }
      likes = localStorage.getItem('carLikesErhverv');
      if (likes){
        this.likedCarIDsErhverv = JSON.parse(likes);
      }
    },
    formatPrice(value){
        if (value === undefined) {
            return "0";
        }
        
        return this.abAmount.format(value)+"";
    }
  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
  .car-item{

    -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
        
    position: relative;
    display: block;
    background: $color-super-light-green;
    border-radius: 20px;
    width: 25%;
    width: calc(25% - 20px);
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 20px;

    margin-right: 10px;
    margin-left: 10px;
    
    .car-bubble{
      position: absolute;
      top: 0;
      left: 0;
      width: 88px;
      height: 80px;
      border-radius: 20px;
      z-index: 10;
      overflow: hidden;

      span{
        position: relative;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 113%;
        color: #FFFFFF;
        padding: 16px 16px 16px 10px;
        display: block;
      }
      &:before{
        content: "";
        position: absolute;
        top: -36px;
        left: -28px;
        width: 116px;
        height: 116px;
        border-radius: 58px;
        background: #1F4743;
      }
    }
    a{
      text-decoration: none;
      height: 100%;
      display: block;
      position: relative;
    }
    .electric-icon{
      position: absolute;
      top: 0px;
      right: 0px;
    }
    .title{
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      color: #1F4743;
      padding-right: 32px;
      margin-bottom: 4px;
    }
    .like-icon{
      position: absolute;
      top: 25px;
      right: 18px;
      cursor: pointer;
      path{
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;

        fill: transparent;
      }
    }

    &.liked{
      .like-icon{
        path{
          fill: #4C6C69;
          stroke: #4C6C69;
        }
      }
    }
    .sub-title{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 133%;
      color: #1F4743;
      margin-bottom: 4px;
    }
    .taxable-price{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 133%;
      color: #4C6C69;
      margin-top: 4px;
    }
    .benefit-price{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 133%;
      color: #4C6C69;
      margin-top:24px;
    }
    .monthly-title{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 133%;
      color: #4C6C69;
      margin-top: 43px;
    }
    .monthly-price{
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      color: #1F4743;
    }
    .image{
      position: relative;
      line-height: 0;
      height: 152px;
      margin: 16px;
      margin-bottom: 0px;
      img{
        mix-blend-mode: multiply;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: all 300ms;
        -webkit-transition: all 300ms;
      }
    }
    .car-tags{
      position: absolute;
      left: 0;
      bottom: 0;
      right: -4px;
      
      flex-direction: row;
      flex-wrap: wrap-reverse;

      .tag{
        display: inline-block;
        vertical-align: top;
        background: #1F4743;
        border-radius: 4px;
        

        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 133%;
        letter-spacing: -0.01em;
        color: #FFFFFF;

        margin-top: 4px;
        padding: 10px;
        padding-top: 3px;
        padding-bottom: 5px;
        margin-right: 4px;
      }
    }
    .car-content{
      padding: 16px;
      padding-bottom: 28px;
      position: relative;
      box-sizing: border-box;
      height: calc(100% - 168px);
    }
    &:hover{
      background: #FFF;
      box-shadow: 0 20px 40px -12px rgba(31, 72, 68, 0.16);
    }
  }
</style>
