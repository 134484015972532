import { Component, Vue } from 'vue-property-decorator';

import NewsSlider from '../news-slider/news-slider';
import template from './NewsSection.vue';

@Component({
  mixins: [template],
  props: ['articles', 'button', 'title', 'propNewsSlug'],
  components: {
    'news-slider' : NewsSlider
  }

})
export default class NewsSection extends Vue {

}