<template>
<div v-if="GetCurrentRouteName !== 'leasingpro' && !closed" :class="''">

    <div class="header-banner" :style="'height: '+bannerHeight+'px;'">
      <div class="wrap">
        <div class="inner">
          <div class="text" v-html="text"></div>
          <span class="close-button" v-on:click="onCloseBanner">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.1759 9.9995L14.7593 6.4245C14.9162 6.26758 15.0043 6.05475 15.0043 5.83284C15.0043 5.61092 14.9162 5.39809 14.7593 5.24117C14.6023 5.08425 14.3895 4.99609 14.1676 4.99609C13.9457 4.99609 13.7328 5.08425 13.5759 5.24117L10.0009 8.8245L6.42593 5.24117C6.26901 5.08425 6.05618 4.99609 5.83426 4.99609C5.61234 4.99609 5.39951 5.08425 5.24259 5.24117C5.08567 5.39809 4.99752 5.61092 4.99752 5.83284C4.99752 6.05475 5.08567 6.26758 5.24259 6.4245L8.82593 9.9995L5.24259 13.5745C5.16449 13.652 5.10249 13.7441 5.06018 13.8457C5.01788 13.9472 4.99609 14.0562 4.99609 14.1662C4.99609 14.2762 5.01788 14.3851 5.06018 14.4867C5.10249 14.5882 5.16449 14.6804 5.24259 14.7578C5.32006 14.8359 5.41223 14.8979 5.51378 14.9402C5.61533 14.9826 5.72425 15.0043 5.83426 15.0043C5.94427 15.0043 6.05319 14.9826 6.15474 14.9402C6.25629 14.8979 6.34846 14.8359 6.42593 14.7578L10.0009 11.1745L13.5759 14.7578C13.6534 14.8359 13.7456 14.8979 13.8471 14.9402C13.9487 14.9826 14.0576 15.0043 14.1676 15.0043C14.2776 15.0043 14.3865 14.9826 14.4881 14.9402C14.5896 14.8979 14.6818 14.8359 14.7593 14.7578C14.8374 14.6804 14.8994 14.5882 14.9417 14.4867C14.984 14.3851 15.0058 14.2762 15.0058 14.1662C15.0058 14.0562 14.984 13.9472 14.9417 13.8457C14.8994 13.7441 14.8374 13.652 14.7593 13.5745L11.1759 9.9995Z" fill="#C7E5E4"/>
            </svg>
          </span>
        </div>
      </div>
    </div>
</div>    
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';
import utils from "@/helpers";

export default {
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      closed: true,
      bannerHeight: 0,
      text: "",
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  beforeDestroy(){
    window.removeEventListener("resize", this.onResize);
  },
  mounted(){
    // alert();
    this.onResize();

  },
  created() {
    var th = this;
    // this.bannerHeight = 100;
    window.addEventListener("resize", this.onResize);

    this.$watch(() => this.cmsGlobalData, (toParams, previousParams) => {
        
        if (this.cmsGlobalData.header.show_banner){
          this.text = utils.nl2br(this.cmsGlobalData.header.banner_text);
          this.safeShowBanner();
        }

    });
    
    
  },
  methods: {

    saveSession: function(key, data){
      sessionStorage.setItem("header-banner", closed);
    },
    loadSession: function(key){
      var closed = sessionStorage.getItem("header-banner");
      
      if (closed){
        this.closed = true;
      }else{
        this.closed = false;
      }
      
      this.$nextTick(function(){
        this.onResize();
      });
    },

    safeShowBanner: function(){
      this.loadSession();
      if (!this.closed){
        this.showBanner();
      }
    },
    showBanner: function(){
      var th = this;
      th.closed = false;
      th.$nextTick(function(){
        th.onResize();
      });
    },
    onCloseBanner: function(e){
      e.preventDefault();
      this.closed = true;
      this.$nextTick(function(){
        this.onResize();
        eventBus.$emit("bannerHeight", 0);
      });
      this.saveSession();
    },
    onResize: function(){
      if (this.$el && this.$el.querySelector){

        if (this.closed){
          var bh = 0;
        }else{
          var el = this.$el.querySelector(".inner");
          if (el){
            var bh = el.scrollHeight;
          }
        }
        if (bh != this.bannerHeight){
          this.bannerHeight = bh;
          eventBus.$emit("bannerHeight", this.bannerHeight);
        }
      }
      
    }
  },
  
}
</script>
<style lang="scss">
@import "~@/styles/variables.scss";


.header-banner{
  background: #1F4743; 
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 1000;
  .inner{
    position: relative;
  }
  .text{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
    text-align: center;
    padding: 14px;
    padding-bottom: 15px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .close-button{
    position: absolute;
    display: block;
    margin-top: -10px;
    top: 50%;
    right: 0px;
    cursor: pointer;
  }
}

@media (max-width: #{$screen-mobile}) {
  .header-banner{
    .text{
      text-align: left;
      padding-left: 0px;
      padding-right: 28px;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 133%;
    }
    .close-button{
      margin-top: 0px;
      top: 8px;
      right: 8px;
      margin-right: -20px;
    }
  }
}

</style>