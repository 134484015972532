<template>

    <article class="reveal__block">
        <div class="reveal__block__wrap">
            <section class="reveal -line -inline">
                <input type="checkbox" class="state" aria-hidden="true" :id="id">
                <div :class="{'reveal__before': values.length >= revealBlockShowCount}">
                    <div class="row">
                        <div :class="rowClass" v-for="(data, index) in values">
                            <div class="row" v-show="index < revealBlockShowCount">
                                <div class="col-12">
                                    <img src="/ui/assets/svg/systemicon_darkblue_Check.svg" class="pb-1" style="width:18px;" />
                                    <span class="secondary-font__large ml-3">{{data}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <label :for="id" class="reveal__label" v-show="values.length >= revealBlockShowCount">
                    <span class="reveal__label__text">
                        <span class="reveal__label__text--more secondary-font__regular">Se flere</span>
                        <span class="reveal__label__text--less">&nbsp;</span>
                    </span>
                    <span class="reveal__toggle">
                        <svg viewBox="0 0 30 30" class="reveal__label__icon"><path d="M10,14 L15,18 L20,14"></path></svg>
                    </span>
                </label>
                <div class="reveal__panel" v-show="values.length >= revealBlockShowCount">
                    <div class="reveal__panel__inner">

                        <div class="row">
                            <div :class="rowClass" v-for="(data, index) in values" v-show="index >= revealBlockShowCount">
                                <div class="row">
                                    <div class="col-12">
                                        <img src="/ui/assets/svg/systemicon_darkblue_Check.svg" class="pb-1" style="width:18px;" />
                                        <span class="secondary-font__large ml-3">{{data}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    </article>


</template>

<script lang="ts">


        /*  SETUP
        //I typsescript
        import RevealBlockComponent from '../../../../shared/revealblock/RevealBlock.vue';
        @Component({
        name: 'temp name',
        components: {
            'reveal-block-component': RevealBlockComponent,
        }
        })

        //I html
        <reveal-block-component :revealBlockShowCount="12"
                                :id="item.BasicVehicleInformation.Id"
                                :values="item.BasicVehicleInformation.Specifications">
        </reveal-block-component>

         */

    import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from "vue-property-decorator";

    @Component({
        name: 'reveal-block-component',
        components: {

        }
    })

    export default class RevealBlockComponent extends Vue {
        @Prop() values: any[];
        @Prop() id: number;
        @Prop({ default: 12 }) revealBlockShowCount: number;
        @Prop({ default: 'col-12 col-sm-6' }) rowClass: string;

        mounted() {
            let self = this;
        }
    }
    
</script>

<style lang="scss" scoped>
    @import "../../abdk/styles/legacy/style.scss";

    .reveal {
        text-align: left;
    }

    .reveal__label {
        text-align: center;
        margin: 0px;
    }

    .reveal__toggle {
        background-color: $color-grey-1;
    }

    .reveal__block__wrap {
        max-width: none;
    }

    .reveal__before::before {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #F9F9F9 100%);
    }


</style>
