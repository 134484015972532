import { Vue } from "vue-property-decorator";
import leasingStore from "../leasingpro/store";
import PageComponentSelector from "./components/pageComponentSelector/PageComponentSelector.vue";

export default class EpiRouting extends Vue {
    routingArr: Array<any> = new Array();
    basePath = leasingStore.state.configEpi.basePath;

    addStaticRoutings() {
        this.routingArr =
            [
                {
                    //issues with the above logout gives error in Mitalmbrand view
                    path: this.basePath + '/leasingpro/logout',
                    beforeEnter: () => {
                        location.href = '/auth/logout';
                    }
                },
            ];
    }

    //tilføj statiske og dynamiske routings fra epi-server
    async addRoutes(router, store) {
        this.addStaticRoutings();
        this.routingArr.push({
            path: "*",
            component: PageComponentSelector
        });
        //router.addRoutes(this.routingArr); // depricated use below instead
        this.routingArr.forEach( r => router.addRoute(r));
    }
}
