import { Component, Vue } from 'vue-property-decorator';

import template from './CarGallery.vue';

@Component({
  mixins: [template],
  props: ['images']
})
export default class CarGallery extends Vue {

}