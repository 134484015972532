import { ActionTree } from 'vuex';
import InsurancesData from '../components/model/Insurances';
import { Person } from '../components/model/person';
import UserData from "../components/providers/UserData";
import Vue from 'vue'
import Vuex from 'vuex'

let store: any = null;

Vue.use(Vuex)

    const state = {
        createdContract: {},
        userData: UserData,
        cmsGlobalData: {},
        insurancesData: InsurancesData
    }

const mutations = {
    //payload = [person, spouse, sharedData];
    FROM_DEALER_TO_CONTRACT(state: any, payload:any) {
        state.createdContract.Contract.Buyer.Person = {};
        state.createdContract.Contract.CoBuyer.Person = {};
        state.createdContract.Contract.ContractComment = {};

        state.createdContract.Contract.Buyer.Person.Cpr = payload[0].Cpr;
        state.createdContract.Contract.Buyer.Person.FirstName = payload[0].FirstName;
        state.createdContract.Contract.Buyer.Person.LastName = payload[0].LastName;
        state.createdContract.Contract.Buyer.Person.Phone = payload[0].CellPhone;
        state.createdContract.Contract.Buyer.Person.CellPhone = payload[0].CellPhone;
        state.createdContract.Contract.Buyer.Person.Email = payload[0].Email;
        state.createdContract.Contract.Buyer.UseDigitalSigning = true;

        if (!(payload[1] === null || payload[1] === 'undefined') && payload[1].Cpr !== "") {
            state.createdContract.Contract.CoBuyer.Person.Cpr = payload[1].Cpr;
            state.createdContract.Contract.CoBuyer.Person.FirstName = payload[1].FirstName;
            state.createdContract.Contract.CoBuyer.Person.LastName = payload[1].LastName;
            state.createdContract.Contract.CoBuyer.Person.Phone = payload[1].CellPhone;
            state.createdContract.Contract.CoBuyer.Person.CellPhone = payload[1].CellPhone;
            state.createdContract.Contract.CoBuyer.Person.Email = payload[1].Email;
            state.createdContract.Contract.CoBuyer.UseDigitalSigning = true;
        } else {
            state.createdContract.Contract.CoBuyer = {};
        }

        if (!(payload[2] === null || payload[2] === 'undefined')) {
            state.createdContract.Contract.ContractComment = payload[2].Comments;
            let _date = payload[2].Date;
            if (_date.length <= 10) {
                let pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
                payload[2].Date = new Date(_date.replace(pattern, '$3-$2-$1'));
            }
            state.createdContract.Contract.DeliveryDate = payload[2].Date;
        }
    },

    FROM_BUYER_TO_CONTRACT(state: any, person: Person) {
        //erhverv
        if (!(person.Cvr === null || person.Cvr === 'undefined') && person.Cvr.length >= 8) {
            state.createdContract.Contract.Buyer.CustomerType = 1;

            state.createdContract.Contract.Buyer.Organisation = {};
            state.createdContract.Contract.Buyer.Organisation.VaTnr = person.Cvr;
            state.createdContract.Contract.Buyer.Organisation.Name = person.FirstName;
            state.createdContract.Contract.Buyer.Organisation.Address = person.Address;
            state.createdContract.Contract.Buyer.Organisation.ZipCode = person.ZipCode;
            state.createdContract.Contract.Buyer.Organisation.City = person.City;
            state.createdContract.Contract.Buyer.Organisation.CellPhone = person.CellPhone;
            state.createdContract.Contract.Buyer.Organisation.Phone = person.CellPhone;
            state.createdContract.Contract.Buyer.Organisation.Email = person.Email;
            state.createdContract.Contract.Buyer.Organisation.ContactPersonName = person.LastName;
        }
        //privat
        else {
            state.createdContract.Contract.Buyer.CustomerType = 0;
            state.createdContract.Contract.Buyer.Person = {};
            state.createdContract.Contract.Buyer.Person.FirstName = person.FirstName;
            state.createdContract.Contract.Buyer.Person.LastName = person.LastName;
            state.createdContract.Contract.Buyer.Person.Phone = person.CellPhone;
            state.createdContract.Contract.Buyer.Person.Email = person.Email;
            state.createdContract.Contract.Buyer.Person.ZipCode = person.ZipCode;
            // state.createdContract.Contract.Buyer.Person.Address = person.Address;
            state.createdContract.Contract.Buyer.Person.City = person.City;
            //samtykke
            state.createdContract.Contract.Buyer.Newsletter = person.Consent;
        }

        state.createdContract.Contract.ContractComment = {};
        state.createdContract.Contract.Buyer.UseDigitalSigning = false;
        state.createdContract.Contract.ContractComment = person.Comments;
    },

    FROM_CO_BUYER_TO_CONTRACT(state: any, person: Person) {
        state.createdContract.Contract.CoBuyer.Person = {};
        state.createdContract.Contract.CoBuyer.Person.FirstName = person.FirstName;
        state.createdContract.Contract.CoBuyer.Person.LastName = person.LastName;
        state.createdContract.Contract.CoBuyer.Person.Phone = person.CellPhone;
        state.createdContract.Contract.CoBuyer.Person.Email = person.Email;
        state.createdContract.Contract.CoBuyer.Person.ZipCode = person.ZipCode;
        state.createdContract.Contract.CoBuyer.UseDigitalSigning = false;
    },

    CLEAR_OPTIONS(state: any) {
        state.createdContract.Contract.Vehicle.Options = [];
    },

    ADD_OPTION(state: any, option: any) {
        state.createdContract.Contract.Vehicle.Options.push(option);
    },

    ADD_OPTIONS(state: any, options: any) {
        options.forEach((option: any) => {
            state.createdContract.Contract.Vehicle.Options.push(option);
        });
    },

    PERIODE_SELECTED(state: any, period: any) {
        state.createdContract.Contract.Period = period;
        state.createdContract.Contract.Leasing.TcOPeriod = period;
    },

    MILEAGE_SELECTED(state: any, mileage: any) {
        state.createdContract.Contract.Leasing.AnnualMileage = mileage;
        state.createdContract.Contract.Leasing.TcOMileage = mileage;
    },

    COLOR_SELECTED(state: any, color: any) {
        state.createdContract.Contract.Vehicle.Color = color;
    },

    TIRE_DEAL_SELECTED(state: any, tireDealType: number) {
        state.createdContract.Contract.Leasing.TireDealType = tireDealType;
    },

    SET_CREATED_CONTRACT(state: any, createdContract: any) {
        state.createdContract = createdContract;
    },

    GET_CREATED_CONTRACT(state: any) {
        return state.createdContract;
    },

    SET_USER_DATA(state: any, userData: UserData) {
            state.userData = userData;
    },
    SET_CMS_GLOBAL_DATA(state: any, data: any) {
        state.cmsGlobalData = data;
    },

    SET_INSURANCE_COMPANY(state: any, company: any) {
        state.createdContract.Contract.Leasing.InsuranceCompany = company;
    },

    SET_INSURANCE_DATA_NEW(state: any, insuranceData: any) {
        state.createdContract.Contract.Leasing.Insurance = insuranceData;
    },

    SET_INSURANCES_DATA(state: any, insurancesData: InsurancesData) {
        state.insurancesData = insurancesData;
    },

    SET_FUEL_CARD_PINCODE(state: any, pincode: string) {
        state.createdContract.Contract.FuelCardPincode = pincode;
    },
}


const actions = {

    fromDealerToContract({ commit }: { commit: any }, payload:any) {
        commit('FROM_DEALER_TO_CONTRACT', payload);
    },

    fromBuyerToContract({ commit }: { commit: any }, person: Person) {
        commit('FROM_BUYER_TO_CONTRACT', person);
    },

    fromCoBuyerToContract({ commit }: { commit: any }, person: Person) {
        commit('FROM_CO_BUYER_TO_CONTRACT', person);
    },

    clearOptions({ commit }: { commit: any }) {
        commit('CLEAR_OPTIONS');
    },

    addOption({ commit }: { commit: any }, option: any) {
        commit('ADD_OPTION', option);
    },

    addOptions({ commit }: { commit: any }, options: any) {
        commit('ADD_OPTIONS', options);
    },

    availablePeriodsSelected({ commit }: { commit: any }, period: any) {
        commit('PERIODE_SELECTED', period);
    },

    availableMileagesSelected({ commit }: { commit: any }, mileage: any) {
        commit('MILEAGE_SELECTED', mileage);
    },

    availableColorSelected({ commit }: { commit: any }, color: any) {
        commit('COLOR_SELECTED', color);
    },

    availableTireDealSelected({ commit }: { commit: any }, tireDealType: number) {
        commit('TIRE_DEAL_SELECTED', tireDealType);
    },

    setCreatedContract({ commit }: { commit: any }, createdContract: any) {
        commit('SET_CREATED_CONTRACT', createdContract);
    },

    setUserData({ commit }: { commit: any }, userData: UserData) {
        commit('SET_USER_DATA', userData);
    },

    setCMSGlobalData({ commit }: { commit: any }, data: any) {
        commit('SET_CMS_GLOBAL_DATA', data);
    },

    setInsuranceCompany({ commit }: { commit: any }, company: string) {
        commit('SET_INSURANCE_COMPANY', company);
    },
    
    setInsurance({ commit }: { commit: any }, data: any) {
        commit('SET_INSURANCE_DATA_NEW', data);
    },
    
    setInsurancesData({ commit }: { commit: any }, insurancesData: InsurancesData) {
        commit('SET_INSURANCES_DATA', insurancesData);
    },

    setFuelCardPincode({ commit }: { commit: any }, pincode: string) {
        commit('SET_FUEL_CARD_PINCODE', pincode);
    },
}

const getters = {
    getCreatedContract: (state: any) => {
        return state.createdContract;
    },

    getUserData: (state: any) => {
        return state.userData;
    },

    getCMSGlobalData: (state: any) => {
        return state.cmsGlobalData;
    },

    getInsurancesData: (state: any) => {
        return state.insurancesData;
    },

    getEksInsurancesCompany: (state: any) => {
        if (state.createdContract.Contract === undefined) {
            return "";
        }
        return state.createdContract.Contract.Leasing.InsuranceCompany;
    },
}

store = new Vuex.Store({
    state,
    mutations,
    actions,
    getters
})

export default store
