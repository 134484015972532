class Utils {
    public doAnimate(extraHeight: number, animateController: string, animateContent: string) {
        let growDiv = (document.querySelector(animateController) as HTMLElement);
        if (growDiv.clientHeight) {
            growDiv.style.height = "0";
        } else {
            let wrapper = document.querySelector(animateContent);
            growDiv.style.height = (extraHeight + wrapper.clientHeight) + "px";
        }
    }

    public closeAnimate(animateController: string) {
        let growDiv = (document.querySelector(animateController) as HTMLElement);
        growDiv.style.height = "0";
    }

    public openAnimate(extraHeight: number, animateController: string, animateContent: string) {
        let growDiv = (document.querySelector(animateController) as HTMLElement);
        let wrapper = document.querySelector(animateContent);
        growDiv.style.height = (extraHeight + wrapper.clientHeight) + "px";
    }

    cleanAttr(attr: Attr): string {
        if (attr !== null && attr.value.replace(/(\r\n\t|\n|\r\t)/gm, "").length > 0) {
            return attr.value.replace(/(\r\n\t|\n|\r\t)/gm, "");
        }

        return "";
    }

}

export default Utils;
