<template>
  <div class="cookie-disclaimer">
    <!-- Cookie Disclaimer Modal Start -->
    <div v-if="modal_visible" class="modal fade show shadow-lg" style="display: block;" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title">Vores brug af cookies</p>
          </div>
          <div class="modal-body">
            <p>Opendo A/S er dataansvarlig for denne hjemmeside, som bruger forskellige typer af cookies med det formål:</p>
            <ul>
              <li>at kunne forbedre hjemmesiden ved brug af statistiske oplysninger om [din enhed, browser og IP-adresse samt din færden på hjemmesiden] (statistiske cookies)</li>
              <li>at personalisere indholdet på hjemmesiden, så det bliver mere relevant, ved brug af oplysninger om din [IP-adresse og adfærd på hjemmesiden] (personaliseringscookies), og for</li>
              <li>at målrette vores online-markedsføring, så det matcher dine interesser, ved brug af oplysninger om din [IP-adresse og adfærd på hjemmesiden] (markedsføringscookies).</li>
            </ul>
            <p>Vi anvender både egne cookies og cookies fra tredjeparter til formålene ovenfor.</p>
            <p>Du kan give samtykke til alle typer cookies eller tilpasse dit samtykke nedenfor. Dit samtykke kan til enhver tid ændres eller trækkes tilbage ved at klikke på ”Cookies” i nederste venstre hjørne på siden. Vi bruger dog nogle cookies, der er teknisk nødvendige for hjemmesidens funktion (fx for at huske dine indstillinger), og som derfor ikke kan fravælges.</p>
            <p>Du kan læse mere om brugen af cookies i vores cookiepolitik og om vores behandling af personoplysninger, herunder dine rettigheder, i vores <a target="_blank" href="/files/Opendo-persondatapolitik.pdf">persondatapolitik</a>.</p>
          </div>
          <div class="modal-footer">

            <div class="buttons">
              
                <button v-on:click="set_selected" type="button" class="button  btn btn-outline-secondary ">{{ only_necessary ? btn_necessary_txt : btn_allow_chosen_txt }}</button>
              
                <button v-on:click="set_all" type="button" class="button btn btn-warning ">{{ btn_all_txt }}</button>
              
            </div>

            <div class="">
              <div v-for="p in policy" v-bind:key="policy.key" v-on:click="toggle(p)" v-bind:class="'checkbox-item'">
                
                <span :class="'checkbox toggle-' + (p.value ? 'on' : 'off')+' '+(p.disabled ? 'disabled' :  '')"></span>
                <div class="label" style="">{{ p.text }}</div>
              </div>
            </div>

            </div>
        </div>
      </div>
    </div>
    <!-- Cookie Disclaimer Modal End -->
    <button type="button" v-on:click="open_modal" class="button cookies-button">Cookies</button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CookieDisclaimer extends Vue {
  public btn_all_txt: string = 'Tillad Alle';
  public btn_necessary_txt: string = 'Kun Nødvendige';
  public btn_allow_chosen_txt: string = 'Tillad valgte';
  public cookie_name: string = 'cookie_policy'

  public modal_visible: boolean = false;
  public only_necessary: boolean = true;

  public policy: any = [{key: 'necessary', text: 'Nødvendige', value: true, disabled: true},
                        {key: 'marketing', text: 'Marketing', value: false},
                        {key: 'statistics', text: 'Statistik', value: false}
                       ];

  public open_modal() {
    this.modal_visible = true;
  }

  public close_modal() {
    this.modal_visible = false;
  }

  public toggle(p){
    if (!p.disabled){
      p.value = !p.value
      this._set_policy(p)
    }
  }

  private _set_policy(p){
    if (p){
      this.policy.forEach(pol => {
        if (pol.key == p.key && !pol.disabled){
          pol.value = p.value
        }
      });
    }
    this.only_necessary = true
    this.policy.forEach(pol => {
      if (pol.key != 'necessary'){
        if (pol.value) { this.only_necessary = false}
      }
    });
  }

  public set_selected() {
    this.set_cookie(this.cookie_name, JSON.stringify(this.policy))
    this.close_modal()
  }

  public set_all() {
    this.policy.forEach(pol => {
      pol.value = true
    });
    this.only_necessary = false
    this.set_cookie(this.cookie_name, JSON.stringify(this.policy))
    this.close_modal()
  }

  public set_cookie(name, value, days=30, path='/') {
    let expire = new Date()
    expire.setDate(expire.getDate() + days)
    document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value) + '; expires=' + expire.toUTCString() + '; path=' + path;
    try {
      window['do_policy']()
    } catch (e){
      console.log(e)
    }
  }

  public get_cookie(cname){
    let name: string = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  mounted(){
    if (!this.get_cookie(this.cookie_name)){
      this.open_modal()
    } else {
      let cookie = JSON.parse(this.get_cookie(this.cookie_name));
      if (cookie){
        cookie.forEach(p => {
          this._set_policy(p)
        })
      }
    }
  }

}
</script>


<style lang="scss" scoped>
@import "~@/style.scss";
.cookie-disclaimer {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 10000;
 
  .modal-dialog{
    width: auto;
    max-width: 100%;
    margin: 0px;
  }
  .cookies-button{
    border: none;
    padding: 6px 12px;
    font-size: 12px;
    position: absolute;
    right: 8px;
    bottom: 8px;

    display: none;
  }
  
  .checkbox-item{
    cursor: pointer;
    margin-bottom: 10px;
    .label{
      display: inline-block;
      vertical-align: top;
      margin-left: 8px;
      margin-top: 2px;
    }
    .checkbox{
      display: inline-block;
      vertical-align: top;
      width: 20px;
      height: 20px;
      border: 2px solid #1F4743;
      box-sizing: border-box;
      border-radius: 4px;
      background: #1F4743;

      &.disabled{
        cursor: default;
        opacity: 0.5;        
      }
      &.toggle-off{
        // opacity: 0.5;
        background: #FFF;
      }

      &.toggle-on{
        // opacity: 1;
        background: #1F4743;
      }
    }
  }
  .modal{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.8);
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  .modal-content {
    border: none;
    background-color: #FFF;

    box-shadow: 0px 32px 64px -24px rgba(31, 72, 68, 0.16);
    border-radius: 20px;


    padding: 25px;
    box-sizing: border-box;
    max-height: 90vh;
    max-height: calc(90vh - 40px);
    overflow-y: scroll;

    position: relative;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .modal-title{
    margin-bottom: 24px;
  }

  .modal-header {
    color: black;
    border: none;

    p {
      font-size: 2rem !important;
    }
  }

  .modal-title {
    font-weight: bold;
  }

  .modal-body {
    // max-height: 300px;
    // overflow-y: auto;

    p {
      padding-bottom: 0.75rem;
      line-height: 1.2em !important;
    }
    ul {
      li {
        line-height: 1.2em !important;
        list-style: disc;
        padding-bottom: 1rem;
        margin-left: 24px;
      }
    }
    a {
      color: inherit;
    }
  }

  .modal-footer {
    border: none;
    padding-bottom: 0;
    display: inline-block !important;

    margin-top: 32px;
    .text-secondary {
      color: #6c757d !important;
    }
    .buttons{
      margin-bottom: 20px;
    }
    button {
      border: none;
      margin: 0px;
      margin-bottom: 12px;
      margin-right: 12px;
    }
    button.btn-outline-secondary {
      // color: #6c757d;
      // border-color: #6c757d;
    }
    button.btn-outline-secondary:hover{
      // color: #fff;
      // background-color: #6c757d;
      // border-color: #6c757d;
    }
    button.btn-warning {
      // color: white;
      // background-color: #fba204;
      // border-color: #fba204;
    }
    button.btn-warning:hover {
      // background-color: #ffc107;
      // border-color: #ffc107;
    }
  }

  button.cookie-disclaimer-corner-button {
    // position: fixed;
    // margin: 0px;
    // border: none;
    // background: #ffc107;
    // color: #FFF;
    // cursor: pointer;
  }
  button.cookie-disclaimer-corner-button:hover {
    // background: #ffc107;
    // color: #ffc107;
    // background: #FFF;
  }
}

@media (max-width: #{$screen-mobile}) {

}
</style>
