<template>
  <div class="news-archive-page ">
    <div class="page-content">
      <div class="wrap fade-on-enter">
        <h1 class="h1-2">{{topTitle}}</h1>
      </div>
      <div class="wrap">
        <div class="tags-list fade-on-enter">
          <a :class="'tag transition-all '+(activeTagIDs.includes(tag.term_id) ? 'selected' : '')" v-on:click="onTagFilterClick(tag)" :data-slug="tag.slug" :data-id="tag.term_id" v-for="tag in tags" :key="tag.term_id" >{{tag.name}}</a>
          
        </div>
        <div class="news-list">

          <div class="item fade-on-enter flex my-flex-row flex-s8" v-for="article in articles" :key="article.ID">
            <router-link class="thumbnail" :to="thisSlug+'/'+article.post_name" >
              <div class="image transition-all" v-if="article.thumbnail" :style="'background-image: url('+article.thumbnail+')'">
                <!-- <img :src="article.thumbnail" alt="thumbnail" /> -->
              </div>
            </router-link>
            <div class="content flex flex-column flex-s6">
              <div>
                <span class="date">{{article.date2}}</span>
                <router-link class="" :to="thisSlug+'/'+article.post_name" >
                  <p class="title">{{article.post_title}}</p>
                </router-link>
                <p class="description" v-html="article.excerpt"></p>
              </div>
              <div class="item-tags">
                <div class="tag" v-for="tag in article.tags" :key="tag.term_id" >
                  {{tag.name}}
                </div>
              </div>
            </div>
          </div>
          
        </div>

        <div class="pagination" v-if="lastPage>1">
          <router-link :class="'prev-button transition-all '+(currentPage <= 1 ? 'disabled' : '' )" :to="''+thisSlug+'?'+cartegoryHash+'page='+(currentPage-1)" >Prev</router-link>
          <span v-for="(page, index) in pagination" :key="'pagination-item-'+index">
            <span class="dots" v-if="page == '.'">...</span>
            <router-link :class="'page-number transition-all '+(page == currentPage ? 'active' : '')" v-else :to="''+thisSlug+'?'+cartegoryHash+'page='+page" >{{page}}</router-link>
            
          </span>
          <router-link :class="'next-button transition-all '+(currentPage >= lastPage ? 'disabled' : '' )" :to="''+thisSlug+'?'+cartegoryHash+'page='+(currentPage+1)" >Next</router-link>
        </div>

        <!--
        <div class="load-more-button-wrap">
          <div class="button load-more-button">
            {{loadMoreTitle}}
          </div>
        </div>
        -->
      </div>
      <span v-if="cmsPageData && !cmsPageData.loaded"></span>
    </div>
  </div>
  
</template>


<script>

import VueScrollTo from "vue-scrollto";
import Axios from "axios";
import eventBus from '../../eventBus';

export default {
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      axios: null,
      thisSlug: "nyheder",
      cartegoryHash: "",
      activeTagIDs: Array(),
      articles: null,
      topTitle: null,
      articlesCount: 0,
      loadMoreTitle: null,
      tags: null,
      currentPage: 1,
      lastPage: 10,
      pagination: [1,2,".",5]
    }
  },
  beforeMount(){
    // alert(footerData.fields.copyright);
    // this.copyright = "b";
  },
  created(){
    // if (this.axios == null){
    //   this.axios = Axios.create({
    //       timeout: 60000,
    //   });
    // }

    // window.addEventListener("loaded-cms-page-data", function(){
    //   alert("a");
    //   // th.cmsPageData = window.cmsPageData;
    //   // th.saveCache("cmsPageData"+window.location.pathname, th.cmsPageData);
		// });

    this.prepareActiveTags();
    this.preparePagiantion();
    // this.loadNews();

    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {
        this.articles = this.cmsPageData.page.news_list;
        this.articlesCount = this.cmsPageData.page.news_count;
        this.lastPage = this.cmsPageData.page.news_pages;
        // this.lastPage = 2;
        if (this.cmsPageData.page.news_page){
          this.topTitle = this.cmsPageData.page.news_page.top_title;
          this.loadMoreTitle = this.cmsPageData.page.news_page.load_more;
          this.tags = this.cmsPageData.page.news_page.tags;
        }

        this.preparePagiantion();

        
          // eventBus.$emit("handlePageAnimations");
        
        // alert(this.lastPage);
        // alert("a");
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.$watch(
      () => this.articles,
      (toParams, previousParams) => {
        this.$nextTick(function(){
          eventBus.$emit("handlePageAnimations");
        });
      }
    );

    this.$watch(
      () => this.currentPage,
      (toParams, previousParams) => {
        this.$nextTick(function(){
          let VueScrollTo = require('vue-scrollto');
          VueScrollTo.scrollTo(document.querySelector('.page-content'));
        });
      }
    );

    

    this.$watch(
      () => this.$route,
      (toParams, previousParams) => {
        console.log(this.$route);
        this.prepareActiveTags();
        this.preparePagiantion();
      }
    );
  },
  mounted(){
    
  },
  methods: {
    prepareActiveTags(){
      var categories = this.$route.query.category;
      var page = this.$route.query.page;

      if (categories){
        
        var ids = categories.split(',');
        for(var i=0; i< ids.length; i++){
          ids[i] = ids[i]*1;
        }
        this.activeTagIDs = ids;
      }else{
        this.activeTagIDs = Array();
      }
      if (page){
        this.currentPage = page*1;
      }else{
        this.currentPage = 1;
      }

      var ids = "";
      for (var i=0; i<this.activeTagIDs.length; i++){
        ids+= this.activeTagIDs[i]+",";
      }
      ids = ids.slice(0, -1);
      if (ids){
        this.cartegoryHash = "category="+ids+"&";
      }else{
        this.cartegoryHash = "";
      }

    },
    preparePagiantion(){

      var size = 2;
      var pages = [];

      if (this.currentPage>size+1){
        pages.push(1);
      }
      if (this.currentPage>size+2){
        pages.push(".");
      }
      for(var i=this.currentPage-size; i<=this.currentPage+size; i++){
        if (i>0 && i<=this.lastPage){
          pages.push(i);
        }
      }
      i--;
      
      if (i<this.lastPage-1){
        pages.push(".");
        i++;
      }
      if (i<this.lastPage){
        pages.push(this.lastPage);
      }

      this.pagination = pages;
    },
    // loadNews(){
    //   if (this.leasingType == "erhverv"){
    //     var finalUrl = "https://api.opendo.dk/leasing/Corporate/vehicles?size=8";
    //   }else{
    //     var finalUrl = "https://api.opendo.dk/leasing/Consumer/vehicles?size=8";
    //   }

      
      
    //   this.axios.get(finalUrl).then((response) => {
    //     this.data = response.data;
    //     this.carlist = this.data.data;
    //     console.log(this.carlist);
    //     this.loading = false;
    //   })    
    // },
    onTagFilterClick(tag){
      // alert(tag.slug);
      var id = tag.term_id;
      if (this.activeTagIDs.includes(id)){
        var index = this.activeTagIDs.indexOf(id);
        if (index !== -1) {
          this.activeTagIDs.splice(index, 1);
        }
      }else{
        this.activeTagIDs.push(id);
      }
      var ids = "";


      //Return to first page
      this.currentPage = 1;
      // this.activeTagIDs = [];

      for (var i=0; i<this.activeTagIDs.length; i++){
        ids+= this.activeTagIDs[i]+",";
      }
      ids = ids.slice(0, -1);

      
      

      var url = "";
      if (this.activeTagIDs.length>0 && this.currentPage>1){
        // url = '?category='+ids+'&page='+this.currentPage;
        this.$router.push({ name: 'News', query: { category: ids, page: this.currentPage } });
      }else{
        if (this.activeTagIDs.length>0){
          // url = '?category='+ids;
          this.$router.push({ name: 'News', query: { category: ids} });
        } else if (this.currentPage>1){
          // url = '?page='+this.currentPage;
          this.$router.push({ name: 'News', query: { page: this.currentPage } });
        }else{
          this.$router.push({ name: 'News', query: {  } });
        }
      }
      

      // this.$router.push({ name: 'News', query: { category: ids, page: this.currentPage } });

      // this.cartegoryHash = "category="+ids+"&";
    }
  },
 
}
</script>

<style lang="scss" scoped>
@import "~@/style.scss";

.news-list{
  margin-bottom: 0px;
  max-width: 997px;
  margin-left: auto;
  margin-right: auto;
  .item{
    background: #FFFFFF;
    border-radius: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    &:hover{
      .thumbnail{
        .image{
          
            transform: scale(1.1);
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
          
        }
      }
    }
    .thumbnail{
      line-height: 0px;
      width: 50%;
      width: calc(50% - 24px);
      box-sizing: border-box;
      background: #69BDB6;
      overflow: hidden;
      .image{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        background-position: 50% 50%;
      }
    }
    .content{
      width: 50%;
      width: calc(50% + 24px);
      padding: 48px;
      padding-top: 34px;
      padding-bottom: 36px;
      box-sizing: border-box;
      a{
        text-decoration: none;
      }
    }

    .date{
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 133%;
      color: #4C6C69;
      opacity: 0.8;
      margin-bottom: 4px;
    }
    .title{
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 130%;
      color: #1F4743;
      text-decoration: none;
      margin-bottom: 20px;
    }
    .description{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      color: #1F4743;
      opacity: 0.8;
    }
    
  }
}
.news-archive-page{
  .page-content{
    background: rgba(105, 189, 182, 0.09);
    border-radius: 40px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding-bottom: 140px;
  }
  h1{
    text-align: center;
    margin-bottom: 60px;

    color: #1F4743;
  }
  .tags-list{
    text-align: center;
    margin-bottom: 40px;
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
    
    .tag{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 133%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #1F4743;
      display: inline-block;
      border: 1px solid #C7E5E4;
      box-sizing: border-box;
      border-radius: 40px;
      padding: 5px 16px 8px 16px;
      cursor: pointer;
      margin-left: 4px;
      margin-right: 4px;
      margin-bottom: 8px;
      &.selected{
        color: #FFF;
        background-color: #69BDB6;
        border: 1px solid #69BDB6;
      }
      &:hover{
        color: #FFF;
        background-color: #69BDB6;
        border: 1px solid #69BDB6;
      }

    }
  }
  .load-more-button-wrap{
    text-align: center;
  }
  
}
@media (max-width: 1000px) {
  .news-list{
    
    .item{
    
      .thumbnail{
        width: 35%;
        width: calc(35% - 24px);
        .image{
        
        }
      }
      .content{
        width: 65%;
        width: calc(65% + 24px);
      }



      .date{
        font-weight: 400;
        font-size: 12px;
        line-height: 133%;
        margin-bottom: 4px;
      }
      .title{
        font-weight: 700;
        font-size: 28px;
        line-height: 130%;
        margin-bottom: 20px;
      }
      .description{
        font-weight: 400;
        font-size: 14px;
        line-height: 133%;
      }
    }
  }
}
@media (max-width: #{$screen-tablet}) {
  .news-archive-page{
    .page-content{
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}
@media (max-width: #{$screen-mobile}) {
  .news-archive-page{
    .page-content{
      padding-top: 32px;
    }
    h1{
      margin-bottom: 32px;
    }
  }
  .news-list{
    
    .item{
      display: block;
      border-radius: 15px;
      .thumbnail{
        width: 100%;
        height: 169px;
        display: block;
        border-radius: 15px;
        .image{
          border-radius: 15px;
        }
      }
      .content{
        width: 100%;
        padding: 16px;
        padding-bottom: 32px;
      }



      .date{
        font-weight: 400;
        font-size: 12px;
        line-height: 133%;
        margin-bottom: 4px;
      }
      .title{
        font-weight: 700;
        font-size: 28px;
        line-height: 130%;
        margin-bottom: 0px;


        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
      }
      .description{
        display: none;
      }
      .item-tags{
        margin-top: 32px;
      }
    }
  }
  .news-archive-page{
    .page-content{
      padding-bottom:60px;
      margin-left: 0px;
      margin-right: 0px;
    }
   
  }
}

</style>
