<template>
  <div class="main liked-cars-page">
    <div class="block car-list-section" v-if="carsIsLoaded === 0">
      <div class="cars-loader flex flex-s1">
          <div class="spinner">
              <div class="double-bounce1"></div>
              <div class="double-bounce2"></div>
          </div>
      </div>
    </div>
    <div class="block car-list-section" v-if="carsIsLoaded === 1 && cars.length>0">
      <div class="wrap">
        <h1 class="h2-1">Dine favoritter</h1>
        
        <div class="car-list flex my-flex-row my-flex-wrap flex-s8">
          <CarItem :businessType="(($route.name == 'LikedCarsErhverv') ? 'erhverv' : 'privat')" v-if="likedCarIDs.indexOf(car.id)!== -1" :car="car" :index="index" @toggleLikeCar="toggleLikeCar" v-for="(car, index) in carList" :key="'car-'+car.id" />
        </div>
      </div>
    </div>
    <div class="block car-list-section" v-if="carsIsLoaded === 1 && cars.length<=0">
      <div class="wrap">
        <h1 class="h2-1">Dine favoritter</h1>
        
        <h2 class="h3-1">Ingen favoritter valgt</h2>
      </div>
    </div>
    
    <span v-if="cmsPageData && !cmsPageData.loaded"></span>
  </div>
</template>
<script>

import AbAxios from '../../components/leasing/Showroom/src/common/ab_axios_leasing';
import eventBus from '../../eventBus';

export default {
  
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      thisSlug: "",
      carList: null,


      getConsumerVehiclesServiceUrl: "/leasing/Consumer/vehicles",
      getCorporateVehiclesServiceUrl: "/leasing/Corporate/vehicles",
      
      pageType: 'privat',
      cachedCars: null,
      cars: null,
      abAxios: null,
      responsesToWait: 0,
      carsIsLoaded: 0,
      likedCarIDs: [],
      likedCarIDsPrivat: [],
      likedCarIDsErhverv: []
    }
  },
  beforeMount(){
    // alert(footerData.fields.copyright);
    // this.copyright = "b";
  },
  created(){

    this.abAxios = new AbAxios(window.location.origin, "application/json; charset=utf-8");
    
    this.init();


    this.thisSlug = window.location.pathname;
    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {
        
        // this.thisSlug = this.cmsPageData.page.url;
        // this.heroBlock = this.cmsPageData.page.about.hero;
        // this.historyBlock = this.cmsPageData.page.about.history;
        // this.partnersBlock = this.cmsPageData.page.about.partners;
        // this.jobsBlock = this.cmsPageData.page.about.jobs;
        // this.patnersLogos = this.cmsPageData.page.about.partners_logos;

        // this.jobs = this.cmsPageData.page.about.jobs.jobs_list;
        
      }
    );
    this.$watch(
      () => this.cars,
      (toParams, previousParams) => {
        var carslist = [];
        var likedIDs = [];
        for(var i=0; i<this.cars.length; i++){
          // if (i<6){

            if (this.likedCarIDs.indexOf(this.cars[i].id)!== -1){
              likedIDs.push(this.cars[i].id);
              carslist.push(this.cars[i]);
            }
          // }
        }
        
        if (this.pageType == "privat"){
          this.likedCarIDsPrivat = likedIDs;
        }
        if (this.pageType == "erhverv"){
          this.likedCarIDsErhverv = likedIDs;
        }

        this.likedCarIDs = this.likedCarIDsPrivat.concat(this.likedCarIDsErhverv);
        this.carList = carslist;

        this.saveLikes();

        eventBus.$emit("car-likes-update");
      }
    );

    this.$watch(
      () => this.$route.name,
      (toParams, previousParams) => {
        this.init(); 
      }
    );
    

    
  },
  mounted(){
    
  },
  methods: {
    init: function(){
      this.loadLikes();
      
      this.cars = [];
      this.cachedCars = [];
      this.carList = [];
      this.carsIsLoaded = 0;
      this.responsesToWait = 2;
      setTimeout(() => {
          if (this.$route.name == "LikedCarsErhverv") {
              this.pageType = 'erhverv';
              this.getVehiclesViaRest(this.getCorporateVehiclesServiceUrl+"?size=10000");
              this.getVehiclesViaRest(this.getCorporateVehiclesServiceUrl+"?used=true&size=10000");
          }
          else if (this.$route.name == "LikedCarsPrivat") {
              this.pageType = 'privat';
              this.getVehiclesViaRest(this.getConsumerVehiclesServiceUrl+"?size=10000");
              this.getVehiclesViaRest(this.getConsumerVehiclesServiceUrl+"?used=true&size=10000");
          }
      }, 500);
    },
    toggleLikeCar: function(id){
      
    },
    
    getVehiclesViaRest(url) {

      var self = this;

      
      
      this.abAxios.abGetWithToken(url, false, function successCallback(response) {
      self.responsesToWait--;
      self.handleGetVehicleResponse(response.data);
      if (self.responsesToWait == 0){
          self.carsIsLoaded = 1;
      }
      }, function errorCallback(error) {
              self.carsIsLoaded = 2;
              if (!isNullOrUndefined(error.response)) {
                  let errorRes = error.response,
                      statusCode = errorRes.status,
                      statusText = errorRes.statusText,
                      errorMsg = "Listen af biler kunne ikke hentes. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
                  self.abGtm.triggerGtmEvent("fejl", "getVehicles", '[' + self.abGtm.getUtcTimestamp() + '] ' + errorMsg);
              } else {
                  self.abGtm.triggerGtmEvent("fejl", "getVehicles", '[' + self.abGtm.getUtcTimestamp() + '] ' + error);
              }
          });
    },
    handleGetVehicleResponse(response) {  
      // this.cachedCars = response.data;
      // this.cachedCars = this.doGeneralFilter(this.cachedCars);

      if (!this.cachedCars){ this.cachedCars = []; } 
      if (this.cachedCars){
          this.cachedCars = this.cachedCars.concat(response.data);
      }

      if (this.responsesToWait == 0){

          var liked = [];
          var cars = [];
          for(var i=0; i<this.cachedCars.length; i++){
            var car = this.cachedCars[i];
            if (this.likedCarIDs.indexOf(car.id)!== -1){
              liked.push(car.id);
              cars.push(car);
            }
          }
          
          

          this.cars = cars;
          
      }
      // console.log(this.cars);
      // this.cars = (response.data);
    },
    // toggleLikeCar: function(id,e){
    //   e.preventDefault();
    //   this.loadLikes();
    //   if (this.likedCarIDs.includes(id)){
    //     var index = this.likedCarIDs.indexOf(id);
    //     if (index !== -1) {
    //       this.likedCarIDs.splice(index, 1);
    //     }
    //   }else{
    //     this.likedCarIDs.push(id);
    //   }
    //   this.saveLikes();

    //   this.$emit('toggleLikeCar', id);
    // },
    
    loadLikes: function(){
      var likes = localStorage.getItem('carLikes');
      if (likes){
        this.likedCarIDs = JSON.parse(likes);
      }
      likes = localStorage.getItem('carLikesPrivat');
      if (likes){
        this.likedCarIDsPrivat = JSON.parse(likes);
      }
      likes = localStorage.getItem('carLikesErhverv');
      if (likes){
        this.likedCarIDsErhverv = JSON.parse(likes);
      }
    },
    saveLikes: function(){
      localStorage.setItem('carLikes', JSON.stringify(this.likedCarIDs));
      localStorage.setItem('carLikesPrivat', JSON.stringify(this.likedCarIDsPrivat));
      localStorage.setItem('carLikesErhverv', JSON.stringify(this.likedCarIDsErhverv));
    },
    
  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.cars-loader{
  height: calc(100vh - 240px);
  box-sizing: border-box;
  // position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}
.car-list-section{
  margin-bottom: 240px;
  margin-top: 60px;
  h1{
    margin-bottom: 12px;
  }
}
.car-list{
  margin-top: 56px;
}



.car-list{
  position: relative;
  margin-left: -10px;
  margin-right: -10px;
  width: calc(100% + 20px);
}
.car-list-section{
  .more-button-wrap{
    margin-top: 60px;
    text-align: center;
  }
  margin-bottom: 80px;
}
@media (max-width: 1000px) {
  .car-list{
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
    .car-item{
      width: calc(33.33% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
    }
  }
}
@media (max-width: #{$screen-tablet}) {
  .car-list{
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
    .car-item{
      width: calc(50% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
    }
  }
}


@media (max-width: #{$screen-mobile}) {
  .car-list{
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    .car-item{
      width: 100%;
      margin: 0px;
      margin-bottom: 20px;
    }
  }
}


</style>