import * as localForage from "localforage";

import AbAxios from "./BankloginAxios.ts";
import Vue from 'vue'

const globalBankloginData = {

}
const bankloginData = {
    events: new Vue(),
    loggedIn: false,
    email: "",
    name: "",
    token: "",

    abAxios: new AbAxios(),
    bankloginTokenKey: "BankloginToken",
    bankloginPopupKey: "BankloginPopup",
    pageData: null,
    homePopupClosed: false,

    closeHomePopup: function(){
        var th = this;
        this.homePopupClosed = true;
        localForage.setItem(this.bankloginPopupKey, {
            "homePopupClosed": th.homePopupClosed
        });
    },
    
    submitLogin: function(userName, password, success, failed){
        var th = this;
        var headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            // "Authorization": 'no',
            // "x-csrf-token": 'tok'
        };
        var formData = new FormData();
        formData.append("action", "bank-auth-login");
        formData.append("username", userName);
        formData.append("password", password);

        th.abAxios.abPostWithHeaders('/', formData, headers, true, function successCallback(response) {

        var data = response.data;
        
        if (data.success){
            localForage.setItem(th.bankloginTokenKey, {
                "token": data.token,
                "bank_logo": data.bank_logo,
                "bank_name": data.bank_name,
                "bank_show_referral_module": data.bank_show_referral_module,
                "user_name": data.user_name,
                "email": userName
            });
            if (success){
                th.events.$emit("auth-success");
                success();
            }
        }else{
            if (failed){
                th.events.$emit("auth-failed");
                failed();
            }
        }

        }, function errorCallback(error) {
            console.log(error);
            var errorRes = error.response,
                statusCode = errorRes.status,
                statusText = errorRes.statusText,
                errorMsg = "Login fejlede. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
        });

        // this.$emit("submit", {"username": this.userName, "password": this.password});
    },
    submitSetPassword: function(userName, password, key, success, failed){
        var th = this;
        var headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            // "Authorization": 'no',
            // "x-csrf-token": 'tok'
        };
        var formData = new FormData();
        formData.append("action", "set-first-time-password");
        formData.append("username", userName);
        formData.append("password", password);
        formData.append("key", key);

        th.abAxios.abPostWithHeaders('/', formData, headers, true, function successCallback(response) {

        // console.log(response);
        var data = response.data;
        
        if (data.success){
            if (success){
                success();
            }
        }else{
            if (failed){
                failed();
            }
        }

        }, function errorCallback(error) {
            console.log(error);
            var errorRes = error.response,
                statusCode = errorRes.status,
                statusText = errorRes.statusText,
                errorMsg = "Login fejlede. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
        });


        // this.$emit("submit", {"username": this.userName, "password": this.password});
  
    },

    submitRegister: function(fullName, userName, password, newsletter, success, failed){
        var th = this;
        var headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            // "Authorization": 'no',
            // "x-csrf-token": 'tok'
        };
        var formData = new FormData();
        formData.append("action", "bank-auth-register");
        formData.append("fullname", fullName);
        formData.append("username", userName);
        formData.append("password", password);
        formData.append("newsletter", newsletter);
        
        // formData.append("key", key);

        th.abAxios.abPostWithHeaders('/', formData, headers, true, function successCallback(response) {

        // console.log(response);
        var data = response.data;
        
        if (data.success){
            if (success){
                success();
            }
        }else{
            if (failed){
                failed(data);
            }
        }

        }, function errorCallback(error) {
            console.log(error);
            var errorRes = error.response,
                statusCode = errorRes.status,
                statusText = errorRes.statusText,
                errorMsg = "Login fejlede. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
        });


        // this.$emit("submit", {"username": this.userName, "password": this.password});
  
    },

    submitResendValidationEmail: function(userName, success, failed){
        var th = this;
        var headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            // "Authorization": 'no',
            // "x-csrf-token": 'tok'
        };
        var formData = new FormData();
        formData.append("action", "resend-bank-user-validation-email");
        formData.append("username", userName);
        // formData.append("key", key);

        th.abAxios.abPostWithHeaders('/', formData, headers, true, function successCallback(response) {

        // console.log(response);
        var data = response.data;
        
        if (data.success){
            if (success){
                success();
            }
        }else{
            if (failed){
                failed();
            }
        }

        }, function errorCallback(error) {
            console.log(error);
            var errorRes = error.response,
                statusCode = errorRes.status,
                statusText = errorRes.statusText,
                errorMsg = "Login fejlede. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
        });


        // this.$emit("submit", {"username": this.userName, "password": this.password});
  
    },

    submitValidateUser: function(userName, key, success, failed){
        var th = this;
        var headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            // "Authorization": 'no',
            // "x-csrf-token": 'tok'
        };
        var formData = new FormData();
        formData.append("action", "validate-bank-user-account");
        formData.append("username", userName);
        formData.append("key", key);

        th.abAxios.abPostWithHeaders('/', formData, headers, true, function successCallback(response) {

        // console.log(response);
        var data = response.data;
        
        if (data.success){
            if (success){
                success();
            }
        }else{
            if (failed){
                failed();
            }
        }

        }, function errorCallback(error) {
            console.log(error);
            var errorRes = error.response,
                statusCode = errorRes.status,
                statusText = errorRes.statusText,
                errorMsg = "Login fejlede. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
        });


        // this.$emit("submit", {"username": this.userName, "password": this.password});
  
    },
  
    recoverFromToken: function (success, failed){
        var th = this;
        localForage.getItem(th.bankloginPopupKey, function(err, data) {
            if (data !== null){
                th.homePopupClosed = data.homePopupClosed;
            }
        });

        localForage.getItem(th.bankloginTokenKey, function(err, token) {
          
            if (token !== null && token.token){
              var headers = {
                  "Content-Type": "application/x-www-form-urlencoded",
                  // "Authorization": 'no',
                  // "x-csrf-token": 'tok'
              };
              var formData = new FormData();
              formData.append("action", "bank-auth-token");
              formData.append("username", token.email);
              formData.append("token", token.token);

              
              formData.append("path", window.location.pathname);
              formData.append("query", window.location.search);
              
  
              th.abAxios.abPostWithHeaders('/', formData, headers, true, function successCallback(response) {
  
                var data = response.data;    
  
                if (data.success){
                    th.email = token.email;
                    th.token = data.token;

                    if(data.page){
                        th.pageData = data.page;
                    }

                    globalBankloginData.email = token.email;
                    th.loggedIn = true;

                    localForage.setItem(th.bankloginTokenKey, {
                        "token": data.token,
                        "bank_logo": data.bank_logo,
                        "bank_name": data.bank_name,
                        "user_name": data.user_name,
                        "bank_show_referral_module": data.bank_show_referral_module,
                        "email": th.email
                    });
                    if (success){
                        th.events.$emit("auth-success");
                        success();
                    }
                }else{
                    th.loggedIn = false;
                    localForage.removeItem(th.bankloginTokenKey, function(){
                        if (failed){
                            th.events.$emit("auth-failed");
                            failed();
                        }
                    });
                }
  
              }, function errorCallback(error) {
                    if (failed){
                        th.events.$emit("auth-failed");
                        failed();
                    }
                  var errorRes = error.response,
                      statusCode = errorRes.status,
                      statusText = errorRes.statusText,
                      errorMsg = "Login fejlede. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
              });
            }else{
                if (failed){
                    failed();
                }
            }
        });
  
  
        
        
          // this.$emit("submit", {"username": this.userName, "password": this.password});
    },


    sendForgotPasswordEmail: function(email, success, failed){
        var th = this;
        var headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            // "Authorization": 'no',
            // "x-csrf-token": 'tok'
        };
        var formData = new FormData();
        formData.append("action", "bank-forgot-password");
        formData.append("username", email);

        th.abAxios.abPostWithHeaders('/', formData, headers, true, function successCallback(response) {

        var data = response.data;
        
        if (data.success){
            
            if (success){
                success();
            }
        }else{
            if (failed){
                failed();
            }
        }

        }, function errorCallback(error) {
            console.log(error);
            var errorRes = error.response,
                statusCode = errorRes.status,
                statusText = errorRes.statusText,
                errorMsg = "Login fejlede. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
        });

        // this.$emit("submit", {"username": this.userName, "password": this.password});
    },

    submitResetPassword: function(userName, password, key, success, failed){
        var th = this;
        var headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            // "Authorization": 'no',
            // "x-csrf-token": 'tok'
        };
        var formData = new FormData();
        formData.append("action", "bank-reset-password");
        formData.append("username", userName);
        formData.append("password", password);
        formData.append("key", key);

        th.abAxios.abPostWithHeaders('/', formData, headers, true, function successCallback(response) {

        // console.log(response);
        var data = response.data;
        
        if (data.success){
            if (success){
                success();
            }
        }else{
            if (failed){
                failed();
            }
        }

        }, function errorCallback(error) {
            console.log(error);
            var errorRes = error.response,
                statusCode = errorRes.status,
                statusText = errorRes.statusText,
                errorMsg = "Login fejlede. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
        });


        // this.$emit("submit", {"username": this.userName, "password": this.password});
  
    },
    


    logout: function(success){
        var th = this;
        th.loggedIn = false;
        localForage.removeItem(th.bankloginPopupKey, function() {});
        localForage.removeItem(th.bankloginTokenKey, function() {
            if (success){
                success();
            }
        });
        
    }
}

Vue.prototype.BLData = globalBankloginData;

export default bankloginData