<template>
  <div class="block car-slider-section">
    <div class="wrapa">
      
      <div class="car-slider" v-if="cars && cars.length>0">
        <swiper
          ref="carSlider"
          :slides-per-view="slidesPerView"
          :space-between="slidesSpacing"
          :loop="false"
          slideVisibleClass = "visible-slide"
          :watchSlidesVisibility = "true"
          :autoHeight = "false"
          :navigation="{
            prevEl: '.prev',
            nextEl: '.next',
          }"
          :pagination="{
            el: '.cars-slider-swiper-pagination-'+sliderIndex,
            type: 'bullets',
            clickable: true
          }"
          :scrollbar="{
            el: '.cars-slider-swiper-scrollbar-'+sliderIndex,
            draggable: true,
          }"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide
            v-for="(car, index) in cars" :key="'car-slider-item-'+car.id" 
            class="slide"
            :class="{test_2: false}"
          >
            <CarItem :car="car" :index="index" @toggleLikeCar="toggleLikeCar" />
          </swiper-slide>

          <div :class="'swiper-pagination cars-slider-swiper-pagination-'+sliderIndex"></div>
          <div :class="'cars-slider-swiper-scrollbar cars-slider-swiper-scrollbar-'+sliderIndex"></div>
          <div class="prev" ref="prevButton" style="display: none;"></div>
          <div class="next" ref="nextButton" style="display: none;"></div>
          
          
          
        </swiper>
        <div v-if="!noSideMask" class="mask mask-right"></div>
        <div v-if="!noSideMask" class="mask mask-left"></div>

        <div v-if="!noArrows" class="prev" v-on:click="function(){ if ($refs.prevButton) $refs.prevButton.click(); }">
          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="22" cy="22" r="20" stroke="currentColor" stroke-width="2" fill="transparent" />
            <path d="M24.3994 15.5L16.8244 21.626C16.7715 21.6729 16.7292 21.7305 16.7002 21.795C16.6712 21.8594 16.6562 21.9293 16.6562 22C16.6562 22.0707 16.6712 22.1406 16.7002 22.205C16.7292 22.2695 16.7715 22.3271 16.8244 22.374L24.3994 28.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div v-if="!noArrows" class="next" v-on:click="function(){ if ($refs.nextButton) $refs.nextButton.click(); }">
          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="22" cy="22" r="20" stroke="currentColor" stroke-width="2" fill="transparent" />
            <path d="M19.6006 15.5L27.1756 21.626C27.2285 21.6729 27.2708 21.7305 27.2998 21.795C27.3288 21.8594 27.3438 21.9293 27.3438 22C27.3438 22.0707 27.3288 22.1406 27.2998 22.205C27.2708 22.2695 27.2285 22.3271 27.1756 22.374L19.6006 28.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        
      </div>
    
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination, Scrollbar } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

// Import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination, Scrollbar])


export default {
  props: ['cars', 'sliderIndex', 'noSideMask'],
  components: {
    Swiper,
    SwiperSlide,
    Scrollbar
  },
  setup() {
    return {
      modules: [Pagination, Scrollbar]
    }
  },
  data() {
    return {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      loading: true,
      slidesPerView: 4,
      slidesSpacing: 20,
      noArrows: false,
      
      // likedCarIDs: Array()
    }
  },
  beforeMount(){
    // alert(footerData.fields.copyright);
    // this.copyright = "b";
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  created(){
    this.$watch(
        () => this.cars,
        (toParams, previousParams) => {
          this.getDimensions();
        }
    );

  },
  
  mounted(){
    
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
    var th = this;

    setTimeout(function(){
      th.$nextTick(() => {
        if (th.$refs.carSlider){
          th.$refs.carSlider.$el.swiper.update();
        }
      });
    },1000);

  },
  methods: {
    toggleLikeCar: function(id){

    },
    
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;

      // this.slidesPerView = 3;
      var slides = 4;

      if(this.width< 1000){
        slides = 3;
      }
      if(this.width< 800){
        slides = 2;
      }
      if(this.width< 600){
        slides = 1.4;
      }

      if(this.width< 400){
        slides = 1.1;
      }

      if (this.cars){
        if (this.cars.length <= slides){
          this.noArrows = true;
        }else{
          this.noArrows = false;
        }
      }


      if (slides!=this.slidesPerView){
          this.slidesPerView = slides;
      }
  
    },
    onSwiper (swiper) {
      // console.log(swiper)
    },
    onSlideChange () {
      // console.log('slide change')
    },
    onNewsThumbLoad (){
      // this.thumbnailsLoaded++;
      // if (this.logos){
      //   if (this.thumbnailsLoaded >= this.logos.length){
          var th = this;
          if (th.$refs.carSlider){
            th.$refs.carSlider.$el.swiper.update();
          }
            // th.$refs.partnersSlider.$el.swiper.update();
          
      //   }
      // }
    }
  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


.car-slider{
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 42px;
  
  .swiper-container{
    overflow: visible;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    padding-left: 48px;
    padding-right: 48px;


    box-sizing: content-box;

    .swiper-wrapper{
      align-self: stretch;
      align-items: stretch;
      display: flex;
      height: auto;
    }
    .swiper-slide{
      height: auto;
    }
  }
  .car-item{
    width: 100%;
    height: 100%;
    margin: 0px;
  }

  .mask{
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 84px;
    
    display: block;
    z-index: 10;

  }
  .mask-right{
    
    // background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 20%, #FFFFFF 40%, #FFFFFF 100%);
    background: linear-gradient(-90deg,
      rgb(255,255,255) 0%,
      rgba(255,255,255, 0.738) 19%,
      rgba(255,255,255, 0.541) 34%,
      rgba(255,255,255, 0.382) 47%,
      rgba(255,255,255, 0.278) 56.5%,
      rgba(255,255,255, 0.194) 65%,
      rgba(255,255,255, 0.126) 73%,
      rgba(255,255,255, 0.075) 80.2%,
      rgba(255,255,255, 0.042) 86.1%,
      rgba(255,255,255, 0.021) 91%,
      rgba(255,255,255, 0.008) 95.2%,
      rgba(255,255,255, 0.002) 98.2%,
      rgba(255,255,255, 0) 100%
    );
    right: 0px;
    &:before{
      right: 0px;
      width: 0px;

      content:"";
      position: absolute;
      height: 100%;
      top: 0px;
      background: #FFF;
    }
    
  }
  .mask-left{
    
    // background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 20%, #FFFFFF 40%, #FFFFFF 100%);
    background: linear-gradient(90deg,
      rgb(255,255,255) 0%,
      rgba(255,255,255, 0.738) 19%,
      rgba(255,255,255, 0.541) 34%,
      rgba(255,255,255, 0.382) 47%,
      rgba(255,255,255, 0.278) 56.5%,
      rgba(255,255,255, 0.194) 65%,
      rgba(255,255,255, 0.126) 73%,
      rgba(255,255,255, 0.075) 80.2%,
      rgba(255,255,255, 0.042) 86.1%,
      rgba(255,255,255, 0.021) 91%,
      rgba(255,255,255, 0.008) 95.2%,
      rgba(255,255,255, 0.002) 98.2%,
      rgba(255,255,255, 0) 100%
    );
    left: 0px;

    &:before{
      left: 0px;
      width: 0px;

      content:"";
      position: absolute;
      height: 100%;
      top: 0px;
      background: #FFF;
    }

    
  }
  .prev,
  .next{
    position: absolute;
    color: #69BDB6;
    top: 50%;
    margin-top: -22px;
    z-index: 20;
    cursor: pointer;
      circle,path{
        transition: all 300ms;
        -webkit-transition: all 300ms;
        stroke: #69BDB6;
      }
      &.swiper-button-disabled{
        
        pointer-events: none;
        circle{
          opacity: 0.12;
        }
        path{
          opacity: 0.24;
        }
      }
      &:hover{

        circle{
          opacity: 1;
          stroke: #1F4743;
        }
        path{
          opacity: 1;
          stroke: #1F4743;
        }
      }
  }
  .prev{
    left: 20px;
  }
  .next{
    right: 20px;
  }
  .swiper-container{

    .cars-slider-swiper-scrollbar{
      display: none;
      box-sizing: border-box;
      transition: all 300ms;
      -webkit-transition: all 300ms;
      margin: 0px;
      height: 2px;
      background: #C7E5E4;
      border-radius: 0px;
      opacity: 1;
      position: relative;
      left: auto;
      right: auto;
      width: 100%;
      margin: 0px;
      
      ::v-deep .swiper-scrollbar-drag{
        background: #4C6C69;
        margin: 0px;
      }
    }

    .swiper-pagination{
      display: none;
      width: 100%;
      box-sizing: border-box;
      margin: 0px;
      top: 0;
      bottom: 0;
    }
    .swiper-pagination ::v-deep .swiper-pagination-bullet{
      transition: all 300ms;
      -webkit-transition: all 300ms;
      flex-grow: 1;
      margin: 0px;
      height: 2px;
      background: #C7E5E4;
      border-radius: 0px;
      opacity: 1;
    }
    .swiper-pagination ::v-deep .swiper-pagination-bullet-active{
      background: #4C6C69;
    }
  }

}
.car-slider-section{
  
}
// @media (max-width: 1000px) {
//   .car-slider{
//     margin-left: -10px;
//     margin-right: -10px;
//     width: calc(100% + 20px);
//     .car-item{
//       width: calc(33.33% - 20px);
//       margin-left: 10px;
//       margin-right: 10px;
//       margin-bottom: 20px;
//     }
//   }
// }
// @media (max-width: #{$screen-tablet}) {
//   .car-slider{
//     margin-left: -10px;
//     margin-right: -10px;
//     width: calc(100% + 20px);
//     .car-item{
//       width: calc(50% - 20px);
//       margin-left: 10px;
//       margin-right: 10px;
//       margin-bottom: 20px;
//     }
//   }
// }


@media (max-width: #{$screen-mobile}) {
  
  .car-slider{
    
    .swiper-container{
      padding-left: 20px;
      padding-right: 20px;
      .cars-slider-swiper-scrollbar{
        display: block;
        margin-top: 32px;
      }
    }
    .mask{
      display: none;
    }
    .prev, .next{
      display: none;
    }
  }

  // .car-slider{
  //   margin-left: 0px;
  //   margin-right: 0px;
  //   width: 100%;
  //   .car-item{
  //     width: 100%;
  //     margin: 0px;
  //     margin-bottom: 20px;
  //   }
  // }
}


</style>