<template>
    <div>
        <div class="bgcolor-secondary__blue-light form-container">
            <div class="p-4">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 no-gutters">
                        <div class="grid-1-1">
                            <p class="primary-font__bold__large text-center">{{userData.epiData.formData.heading}}</p>
                        </div>
                        <div class="grid-1-1">
                            <p class="secondary-font__regular">{{userData.epiData.formData.infoText}}</p>
                        </div>
                        <form class="form__fields__container EPiServerForms LeasingForm">
                            <div class="Form__MainBody">
                                <div class="grid grid__inner Form__Element FormStep Form__Element--NonData">
                                    <div id="scroll-from" class="pt-2"></div>

                                    <aside class="grid-1-1" v-if="userData.epiData.formData.subHeading">
                                        <p class="secondary-font__large__bolder text-left pt-2">{{userData.epiData.formData.subHeading}}</p>
                                    </aside>

                                    <div id="scroll-validate-error-customer"></div>

                                    <div class="grid-1-2" v-if="isCorporate">
                                        <div class="Form__Element FormTextbox">
                                            <div class="caption__wrapper">
                                                <label for="cvr">{{userData.epiData.formData.cvrLabel}}</label>
                                            </div>
                                            <input id="cvr" ref="cvr" class="form-control pl-2" type="text" v-model="person.Cvr" placeholder="" @focus="resetCvrError()" />
                                            <p class="secondary-font__regular color-primary__red">{{cvrErrLabel}}</p>
                                        </div>
                                    </div>
                                    <div class="grid-1-2">
                                        <div class="Form__Element FormTextbox">
                                            <div class="caption__wrapper">
                                                <label v-if="!isCorporate" class="label-helper" for="firstName">{{userData.epiData.formData.firstnameLabel}}</label>
                                                <label v-if="isCorporate" class="label-helper" for="firstName">{{userData.epiData.formData.companyLabel}}</label>
                                            </div>
                                            <input id="firstName" ref="firstName" class="form-control pl-2" type="text" v-model="person.FirstName" placeholder="" @focus="resetFirstNameError()" />
                                            <p class="secondary-font__regular color-primary__red">{{firstNameErrLabel}}</p>
                                        </div>
                                    </div>
                                    <div class="grid-1-2">
                                        <div class="Form__Element FormTextbox">
                                            <div class="caption__wrapper">
                                                <label v-if="!isCorporate" for="lastName">{{userData.epiData.formData.lastnameLabel}}</label>
                                                <label v-if="isCorporate" for="lastName">{{userData.epiData.formData.contactPersonLabel}}</label>
                                            </div>
                                            <input id="lastName" ref="lastName" class="form-control pl-2" type="text" v-model="person.LastName" placeholder="" @focus="resetLastNameError()" />
                                            <p class="secondary-font__regular color-primary__red">{{lastNameErrLabel}}</p>
                                        </div>
                                    </div>

                                    <div class="grid-1-2" v-if="isCorporate">
                                        <div class="Form__Element FormTextbox">
                                            <div class="caption__wrapper">
                                                <label for="address">{{userData.epiData.formData.addressLabel}}</label>
                                            </div>
                                            <input id="address" ref="address" class="form-control pl-2" type="text" v-model="person.Address" placeholder="" @focus="resetAddressError()" />
                                            <p class="secondary-font__regular color-primary__red">{{addressErrLabel}}</p>
                                        </div>
                                    </div>
                                    <div class="grid-1-2">
                                        <div class="Form__Element FormTextbox">
                                            <div class="caption__wrapper">
                                                <label for="zip">{{userData.epiData.formData.zipcodeLabel}}</label>
                                            </div>
                                            <input id="zip" ref="zip" class="form-control pl-2" type="number" v-model="person.ZipCode" maxlength="4" placeholder="" @focus="resetPostNumError()"/>
                                            <p class="secondary-font__regular color-primary__red">{{postNumErrLabel}}</p>
                                        </div>
                                    </div>
                                    <div class="grid-1-2">
                                        <div class="Form__Element FormTextbox">
                                            <div class="caption__wrapper">
                                                <label for="city">By</label>
                                            </div>
                                            <input id="city" ref="city" class="form-control pl-2" type="text" v-model="person.City" placeholder="" @focus="resetCityError()"/>
                                            <p class="secondary-font__regular color-primary__red">{{cityErrLabel}}</p>
                                        </div>
                                    </div>
                                    <div class="grid-1-2">
                                        <div class="Form__Element FormTextbox">
                                            <div class="caption__wrapper">
                                                <label for="mobile">{{userData.epiData.formData.phoneLabel}}</label>
                                            </div>
                                            <input id="mobile" ref="mobile" class="form-control pl-2" type="text" v-model="person.CellPhone" placeholder="" @focus="resetMobileError()" />
                                            <p class="secondary-font__regular color-primary__red">{{mobileErrLabel}}</p>
                                        </div>
                                    </div>
                                    <div class="grid-1-2">
                                        <div class="Form__Element FormTextbox">
                                            <div class="caption__wrapper">
                                                <label for="email">{{userData.epiData.formData.emailLabel}}</label>
                                            </div>
                                            <input id="email" ref="email" class="form-control pl-2" type="email" v-model="person.Email" placeholder="" @focus="resetEmailError()" />
                                            <p class="secondary-font__regular color-primary__red">{{emailErrLabel}}</p>
                                        </div>
                                    </div>

                                    <div id="scroll-to" class="pt-3"></div>
                                    <div id="scroll-validate-error-spouse"></div>

                                    <div class="animate-height" id="animate-controller">
                                        <div class="animate-content grid grid__inner mt-2">
                                            <div class="grid-1-1 pt-3" v-if="!isCorporate">
                                                <p class="secondary-font__large__bolder text-left">{{userData.epiData.formData.extraSubHeading}}</p>
                                                <div v-show="spouseAdded">
                                                    <div class="url__menu text-nowrap float-right" style="margin-bottom: 1rem" @click="toggleSpouse" role="button" v-scroll-to="'#scroll-from'">
                                                        <img src="/ui/assets/svg/bullet-negative_blue.svg" class="mr-1" style="width:15px" />
                                                        <span>Fjern medansøger</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="grid-1-2">
                                                <div class="Form__Element FormTextbox">
                                                    <div class="caption__wrapper">
                                                        <label for="spouse-firstName">{{userData.epiData.formData.firstnameLabel}}</label>
                                                    </div>
                                                    <input id="spouse-firstName" ref="spouse-firstName" class="form-control pl-2" type="text" v-model="spouse.FirstName" placeholder="" @focus="resetSpouseFirstNameError()" />
                                                    <p class="secondary-font__regular color-primary__red">{{spouseFirstNameErrLabel}}</p>
                                                </div>
                                            </div>
                                            <div class="grid-1-2">
                                                <div class="Form__Element FormTextbox">
                                                    <div class="caption__wrapper">
                                                        <label for="spouse-lastName">{{userData.epiData.formData.lastnameLabel}}</label>
                                                    </div>
                                                    <input id="spouse-lastName" ref="spouse-lastName" class="form-control pl-2" type="text" v-model="spouse.LastName" placeholder="" @focus="resetSpouseLastNameError()" />
                                                    <p class="secondary-font__regular color-primary__red">{{spouseLastNameErrLabel}}</p>
                                                </div>
                                            </div>
                                            <div class="grid-1-2">
                                                <div class="Form__Element FormTextbox">
                                                    <div class="caption__wrapper">
                                                        <label for="spouse-zip">{{userData.epiData.formData.zipcodeLabel}}</label>
                                                    </div>
                                                    <input id="spouse-zip" ref="spouse-zip" class="form-control pl-2" type="number" maxlength="4" v-model="spouse.ZipCode" placeholder="" @focus="resetSpouseLastNameError()" />
                                                    <p class="secondary-font__regular color-primary__red">{{spousePostNumErrLabel}}</p>
                                                </div>
                                            </div>
                                            <div class="grid-1-2">
                                                <div class="Form__Element FormTextbox">
                                                    <div class="caption__wrapper">
                                                        <label for="spouse-City">By</label> <!--{{userData.epiData.formData.cityLabel}}-->
                                                    </div>
                                                    <input id="spouse-city" ref="spouse-city" class="form-control pl-2" type="text" v-model="spouse.City" placeholder="" @focus="resetSpouseCityError()" />
                                                    <p class="secondary-font__regular color-primary__red">{{spouseCityErrLabel}}</p>
                                                </div>
                                            </div>
                                            <div class="grid-1-2">
                                                <div class="Form__Element FormTextbox">
                                                    <div class="caption__wrapper">
                                                        <label for="spouse-mobile">{{userData.epiData.formData.phoneLabel}}</label>
                                                    </div>
                                                    <input id="spouse-mobile" ref="spouse-mobile" class="form-control pl-2" type="text" v-model="spouse.CellPhone" placeholder="" @focus="resetSpouseMobileError()" />
                                                    <p class="secondary-font__regular color-primary__red">{{spouseMobileErrLabel}}</p>
                                                </div>
                                            </div>
                                            <div class="grid-1-2">
                                                <div class="Form__Element FormTextbox">
                                                    <div class="caption__wrapper">
                                                        <label for="spouse-email">{{userData.epiData.formData.emailLabel}}</label>
                                                    </div>
                                                    <input id="spouse-email" ref="spouse-email" class="form-control pl-2" type="email" v-model="spouse.Email" placeholder="" @focus="resetSpouseEmailError()" />
                                                    <p class="secondary-font__regular color-primary__red">{{spouseEmailErrLabel}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid-1-1">
                                        <div class="Form__Element FormTextbox FormTextbox--Textarea Comment">
                                            <div class="caption__wrapper">
                                                <label for="comments">{{userData.epiData.formData.commentLabel}}</label>
                                            </div>
                                            <textarea id="comments" class="form-control FormTextbox__Input pl-2" type="text" v-model="person.Comments" placeholder="" maxlength="1200">
                                            </textarea>
                                        </div>
                                    </div>
                                    <div class="grid-1-1 text-left" v-if="!isCorporate" v-show="!spouseAdded">
                                        <div class="url__menu text-nowrap" style="margin-bottom: 1rem" @click="toggleSpouse" role="button" v-scroll-to="'#scroll-to'">
                                            <img src="/ui/assets/svg/bullet_plus_blue.svg" class="mr-1" style="width:15px" />
                                            <span>Tilføj evt. medansøger</span>
                                        </div>
                                    </div>
                                    <div class="grid-1-1">
                                        <div class="form-group form__item text-left" v-if="!isCorporate">
                                            <div class="row no-gutters px-2">
                                                <div class="col-1 mr-3">
                                                    <label class="checkbox__primary-color">
                                                        <input type="checkbox" v-model="person.Consent" class="form-control">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="col-9">
                                                    <span class="secondary-font__regular">
                                                        {{userData.epiData.formData.agreementText}}
                                                    </span>
                                                    <span @click="animateController()" class="secondary-font__regular color-primary__blue text-nowrap pt-1" role="button">
                                                        <span>{{animateControllerSelected ? 'Skjul': userData.epiData.formData.agreementLinkText}}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="grid-1-1">
                                                <div class="col-1 mr-3">
                                                </div>
                                                <div class="col-9">
                                                    <div id='animate-controller-second' class="animate-height">
                                                        <div class='animate-content-second'>
                                                            <span class="secondary-font__regular" v-html="userData.epiData.formData.agreementInfo">
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <p class="secondary-font__small color-secondary__grey-darker mt-3 text-center" style="line-height: 2em">
            {{userData.epiData.formData.warningText}}
        </p>

        <div class="form-container">
            <div class="row mt-4 mb-4 align_center text-center">
                <div class="col-12 col-md-2 mb-2 text-nowrap">
                    <a href="#" @click="$router.go(-1)" class="url__link" v-show="!userData.isDeepLink">
                        <img :src="require('@/components/leasing/Showroom/public/ui/assets/svg/arrow_darkblue_left.svg')" style="width:16px" />
                        <span>Tilbage til oversigt</span>
                    </a>
                </div>

                <div class="col-12 col-md-10 text-md-right">
                    <button class="button__cta mb-2 mr-2" v-bind:class="userData.epiData.formData.submitButtonStyle" type="button" :disabled="submitting" @click="createOffer()">{{userData.epiData.formData.submitButtonText}}</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script lang="ts">
    import Vue from "vue";
    import { Component, Emit, Inject, Model, Prop, Provide, Watch } from "vue-property-decorator";
    import AbValidator from '../../common/ab_validator';
    import UserData from '../providers/UserData';
    import Utils from '../providers/Utils';
    import FormHelper from './FormHelper';
    import { Person } from '../model/person';
    import { isNullOrUndefined } from "is-what";
    import RecaptchaComponent from "../../shared/inputs/recaptcha/RecaptchaInvisibleComponent.vue";
    import AutocompleteComponent from '../../shared/inputs/autocomplete/Autocomplete.vue';

    @Component({
        name: 'leasing-form-component',
        components: {
            'vue-recaptcha': RecaptchaComponent,
            'autocomplete-component': AutocompleteComponent
        }
    })
    export default class Form extends Vue {
        @Prop() isCorporate: boolean;
        @Prop() colorSelected: any;

        //reference til Recaptcha component
        $refs: {
            almbrandRecaptcha: any,
        }
        captcha: string;

        formHelper = new FormHelper();
        abValidator = new AbValidator();
        animateControllerSelected: boolean = false;
        userData: UserData = this.$store.getters.getUserData;
        utils = new Utils();
        spouseAdded: boolean = false;
        
        submitting: boolean = false;

        person: Person = {
            Cvr: "",
            FirstName: "",
            LastName: "",
            Address: "",
            ZipCode: "",
            City: "",
            CellPhone: "",
            Email: "",
            Comments: "",
            Consent: false,
        }

        spouse: Person = {
            Cvr: "",
            FirstName: "",
            LastName: "",
            Address: "",
            ZipCode: "",
            City: "",
            CellPhone: "",
            Email: "",
            Comments: "",
            Consent: false,
        }


        cvrErrLabel: string = '';
        firstNameErrLabel: string = '';
        lastNameErrLabel: string = '';
        addressErrLabel: string = '';
        postNumErrLabel: string = '';
        cityErrLabel: string = '';
        mobileErrLabel: string = '';
        emailErrLabel: string = '';

        spouseFirstNameErrLabel: string = '';
        spouseLastNameErrLabel: string = '';
        spouseAddressErrLabel: string = '';
        spousePostNumErrLabel: string = '';
        spouseCityErrLabel: string = '';
        spouseMobileErrLabel: string = '';
        spouseEmailErrLabel: string = '';


        resetCvrError() {
            this.cvrErrLabel = "";
        }

        resetFirstNameError() {
            this.firstNameErrLabel = "";
        }

        resetLastNameError() {
            this.lastNameErrLabel = "";
        }

        resetAddressError() {
            this.addressErrLabel = "";
        }

        resetPostNumError() {
            this.postNumErrLabel = "";
        }

        resetCityError() {
            this.cityErrLabel = "";
        }

        resetMobileError() {
            this.mobileErrLabel = "";
        }

        resetEmailError() {
            this.emailErrLabel = "";
        }

        resetSpouseFirstNameError() {
            this.spouseFirstNameErrLabel = "";
        }

        resetSpouseLastNameError() {
            this.spouseLastNameErrLabel = "";
        }

        resetSpouseAddressError() {
            this.spouseAddressErrLabel = "";
        }

        resetSpousePostNumError() {
            this.spousePostNumErrLabel = "";
        }

        resetSpouseCityError() {
            this.spouseCityErrLabel = "";
        }

        resetSpouseMobileError() {
            this.spouseMobileErrLabel = "";
        }

        resetSpouseEmailError() {
            this.spouseEmailErrLabel = "";
        }

        printPage() {
            this.formHelper.printPage();
        }
        resetLabels() {
            this.cvrErrLabel = '';
            this.firstNameErrLabel = '';
            this.lastNameErrLabel = '';
            this.addressErrLabel = '';
            this.postNumErrLabel = '';
            this.cityErrLabel = '';
            this.mobileErrLabel = '';
            this.emailErrLabel = '';
            this.spouseFirstNameErrLabel = '';
            this.spouseLastNameErrLabel = '';
            this.spouseAddressErrLabel = '';
            this.spousePostNumErrLabel = '';
            this.spouseCityErrLabel = '';
            this.spouseMobileErrLabel = '';
            this.spouseEmailErrLabel = '';

            this.userData.colorErrLabel = "";
            this.userData.insurancesErrLabel = "";
        }

        validateSubmit() {
            this.resetLabels();
            let validated: boolean = true;
            let colorIsMissing = false;
            let insurancesMissing = false;
            let customerValidationError = false;
            let spouseValidationError = false;

            if (this.isCorporate) {
                if (this.person.Address === undefined || this.person.Address === "") {
                    this.addressErrLabel = "Du mangler at angive din addresse";
                    validated = false;
                    customerValidationError = true;
                }
                if (this.person.Cvr.length < 8) {
                    this.cvrErrLabel = "Dit cvr nummer skal minimum være 8 tal";
                    validated = false;
                    customerValidationError = true;
                }
            }

            if (!this.isCorporate) {
                let insurancesData = this.$store.getters.getInsurancesData;
                if (insurancesData.almbrandInsurancesCheckBox) {
                    if (insurancesData.insurancePrice === 0) {
                        this.userData.insurancesErrLabel = "Du har valgt Almbrand forsikring, men mangler at beregne din forsikrings pris";
                        validated = false;
                        insurancesMissing = true;
                    }
                } else if (isNullOrUndefined(this.$store.getters.getEksInsurancesCompany) || this.$store.getters.getEksInsurancesCompany === "") {
                    this.userData.insurancesErrLabel = "Du mangler at angive en forsikring på leasing bil";
                    validated = false;
                    insurancesMissing = true;
                }
            }


            if (isNullOrUndefined(this.colorSelected)) {
                this.userData.insurancesErrLabel = "Du mangler at angive en farve på leasing bil";
                validated = false;
                colorIsMissing = true;
            }


            if (this.person.FirstName === undefined || this.person.FirstName === "") {
                this.firstNameErrLabel = "Du mangler at angive dit fornavn";
                validated = false;
                customerValidationError = true;
            }

            if (this.person.LastName === undefined || this.person.LastName === "") {
                this.lastNameErrLabel = "Du mangler at angive dit efternavn";
                validated = false;
                customerValidationError = true;
            }

            if (this.person.CellPhone.length < 8) {
                this.mobileErrLabel = "Dit telefonnummer skal minimum være 8 tal";
                validated = false;
                customerValidationError = true;
            }

            if (this.person.ZipCode === undefined || this.person.ZipCode === "") {
                this.postNumErrLabel = "Du mangler at angive dit postnummer";
                validated = false;
                customerValidationError = true;
            }
            else if (this.person.ZipCode.length !== 4) {
                this.postNumErrLabel = "Dit postnummer skal være 4 tal";
                validated = false;
                customerValidationError = true;
            }

            if (this.person.City === undefined || this.person.City === "") {
                this.cityErrLabel = "Du mangler at angive din by";
                validated = false;
                customerValidationError = true;
            }

            if (!this.validEmail(this.person.Email)) {
                this.emailErrLabel = "Der er noget galt med mail";
                validated = false;
                customerValidationError = true;
            }

            //spouse
            if (this.spouseAdded) {

                if (this.spouse.FirstName === undefined || this.spouse.FirstName === "") {
                    this.spouseFirstNameErrLabel = "Medansøger mangler at angive sit fornavn";
                    validated = false;
                    spouseValidationError = true;
                }

                if (this.spouse.LastName === undefined || this.spouse.LastName === "") {
                    this.spouseLastNameErrLabel = "Medansøger mangler at angive sit efternavn";
                    validated = false;
                    spouseValidationError = true;
                }

                if (this.spouse.CellPhone.length < 8) {
                    this.spouseMobileErrLabel = "Medansøger telefonnummer skal minimum være 8 tal";
                    validated = false;
                    spouseValidationError = true;
                }

                if (this.spouse.ZipCode === undefined || this.spouse.ZipCode === "") {
                    this.spousePostNumErrLabel = "Medansøger mangler at angive sit postnummer";
                    validated = false;
                    spouseValidationError = true;
                }
                else if (this.spouse.ZipCode.length !== 4) {
                    this.spousePostNumErrLabel = "Medansøger postnummer skal være 4 tal";
                    validated = false;
                    spouseValidationError = true;
                }

                if (this.spouse.City === undefined || this.spouse.City === "") {
                this.spouseCityErrLabel = "Medansøger mangler at angive sin by";
                validated = false;
                customerValidationError = true;
            }

                if (!this.validEmail(this.spouse.Email)) {
                    this.spouseEmailErrLabel = "Der er noget galt med medansøger mail";
                    validated = false;
                    spouseValidationError = true;
                }

            }

            let VueScrollTo = require('vue-scrollto');
            if (colorIsMissing) {
                VueScrollTo.scrollTo(document.querySelector('#scroll-color-options'));
            } else if (insurancesMissing) {
                VueScrollTo.scrollTo(document.querySelector('#scroll-insurances-options'));
            } else if (customerValidationError) {
                VueScrollTo.scrollTo(document.querySelector('#scroll-validate-error-customer'));
            } else if (spouseValidationError) {
                VueScrollTo.scrollTo(document.querySelector('#scroll-validate-error-spouse'));
            }

            return validated;
        }

        validEmail(email) {
            return this.abValidator.validEmail(email);
        }

        toggleSpouse(): void {
            this.spouseAdded ? this.spouseAdded = false : this.spouseAdded = true;
            this.utils.doAnimate(24, "#animate-controller", ".animate-content");
        }

        animateController() {
            this.animateControllerSelected ? this.animateControllerSelected = false : this.animateControllerSelected = true;
            this.utils.doAnimate(0, "#animate-controller-second", ".animate-content-second");
        }


        /**
         * Opret tilbud.
         * 1. Felter valideres
         * 2. Google Recaptcha eksekveres
         * 3. ansøger og medansøger mappes over i kontrakten
         * 4. Opret tilbud(createOffer) kaldes hos bytelab via rest
         *
         * */
        createOffer() {
            if (this.validateSubmit()) {
                this.submitting = true;
                this.recaptchaChanged(null);
                //(this.$refs.almbrandRecaptcha as any).onSubmit();
            }

        }

        //callback fra recaptcha component
        recaptchaChanged(data: any): void {
            //this.captcha = data;


            //map ansøger og medansøger over i kontrakten
            this.$store.dispatch('fromBuyerToContract', this.person);
            if (this.spouseAdded) {
                this.$store.dispatch('fromCoBuyerToContract', this.spouse);
            }
            //opret tilbud hos bytelab

            //this.formHelper.createOfferViaRest(this.salesChannelGuid, this.$refs.almbrandRecaptcha, this.captcha);
            this.formHelper.createOfferViaRest();
        }

        //callback fra postnr component
        postnumChanged(internalValue: string): void {
            this.person.ZipCode = internalValue;
            this.resetPostNumError();
        }
        //callback fra postnr component
        postnumSelected(internalValue: any): void {
            this.person.ZipCode = internalValue.postnummer.nr;
            this.resetPostNumError();
        }

        //callback fra adresse component
        addressChanged(internalValue: string): void {
            this.person.Address = internalValue;
            this.resetAddressError();
        }
        //callback fra adresse component
        addressSelected(addr: any): void {
            this.person.Address = addr.forslagstekst;
            this.resetAddressError();
        }

        //callback fra postnr component
        spousePostnumChanged(internalValue: string): void {
            this.spouse.ZipCode = internalValue;
            this.resetSpousePostNumError();
        }
        //callback fra postnr component
        spousePostnumSelected(internalValue: any): void {
            this.spouse.ZipCode = internalValue.postnummer.nr;
            this.resetSpousePostNumError();
        }
    }
</script>


<style lang="scss" scoped>
    @import "../../abdk/styles/legacy/style.scss";
    @import "../../leasingstyle.scss";
</style>
