<template>
  <section class="iframe-block" :class="green_background? 'green-background': ''">
    <div class="wrap">
      <div class="block">
        <div class="side-content">
          
          <h2 class="title h2-1">{{title}}</h2>
          <div class="description" v-html="content"></div>
          <div v-if="button && button.url">
            <a class="button" :href="button.url" :target="button.target">{{button.title}}</a>
          </div>
          
          <div class="iframe-container" :style="iframeStyle" v-html="iframe">

          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>

export default {
  props: ['block'],
  components: {

  },
  data() {
    return {
      title: null,
      content: null,
      button: {"url": null, "title": null, "target": null},
      iframe: null,
      iframeStyle: null,
      iframe_height: null,
      green_background: false,
      iframeID: null
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  beforeMount(){
    
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();

    // console.log(this.$refs.iframeel);

  },
  methods: {
    prepareContent: function(){
      // console.log(this.block);
      this.title = this.block.title.value;
      this.content = this.block.content.value;

      if (this.block.iframe_height && this.block.iframe_height.value){
        this.iframe_height = this.block.iframe_height.value;
      }
      if (this.iframe_height){
        this.iframeStyle = "height: "+this.iframe_height+"px";
      }

      var iframe = this.block.iframe.value;


      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = iframe;

      const scriptTags = tempDiv.querySelectorAll('script');
      scriptTags.forEach(script => {
        const existingScript = document.querySelector(`script[src="${script.src}"]`);
        if (existingScript) {
          if (window.RepdateGlobalSearchReload){
            window.RepdateGlobalSearchReload();
          }
        }else{
          const newScript = document.createElement('script');
          if (script.src) {
            newScript.src = script.src;
          } else {
            newScript.textContent = script.textContent;
          }
          document.body.appendChild(newScript);
        }
      });

      this.iframe = iframe;

      this.button = this.block.button.value;
      if(this.block.green_background){
        this.green_background = this.block.green_background.value === "1" ? true : false;
      }
    },
    getDimensions: function(){

    },
    onIframeLoad: function(){
      this.iframeLoaded();
    },

    iframeLoaded: function() {
      var iFrameID = document.getElementById(this.iframeID);
      if(iFrameID) {
            // here you can make the height, I delete it first, then I make it again
            iFrameID.height = "";
            iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px";
      }   
    }
  },
  created() {
    var th = this;
    this.iframeID = "iframe-"+Date.now();
    
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";



.iframe-block{
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
  &.green-background{
    padding-top: 80px;
    padding-bottom: 80px;
    background: rgba(105, 189, 182, 0.09);
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
  }
  .title{
    margin-bottom: 16px;
    max-width: 715px;
    margin-left: auto;
    margin-right: auto;
  }
  .description{
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 31.2px */
    max-width: 715px;
    margin-left: auto;
    margin-right: auto;
    color: #4C6C69;
  }
  .button{
    margin-top: 16px;
  }
  
  .iframe-container{
    margin-top: 56px;
    width: 100%;

    border-radius: 20px;
    border: 1px solid #E8F5F4;
    background: #FFF;

    /* Opendo/Shadow small */
    box-shadow: 0px 10px 22px -9px rgba(31, 72, 68, 0.12);
    overflow: hidden;
    line-height: 0px;

    height: 800px;

    ::v-deep iframe{
      padding: 24px;
      width: 100%;
    }
  }
  
}

@media (max-width: #{$screen-tablet}) {
  .iframe-block{
    &.green-background{
      padding-top: 60px;
      padding-bottom: 60px;
    }
    .title{
      font-size: 2rem;
    }
    .description{
      font-size: 1.25rem;
    }
    
    
  }
}
@media (max-width: #{$screen-mobile}) {
  .iframe-block{
    &.green-background{
      padding-top: 48px;
      padding-bottom: 48px;
    }
    .title{
      font-size: 2rem;
      margin-bottom: 16px;
      width: 100%;
    }
    .description{
      font-size: 1rem;
    }
    .iframe-container{
      margin-top: 40px;
    }
  }
}
</style>