<template>
  <div>
    <div class="main page-logins">
    

      <section class="section login-section">
        <div class="wrap">
          <div class="sides flex flex-s3">
            <div class="left-side">
              <svg width="286" height="412" viewBox="0 0 286 412" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="142.5" cy="234.5" r="142.5" fill="#E1F2F0"/>
                <path d="M150.974 213.5L150.973 34.8968L177.168 8.81093M202.078 215L202.078 185.807L226.755 160.547L202.946 135.514L227.132 110.465L203.622 85.5391L227.913 60.5546L177.168 8.81093M177.168 8.81093L176.166 70.7292M176.099 97.3707L176.099 210" stroke="#1F4743" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M259.35 279.055C270.918 326.155 242.115 373.715 195.015 385.284C147.915 396.853 100.355 368.049 88.7859 320.949C77.2171 273.849 106.021 226.289 153.121 214.72C200.22 203.151 247.781 231.955 259.35 279.055Z" stroke="#1F4743" stroke-width="8"/>
                <path d="M204.236 325.878C204.236 342.029 191.143 355.122 174.992 355.122C158.841 355.122 145.748 342.029 145.748 325.878C145.748 309.727 158.841 296.634 174.992 296.634C191.143 296.634 204.236 309.727 204.236 325.878Z" stroke="#1F4743" stroke-width="8"/>
              </svg>
            </div>
            <div class="content">
              
              <h1>{{title}}</h1>
              

              

              <div class="card-style login-box" v-if="currentView=='banklogin-login'" :class="loading ? ' loading' : ''">
                <div class="title-wrap">
                  <!--<router-link class="back-arrow" :to="'/log-paa'">-->
                  <router-link class="back-arrow" :to="'/log-paa'">
                    <span class="back-arrow-icon">
                      <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.52344 13L1.52344 7L7.52344 1" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 7L30 7" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </span>
                  </router-link>
                  <!--</router-link>-->
                  <h2 class="h3-1 title">Banklogin</h2>
                </div>
                <div class="form-wrap">
                  <form method="POST" action="" v-on:submit="onSubmitLogin" ref="loginForm">

                    <!--'theme', 'size', 'name', 'value', 'placeholder', 'type', 'customClass'-->
                    <div class="login-field">
                      <InputField :placeholder="'E-mail'" :name="'username'" :value="userName" type="text" theme="nr1" @focus="onFocusUsername" />
                      <span class="error-label" v-if="userNameErrLabel">{{userNameErrLabel}}</span>
                    </div>
                    <div class="login-field">
                      <InputField :placeholder="'Kodeord'" :name="'password'" type="password" :value="password" theme="nr1" @focus="onFocusPassword" />
                      <span class="error-label" v-if="passwordErrLabel">{{passwordErrLabel}}</span>
                    </div>

                    <input type="submit" name="submit" value="Log ind" class="button" />
                  </form>
                </div>

                <div class="foot-info">
                  <p><a href="#" class="forgot-link" v-on:click="forgotPasswordClick">Glemt adgangskode</a></p>
                  <hr class="link-line" />
                  <p>Ny bruger? <a href="#" class="register-link" v-on:click="toRegisterClick">Opret ny bruger</a></p>
                </div> 

              

                
                
              </div>


              <div class="card-style login-box" v-if="currentView=='banklogin-set-password'" :class="loading ? ' loading' : ''">
                <div class="title-wrap">
                  
                  <h2 class="h3-1 title">Opret bruger</h2>
                </div>
                <div class="form-wrap">
                  <form method="POST" action="" v-on:submit="onSubmitSetPassword" ref="setPasswordForm">

                    <!--'theme', 'size', 'name', 'value', 'placeholder', 'type', 'customClass'-->
                    <div class="login-field">
                      <InputField :placeholder="'E-mail'" :disabled="true" :name="'username'" :value="sPasswordEmail" type="text" theme="nr1" @focus="onFocusUsername" />
                      <span class="error-label" v-if="userNameErrLabel">{{userNameErrLabel}}</span>
                    </div>
                    <div class="login-field">
                      <InputField :placeholder="'Adgangskode'" :name="'password'" type="password"  theme="nr1" @focus="onFocusPassword" />
                      <span class="error-label" v-if="passwordErrLabel">{{passwordErrLabel}}</span>
                    </div>
                    <div class="login-field">
                      <InputField :placeholder="'Adgangskode igen'" :name="'confirm-password'" type="password"  theme="nr1" @focus="onFocusConfirmPassword" />
                      <span class="error-label" v-if="confirmPasswordErrLabel">{{confirmPasswordErrLabel}}</span>
                    </div>

                    <input type="hidden" name="email" :value="sPasswordEmail"/>
                    <input type="hidden" name="key" :value="sPasswordKey"/>

                    <input type="submit" name="submit" value="Tilføj" :disabled="disabledSubmit" class="button" />
                  </form>
                </div>



                <div class="loading-wheel" >
                  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 48C3 59.9347 7.74106 71.3807 16.1802 79.8198C24.6193 88.2589 36.0653 93 48 93C59.9347 93 71.3807 88.2589 79.8198 79.8198C88.2589 71.3807 93 59.9347 93 48C93 36.0653 88.2589 24.6193 79.8198 16.1802C71.3807 7.74106 59.9347 3 48 3C36.0653 3 24.6193 7.74106 16.1802 16.1802C7.74106 24.6193 3 36.0653 3 48V48Z" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21 48C21 55.1608 23.8446 62.0284 28.9081 67.0919C33.9716 72.1554 40.8392 75 48 75C55.1608 75 62.0284 72.1554 67.0919 67.0919C72.1554 62.0284 75 55.1608 75 48C75 40.8392 72.1554 33.9716 67.0919 28.9081C62.0284 23.8446 55.1608 21 48 21C40.8392 21 33.9716 23.8446 28.9081 28.9081C23.8446 33.9716 21 40.8392 21 48V48Z" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M39 48C39 50.3869 39.9482 52.6761 41.636 54.364C43.3239 56.0518 45.6131 57 48 57C50.3869 57 52.6761 56.0518 54.364 54.364C56.0518 52.6761 57 50.3869 57 48C57 45.6131 56.0518 43.3239 54.364 41.636C52.6761 39.9482 50.3869 39 48 39C45.6131 39 43.3239 39.9482 41.636 41.636C39.9482 43.3239 39 45.6131 39 48V48Z" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M48 39V21" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M39.4398 45.2203L22.3198 39.6562" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M42.7121 55.2803L32.1281 69.8443" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M53.2921 55.2803L63.8721 69.8443" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M56.5602 45.2203L73.6802 39.6562" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>

              
              <div class="card-style login-box" v-if="currentView=='banklogin-reset-password'" :class="loading ? ' loading' : ''">
                <div class="title-wrap">
                  
                  <h2 class="h3-1 title">Glemt adgangskode</h2>
                  <p class="sub-title">Nulstil adgangskoden til brugeren {{sPasswordEmail}}</p>
                </div>
                <div class="form-wrap">
                  <form method="POST" action="" v-on:submit="onSubmitResetPassword" ref="resetPasswordForm">

                    
                    <div class="login-field">
                      <InputField :placeholder="'Adgangskode'" :name="'password'" type="password"  theme="nr1" @focus="onFocusPassword" />
                      <span class="error-label" v-if="passwordErrLabel">{{passwordErrLabel}}</span>
                    </div>
                     <div class="login-field">
                      <InputField :placeholder="'Adgangskode igen'" :name="'confirm-password'" type="password"  theme="nr1" @focus="onFocusConfirmPassword" />
                      <span class="error-label" v-if="confirmPasswordErrLabel">{{confirmPasswordErrLabel}}</span>
                    </div>

                    <input type="hidden" name="email" :value="sPasswordEmail"/>
                    <input type="hidden" name="key" :value="sPasswordKey"/>

                    <input type="submit" name="submit" value="Bekræft" :disabled="disabledSubmit" class="button" />
                  </form>
                </div>



                <div class="loading-wheel" >
                  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 48C3 59.9347 7.74106 71.3807 16.1802 79.8198C24.6193 88.2589 36.0653 93 48 93C59.9347 93 71.3807 88.2589 79.8198 79.8198C88.2589 71.3807 93 59.9347 93 48C93 36.0653 88.2589 24.6193 79.8198 16.1802C71.3807 7.74106 59.9347 3 48 3C36.0653 3 24.6193 7.74106 16.1802 16.1802C7.74106 24.6193 3 36.0653 3 48V48Z" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21 48C21 55.1608 23.8446 62.0284 28.9081 67.0919C33.9716 72.1554 40.8392 75 48 75C55.1608 75 62.0284 72.1554 67.0919 67.0919C72.1554 62.0284 75 55.1608 75 48C75 40.8392 72.1554 33.9716 67.0919 28.9081C62.0284 23.8446 55.1608 21 48 21C40.8392 21 33.9716 23.8446 28.9081 28.9081C23.8446 33.9716 21 40.8392 21 48V48Z" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M39 48C39 50.3869 39.9482 52.6761 41.636 54.364C43.3239 56.0518 45.6131 57 48 57C50.3869 57 52.6761 56.0518 54.364 54.364C56.0518 52.6761 57 50.3869 57 48C57 45.6131 56.0518 43.3239 54.364 41.636C52.6761 39.9482 50.3869 39 48 39C45.6131 39 43.3239 39.9482 41.636 41.636C39.9482 43.3239 39 45.6131 39 48V48Z" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M48 39V21" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M39.4398 45.2203L22.3198 39.6562" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M42.7121 55.2803L32.1281 69.8443" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M53.2921 55.2803L63.8721 69.8443" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M56.5602 45.2203L73.6802 39.6562" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>

              <div class="card-style login-box" v-if="currentView=='banklogin-forgot'" :class="loading ? ' loading' : ''">
                <div class="title-wrap">
                  <span class="back-arrow" v-on:click="backLinkClick">
                    <span class="back-arrow-icon">
                      <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.52344 13L1.52344 7L7.52344 1" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 7L30 7" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </span>
                  </span>
                  <h2 class="h3-1 title">Glemt adgangskode</h2>
                  <p class="sub-title">Indtast mailadressen til din bruger i feltet nedenfor og klik ‘Nulstil’, så får du tilsendt en mail, hvor du kan angive en ny adgangkode.</p>
                </div>
                <div class="form-wrap">
                  <form method="POST" action="" v-on:submit="onSubmitForgotPassword" ref="forgotPasswordForm">

                    <!--'theme', 'size', 'name', 'value', 'placeholder', 'type', 'customClass'-->
                    <div class="login-field">
                      <InputField :placeholder="'E-mail'" :name="'username'" :value="userName" type="text" theme="nr1" @focus="onFocusUsername" />
                      <span class="error-label" v-if="userNameErrLabel">{{userNameErrLabel}}</span>
                    </div>

                    <input type="submit" name="submit" value="Nulstil" :disabled="disabledSubmit" class="button" />
                  </form>
                </div>



                <div class="loading-wheel" >
                  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 48C3 59.9347 7.74106 71.3807 16.1802 79.8198C24.6193 88.2589 36.0653 93 48 93C59.9347 93 71.3807 88.2589 79.8198 79.8198C88.2589 71.3807 93 59.9347 93 48C93 36.0653 88.2589 24.6193 79.8198 16.1802C71.3807 7.74106 59.9347 3 48 3C36.0653 3 24.6193 7.74106 16.1802 16.1802C7.74106 24.6193 3 36.0653 3 48V48Z" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21 48C21 55.1608 23.8446 62.0284 28.9081 67.0919C33.9716 72.1554 40.8392 75 48 75C55.1608 75 62.0284 72.1554 67.0919 67.0919C72.1554 62.0284 75 55.1608 75 48C75 40.8392 72.1554 33.9716 67.0919 28.9081C62.0284 23.8446 55.1608 21 48 21C40.8392 21 33.9716 23.8446 28.9081 28.9081C23.8446 33.9716 21 40.8392 21 48V48Z" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M39 48C39 50.3869 39.9482 52.6761 41.636 54.364C43.3239 56.0518 45.6131 57 48 57C50.3869 57 52.6761 56.0518 54.364 54.364C56.0518 52.6761 57 50.3869 57 48C57 45.6131 56.0518 43.3239 54.364 41.636C52.6761 39.9482 50.3869 39 48 39C45.6131 39 43.3239 39.9482 41.636 41.636C39.9482 43.3239 39 45.6131 39 48V48Z" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M48 39V21" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M39.4398 45.2203L22.3198 39.6562" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M42.7121 55.2803L32.1281 69.8443" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M53.2921 55.2803L63.8721 69.8443" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M56.5602 45.2203L73.6802 39.6562" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>




              <div class="card-style login-box" v-if="currentView=='banklogin-register'" :class="loading ? ' loading' : ''">
                <div class="title-wrap">
                  <h2 class="h3-1 title">Opret bruger</h2>
                </div>
                <div class="form-wrap">
                  <form method="POST" action="" v-on:submit="onSubmitRegister" ref="registerForm">
                    <div class="login-field">
                      <RegisterInputField :placeholder="'Navn'" :disabled="false" :name="'fullname'" :value="''" type="text" theme="nr1" @focus="onFocusFullname" autocomplete="new-password" />
                      <span class="error-label" v-if="userFullnameErrLabel">{{userFullnameErrLabel}}</span>
                    </div>
                    <div class="login-field">
                      <RegisterInputField :placeholder="'Email'" :disabled="false" :name="'email'" :value="''" type="text" theme="nr1" @focus="onFocusUsername" role="presentation" autocomplete="new-password" />
                      <span class="error-label" v-if="userNameErrLabel">{{userNameErrLabel}}</span>
                    </div>
                    <div class="login-field">
                      <RegisterInputField :placeholder="'Adgangskode'" :name="'password'" type="password"  theme="nr1" @focus="onFocusPassword" autocomplete="new-password" />
                      <span class="error-label" v-if="passwordErrLabel">{{passwordErrLabel}}</span>
                    </div>
                    <div class="login-field">
                      <RegisterInputField :placeholder="'Adgangskode igen'" :name="'confirm-password'" type="password"  theme="nr1" @focus="onFocusConfirmPassword" autocomplete="new-password" />
                      <span class="error-label" v-if="confirmPasswordErrLabel">{{confirmPasswordErrLabel}}</span>
                    </div>

                    <div class="login-field">
                      <CheckboxField :name="'Tilmeld dig Opendos nyhedsbrev og få relevant information og nyheder direkte I din indbakke'" :customClass="'transparent'" :value="'newsletter'" :isChecked="true"/>
                    </div>

                    <input type="submit" name="submit" value="Opret" :disabled="disabledSubmit" class="button" />
                  </form>
                </div>

                <div class="loading-wheel" >
                  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 48C3 59.9347 7.74106 71.3807 16.1802 79.8198C24.6193 88.2589 36.0653 93 48 93C59.9347 93 71.3807 88.2589 79.8198 79.8198C88.2589 71.3807 93 59.9347 93 48C93 36.0653 88.2589 24.6193 79.8198 16.1802C71.3807 7.74106 59.9347 3 48 3C36.0653 3 24.6193 7.74106 16.1802 16.1802C7.74106 24.6193 3 36.0653 3 48V48Z" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21 48C21 55.1608 23.8446 62.0284 28.9081 67.0919C33.9716 72.1554 40.8392 75 48 75C55.1608 75 62.0284 72.1554 67.0919 67.0919C72.1554 62.0284 75 55.1608 75 48C75 40.8392 72.1554 33.9716 67.0919 28.9081C62.0284 23.8446 55.1608 21 48 21C40.8392 21 33.9716 23.8446 28.9081 28.9081C23.8446 33.9716 21 40.8392 21 48V48Z" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M39 48C39 50.3869 39.9482 52.6761 41.636 54.364C43.3239 56.0518 45.6131 57 48 57C50.3869 57 52.6761 56.0518 54.364 54.364C56.0518 52.6761 57 50.3869 57 48C57 45.6131 56.0518 43.3239 54.364 41.636C52.6761 39.9482 50.3869 39 48 39C45.6131 39 43.3239 39.9482 41.636 41.636C39.9482 43.3239 39 45.6131 39 48V48Z" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M48 39V21" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M39.4398 45.2203L22.3198 39.6562" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M42.7121 55.2803L32.1281 69.8443" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M53.2921 55.2803L63.8721 69.8443" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M56.5602 45.2203L73.6802 39.6562" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>


              <div class="card-style login-box" v-if="currentView=='banklogin-validate-account'" :class="loading ? ' loading' : ''">
                <div class="title-wrap">
                  <h2 class="h3-1 title">Banklogin</h2>
                </div>
                <div class="textual-wrap">
                  <p>Vi har sendt en mail til <a :href="'mailto:'+validateEmail">{{validateEmail}}</a>.</p>
                  <p>Du skal verificere din email for at fortsætte.</p>
                  <p>Hvis du ikke har modtaget verifikationsmailen kan du checke din spammappe. Du kan også få gensendt mailen.</p>
                </div>
                <div class="foot-info">
                <hr class="link-line" />
                <p><a href="#" class="resend-email-link" v-on:click="resendEmailClick">Gensend email</a></p>
                </div>

                <div class="loading-wheel" >
                  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 48C3 59.9347 7.74106 71.3807 16.1802 79.8198C24.6193 88.2589 36.0653 93 48 93C59.9347 93 71.3807 88.2589 79.8198 79.8198C88.2589 71.3807 93 59.9347 93 48C93 36.0653 88.2589 24.6193 79.8198 16.1802C71.3807 7.74106 59.9347 3 48 3C36.0653 3 24.6193 7.74106 16.1802 16.1802C7.74106 24.6193 3 36.0653 3 48V48Z" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21 48C21 55.1608 23.8446 62.0284 28.9081 67.0919C33.9716 72.1554 40.8392 75 48 75C55.1608 75 62.0284 72.1554 67.0919 67.0919C72.1554 62.0284 75 55.1608 75 48C75 40.8392 72.1554 33.9716 67.0919 28.9081C62.0284 23.8446 55.1608 21 48 21C40.8392 21 33.9716 23.8446 28.9081 28.9081C23.8446 33.9716 21 40.8392 21 48V48Z" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M39 48C39 50.3869 39.9482 52.6761 41.636 54.364C43.3239 56.0518 45.6131 57 48 57C50.3869 57 52.6761 56.0518 54.364 54.364C56.0518 52.6761 57 50.3869 57 48C57 45.6131 56.0518 43.3239 54.364 41.636C52.6761 39.9482 50.3869 39 48 39C45.6131 39 43.3239 39.9482 41.636 41.636C39.9482 43.3239 39 45.6131 39 48V48Z" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M48 39V21" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M39.4398 45.2203L22.3198 39.6562" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M42.7121 55.2803L32.1281 69.8443" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M53.2921 55.2803L63.8721 69.8443" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M56.5602 45.2203L73.6802 39.6562" stroke="#69BDB6" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>

              </div>





            </div>
          </div>
        </div>
      </section>

    </div>

  </div>
</template>
<script>

import InputField from "@/components/ui/input-field/InputField.vue";
import RegisterInputField from "@/components/ui/register-input-field/RegisterInputField.vue";
import CheckboxField from "@/components/ui/checkbox-field/CheckboxField.vue";
import bankloginData from "./BankloginData";

export default {
  props: ['cmsGlobalData', 'cmsPageData', 'view'],
  components: {
    'InputField' : InputField,
    'RegisterInputField' : RegisterInputField,
    'CheckboxField' : CheckboxField
  },
  
  data() {
    return {
      thisSlug: "",
      title: "Login",
      rootView: 'banklogin-login',
      currentView: 'banklogin-login',
      // currentView: 'banklogin-validate-account',
      // currentView: 'leasingpro-login',
      disabledSubmit: false,

      loading: false, 

      validateEmail: '',
      sPasswordKey: '',
      sPasswordEmail: '',
      userFullname: '',
      userName: '',
      password: '',
      confirmPassword: '',
      confirmPasswordErrLabel: '',
      newsletterSub: false,

      userFullnameErrLabel: "",
      userNameErrLabel: "",
      passwordErrLabel: "",
    }
  },
  beforeMount(){
    // alert(footerData.fields.copyright);
    // this.copyright = "b";
  },
  created(){

    if (this.$route.query.action && this.$route.query.action == "setpassword"){
      this.sPasswordKey = this.$route.query.key;
      this.sPasswordEmail = this.$route.query.login;
      this.currentView = 'banklogin-set-password';
    }
    
    if (this.$route.query.action && this.$route.query.action == "validateaccount"){
      var th = this;
      this.sPasswordKey = this.$route.query.key;
      this.sPasswordEmail = this.$route.query.login;
      this.currentView = 'banklogin-validate-account';
      this.validateEmail = this.sPasswordEmail;
      this.loading = true;

      bankloginData.submitValidateUser(this.sPasswordEmail, this.sPasswordKey, function(){
          th.$emit("submit", {"type": "userValidated", "email": th.sPasswordEmail});
          th.loading = false;
          th.$router.replace({  });
          th.sPasswordKey = "";
          th.sPasswordEmail = "";
          th.currentView = 'banklogin-login';

      }, function(){
        th.$emit("submit", {"type": "failedUserValidation"});
        th.loading = false;
        
      });

    }

    if (this.$route.query.action && this.$route.query.action == "resetpassword"){
      this.sPasswordKey = this.$route.query.key;
      this.sPasswordEmail = this.$route.query.login;
      this.currentView = 'banklogin-reset-password';
    }
    
    if (this.view){
      this.currentView = this.view;
    }
    this.$watch(
      () => this.$route.name,
      (toParams, previousParams) => {
        if (this.view){
          this.currentView = this.view;
        }else{
          this.currentView = this.rootView;
        }
      }
    )
    
    this.$watch(
      () => this.view,
      (toParams, previousParams) => {
        this.currentView = this.view;
      }
    );
    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        // this.title = this.cmsGlobalData.page_404.title;
        // this.subTitle = this.cmsGlobalData.page_404.subtitle;
        // this.listTitle = this.cmsGlobalData.page_404.list_title;
        // this.links = this.cmsGlobalData.page_404.list; 
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {
        this.thisSlug = this.cmsPageData.page.url;
      }
    );
  },
  mounted(){
    var th = this;
    
      th.recoverFromToken();
    
  },
  methods: {
    forgotPasswordClick: function(e){
      e.preventDefault();
      this.currentView = "banklogin-forgot";
    },
    toRegisterClick: function(e){
      e.preventDefault();
      this.currentView = "banklogin-register";
    },
    backLinkClick: function(e){
      e.preventDefault();
      this.currentView = this.rootView;
    },


    onSubmitRegister: function(e){
      e.preventDefault();
      // alert("x");
      var formData = new FormData(this.$refs.registerForm);
      this.userFullname = formData.get('fullname');
      this.userName = formData.get('email');
      this.password = formData.get('password');
      this.confirmPassword = formData.get('confirm-password');

      this.newsletterSub = formData.get('newsletter') ? true : false;
      
      
      if (this.validateRegisterSubmit()){
        var th = this;
        this.loading = true;


  
        bankloginData.submitRegister(this.userFullname, this.userName, this.password, this.newsletterSub, function(){
          th.$emit("submit", {"type": "userRegistered"});
          th.loading = false;
          th.$router.replace({  });
          th.password = "";
          th.confirmPassword = "";
          th.validateEmail = th.userName;
          th.currentView = 'banklogin-validate-account';

        }, function(data){
          if (data.error_type == "no-domain"){
            th.userNameErrLabel = "Ikke gyldig emailadresse.";
          }
          th.$emit("submit", {"type": "failedUserRegister", "error_type": data.error_type});
          th.loading = false;
          
        });


        // this.$emit("submit", {"username": this.userName, "password": this.password});
      }

    },
    
    onSubmitResetPassword: function(e){
      e.preventDefault();
      // alert("x");
      var formData = new FormData(this.$refs.resetPasswordForm);
      this.userName = formData.get('email');
      this.password = formData.get('password');
      this.confirmPassword = formData.get('confirm-password');

      if (this.validateResetPasswordSubmit()){
        
        var th = this;
        this.loading = true;
  
        bankloginData.submitResetPassword(this.userName, this.password, this.sPasswordKey, function(){
          th.$emit("submit", {"type": "resetPasswordSuccess"});
          th.loading = false;
          th.$router.replace({ 'name' : "Banklogin" });
          th.password = "";
          th.confirmPassword = "";
          th.currentView = 'banklogin-login';

        }, function(){
          th.$emit("submit", {"type": "failedResetPassword"});
          th.loading = false;
          
        });


        // this.$emit("submit", {"username": this.userName, "password": this.password});
      }

    },



    onSubmitForgotPassword: function(e){
      e.preventDefault();

      var formData = new FormData(this.$refs.forgotPasswordForm);
      this.userName = formData.get('username');

      if (this.validateForgotPasswordSubmit()){

        var th = this;
        this.loading = true;
  
        bankloginData.sendForgotPasswordEmail(this.userName, function(){
          var th = this;
          th.loading = false;
          th.$router.replace({ name: "Banklogin" });
          th.password = "";
          th.confirmPassword = "";
          th.currentView = 'banklogin-login';
          th.$emit("submit", {"type": "forgotPasswordSuccess"});
        }.bind(this), function(){
          var th = this;
          th.loading = false;
          th.$emit("submit", {"type": "forgotPasswordFailed"});
        }.bind(this))
        // this.$emit("submit", {"username": this.userName, "password": this.password});
      }

    },


    onSubmitLogin: function(e){
      e.preventDefault();


      var formData = new FormData(this.$refs.loginForm);
      this.userName = formData.get('username');
      this.password = formData.get('password');

      if (this.validateLoginSubmit()){

        var th = this;
        this.loading = true;

  
        bankloginData.submitLogin(this.userName, this.password, function(){
          var th = this;
          th.$emit("submit", {"type": "loginSuccess"});
          th.loading = false;
          th.$router.replace({ name: "BankloginHome" });
          th.password = "";
          th.confirmPassword = "";
          th.currentView = 'banklogin-login';
        }.bind(this), function(){
          var th = this;

          this.passwordErrLabel = "Tjek venligst login detaljer";

          th.$emit("submit", {"type": "loginFailed"});

          

          th.loading = false;
        }.bind(this))
        // this.$emit("submit", {"username": this.userName, "password": this.password});
      }

    },
    onSubmitSetPassword: function(e){
      e.preventDefault();
      // alert("x");
      var formData = new FormData(this.$refs.setPasswordForm);
      this.userName = formData.get('email');
      this.password = formData.get('password');
      this.confirmPassword = formData.get('confirm-password');
      
      
      if (this.validateSetPasswordSubmit()){
        var th = this;
        this.loading = true;


  
        bankloginData.submitSetPassword(this.userName, this.password, this.sPasswordKey, function(){
          th.$emit("submit", {"type": "setPassword"});
          th.loading = false;
          th.$router.replace({  });
          th.password = "";
          th.confirmPassword = "";
          th.currentView = 'banklogin-login';

        }, function(){
          th.$emit("submit", {"type": "failedSetPassword"});
          th.loading = false;
          
        });


        // this.$emit("submit", {"username": this.userName, "password": this.password});
      }

    },

    resendEmailClick(e){
      e.preventDefault();
      
      var th = this;

      bankloginData.submitResendValidationEmail(th.validateEmail, function(){
        th.loading = false;
        th.$emit("submit", {"type": "resendValidationEmailSuccess", "email": th.validateEmail});
      },function(){
        th.$emit("submit", {"type": "resendValidationEmailFailed"});
        th.loading = false;
      });
      

      

    },
    recoverFromToken(){

      var th = this;
      
      bankloginData.recoverFromToken(function(){
        th.$emit("submit", {"type": "recoverSuccess"});
        th.loading = false;
        th.$router.replace({ name: "BankloginHome" });
        th.password = "";
        th.confirmPassword = "";
        th.currentView = 'banklogin-login';
      },function(){
        th.$emit("submit", {"type": "recoverFailed"});
        th.loading = false;
      });
      
    },
    

    
    onFocusFullname(){
      this.userFullnameErrLabel = "";
    },
    onFocusUsername(){
      this.userNameErrLabel = "";
    },
    onFocusPassword(){
      this.passwordErrLabel = "";
    },
    onFocusConfirmPassword(){
      this.confirmPasswordErrLabel = "";
    },
    resetLabels() {
      this.userNameErrLabel = "";
      this.passwordErrLabel = "";
      this.confirmPasswordErrLabel = "";
    },

    validateLoginSubmit() {
      this.resetLabels();
      var validated = true;

      if (this.userName === undefined || this.userName === null || this.userName === "") {
        this.userNameErrLabel = "Du mangler at angive e-mail";
        validated = false;
      }

      if (this.password === undefined || this.password === null || this.password === "") {
        this.passwordErrLabel = "Du mangler at angive kodeord";
        validated = false;
      }

      return validated;
    },


    validateRegisterSubmit(){
      this.resetLabels();
      var validated = true;


      if (this.userFullname === undefined || this.userFullname === null || this.userFullname === "") {
        this.userFullnameErrLabel = "Du mangler at angive e-mail";
        validated = false;
      }

      if (this.userName === undefined || this.userName === null || this.userName === "") {
        this.userNameErrLabel = "Du mangler at angive e-mail";
        validated = false;
      }

      if (this.password === undefined || this.password === null || this.password === "") {
        this.passwordErrLabel = "Du mangler at angive kodeord";
        validated = false;
      }

      if (this.confirmPassword === undefined || this.confirmPassword === null || this.confirmPassword === "") {
        this.confirmPasswordErrLabel = "Du mangler at angive kodeord";
        validated = false;
      }

      if (validated){
        if (this.password == this.confirmPassword){
          // alert("nois");
        }else{
          validated = false;
          this.confirmPasswordErrLabel = "Adgangskoder stemmer ikke overens";
        }
      }

      return validated;
    },

    validateForgotPasswordSubmit() {
      this.resetLabels();
      var validated = true;

      if (this.userName === undefined || this.userName === null || this.userName === "") {
        this.userNameErrLabel = "Du mangler at angive e-mail";
        validated = false;
      }

      return validated;
    },

    validateSetPasswordSubmit() {
      this.resetLabels();
      var validated = true;

      if (this.userName === undefined || this.userName === null || this.userName === "") {
        this.userNameErrLabel = "Du mangler at angive e-mail";
        validated = false;
      }

      if (this.password === undefined || this.password === null || this.password === "") {
        this.passwordErrLabel = "Du mangler at angive kodeord";
        validated = false;
      }

      if (this.confirmPassword === undefined || this.confirmPassword === null || this.confirmPassword === "") {
        this.confirmPasswordErrLabel = "Du mangler at angive kodeord";
        validated = false;
      }

      if (validated){
        if (this.password == this.confirmPassword){
          // alert("nois");
        }else{
          validated = false;
          this.confirmPasswordErrLabel = "Adgangskoder stemmer ikke overens";
        }
      }

      return validated;
    },
    validateResetPasswordSubmit() {
      this.resetLabels();
      var validated = true;

      if (this.userName === undefined || this.userName === null || this.userName === "") {
        this.userNameErrLabel = "Du mangler at angive e-mail";
        validated = false;
      }

      if (this.password === undefined || this.password === null || this.password === "") {
        this.passwordErrLabel = "Du mangler at angive kodeord";
        validated = false;
      }

      if (this.confirmPassword === undefined || this.confirmPassword === null || this.confirmPassword === "") {
        this.confirmPasswordErrLabel = "Du mangler at angive kodeord";
        validated = false;
      }

      if (validated){
        if (this.password == this.confirmPassword){
          // alert("nois");
        }else{
          validated = false;
          this.confirmPasswordErrLabel = "Adgangskoder stemmer ikke overens";
        }
      }

      return validated;
    }
  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/style.scss";
@import "~@/styles/variables.scss";
  .page-logins{
    padding-bottom: 148px;


    hr.link-line{
      margin-top: 40px;
      margin-bottom: 32px;
      display: block;
      border: none;
      height: 1px;
      width: 100%;
      background: #000;
      opacity: 0.1;
    }

    .left-side{
      padding-top: 64px;
      padding-bottom: 64px;
      text-align: center;
      padding-left: 48px;
      padding-right: 48px;
      svg{
        display: inline-block;
        max-width: 100%;
        height: auto;
      }
    }
    .content{
      width:100%;
      max-width: 716px;
      margin-left: auto;
      padding-left: 24px;
      box-sizing: border-box;
      padding-top: 60px;
    }
    h1{
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 72px;
      color: $color-primary;
      
    }
    h3{
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 130%;
      color: $color-primary;
      opacity: 0.8;
      margin-top: 20px;
    }
    .sub-title{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 133%;
      text-align: center;
      color: #4C6C69;
      margin-top: 24px;
      max-width: 512px;
      margin-left: auto;
      margin-right: auto;
    }
    .login-box{
      padding: 40px;
      padding-top: 40px;
      padding-bottom: 40px;
      width: 100%;
      box-sizing: border-box;
      margin-top: 60px;

      position: relative;


      .textual-wrap{
        
        max-width: 436px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;

        p{
          font-style: normal;
          margin-top: 0.5em;
          font-weight: 400;
          font-size: 14px;
          line-height: 133%;
          text-align: center;
          color: #4C6C69;
        }
        a{
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 133%;
          text-align: center;
          color: #1F4743;
        }

      }
      .title-wrap{
        position: relative;


        

        .back-arrow{
          position: absolute;
          top: 0px;
          left: 0px;
          width: 32px;
          height: 32px;
          line-height: 0px;
          .back-arrow-icon{
            position: absolute;
            top: 50%;
            left: 0px;
            margin-top: -7px;
          }
        }

      }

      .error-label{
        font-size: 12px;
        padding-left: 4px;
        display: block;
        text-align: left;
        color: #DF4343;
        margin-top: 4px;
      }
      .foot-info{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        text-align: center;
        color: #4C6C69;
        margin-top: 20px;
      }
      .button{
        border: none;
        margin-top: 34px;
      }
      .form-wrap{
        text-align: center;
        margin-top: 40px;
      }
      .login-field{
        margin-left: auto;
        margin-right: auto;
        display: block;
        max-width: 395px;
        margin-bottom: 16px;
        text-align: left;
      }

      .title{
        text-align: center;
      }
    }
    .links-list{
      padding: 40px;
      padding-top: 12px;
      padding-bottom: 24px;
      width: 100%;
      box-sizing: border-box;
      margin-top: 60px;
      .title{
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 130%;
        color: $color-primary;
        margin-bottom: 40px;
      }
      
    }


    .forgot-link{
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 133%;
      text-align: center;
      color: #1F4743;
    }

    .register-link{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      text-align: center;
      color: #1F4743;
      font-weight: 700;
    }
    .resend-email-link{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      text-align: center;
      color: #1F4743;
      font-weight: 700;
    }
    

    .loading-wheel{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 96px;
      height: 96px;
      margin-top: - 48px;
      margin-left: - 48px;
      -webkit-animation:spinwheel 1s linear infinite;
      -moz-animation:spinwheel 1s linear infinite;
      animation:spinwheel 1s linear infinite;

      display: none;

    }
    @-moz-keyframes spinwheel { 
        100% { -moz-transform: rotate(360deg); } 
    }
    @-webkit-keyframes spinwheel { 
        100% { -webkit-transform: rotate(360deg); } 
    }
    @keyframes spinwheel { 
        100% { 
            -webkit-transform: rotate(360deg); 
            transform:rotate(360deg); 
        } 
    }

    .loading{


      .loading-wheel{
        display: block;
      }
    }
  }

@media (max-width: 1000px) {
    .page-logins{
      .sides{
        display: block;
      }
      .content{
        max-width: 100%;
        padding-top: 0px;
        padding-left: 0px;
      }
      h1{
        max-width: 692px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      h3{
        text-align: center;
      }
    }
}
</style>