<template>
  <div class="cars-block">
    <div class="block">
      <div class="wrap">
        <h2 class="block-title">{{title}}</h2>
      </div>
      <car-slider :cars="cars" :sliderIndex="sliderIndex" :noSideMask="true" />
      <div class="wrap">
        <div class="button-wrap" v-if="button && button.url">
          <a class="button" :href="button.url" :target="button.target">{{button.title}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CarSlider from "../../../components/ui/car-slider/CarSlider";
import CarList from "../../../components/ui/car-list/car-list";
import AbAxios from "../../../components/leasing/Showroom/src/common/ab_axios_leasing";

export default {
  props: ['block'],
  components: {
    "car-list" : CarList,
    "car-slider" : CarSlider
  },
  data() {
    return {
      axios: null,
      title: null,
      description: null,
      loading: true,
      data: null,
      carlist: null,
      cars: null,
      leasingType: "erhverv",
      sliderIndex: Math.round(Math.random())+''+Math.round(Math.random())+''+Math.round(Math.random()),
      button: {"url": null, "title": null, "target": null},
    }
  },
  mounted(){
    this.prepareContent();
  },
  methods: {
    prepareContent: function(){
      let finalUrl;
      const th = this;
      this.title = this.block.title.value;

      if (this.block.cars){
        this.leasingType = this.block.cars.value;
      }

      if (this.block.button){
        this.button = this.block.button.value;
      }

      let fuelTypesParameter = "";
      if (this.block.fuel_type) {
        let fuelTypes = [];
        if (this.block.fuel_type.value === "el") {
          fuelTypes.push("el");
        } else if (this.block.fuel_type.value === "fuel") {
          fuelTypes.push("benzin","diesel");
        } else if (this.block.fuel_type.value === "plugin") {
          fuelTypes.push("elbenzinplugin", "eldieselplugin")
        }
        fuelTypesParameter = "&fuel_types=" + fuelTypes.join(",");
      }

      if (this.axios == null){
        this.axios = new AbAxios();
      }

      const count = 4;

      if (this.leasingType == "erhverv"){
        finalUrl = "leasing/Corporate/vehicles?size=" + count + fuelTypesParameter;
      } else {
        finalUrl = "leasing/Consumer/vehicles?size=" + count + fuelTypesParameter;
      }
      
      this.axios.abGet(finalUrl, true, function successCallback(response) {
        th.data = response.data;
        th.carlist = th.data.data;
        th.loading = false;      
      }, function errorCallback(error) {
          console.log(error);
      });
    },
    onTaxTabClick(e){
      e.preventDefault();
      this.currentTab = "tax";
    },
    onCarTabClick(e){
      e.preventDefault();
      this.currentTab = "car";
    }

  },
  created() {
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );

    this.sliderIndex = Math.round(Math.random()*2000)+''+Math.round(Math.random()*2000)+''+Math.round(Math.random()*2000);

    this.$watch(
      () => this.carlist,
      (toParams, previousParams) => {
        let cl = [];
        for(const element of this.carlist) {
          //add custom url to car as the car block don't have a showroom route to check it will fail
          if (element.frontend_configuration_url?.length) {
            element.customUrl = element.frontend_configuration_url;
          } else {
            element.customUrl = '/' + this.leasingType + '/showroom/' + element.id;
          }
          cl.push(element);
        }
        this.cars = cl;
      }
    );
  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


.cars-block{
  margin-top: 124px;
  margin-bottom: 124px;
  padding-top: 56px;
  padding-bottom: 56px;

  .block-title{
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    color: #1F4743;
    margin-bottom: 64px;
  }
  .button-wrap{
    text-align: center;
  }
}


@media (max-width: #{$screen-tablet}) {

  .cars-block{
    margin-top: 96px;
    margin-bottom: 96px;
    padding-top: 64px;
    padding-bottom: 64px;
  }

}
@media (max-width: #{$screen-mobile}) {

  .cars-block{
    margin-top: 112px;
    margin-bottom: 112px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>