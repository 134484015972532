<template>
  <div class="main referal-landing-main">
    <div class="page-content">
      <section class="section fade-on-enter hero-section">
        <div class="wrap">
          <div class="flex my-flex-row my-flex-nowrap flex-s3">
            <div v-if="heroBlock.image" class="image">
              <img :src="heroBlock.image.sizes.large" :alt="heroBlock.image.info ? heroBlock.image.info.alt : ''" />
            </div>
            <div class="side">
              <h1 class="h1-1 title" >{{heroBlock.title}}</h1>
              <div class="content" v-html="heroBlock.content"></div>
              <a class="button" :href="'/erhverv/showroom'" v-on:click="onHeroButtonClick" >{{heroBlock.button_title}}</a>
              <!--<router-link :class="'button'" :to="'/faq'" >{{heroBlock.button_title}}</router-link>-->
            </div>
          </div>
        </div>
      </section>
      <section class="section fade-on-enter how-list-section" v-if="advantagesBlock">
        <div class="wrap">
          <h2 class="section-title">{{advantagesBlock.title}}</h2>
          <div class="how-list flex my-flex-row flex-s6 my-flex-wrap">
            <div class="item" v-for="(item, index) in advantagesBlock.advantages" :key="'how-item-'+index" >
              <img class="icon" :src="item.icon.url" alt="icon" />
              <p class="title">{{item.title}}</p>
              <div class="content" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </section>


      <div v-if="true" class="wrap fade-on-enter op-fade-in-animation steps-content style-2 flex flex-s10 my-flex-wrap flex-row">
        <div class="left-side">
          <div class="image">
            <img v-if="stepsContent.image" :src="stepsContent.image.sizes.large" :alt="stepsContent.image.info ? stepsContent.image.info.alt : ''" />
          </div>
        </div>
        <div class="right-side">
          <h2 class="title" v-if="stepsContent.title">{{stepsContent.title}}</h2>
          <div class="steps-block">
            <div class="item" v-if="stepsContent.steps" v-for="(item, index) in stepsContent.steps" :key="index" >
                  <h2 class="step-title">{{item.title}}</h2>
                  <p class="description" v-html="item.content"></p>
            </div>
          </div>
        </div>
      </div>

       <section v-if="benefitsBlock" class="section fade-on-enter benefits-section">
        <div class="wrap">
          <div class="container flex my-flex-row my-flex-nowrap flex-s3">
            <h2 class="h2-1 big-title">{{benefitsBlock.title}}</h2>
            <div class="benefits flex my-flex-row my-flex-wrap flex-s3">
              <div class="benefit" v-for="(item, index) in benefitsBlock.advantages" :key="'benefits-'+index" >
                <p class="title">{{item.title}}</p>
                <div class="content" v-html="item.content"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <span v-if="cmsPageData && !cmsPageData.loaded"></span>
    </div>
  </div>
  
</template>


<script>

import VueScrollTo from "vue-scrollto";
import Axios from "axios";
import eventBus from '../../eventBus';

export default {
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      axios: null,
      heroBlock: { title: null },
      advantagesBlock: null,
      benefitsBlock: { title: "" },
      stepsContent: null
    }
  },
  beforeMount(){
    // alert(footerData.fields.copyright);
    // this.copyright = "b";
  },
  created(){
  

    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {
        // this.articles = this.cmsPageData.page.news_list;
        this.heroBlock = this.cmsPageData.page.referal_landing.hero;
        this.advantagesBlock = this.cmsPageData.page.referal_landing.advantages;
        this.benefitsBlock = this.cmsPageData.page.referal_landing.private_advantages;
        this.stepsContent = this.cmsPageData.page.referal_landing.how;
        // alert(this.heroBlock.button_title);
      }
    );
    

    this.$watch(
      () => this.$route,
      (toParams, previousParams) => {
       
      }
    );
  },
  mounted(){
    
  },
  methods: {
    onHeroButtonClick: function(e){
      
    }
  },
 
}
</script>

<style lang="scss" scoped>
@import "~@/style.scss";


.hero-section{
  margin-bottom:80px;
  margin-top:60px;
  .image{
    width: 50%;
    border-radius: 20px;
    overflow: hidden;
    line-height: 0px;
    padding-right: 60px;
    box-sizing: border-box;
    img{
      border-radius: 20px;
    }
  }
  .side{
    padding-top: 36px;
    width: 50%;
    padding-left: 20px;
    box-sizing: border-box;


    .title{
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
    }
    .content{
      max-width:430px;

      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #4C6C69;
    }
    .button{
      margin-top: 60px;
    }
  }
}

.how-list-section{
  margin-bottom: 120px;
  margin-top: 120px;

  .section-title{
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 130%;
    text-align: center;
    color: #1F4743;
    margin-bottom: 60px;
  }
  .how-list{
    text-align: center;
    .item{
      text-align: left;
      width: 25%;
      width: calc(25% - 30px);
      .icon{
        height: 60px;
        margin-bottom: 28px;
      }
      .title{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 130%;
        color: #1F4743;
        margin-bottom: 8px;
      }
      .content{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        color: #1F4743;
      }
    }
  }
}

.benefits-section{
  margin-bottom: 80px;
  .container{
    // background: $color-super-light-green;
    margin: 0px;
    background: rgba(105, 189, 182, 0.09);
    border-radius: 20px;  
    padding: 60px;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-right: 40px;
  }
  .big-title{
    max-width: 283px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #1F4743;
    width: 283px;
  }
  .benefits{
    width: 100%;
    width: calc(100% - 283px);
    .benefit{
      width: 50%;
      padding-left: 65px;
      padding-right: 20px;
      box-sizing: border-box;
      margin-bottom: 48px;
    }
    .title{
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: #1F4743;
      margin-bottom: 16px;
    }
    .content{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      color: #1F4743;

    
      
    }
    .content ::v-deep p{
      margin-top: 16px;
    }
  }
}

.steps-content.style-2{
  margin-top: 80px;
  margin-bottom: 120px;

  .left-side{
    width: 35%;
    .image{
      border-radius: 20px;
      overflow: hidden;
      width: 100%;
      img{
        border-radius: 20px;
        width: 100%;
      }
    }
  }
  .right-side{
    width: 65%;
    padding-left: 123px;
    box-sizing: border-box;
  }
  .title{
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #1F4743;
    margin-bottom: 48px;
    max-width: 386px;
  }
  .steps-block{
    .item{
      width: 100%;
      // margin-bottom: 20px;
      max-width: 466px;
      margin-top: 40px;
      .step-title{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 130%;
        color: #1F4743;
        margin-bottom: 8px;
      }
      .description{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        color: #4C6C69;
      }
    }
  }
}



@media (max-width: 1000px) {
  .hero-section{
    .image{
      width: 35%;
      height: 400px;
      img{
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }
    .side{
      width: 65%;
      .title{
        
      }
      .content{
        
        ::v-deep p{
        
        }
      }
      .button{
        
      }
    }
  }
  .benefits-section{

    .container{
      display: block;
      padding-bottom: 60px;
    }
    .big-title{
      max-width: none;
      margin-bottom: 40px;
      width: auto;
    }
    .benefits{
      width: 100%;
      .benefit{
        padding-right: 56px;
        padding-left: 0px;
      }
      .title{
      
      }
      .content{
      
      }
      .content ::v-deep p{
      
      }
    }
  }

}



@media (max-width: 968px) {

  .steps-content.style-2{
    .left-side{
      width: 40%;
    }
    .right-side{
      width: 60%;
      padding-left: 48px;
    }
  }
}

@media (max-width: 668px) {

  .steps-content.style-2{
    display: block;
    .left-side{
      width: 100%;
      max-width: 400px;
      margin-bottom: 48px;
    }
    .right-side{
      width: 100%;
      padding-left: 0px;
    }
  }
}



@media (max-width: #{$screen-tablet}) {
  .hero-section{
    .flex{
      // display: block;
      -ms-flex-direction: column;
		  flex-direction: column;
    }
    .image{
      width: 100%;
      padding: 0px;
      height: auto;
      img{
        height: auto;
      }
    }
    .side{
      width: 100%;
      .title{
        
      }
      .content{
        
        ::v-deep p{
        
        }
      }
      .button{
        
      }
    }
  }
}


@media (max-width: #{$screen-mobile}) {
  .hero-section{
    .side{
      .title{
        margin-bottom: 12px;
        font-size: 32px;
        line-height: 130%;
      }
      .content{
        font-size: 16px;
        line-height: 130%;
      }
    }
  }
  .how-list-section{
    margin-bottom: 60px;
    .how-list{
      .item{
        width: calc(50% - 20px);
        // width: 100%;
        // margin-bottom: 60px;
      }
    }
  }
  .benefits-section{

    .container{
      display: block;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 40px;
    }
    .big-title{
      max-width: none;
      margin-bottom: 0px;
      width: auto;

      font-weight: 700;
      font-size: 28px;
      line-height: 120%;
    }
    .benefits{
      display: block;
      .benefit{
        padding-right: 0px;
        margin-top: 40px;
        width: 100%;
      }
      .title{
      
      }
      .content{
      
      }
      .content ::v-deep p{
      
      }
    }
  }
}


</style>
