import Vue from "vue";
import { Component, Emit, Inject, Model, Prop, Provide, Watch } from "vue-property-decorator";
import UserData from '../providers/UserData';
import AbAxios from '../../common/ab_axios_leasing';
import SimpleUpload from '../../shared/inputs/upload/SimpleUpload.vue';
import template from './Upload.vue';
import Utils from '../providers/Utils';
import AbLightbox from '../../common/ab_lightbox';
import IMoneyConfig from '../../calculators/core/models/bank/IMoneyConfig';
import { Money } from 'v-money';
import { isNullOrUndefined } from "is-what";
import AbGtm from '../../common/ab_gtm';
import AbCpr from '../../common/ab_cpr';
// import money from 'v-money'
 
// register directive v-money and component <money>
Vue.use(Money, {precision: 2})



interface formPerson {
    name: string,
    mobile: string,
    income: number,
    cpr: string,
    employment: string,
    debt: number
}

interface sharedDataForm {
    token: string
}

@Component({
    name: 'leasing-upload-component',
    mixins: [template],
    components: {
        'simple-upload-component': SimpleUpload,
        'money-component': Money,
    }

})
export default class Upload extends Vue {

    userData: UserData = this.$store.getters.getUserData;

    moneyInputConfig: IMoneyConfig = {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: ' kr',
        precision: 0,
        masked: false,
        allowBlank: true //false
    };

    utils: Utils = new Utils();
    abLightbox = new AbLightbox();
    abGtm = new AbGtm();
    abCpr = new AbCpr();

    //upload component
    uploadPostUrl: string = "";
    uploadBtnText: string = "Vedhæft";
    sendBtnText: string = "Send filer";
    uploadedFiles: Blob[] = [];
    //upload component

    employment: Array<string> = ["Fuldtidsansat", "Selvstændig", "Under uddannelse", "Deltidsansat", "Orlov", "Pensionist", "Vikar", "Ledig", "Hjemmegående"];

    spouseAdded: boolean = false;

    sharedData: sharedDataForm = {
        token: ""
    }

    person: formPerson = {
        name: "",
        mobile: "",
        income: 0,
        cpr: "",
        employment: "",
        debt: 0
    }

    spouse: formPerson = {
        name: "",
        mobile: "",
        income: 0,
        cpr: "",
        employment: "",
        debt: 0
    }

    savedContract: any = {}
    abAxios = new AbAxios(this.userData.serviceUrl, "application/json; charset=utf-8", this.userData.csrftoken);

    uploadServiceUrl: string = "/leasing/api/upload";
    checkcontractServiceUrl: string = "/leasing/api/checkcontract";

    uploadErrLabel: string = "";
    mobileErrLabel: string = '';
    cprErrLabel: string = '';

    incomeErrLabel: string = '';
    employmentErrLabel: string = '';
    debtErrLabel: string = '';

    spouseIncomeErrLabel: string = '';
    spouseEmploymentErrLabel: string = '';
    spouseDebtErrLabel: string = '';
    spouseNameErrLabel: string = '';
    spouseCprErrLabel: string = '';

    //emit fra simple upload component
    uploadChanged(data: any): void {

        this.uploadedFiles = [];
        for (let i=0; i < data.length; i++) {
            this.uploadedFiles[i] = data[i].file;
        }
        this.resetUploadError();
    }

    resetUploadError() {
        this.uploadErrLabel = "";
    }

    resetMobileError() {
        this.mobileErrLabel = "";
    }
    resetCprError() {
        this.cprErrLabel = "";
    }

    resetIncomeError() {
        this.incomeErrLabel = "";
    }
    resetEmploymentError() {
        this.employmentErrLabel = "";
    }
    resetDebtError() {
        this.debtErrLabel = "";
    }

    resetSpouseIncomeError() {
        this.spouseIncomeErrLabel = "";
    }
    resetSpouseEmploymentError() {
        this.spouseEmploymentErrLabel = "";
    }
    resetSpouseDebtError() {
        this.spouseDebtErrLabel = "";
    }
    resetSpouseNameError() {
        this.spouseNameErrLabel = "";
    }
    resetSpouseCprError() {
        this.spouseCprErrLabel = "";
    }

    uploadViaRest(serviceUrl: string) {
        let self = this;

        let formData = new FormData();

        formData.append("cpr", this.person.cpr);
        formData.append("phone_number", this.person.mobile);
        formData.append("income", this.person.income+"");
        formData.append("employment", this.person.employment);
        formData.append("debt", this.person.debt+"");
        //shared
        formData.append("token", this.sharedData.token);

        for (var i = 0; i < this.uploadedFiles.length; i++) {
            let file = this.uploadedFiles[i];
            formData.append("files", file);
            //console.log(file.name + " has a size of " + file.size + " Bytes");
            //console.log(URL.createObjectURL(file));
        }
        if (this.spouseAdded) {
            //spouse
            formData.append("co_buyer_cpr", this.spouse.cpr + "");
            formData.append("co_buyer_name", this.spouse.name + "");
            formData.append("co_buyer_income", this.spouse.income + "");
            formData.append("co_buyer_employment", this.spouse.employment);
            formData.append("co_buyer_debt", this.spouse.debt + "");
        }

        this.abAxios.abPostWithToken(serviceUrl, formData, "multipart/form-data", true,
            function successCallback(response: any) {
                //console.log(response);
                if (self.userData.epiData.redirectPageAfterUpload !== "") {
                    window.location.href = "/" + self.userData.epiData.redirectPageAfterUpload;
                } else {
                    window.location.href = "https://www.almbrand.dk";
                }
            },
            function errorCallback(error: any) {
                let errorFound = false;
                if (!isNullOrUndefined(error.response.data) && error.response.data !== "" && error.response.status === 400) {
                    let violations = error.response.data.violations;
                    if (violations && violations.length > 0) {
                        violations.forEach((_data: any) => {
                            if (_data.field === "phone_number") {
                                self.abLightbox.standardLightText("Upload fejlede", "Noget gik galt.. </br></br>" + "Det oprindelig oplyste telefonnummer stemmer ikke overens med angivet telefonnummer." + "</br></br> Ring til os på Tlf. <a class='popup__content__phone-link' href='tel: 70607010'> 70 60 70 10</a>");
                                errorFound = true;
                            }
                        })
                    }
                }
                if (!errorFound) {

                    let errorRes = error.response,
                        statusCode = errorRes.status,
                        statusText = errorRes.statusText,
                        errorMsg = "Upload fejled. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
                        self.abGtm.triggerGtmEvent("fejl", "Upload", '[' + self.abGtm.getUtcTimestamp() + '] ' + errorMsg);

                    self.abLightbox.standardLightText("Upload fejlede", "Noget gik galt.. </br></br>" + error + "</br></br> Ring til os på Tlf. <a class='popup__content__phone-link' href='tel: 70607010'> 70 60 70 10</a>");
                }
            });
    }

    checkcontractViaRest(serviceUrl: string) {
        let self = this;
        let payload = { "token": this.sharedData.token};
        this.abAxios.abPostWithToken(serviceUrl, payload, "application/json", true,
            function successCallback(response: any) {
                let resp = response.data.with_cobuyer;
                if (!isNullOrUndefined(resp) && resp !== "" && resp) {
                    self.toggleSpouse();
                }
            },
            function errorCallback(error: any) {
                console.log(error);
            });
    }


    resetLabels() {
        this.mobileErrLabel = '';
        this.cprErrLabel = '';

        this.incomeErrLabel = '';
        this.employmentErrLabel = '';
        this.debtErrLabel = '';

        this.spouseNameErrLabel = '';
        this.spouseCprErrLabel = '';
        this.spouseIncomeErrLabel = '';
        this.spouseEmploymentErrLabel = '';
        this.spouseDebtErrLabel = '';
    }
    //focus virker ikke på VueMoneyInput
    @Watch('person.income')
    watchPersonIncome(newValue: any, oldValue: any) {
        this.resetIncomeError();
    }
    @Watch('person.debt')
    watchPersonDebt(newValue: any, oldValue: any) {
        this.resetDebtError();
    }
    @Watch('spouse.income')
    watchSpouseIncome(newValue: any, oldValue: any) {
        this.resetSpouseIncomeError();
    }
    @Watch('spouse.debt')
    watchSpouseDebt(newValue: any, oldValue: any) {
        this.resetSpouseDebtError();
    }

    validateSubmit() {
        this.resetLabels();
        let validated: boolean = true;

        if (this.person.mobile === undefined || this.person.mobile === "") {
            this.mobileErrLabel = "Du mangler at angive dit telefonnummer";
            validated = false;
        }
        else if (this.person.mobile.length < 8) {
            this.mobileErrLabel = "Dit telefonnummer skal minimum være 8 tal";
            validated = false;
        }

        if (this.person.cpr === undefined || this.person.cpr === "") {
            this.cprErrLabel = "Du mangler at angive dit cpr nummer";
            validated = false;
        }
        else if (!this.abCpr.isValidCpr(this.person.cpr)) {
            this.cprErrLabel = "Dit cpr nummer er ikke valid";
            validated = false;
        }

        /*if (this.sharedData.captcha === undefined || this.sharedData.captcha === "") {
            console.log("Du er en robot...");
            validated = false;
        }*/

        if (this.uploadedFiles.length === 0) {
            this.uploadErrLabel = "Du skal uploade mindst en fil.";
            validated = false;
        }

        if (this.person.income === 0) {
            this.incomeErrLabel = "Du mangler at angive din indkomst";
            validated = false;
        }

        if (this.person.employment === undefined || this.person.employment === "") {
            this.employmentErrLabel = "Du mangler at angive dit ansættelsesforhold";
            validated = false;
        }

        /*if (this.person.debt === 0) {
            this.debtErrLabel = "Du mangler at angive din gæld";
            validated = false;
        }*/

        //spouse
        if (this.spouseAdded) {
            if (this.spouse.income === 0) {
                this.spouseIncomeErrLabel = "Du mangler at angive din indkomst";
                validated = false;
            }

            if (this.spouse.employment === undefined || this.spouse.employment === "") {
                this.spouseEmploymentErrLabel = "Du mangler at angive dit ansættelsesforhold";
                validated = false;
            }

            /*if (this.spouse.debt === 0) {
                this.spouseDebtErrLabel = "Du mangler at angive din gæld";
                validated = false;
            }*/

            if (isNullOrUndefined(this.spouse.name) || this.spouse.name === "") {
                this.spouseNameErrLabel = "Du mangler at angive dit navn og efternavn";
                validated = false;
            }
            if (isNullOrUndefined(this.spouse.cpr) || this.spouse.cpr === "") {
                this.spouseCprErrLabel = "Du mangler at angive dit cpr nummer";
                validated = false;
            }
            else if (!this.abCpr.isValidCpr(this.spouse.cpr)) {
                this.spouseCprErrLabel = "Dit cpr nummer er ikke valid";
                validated = false;
            }
        }

        return validated;
    }

    toggleSpouse(): void {
        this.spouseAdded ? this.spouseAdded = false : this.spouseAdded = true;
        this.utils.doAnimate(28, "#animate-controller", ".animate-content");
    }

    mounted() {
        this.sharedData.token = this.$route.params.token;
        this.checkcontractViaRest(this.checkcontractServiceUrl);
        
        // Removing mini Form
        this.userData.removeMiniFormFunc(this.userData.epiData.hideElementId);
        
    }

    /**
    * Upload data.
    * 1. Felter valideres
    * 2. Google Recaptcha eksekveres
    * 3. felter/filer bliver mappet over i en FormData og upload service kaldes i vores api
    * 4. api'et henter kontrakten igen hos bytelab og beriger med nye filter/filer (save offer)
    *
    * */
    uploadData() {
        if (this.validateSubmit()) {
            this.uploadViaRest(this.uploadServiceUrl);
        }
    }
}
