import { Component, Vue } from "vue-property-decorator";

import dynamicPage from "../dynamic_page/DynamicPage.vue"
import newsSingle from "../news/news_single"
import newsSingleDynamic from "../news/news_single_dynamic"
import subpage from "../subpage/Subpage.vue"
import template404 from "../NotFound/NotFound"
import templateRouter from "./CustomRouteHandler.vue"

@Component({
    mixins:[templateRouter],
    props: ['cmsGlobalData', 'cmsPageData'],
    components: {
        'not-found' : template404,
        'subpage' : subpage,
        'dynamicPage' : dynamicPage,
        'newsSingle' : newsSingle,
        'newsSingleDynamic' : newsSingleDynamic,
    }
})
export default class CustomRouteHandler extends Vue {
    page: string = null;
    // cmsGlobalData1: string = null;
    // cmsPageData1: string = null;
    created() {
        this.$watch(
            () => this.$props.cmsGlobalData,
            (toParams, previousParams) => {
            //   this.title = this.cmsGlobalData.page_404.title;
            //   this.subTitle = this.cmsGlobalData.page_404.subtitle;
            //   this.listTitle = this.cmsGlobalData.page_404.list_title;
            //   this.links = this.cmsGlobalData.page_404.list;
            // this.cmsGlobalData1 = this.$props.cmsGlobalData;
            }
        );
        this.$watch(
            () => this.$props.cmsPageData,
            (toParams, previousParams) => {
                
                if (this.$props.cmsPageData.page.subpage){
                    this.page = "subpage";
                }else if (this.$props.cmsPageData.page.news_article){
                    if (this.$props.cmsPageData.page.news_article.templates && this.$props.cmsPageData.page.news_article.templates.length>0 && this.$props.cmsPageData.page.news_article.templates[0].name == "Car comparison"){
                        this.page = "news_article_dynamic";
                    }else{
                        this.page = "news_article";
                    }
                }else if (this.$props.cmsPageData.page.dynamic_page){
                    this.page = "dynamic_page";
                }else{
                    this.page = "404";
                }
                
                // console.log("propyy");
                // console.log(this.$props.cmsPageData);
                // this.cmsPageData1 = this.$props.cmsPageData;
            //   this.thisSlug = this.cmsPageData.page.url;
              
              
            }
        );
    }   
}