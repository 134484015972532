import CarList, { filterItem } from "./index";
import { LeasingPrice, VehicleGroup, Vehicle, Make } from '../model/apimodels';

class CarListHelper {

    buildTaxValueFilter(cars: Array<VehicleGroup>, filters: filterItem[]): filterItem[] {
        let filter: filterItem[] = [];

        for (let car of cars) {
            let taxValue = car.price.tax.taxable_value;
            for (let filt of filters) {
                if (taxValue >= filt.range[0] && taxValue < filt.range[1] && filt.selected == false) {
                    filt.selected = true;
                    filter.push({
                        name: filt.name,
                        range: filt.range,
                        order: filt.order,
                        selected: false
                    })
                }

            }
        }

        for (let filt of filters) {
            filt.selected = false;
        }

        filter = filter.sort((a, b) => (a.order > b.order) ? 1 : -1)
        return filter;
    }

    buildMonthlyPriceFilter(cars: Array<VehicleGroup>, filters: filterItem[]): filterItem[] {
        let filter: filterItem[] = [];

        for (let car of cars) {
            let price = car.price.monthly_payment.total;
            for (let filt of filters) {
                if (price >= filt.range[0] && price < filt.range[1] && filt.selected == false) {
                    filt.selected = true;
                    filter.push({
                        name: filt.name,
                        range: filt.range,
                        order: filt.order,
                        selected: false
                    })
                }

            }
        }

        for (let filt of filters) {
            filt.selected = false;
        }

        filter = filter.sort((a, b) => (a.order > b.order) ? 1 : -1)
        return filter;
    }

    buildGeneralFilter(types: string[]): filterItem[] {
        let plates: filterItem[] = [];
        types.forEach(type => {
            plates.push({
                name: type,
                range: [],
                order: 1,
                selected: false
            });
        });

        return plates;
    }

    buildFuelTypeFilter(cars: Array<VehicleGroup>): filterItem[] {
        let filter: filterItem[] = [];
        let types: string[] = [];

        for (let car of cars) {
            let engineTypes = car.engine_types


            for (let engineType of engineTypes) {
                if (!types.includes(engineType) && engineType != null) {
                    types.push(engineType);
                }

            }
        }

        for (let filt of types) {
            filter.push({
                name: filt,
                range: [],
                order: 1,
                selected: false
            })
        }

        return filter;
    }

    buildGearTypeFilter(cars: Array<VehicleGroup>): filterItem[] {
        let filter: filterItem[] = [];
        let types: string[] = [];

        for (let car of cars) {
            let gearTypes = car.transmission_types

            if (gearTypes != null) {
                for (let gearType of gearTypes) {
                    if (!types.includes(gearType) && gearType != null) {
                        types.push(gearType);
                    }
                }
            }

        }

        for (let filt of types) {
            filter.push({
                name: filt,
                range: [],
                order: 1,
                selected: false
            })
        }

        return filter;
    }

    buildCarTypesFilter(cars: Array<VehicleGroup>): filterItem[] {
        let filter: filterItem[] = [];
        let types: string[] = [];

        for (let car of cars) {
            if (!types.includes("Nye biler") && car.new_car === true) {
                types.push("Nye biler");
            }
            if (!types.includes("Releasing") && car.used === true) {
                types.push("Releasing");
            }
        }

        for (let filt of types) {
            filter.push({
                name: filt,
                range: [],
                order: 1,
                selected: false
            })
        }

        return filter.reverse();
    }

    buildBrandFilter(cars: Array<VehicleGroup>): filterItem[] {
        let filter: filterItem[] = [];
        let sortedCars = [...cars].sort((obj1, obj2) => {
            return obj1.make.name.localeCompare(obj2.make.name);
        });

        let curentMake: string;
        let currentCars: filterItem[] = [];

        //grupper biler paa brand f.eks Audi eller BMW
        sortedCars.forEach((obj1: any, index: any) => {
            if (obj1.make.name !== curentMake) {
                curentMake = obj1.make.name;

                let emptyAnyArray: any[] = [];
                let newGroup = {
                    name: curentMake,
                    range: [],
                    order: 1,
                    children: emptyAnyArray,
                    selected: false
                };

                currentCars = newGroup.children;
                filter.push(newGroup);
            }

            currentCars.push(obj1);
        });

        return filter;
    }

}

export default CarListHelper;
