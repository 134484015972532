<template>
  <div class="news-article-list">  
    

    <swiper
      ref="newsSlider"
      :slides-per-view="slidesPerView"
      :space-between="slidesSpacing"
      :loop="false"
      slideVisibleClass = "visible-slide"
      :watchSlidesVisibility = "true"
      :direction = "direction ? direction : 'horizontal'"
      :autoHeight = "false"
      
      :observer = "true"
      :observeParents = "true"

      :navigation="{
        prevEl: '.prev',
        nextEl: '.next',
      }"
      :pagination="{
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }"
      :scrollbar= "{
        el: '.custom-swiper-scrollbar',
        draggable: true,
      }"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="article in articles" :key="article.ID" 
        class="slide"
        :class="{test_2: false}"
      >
        
        <router-link class="item" :to="'/'+newsSlug+'/'+article.post_name" >
          <div class="thumbnail" v-if="article.thumbnail">
            <img :src="article.thumbnail" :alt="article.thumbnail_info ? article.thumbnail_info.alt : ''" @load="onNewsThumbLoad" />
          </div>
          <div class="thumbnail no-image" v-else></div>
          <div class="content">
            <p class="date">{{article.date2}}</p>
            <p class="title">{{article.post_title}}</p>
            <div class="item-tags">
              <p class="tag" v-for="(tag, index) in article.tags" v-if="!tagCount || (index<tagCount)" :key="tag.ID" >{{tag.name}}</p>
            </div>
          </div>
        </router-link>
      </swiper-slide>

      <div class="prev" >
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24.3994 15.5L16.8244 21.626C16.7715 21.6729 16.7292 21.7305 16.7002 21.795C16.6712 21.8594 16.6562 21.9293 16.6562 22C16.6562 22.0707 16.6712 22.1406 16.7002 22.205C16.7292 22.2695 16.7715 22.3271 16.8244 22.374L24.3994 28.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <circle cx="22" cy="22" r="20" stroke="currentColor" stroke-width="2" />
        </svg>
      </div>
      <div class="next" >
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.6006 15.5L27.1756 21.626C27.2285 21.6729 27.2708 21.7305 27.2998 21.795C27.3288 21.8594 27.3438 21.9293 27.3438 22C27.3438 22.0707 27.3288 22.1406 27.2998 22.205C27.2708 22.2695 27.2285 22.3271 27.1756 22.374L19.6006 28.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <circle cx="22" cy="22" r="20" stroke="currentColor" stroke-width="2" />
        </svg>
      </div>
      <div class="swiper-pagination"></div>
      <div class="custom-swiper-scrollbar"></div>
      <div v-if="!noSideGradient" class="mask mask-right" :class="whiteSideGradient? ' white': ''"></div>
      <div v-if="!noSideGradient" class="mask mask-left" :class="whiteSideGradient? ' white': ''"></div>
    </swiper>

  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';
import { Navigation, Pagination, Scrollbar } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

// Import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination, Scrollbar])

export default {
  props: ['articles', 'propNewsSlug', 'styleVersion', 'direction', 'whiteSideGradient', 'noSideGradient', 'tagCount'],
  components: {
    Swiper,
    SwiperSlide,
    Scrollbar
  },
  setup() {
    return {
      modules: [Pagination, Scrollbar]
    }
  },
  data() {
    return {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      newsSlug: "nyheder",
      slidesPerView: 3,
      slidesSpacing: 20,
      thumbnailsLoaded: 0,
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  mounted() {
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
    var th = this;

    if (this.propNewsSlug){
      this.newsSlug = this.propNewsSlug;
    }

    setTimeout(function(){
      th.$nextTick(() => {
        if (th.$refs.newsSlider){
          th.$refs.newsSlider.$el.swiper.update();
        }
      });
    },1000);

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  methods: {
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;

      if (this.direction == "vertical"){
        this.slidesPerView = 2;
        if(this.width< 1000){
          this.slidesPerView = 2;
        }
        if(this.width< 700){
          this.slidesPerView = 2;
        }
      }else{
        this.slidesPerView = 3;
        if(this.width< 1000){
          this.slidesPerView = 2;
        }
        if(this.width< 700){
          this.slidesPerView = 1.2;
        }
      }
      // if ()
    },
    onSwiper (swiper) {
      // console.log(swiper)
    },
    onSlideChange () {
      // console.log('slide change')
    },
    onNewsThumbLoad (){
      // this.thumbnailsLoaded++;
      // if (this.logos){
      //   if (this.thumbnailsLoaded >= this.logos.length){
          var th = this;
          
            th.$refs.newsSlider.$el.swiper.update();
            // th.$refs.partnersSlider.$el.swiper.update();
          
      //   }
      // }
    }
  },
  created() {
   var th = this;
    this.$watch(
      () => this.articles,
      (toParams, previousParams) => {
        th.$nextTick(() => {
          th.$refs.newsSlider.$el.swiper.update();
        });
      }
    );
    this.$watch(
      () => this.direction,
      (toParams, previousParams) => {
        th.getDimensions();
      }
    );
    this.$watch(
      () => this.propNewsSlug,
      (toParams, previousParams) => {
        if (this.propNewsSlug){
          this.newsSlug = this.propNewsSlug;
        }else{
          this.newsSlug = "nyheder";
        }
      }
    );
  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.news-article-list{
  position: relative;
  z-index: 1;
  .slide{
    padding-top: 48px;
    padding-bottom: 48px;
    .item{
      display: block;
      opacity: 0;
      transition: opacity 300ms;
      -webkit-transition: opacity 300ms;
    }
    &.visible-slide{
      .item{
        opacity: 1;
      }
    }
  }
  .item{
    // display: inline-block;
    // vertical-align: top;
    // width: 33.33%;
    // margin-left: 10px;
    // margin-right: 10px;
    text-decoration: none;
    display: block;
    border-radius: 20px;
    transition: all 300ms;
    -webkit-transition: all 300ms;


    .content{
      border-radius: 20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;

      transition: all 300ms;
      -webkit-transition: all 300ms;
    }


    .thumbnail{
      transition: all 300ms;
      -webkit-transition: all 300ms;
      height: 220px;
      width: 100%;
      display: block;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0px 32px 64px -24px rgba(31, 71, 67, 0.32);
      margin-bottom: 26px;

      
      transform: scale(1);
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      &.no-image{
        background: #82724A;
      }
      img{
        transition: all 300ms;
        -webkit-transition: all 300ms;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        object-fit: cover;
        object-position: 50% 50%;
        border-radius: 20px;
      }
    }
    .date{
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 133%;
      color: #1F4743;
      opacity: 0.8;
      margin-bottom: 4px;
    }
    .title{
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #1F4743;
    }

    .item-tags{
      margin-top: 21px;
      .tag{
        margin-top: 8px;
      }
    }

    &:hover{
      background: #FFF;
      .thumbnail{
        box-shadow: none;
        img{
          transform: scale(1.1);
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
        }
      }
      .content{
        box-shadow: 0px 20px 40px -12px rgba(31, 72, 68, 0.16);
      }
    }
    
  }

  .swiper-container{
    // margin-left: 85px;
    // margin-right: 85px;
    position: relative;
    padding-left: 85px;
    padding-right: 85px;
    


    z-index: -1;
    // padding-top: 24px;

    // -webkit-mask-image: -webkit-gradient(linear, 94% 0%, 100% 0%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    .mask{
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: 84px;
      
      display: block;
      z-index: 10;

    }
    .mask-right{
      right: 0px;
      // background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 20%, #FFFFFF 40%, #FFFFFF 100%);
      background: linear-gradient(-90deg,
        rgb(241,249,248) 0%,
        rgba(241,249,248, 0.738) 19%,
        rgba(241,249,248, 0.541) 34%,
        rgba(241,249,248, 0.382) 47%,
        rgba(241,249,248, 0.278) 56.5%,
        rgba(241,249,248, 0.194) 65%,
        rgba(241,249,248, 0.126) 73%,
        rgba(241,249,248, 0.075) 80.2%,
        rgba(241,249,248, 0.042) 86.1%,
        rgba(241,249,248, 0.021) 91%,
        rgba(241,249,248, 0.008) 95.2%,
        rgba(241,249,248, 0.002) 98.2%,
        rgba(241,249,248, 0) 100%
      );

      &.white{
        background: linear-gradient(-90deg,
          rgb(255,255,255) 0%,
          rgba(255,255,255, 0.738) 19%,
          rgba(255,255,255, 0.541) 34%,
          rgba(255,255,255, 0.382) 47%,
          rgba(255,255,255, 0.278) 56.5%,
          rgba(255,255,255, 0.194) 65%,
          rgba(255,255,255, 0.126) 73%,
          rgba(255,255,255, 0.075) 80.2%,
          rgba(255,255,255, 0.042) 86.1%,
          rgba(255,255,255, 0.021) 91%,
          rgba(255,255,255, 0.008) 95.2%,
          rgba(255,255,255, 0.002) 98.2%,
          rgba(255,255,255, 0) 100%
        );
      }
      
    }
    .mask-left{
      left: 0px;
      // background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 20%, #FFFFFF 40%, #FFFFFF 100%);
      background: linear-gradient(90deg,
        rgb(241,249,248) 0%,
        rgba(241,249,248, 0.738) 19%,
        rgba(241,249,248, 0.541) 34%,
        rgba(241,249,248, 0.382) 47%,
        rgba(241,249,248, 0.278) 56.5%,
        rgba(241,249,248, 0.194) 65%,
        rgba(241,249,248, 0.126) 73%,
        rgba(241,249,248, 0.075) 80.2%,
        rgba(241,249,248, 0.042) 86.1%,
        rgba(241,249,248, 0.021) 91%,
        rgba(241,249,248, 0.008) 95.2%,
        rgba(241,249,248, 0.002) 98.2%,
        rgba(241,249,248, 0) 100%
      );

      &.white{
         background: linear-gradient(90deg,
          rgb(255,255,255) 0%,
          rgba(255,255,255, 0.738) 19%,
          rgba(255,255,255, 0.541) 34%,
          rgba(255,255,255, 0.382) 47%,
          rgba(255,255,255, 0.278) 56.5%,
          rgba(255,255,255, 0.194) 65%,
          rgba(255,255,255, 0.126) 73%,
          rgba(255,255,255, 0.075) 80.2%,
          rgba(255,255,255, 0.042) 86.1%,
          rgba(255,255,255, 0.021) 91%,
          rgba(255,255,255, 0.008) 95.2%,
          rgba(255,255,255, 0.002) 98.2%,
          rgba(255,255,255, 0) 100%
        );
      }
      
    }

    .prev,
    .next{
      position: absolute;
      color: #69BDB6;
      top: 50%;
      margin-top: -22px;
      z-index: 20;
      cursor: pointer;
      circle,path{
        transition: all 300ms;
        -webkit-transition: all 300ms;
        stroke: #69BDB6;
      }
      &.swiper-button-disabled{
        
        pointer-events: none;
        circle{
          opacity: 0.12;
        }
        path{
          opacity: 0.24;
        }
      }
      &:hover{

        circle{
          opacity: 1;
          stroke: #1F4743;
        }
        path{
          opacity: 1;
          stroke: #1F4743;
        }
      }
    }
    .prev{
      left: 0px;
    }
    .next{
      right: 0px;
    }

    .custom-swiper-scrollbar{
      display: none;
      box-sizing: border-box;
      transition: all 300ms;
      -webkit-transition: all 300ms;
      margin: 0px;
      height: 2px;
      background: #C7E5E4;
      border-radius: 0px;
      opacity: 1;
      position: relative;
      left: auto;
      right: auto;
      width: 100%;
      margin: 0px;
      
      ::v-deep .swiper-scrollbar-drag{
        background: #4C6C69;
        margin: 0px;
      }
    }

    .swiper-pagination{
      display: none;
      width: 100%;
      box-sizing: border-box;
      margin: 0px;
      top: 0;
      bottom: 0;
    }
    .swiper-pagination ::v-deep .swiper-pagination-bullet{
      transition: all 300ms;
      -webkit-transition: all 300ms;
      flex-grow: 1;
      margin: 0px;
      height: 2px;
      background: #C7E5E4;
      border-radius: 0px;
      opacity: 1;
    }
    .swiper-pagination ::v-deep .swiper-pagination-bullet-active{
      background: #4C6C69;
    }
  }


  .swiper-container-vertical{
    height: 524px;
    width: 670px;
    padding: 0px;
    padding-top: 64px;
    padding-bottom: 64px;
    margin-top: -64px;
    margin-bottom: -64px;
    padding-left: 64px;
    padding-right: 16px;
    margin-right: -16px;
    .slide{
      // height: 396px;
      padding: 0px;
    }
    .item{
      // height: 396px;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;

      .thumbnail{
        margin: 0px;
        height: auto;
        width: 50%;
        width: calc(50% - 10px);
        box-shadow: none;
      }
      .content{
        height: auto;
        width: calc(50% - 10px);
        padding: 0px;
        padding-top: 24px;
      }
      .item-tags{
        // display: none;
        white-space: nowrap;
        overflow: hidden;
      }

      &:hover{
        box-shadow: 0px 10px 22px -9px rgba(31, 72, 68, 0.12);
        border-radius: 20px;

        .content{
          box-shadow: none;
        }
      }
    }
    .prev{
      left: 0px;
      top: 64px;
      margin: 0px;
      right: auto;
      transform: rotate(90deg);
    }
    .next{
      left: 0px;
      top: 122px;
      margin: 0px;
      right: auto;
      transform: rotate(90deg);
    }

    .mask{
      height: 64px;
      width: 100%;
    }
    .mask-left{
      // transform: rotate(90deg);
      top: 0px;
      left: 0px;
      bottom: auto;
      right: auto;
    }
    .mask-right{
      // transform: rotate(90deg);
      top: auto;
      right: auto;
      bottom: 0px;
      left: 0px;
    }
    .mask-right{
      background: linear-gradient(0deg,
        rgb(241,249,248) 0%,
        rgba(241,249,248, 0.738) 19%,
        rgba(241,249,248, 0.541) 34%,
        rgba(241,249,248, 0.382) 47%,
        rgba(241,249,248, 0.278) 56.5%,
        rgba(241,249,248, 0.194) 65%,
        rgba(241,249,248, 0.126) 73%,
        rgba(241,249,248, 0.075) 80.2%,
        rgba(241,249,248, 0.042) 86.1%,
        rgba(241,249,248, 0.021) 91%,
        rgba(241,249,248, 0.008) 95.2%,
        rgba(241,249,248, 0.002) 98.2%,
        rgba(241,249,248, 0) 100%
      );

      &.white{
        background: linear-gradient(0deg,
          rgb(255,255,255) 0%,
          rgba(255,255,255, 0.738) 19%,
          rgba(255,255,255, 0.541) 34%,
          rgba(255,255,255, 0.382) 47%,
          rgba(255,255,255, 0.278) 56.5%,
          rgba(255,255,255, 0.194) 65%,
          rgba(255,255,255, 0.126) 73%,
          rgba(255,255,255, 0.075) 80.2%,
          rgba(255,255,255, 0.042) 86.1%,
          rgba(255,255,255, 0.021) 91%,
          rgba(255,255,255, 0.008) 95.2%,
          rgba(255,255,255, 0.002) 98.2%,
          rgba(255,255,255, 0) 100%
        );
      }
      
    }
    .mask-left{
      background: linear-gradient(180deg,
        rgb(241,249,248) 0%,
        rgba(241,249,248, 0.738) 19%,
        rgba(241,249,248, 0.541) 34%,
        rgba(241,249,248, 0.382) 47%,
        rgba(241,249,248, 0.278) 56.5%,
        rgba(241,249,248, 0.194) 65%,
        rgba(241,249,248, 0.126) 73%,
        rgba(241,249,248, 0.075) 80.2%,
        rgba(241,249,248, 0.042) 86.1%,
        rgba(241,249,248, 0.021) 91%,
        rgba(241,249,248, 0.008) 95.2%,
        rgba(241,249,248, 0.002) 98.2%,
        rgba(241,249,248, 0) 100%
      );

      &.white{
         background: linear-gradient(180deg,
          rgb(255,255,255) 0%,
          rgba(255,255,255, 0.738) 19%,
          rgba(255,255,255, 0.541) 34%,
          rgba(255,255,255, 0.382) 47%,
          rgba(255,255,255, 0.278) 56.5%,
          rgba(255,255,255, 0.194) 65%,
          rgba(255,255,255, 0.126) 73%,
          rgba(255,255,255, 0.075) 80.2%,
          rgba(255,255,255, 0.042) 86.1%,
          rgba(255,255,255, 0.021) 91%,
          rgba(255,255,255, 0.008) 95.2%,
          rgba(255,255,255, 0.002) 98.2%,
          rgba(255,255,255, 0) 100%
        );
      }
      
    }


  }
}

@media (max-width: #{$screen-news-full-width}) {
  .news-article-list{
    .slide{
      padding-top: 24px;
      padding-bottom: 32px;
    }
    .item{
      .content{
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
      }
    }
    .item-tags .tag{
      margin-top: 8px;
    }
    .swiper-container{
      padding-left: 32px;
      padding-right: 32px;
      
      .mask{
        display: none;
      }
      .next, .prev{
        display: none;
      }
      .custom-swiper-scrollbar{
        display: block;
        // margin-left: 32px;
        // margin-right: 32px;
      }
      // .swiper-pagination{
      //   display: flex;
      //   padding-left: 32px;
      //   padding-right: 32px;
      // }
    }
  }
}
@media (max-width: #{$screen-mobile}) {
  .news-article-list{
    .swiper-container{
      padding-left: 20px;
      padding-right: 20px;
    }
    .custom-swiper-scrollbar{
        display: block;
        // margin-left: 20px;
        // margin-right: 20px;
      }
    // .swiper-pagination{
    //     display: flex;
    //     padding-left: 20px;
    //     padding-right: 20px;
    // }
  }
}

</style>