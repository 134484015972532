import { Component, Vue } from 'vue-property-decorator';

import CheckboxField from '../checkbox-field/CheckboxField';
import InputField from '../input-field/input-field';
import SelectField from '../select-field/select-field';
import template from './NewsletterButton.vue';

@Component({
  mixins: [template],
  props: [],
  components: {
    'select-field': SelectField,
    'CheckboxField': CheckboxField,
    'input-field': InputField
  }
})
export default class PrivatErhvervSelectBlock extends Vue {
  get GetCurrentRouteName() {
    return this.$route.name;
  }
}