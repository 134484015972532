import ConfigModel from "./configModel";

export default {
    getName: (state: ConfigModel) => {
        return state.name;
    },
    getBasePath: (state: ConfigModel) => {
        return state.configEpi.basePath;
    },
    getConfigEpi: (state: ConfigModel) => {
        return state.configEpi;
    },
    getBusinessCustomer: (state: ConfigModel) => {
        return state.businessCustomer;
    },
    getLeasingUserPresent: (state: ConfigModel) => {
        return state.userPresent;
    },
    getLeasingProAdminDashboard: (state: ConfigModel) => {
        return state.adminDashboard;
    },
    getInitialCarSearch: (state: ConfigModel) => {
        return state.initialCarSearch;
    },
    getLeasingProAdmin: (state: ConfigModel) => {
        return state.adminData;
    },
    getEpiRouting: (state: ConfigModel) => {
        return state.epiRouting;
    },
    getUsabillaState: (state: ConfigModel) => {
        return state.usabilla;
    },
}
