<template>

  <div class="wrap">
  <section class="text-boxes-block" :class="green_background? 'green-background': ''">
    
      <div class="wrap">
        <div class="block">
          
            
          <h2 class="title h2-1">{{title}}</h2>
          
          <div class="boxes">
            <div v-if="boxes" class="box" v-for="(box, index) in boxes" :key="'text-box-'+index" >
              <div class="box-inner">
                <p class="box-title">{{box.title}}</p>
                <div class="description" v-html="box.text"></div>
              </div>
            </div>
          </div>
          
        
        </div>
      </div>
    
  </section>
  </div>

</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';

export default {
  props: ['block'],
  components: {

  },
  data() {
    return {
      title: null,
      boxes: null,
      green_background: false,
    }
  },

  mounted() {
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  beforeMount(){
    
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();

  },
  methods: {
    prepareContent: function(){
      // console.log(this.block);
      
      this.title = this.block.title.value;
      if(this.block.green_background){
        this.green_background = this.block.green_background.value === "1" ? true : false;
      }
      if (this.block.boxes){
          var boxes = [];
          // console.log(this.block.faq);
          var catCount = this.block.boxes.value;
          var c = 0;
          for (var i=0; i<catCount; i++){
            var title = this.block['boxes_'+i+'_title'].value;
            var text = this.block['boxes_'+i+'_text'].value;
            boxes.push({"title": title, "text": text});
          }
          this.boxes = boxes;
        }
    },
    getDimensions() {
      
    
    },

  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";



.text-boxes-block{
  margin-top: 80px;
  margin-bottom: 80px;

  // padding-top: 80px;
  // padding-bottom: 52px;
  &.green-background{
    padding-top: 80px;
    padding-bottom: 32px;
    background: rgba(105, 189, 182, 0.09);
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: auto;

    max-width: 1264px;
    // padding-left: 32px;
    // padding-right: 32px;
    margin-left: auto;
    margin-right: auto;
  }
  

  .title{
    font-size: 2.5rem;
    width: 30%;
    margin-right: 75px;
  }
  .block{
    display: flex;
  }
  .boxes{
    display: block;
    column-count: 2;
    column-gap: 75px;
    width: 70%;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    .box-inner{
      display: flex;
      flex-direction: column;
      break-inside: avoid-column;
      padding-bottom: 32px;
    }
    .box{
      
      // display: block;
      // width: calc(50% - 37px);
      display: block;

      .box-title{
        margin-bottom: 8px;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        
      }
      .description{
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 135%; /* 21.6px */
        ::v-deep p{
          margin-bottom: 1em;
        }
        ::v-deep a{
          color: #69BDB6;
          font-weight: 700;
          text-decoration-line: underline;
        }
      }
    }
  }


  
  
}

@media (max-width: #{$screen-tablet}) {
  .text-boxes-block{
    &.green-background{
      padding-top: 60px;
      padding-bottom: 0px;
    }
    .block{
      display: block;
    }
    .title{
      font-size: 2rem;
      width: 50%;
      margin: 0px;
      display: block;
      margin-bottom: 48px;
    }
    .boxes{
      width: 100%;
      column-gap: 48px;
    }
  }
}
@media (max-width: #{$screen-mobile}) {
  .text-boxes-block{
    &.green-background{
      padding-top: 48px;
      padding-bottom: 0px;
      // margin-left: 0px;
      // margin-right: 0px;
    
    }
    .title{
      font-size: 2rem;
      margin-bottom: 16px;
      width: 100%;
    }
    .boxes{
      width: 100%;
      column-gap: 48px;
      column-count: 1;
    }
  }
}

</style>