import AbMisc from '../episerver/ab_misc';
import { isNullOrUndefined } from 'is-what';


export default class ConfigEpi {


    readonly apiKey: string = "";
    //recapcha google key
    siteKey: string = "";

    serviceUrl: string = "/leasing-reporting/serviceproxy";
    basePath: string = ""
    mailUrl: string = '/leasing-reporting/profilecheckout/sendMails?email=';
    mailOSSUrl: string = '/leasing-reporting/checkout/sendoss';
    mailOSSNoLogin: string = '/leasing-reportingcheckout/sendossnotloggedin';
    mailCustomerServiceUrl: string = '/leasing-reporting/checkout/sendcustomerservice';
    mailTeamUrl: string = '/leasing-reporting/checkout/sendTeam';
    menuServiceUrl: string = "/leasing-reporting/menu/47867";

    csrfToken: string = "";



    epiServiceUrl: string = "https://www.opendo.dk/webapi";


    errorPageUrl: string = "";

    abMisc = new AbMisc();

    constructor() {
        this.init();
    }

    init() {
        let leasingProComponent = document.querySelector('#webview-leasingpro-component');
        if (leasingProComponent === null) {
            return;
        }

        let basePath = this.abMisc.cleanAttr(leasingProComponent.getAttributeNode('base-path'));
        if (!isNullOrUndefined(basePath) && basePath !== "") {
            basePath = basePath.endsWith('/') ? basePath.slice(0, -1) : basePath;
            this.basePath = basePath;
        }

        //csrfToken
        let _csrfToken = this.abMisc.cleanAttr(leasingProComponent.getAttributeNode('csrf-token'));
        if (!isNullOrUndefined(_csrfToken) && _csrfToken !== "") {
            this.csrfToken = _csrfToken;
        }

        //sitekey
        let _siteKey = this.abMisc.cleanAttr(leasingProComponent.getAttributeNode('recaptcha-site-key'));
        if (!isNullOrUndefined(_siteKey) && _siteKey !== "") {
            this.siteKey = _siteKey;
        }
        //epi service url
        let _epiServiceUrl = this.abMisc.cleanAttr(leasingProComponent.getAttributeNode('epi-service-url'));
        if (!isNullOrUndefined(_epiServiceUrl) && _epiServiceUrl !== "") {
            this.epiServiceUrl = _epiServiceUrl;
        }

        //Fejl siden som kunden bliver redirectet til hvis api-kald fejler efter 3 forsøg
        this.errorPageUrl = this.abMisc.cleanAttr(leasingProComponent.getAttributeNode('error-page-url'));
    }

}
