import anime from 'animejs';

class AbAmount {

    private objFormat: any = { locale: 'da', maxDigits: 0 };

    public format(value: number, forceRoundUp: boolean = false) {
        if (value === null) {
            return 0;
        }

        if (forceRoundUp) {
            value = Math.ceil(value);
        }

        const formattedValue = value.toLocaleString(
            this.objFormat.locale,
            { maximumFractionDigits: this.objFormat.maxDigits },
        );
        return formattedValue;
    };

    public formatRoundInt(value: number) {
        if (value === null) {
            return 0;
        }

        value = Math.round(value);

        const formattedValue = value.toLocaleString(
            this.objFormat.locale,
            { maximumFractionDigits: 0},
        );
        return formattedValue;
    };

    public format2(value: number) {
        if (value === null) {
            return 0;
        }
        let valueToLocale = value.toLocaleString(this.objFormat.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return valueToLocale;
    }

    public formatDecimals(value: number, minDecimals: number, maxDecimals: number) {
        if (value === null) {
            return 0;
        }
        let valueToLocale = value.toLocaleString(this.objFormat.locale, { minimumFractionDigits: minDecimals, maximumFractionDigits: maxDecimals });
        return valueToLocale;
    }

    public animateNumberCount(elmId: string, nStart: number, nEnd: number, formatBool: boolean, forceRoundUp: boolean = false) {
        let self = this;
        let priceAniObject = {
            nStart
        };

        anime({
            targets: priceAniObject,
            nStart: nEnd,
            easing: 'easeInOutQuad',
            duration: 700,
            round: 1,
            update: function () {
                let el = document.getElementById(elmId);
                formatBool ? el.innerHTML = self.format(priceAniObject.nStart, forceRoundUp).toString() : el.innerHTML = priceAniObject.nStart.toString();
            }
        });
    }

}

export default AbAmount;
