<template>
   <section class="section fade-on-enter news-section">
      <div class="wrap">
        <h2 class="h2-1">{{title}}</h2>
        <news-slider :articles="articles" :propNewsSlug="newsSlug"  />
        <div class="button-wrap">
          <router-link class="button" :to="'/'+newsSlug">
            {{button}}
          </router-link>
        </div>
      </div>
    </section>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';

import { Navigation, Pagination } from 'swiper'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

// Import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination])

export default {
  props: ['articles', 'title', 'button', 'propNewsSlug'],
  
  data() {
    return {
      newsSlug: "nyheder",
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  mounted() {
    if (this.propNewsSlug){
      this.newsSlug = this.propNewsSlug;
    }
  },
  beforeDestroy() {
  
  },
  methods: {
  
  },
  created() {
    this.$watch(
      () => this.propNewsSlug,
      (toParams, previousParams) => {
        if (this.propNewsSlug){
          this.newsSlug = this.propNewsSlug;
        }else{
          this.newsSlug = "nyheder";
        }
      }
    );
   
  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.news-section{
  .h2-1{
    text-align: center;
    margin-bottom: 22px;
  }
  .button-wrap{
    text-align: center;
    margin-top: 22px;
  }
  .wrap{

  }
}

@media (max-width: #{$screen-news-full-width}) {
  .news-section{
    .wrap{
      padding-left: 0px;
      padding-right: 0px;
    }
    .button-wrap{
      margin-top:40px;
    }
    .h2-1{
      margin-bottom: 0px;
    }
  }
}

</style>