import { Component, Vue, Watch } from 'vue-property-decorator';

import AskPopup from '../../views/ask_popup/ask_popup';
import ComponentCookieDisclaimer from './cookie-disclaimer-component/cookie-disclaimer';
import ComponentFooter from './footer-component/footer';
import ComponentHeader from './header-component/header';
import HeaderBanner from './header-banner/header-banner';
import NewsletterButton from '../ui/newsletter-buttton/newsletter-buttton';
import template from './Mainview.vue';

@Component({
  mixins: [template],
  components: {
    'component-header': ComponentHeader,
    'component-footer': ComponentFooter,
    'component-cookie-disclaimer': ComponentCookieDisclaimer,
    'component-ask-popup': AskPopup,
    'newsletter-button': NewsletterButton,
    'header-banner': HeaderBanner,
  },
})

export default class Mainview extends Vue {

}
