<template>
  <div class="">

    <section class="section fade-on-enter newbanking-section">
      <div class="wrap">
        <div class="flex my-flex-row my-flex-nowrap flex-s3">
          <div class="side">
            <div class="title-content">
                <h1 class="h1-1 title">{{title}}</h1>
            </div>

            <div id="nb-process-app-container"></div>

            <div class="content">
              <div class="textual-content" v-html="content"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="faqList" class="section fade-on-enter faq-list-section">
      <div class="wrap">
        <h2 class="section-title">{{faqTitle}}</h2>
        <faq-list :items="faqList"/>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      customerId: null,
      processId: null,
      title: "",
      content: "",
      faqTitle: "",
      faqList: [],
    }
  },
  beforeMount(){
    
  },
  created(){
    
    this.$watch(
        () => this.cmsGlobalData,
        (toParams, previousParams) => {
          // this.buttons = this.cmsGlobalData.footer.buttons;
        }
    );
    this.$watch(
        () => this.cmsPageData,
        (toParams, previousParams) => {
          
          this.title = this.cmsPageData.page.newbanking.hero.title;
          this.content = this.cmsPageData.page.newbanking.hero.content;
          this.faqTitle = this.cmsPageData.page.newbanking.faq_title;
          
          var faqList = this.cmsPageData.page.newbanking.faq;

          this.faqList = [];
          for(var i=0; i<faqList.length; i++){
            this.faqList.push({"ID": i+1, "post_title": faqList[i].title, "content": faqList[i].content});
          }
          
          // this.buttons = this.cmsGlobalData.footer.buttons;
        }
    );

    const scpt = document.createElement('script');
    this.customerId = "3403dcc8-c015-4842-9f8a-70569b2ea6d6";
    this.processId = "98101be1-0aec-4445-8fd3-abe046fc6388";
    this.languageCode = "da-DK";

    scpt.innerText = "(function(i,s,o,g,r,a,m){i['NewBanking']=r;i[r]=i[r]||function(){ (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o), m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode. insertBefore(a,m)})(window, document, 'script', 'https://newbanking.com/process/static/loader/v1.js', 'nbloader'); nbloader('create', 'nbwidget', { 'grantProcessId': '" + this.processId + "', 'languageCode': '" + this.languageCode + "', 'customerId': '" + this.customerId + "'});";
    document.head.prepend(scpt);
  },
  mounted(){
    
  },
  methods: {

  },
 
}
</script>



<style lang="scss" scoped>
@import "~@/style.scss";


.newbanking-section{
  margin-top: 60px;
  padding-bottom: 120px;
  .side{
    margin-left: 20px;
    .title{
      margin-bottom: 48px;
    }
    .content{
      max-width: 589px;
      margin-left: auto;
      margin-right: auto;
    }
    #nb-process-app-container {
      width: 100vw;
      max-width: 1200px;
      height: 100vh;
      max-height: 700px;
    }
  }
}

.faq-list-section{
  background: rgba(105, 189, 182, 0.09);
  border-radius: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-top: 96px;
  padding-bottom: 96px;
  .section-title{
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #1F4743;
    margin-bottom: 60px;
  }
  .button-wrap{
    margin-top: 80px;
    text-align: center;
  }
}


@media (max-width: 1000px) {
  .newbanking-section{
    text-align: center;
    .flex{
      display: block;
    }
    .image{
      display: inline-block;
      margin: 0px;
      margin-bottom: 48px;
    } 
    .side{
      text-align: left;
      display: block;

      #nb-process-app-container {
        width: calc(100vw - 100px);
      }
    }
  }
}

@media (max-width: 640px) {
  .newbanking-section {
    .side {
      #nb-process-app-container {
        width: calc(100vw - 60px);
      }
    }
  }
}
</style>
