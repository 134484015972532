<template>

  
  <section class="faq-block">
      <div class="block">
        <div class="wrap">
          <h2 class="title h2-1">{{title}}</h2>
          <div>
            <!-- <a href="#" v-on:click="faqButtonClick(index,$event)" v-for="(faq, index) in faqs" :key="'faq-category-'+Math.round(Math.random()*2000)+'-'+index">{{faq.title}}</a> -->
            
          </div>
          <faq-list :items="faqs" :tags="tags"/>
          <div class="button-wrap" v-if="button && button.url">
            <a class="button" :href="button.url" :target="button.target">{{button.title}}</a>
          </div>
        </div>
      </div>
    
  </section>

</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';
import FAQList from "../../../components/ui/faq-list/faq-list";

export default {
  props: ['block'],
  components: {
    "faq-list" : FAQList
  },
  data() {
    return {
      title: null,
      // currentCat: 0,
      tags: null,
      faqs: [{"faqs":[]}],
      button: {"url": null, "title": null, "target": null},
    }
  },

  mounted() {
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  beforeMount(){
    
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();

  },
  methods: {
    prepareContent: function(){
      this.title = this.block.title.value;
      this.button = this.block.button.value;

      var faqs = [];
      var tags = [];
      if (this.block.faq){
        // console.log(this.block.faq);
        var catCount = this.block.faq.value;
        var c = 0;
        for (var i=0; i<catCount; i++){
          var categoryTitle = this.block['faq_'+i+'_title'].value;
          var questionCount = this.block['faq_'+i+'_questions'].value;

          tags.push({"term_id": i+1, "name": categoryTitle});

          // faqs[i] = {"faqs": [], "title": categoryTitle};
          for (var j=0; j<questionCount; j++){
            c++;
            var questionTitle = this.block['faq_'+i+'_questions_'+j+'_title'].value;
            var questionContent = this.block['faq_'+i+'_questions_'+j+'_content'].value;
            // console.log(questionTitle+" - "+questionContent);  

            faqs.push({'ID': c, 'post_title': questionTitle, 'content': questionContent, 'tags': [{'term_id': i+1}]});
          }
          
        }
      }
      this.tags = tags;
      this.faqs = faqs;
      // this.content = this.block.content.value;
      // this.image = this.block.image.value.sizes.large.url;
      // this.icon = this.block.icon.value.sizes.large.url;
      // this.button = this.block.button.value;
      // console.log(this.block.image.value);
      // this.description = this.block.description.value;

      // this.button = this.block.button.value;
      // this.articles = this.block.news.list;
    },
    // faqButtonClick(index, e){
    //   e.preventDefault();
    //   this.currentCat = index;
    // },
    getDimensions() {
      // this.width = document.documentElement.clientWidth;
      // this.height = document.documentElement.clientHeight;

      
      // this.newsDirection = 'vertical';
      // if(this.width< 1000){
      //   this.newsDirection = 'horizontal';
      // }
    
    },
    onTaxTabClick(e){
      e.preventDefault();
      this.currentTab = "tax";
    },
    onCarTabClick(e){
      e.preventDefault();
      this.currentTab = "car";
    }

  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.faq-block{
  margin-top: 124px;
  margin-bottom: 20px;

  .block{
    background: rgba(105, 189, 182, 0.09);
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 96px;
    padding-bottom: 96px;
  }
  .title{
    margin-bottom: 60px;
    text-align: center;
  }
  .button-wrap{
    margin-top: 72px;
    text-align: center;
  }
}




@media (max-width: #{$screen-tablet}) {
  .faq-block{
    margin-top: 96px;
    margin-bottom: 20px;
    .block{
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }
}
@media (max-width: #{$screen-mobile}) {
  .faq-block{
    margin-top: 112px;
    margin-bottom: 20px;
    .block{
      padding-top: 60px;
      padding-bottom: 83px;
    }
  }
}

</style>