import { UPDATE_CONTEXT } from "@/components/leasingpro/episerver/store/modules/epiContext";
import { UPDATE_MODEL_BY_CONTENT_LINK } from '@/components/leasingpro/episerver/store/modules/epiContent';
import { Store } from "vuex";

function setContext(store: Store<any>) {
    // The `epiReady` event only has `isEditable`, but the epi object has both.
    const context = {
        inEditMode: window.epi.inEditMode,
        isEditable: window.epi.isEditable
    };

    // Make the context available to all Vue components.
    store.commit(UPDATE_CONTEXT, context);

    // If we're in an editable context we want to update the model on every change by the editor
    if (window.epi.isEditable) {
        window.epi.subscribe('contentSaved', message => {
            store.dispatch(UPDATE_MODEL_BY_CONTENT_LINK, message.contentLink);
        });
    }
}

function enablePageEditorSupport(store: Store<any>) {
    // Listen to the `epiReady` event to update the `context` property.
    window.addEventListener('load', () => {
        // Expect `epi` to be there after the `load` event. If it's not then we're
        // not in any editing context.

        if (!window.epi) {
            return;
        }

        // Check for epi and that ready is an actual true value (not just truthy).
        if (window.epi.ready === true) {
            // `epiReady` already fired.
            setContext(store);

            // The subscribe method won't be available in View mode.
        } else if (window.epi.subscribe) {
            // Subscribe if the `epiReady` event hasn't happened yet.
            window.epi.subscribe('epiReady', () => setContext(store));
        }
    });
}

interface WindowEpi {
    inEditMode: boolean;
    isEditable: boolean;
    ready: boolean;
    subscribe: any;
}
declare global {
    export interface Window { epi: WindowEpi; }
}

export default {
    /** enables pageEditor support when a page is open in the editor */
    enablePageEditorSupport
}
