<template>

  <div>
    <div class="tags-list" v-if="tags">
      <a :class="'tag transition-all '+(tag.term_id == selectedTagID ? 'selected' : '')" v-for="tag in tags" :key="tag.term_id" v-on:click="selectTag(tag.term_id)">{{tag.name}}</a>
    </div>
    <div class="faq-list">
      <div :class="'faq-item transition-all '+'faq-item-'+item.ID+' '+(selectedItemsIDs.includes(item.ID) ? 'open' : '' )" v-for="(item, index) in filteredList" :data-id="item.ID" :key="'faq-item-'+item.ID" v-on:click="toggleItem(item.ID)" >
        <span class="title">{{item.post_title}}</span>
        <span class="open-icon">
          <span class="l1"></span><span class="l2"></span>
        </span>
        <div class="content" >
          <div class="inner" v-html="item.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';

export default {
  props: ['items', 'tags'],
  data() {
    return {
      selectedTagID: 0,
      filteredList: [],
      selectedItemsIDs: []
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  mounted(){
    this.setSelectedTag();
    this.filterList();
  },
  methods: {
    selectTag: function(tag_id){
      this.selectedItemsIDs = Array();
      this.selectedTagID = tag_id;
    },
    toggleItem: function(id){
      

      if (this.selectedItemsIDs.includes(id)){
        var index = this.selectedItemsIDs.indexOf(id);
        if (index !== -1) {
          var th = this;
          
          var element = document.querySelector('.faq-item-'+id+' .content');
          var h = element.clientHeight;
          gsap.to(element, { duration: 0.3, height: 0, opacity: 0, onComplete: function(){
            this._targets[0].style.height = '';
            var id2 = this._targets[0].closest(".faq-item").getAttribute("data-id")*1;
            index = th.selectedItemsIDs.indexOf(id2);
            if (index !== -1) {
              th.selectedItemsIDs.splice(index, 1);
            }
          }});

        }
      }else{
        this.selectedItemsIDs.push(id);  

        this.$nextTick(() => {
            var element = document.querySelector('.faq-item-'+id+' .content');
            var h = element.clientHeight;
            gsap.to(element, { duration: 0, height: 0, opacity: 0});
            gsap.to(element, { duration: 0.3, height: h, opacity: 1, onComplete: function(){
              this._targets[0].style.height = '';
            }});
        });
            

      }
      
    },
    setSelectedTag: function(tag_id){

      if (tag_id){
        this.selectedTagID = tag_id;
      }else{
         if (!this.selectedTagID){
          if (this.tags && this.tags[0]){
            this.selectedTagID = this.tags[0].term_id;
          }
         }
      }
    },
    filterList: function(){
      var list = [];

      for(var i=0; i<this.items.length; i++){
        var item = this.items[i];
        if (this.tags){
          if (!this.selectedTagID){
            list.push(item);
          }else{
            for(var j=0; j<item.tags.length; j++){
              if (item.tags[j].term_id == this.selectedTagID){
                list.push(item);
                break;
              }
            }     
          } 
        }else{
          list.push(item);
        }
      }
      this.filteredList = list;

      
    }
  },
  created() {
    var th = this;
    this.$watch(
      () => this.tags,
      (toParams, previousParams) => {
        this.setSelectedTag();
      }
    );
    this.$watch(
      () => this.items,
      (toParams, previousParams) => {
        this.filterList();
      }
    );
    this.$watch(
      () => this.selectedTagID,
      (toParams, previousParams) => {
        this.filterList();
      }
    );  

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


.tags-list{
  text-align: center;
  margin-bottom: 40px;
  
  .tag{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    color: #1F4743;
    display: inline-block;
    border: 1px solid #C7E5E4;
    box-sizing: border-box;
    border-radius: 40px;
    padding: 5px 16px 8px 16px;
    cursor: pointer;
    margin-left: 4px;
    margin-right: 4px;
    &.selected{
      color: #FFF;
      background-color: #69BDB6;
      border: 1px solid #69BDB6;
    }
    &:hover{
      color: #FFF;
      background-color: #69BDB6;
      border: 1px solid #69BDB6;
    }

  }
}

.faq-list{
  max-width: 794px;
  margin-right: auto;
  margin-left: auto;
  .faq-item{
    position: relative;
    background: #FFFFFF;
    border: 1px solid #69BDB6;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 24px;
    padding-top: 20px;
    padding-bottom: 25px;
    padding-right: 48px;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    color: #1F4743;
    margin-top: 12px;
    
    cursor: pointer;
    
    &:first-child{
      margin-top: 0px;
    }

    &:hover{
      background: #E1F2F0;
    }

    .open-icon{
      position: absolute;
      top: 50%;
      right: 24px;
      margin-top: -10px;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      border: 2px solid #69BDB6;
      box-sizing: border-box;
      .l1, .l2{
        display: block;
        position: absolute;
        width: 10px;
        height: 2px;
        background: #69BDB6;
        top: 50%;
        left: 50%;
        margin-top: -1px;
        margin-left: -5px;

        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
      .l1{
        
      }
      .l2{
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
      }
    }

    .content{
      // -webkit-transition: all 0.3s ease;
      // -moz-transition: all 0.3s ease;
      // -o-transition: all 0.3s ease;
      // -ms-transition: all 0.3s ease;
      // transition: all 0.3s ease;


      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      color: #1F4743;

      margin-top: 0px;
      overflow: hidden;
      max-height: 0px;

      .inner{
        margin-top: 13px;

        ::v-deep p{
          margin-top: 12px;
        }
        ::v-deep ul{
          list-style: disc;
          li{
            margin: 12px;
            margin-left: 24px;
          }
        }
        ::v-deep ol{

          li{

          }
        }
        ::v-deep strong{
          font-weight: bold;
        }
        ::v-deep i{
          font-style: italic;
        }
      }
    }

    &.open{
      .content{
        max-height: 1000px;
      }
      .open-icon{
        .l2{
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
        }
      }
    }

    
  }
}

</style>