import { render, staticRenderFns } from "./BankloginAuth.vue?vue&type=template&id=8c78d48a&scoped=true"
import script from "./BankloginAuth.vue?vue&type=script&lang=js"
export * from "./BankloginAuth.vue?vue&type=script&lang=js"
import style0 from "./BankloginAuth.vue?vue&type=style&index=0&id=8c78d48a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c78d48a",
  null
  
)

export default component.exports