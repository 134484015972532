import Vue from "vue";
import EpiProperty from "@/components/leasingpro/sharedcomponents/epiComponents/infrastructure/epiProperty/EpiProperty.vue";


Vue.component("LeasingProServicePage", () =>
    import(
        /* webpackChunkName: "LeasingProServicePage" */ "@/components/leasingpro/sharedcomponents/epiComponents/pages/leasingProServicePage/LeasingProServicePage.vue"
    )
);
Vue.component("GenericContent", () =>
    import(
        /* webpackChunkName: "GenericBlock" */ "@/components/leasingpro/sharedcomponents/epiComponents/blocks/genericContent/GenericContent.vue"
    )
);

Vue.component("EpiProperty", EpiProperty);

export function registerEpiEditDirective(store) {
    const toggleEditAttributes = (el, binding, vnode) => {
        const siteIsEditable = store.state.epiContext.isEditable;
        const componentIsEditable = !vnode.context.epiDisableEditing;

        if (siteIsEditable && componentIsEditable) {
            el.setAttribute("data-epi-edit", binding.value);
        } else {
            el.removeAttribute("data-epi-edit");
        }
    };
    Vue.directive("epi-edit", {
        bind: toggleEditAttributes,
        update: toggleEditAttributes
    });
}
