import Vue from 'vue';

export default {
    setName({ commit }: { commit: any }, name: string) {
        commit("SET_NAME", name);
    },
    setUserPresent({ commit }: { commit: any }, userPresent: boolean) {
        commit('SET_LEASINGPRO_USER_PRESENT', userPresent);
    },
    setLeasingProAdminDashboard({ commit }: { commit: any }, adminDashboard: boolean) {
        commit('SET_LEASINGPRO_ADMIN_DASHBOARD', adminDashboard);
    },
    setInitialCarSearch({ commit }: { commit: any }, setInitialCarSearch: string) {
        commit('SET_LEASINGPRO_INITIAL_CARSEARCH', setInitialCarSearch);
    },
    setLeasingProAdmin({ commit }: { commit: any }, adminData: {}) {
        commit('SET_LEASINGPRO_ADMIN', adminData);
    },

    setEpiRouting({ commit }: { commit: any }, epiRouting: []) {
        commit("SET_EPI_ROUTING", epiRouting);
    },

    setUsabillaCount({ commit }: { commit: any }) {
        commit("SET_USABILLA_COUNT");
    },
    setUsabillaMounted({ commit }: { commit: any }) {
        commit("SET_USABILLA_MOUNTED");
    },
};
