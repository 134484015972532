import { Component, Vue } from "vue-property-decorator";

import HomepageHero from '../../components/ui/homepage-hero/homepage-hero';
import NewsSection from '../../components/ui/news-section/news-section';
import ParntersSlider from '../../components/ui/partners-slider/partners-slider';
import PrivatErhvervSelectBlock from '../../components/ui/privat-erhverv-select-block/privat-erhverv-select-block';
import template from "./LandingPage.vue"

@Component({
    mixins:[template],
    props: ['cmsGlobalData', 'cmsPageData'],
    components: {
        'privat-erhverv-select-block': PrivatErhvervSelectBlock,
        'homepage-hero': HomepageHero,
        'news-section': NewsSection,
        'partners-slider': ParntersSlider
    },
})
export default class LandingPage extends Vue {
}