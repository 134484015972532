<template>
  <div class="jobs-archive-page">
    <div class="page-content">
      <div class="wrap">
        <h1>Jobs - {{thisSlug}}</h1>
      </div>
      <div class="wrap">
        <div class="jobs-list">
          
          <router-link class="item" :to="thisSlug+'/'+article.post_name" v-for="article in articles" :key="article.ID" >{{article.post_title}}</router-link>
        </div>
      </div>
      <span v-if="cmsPageData && !cmsPageData.loaded"></span>
    </div>
  </div>
  
</template>


<script>
export default {
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      thisSlug: "",
      articles: null
    }
  },
  beforeMount(){
    // alert(footerData.fields.copyright);
    // this.copyright = "b";
  },
  created(){
    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {
        this.articles = this.cmsPageData.page.jobs_list;
        this.thisSlug = this.cmsPageData.page.url;
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
  },
  mounted(){
    
  },
  methods: {

  },
 
}
</script>

<style lang="scss" scoped>
@import "~@/style.scss";

.jobs-list{
  .item{
    display: block;
  }
}

</style>
