<template>
  <div class="slider-input">
    <div class="top-side" style="display: flex; align-items: center;">
      <div class="title-wrap">
        <div class="title">{{title}}</div><info-button v-if="info" :info="info"/>
      </div>
      <p class="res"><input ref="inputField" type="text" class="value" v-model="formatedValue" /><span class="suffix">kr.</span></p>
    </div>
    <div class="slider" ref="slider" >
      <span class="line">
        <span class="filled-line" :style="'width: '+percents+'%'">
          <span class="dot" ref="dot" ></span>
        </span>
      </span>
      
    </div>
  </div>
</template>

<script>


import InfoButton from '../../info-button/InfoButton.vue';
import AbAmount from '../../../leasing/Showroom/src/common/ab_amount';

export default {
  props: ['initValue', 'initFrom', 'initTo', 'title', 'info', 'step'],
  components: {
    // 'news-slider' : NewsSlider
    'info-button' : InfoButton
  },
  data() {
    return {
      // title: null,
      description: null,
      selectedDot: false,
      from: 0,
      to: 1000000,
      offsetX: 0,
      percents: 20,
      value: 0,
      formatedValue: 0,
      abAmount: null,
      // info: "a"
    }
  },

  
  mounted() {
    this.prepareContent();
    
    
    
    this.$refs.slider.addEventListener("mousedown", this.onMouseDownDot);
    this.$refs.slider.addEventListener("touchstart", this.onMouseDownDot);
    

    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("touchmove", this.onMouseMove);
    window.addEventListener("mouseup", this.onMouseUp);
    window.addEventListener("touchend", this.onMouseUp);
    window.addEventListener("resize", this.onWindowResize);  

    this.onWindowResize();


    if (this.initValue){
      this.value = this.initValue;
    }
    if (this.initFrom){
      this.from = this.initFrom;
    }
    if (this.initTo){
      this.to = this.initTo;
    }

    var percents = (this.value-this.from) / (this.to-this.from);
    this.percents = percents*100;
  },
  beforeDestroy() {
    this.$refs.slider.removeEventListener("mousedown", this.onMouseDownDot);
    this.$refs.slider.removeEventListener("touchstart", this.onMouseDownDot);

    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("touchmove", this.onMouseMove);
    window.removeEventListener("mouseup", this.onMouseUp);
    window.removeEventListener("touchend", this.onMouseUp);
    window.removeEventListener("resize", this.onWindowResize);
  },
  beforeMount(){
    
  },
  
  methods: {
    prepareContent: function(){
      // this.title = this.block.title.value;
      // this.description = this.block.description.value;

    },
    onWindowResize() {
      // this.width = document.documentElement.clientWidth;
      // this.height = document.documentElement.clientHeight;

      
      // this.newsDirection = 'vertical';
      // if(this.width< 1000){
      //   this.newsDirection = 'horizontal';
      // }

      this.$nextTick(function(){
          
        this.$refs.inputField.style.width = 0+"px";
        var a = this.$refs.inputField.scrollWidth;
        // if (a<33){
        //   a = 33;
        // }
        // a = a+4;
        this.$refs.inputField.style.width = a+"px";
        // console.log(a + "-"+this.value+"-");

        
      });
    
    },
    formatPrice(value) {
        if (value === undefined) {
            return "0";
        }

        // return value.toFixed(2);
        var cents = Math.round(value%1*100);
        var sCents = "";
        if (cents>0 && cents<10) { sCents = ",0"+cents; }
        if (cents>=10) { sCents = ","+cents; }
        
        return this.abAmount.format(value)+"";
    },
    onMouseMove(e){
      if (this.selectedDot){
        this.calculate(e);
      }
    },
    onMouseUp(e){
      this.selectedDot = false;
    },
    calculate(e){
      
      this.offsetX = this.getMouseXFromEvent(e);
      var percents = ((this.offsetX - this.$refs.slider.offsetLeft)/this.$refs.slider.offsetWidth);
      if (percents>1) percents = 1;
      if (percents<0) percents = 0;
      this.percents = percents*100;
      var val = (this.to-this.from) * percents;
      val = val + this.from;

      if (this.step){
        val = val/this.step;
        val = Math.round(val) * this.step;
        // console.log(val+" "+this.step);
      }


      this.value = val;

      this.$emit("changed", this.value);
    },
    onMouseDownDot(e){
      e.preventDefault();
      e.stopPropagation();

      this.selectedDot = true;
      this.calculate(e);
     
    },
    getMouseXFromEvent: function(e){
      var touch = null;
      if (e.originalEvent && e.originalEvent.touches){
        touch = e.originalEvent.touches[0];
      }
      
      if (touch && touch.pageX){
        return touch.pageX;
      }
      return e.pageX;

    }

  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );
    this.$watch(
      () => this.initValue,
      (toParams, previousParams) => {
        this.value = this.initValue;
      }
    );
    this.$watch(
      () => this.initTo,
      (toParams, previousParams) => {
        this.to = this.initTo;
      }
    );
    this.$watch(
      () => this.initFrom,
      (toParams, previousParams) => {
        this.from = this.initFrom;
      }
    );
    this.$watch(
      () => this.value,
      (toParams, previousParams) => {

        if (!this.value || this.value<0){
          this.value = 0;
          this.formatedValue = 0;
          return;
        }
        this.value = parseInt(this.value);
        if (this.value>this.to){
          this.value = this.to;
          this.formatedValue = this.formatPrice(this.value);
          return;
        }

        
        this.formatedValue = this.formatPrice(this.value);

        var percents = (this.value - this.from) / (this.to-this.from);
        this.percents = percents*100;
        
      }
    );

    this.$watch(
      () => this.formatedValue,
      (toParams, previousParams) => {
        var v = this.formatedValue+"";
        v = v.replace(/\./g, "");
        this.value = parseFloat(v);


        this.$nextTick(function(){
          
          this.$refs.inputField.style.width = 0+"px";
          var a = this.$refs.inputField.scrollWidth;
          // if (a<33){
          //   a = 33;
          // }
          // a = a+4;
          this.$refs.inputField.style.width = a+"px";
          // console.log(a + "-"+this.value+"-");

          
        });


        this.$emit("changed", this.value);

      }
    );

    this.abAmount = new AbAmount();

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.slider-input{
  .top-side{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  .title-wrap{
    width: 60%;
  }
  .title{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 133%;
    color: #1F4743;
    display: inline;
    vertical-align: top;
  }
  .info-button{
    margin-left: 8px;
    top: 5px;
    vertical-align: top;
    display: inline;
  }
  .value{
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    //line-height: 130%;
    color: #4C6C69;
    padding-right: 4px;
    margin-bottom: 0;
    background: transparent;
    // width: 100%;
    width: calc(100% - 20px);
    border: none;
    apperance: none;
    -webkit-apperance: none;
    display: inline-block;
    text-align: right;
    // box-sizing: border-box;
    box-sizing: content-box;
  }
  .res{
    text-align: right;
    width: 40%;
    white-space: nowrap;
  }
  .suffix{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    
    color: #4C6C69;
  }
  .slider{
    position: relative;
    height: 22px;
    widows: 100%;
    cursor: pointer;
    .line{
      position: relative;
      display: block;
      height: 4px;
      top: 9px;
      width: 100%;
      background: #E8F5F4;
      border-radius: 4px;
    }
    .filled-line{
      position: absolute;
      display: block;
      top: 0px;
      left: 0px;
      height: 4px;
      width: 50%;
      background: #69BDB6;
      border-radius: 4px;
    }
    .dot{
      width: 22px;
      height: 22px;
      border-radius: 11px;
      margin-top: -9px;
      margin-right: -11px;
      top: 0px;
      right: 0px;
      display: block;
      position: absolute;
      background: #69BDB6;
      border: 5px solid #FFFFFF;
      box-shadow: 0px 1px 4px rgba(31, 72, 68, 0.3);
      cursor: pointer;
    }
  }
}
</style>