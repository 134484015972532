import {
    IContent,
    ContentReference
} from "@/components/leasingpro/definitions/episerver/content";
import api from "@/components/leasingpro/episerver/api/contentDeliveryApi";
import { ActionContext } from "vuex";
import 'url-search-params-polyfill';


//actions for the epiDataModel module
export const UPDATE_MODEL_BY_URL = "epiContent/UPDATE_CONTENT_BY_URL";
export const UPDATE_MODEL_BY_CONTENT_LINK =
    "epiContent/UPDATE_CONTENT_BY_CONTENT_LINK";
const MODEL_LOADING = "epiContent/MODEL_LOADING";


export interface EpiContentStateContainer {
    epiContent: EpiContentState;
}

export interface EpiContentState {
    modelLoaded: boolean;
    model: IContent;
    modelLanguage: string;
}

const state = (): EpiContentState => ({
    modelLoaded: false,
    model: null,
    modelLanguage: null
});

const UPDATE_CONTENT = "epiContent/UPDATE_CONTENT";
const mutations = {
    [UPDATE_CONTENT](state: EpiContentState, newModel: IContent) {
        state.model = newModel;
        state.modelLoaded = true;
        // state.modelLanguage = newModel.language.name;
    },
    [MODEL_LOADING](state: EpiContentState) {
        state.modelLoaded = false;
    }
};

const parameters = {
    expand: "*",
    html: true
};

const getQueryParams = () => {
    const urlQuery = new URLSearchParams(window.location.search);

    const queries = {};
    urlQuery.forEach((val, key) => {
        queries[key] = val;
    });

    return queries;
};

const convertContentLinkToString = (contentLink: ContentReference) => {
    let id = contentLink.id.toString();

    if (contentLink.workId && contentLink.workId > 0) {
        id = `${id}_${contentLink.workId}`;
    }

    return id;
};

const actions = {
    async [UPDATE_MODEL_BY_URL](
        { commit },
        friendlyUrl: string
    ): Promise<IContent> {
        /**
         * When updating a model by friendly URL we assume that the friendly URL
         * contains every querystring parameter that we might need on the server.
         */
        commit(MODEL_LOADING);
        let content = await api.getMockData(
            friendlyUrl,
            parameters
        );
        commit(UPDATE_CONTENT, content);

        return content;
    },
    async [UPDATE_MODEL_BY_CONTENT_LINK](
        { commit, rootState, state }: ActionContext<EpiContentState, any>,
        contentLink: string
    ): Promise<IContent> {
        /**
         * Updating a model by content link is done when something is being
         * edited and when viewing a block. In order to be sure that we get the
         * correct model, we need to keep any previously existing query string
         * from the friendly URL.
         *
         * See the implementation of ExtendedContentModelMapper.GetContextMode
         * for additional details.
         */
        const route = rootState.route || { query: getQueryParams() };
        const params = Object.assign({}, parameters, route.query);

        let content = await api.getContentByContentLink(contentLink, params);
        //this check prevents an block from stealing the view from an page in the editor
        if (
            state.modelLoaded &&
            state.model.contentType.parent !== content.contentType.parent
        ) {
            content = await api.getContentByContentLink(
                convertContentLinkToString(state.model.contentLink),
                params
            );
        }
        commit(UPDATE_CONTENT, content);

        return content;
    }
};

export default {
    state,
    mutations,
    actions
};
