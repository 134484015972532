<!--
    Renders a button to edit a property in OPE. Useful when the property to
    edit isn't directly visible (such as ArtistGenre on the ArtistDetailsPage
    component), or when the OPE overlays would be overlapping (e.g. the background
    image in the Hero component).
-->

<template>
    <button v-if="isEditable" v-epi-edit="propertyName" type="button">
        Edit property: {{ propertyName }}
    </button>
</template>

<script lang="ts">

import { Vue, Component, Prop } from "vue-property-decorator";
import { mapState } from "vuex";
import ConfigModel from "@/components/leasingpro/store/configModel";

@Component({
    name: 'EpiPropertyComponent',
    props: ["propertyName"],
    computed: mapState<ConfigModel>({
        isEditable: state => state.epiContext.isEditable
    })
})

export default class EpiPropertyComponent extends Vue {
  
}

</script>

<style lang="scss" scoped>
button {
    background: gray;
    color: white;
}
</style>
