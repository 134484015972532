<template>
  <div class="percent-input">
    <div class="title-wrap">
      <p class="title">{{title}}</p><info-button v-if="info" :info="info"/>
    </div>
    <div class="input-wrap">
      <span class="minus" v-on:click="minusClick"><svg width="9" height="3" viewBox="0 0 9 3" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0.697632V2.3022H9V0.697632H0Z" fill="white"/></svg></span>
      <p class="res"><input ref="inputField" type="text" class="value" v-model="value" /><span class="sufix">%</span></p>
      <span class="plus" v-on:click="plusClick"><svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 3.73469V5.28571H3.71429V9H5.26531V5.28571H9V3.73469H5.26531V0H3.71429V3.73469H0Z" fill="white"/></svg></span>
    </div>
  </div>
</template>

<script>


import InfoButton from '../../info-button/InfoButton.vue';

export default {
  props: ['block', 'initValue', 'title', 'info'],
  components: {
    'info-button' : InfoButton
  },
  data() {
    return {
      description: null,
      value: 0,
      maxValue: 100
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  beforeMount(){
    
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();

  },
  methods: {
    prepareContent: function(){
    },
    getDimensions() {
      this.$nextTick(function(){
          
          this.$refs.inputField.style.width = 0+"px";
          var a = this.$refs.inputField.scrollWidth;
          if (a<33){
            a = 33;
          }
          a = a+12;
          this.$refs.inputField.style.width = a+"px";
          // console.log(a + "-"+this.value+"-");

          this.$emit("changed", this.value);
        });
    
    },
    minusClick(e){
      e.preventDefault();
      this.value--;
    },
    plusClick(e){
      e.preventDefault();
      this.value++;
    },
    setValue(toParams, previousParams){
        if (!this.value || this.value<0){
          this.value = 0;
          //OPENDO-3945
          //return;
        }
        this.value = parseInt(this.value);
        if (this.value>this.maxValue){
          this.value = previousParams;
          return;
        }
        
        this.$nextTick(function(){
          
          this.$refs.inputField.style.width = 0+"px";
          var a = this.$refs.inputField.scrollWidth;
          if (a<33){
            a = 33;
          }
          a = a+12;
          this.$refs.inputField.style.width = a+"px";
          // console.log(a + "-"+this.value+"-");

          this.$emit("changed", this.value);
        });
    }
  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );
    this.$watch(
      () => this.initValue,
      (toParams, previousParams) => {
        this.value = this.initValue;
      }
    );
    if (this.initValue){
      this.value = this.initValue;
      this.$nextTick(function(){
        this.setValue(this.value, this.value);
      });
    }

    this.$watch(
      () => this.value,
      (toParams, previousParams) => {
        this.setValue(toParams, previousParams);
      }
    );

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.percent-input{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    .title-wrap{
      white-space: nowrap;
    }
  .title{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 133%;
    color: #1F4743;
    display: inline;
    vertical-align: top;
    white-space: normal;
  }
  .info-button{
    margin-left: 8px;
    top: 5px;
    vertical-align: top;
    display: inline;
  }
  .value{
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 130%;
    color: #4C6C69;
    border: none;
    apperance: none;
    -webkit-apperance: none;
    display: inline-block;
    width: 49px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: right;
    box-sizing: content-box;
    margin-left: -12px;
    background: transparent;
    margin-right: -8px;
    margin-bottom: 0;
  }
  .suffix{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: #4C6C69;
  }

  .input-wrap{

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 140px;

    .plus,
    .minus{
      background: #69BDB6;
      border: 1.5px solid #69BDB6;
      border-radius: 32px;
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      line-height: 0px;

      cursor: pointer;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      transition: all 300ms;
      -webkit-transition: all 300ms;

      position: relative;
      z-index: 2;
      &:hover{
        opacity: 0.7;
      }

    }
    .res{
      display: inline-block;
      vertical-align: top;
      margin-right: 12px;
      margin-left: 0;
      white-space: nowrap;
      position: relative;
      z-index: 1;
    }
  }
}

@media (max-width: #{$screen-tablet}) {


}
@media (max-width: #{$screen-mobile}) {

}

</style>