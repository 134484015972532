<template>
  <div :class="'dropdown-filter '+(customClass ? customClass+' ': '')+(open ? ' open' : '')">
    <div class="title-bar" v-on:click="titleBarClick">
      <span class="icon">
        <img v-if="icon=='brand'" src="@/assets/showroom-icons/brand.svg" />
        <img v-if="icon=='car-retro'" src="@/assets/showroom-icons/car-retro.svg" />
        <img v-if="icon=='clean-car-gas'" src="@/assets/showroom-icons/clean-car-gas.svg" />
        <img v-if="icon=='monthly'" src="@/assets/showroom-icons/monthly.svg" />
        <img v-if="icon=='battery'" src="@/assets/showroom-icons/battery.svg" />
        <img v-if="icon=='co2'" src="@/assets/showroom-icons/co2.svg" />
        <img v-if="icon=='distance'" src="@/assets/showroom-icons/distance.svg" />
        <img v-if="icon=='doors'" src="@/assets/showroom-icons/doors.svg" />
        <img v-if="icon=='gear'" src="@/assets/showroom-icons/gear.svg" />
        <img v-if="icon=='hand'" src="@/assets/showroom-icons/hand.svg" />
        <img v-if="icon=='leaves'" src="@/assets/showroom-icons/leaves.svg" />
        <img v-if="icon=='money'" src="@/assets/showroom-icons/money.svg" />
        <img v-if="icon=='motor'" src="@/assets/showroom-icons/motor.svg" />
        <img v-if="icon=='sparks'" src="@/assets/showroom-icons/sparks.svg" />
        <img v-if="icon=='speed'" src="@/assets/showroom-icons/speed.svg" />
        <img v-if="icon=='water'" src="@/assets/showroom-icons/water.svg" />
      </span>
      <p class="dropdown-title">{{title}}</p>
      <span class="info-button" v-if="info">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 8C16 12.4114 12.4113 16 8 16C3.58865 16 0 12.4113 0 8C0 3.58865 3.58865 0 8 0C12.4113 0 16 3.58849 16 8ZM14.6285 8C14.6285 4.34278 11.657 1.37143 7.99992 1.37143C4.34269 1.37143 1.37135 4.3429 1.37135 8C1.37135 11.6572 4.34282 14.6286 7.99992 14.6286C11.6571 14.6514 14.6285 11.6571 14.6285 8Z" fill="#69BDB6"/>
          <path d="M7.99984 6.83447C7.61129 6.83447 7.31409 7.13166 7.31409 7.52023V11.0174C7.31409 11.4059 7.61128 11.7031 7.99984 11.7031C8.38841 11.7031 8.6856 11.4059 8.6856 11.0174V7.52023C8.6856 7.13168 8.38841 6.83447 7.99984 6.83447Z" fill="#69BDB6"/>
          <path d="M7.99985 4.31983C7.81697 4.31983 7.63409 4.38839 7.51976 4.52551C7.38265 4.63982 7.31409 4.8227 7.31409 5.00542C7.31409 5.05118 7.31409 5.09678 7.33689 5.14254C7.33689 5.1883 7.35969 5.2339 7.38265 5.27966C7.40545 5.32542 7.4284 5.34822 7.4512 5.39398C7.474 5.43974 7.49696 5.46254 7.54256 5.5083C7.794 5.75973 8.25109 5.75973 8.50256 5.5083C8.52536 5.4855 8.57112 5.43974 8.59392 5.39398C8.61672 5.34822 8.63968 5.32542 8.66248 5.27966C8.68528 5.2339 8.68528 5.1883 8.70824 5.14254C8.70824 5.09678 8.73104 5.05118 8.73104 5.00542C8.73104 4.82255 8.66248 4.63967 8.52536 4.52534C8.3656 4.38838 8.18273 4.31982 7.99985 4.31982L7.99985 4.31983Z" fill="#69BDB6"/>
        </svg>
        <span class="info-text">{{info}}</span>
      </span>
      <span class="arrow transition-all">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.5 9.6006L12.374 17.1756C12.3271 17.2285 12.2695 17.2708 12.205 17.2998C12.1406 17.3288 12.0707 17.3437 12 17.3437C11.9293 17.3437 11.8594 17.3288 11.795 17.2998C11.7305 17.2708 11.6729 17.2285 11.626 17.1756L5.5 9.6006" stroke="#4C6C69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </span>
    </div>
    <div class="dropdown-wrap" ref="dropdown">
      <div class="checkboxes-wrap" v-if="childrenType == 'checkboxes' && children">
        <!-- CHECKBOXES -->
        <div :class="'item'+(item.disabled || (item.count<=0) ? ' disabled' : '')" v-for="(item, index) in children" :key="'checkbox-item-'+index">
          <CheckboxField :name="item.name+ (item.count ? ' ('+item.count+')' : '')" :disabled="(item.disabled || (item.count<=0))" :value="item.value" :children="item.children" :isChecked="item.checked" @changed="changed" />
        </div>
      </div>
      <div class="check-buttons-wrap" v-if="childrenType == 'checkbuttons' && children">
        <!-- CHECKBUTTONS -->
        <div :class="'item'+(item.disabled || (item.count<=0) ? ' disabled' : '')" v-for="(item, index) in children" :key="'checkbox-item-'+index">
          <CheckbuttonField :name="item.name" :value="item.value" :icon="item.icon" :disabled="(item.disabled || (item.count<=0))" :isChecked="item.checked"  @changed="changed" />
        </div>
      </div>
      <div class="interval-wrap" v-if="childrenType == 'interval'">
        <!-- INTERVAL -->
        <IntervalField :customClass="'dark small'" :name="name" :from="fromValue" :to="toValue" :updateTrigger="(updateTrigger ? updateTrigger : localUpdateTrigger)" :initFrom="initFrom" :initTo="initTo"  @changed="changed" />
      </div>
    </div>



    
    
  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';



export default {
  props: ['icon', 'title', 'info', 'children', 'name', 'fromValue', 'toValue', 'initFrom', 'initTo', 'childrenType', 'customClass', 'updateTrigger'],
  data() {
    return {
      open: true,
      localUpdateTrigger: false
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  mounted(){
    this.init();
  },
  created(){
    this.$watch(
      () => this.open,
      (toParams, previousParams) => {
        this.handleDropdown();
      }
    );
    
  },
  methods: {
    init: function(){
      // console.log("init-d");
    },
    changed: function(){
      this.$emit("changed");
    },
    titleBarClick: function(){
      this.open = !this.open;
    },
    handleDropdown: function(){
      if (this.$refs.dropdown){
        var th = this;
        if (this.open){
          this.$nextTick(() => {
            var h = this.$refs.dropdown.offsetHeight;
            gsap.to(this.$refs.dropdown, { duration: 0, opacity: 0, height: 0});
            gsap.to(this.$refs.dropdown, { duration: 0.3, opacity: 1, height: h, onComplete:function(){
              th.$refs.dropdown.style.height = "";
            }});
          });
        }else{
          var th = this;
          var h = this.$refs.dropdown.offsetHeight;
          this.$nextTick(() => {
            gsap.to(this.$refs.dropdown, { duration: 0, opacity: 1, height: h});
            gsap.to(this.$refs.dropdown, { duration: 0.3, opacity: 0, height: 0, onComplete:function(){
              th.$refs.dropdown.style.height = "";
            }});
          });
        }
      }
    },
  },
 
}
</script>
<style lang="scss">
@import "~@/styles/variables.scss";
.dropdown-filter{
  
  margin-bottom: 24px;
  .title-bar{
    position: relative;
    display: block;
    padding-bottom: 16px;
    padding-top: 16px;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 64px;
    cursor: pointer;
    .icon{
      display: inline-block;
      vertical-align: top;

      position: absolute;
      left: 8px;
      top: 50%;
      margin-top: - 12px;
    }
    .info-button{
      display: inline-block;
      vertical-align: top;
      position: absolute;
      right: 40px;
      top: 50%;
      margin-top: - 8px;

			path{
				-webkit-transition: all 0.3s ease;
				-moz-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
				transition: all 0.3s ease;
			}
			.info-text{
				z-index: 100;
				line-height: 1.2rem;
				display: none;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 133%;
				text-align: center;
				color: #FFFFFF;

				background: #1F4743;
				border-radius: 4px;
				padding: 12px 22px;
				padding-bottom: 16px;
				box-sizing: border-box;
				
				position: absolute;
				top: 28px;
				width: 293px;
				left: 50%;
				margin-left: -146px;

        white-space: normal;
			}
			&:hover{
				path{
					fill: #1F4743;
				}
				.info-text{
					display: block;
				}
			}
		
    }

    .arrow{
      display: inline-block;
      vertical-align: top;
      position: absolute;
      right: 8px;
      top: 50%;
      margin-top: - 12px;

      -webkit-transform: rotate(0deg) scale(1);
      -moz-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
    }
    .dropdown-title{
      display: inline-block;
      vertical-align: top;

      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 130%;
      color: #1F4743;

    }
    
  }
  .dropdown-wrap{
    overflow: hidden;
    height: 0px;
  }
  &.open{
    .title-bar{
      .arrow{
        -webkit-transform: rotate(180deg) scale(1);
        -moz-transform: rotate(180deg) scale(1);
        transform: rotate(180deg) scale(1);
      }
    }
    .dropdown-wrap{
      height: auto;
    }
   
  }

  .check-buttons-wrap{
    margin-left: -4px;
    margin-right: -4px;
    > .item{
      display: inline-block;
      vertical-align: top;
      width: 33.33%;
    }
  }
  .interval-wrap{
    padding-left: 8px;
    padding-right: 8px;
    .interval-field{
      margin-top: 4px;
    }
  }
}
</style>