<template>
  <div :class="'checkbutton-field transition-all'+(!icon ? ' no-icon': '')+(customClass ? customClass+' ': '')+(disabled ? ' disabled': '')+(theme ? ' theme-'+theme : '')+(size ? ' size-'+size : '')+(checked ? ' checked' : '')">
    <label>
      <span class="icon transition-all" v-if="icon">
        <img v-if="icon=='cabriolet'" src="@/assets/showroom-icons/cars/cabriolet.svg" alt="cabriolet" />
        <img v-if="icon=='coupe'" src="@/assets/showroom-icons/cars/coupe.svg" alt="coupe" />
        <img v-if="icon=='hatchback'" src="@/assets/showroom-icons/cars/hatchback.svg" alt="hatchback" />
        <img v-if="icon=='mini'" src="@/assets/showroom-icons/cars/mini.svg" alt="mini" />
        <img v-if="icon=='sedan'" src="@/assets/showroom-icons/cars/sedan.svg" alt="sedan" />
        <img v-if="icon=='stationcar'" src="@/assets/showroom-icons/cars/stationcar.svg" alt="stationcar" />
        <img v-if="icon=='suv'" src="@/assets/showroom-icons/cars/suv.svg" alt="suv" />
        <img v-if="icon=='varebil'" src="@/assets/showroom-icons/cars/varebil.svg" alt="varebil" />
        <img v-if="icon=='Kassevogn'" src="@/assets/showroom-icons/cars/varebil.svg" alt="varebil" />
        <img v-if="icon=='kassevogn'" src="@/assets/showroom-icons/cars/varebil.svg" alt="varebil" />
        <img v-if="icon=='mpv'" src="@/assets/showroom-icons/cars/mpv.svg" alt="mpv" />
      </span>
      <input :type="'checkbox'" v-model="checked" :name="(inputName ? inputName : value)" :disabled="(forceDisabled)" :value="value" v-on:change="change" /><span class="label transition-all">{{name}}</span>
    </label>
  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';

export default {
  props: ['theme', 'size', 'name', 'inputName', 'value', 'icon', 'isChecked', 'customClass', 'disabled', 'forceDisabled'],
  data() {
    return {
      checked: false 
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  mounted(){
    this.init();
  },
  created(){
    // this.$watch(
    //   () => this.options,
    //   (toParams, previousParams) => {
    //     this.init();
    //   }
    // );
    this.$watch(
      () => this.isChecked,
      (toParams, previousParams) => {
        this.handleIsChecked();
      }
    );
  },
  methods: {
    init: function(){
      this.handleIsChecked();
    },
    handleIsChecked: function(){
      if (this.isChecked){
        this.checked = true;
      }else{
        this.checked = false;
      }
    },
    change:function(e){
      // console.log(this.checked);
      // var th = this;
      this.$emit("changed");
    }
  },
 
}
</script>
<style lang="scss">
@import "~@/styles/variables.scss";
.checkbutton-field{
  // display: inline-block;
  // vertical-align: top;
  // width: 33.33%;
  cursor: pointer;

  input{
    display: none;
  }
  label{
    position: relative;
    border: 2px solid rgba(105, 189, 182, 0.09);
    box-sizing: border-box;
    border-radius: 12px;
    display: block;
    margin-bottom: 8px;
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;
  }
  .icon{
    display: block;
    text-align: center;
    opacity: 0.5;
  }
  .label{
    display: block;


    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #4C6C69;
    margin-top: -8px;
    margin-bottom: 20px;
  }
  &.no-icon{
    .label{
      margin-top: 0px;
      padding-top: 29px;
      padding-bottom: 12px;
    }
  }
  &:hover{
    .icon{
      opacity: 1;
    }
  }
  &.checked{
    label{
      background: rgba(105, 189, 182, 0.09);
      border: 2px solid transparent;
      
    }
    .label{
      color: #1F4743;
    }
    .icon{
      opacity: 1;
    }
  }

  &.disabled{
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>