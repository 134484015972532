import { Component, Vue } from "vue-property-decorator";

// import InputField from "@/components/ui/input-field/InputField.vue";
// import InputField from "../../components/ui/input-field/input-field";
import template from "./Logins.vue"

@Component({
    mixins:[template],
    name: 'LoginView',
    props: ['cmsGlobalData', 'cmsPageData'],
    // components: {
    //     InputField
    // }
  
})
export default class LoginView extends Vue {
}