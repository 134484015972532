import Awesomplete from 'awesomplete'
import AbAxios from './ab_axios';

class AddressAutocomplete{

    constructor() {
    }

    readonly dawaBaseUrl = "https://dawa.aws.dk";
    readonly postnumUrl = "/postnumre/autocomplete?";
    readonly addressUrl = "/autocomplete?";
    readonly abAxios = new AbAxios(this.dawaBaseUrl);

    dawaParams = {
        startfra: "vejnavn",
        type: "adresse",
        caretpos: 2,
        supplerendebynavn:true,
        stormodtagerpostnumre:true,
        multilinje:false,
        fuzzy:true
    }

    createQueryParams = params =>
        Object.keys(params)
            .map(k => `${k}=${encodeURI(params[k])}`)
            .join('&');


    async autocompletePostNum(input: any, successFunction: any) {
        if ((input as HTMLInputElement).value === "") {
            return;
        }

        let url = this.postnumUrl + this.createQueryParams(this.dawaParams) + "&q=" + (input as HTMLInputElement).value;

        await this.abAxios.abGet(url, false,
        function successCallback(response: any) {
            successFunction(response);
        },
        function errorCallback(error: any) {
            console.error(error);
        });
    }

    async autocompleteAddress(input: any, successFunction: any) {
        if ((input as HTMLInputElement).value === "") {
            return;
        }

        let url = this.addressUrl + this.createQueryParams(this.dawaParams) + "&q=" + (input as HTMLInputElement).value;

        await this.abAxios.abGet(url, false,
            function successCallback(response: any) {
                successFunction(response);
            },
            function errorCallback(error: any) {
                console.error(error);
            });
    }

}

export default AddressAutocomplete;
