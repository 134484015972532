import { Component, Vue } from "vue-property-decorator";

import template from "./ReferalLanding.vue"

@Component({
    mixins:[template],
    props: ['cmsGlobalData', 'cmsPageData']
})
export default class ReferalLanding extends Vue {

}
