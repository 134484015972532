<template>

  
    <div class="compare-table-block">
      <div class="block" ref="block">
        <div class="wrap" :class="'col-count-'+colCount">
          <div class="table" :class="'col-count-'+colCount">
            <div class="th">
              <span class="td first" v-if="headerTitle1">{{headerTitle1}}</span>
              <span class="td" v-if="headerTitle2">{{headerTitle2}}</span>
              <span class="td" v-if="headerTitle3">{{headerTitle3}}</span>
              <span class="td" v-if="headerTitle4">{{headerTitle4}}</span>
            </div>
            <div class="tr" v-for="(row, index) in tableRows" :key="'compare-table-row-1-'+index">
              <span class="td first" v-if="row.title">{{row.title}}</span>
              <span class="td" v-for="(col, index2) in colMask" v-if="col" :key="'compare-table-row-col-1-'+index+'-'+index2">

                <span v-if="row['col'+(index2+1)+'Type'] == 'check'" class="check" :class="row['col'+(index2+1)+'Value']*1  ? 'on' : 'off'"></span>
                <span v-if="row['col'+(index2+1)+'Type'] == 'energy'" class="energy" :class="row['col'+(index2+1)+'Value']">{{energyTitle(row['col'+(index2+1)+'Value'])}}
                  <span class="spike">
                    <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.43024 1.9927C5.29219 0.724503 3.6686 0 1.96464 0H0V32H1.96464C3.6686 32 5.29219 31.2755 6.43024 30.0073L16.0033 19.3394C17.7082 17.4395 17.7082 14.5605 16.0033 12.6606L6.43024 1.9927Z" fill="#4DA45A"/>
                    </svg>
                  </span>
                </span>
                <span v-if="row['col'+(index2+1)+'Type'] == 'text'" class="text" >{{row['col'+(index2+1)+'Value']}}</span>

              </span>
            </div>
            <div class="tf" v-if="footerButton1 || footerButton2 || footerButton3 || footerButton4">
              <span class="td first" >
                <!--<a v-if="footerButton1 && footerButton1.url" class="button" :href="footerButton1.url" :target="footerButton1.target">{{footerButton1.title}}</a>-->
              </span>
              <span class="td" v-if="footerButton2 && footerButton2.url && colMask[0]">
                <a class="button" :href="footerButton2.url" :target="footerButton2.target">{{footerButton2.title}}</a>
              </span>
              <span class="td" v-if="footerButton3 && footerButton3.url && colMask[1]">
                <a class="button" :href="footerButton3.url" :target="footerButton3.target">{{footerButton3.title}}</a>
              </span>
              <span class="td" v-if="footerButton4 && footerButton4.url && colMask[2]">
                <a class="button" :href="footerButton4.url" :target="footerButton4.target">{{footerButton4.title}}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="scroll-bar-wrap">
        <ScrollBar :elem="refBlock"/>
      </div>
    </div>
    
  

</template>

<script>


import gsap from 'gsap';
import eventBus from '../../../eventBus';

import ScrollBar from '../scroll-bar/ScrollBar';

export default {
  props: ['block'],
  components: {
    "ScrollBar" : ScrollBar
  },
  data() {
    return {
      
      headerTitle1: null,
      headerTitle2: null,
      headerTitle3: null,
      headerTitle4: null,

      footerButton1: null,
      footerButton2: null,
      footerButton3: null,
      footerButton4: null,

      
      refBlock : null,
      colCount: 0,
      colMask: [],
      tableRows: [],
    }
  },

  
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  beforeMount(){
    
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
    this.refBlock = this.$refs.block;

  },
  methods: {
    energyTitle: function(key){
      if (key=="a3") return "A+++";
      if (key=="a2") return "A++";
      if (key=="a1") return "A+";
      if (key=="a") return "A";
      if (key=="b") return "B";
      if (key=="c") return "C";
      if (key=="d") return "D";
      if (key=="e") return "E";
      if (key=="f") return "F";
      if (key=="g") return "G";
      if (key=="h") return "H";
    },
    typeToKey: function(type){
      if (type == "text") return "text";
      if (type == "check") return "checkmark";
      if (type == "energy") return "energy";
      
    },
    prepareContent: function(){
      this.headerTitle1 = this.block.header_title_1.value;
      this.headerTitle2 = this.block.header_title_2.value;
      this.headerTitle3 = this.block.header_title_3.value;
      this.headerTitle4 = this.block.header_title_4.value;

      this.footerButton1 = this.block.footer_button_1.value;
      this.footerButton2 = this.block.footer_button_2.value;
      this.footerButton3 = this.block.footer_button_3.value;
      this.footerButton4 = this.block.footer_button_4.value;

      this.colCount = 0;
      if (this.headerTitle2){ this.colMask.push(true); this.colCount++; }else{ this.colMask.push(false); }
      if (this.headerTitle3){ this.colMask.push(true); this.colCount++; }else{ this.colMask.push(false); }
      if (this.headerTitle4){ this.colMask.push(true); this.colCount++; }else{ this.colMask.push(false); }
      
      
      // this.description = this.block.description.value;
      
      var tableRows = [];
      if (this.block.rows){
        // console.log(this.block);
        var tableRowsCount = this.block.rows.value;
        var c = 0;
        for (var i=0; i<tableRowsCount; i++){
          var rowTitle = this.block['rows_'+i+'_title'].value;
          var rowCol1Type = this.block['rows_'+i+'_col_1_type'].value;
          var rowCol2Type = this.block['rows_'+i+'_col_2_type'].value;
          var rowCol3Type = this.block['rows_'+i+'_col_3_type'].value;

          var rowCol1Value = this.block['rows_'+i+'_col_1_'+this.typeToKey(rowCol1Type)].value;
          var rowCol2Value = this.block['rows_'+i+'_col_2_'+this.typeToKey(rowCol2Type)].value;
          var rowCol3Value = this.block['rows_'+i+'_col_3_'+this.typeToKey(rowCol3Type)].value;

          var cols = [];
          tableRows.push({
            'title': rowTitle,
            'col1Type': rowCol1Type,
            'col2Type': rowCol2Type,
            'col3Type': rowCol3Type,
            'col1Value': rowCol1Value,
            'col2Value': rowCol2Value,
            'col3Value': rowCol3Value,
          }); 
        }
      }
      this.tableRows = tableRows;

      // console.log(this.tableRows);
      

    },
    getDimensions() {
      // this.width = document.documentElement.clientWidth;
      // this.height = document.documentElement.clientHeight;

      
      // this.newsDirection = 'vertical';
      // if(this.width< 1000){
      //   this.newsDirection = 'horizontal';
      // }
    
    },
  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.compare-table-block{
  margin-top: 100px;
  margin-bottom: 100px;
  .scroll-bar-wrap{
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
  }
  .wrap{
    // padding: 0px;
  }
  .table{
    border-radius: 20px;
    overflow: hidden;
    // margin-left: 20px;
    // margin-right: 20px;
    .th, .tr, .tf{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .th{
      background: #E1F2F0;
      .td{
        padding: 24px 16px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 130%;
        color: #1F4743;
        border-right: none;

      }
    }
    .tf{
      .td{
        padding: 16px;
        border: none;
      }
    }
    .tr{
      background: #FFF;
      &:nth-of-type(2n + 1){
        background: #F9FCFC;
      }
    }
    .td{
      padding: 4px 16px;
      min-height: 55px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 133%;
      color: #1F4743;
      box-sizing: border-box;
      border-right: 1px solid #E8F5F4;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      &.first{
        text-align: left;
        border-right: none;
        justify-content: flex-start;
      }
      &:last-child{
        border-right: none;
      }

      .check{
        width: 30px;
        height: 30px;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        
        &.on{
          background-image: url('/ui/assets/icons/icon-check-on.svg');
        }
        &.off{
          background-image: url('/ui/assets/icons/icon-check-off.svg');
        }
      }
      .energy{
        background: #4DA45A;
        border-radius: 5px 0px 0px 5px;
        padding: 8px;
        box-sizing: border-box;
        display: inline-block;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #FFFFFF;
        position: relative;
        height: 32px;
        margin-right: 15px;

        &.a1{
          background: #C2D750;
          color: #1F4743;
          svg path{ fill: #C2D750; }
        }
        .spike{
          position: absolute;
          top: 0px;
          bottom: 0px;
          right: -15px;
          display: inline-block;
          vertical-align: top;
          line-height: 0px;
          svg{
            height: 100%;
            width: auto;
          }
        }
      }
    }
  }
  .table{
    .td{
      &.first{
        width: 50%;
      }
      width: 25%;
    }

    &.col-count-3{
      .td{
        &.first{
          width: 40%;
        }
        width: 30%;
      }
    }
    &.col-count-2{
      .td{
        &.first{
          width: 50%;
        }
        width: 25%;
      }
    }
    &.col-count-1{
      .td{
        &.first{
          width: 50%;
        }
        width: 50%;
      }
    }
  }
}
@media (max-width: 1000px) {
  .compare-table-block{

    .block{
      overflow-x: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }


    .wrap{
      &.col-count-3{
        min-width: 700px;
      }
      &.col-count-2{
        min-width: 500px;
      }
      &.col-count-1{
        min-width: 400px;
      }
    }

    .table{
      .td{
        &.first{
          width: 33.33%;
        }
        width: 33.33%;
      }

      &.col-count-3{
        .td{
          &.first{
            width: 33.33%;
          }
          width: 33.33%;
        }
      }
      &.col-count-2{
        .td{
          &.first{
            width: 33.33%;
          }
          width: 33.33%;
        }
      }
      &.col-count-1{
        .td{
          &.first{
            width: 50%;
          }
          width: 50%;
        }
      }
    }
  }
}

@media (max-width: #{$screen-tablet}) {
  
  .compare-table-block{
    .table{
      .th{
        .td{
          padding: 16px;
          font-size: 16px;
          line-height: 120%;
        }
      }
      .td{
        font-size: 14px;
        line-height: 133%;
        min-height: 50px;
        .energy{
          font-size: 11px;
          line-height: 100%;
          height: 19px;
          padding: 3px 8px;
          .spike{
            right: -9px;
          }
        }
        .check{
          width: 21px;
          height: 21px;
        }
      }
      
    }
  }
}
@media (max-width: 700px) {

}

@media (max-width: #{$screen-mobile}) {


  .compare-table-block{
    .table{
      .th{
        .td{
          padding: 16px;
          font-size: 13px;
          line-height: 133%;
        }
      }
      .tf{
        display: none;
      }
    }
  }



}

</style>