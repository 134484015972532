import { Component, Vue } from 'vue-property-decorator';

import template from './FAQList.vue';

@Component({
  mixins: [template],
  props: ['items', 'tags']
})
export default class FAQList extends Vue {

}