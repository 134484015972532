import router from "@/router";
import { Vue, Component, Prop } from "vue-property-decorator";
import template from "./Confirmation.vue"

@Component({
    mixins:[template],
    props:['state', 'savedContract'],
  
})
export default class Confirmation extends Vue {

    user:any = this.$props.savedContract;

    created() {
        console.log(this.user)
        if(this.$props.state !== 'true') {
            router.push({
                name: 'home'
            })
        }
    }
}