<template>
  <div :class="'input-field-wrap'">
    <div :class="'input-field '+(visibleError ? ' has-error':'')+(customClass ? customClass+' ': '')+(theme ? ' theme-'+theme : '')+(size ? ' size-'+size : '')+(disabled ? ' disabled' : '')">
      <input :type="(type ? type : 'text')" :name="name" :disabled="disabled" v-model="liveValue" :placeholder="placeholder" :autocomplete="autocomplete" @focus="onFocus">
    </div>
    <span class="error-text" v-if="visibleError && errorText" >{{errorText}}</span>
  </div>
</template>

<script>
export default {
  props: ['theme', 'size', 'name', 'value', 'placeholder', 'disabled', 'type', 'customClass', 'errorLabel', 'minLength', 'autocomplete', 'role'],
  data() {
    return {
      visibleError: false,
      errorText: null,
      liveValue: ''
    }
  },
  created(){
    this.$watch(
      () => this.errorLabel,
      (toParams, previousParams) => {
        this.errorText = this.errorLabel;
      }
    );
    this.$watch(
      () => this.liveValue,
      (toParams, previousParams) => {
        this.$emit("changed", this.liveValue);
      }
    );
    
    this.$watch(
      () => this.value,
      (toParams, previousParams) => {
        this.liveValue = this.value;
      }
    );

    this.init();
  },
  methods: {
    init: function(){
      if (this.errorLabel){
        this.errorText = this.errorLabel;
      }
      if (this.value){
        this.liveValue = this.value;
      }

      
    },
    validate: function(){
      if (!this.liveValue){
        return false;
      }

      if (this.minLength){
        if (this.liveValue.length < this.minLength){
          return false;
        }
      }
      return true;
    },
    showError: function(text){
      if (text){
        this.errorText = text;
      }
      this.visibleError = true;
    },
    onFocus: function(e){
      this.visibleError = false;
      this.$emit("focus");
    }
  },
 
}
</script>
<style lang="scss">
@import "~@/styles/variables.scss";
.input-field-wrap{
  width: 100%;
}
.input-field{
  position: relative;
  width: 100%;
  border: 1px solid #C7E5E4;
  box-sizing: border-box;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
  text-align: left;

  input{
    background: transparent;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 12px;
    min-height: 42px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 14px;
    border: none;

    margin: 0px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: #4C6C69;


    &::placeholder{
      color: #4C6C69;
    }
  }

  &.has-error{
    border: 1px solid #E1232D;
  }

  &.size-big{
    input{
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      min-height: 54px;
      padding: 20px;
      padding-top: 15px;
      padding-bottom: 19px;
    }
  }

  &.theme-nr1{
    border: 1px solid #1F4743;
  }

  &.disabled{
    border: 1px solid #1F4743;
    opacity: 0.5;
    input {
      color: #1F4743;
    }
  }
}
.error-text{
  font-size: 12px;
  padding-left: 4px;
  display: block;
  text-align: left;
  color: #E1232D;
  margin-top: 4px;
}
</style>