const
    overlay = document.querySelector("[data-js=modalOverlay]"),
    spinner = document.querySelector("[data-js=loaderSnake]"),
   
    hiddenOptions = [
        { "className": "hiddenAni" },
        { "className": "hiddenDis" },
        { "className": "hiddenVis" }
    ];

interface CssClassObj {
    className: string,
    value: string
};

interface ModalConfig {
    overlay: boolean,
    spinner: boolean
}

class AbToggle {

    constructor() { }

    /**
     * Toggle css class on element
     * @param elm
     * @param cssClass
     */
    public cssClassOnElement(elm: Element, cssClass: string): void {
        if (elm != null) {
            elm.classList.toggle(cssClass);
        }
    };

    /**
     * Toggle css class on a list of elements
     * @param nodeList
     * @param cssClass
     */
    public cssClassOnElements(nodeList: NodeList, cssClass: string): void {
        if (nodeList.length > 0) {
            for (let i = 0; i < nodeList.length; i++) {
                let elm: Element = nodeList[i] as Element;
                this.cssClassOnElement(elm, cssClass);
            }
        }
    };

    /**
     * returns css class from hiddenOptions array if there is a match
     * @param elm
     */
    public getMatchingCssClass(elm: Element): string {
        var foundHiddenClass = "";
        for (let i = 0; i < elm.classList.length; i++) {
            let ccsClass: string = elm.classList[i];
            for (let j = 0; j < hiddenOptions.length; j++) {
                var hiddenOption: string = hiddenOptions[j].className;
                if (ccsClass == hiddenOption) {
                    foundHiddenClass = ccsClass;
                    break;
                }
            }
        }
        return foundHiddenClass;
    };

    /**
     * toggles a css class on an element
     * Usage: if html element is initialised with a hidden class _toggle.hideShowElement(someElement, null);
     * @param elm
     * @param customDefault
     */
    public hideShowElement(elm: Element, customDefault: CssClassObj): void {
        if (elm != null) {
            var hiddenClass: string = this.getMatchingCssClass(elm);
            switch (hiddenClass) {
                case hiddenOptions[0].className:
                    this.cssClassOnElement(elm, hiddenOptions[0].className);
                    break;
                case hiddenOptions[1].className:
                    this.cssClassOnElement(elm, hiddenOptions[1].className);
                    break;
                case hiddenOptions[2].className:
                    this.cssClassOnElement(elm, hiddenOptions[2].className);
                    break;
                default:
                    if (customDefault != null || customDefault != undefined) {
                        this.cssClassOnElement(elm, customDefault.className);
                    } else {
                        this.cssClassOnElement(elm, hiddenOptions[1].className);
                    }
            }
        }
    };

    /**
     * toggles a css class on all elements in NodeList
     * @param nodeList
     * @param customDefault
     */
    public hideShowElements(nodeList: NodeList, customDefault: CssClassObj): void {
        if (nodeList.length > 0) {
            for (let i = 0; i < nodeList.length; i++) {
                let element: Element = nodeList[i] as Element;
                this.hideShowElement(element, customDefault);
            }
        }
    };

    /**
     * toggles checked state on element
     * @param elm
     */
    public checkedElement(elm: HTMLInputElement) {
        if (elm != null) {
            if (elm.checked == true) {
                elm.checked = false;
            } else {
                    elm.checked = true;
            }
        }
    };

    /**
     * toggles checked state on all elements on list
     * @param nodeList
     */
    public checkedElements(nodeList: NodeList) {
        if (nodeList.length > 0) {
            for (let i = 0; i < nodeList.length; i++) {
                let elm: HTMLInputElement = nodeList[i] as HTMLInputElement;
                this.checkedElement(elm);
            }
        }
    };

    /**
     * Show modal overlay with varius options
     * @param config
     */
    public modal(config: ModalConfig) {
        if (config != null || config != undefined) {
            config.overlay === true ? this.hideShowElement(overlay, null) : false;
            config.spinner === true ? this.hideShowElement(spinner, null) : false;
        } else {
            this.hideShowElement(overlay, null);
            this.hideShowElement(spinner, null);
        }
    };

}

export default AbToggle;
