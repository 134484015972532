<template>
  <div :class="'site-header '+siteHeaderOpenClass" :style="'margin-top:'+bannerHeight+'px'">
    <div class="wrap">
        <div class="flex my-flex-nowrap flex-s1">
          <div class="left-side flex my-flex-nowrap flex-s1">
            <router-link :to="{'name': 'BankloginHome'}" class="logo-wrap" >
              <img :src="require('@/assets/logo.svg')" class="logo" />
            </router-link>
          </div>
          
          

          <div class="right-side flex my-flex-nowrap flex-s1">
            
            <div class="navigation">
              <span v-if="show_referral_module===true" class="item"><router-link :to="{'name': 'BankloginReferal'}" >Henvisningsmodul</router-link></span>
              <span class="item"><router-link :to="{'path': '/pengeinstitutter/dokumenter'}" >Udfyld formular</router-link></span>
              <span class="item"><router-link :to="{'path': '/pengeinstitutter/uddannelse'}" >Uddannelse</router-link></span>
              <span class="item"><router-link :to="{'name': 'BankloginDokumenter'}" >Spørgsmål og svar</router-link></span>
              <span class="item"><router-link :to="{'name': 'BankloginNews'}" >Nyheder</router-link></span>
              <!--<router-link :to="{'name': 'BankloginVarebiler'}" class="logo-wrap" ></router-link>-->
            </div>
            <div class="login-button-wrap">
              <div to="/log-paa" class="login-button login-button-style noselect" v-on:click="logout"><div class="icon"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.6654 6.66602H4.33203C3.77974 6.66602 3.33203 7.11375 3.33203 7.66602V12.9993C3.33203 13.5516 3.77974 13.9993 4.33203 13.9993H11.6654C12.2176 13.9993 12.6654 13.5516 12.6654 12.9993V7.66602C12.6654 7.11375 12.2176 6.66602 11.6654 6.66602Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <rect x="7" y="9" width="2" height="2" rx="1" fill="currentColor"/>
                <path d="M10 6.58333H10.75V5.83333V4.5C10.75 2.98122 9.51878 1.75 8 1.75C6.48122 1.75 5.25 2.98122 5.25 4.5V5.83333V6.58333H6H10Z" stroke="currentColor" stroke-width="1.5"/>
                </svg>
                </div>Log ud</div>
            </div>
          </div>

          <div class="menu-burger-wrap">
            <span class="menu-burger noselect" v-on:click="onMenuBurgerClick">
              <span class="l1"></span>
              <span class="l2"></span>
              <span class="l3"></span>
              <span class="label">Menu</span>
            </span>
          </div>
          
        </div>
      </div>
    </div>

    
</template>
<script>


import eventBus from '../../../eventBus';
import BottomInfoBar from "@/components/ui/bottom-info-bar/BottomInfoBar.vue";
import bankloginData from "../BankloginData";
import * as localForage from "localforage";

export default {
  components: {
    BottomInfoBar : BottomInfoBar
  },
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      bankloginTokenKey: "BankloginToken",
      homeURL: "/",
      openBotomBar: false,
      bottomBarText: '',
      siteHeaderOpenClass: "",
      bannerHeight: 0,
      show_referral_module: false
    }
  },
  async beforeMount(){
    const token = await localForage.getItem(this.bankloginTokenKey);
    if (token["bank_show_referral_module"] != undefined &&
          token["bank_show_referral_module"]) {
      this.show_referral_module = true;
    }
  },
  created(){
    var th = this;

    var el = document.querySelector(".header-banner .inner");
    if (el){
      var bh = el.scrollHeight;
      if (bh){
        th.bannerHeight = bh;
      }
    }
    
    eventBus.$on("bannerHeight", function(e){
      th.bannerHeight = e;
    });

  },
  mounted(){
    var th = this;
    
  },
  methods: {
    onCustomSubmit: function(e){
      var th = this;
      if (e.type=="setPassword"){
        th.bottomBarText = "Din bruger er nu oprettet! Du kan logge ind med din mail og valgte adgangskode.";
        th.openBotomBar = true;  
      }
      if (e.type=="failedSetPassword"){
        th.bottomBarText = "Failed to set password.";
        th.openBotomBar = true;  
      }
      if (e.type=="loginFailed"){
        th.bottomBarText = "Failed login.";
        th.openBotomBar = true;  
      }
      if (e.type=="loginSuccess"){
        th.bottomBarText = "Success login.";
        th.openBotomBar = true;  
      }
      if (e.type=="recoverSuccess"){
        th.bottomBarText = "Success recover.";
        th.openBotomBar = true;  
      }
      if (e.type=="recoverFailed"){
        th.bottomBarText = "Bad token.";
        th.openBotomBar = true;  
      }

      
    },
    onCloseBottomBar: function(e){
      var th = this;
      this.openBotomBar = false;
    },
    logout: function(e){
      e.preventDefault();
      var th = this;
      bankloginData.logout(function(){
        th.$router.replace({ name: "Banklogin" });
      });
    },

    onMenuBurgerClick: function(e){
      e.preventDefault();
      if (!this.siteHeaderOpenClass){
        this.openMobileMenu();
      }else{
        this.closeMobileMenu();
      }
    },
    closeMobileMenu: function(e){
      this.siteHeaderOpenClass = "";
      document.querySelector("html").classList.remove("scroll-lock");
      document.querySelector("body").classList.remove("scroll-lock");
    },
    openMobileMenu: function(e){
      this.siteHeaderOpenClass = "open";
      document.querySelector("html").classList.add("scroll-lock");
      document.querySelector("body").classList.add("scroll-lock");
    },
    
  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";



</style>