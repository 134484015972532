<template>
  <div class="car-item">
    <div class="car-bubble" v-if="(car.soldDate != null || car.reservedDate != null) && car.count === 1 && carBusinessType === 'privat'">
      <span>{{car.soldDate != null ? "Solgt" : car.reservedDate != null ? "Reserveret" : ""}}</span>
    </div>

    <div class="flex flex-column flex-s5" v-on:click="onSelectCar(car, $event)">
      <div class="image" v-if="car.image"><img :src="car.image" alt="image"/>
        <div class="car-tags flex" v-if="carImageTags && carImageTags.length>0">
          <span :class="'tag'" v-if="tag" v-for="(tag, index) in carImageTags"
                :key="'car-tag-'+car.id+'-'+index">{{ tag }}</span>
        </div>
      </div>
      <div class="image" v-if="car.images && car.images[0]">
        <img :src="car.images[0]" alt="image"/>
      </div>
      <div class="car-content flex flex-column flex-s6">
        <div>
          <p class="title">{{ car.name }} ({{car.count}})</p>
        </div>
        <div>
          <p class="benefit-price">Førstegangsydelse fra {{ formatPrice(car.price.downpayment.total) }} kr.</p>
          <p class="taxable-price" v-if="carBusinessType === 'erhverv'">Beskatningsgrundlag fra {{ formatPrice(car.price.tax.taxable_value) }} kr.</p>
          <p class="monthly-title">Månedlig ydelse fra</p>
          <p class="monthly-price">{{ formatPrice(car.price.monthly_payment.total) }} kr.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AbAmount from '../../leasing/Showroom/src/common/ab_amount';

export default {
  props: ['car', 'liked', 'index', 'businessType'],
  data() {
    return {

      likedCarIDs: Array(),
      likedCarIDsPrivat: Array(),
      likedCarIDsErhverv: Array(),
      carBusinessType: null,
      carURL: "",
      abAmount: null,
      carImageTags: null
    }
  },
  beforeMount() {

  },
  created() {
    this.abAmount = new AbAmount();
  },
  mounted() {
    if (!this.businessType) {
      if (['CarListErhverv', 'ErhvervLeasing'].includes(this.$route.name)) {
        this.carBusinessType = 'erhverv';
      } else if (['DealerCarList', 'DealerCarCalc'].includes(this.$route.name)) {
        this.carBusinessType = 'forhandler';
      } else {
        this.carBusinessType = 'privat';
      }
    } else {
      this.carBusinessType = this.businessType;
    }
  },
  methods: {
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    },
    replaceAll(str, find, replace) {
      return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    },
    stringifyValue(val){
      val = val.toLowerCase();
      val = this.replaceAll(val, "+", "p");
      val = val.replace(/é/g, "e");
      val = val.replace(/[^a-zA-Z0-9 ]/g, ""); //RemoveSpecialChars
      val = val.replace(/ /g, "-");

      return val;
    },
    onSelectCar(c) {
      if (c.count === 1) {
        this.goToCarURL(c);
      } else {
        const  makeName = this.stringifyValue(c.make.name)
        const  modelName = this.stringifyValue(c.model.name)

        const args = {
          'value': 'marke-' + makeName + '-' + modelName,
          'checked': true
        };

        this.$emit("changed", args);
      }
    },
    formatPrice(value) {
      if (value === undefined) {
        return "0";
      }

      return this.abAmount.format(value) + "";
    },
    goToCarURL: function(c){
      const routes = this.$router.getRoutes();
      let carURL = '';
      if (this.carBusinessType){
        for (let i = 0; i < routes.length; i++) {
          if (this.carBusinessType === "erhverv") {
            if (routes[i].name === "CarListErhverv") {
              carURL = routes[i].path + c.id;
            }
          } else if(this.carBusinessType === "forhandler") {
            if (routes[i].name === "DealerCarList"){
              carURL = routes[i].path + c.id;
            }
          } else {
            if (routes[i].name === "CarListPrivat"){
              if (c.frontend_configuration_url != null &&
                c.frontend_configuration_url.length) {
                carURL = c.frontend_configuration_url;
              } else {
                carURL = routes[i].path + c.id;
              }
            }
          }
        }
      }

      if (carURL.length) {
        window.location.href = carURL;
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.car-item {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;

  position: relative;
  display: block;
  background: $color-super-light-green;
  border-radius: 20px;
  width: 25%;
  width: calc(25% - 20px);
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 20px;

  margin-right: 10px;
  margin-left: 10px;

  cursor: pointer;


  .car-bubble {
    position: absolute;
    top: 0;
    left: 0;
    width: 88px;
    height: 80px;
    border-radius: 20px;
    z-index: 10;
    overflow: hidden;

    span {
      position: relative;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 113%;
      color: #FFFFFF;
      padding: 16px;
      display: block;
    }

    &:before {
      content: "";
      position: absolute;
      top: -36px;
      left: -28px;
      width: 116px;
      height: 116px;
      border-radius: 58px;
      background: #1F4743;
    }
  }

  a {
    text-decoration: none;
    height: 100%;
    display: block;
    position: relative;
  }

  .electric-icon {
    position: absolute;
    top: 0;
    right: 0;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #1F4743;
    padding-right: 32px;
    margin-bottom: 4px;
  }

  .like-icon {
    position: absolute;
    top: 25px;
    right: 18px;
    cursor: pointer;

    path {
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;

      fill: transparent;
    }
  }

  &.liked {
    .like-icon {
      path {
        fill: #4C6C69;
        stroke: #4C6C69;
      }
    }
  }

  .sub-title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    color: #1F4743;
    margin-bottom: 4px;
  }

  .taxable-price {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    color: #4C6C69;
    margin-top: 4px;
  }

  .benefit-price {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    color: #4C6C69;
    margin-top: 24px;
  }

  .monthly-title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    color: #4C6C69;
    margin-top: 43px;
  }

  .monthly-price {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #1F4743;
  }

  .image {
    position: relative;
    line-height: 0px;
    height: 168px;
    height: 152px;
    margin: 16px;
    margin-bottom: 0px;
    img {
      mix-blend-mode: multiply;
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: all 300ms;
      -webkit-transition: all 300ms;
    }
  }

  .car-tags {
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: -4px;

    flex-direction: row;
    flex-wrap: wrap-reverse;

    .tag {
      display: inline-block;
      vertical-align: top;
      background: #1F4743;
      border-radius: 4px;


      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 133%;
      letter-spacing: -0.01em;
      color: #FFFFFF;

      margin-top: 4px;
      padding: 10px;
      padding-top: 3px;
      padding-bottom: 5px;

      margin-right: 4px;
    }
  }

  .car-content {
    padding: 16px;
    padding-bottom: 28px;
    position: relative;
    box-sizing: border-box;
    height: calc(100% - 168px);


  }

  &:hover {
    background: #FFF;
    box-shadow: 0 20px 40px -12px rgba(31, 72, 68, 0.16);
  }
}
</style>
