import { Component, Vue } from "vue-property-decorator";

import CarList from "../../components/ui/car-list/car-list";
import NewsSection from '../../components/ui/news-section/news-section';
import ParntersSlider from '../../components/ui/partners-slider/partners-slider';
import template from "./PrivatLeasing.vue"

@Component({
    mixins:[template],
    props: ['cmsGlobalData', 'cmsPageData'],
    components:{
        'car-list': CarList,
        'news-section': NewsSection,
        'partners-slider': ParntersSlider
    }
})
export default class PrivatLeasing extends Vue {


}
