import { ActionContext, Dispatch } from "vuex";
import Vue from "vue";
import { IDataSource, DataSourceResult } from "../../../definitions/episerver/content"
import 'whatwg-fetch'

export const UPDATE_DATA_BY_LINK = "dataSource/UPDATE_DATA_BY_LINK";
export const GET_REQUIREMENTS = "dataSource/GET_REQUIREMENTS";


const UPDATE_DATASOURCE = "dataSource/UPDATE_DATASOURCE";
const UPDATE_INPROGESS = "dataSource/UPDATE_INPROGESS";
const UPDATE_ERROR = "dataSource/UPDATE_ERROR";

export const CLEAR_DATASOURCE = "dataSource/CLEAR_DATASOURCE";


export interface DataSourceStateContainer<T> {
    dataSource: DataSourceGenericState<T>
}

export interface DataSourceGenericState<T> {
    [P: string]: T
}


interface DataSourceState extends DataSourceGenericState<any> {
    datasourceIds: string[]
}

const state: DataSourceState = {
    datasourceIds: []
}

const getRequirements = async function (link: IDataSource, dispatch: Dispatch): Promise<{ [P: string]: string|string[] }> {
    const requirements = {};
    // if (link?.requirements?.length > 0) {
    if (link.requirements.length > 0) {
        for (let index = 0; index < link.requirements.length; index++) {
            const req = link.requirements[index];
            const queryResult = await dispatch(GET_REQUIREMENTS + "/" + req, { requirement: req, type: link.type });

            if (queryResult) {
                //if multiple actions is registered in the store for the same requirement then vuex returns an array with value from all the actions
                //if one of the actions don't return an value the array will still have an entry for the action of 'undefined'
                //so we check if the query is an queryResult and if that the case return the first value that is not null or undefined
                if(Array.isArray(queryResult)) {
                    requirements[req] = queryResult.find(v => v != null);
                } else {
                    requirements[req] = queryResult;
                }
            }
        }
    }

    return requirements;
};

const mutations = {
    [UPDATE_DATASOURCE](state: DataSourceState, payload: { id: string, dataSource: any }) {
        Vue.set(state, payload.id, payload.dataSource);
        state.datasourceIds.push(payload.id);
    },
    [UPDATE_INPROGESS](state: DataSourceState, payload: { id: string, dataSource: any }) {
        Vue.set(state, payload.id, payload.dataSource);
    },
    [UPDATE_ERROR](state: DataSourceState, payload: { id: string, dataSource: any }) {
        Vue.set(state, payload.id + "/Error", payload.dataSource);
        state.datasourceIds.push(payload.id);
    },
    [CLEAR_DATASOURCE](state: DataSourceState) {
        state.datasourceIds.forEach(function (item) {
            Vue.set(state, item, null);
        })
    }
}

const getDataSourceStoreKey = (source: IDataSource, enableRequirementCacheKey: boolean, requirements: { [P: string]: string | string[] }) => {
    if (enableRequirementCacheKey) {
        let requirementCacheKey = "";
        for (const property in requirements) {
            requirementCacheKey += "|"
            const value = requirements[property]
            if (Array.isArray(value)) {
                requirementCacheKey += value.join("&");
            }
            else {
                requirementCacheKey += value
            }
        }

        return source.id + requirementCacheKey
    }
    return source.id;
};

const actions = {
    async [UPDATE_DATA_BY_LINK]({ commit, state, dispatch }: ActionContext<DataSourceState, any>, config: { link: IDataSource, refresh?: boolean, enableRequirementCacheKey?: boolean }) {

        const requirements = await getRequirements(config.link, dispatch);
        const storeKey = getDataSourceStoreKey(config.link, config.enableRequirementCacheKey, requirements);
        if(true){
            const data = require("../../../json/datasource/"+config.link.id+".json");            
            commit(UPDATE_DATASOURCE, { id: storeKey, dataSource: data.result});
        }else{

            const requirements = await getRequirements(config.link, dispatch);
            const storeKey = getDataSourceStoreKey(config.link, config.enableRequirementCacheKey, requirements);
            if (!state[storeKey + "/inprogress"]) {
                //already in store
                if (state[storeKey] && !config.refresh) {
                    if (state[storeKey] !== state[config.link.id]) {
                        //we commit this to avoid a stale cache where the same datasource is called with different requirements
                        commit(UPDATE_DATASOURCE, { id: config.link.id, dataSource: state[storeKey] });
                    }
                    return;
                }
                commit(UPDATE_INPROGESS, { id: storeKey + "/inprogress", dataSource: true });
                
                const response = await window.fetch(config.link.url, {
                    method: 'POST',
                    body: JSON.stringify(requirements),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
    
                const data: DataSourceResult = await response.json();
                if (response.status == 200) {
                    
                    if (storeKey !== config.link.id) {
                        //we commit this to avoid a stale cache where the same datasource is called with different requirements
                        commit(UPDATE_DATASOURCE, { id: storeKey, dataSource: data.result });
                    }
                    
                    commit(UPDATE_DATASOURCE, { id: config.link.id, dataSource: data.result });
                    commit(UPDATE_INPROGESS, { id: storeKey + "/inprogress", dataSource: false });
                }
                else {
                    if (storeKey !== config.link.id) {
                        //we commit this to avoid a stale cache where the same datasource is called with different requirements
                        commit(UPDATE_ERROR, { id: storeKey, dataSource: data.error });
                    }
                    commit(UPDATE_ERROR, { id: config.link.id, dataSource: data.error });
                    commit(UPDATE_INPROGESS, { id: storeKey + "/inprogress", dataSource: false });
                }
            }
    
        }
        
    }
}

export default {
    state,
    mutations,
    actions
};
