<template>
  <div v-if="loggedIn">
    <BLHeader/>
    <div class="page-content">
      <div class="wrap fade-on-enter op-fade-in-animation">
        <div class="top-bar">
          <h1 class="h1-1 title">{{title}}</h1>
          <div class="description" v-html="description"></div>
        </div>
        <faq-list :items="faqs" :tags="tags"/>
      </div>
    </div>
    <BottomInfoBar :open="openBotomBar" :text="bottomBarText" @onClose="onCloseBottomBar" />
  </div>
</template>
<script>

import BLHeader from "./BLHeader.vue";
import BottomInfoBar from "@/components/ui/bottom-info-bar/BottomInfoBar.vue";
import bankloginData from "../BankloginData";
import FAQList from "../../../components/ui/faq-list/faq-list";

export default {
  components: {
    BottomInfoBar : BottomInfoBar,
    BLHeader : BLHeader,
    "faq-list" : FAQList
  },
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      loggedIn: false,
      openBotomBar: false,
      bottomBarText: '',
      title: '',
      description: '',

      tags: null,
      faqs: [
        {'ID': 1, 'post_title': "Er det forventet at jeg som bankrådgiver ved noget om biler eller leasing?", 'content': "Er det forventet at jeg som bankrådgiver ved noget om biler eller leasing?"},
        {'ID': 2, 'post_title': "Er det forventet at jeg som bankrådgiver ved noget om biler eller leasing?", 'content': "Er det forventet at jeg som bankrådgiver ved noget om biler eller leasing?"}
      ]
    }
  },
  beforeMount(){
    
  },
  created(){
    var th = this;
    th.loggedIn = bankloginData.loggedIn;

    if (!bankloginData.loggedIn){
      bankloginData.recoverFromToken(function(){
        th.loggedIn = bankloginData.loggedIn;
      },function(){
        th.loggedIn = bankloginData.loggedIn;
        th.$router.replace({ 'name': "logins" });
      });
    }

  },
  mounted(){
    var th = this;
    
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {

        this.title = this.cmsPageData.page.pengeinstitutter_dokumenter.title;
        this.description = this.cmsPageData.page.pengeinstitutter_dokumenter.description;
        this.faqs = this.cmsPageData.page.pengeinstitutter_dokumenter.faq;
          

        if (this.cmsPageData.page.pengeinstitutter_dokumenter.faq2){
          var faqs = [];
          var tags = [];
          // console.log(this.block.faq);
          var faq2 = this.cmsPageData.page.pengeinstitutter_dokumenter.faq2;
          var catCount = faq2.length;
          var c = 0;
          for (var i=0; i<catCount; i++){
            var categoryTitle = faq2[i].title;
            var questionCount = faq2[i].questions.length;
            tags.push({"term_id": i+1, "name": categoryTitle});
            for (var j=0; j<questionCount; j++){
              c++;
              var questionTitle = faq2[i].questions[j].title;
              var questionContent = faq2[i].questions[j].content;
              faqs.push({'ID': c, 'post_title': questionTitle, 'content': questionContent, 'tags': [{'term_id': i+1}]});
            }
            
          }

          this.tags = tags;
          this.faqs = faqs;
        }
        

    });


    
    
  },
  methods: {
    
    onCloseBottomBar: function(e){
      var th = this;
      this.openBotomBar = false;
    }
    
  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.page-content{
  background: rgba(105, 189, 182, 0.09);
  border-radius: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-bottom: 140px;
}
.top-bar{
  text-align: center;
  margin-bottom: 60px;
  .title{
    margin-bottom: 40px;
  }
  .description{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 133%;
    
    text-align: center;
    color: #4C6C69;

    max-width: 690px;
    margin-left: auto; 
    margin-right: auto;
    p{
      margin-bottom: 1em;
    }
  }
}

</style>