<template>
  <div class="result-box">
    <div><p class="title">{{title}}</p><info-button v-if="info" :info="info"/>
    </div>
    <p class="description">{{description}}</p>
    <p class="value">{{formatPrice(value)}} kr.</p>

    
  </div>
</template>

<script>


import gsap from 'gsap';
import eventBus from '../../../../eventBus';
import AbAmount from '../../../leasing/Showroom/src/common/ab_amount';
import InfoButton from '../../info-button/InfoButton.vue';

export default {
  props: ['block', 'value', 'title', 'info', 'description'],
  components: {
    'info-button' : InfoButton
    // 'news-slider' : NewsSlider
  },
  data() {
    return {
      abAmount: null,
    }
  },

  mounted() {
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  beforeMount(){
    
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();

  },
  methods: {
    prepareContent: function(){
      // this.title = this.block.title.value;
      // this.description = this.block.description.value;

    },
    getDimensions() {
      // this.width = document.documentElement.clientWidth;
      // this.height = document.documentElement.clientHeight;

      
      // this.newsDirection = 'vertical';
      // if(this.width< 1000){
      //   this.newsDirection = 'horizontal';
      // }
    
    },
    formatPrice(value) {
        if (value === undefined) {
            return "0";
        }

        // return value.toFixed(2);
        var cents = Math.round(value%1*100);
        var sCents = "";
        if (cents>0 && cents<10) { sCents = ",0"+cents; }
        if (cents>=10) { sCents = ","+cents; }
        
        return this.abAmount.format(value)+"";
    },

  },
  created() {
    var th = this;

    this.abAmount = new AbAmount();

    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.result-box{
  text-align: center;
  
  margin-bottom: 28px;
  &::after{
    content: "";
    height: 1px;
    display: block;
    margin-top: 28px;
    background: #C7E5E4;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  &:last-child{
    margin-bottom: 0px;
    &::after{
      display: none;
    }
  }
  .info-button{
      margin-left: 8px;
      vertical-align: top;
      margin-top: 4px;
  }
  .title{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    color: #4C6C69;
    display: inline;
    vertical-align: top;
  }
  .label{
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
    letter-spacing: -0.01em;
    color: #4C6C69;
    margin-top: 2px;
  }
  .value{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #1F4743;
    margin-top: 8px;
  }
  .description{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #4C6C69;
  }
}


@media (max-width: #{$screen-tablet}) {


}
@media (max-width: #{$screen-mobile}) {

}

</style>