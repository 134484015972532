<template>

  
  <div class="tagged-news-block">
    <div class="wrap">
      <div class="block">
        <div class="left-side">
          <h2 class="title">{{title}}</h2>
          <div class="description" v-html="content"></div>
          <div class="button-wrap" v-if="button && button.url">
            <a class="button" :href="button.url" :target="button.target">{{button.title}}</a>
          </div>
        </div>
        <div class="right-side">
          <news-slider :articles="articles" :propNewsSlug="newsSlug" :direction="newsDirection" :tagCount="2" :whiteSideGradient="true" :noSideGradient="true"  />
          <div class="button-wrap" v-if="button && button.url">
            <a class="button" :href="button.url" :target="button.target">{{button.title}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import NewsSlider from '../news-slider/news-slider';
import gsap from 'gsap';
import eventBus from '../../../eventBus';

export default {
  props: ['block'],
  components: {
    'news-slider' : NewsSlider
  },
  data() {
    return {
      filteredList: [],

      newsDirection: 'horizontal',
      newsSlug: null,
      articles: null,
      title: null,
      content: null,
      button: {"url": null, "title": null, "target": null},

    }
  },

  mounted() {
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  beforeMount(){
    
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();

  },
  methods: {
    prepareContent: function(){
      this.title = this.block.title.value;
      this.content = this.block.content.value;
      this.button = this.block.button.value;
      this.articles = this.block.news.list;
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;

      
      this.newsDirection = 'vertical';
      if(this.width< 1170){
        this.newsDirection = 'horizontal';
      }
    
    }
  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


.tagged-news-block{
  margin-top: 124px;
  margin-bottom: 124px;
  
  .block{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;

    .left-side{
      box-sizing: border-box;
      width: 50%;
      width: calc(50% - 84px);
      padding-right: 20px;
    }
    .right-side{
      box-sizing: border-box;
      width: 50%;
      width: calc(50% + 84px);
    }
  }

  .left-side{
    .title{
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
      color: #1F4743;
      margin-bottom: 20px;
    }
    .description{
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #1F4743;
      opacity: 0.8;
    }
    .button-wrap{
      margin-top: 60px;
    }
  }
  .right-side{
    .button-wrap{
      display: none;
      text-align: center;
      margin-top: 64px;
    }
  }
}

@media (max-width: 1170px) {
  .tagged-news-block{
    .block{
      display: block;
      .left-side{
        width: 100%;
      }
      .right-side{
        width: 100%;
      }
    }
  }
}
@media (max-width: #{$screen-tablet}) {
  .tagged-news-block{
    margin-top: 80px;
    margin-bottom: 80px;
  
    .wrap{
      padding: 0px;
    }
    .block{
      .left-side{
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
        margin-bottom: 48px;

        .title{
          font-size: 32px;
          line-height: 130%;
        }
        .button-wrap{
          display: none;
        }
      }
      .right-side{
        .button-wrap{
          display: block;
        }
      }
    }
  }

}
@media (max-width: #{$screen-mobile}) {
  .tagged-news-block{
    margin-top: 112px;
    margin-bottom: 112px;
    .block{
      padding: 0px;
      .left-side{
        margin-bottom: 4px;
        .description{
          font-size: 16px;
          line-height: 135%;
        }
      }
    }
  }
}

</style>