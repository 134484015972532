
class AbGtm {

    constructor() { 
        (window as any).dataLayer = (window as any).dataLayer || [];
    }

    /**
     * 
     * @param action //This is the action that occurred during this event, such as “click_add_to_cart”, or “click_video.”
     * @param category //This is the overall category of the event, whether it is a check out event, an engagement event, or so on.
     * @param label //This is where you place additional information about the event, such as what product is being clicked, what video is being watched, etc.
     * @param val //If you are using sophisticated micro conversion value assignment in your analytics pipeline, you can assign a value to this event. Otherwise, I recommend leaving this blank for now.
     * @param event //String
     */
    public triggerGtmEvent(category: string, action: string, label: string, val: number = 0, event: string = "GTM_Event"): void {
        (window as any).dataLayer.push(
            {
                "eventAction": action,
                "eventCategory": category,
                "eventLabel": label,
                "eventValue": val,
                "event": event
            });
    }

    public triggerCustomGtmEvent(values: any): void {
        (window as any).dataLayer.push(values);
    }

    public getUtcTimestamp() {
        const dt = new Date(),
            utcDate = dt.toUTCString();
        return utcDate;
    }

    /**
     * This method is for vendors such as Atcore, that want specific tracking of events
     * */
    public vendorGtmEvent(event: string, eventCategory: string, eventAction: string, eventLabel: string, eventValue: string = null) {
        (window as any).dataLayer.push(
            {
                'event': event,
                'eventCategory': eventCategory,
                'eventAction': eventAction,
                'eventLabel': eventLabel,
                'eventValue': eventValue
            }
        ); 
    }

    /**
     * This method is for vendors such as Atcore, that want specific tracking of virtual page views
     * */
    public vendorGtmVpv(event: string, vpvUrl: string) {
        (window as any).dataLayer.push(
            {
                'event': event,
                'vpv': vpvUrl
            }
        ); 
    }
}

export default AbGtm;
