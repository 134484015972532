import { Component, Vue } from "vue-property-decorator";

import CarItem from "../../components/ui/car-item/CarItem"
import CarList from '../../components/ui/car-list/car-list'
import ShowRoom from '../../components/leasing/Showroom/src/App.vue'
import store from '../../components/leasing/Showroom/src/store/index';
import template from "./LikedCars.vue"

@Component({
    mixins:[template],
    props: ['cmsGlobalData', 'cmsPageData'],
    components:{
        'car-list': CarList,
        'CarItem' : CarItem
    }
})
export default class LikedCars extends Vue {


}
