<template>
  <section class="section fade-on-enter hero-section homepage-hero">
      <div class="wrap">
        <div class="flex my-flex-row flex-s3 top-box">
          <div class="left-side">
            <h1 class="h1-1">{{heroBlock.title}}</h1>
          </div>
          <div class="right-side">
            <div class="description">
              {{heroBlock.description}}
            </div>
          </div>
        </div>

        <div class="buttons flex my-flex-row flex-s1">
          <router-link :class="'b-'+index" :data-index="index"  @mouseover.native="itemMouseOver(index, $event)" @mouseleave.native="itemMouseLeave(index, $event)"  :to="button.link.url" v-for="(button, index) in heroBlock.buttons" :key="button.link.title" >
            <div class="text">
              <span class="label">{{button.link.title}}
                <span class="icon">
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M37 15L47 25L37 35" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M47 25H1" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
              </span>
              <span class="description">
                {{button.description}}
              </span>
            </div>
            <img :src="button.image.sizes.large" :alt="button.image.info ? button.image.info.alt : ''" />
          </router-link>
        </div>
      </div>
  </section>
</template>

<script>

// import gsap from 'gsap';
import {gsap, Quad} from 'gsap';
import eventBus from '../../../eventBus';

export default {
  props: ['heroBlock'],
  data() {
    return {
     
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  mounted(){
    

  },
  methods: {
    itemMouseOver: function(index,e){
     
      var buttonWrap = document.querySelector(".homepage-hero .buttons");
      if (window.getComputedStyle(buttonWrap, null).display == "block"){
        document.documentElement.style.setProperty('--homepage-hero-description-width', (((buttonWrap.offsetWidth - 20) * 1) - 48 - 48) + "px");
      }else{
        document.documentElement.style.setProperty('--homepage-hero-description-width', (((buttonWrap.offsetWidth - 20) * 0.6) - 48 - 48) + "px");
      }

      var buttons = document.querySelectorAll(".homepage-hero .buttons a");
      for(var i=0; i<buttons.length; i++){
        var item = buttons[i];
        if (item.classList.contains("b-"+index)){
          item.style.zIndex = 2;
          item.classList.add("selected");

          var d = item.querySelector(".description");
          if (!d.classList.contains("visible")){
            d.classList.add("visible");
            var dh = d.offsetHeight;
            gsap.to(d, { duration: 0, height: 0});
            gsap.to(d, { duration: 0.3, height: dh, onComplete: function(){
              this._targets[0].style.height = null;
            }});
          }

          // item.style.setProperty('--homepage-hero-description-width', item.offsetWidth + "px");
        }else{
          item.style.zIndex = 1;
          item.classList.add("hidden");
          item.classList.remove("selected");

          
          var d = item.querySelector(".description");
          // gsap.to(d, { duration: 0.3, height: 0, onComplete: function(){
          //   this._targets[0].style.height = null;
          // }});
          d.classList.remove("visible");


        }
      }
     
      
    },
    itemMouseLeave: function(index, e){
      
      var buttons = document.querySelectorAll(".homepage-hero .buttons a");
      for(var i=0; i<buttons.length; i++){
        var item = buttons[i];
        item.classList.remove("selected");
        item.classList.remove("hidden");

        var d = item.querySelector(".description");
        if (d.classList.contains("visible")){
          var dh = d.offsetHeight;
          gsap.to(d, { duration: 0, height: dh});
          gsap.to(d, { ease: Quad.easeInOut, duration: 0.3, height: 0, onComplete: function(){
              this._targets[0].style.height = null;
          }});
          d.classList.remove("visible");
        }
      }
      
    }
  },
  created() {
   
   
  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.homepage-hero{
  position: relative;
  z-index: 10;
  padding-top: 60px;
  margin-bottom: 0px;
  .top-box{
    .left-side{
      width: 40%;
      padding-right: 10px;
      box-sizing: border-box;
    }
    .right-side{
      width: 50%;
      padding-left: 0px;
      margin-right: 0px;
      margin-left: auto;
      padding-left: 12px;
      box-sizing: border-box;
      text-align: left;
      padding-top: 20px;
    }
    .description{
      display: inline-block;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #1F4743;
      opacity: 0.8; 
      // max-width: 433px;
      text-align: left;
    }
  }

  .buttons{
    margin-top: 80px;

    a{
      position: relative;
      height: 450px;
      width: calc(50% - 10px);
      display: inline-block;
      line-height: 0px;
      border-radius: 40px;
      overflow: hidden;

      opacity: 1;
      -webkit-transition: width 0.3s ease, opacity 0.3s ease;
      -moz-transition: width 0.3s ease, opacity 0.3s ease;
      -o-transition: width 0.3s ease, opacity 0.3s ease;
      -ms-transition: width 0.3s ease, opacity 0.3s ease;
      transition: width 0.3s ease, opacity 0.3s ease;

      

      .text{
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;

        background: linear-gradient(180deg, rgba(28, 28, 27, 0) 0%, #1C1C1B 100%);


        .description{
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.7);
          margin-top: 0px;
          
          display: block;
          opacity: 0;

          padding-left: 48px;
          padding-right: 48px;
          margin-top: 48px;
          margin-bottom: 0px;
          // width: 600px;
          height: 0px;
          // padding-top: 12px;
          box-sizing: border-box;
          width: var(--homepage-hero-description-width);

          -webkit-transition: opacity 0.3s ease, margin 0.3s ease;
          -moz-transition: opacity 0.3s ease, margin 0.3s ease;
          -o-transition: opacity 0.3s ease, margin 0.3s ease;
          -ms-transition: opacity 0.3s ease, margin 0.3s ease;
          transition: opacity 0.3s ease, margin 0.3s ease;


          &.visible{
              height: auto;
              opacity: 1;
              margin-bottom: 42px;
              margin-top: 0px;
          }
          
        }
      }
      .label{
        position: relative;
        line-height: 1.4em;
        display: block;
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 40px;
        color: #FFFFFF;
        padding-left: 48px;
        padding-right: 48px;
        margin-bottom: 18px;
        .icon{
          position: absolute;
          right: 48px;
          top: 0px;
        }
      }
      img{
        border-radius: 40px;
        width: 100%;  
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }

      &.selected{
        width: calc(60% - 10px);
      }
      &.hidden{
        width: calc(40% - 10px);
        opacity: 0.6;
      }
    }
  }
}

@media (max-width: 1000px) {
  .homepage-hero{
    .buttons{
      margin-top: 40px; 
      display: block;
      a{
        margin-top: 24px;
        width: 100%;
        &:first-child{
          margin-top: 0px;
        }
        &.selected{
          width: calc(100%);
          .text{
            .description{
              max-height: 200px;
              margin-top: 12px;
              opacity: 1;
            }
          }
        }
        .text{
          .description{
            width: 100%;
          }
        }
        &.hidden{
          width: calc(100%);
          opacity: 0.6;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .homepage-hero{
    .top-box{
      display: block;
      .description{
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        padding-left: 116px;
        max-width: 100%;
      }
      .left-side{
        width: 100%;
      }
      .right-side{
        width: 100%;
        text-align: left;
        padding-top: 34px;
        padding-left: 0px;

      }
    }
    
  }
}
@media (max-width: #{$screen-tablet}) {
  .homepage-hero{
    .buttons{
      a{
        height: 256px;

        .label{
          font-size: 24px;
          line-height: 130%;
          .icon{
            width:32px;
            svg{
              width: 100%;
              height: 32px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: #{$screen-mobile}) {
  .homepage-hero{
    .top-box{
      .description{
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        padding-left: 0px;
      }
      .left-side{
        padding: 0px;
      }
      .right-side{
        padding-top: 20px;
      }
    }
    
    .buttons{
      a{
        height: 256px;

        .label{
          font-size: 24px;
          line-height: 130%;
          .icon{
            width:32px;
            svg{
              width: 100%;
              height: 32px;
            }
          }
        }
      }
    }
  }
}

</style>