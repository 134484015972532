<template>
  <div class="main private-page-main">
    

    <section :class="'section fade-on-enter hero-section leasing-hero-section hero-type-'+(heroBlock.hero_type)">
      <div class="wrap">
        <h1 class="h1-2 title">{{heroBlock.title}}</h1>
        <div class="content" v-html="heroBlock.content"></div>
        <div class="contacts">
          <a :href="'tel:'+heroBlock.phone.replace(/ /g,'')" class="phone"><span class="icon"><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1167_935)">
            <path d="M12.8705 22.5393L12.8805 22.5463C13.7446 23.0965 14.7705 23.3354 15.7887 23.2235C16.8069 23.1117 17.7565 22.6559 18.4805 21.9313L19.1095 21.3023C19.2489 21.163 19.3595 20.9976 19.4349 20.8156C19.5104 20.6335 19.5492 20.4384 19.5492 20.2413C19.5492 20.0442 19.5104 19.8491 19.4349 19.6671C19.3595 19.485 19.2489 19.3196 19.1095 19.1803L16.4565 16.5293C16.3172 16.3899 16.1518 16.2793 15.9698 16.2039C15.7877 16.1285 15.5926 16.0896 15.3955 16.0896C15.1985 16.0896 15.0033 16.1285 14.8213 16.2039C14.6392 16.2793 14.4738 16.3899 14.3345 16.5293C14.0532 16.8105 13.6718 16.9685 13.274 16.9685C12.8763 16.9685 12.4948 16.8105 12.2135 16.5293L7.97153 12.2863C7.69033 12.005 7.53235 11.6236 7.53235 11.2258C7.53235 10.8281 7.69033 10.4466 7.97153 10.1653V10.1653C8.11092 10.026 8.2215 9.8606 8.29694 9.67855C8.37238 9.4965 8.41121 9.30137 8.41121 9.1043C8.41121 8.90724 8.37238 8.71211 8.29694 8.53006C8.2215 8.34801 8.11092 8.1826 7.97153 8.0433L5.31953 5.3923C5.03824 5.1111 4.65678 4.95312 4.25903 4.95312C3.86129 4.95312 3.47982 5.1111 3.19853 5.3923L2.56853 6.0213C1.84412 6.74548 1.38845 7.69513 1.27681 8.71334C1.16518 9.73154 1.40425 10.7574 1.95453 11.6213L1.96053 11.6313C4.86671 15.9311 8.57021 19.6339 12.8705 22.5393V22.5393Z" stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.75 8.25C12.9435 8.25 14.0881 8.72411 14.932 9.56802C15.7759 10.4119 16.25 11.5565 16.25 12.75" stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.75 4.5C13.938 4.5 16.0365 5.36919 17.5836 6.91637C19.1308 8.46354 20 10.562 20 12.75" stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.75 0.75C14.9326 0.75 17.9848 2.01428 20.2353 4.26472C22.4857 6.51516 23.75 9.5674 23.75 12.75" stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_1167_935">
            <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
            </clipPath>
            </defs>
            </svg>
            </span><span class="label">{{heroBlock.phone}}</span>
          </a>
          <a :href="'mailto:'+heroBlock.email" class="email"><span class="icon"><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4.75H23V19.75H2V4.75Z" stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.6618 5.30078L14.5178 11.5648C13.9396 12.0096 13.2304 12.2508 12.5008 12.2508C11.7713 12.2508 11.0621 12.0096 10.4838 11.5648L2.33984 5.30078" stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </span><span class="label">{{heroBlock.email}}</span>
          </a>
        </div>

        <div v-if="heroBlock.hero_type == 'image'" class="big-image">
          <a class="" v-if="heroBlock.block_url && heroBlock.block_url.url" :href="heroBlock.block_url.url" :target="heroBlock.block_url.target">
            <img :src="heroBlock.big_image.sizes.large" :alt="heroBlock.big_image.info ? heroBlock.big_image.info.alt : ''" />
          </a>
          <!--
          <router-link class="" :to="heroBlock.block_url.url" v-else-if="heroBlock.block_url && heroBlock.block_url.url && heroBlock.block_url.target!='_blank'">
            <img :src="heroBlock.big_image.sizes.large" alt="big-image" />
          </router-link>
          !-->
          <div class="" v-else >
            <img :src="heroBlock.big_image.sizes.large" :alt="heroBlock.big_image.info ? heroBlock.big_image.info.alt : ''" />
          </div>
        </div>
        <div v-if="heroBlock.hero_type == 'video'" class="big-image">
          <a class="" v-if="heroBlock.block_url && heroBlock.block_url.url" :href="heroBlock.block_url.url" :target="heroBlock.block_url.target">
            <video  inline autoplay muted loop>
              <source :src="heroBlock.big_video.url" :type="heroBlock.big_video.mime_type">
            </video>
            <span class="tagline" v-if="heroBlock.video_tagline">{{heroBlock.video_tagline}}</span>
            <span class="arrow"><svg width="48" height="23" viewBox="0 0 48 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M37 1.5L47 11.5L37 21.5" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M47 11.5H1" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </span>
          </a>
          <div class="" v-else >
            <video  inline autoplay muted loop>
              <source :src="heroBlock.big_video.url" :type="heroBlock.big_video.mime_type">
            </video>
            <span class="tagline" v-if="heroBlock.video_tagline">{{heroBlock.video_tagline}}</span>
          </div>
        </div>
        <div v-if="heroBlock.hero_type == 'car'" class="big-car">
          <a class="" v-if="heroBlock.block_url && heroBlock.block_url.url" :href="heroBlock.block_url.url" :target="heroBlock.block_url.target">
            <div class="flex my-flex-row flex-s10 big-car-flex">
              <div class="image">
                <img :src="heroBlock.car.image.sizes.large" :alt="heroBlock.car.image.info ? heroBlock.car.image.info.alt : ''" />
              </div>
              <div class="side">
                <p class="label">{{heroBlock.car.label}}</p>
                <p class="title">{{heroBlock.car.title}}</p>
                <div class="description"><p>{{heroBlock.car.description}}</p></div>
                <div class="flex my-flex-row flex-s3">
                  <div class="box">
                    <p class="price-label">Førstegangsydelse fra</p>
                    <p class="price">{{heroBlock.car.first_payment}}</p>
                  </div>
                  <div class="box">
                    <p class="price-label">Månedlig ydelse fra</p>
                    <p class="price">{{heroBlock.car.monthly_payment}}</p>
                  </div>
                </div>
              </div>
            </div>
          </a>
          <!--
          <router-link class="" :to="heroBlock.block_url.url" v-else-if="heroBlock.block_url && heroBlock.block_url.url && heroBlock.block_url.target!='_blank'">
            <div class="flex my-flex-row flex-s10">
              <div class="image">
                <img :src="heroBlock.car.image.sizes.large" alt="car" />
              </div>
              <div class="side">
                <p class="label">{{heroBlock.car.label}}</p>
                <p class="title">{{heroBlock.car.title}}</p>
                <div class="description"><p>{{heroBlock.car.description}}</p></div>
                <div class="flex my-flex-row flex-s3">
                  <div class="box">
                    <p class="price-label">Førstegangsydelse fra</p>
                    <p class="price">{{heroBlock.car.first_payment}}</p>
                  </div>
                  <div class="box">
                    <p class="price-label">Månedlig ydelse fra</p>
                    <p class="price">{{heroBlock.car.monthly_payment}}</p>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
          -->
          <div class="" v-else >
            <div class="flex my-flex-row flex-s10">
              <div class="image">
                <img :src="heroBlock.car.image.sizes.large" :alt="heroBlock.car.image.info ? heroBlock.car.image.info.alt : ''" />
              </div>
              <div class="side">
                <p class="label">{{heroBlock.car.label}}</p>
                <p class="title">{{heroBlock.car.title}}</p>
                <div class="description"><p>{{heroBlock.car.description}}</p></div>
                <div class="flex my-flex-row flex-s3">
                  <div class="box">
                    <p class="price-label">Førstegangsydelse fra</p>
                    <p class="price">{{heroBlock.car.first_payment}}</p>
                  </div>
                  <div class="box">
                    <p class="price-label">Månedlig ydelse fra</p>
                    <p class="price">{{heroBlock.car.monthly_payment}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>

    <car-list :leasingType="'privat'" :allCarsURL="'/privat/showroom'"/>



    <div class="leasing-news-green-section">
      <news-section :articles="articles" :button="newsBlock.button_title" :title="newsBlock.title"  />
    </div>

    

    <section class="section fade-on-enter partners-section">
      <div class="wrap">
        <div class="partners-section-content flex my-flex-row flex-s9">
          <div class="side-image"> 
            <img :src="partnersBlock.image.sizes.large" :alt="partnersBlock.image.info ? partnersBlock.image.info.alt : ''" />
          </div>
          <div class="side-content">
            <h2 class="title h2-1">{{partnersBlock.title}}</h2>
            <div class="description">
              {{partnersBlock.description}}
            </div>

            <partners-slider :logos="patnersLogos" />
            
          </div>
        </div>
      </div>
    </section>
    
    <!-- <router-link class="" :to="thisSlug+'/faq'" >FAQ</router-link> -->
    <span v-if="cmsPageData && !cmsPageData.loaded"></span>
  </div>
</template>
<script>
export default {
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      thisSlug: "",
      heroBlock: { title: "Private Leasing", image: {url: null}, content: "", phone: "", email: "" },
      partnersBlock: {image: {sizes: {large: ""}}},
      articles: null,
      newsBlock: {button_title: null},
      patnersLogos: null,
    }
  },
  beforeMount(){
    // alert(footerData.fields.copyright);
    // this.copyright = "b";
  },
  created(){
    this.thisSlug = window.location.pathname;
    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {
        
        // this.thisSlug = this.cmsPageData.page.url;
        this.heroBlock = this.cmsPageData.page.private.hero;

        this.newsBlock = this.cmsPageData.page.private.news;
        this.partnersBlock = this.cmsPageData.page.private.partners;
        this.articles = this.cmsPageData.page.private.news_list;
        this.patnersLogos = this.cmsPageData.page.private.partners_logos;

        // this.historyBlock = this.cmsPageData.page.private.history;
        // this.partnersBlock = this.cmsPageData.page.private.partners;
        // this.jobsBlock = this.cmsPageData.page.private.jobs;
        // this.patnersLogos = this.cmsPageData.page.private.partners_logos;
        // this.jobs = this.cmsPageData.page.private.jobs.jobs_list;
        
      }
    );
  },
  mounted(){
    
  },
  methods: {

  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


.leasing-news-green-section{
  position: relative;
  background: $color-super-light-green;
  padding-top: 100px;
  padding-bottom: 60px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 40px;
  margin-top: 0px;
  margin-bottom: -52px;
  padding-bottom: 156px;
}

</style>