<template>

  
  <div class="anchor-block" :id="id">
    
  </div>

</template>

<script>


import gsap from 'gsap';
import eventBus from '../../../eventBus';
import VueScrollTo from "vue-scrollto";

export default {
  props: ['block'],
  components: {
    
  },
  data() {
    return {
      id: null,
    }
  },

  
  beforeDestroy() {
    
  },
  beforeMount(){
    
  },
  mounted(){
    var th = this;
    this.prepareContent();
    if (window.location.hash == "#"+this.id){
      Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
        th.$nextTick(function(){
          let VueScrollTo = require('vue-scrollto');
          VueScrollTo.scrollTo(th.$el);
        });
      });
      
    }
    
  },
  methods: {
    
    prepareContent: function(){
      this.id = this.block.anchorid.value;
    },
    
    getDimensions() {
      
    
    },
  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );


  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


.anchor-block{
  
}

</style>