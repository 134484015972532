import { Component, Vue } from "vue-property-decorator";

import ShowRoom from '../../components/leasing/Showroom/src/App.vue'
import template from "./ErhvervCars.vue"

@Component({
    mixins:[template],
    props: ['cmsGlobalData', 'cmsPageData'],
    components: {
        ShowRoom: ShowRoom
    }
})
export default class ErhvervCars extends Vue {


}
