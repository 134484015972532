import { Component, Vue } from 'vue-property-decorator';

import template from './CalculatorBlock.vue';

@Component({
  mixins: [template],
  props: ['block', 'cmsGlobalData']
})
export default class CalculatorBlock extends Vue {

}



