<template>

  
  <section class="media-text-block" :class="green_background? 'green-background': ''">
    <div class="wrap">
      <div class="block" :class="'style-'+block.style.value">
        <div class="side-image" v-if="image"> 
          <img :src="image" :alt="imageAlt" />
        </div>
        <div class="side-content">
          <div class="icon" v-if="icon"> 
            <img :src="icon" alt="icon" />
          </div>
          <h2 class="title h2-1">{{title}}</h2>
          <div class="description" v-html="content"></div>
          <div class="button-wrap">
            <div v-if="side_style_buttons">
              <a class="button" style="margin-top: 10px" :href="button.url" :target="button.target">{{button.title}}</a>&nbsp;
              <a class="button" style="margin-top: 10px" :href="button_2.url" :target="button_2.target">{{button_2.title}}</a>&nbsp;
              <a class="button" style="margin-top: 10px" :href="button_3.url" :target="button_3.target">{{button_3.title}}</a>&nbsp;
              <a class="button" style="margin-top: 10px" :href="button_4.url" :target="button_4.target">{{button_4.title}}</a>
            </div>
            <div v-else>
              <div v-if="button && button.url">
                <a class="button" :href="button.url" :target="button.target">{{button.title}}</a>
              </div>
              <div v-if="button_2 && button_2.url">
                <br /><br />
                <a class="button" :href="button_2.url" :target="button_2.target">{{button_2.title}}</a>
              </div>
              <div v-if="button_3 && button_3.url">
                <br /><br />
                <a class="button" :href="button_3.url" :target="button_3.target">{{button_3.title}}</a>
              </div>
              <div v-if="button_4 && button_4.url">
                <br /><br />
                <a class="button" :href="button_4.url" :target="button_4.target">{{button_4.title}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';

export default {
  props: ['block'],
  components: {

  },
  data() {
    return {
      title: null,
      content: null,
      image: null,
      imageAlt: null,
      icon: null,
      green_background: false,
      side_style_buttons: false,
      button: {"url": null, "title": null, "target": null},
      button_2: {"url": null, "title": null, "target": null},
      button_3: {"url": null, "title": null, "target": null},
      button_4: {"url": null, "title": null, "target": null},
    }
  },

  mounted() {
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  beforeMount(){
    
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();

  },
  methods: {
    prepareContent: function(){
      this.title = this.block.title.value;
      this.content = this.block.content.value;
      this.image = this.block.image.value.sizes && this.block.image.value.sizes.large ? this.block.image.value.sizes.large.url : null;
      this.icon = this.block.icon.value.url
      this.button = this.block.button.value;
      this.button_2 = this.block.button_2.value;
      this.button_3 = this.block.button_3.value;
      this.button_4 = this.block.button_4.value;

      if (this.image && this.block.image.value.info){
        this.imageAlt = this.block.image.value.info.alt;
      }

      if (this.image){
        this.imageAlt = this.block.image.value.info.alt;
      }

      if(this.block.green_background){
        this.green_background = this.block.green_background.value === "1" ? true : false;
      }

      if(this.block.side_style_buttons){
        this.side_style_buttons = this.block.side_style_buttons.value === "1" ? true : false;
      }
    },
    getDimensions() {
      // this.width = document.documentElement.clientWidth;
      // this.height = document.documentElement.clientHeight;

      
      // this.newsDirection = 'vertical';
      // if(this.width< 1000){
      //   this.newsDirection = 'horizontal';
      // }
    
    },
    onTaxTabClick(e){
      e.preventDefault();
      this.currentTab = "tax";
    },
    onCarTabClick(e){
      e.preventDefault();
      this.currentTab = "car";
    }

  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";



.media-text-block{
  margin-top: 122px;
  margin-bottom: 122px;
  padding-top: 80px;
  padding-bottom: 80px;
  &.green-background{
    padding-top: 96px;
    padding-bottom: 96px;
    background: rgba(105, 189, 182, 0.09);
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
  }
  .block{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .side-image{
      width: 50%;
      box-sizing: border-box;
      line-height: 0px;
      border-radius: 20px;


      margin-left: 0px;
      margin-right: 122px;
      max-width: 487px;

      img{
        border-radius: 20px;
      }
      padding-right: 0px;
      padding-left: 0px;
    }
    &.style-right{
      flex-direction: row-reverse;
      justify-content: flex-end;
      .side-image{
        margin-right: 0px;
        margin-left: 122px;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
  .side-content{
    width: 50%;
    max-width: 488px;
    margin-top: 80px;

    .icon{
      margin-bottom: 32px;
      line-height: 0px;
      
    }
    .title{
      margin-bottom: 20px;
    }
    .description{
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #4C6C69;
    }
    .button-wrap{
      margin-top: 60px;
    }
  }
  
}

@media (max-width: #{$screen-tablet}) {
  .media-text-block{

    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 96px;
    margin-bottom: 96px;
    &.green-background{
      padding-top: 64px;
      padding-bottom: 64px;
      background: rgba(105, 189, 182, 0.09);
      border-radius: 20px;
      margin-left: 20px;
      margin-right: 20px;
      width: auto;
    }
    .block{
      // display: block;
      .side-image{
        margin-right: 48px;
        width: 40%;
        // max-width: 100%;
        // width: 100%;
      }
      &.style-right{
        .side-image{
          margin-left: 48px;
        }
      }
    }
    .side-content{
      // max-width: 100%;
      // width: 100%;
      width: 60%;
      .description{
        font-size: 18px;
        line-height: 133%;
      }
    }
    
  }

}
@media (max-width: #{$screen-mobile}) {

  .media-text-block{

    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 112px;
    margin-bottom: 112px;
     &.green-background{
      padding-top: 0px;
      padding-bottom: 0px;
      background: transparent;
      margin-left: 0px;
      margin-right: 0px;
      
      .side-content{
        background: rgba(105, 189, 182, 0.09);
        padding: 20px;
        padding-top: 38px;
        padding-bottom: 48px;
        border-radius: 20px;
        margin-top: 0px;
      }
      .side-image{
        margin-bottom: 32px;
      }
    }


    .block{
      display: block;
      .side-image{
        padding: 0px;
        width: 100%;
        margin: 0px;
        margin-bottom: 36px;
        max-width: 100%;
      }
      &.style-right{
        .side-image{
          margin: 0px;
          padding: 0px;
        }
      }
    }
    .side-content{
      padding: 0px;
      width: 100%;
      max-width: 100%;
      margin-top: 36px;
      .title{
        margin-bottom: 12px;
      }
      .description{
        font-size: 20px;
        line-height: 28px;
      }
      .button-wrap{
        margin-top: 40px;
      }
    }
   
  }
}

</style>