<template>
  <section class="section fade-on-enter jobs-section">
    <div class="wrap">
      <div class="main-flex flex my-flex-row flex-s9">
        <div class="left-side">
          <h2 class="section-title">{{ title }}</h2>
          <div class="description" v-html="description"></div>
        </div>
        <div class="right-side">
          <div class="card-style links-list">
            <h2 class="title">{{ list_title }}</h2>
            <div class="buttons">
              <router-link class="item link-button-item" :to="job.url" v-for="job in jobs" :key="job.id">
                <span class="label">{{ job.title }}</span>
                <span class="icon">
                  <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.4766 1L30.4766 7L24.4766 13" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10"
                        stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M30 7H2" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round"/>
                  </svg>
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import eventBus from "@/eventBus";

export default {
  props: ['block'],
  data() {
    return {
      title: '',
      description: '',
      list_title: '',
      jobs: null
    }
  },
  methods: {
    prepareContent: function () {
      this.title = this.block.title.value;
      this.description = this.block.description.value;
      this.list_title = this.block.list_title.value;

      let _jobs = [];
      for (const job of this.block.jobs.value) {
        _jobs.push({id: job.id, title: job.post_title, url: '/jobs/' + job.post_name});
      }
      console.log(_jobs);
      this.jobs = _jobs;
    },
  },
  mounted() {
    this.prepareContent();
  },
  created() {
    this.$watch(
      () => this.jobs,
      () => {
        this.$nextTick(function () {
          eventBus.$emit("handlePageAnimations");
        });
      }
    );
    this.$watch(
      () => this.block,
      () => {
        this.prepareContent();
      }
    );
  },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.jobs-section{
  background: rgba(105, 189, 182, 0.09);
  border-radius: 40px;
  margin: 160px 20px 20px;
  padding-top: 96px;
  padding-bottom: 146px;

  .left-side{
    width: 41.6666666667%;
    box-sizing: border-box;
    padding-right: 10px;
    padding-top: 48px;
  }
  .right-side{
    width: 58.3333333333%;
    box-sizing: border-box;
    padding-left: 10px;
  }
  .section-title{
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #1F4743;
    margin-bottom: 20px;
  }
  .description{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #4C6C69;
    max-width: 390px;
    ::v-deep p{
      margin-top: 16px;
    }
  }
  .links-list{
    padding: 48px 48px 33px;

    .title{
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 130%;
      color: #1F4743;
      margin-bottom: 28px;
    }
  }
}

@media (max-width: 1000px) {
  .jobs-section{
    padding: 0 48px 48px;

    .wrap{
      padding: 0;
    }
    .main-flex{
      display: block;
    }
    .left-side{
      padding-right: 0;
      width: 100%;
      margin-bottom: 48px;
    }
    .right-side{
      padding-left: 0;
      width: 100%;
    }
  }
}

@media (max-width: #{$screen-mobile}) {
  .jobs-section{
    padding: 0 20px 48px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 80px;
    .wrap{
      padding: 0;
    }
    .main-flex{
      display: block;
    }
    .left-side{
      padding-right: 0;
      width: 100%;
      margin-bottom: 48px;
    }
    .right-side{
      padding-left: 0;
      width: 100%;
    }
    .section-title{
      font-size: 32px;
      line-height: 130%;
      margin-bottom: 12px;
    }
    .description{
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 48px;
    }
    .links-list{
      padding: 20px;
      .title{
        font-size: 25px;
      }
    }
  }
}
</style>
