<template>

  
  <div class="soft-conversion-block">
    <div class="wrap">
      <div class="block">
        <div class="left-side">
          <h2 class="block-title">{{title}}</h2>
          <div class="block-description" v-html="description"></div>
          <div class="icon-list" v-if="icons">
            <div class="item" v-for="(item, index) in icons" :key="'scb-list-1-'+index">
               <img class="icon" :src="item.icon" alt="icon" />
               <p class="title">{{item.title}}</p>
               <p class="description">{{item.description}}</p>
            </div>
          </div>
          <div class="form-wrap" id="conversion-form-wrap">
            <form action="https://opendo.us1.list-manage.com/subscribe/post-json?u=b83fc74923d4150ebb2826300&id=cd867b1276&f_id=000a78e2f0&c=?" method="post" id="mc-embedded-subscribe-form-conversion" name="mc-embedded-subscribe-form-conversion" class="hidden" target="_blank" novalidate>
              <div id="mc_embed_signup_scroll">
                <h2>Subscribe</h2>

                <input type="hidden" name="u" value="b83fc74923d4150ebb2826300">
                <input type="hidden" name="id" value="cd867b1276">
                <!--<input type="hidden" name="f_id" value="000a78e2f0">-->

                <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                <div class="mc-field-group">
                  <label for="mce-EMAIL">Email  <span class="asterisk">*</span>
                </label>
                  <input type="email" v-model="emailValue" name="EMAIL" class="required email" id="mce-EMAIL">
                </div>
                <div class="mc-field-group">
                  <label for="mce-FNAME">Navn </label>
                  <input type="text" v-model="nameValue" name="FNAME" class="" id="mce-FNAME">
                </div>
                <div class="mc-field-group">
                  <label for="mce-MMERGE5">Firmanavn </label>
                  <input type="text" v-model="companyValue" name="MMERGE5" class="" id="mce-MMERGE5">
                </div>
                <div class="mc-field-group input-group">
                    <strong>Type </strong>
                    <ul>
                      <li><input type="checkbox" value="1" name="group[384117][1]" id="mce-group[384117]-384117-0"><label for="mce-group[384117]-384117-0">Privat</label></li>
                      <li><input type="checkbox" value="2" name="group[384117][2]" id="mce-group[384117]-384117-1"><label for="mce-group[384117]-384117-1">Erhverv</label></li>
                    </ul>
                </div>
                <div id="mce-responses" class="clear">
                  <div class="response" id="mce-error-response" style="display:none"></div>
                  <div class="response" id="mce-success-response" style="display:none"></div>
                </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b83fc74923d4150ebb2826300_cd867b1276" tabindex="-1" value=""></div>
                <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
                <input type="hidden" name="c" value="mailchimConversionResponse">     
                

              </div>
            </form>

            
            <div class="double-field">
              <div class="field">
                <InputField :placeholder="'Navn'" :name="'name'" :value="nameValue" type="text" theme="nr2" @focus="onFocusNameField" @changed="onChangedNameField" />
                <span class="error-label" v-if="nameErrLabel">{{nameErrLabel}}</span>
              </div>
              <div class="field">
                <InputField :placeholder="'Firmanavn'" :name="'company'" :value="companyValue" type="text" theme="nr2" @focus="onFocusCompanyField" @changed="onChangedCompanyField"  />
                <span class="error-label" v-if="companyErrLabel">{{companyErrLabel}}</span>
              </div>
            </div>
            <div class="field">
              <InputField :placeholder="'Email'" :name="'email'" :value="emailValue" type="text" theme="nr2" @focus="onFocusEmailField" @changed="onChangedEmailField"  />
              <span class="error-label" v-if="emailErrLabel">{{emailErrLabel}}</span>
            </div>

            <p v-if="mailchimpResponseVisible" :class="'mailchimp-response '+mailchimpResponseType" v-html="mailchimpResponse"></p>

            <div class="button-wrap" v-if="button && button.url">
              <a class="button" :href="button.url" :target="button.target" v-on:click="mailchimpSubmit">{{button.title}}</a>
            </div>
          </div>
        </div><div class="right-side">
          <div class="icon-list" v-if="icons">
            <div class="item" v-for="(item, index) in icons" :key="'scb-list-2-'+index">
               <img class="icon" :src="item.icon" alt="icon" />
               <p class="title">{{item.title}}</p>
               <p class="description">{{item.description}}</p>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>

</template>

<script>


import gsap from 'gsap';
import eventBus from '../../../eventBus';
import InputField from "@/components/ui/input-field/InputField.vue";

export default {
  props: ['block'],
  components: {
    'InputField' : InputField
  },
  data() {
    return {
      title: "Få tilbud og nyheder som den første",
      description: "<p>Tilmeld dig vores nyhedsbrev, og få gode råd og seneste nyt fra Opendo direkte i indbakken.</p>",
      button: {"url": "#", "title": "Tilmeld nyhedsbrev", "target": null},
      icons: [
        {
          'icon': 'https://www-stage.opendo.dk/wp-api/wp-content/uploads/2022/03/illustration-traer.svg',
          'title' : "Grøn omstilling",
          'description' : "Hold dig opdateret på de nyeste tendenser inden for den grønne omstilling."
        },{
          'icon': 'https://www-stage.opendo.dk/wp-api/wp-content/uploads/2022/03/illustration-traer.svg',
          'title' : "Grøn omstilling",
          'description' : "Hold dig opdateret på de nyeste tendenser inden for den grønne omstilling."
        },{
          'icon': 'https://www-stage.opendo.dk/wp-api/wp-content/uploads/2022/03/illustration-traer.svg',
          'title' : "Grøn omstilling",
          'description' : "Hold dig opdateret på de nyeste tendenser inden for den grønne omstilling."
        }
      ],
      nameErrLabel: '',
      companyErrLabel: '',
      emailErrLabel: '',

      nameValue: '',
      companyValue: '',
      emailValue: '',

      mailchimpResponseVisible: false,
      mailchimpResponseType: null,
      mailchimpResponse: null,

      loading: false,

    }
  },

  
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
    window.removeEventListener('mailchimpConversionVueResponse', this.onMailchimpResponse);
  },
  beforeMount(){
    
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
    
  },
  methods: {
    onMailchimpResponse: function(e){

      this.loading = false;

      if(window.mailchimpConversionVueResponse){
        var result = window.mailchimpConversionVueResponse.result;
        var msg = window.mailchimpConversionVueResponse.msg;

        // a1@a.com is an invalid email address and cannot be imported.

        
        if (msg.indexOf("Please enter a value")>=0){
          this.mailchimpResponse = "Please fill out fields";
        }else if (msg.indexOf("This email cannot be added to this list")>=0){
          this.mailchimpResponse = "This email cannot be added to this list";
        }else{
          this.mailchimpResponse = msg;
        }

        this.mailchimpResponseVisible = true;
        
        this.mailchimpResponseType = result;
        // alert(result+" "+msg);
        if (result == "error"){
          
          // this.$refs.emailField.showError(msg);
          // this.success = true;
        }
        if (result == "success"){

          this.mailchimpResponse = "<span>Du er nu tilmeldt Opendos nyhedsbrev. Du kan finde mere information om, hvordan Opendo behandler dine personoplysninger i Opendos <a href='https://www.opendo.dk/wp-api/wp-content/uploads/2022/08/Opendo-persondatapolitik.pdf' target='_blank'>persondatapolitik</a>.</span>";
          this.success = true;
        }




      }
      // alert(window.mailchimpConversionVueResponse);
      // alert("yas");
    },
    prepareContent: function(){
      this.title = this.block.title.value;
      this.description = this.block.description.value;
      
      var icons = [];
      if (this.block.icons){
        // console.log(this.block.faq);
        var iconsCount = this.block.icons.value;
        var c = 0;
        for (var i=0; i<iconsCount; i++){
          var icon = this.block['icons_'+i+'_icon'].value.url;
          var iconTitle = this.block['icons_'+i+'_title'].value;
          var iconDescription = this.block['icons_'+i+'_description'].value;
          icons.push({'title': iconTitle, 'description': iconDescription, 'icon': icon}); 
        }
      }
      this.icons = icons;

      
    },
    onFocusNameField(){
      this.mailchimpResponseVisible = false;
    },
    onChangedNameField(v){
      this.nameValue = v;
    },
    onFocusCompanyField(){
      this.mailchimpResponseVisible = false;
    },
    onChangedCompanyField(v){
      this.companyValue = v;
    },
    onFocusEmailField(){
      this.mailchimpResponseVisible = false;
    },
    onChangedEmailField(v){
      this.emailValue = v;
    },
    
    mailchimpSubmit(e){
      e.preventDefault();
      if (!this.loading){
        this.loading = true;
        this.mailchimpResponseVisible = false;
        document.querySelector("#conversion-form-wrap form input[type='submit']").click();
      }
    },
    getDimensions() {
      // this.width = document.documentElement.clientWidth;
      // this.height = document.documentElement.clientHeight;

      
      // this.newsDirection = 'vertical';
      // if(this.width< 1000){
      //   this.newsDirection = 'horizontal';
      // }
    
    },
  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );

    window.addEventListener('mailchimpConversionVueResponse', this.onMailchimpResponse);

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
form.hidden{
  display: none;
}
.soft-conversion-block{
  margin-top: 122px;
  margin-bottom: 122px;
  
  .icon-list{
    display: flex;
    flex-direction: row;
    align-items: flex-start;


    .item{
      margin-left: 20px;
      margin-right: 20px;
      width: calc(33.33% - 40px);
      text-align: center;
    }
    .icon{
      display: block;
      line-height: 0px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      height: 74px;
      width: auto;
      
    }
    .title{
      margin-bottom: 4px;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      text-align: center;
      color: #4C6C69;
    }
    .description{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      text-align: center;
      color: #4C6C69;

    }
  }
  .block-title{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #1F4743;
    margin-bottom: 20px;
  }
  .block-description{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #4C6C69;
  }
  .block{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .left-side{
    background: #FFFFFF;
    box-shadow: 0px 11px 41px -10px rgba(31, 72, 68, 0.09);
    border-radius: 20px;
    margin-right: 54px;
    width: 444px;
    padding: 48px;
    box-sizing: border-box;
    .icon-list{
      display: none;
    }
  }
  .right-side{
    display: block;
    width: calc(100% - 444px - 54px);
  }
  .button-wrap{
    text-align: center;
    margin-top: 32px;
  }
  .form-wrap{
    margin-top: 32px;
  }

  .field{
    margin-bottom: 16px;
  }
  .double-field{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .field{
      width: calc(50% - 8px);
      
    }
  }


  .mailchimp-response{
    ::v-deep a{
      text-decoration: underline;
    }
    &.error{
      color: #DF4343;
      ::v-deep a{
        color: #DF4343;
      }
    }
    &.success{
      color: #69BDB6;
      ::v-deep a{
        color: #69BDB6;
      }
    }
    &.info{
      color: #69BDB6;
      ::v-deep a{
        color: #69BDB6;
      }
    }
    max-width: 570px;
    font-size: 12px;
    padding-left: 4px;
    display: block;
    text-align: left;
    margin-top: 4px;
  }
}



@media (max-width: 1000px) {
  .soft-conversion-block{
    .icon-list{
      
    }
    .form-wrap{
      max-width: 482px;
      margin-left: auto;
      margin-right: auto;
    }
    .left-side{
      width: 100%;
      margin: 0px;
      text-align: center;
      .icon-list{
        display: flex;
        margin-top: 48px;
        margin-bottom: 48px;
      }
    }
    .right-side{
      display: none;
      .icon-list{
        display: none;
      }
    }

    .block-title{
      font-size: 24px;
      line-height: 130%;
      margin-bottom: 12px;
    }
    .block-description{
      font-size: 16px;
      line-height: 135%;
    }
  }
}

@media (max-width: #{$screen-tablet}) {
  

}
@media (max-width: 700px) {

}

@media (max-width: #{$screen-mobile}) {
 .soft-conversion-block{
    .icon-list{
      .item{
        width: 100%;
        margin: 0px;
        margin-bottom: 48px;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        &:last-child{
          margin-bottom: 0px;
        }
      }
    }
    
    .left-side{
      
      .icon-list{
        display: block;
      
      }
    }
    .right-side{

    }
  }
}

</style>