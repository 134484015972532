import { Component, Vue } from 'vue-property-decorator';

import CheckboxField from '../checkbox-field/CheckboxField.vue';
import CheckbuttonField from '../checkbutton-field/CheckbuttonField.vue';
import IntervalField from '../interval-field/IntervalField.vue';
import template from './DropdownFilter.vue';

@Component({
  mixins: [template],
  props: ['icon', 'title', 'info', 'children', 'childrenType', 'customClass'],
  components: {
    CheckboxField, IntervalField, CheckbuttonField
  }
})
export default class DropdownFilter extends Vue {

}