import router from '@/router';
import * as localForage from "localforage";
import Utils from './Utils';
import { filterItem } from '../carlist';
import { isNullOrUndefined } from 'is-what';

export const enum StorageType {
    USERNAME = "leasing_userName",
    CUST_TYPE = "leasing_cust_type",
    CUST_SPEC = "leasing_cust_spec",

    //generale variabler til api-services
    TOKEN = "token",
    LOGIN_REQUIRED = "loginRequired"
}
export const enum CustomerSpec {
    NONE = "leasing_none",
    //forhandler
    DEALER = "leasing_dealer",
    //Assurandoer
    AGENT = "leasing_agent",
    RELEASING = "leasing_releasing"
}

export interface DataObject {
    step1: Step1,
    step2: Step2,
    step3: Step3,
    service: Service,
    formData: FormData,
    insurrance: Insurrance,
    isCampaign: boolean,
    carId: string,
    leasingType: string,
    redirectPageAfterUpload: string,
    disclaimerText: string,
    loginAuthorizationToken: string,
    taxValueText: string,
    monthlyPaymentText: string
    showMoreLabel: string,
    extraEquipmentHeading: string,
    topArea: string,
    buttomArea: string,
    hideElementId: string,
    vehicleServiceUrl: string
}

export interface Step1 {
    smallHeader: string,
    largeHeader: string,
    svgHeader: string,
    typeFilterLabel: string,
    gearFilterLabel: string,
    priceFilterLabel: string,
    brandFilterLabel: string,
    fuelFilterLabel: string,
    modelFilterLabel: string,
    taxValueFilterLabel: string,
    initialPaymentText: string,
    releasingBannerText: string,
    seeVariantText: string,
    priceFilterItems: Array<filterItem>,
    taxFilterItems: Array<filterItem>
}

export interface Step2 {
    filterLabel : string,
    newCarLabel : string,
    usedCarLabel : string,
    initialPaymentText2 : string,
    showVariantsLabel : string,
    fuelLabel : string,
    milesLiterLabel : string,
    horsepowerLabel : string,
    motorLabel : string,
    doorsLabel : string,
    energyLabel : string,
    ncapLabel: string,
    towCapacityLabel: string,
    buttonTekst : string,
    buttonStyle : string
}

export interface Step3 {
    heading :string,
    milesHeading :string,
    milesSubHeading :string,
    leasingDropdownLabel: string,
    leasingMonthText: string,
    milesDropdownLabel :string,
    colorHeading :string,
    colorDropdownLabel: string,
    colorPlaceholder:string,
    colorHelperText :string,
    factoryHeading :string,
    retofitHeading :string,
    serviceHeading :string,
    tierHeading :string,
    insuranceHeading: string,
    insuranceDropdownLabel: string,
    insurancePlaceholder: string,
    disableInspectionRepport: string
}

export interface Service {
    roadHelp : string,
    replaceService : string,
    replaceCrash : string,
    shell : string,
    circleK : string,
    unoX : string,
    q8 : string,
    ok: string,
    tierAgreement:string,
    tierAgreementWinter: string
}

export interface FormData {
    heading: string,
    subHeading: string,
    extraSubHeading: string,
    infoText: string,
    dateLabel: string,
    cvrLabel: string,
    companyLabel: string,
    contactPersonLabel: string,
    firstnameLabel: string,
    lastnameLabel: string,
    cprLabel: string,
    addressLabel: string,
    zipcodeLabel: string,
    cityLabel: string,
    phoneLabel: string,
    emailLabel: string,
    commentLabel: string,
    agreementText: string,
    agreementLinkText: string,
    agreementInfo: string,
    warningText: string,
    printButtonText: string,
    printButtonStyle: string,
    submitButtonText: string,
    submitButtonStyle: string,
    redirectPageAfterSubmit: string,
    redirectPageAfterReleasingSubmit: string,
}

export interface Insurrance {
    insuranceDiscount: number,
    insuranceContribution: number,
    leasingTiaMatchRating: number
}

export const sharedFunctions = {

    // Removing mini Form
    removeMiniFormFunc() {
        let formElement = <HTMLElement>document.getElementById("2bc07b0b-19bc-4728-9845-58fb44d3adef")
        if (formElement) {
            formElement.style.display = 'none'
        }
    }
}

export default class UserData {

    utils = new Utils();

    leasingtype: string = 'privat';

    epiData: DataObject = null;
    loginRequired: boolean = false;
    isDeepLink: boolean = false;
    csrftoken: string = ""
    serviceUrl: string = "";
    epiServiceUrl: string = "/webapi";
    //loginAuthorizationToken: string = "Basic YWxtYnJhbmQtYnl0ZWxhYi1wcm9kOjMzZTIzYjk0LTI5ZjUtNDgwZC05NzIxLTEyYmI2MjU1MjA1Ng==";

    isUsed: boolean = false;
    isCorporate: boolean = false;
    isDealer: boolean = false;
    colorErrLabel: string = "";
    insurancesErrLabel: string = "";

    //readonly apiKey: string = "x-api-key=1f4b3146-4b27-4554-ab49-1dff38933b66";

    //recapcha google key
    //siteKey: string = "6LdXXE8UAAAAAFKTmwc9rlXjK_IP9iDZsJXtd1Ei";

    constructor() {
        this.init();
    }

    init() {
        // let leasingComponent = document.querySelector('#leasing-component');
        // if (leasingComponent === null) {
        //     return;
        // }
        // let leasingComponentObjectb64 = this.utils.cleanAttr(leasingComponent.getAttributeNode('leasing-data'));
        // if (leasingComponentObjectb64 === null) {
        //     console.log("leasing-data == null")
        //     return;
        // }

        // this.epiData = JSON.parse(decodeURIComponent(escape(window.atob(leasingComponentObjectb64))));

        // Assigning type of user
        if(window.location.href.indexOf("erhvervsleasing") > -1 || window.location.href.indexOf("erhverv") > -1) {
            this.epiData = require("../../json/erhverv.json");
        } else if(window.location.href.indexOf("releasing") > -1) {
            this.epiData = require("../../json/releasing.json");
        } else if(window.location.href.indexOf("forhandler") > -1) {
            this.epiData = require("../../json/forhandler.json");
        } else {
            this.epiData = require("../../json/privat.json");
        }

        // set labels
        this.epiData.leasingType
        if (this.epiData.leasingType === "Erhvervsleasing") {
            this.isCorporate = true;
        } else if (this.epiData.leasingType === "Releasing") {
            this.isUsed = true;
        }  else if (this.epiData.leasingType === "Forhandler") {
            this.isDealer = true;
        }

        // Forhandler template loaded, make sure correct Basic authorization is passed.
        if(window.location.href.indexOf("forhandler") > -1) {
            if ( window.location.hostname.toUpperCase() === 'www.opendo.dk'.toUpperCase() || window.location.hostname.toUpperCase() === 'www-prod.opendo.dk'.toUpperCase() || window.location.hostname.toUpperCase() === 'opendo.dk'.toUpperCase()  ) {
                this.epiData.loginAuthorizationToken = "Basic YWxtYnJhbmQtYnl0ZWxhYi1wcm9kOjMzZTIzYjk0LTI5ZjUtNDgwZC05NzIxLTEyYmI2MjU1MjA1Ng==";
            } else if (window.location.hostname.toUpperCase() === 'www-stage.opendo.dk'.toUpperCase()) {
                this.epiData.loginAuthorizationToken = "Basic YWxtYnJhbmQtYnl0ZWxhYi1zdGFnZTowNzdkNDhmMS0wMzYwLTRhYTAtYTY4OS01ZTNlOThlY2RkNjE=";
            } else if (window.location.hostname.toUpperCase() === 'www-test.opendo.dk'.toUpperCase()) {
                this.epiData.loginAuthorizationToken = "Basic YWxtYnJhbmQtYnl0ZWxhYi10ZXN0OjAwMjdjYWMwLTQ0OGMtNDRkMy04YjdiLTFhNjk5NDkwY2FhOQ==";
            } else {
                // Developer locally change here
                this.epiData.loginAuthorizationToken = "Basic YWxtYnJhbmQtYnl0ZWxhYi10ZXN0OjAwMjdjYWMwLTQ0OGMtNDRkMy04YjdiLTFhNjk5NDkwY2FhOQ==";
            }
            // console.log("Token for login: " + this.epiData.loginAuthorizationToken );
        }

        ////csrftoken
        // this.csrftoken = this.utils.cleanAttr(leasingComponent.getAttributeNode('csrf-token'));

        //tjekker om kunden er påkrævet at logge ind. login-required er sat op i epi-server block

        if (this.epiData.leasingType !== "" && this.epiData.leasingType === "Forhandler") {
            //login er påkrævet
            this.loginRequired = true;
            localForage.setItem(StorageType.LOGIN_REQUIRED, true);
            this.hasLoggedIn()
                .then(value => {
                    if (!value) {
                        router.push({
                            name: 'ForhandlerLogin'
                        })
                    }
                });
        }
        //deeplink er ikke tilladt på sider bag login
        else {
            //login er ikke påkrævet på block
            localForage.setItem(StorageType.LOGIN_REQUIRED, false);
            //deeplink til en bestemt bil
            this.deepLinkToCarCalculator(this.epiData);
        }
    }

    deepLinkToCarCalculator(epiData: DataObject) {
        let _carId = epiData.carId;
        if (_carId !== null && _carId !== "") {
            this.isDeepLink = true;
            router.push({
                name: 'CarCalcNoModel',
                params: {
                    isCorporate: this.isCorporate.toString(),
                    vehicleId: _carId
                }
            })
        }
    }

    clearStorage() {
        localForage.clear().then(function () {
            //console.log('Database is now empty.');
        }).catch(function (err: any) {
            console.error(err);
        });
    }

    login(username: string) {
        this.setUsername(username);
    }

    static async logout() {
        return Promise.all(
            [
                localForage.removeItem(StorageType.USERNAME),
                localForage.removeItem(StorageType.CUST_TYPE),
                localForage.removeItem(StorageType.CUST_SPEC),
                localForage.removeItem(StorageType.TOKEN)
            ]);
    }

    setUsername(username: string) {
        localForage.setItem(StorageType.USERNAME, username);
    }

    async getUsername() {
        return await localForage.getItem(StorageType.USERNAME).then((value) => {
            return value;
        }).catch((err) => {
            console.log(err);
            return null;
        });
    }

    async hasLoggedIn() {
        return await localForage.getItem(StorageType.TOKEN).then((value) => {
            if (value !== null) {
                return true;
            }
            return false;
        }).catch((err) => {
            console.log(err);
            return false;
        });
    }

    setCustomerType(value: string) {
        localForage.setItem(StorageType.CUST_TYPE, value);
    }

    async getCustomerType() {
        return await localForage.getItem(StorageType.CUST_TYPE).then((value) => {
            return value;
        }).catch((err) => {
            console.log(err);
            return null;
        });
    }

    setCustomerSpec(value: string) {
        localForage.setItem(StorageType.CUST_SPEC, value);
    }

    async getCustomerSpec() {
        return await localForage.getItem(StorageType.CUST_SPEC).then((value) => {
            return value;
        }).catch((err) => {
            console.log(err);
            return null;
        });
    }

    setLoginToken(value: string) {
        localForage.setItem(StorageType.TOKEN, value);
    }

    async getLoginToken() {
        return await localForage.getItem(StorageType.TOKEN).then((value) => {
            return value;
        }).catch((err) => {
            console.log(err);
            return null;
        });
    }

    removeMiniFormFunc(id: string) {
        if (!isNullOrUndefined(id)) {
            let formElement = <HTMLElement>document.getElementById(id)
            if (formElement) {
                formElement.style.display = 'none'
            }
        }
    }

    showMiniFormFunc(id: string) {
        if (!isNullOrUndefined(id)) {
            let formElement = <HTMLElement>document.getElementById(id)
            if (formElement) {
                formElement.style.display = 'block'
            }
        }
    }


    insurances: Array<string> = [];
}
