<template>
  <div class="main page-404">
    

    <section class="section fade-on-enter not-found-section">
      <div class="wrap">
        <div class="sides flex flex-s3">
          <div class="left-side">
            <svg width="289" height="340" viewBox="0 0 289 340" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M140.821 340C218.594 340 281.642 276.91 281.642 199.085C281.642 121.26 218.594 58.1699 140.821 58.1699C63.0476 58.1699 0 121.26 0 199.085C0 276.91 63.0476 340 140.821 340Z" fill="#C7E5E4"/>
              <path d="M141.835 216.432L71.9302 146.395M182.306 177.917C209.348 209.671 199.883 238.122 182.306 256.98L141.835 216.432L182.306 177.917ZM182.306 177.917L270.238 89.9252L279.988 9.021L199.138 18.8694L111.389 106.769L182.306 177.917ZM182.306 177.917L141.835 216.432L182.306 177.917ZM111.389 106.769C88.6463 85.1006 56.8455 83.0221 32.3789 106.769L71.9302 146.395L111.389 106.769ZM111.389 106.769L71.9302 146.395L111.389 106.769Z" stroke="#1F4743" stroke-width="9"/>
              <path d="M214.315 98.8516C227.522 98.8516 238.229 88.1375 238.229 74.9209C238.229 61.7044 227.522 50.9902 214.315 50.9902C201.107 50.9902 190.4 61.7044 190.4 74.9209C190.4 88.1375 201.107 98.8516 214.315 98.8516Z" stroke="#1F4743" stroke-width="9"/>
              <path d="M109.623 237.822L49.6519 177.719L84.3282 159.771L127.467 204.227L109.623 237.822Z" stroke="#1F4743" stroke-width="9"/>
              <line y1="-4.5" x2="55.0928" y2="-4.5" transform="matrix(-0.70687 0.707343 -0.70687 -0.707343 45.7461 208.481)" stroke="#1F4743" stroke-width="9"/>
              <line y1="-4.5" x2="46.3278" y2="-4.5" transform="matrix(-0.70687 0.707343 -0.70687 -0.707343 60.3608 223.095)" stroke="#1F4743" stroke-width="9"/>
              <line y1="-4.5" x2="70.6749" y2="-4.5" transform="matrix(-0.70687 0.707343 -0.70687 -0.707343 75.938 237.708)" stroke="#1F4743" stroke-width="9"/>
            </svg>
          </div>
          <div class="content">
            
            <h1>{{title}}</h1>
            <h3>{{subTitle}}</h3>

            <div class="card-style links-list">
              <h2 class="title">{{listTitle}}</h2>
              <div class="buttons">
                <router-link class="item link-button-item" :to="link.link.url" v-for="link in links" :key="link.link.title" >
                  <span class="label">
                    {{link.link.title}}
                  </span><span class="icon">
                    <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.4766 1L30.4766 7L24.4766 13" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M30 7H2" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
export default {
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      thisSlug: "",
      title: null,
      subTitle: null,
      listTitle: null,
      links: null
    }
  },
  beforeMount(){
    // alert(footerData.fields.copyright);
    // this.copyright = "b";
  },
  created(){
    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        this.setCMSGlobalData();

        
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {
        
        this.setCMSPageData();
        
        
      }
    );

    if (this.cmsGlobalData){
      this.setCMSGlobalData();
    }
    if (this.cmsPageData){
      this.setCMSPageData();
    }

  },
  mounted(){
    
  },
  methods: {
    setCMSGlobalData(){
      this.title = this.cmsGlobalData.page_404.title;
      this.subTitle = this.cmsGlobalData.page_404.subtitle;
      this.listTitle = this.cmsGlobalData.page_404.list_title;
      this.links = this.cmsGlobalData.page_404.list;
    },
    setCMSPageData(){
      this.thisSlug = this.cmsPageData.page.url;
    },
  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
  .page-404{
    padding-bottom: 148px;


    .left-side{
      padding-top: 64px;
      padding-bottom: 64px;
      text-align: center;
      padding-left: 48px;
      padding-right: 48px;
      svg{
        display: inline-block;
      }
    }
    .content{
      max-width: 716px;
      margin-left: auto;
      padding-left: 24px;
      box-sizing: border-box;
      padding-top: 96px;
    }
    h1{
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
      color: $color-primary;
      
    }
    h3{
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 130%;
      color: $color-primary;
      opacity: 0.8;
      margin-top: 20px;
    }
    .links-list{
      padding: 48px;
      width: 100%;
      box-sizing: border-box;
      margin-top: 86px;
      .title{
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 130%;
        color: $color-primary;
        margin-bottom: 40px;
      }
      
    }
  }

@media (max-width: 1000px) {
    .page-404{
      .sides{
        display: block;
      }
      .content{
        max-width: 100%;
        padding-top: 0px;
        padding-left: 0px;
      }
      h1{
        max-width: 692px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      h3{
        text-align: center;
      }
    }
}
</style>