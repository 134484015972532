<template>

    <div>

        <AppHeader></AppHeader>
        
        
        <LoginView :view="'forhandler-login'" @submit="onCustomSubmit"/>

        <!--
        <div class="padding-top">
            <div class="container bgcolor-secondary__blue-light py-4 mb-5" style="max-width: 588px; max-height: 588px">
                <div class="row">
                    <div class="col text-center">
                        <p class="primary-font__bold__xlarge lineBelow">Log på</p>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-10">

                        <form v-on:submit="onSubmit" class="form__fields__container">
                            <div class="form-group form__item ">
                                <label for="userName">Brugernavn</label>
                                <input id="userName" class="form-control" type="text" v-model="person.userName" v-on:keyup.enter="login()" @focus="resetUserNameError()" />
                                <p class="secondary-font__regular color-primary__red">{{userNameErrLabel}}</p>
                            </div>
                            <div class="form-group form__item ">
                                <label for="password">Kodeord</label>
                                <input id="password" class="form-control" type="password" v-model="person.password" v-on:keyup.enter="login()" @focus="resetPasswordError()" />
                                <p class="secondary-font__regular color-primary__red">{{passwordErrLabel}}</p>
                            </div>
                        </form>

                        <div class="row mt-4 mb-4 align_center text-center">
                            <div class="col-12">
                                <b-button pill variant="info" class="custom-button" @click="login()">Log på</b-button>
                            </div>
                        </div>

                        <p class="secondary-font__large text-center pt-4">
                            Har du glemt kodeordet? Kontakt os på tlf. 70 60 70 10.
                        </p>

                    </div>
                </div>
            </div>
        </div>
        -->  
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import router from "@/router";
    import { Component, Emit, Inject, Model, Prop, Provide, Watch } from "vue-property-decorator";
    import AbAxios from '../../common/ab_axios_leasing';
    import UserData from '../providers/UserData';
    import { CustomerSpec } from '../providers/UserData';
    import AbLightbox from '../../common/ab_lightbox';
    import AppHeader from "../../AppHeader.vue";
    import AbGtm from '../../common/ab_gtm';
    import LoginView from  "@/views/logins/logins";

    interface formPerson {
        userName: string,
        password: string,
    }


    @Component({
        name: 'leasing-login-component',
        components: { AppHeader, LoginView }
    })
    export default class Form extends Vue {

        person: formPerson = {
            userName: "",
            password: ""
        }

        userData: UserData = this.$store.getters.getUserData;
        abLightbox = new AbLightbox();
        abGtm = new AbGtm();
        abAxios = new AbAxios(this.userData.serviceUrl, "application/json; charset=utf-8", this.userData.csrftoken);

        // loginServiceUrl: string = "/security/oauth/token";
        loginServiceUrl: string = "/security/login";

        userNameErrLabel: string = '';
        passwordErrLabel: string = '';

        onSubmit(evt) {
            evt.preventDefault();
        }

        resetUserNameError() {
            this.userNameErrLabel = "";
        }

        resetPasswordError() {
            this.passwordErrLabel = "";
        }

        resetLabels() {
            this.userNameErrLabel = '';
            this.passwordErrLabel = '';
        }
        onCustomSubmit(data){
            this.person.userName = data.username;
            this.person.password = data.password;
            this.login();
        }
        
        


        validateSubmit() {
            this.resetLabels();
            let validated: boolean = true;

            if (this.person.userName === undefined || this.person.userName === "") {
                this.userNameErrLabel = "Du mangler at angive brugernavn";
                validated = false;
            }

            if (this.person.password === undefined || this.person.password === "") {
                this.passwordErrLabel = "Du mangler at angive kodeord";
                validated = false;
            }

            return validated;
        }

        login() {
            if (this.validateSubmit()) {
                this.validateViaRest(this.loginServiceUrl);
            }
        }

        validateViaRest(serviceUrl: string) {
            var self = this;

            let headers: any = {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": self.userData.epiData.loginAuthorizationToken,
                "x-csrf-token": this.userData.csrftoken
            }

            let formData = new FormData();
            formData.append("grant_type", "password");
            //formData.append("client_id", "almbrand-bytelab-test");
            formData.append("username", this.person.userName);
            formData.append("password", this.person.password);
            formData.append("subsystem", "dealer");


            /*

            self.userData.setLoginToken("3ec55d56-e262-484a-8cd8-dd0eba30b28e");
            let salesChannelGuid = "7c9e6679-7425-40de-944b-e07fc1f90ae7";
            //logger kunden
            self.userData.login("aa");
            self.userData.setCustomerSpec(CustomerSpec.DEALER);
            //indtil videre privat kunde
            //self.userData.setCustomerType(CustomerType.CONSUMER);

            //vent til at kunden er logget ind så routing tjek ikke fanger ham
            self.userData.hasLoggedIn().then(value => {
                router.push({
                    name: 'DealerCarList',
                    params: {
                        // customerType: customerType,
                        salesChannelGuid: salesChannelGuid
                    }
                })
            });

            return;
            

            */


            this.abAxios.abPostWithHeaders(serviceUrl, formData, headers, true, function successCallback(response: any) {
                self.userData.setLoginToken(response.data);//OPENDO-4735 .access_token

                /*
                 * Saleschannel og profilid bliver hentet fra bytelab via api-integration
                 * dvs Saleschannel er kun med her fordi sitet forventer feltet. Alle forhandlere
                 * har en unik Saleschannel.
                 * Forhandler er p.t. altid privat
                 * Service hos bytelab er følgende: /api/SalesChannelsApi/GetAvailableSalesChannelsAndProfiles
                */
                //let customerType = CustomerType.CONSUMER;
                let salesChannelGuid = "7c9e6679-7425-40de-944b-e07fc1f90ae7";
                //logger kunden
                self.userData.login(self.person.userName);
                self.userData.setCustomerSpec(CustomerSpec.DEALER);
                //indtil videre privat kunde
                //self.userData.setCustomerType(CustomerType.CONSUMER);

                //vent til at kunden er logget ind så routing tjek ikke fanger ham
                self.userData.hasLoggedIn().then(value => {
                    router.push({
                        name: 'DealerCarList',
                        params: {
                            // customerType: customerType,
                            salesChannelGuid: salesChannelGuid
                        }
                    })
                });

            }, function errorCallback(error: any) {
                //console.log(error);
                let errorRes = error.response,
                    statusCode = errorRes.status,
                    statusText = errorRes.statusText,
                    errorMsg = "Login fejlede. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
                    self.abGtm.triggerGtmEvent("fejl", "Login", '[' + self.abGtm.getUtcTimestamp() + '] ' + errorMsg);

                self.abLightbox.standardLightText("Login fejlede", "Brugernavn eller kodeord er forkert... </br> Ring til os på Tlf. <a class='popup__content__phone-link' href='tel: 70607010'> 70 60 70 10</a>");
            });
        }
    }
</script>


<style lang="scss">
    @import "../../abdk/styles/legacy/style.scss";

    .readline, .abbreadcrumb {
        display: none;
    }

    .padding-top-mobile {
        z-index: 999;
        /*
        position: fixed;
        top: 16px;
        right: 0;
        left: 0;


        @media (max-width: $medium-width) {
            position: relative;
            top: 0;
        }*/

    }

</style>
