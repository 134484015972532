<template>
  <section class="section fade-on-enter timeline-section">
    <div class="wrap">
      <h2 class="section-title">{{ title }}</h2>
      <div class="timeline-list">
        <div class="item " v-for="item in timelineBlock" :key="item.title">
            <span class="line">
              <span class="l1"></span>
              <span class="c"></span>
              <span class="l2"></span>
            </span>
          <div class="inner fade-on-enter">

            <p class="year">{{ item.title }}</p>
            <div class="description"><p>{{ item.text }}</p></div>

            <span class="spike">
                <svg width="49" height="227" viewBox="0 0 49 227" fill="none" xmlns="http://www.w3.org/2000/svg"
                     preserveAspectRatio="none">
                  <path
                    d="M1.85913 0C10.8146 0 18.6785 5.95331 21.1087 14.5728L47.4699 108.073C48.4705 111.622 48.4705 115.378 47.4699 118.927L21.1087 212.427C18.6785 221.047 10.8146 227 1.85913 227H0V0H1.85913Z"
                    fill="white"/>
                </svg>
              </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import eventBus from "@/eventBus";

export default {
  props: ['block'],
  data() {
    return {
      timelineBlock: null,
      title: '',
    }
  },
  methods: {
    prepareContent: function () {
      let _timelineBlock = [];
      for (let i = 0; i < this.block.timeline_entry.value; i++) {
        _timelineBlock.push({
          title: this.block['timeline_entry_' + i + '_title'].value,
          text: this.block['timeline_entry_' + i + '_text'].value
        });
      }

      this.timelineBlock = _timelineBlock;
      this.title = this.block.title.value;
    },
  },
  mounted() {
    this.prepareContent();
  },
  created() {
    this.$watch(
      () => this.timelineBlock,
      (toParams, previousParams) => {
        this.$nextTick(function () {
          eventBus.$emit("handlePageAnimations");
        });
      }
    );
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );
  },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.timeline-section {
  background: rgba(105, 189, 182, 0.09);
  border-radius: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 80px;
  padding-bottom: 120px;

  .section-title {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    color: #1F4743;
    margin-bottom: 80px;
  }

  .timeline-list {
    .item {
      display: block;
      text-align: right;
      position: relative;
      padding-bottom: 4px;
      padding-top: 4px;

      .line {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 2px;
        margin-left: -1px;
        left: 50%;
        display: block;

        .l1, .l2 {
          display: block;
          background: #69BDB6;
          position: absolute;
          width: 100%;
          height: calc(50% - 16px);
        }

        .l1 {
          top: 0;
        }

        .l2 {
          bottom: 0;
        }

        .c {
          display: block;
          position: absolute;
          width: 24px;
          height: 24px;
          margin-top: -12px;
          margin-left: -12px;
          left: 50%;
          top: 50%;
          background: #E1F2F0;
          border: 2px solid #69BDB6;
          box-sizing: border-box;
          border-radius: 100px;
        }
      }

      .inner {
        position: relative;
        display: inline-block;
        background: #FFFFFF;
        border-radius: 0 20px 20px 0;
        width: calc(50% - 28px - 48px);
        text-align: left;
        padding: 32px 32px 48px 40px;
        box-sizing: border-box;

        .spike {
          position: absolute;
          top: 0;
          left: -46px;
          right: auto;
          bottom: 0;
          height: 100%;
          width: 48px;
          transform: rotate(180deg);

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      &:nth-of-type(2n + 1) {
        text-align: left;

        .inner {
          text-align: right;
          border-radius: 20px 0 0 20px;

          .spike {
            right: -46px;
            left: auto;
            transform: rotate(0deg);
          }
        }
      }

      .year {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
        color: #1F4743;
      }

      .description {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #4C6C69;
      }

      &:first-child {
        .line {
          .l1 {
            display: none;
          }
        }
      }

      &:last-child {
        .line {
          .l2 {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: #{$screen-tablet}) {
  .timeline-section {
    margin-left: 0;
    margin-right: 0;

    .timeline-list {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;

      .item {
        padding-top: 40px;
        padding-bottom: 40px;

        .line {
          .l1 {
            top: 16px;
          }

          .l2 {
            bottom: 16px;
          }

          .c {
            top: 0;
          }
        }

        .inner {
          border-radius: 20px;
          text-align: center;
          width: 100%;

          .spike {
            display: none;
          }
        }

        &:nth-of-type(2n + 1) {
          .inner {
            border-radius: 20px;
            text-align: center;
            width: 100%;

            .spike {
              display: none;
            }
          }
        }

        &:first-child {
          .line {
            .l1 {
              display: block;
            }
          }
        }

        &:last-child {
          .line {
            .l2 {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: #{$screen-mobile}) {
  .timeline-section {
    padding-top: 48px;
    padding-bottom: 40px;

    .section-title {
      font-size: 32px;
      line-height: 120%;
      margin-bottom: 34px;
    }
  }
}
</style>
