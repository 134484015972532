<template>
    <div class="abHeader">
        <svg class="abHeader__icon" v-if="iconHref"><use :xlink:href="iconHref"></use></svg>
        <div class="abHeader__tagline" v-if="tagline">{{tagline}}</div>
        <div class="abHeader__title" v-if="title && htagsize === 1"><h1>{{title}} <em v-if="emp">{{emp}}</em></h1></div>
        <div class="abHeader__title" v-else-if="title && htagsize === 2"><h2>{{title}} <em v-if="emp">{{emp}}</em></h2></div>
        <div class="abHeader__title" v-else-if="title && htagsize === 3"><h3>{{title}} <em v-if="emp">{{emp}}</em></h3></div>
        <div class="abHeader__title" v-else-if="title && htagsize === 4"><h4>{{title}} <em v-if="emp">{{emp}}</em></h4></div>
        <div class="abHeader__title" v-else-if="title && htagsize === 5"><h5>{{title}} <em v-if="emp">{{emp}}</em></h5></div>
        <hr class="abHeader__divider" v-if="title" />
        <div class="abHeader__introtext richtext" v-if="richText" v-html="richText"></div>
    </div>
</template>

<script lang="ts">
    import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from 'vue-property-decorator';

    @Component({
        name: 'abHeader-component',
        components: {
        }
    })
    export default class AbHeader extends Vue {

        @Prop()  iconHref: string;
        @Prop()  tagline: string;
        @Prop() title: string;
        @Prop() emp: string;
        @Prop() richText: string;
        @Prop() htagsize: number;

    }

</script>

<style></style>
