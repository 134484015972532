<template>
    <div :class="styleClasses">

        <div class="value__wrapper__mobile">
            <span class="value__result" v-html="getFormattedValue(value)"></span>
            <span v-if="valueSubLabel !== undefined && valueSubLabel !== null" class="value__sub__result">{{ valueSubLabel }}</span>
        </div>

        <vue-slider ref="slider"
                    v-model="value"
                    :data="data"
                    :lazy=true
                    :min="min"
                    :max="max"
                    :piecewiseLabel="piecewiseLabel">
            <div slot="tooltip" slot-scope="{ value }">
                <div class="value__wrapper__desktop ">
                    <span class="value__result" v-html="getFormattedValue(value)"></span>
                    <span v-if="valueSubLabel !== undefined && valueSubLabel !== null" class="value__sub__result">{{ valueSubLabel }}</span>
                </div>
            </div>
        </vue-slider>

        <div v-if="useCustomLabels" class="customPieces__wrapper">
            <template v-if="data">
                <span class="customPieces__piece">
                    <span v-html="getFormattedValue(data[0])"></span>
                    <span v-if="minTooltip !== undefined && minTooltip !== null" class="tooltip-icon">
                        <img class="definition" :data-dfnid="minTooltip" src="/ui/assets/svg/systemicon_lightblue_Question.svg" width="20" height="20" tabindex="0">
                    </span>
                </span>
                <span class="customPieces__piece">
                    <span v-html="getFormattedValue(data[data.length -1])"></span>
                    <span v-if="maxTooltip !== undefined && maxTooltip !== null" class="tooltip-icon">
                        <img class="definition" :data-dfnid="maxTooltip" src="/ui/assets/svg/systemicon_lightblue_Question.svg" width="20" height="20" tabindex="0">
                    </span>
                </span>
            </template>
            <template v-if="!data">
                <span class="customPieces__piece">{{ min }}</span>
                <span class="customPieces__piece">{{ max }}</span>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
    import vueSlider from 'vue-slider-component';
    // Docs: https://nightcatsama.github.io/vue-slider-component/example/
    // https://github.com/NightCatSama/vue-slider-component

    @Component({
        name: 'range-component',
        components: {
           vueSlider
        }
    })
    export default class ResultRangeInput extends Vue {

        @Prop() private styleClasses: string;
        
        @Prop() private data: [Number, String, Array<any>, Object];
        @Prop() private lazy: boolean;
        @Prop() private min: number;
        @Prop() private max: number;
        @Prop() private piecewiseLabel: boolean;
        @Prop() private useCustomLabels: boolean;
        @Prop() private theValue!: string; 
        @Prop() private valueSubLabel: string; //If component should show some static extra text under value, f.eks. "pr. md. før skat"
        @Prop() private selectedIndex: number;
        @Prop() private minTooltip: string; //If component should show min tooltip
        @Prop() private maxTooltip: string; //If component should show max tooltip
        @Prop() private format: string;
        @Provide() private value: any = this.theValue;
        @Provide() private isInit: boolean = false;

        public $refs: any;

        public updated(): void {
            if (this.selectedIndex === undefined || this.selectedIndex === null) {
                this.isInit = true;
                return;
            } 
            this.setIndex(this.selectedIndex);
            this.$nextTick(() => {
                this.isInit = true;
            })
        }

        @Watch('value', {
            immediate: false,
            deep: true
        })
        
        public valueChanged(newValue, oldValue) {

            const changedValue = {
                index: this.getIndex(),
                value: newValue,
                ref: this.$el.id,
                isInit: this.isInit,
            };
            this.$emit('changedIndex', changedValue);
        }

        public setValue(value: [Number, String, Array<any>, Object]) {
            this.$refs.slider.setValue(value);	
        }

        public setIndex(index: number) {
            this.$refs.slider.setIndex(index);
        }

        public getValue() {
            return this.$refs.slider.getValue();
        }

        public getIndex() {
            return this.$refs.slider.getIndex();
        }

        public refresh() {
            this.$refs.slider.refresh();
        }

        public setTheValue(_value: string) {
            this.value = _value;
        }

        public getFormattedValue(value) {
            if (this.format === null || this.format === undefined || this.format === '') return value;
            let formattedValue = value + ' ' + this.format; 
            return formattedValue;
        }
    }

</script>

<style></style>
