import { Component, Watch } from "vue-property-decorator";

import AbAmount from '../../common/ab_amount';
import AbAxios from '../../common/ab_axios_leasing';
import AbGtm from '../../common/ab_gtm';
import AbLightbox from '../../common/ab_lightbox';
import AppHeader from "../../AppHeader.vue";
import Basket from './Basket.vue';
import CarGallery from '@/components/ui/car-gallery/CarGallery';
import CheckboxField from '@/components/ui/checkbox-field/CheckboxField';
import CheckboxFieldWithPrice from '@/components/ui/checkbox-field-with-price/CheckboxFieldWithPrice';
import FormHelper from './FormHelper';
import InfoButton from '@/components/ui/info-button/InfoButton';
import InkluderetUdstyrComponent from './InkluderetUdstyr.vue';
import InputField from '@/components/ui/input-field/input-field';
import InsuranceCalc from './insurancecalc';
import LottieAnimation from 'lottie-web-vue';
import PinField from '@/components/ui/pin-field/PinField';
import Print from './Print.vue';
import RangeInput from '../../shared/inputs/range/RangeInputComponent.vue';
import RevealBlockComponent from '../../shared/revealblock/RevealBlockToggle.vue';
import SelectField from '@/components/ui/select-field/select-field';
import ToggleLineComponent from '../../shared/toggleline/ToggleLineSimpleComponent.vue';
import Tooltipcomponent from '../../shared/tooltip/Tooltip.vue';
import UserData from '../providers/UserData';
import UserForm from './Form.vue';
import UserFormDealer from './FormDealer.vue';
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import eventBus from '../../../../../../eventBus';
import { isNullOrUndefined } from 'is-what';
import template from './CarCalc.vue';
import vueSlider from 'vue-slider-component';

Vue.use(VueScrollTo)

interface calcSlider {
    period: number,
    mileage: number
}

@Component({
    name: 'leasing-carcalc-component',
    mixins: [template],
    components: {
        UserForm, UserFormDealer, vueSlider, 'range-component': RangeInput, Print, Basket, AppHeader,
        'toggleline-component': ToggleLineComponent, 'leasing-car-insurance-component': InsuranceCalc,
        'reveal-block-component': RevealBlockComponent,
        'tooltip-component': Tooltipcomponent,
        'inkluderetudstyr-block-component' : InkluderetUdstyrComponent,
        'CarGallery' : CarGallery,
        'select-field' : SelectField,
        'CheckboxField' : CheckboxField,
        'CheckboxFieldWithPrice' : CheckboxFieldWithPrice,
        'InfoButton' : InfoButton,
        'PinField' : PinField,
        'InputField' : InputField,
        'lottie-animation': LottieAnimation
    }
})
export default class CarCalc extends Vue {

    $refs: {
        scrollKm: any;
        scrollColor: any;
        scrollFabrik: any;
        scrollEfterStep: any;
        scrollService: any;
        scrollDaek: any;
        scrollForsikring: any;
        singlecolorid: any;

        formFieldFirstName: any;
        formFieldLastName: any;
        formFieldPostNr: any;
        formFieldBy: any;
        formFieldTelefon: any;
        formFieldEmail: any;

        formFieldFirstNameCo: any;
        formFieldLastNameCo: any;
        formFieldPostNrCo: any;
        formFieldByCo: any;
        formFieldTelefonCo: any;
        formFieldEmailCo: any;

        formFieldCVRCorp: any;
        formFieldFirmNameCorp: any;
        formFieldKontaktPersonCorp: any;
        formFieldDriverNameCorp: any;
        formFieldAdresseCorp: any;
        formFieldByCorp: any;
        formFieldPostnummerCorp: any;
        formFieldTelefonCorp: any;
        formFieldEmailCorp: any;

        opendoInsuranceAcceptTermsP: any;
        opendoInsuranceAcceptTermsE: any;

        formFieldComment: any;
        formFieldAcceptTerms: any;

        otherInsuranceField: any;

        infoSide: any;

        carStepsBar: any;
        insuranceSelectField: any;
    }

    kmStepToggle: boolean = true;
    farveStepToggle: boolean = false;
    fabrikStepToggle: boolean = false;
    efterStepToggle: boolean = false;
    serviceStepToggle: boolean = false;
    daekStepToggle: boolean = false;
    forsikringStepToggle: boolean = false;
    formularStepToggle: boolean = false;

    kmStepHasBeenActivated: boolean = true;
    farveStepHasBeenActivated: boolean = false;
    fabrikStepHasBeenActivated: boolean = false;
    efterStepHasBeenActivated: boolean = false;
    serviceStepHasBeenActivated: boolean = false;
    daekStepHasBeenActivated: boolean = false;
    forsikringStepHasBeenActivated: boolean = false;

    kmStepHasBeenFilledOut: boolean = false;
    farveStepHasBeenFilledOut: boolean = false;
    fabrikStepHasBeenFilledOut: boolean = false;
    efterStepHasBeenFilledOut: boolean = false;
    serviceStepHasBeenFilledOut: boolean = false;
    daekStepHasBeenFilledOut: boolean = false;
    forsikringStepHasBeenFilledOut: boolean = false;


    valgtFabriksUdstyr: any[] = [];
    valgtEkstraUdstyr: any[] = [];
    valgtService: any[] = [];
    valgtDaek: string = "";

    abAmount = new AbAmount();
    userData: UserData = this.$store.getters.getUserData;

    cmsGlobalData: any = this.$store.getters.getCMSGlobalData;

    abLightbox = new AbLightbox();
    abGtm = new AbGtm();
    carIsLoaded: number = 0;

    showMoreLabel: string = this.userData.epiData.showMoreLabel;
    extraEquipmentHeading: string = this.userData.epiData.extraEquipmentHeading;


    //valgte bil fra vehiclelist
    detailedCar: any = {};
    //object struktur der bliver returneret hvergang calculate service bliver kaldt
    calculatedContract: any = {};
    //dæk service typer
    tireDealOptions: any[] = [];

    isCorporate: boolean = this.userData.isCorporate;
    //valgte udstyr/service array
    checkedAvailableOptions: Array<number> = [];

    abAxios = new AbAxios(this.userData.serviceUrl, "application/json; charset=utf-8", this.userData.csrftoken);

    getConsumerVehicleServiceUrl: string = "/leasing/contracts/defaults/Consumer";
    getCorporateVehicleServiceUrl: string = "/leasing/contracts/defaults/Corporate";
    createContractServiceUrl: string = "/leasing/api/Contract/CreateConfigurableLeasingContract";
    calculateContractServiceUrl: string = "/leasing/api/Contract/CalculateContract";
    getInsuranceServiceUrl: string = "/leasing/insurance/configurations";
    getOpendoInsuranceUrl: string = "/leasing/insurance/prices?configIds=122,123";

    showOpendoInsuranceDisclaimer: boolean = false;
    opendoInsuranceDisclaimerPrice: any = '';
    scrollContainerTopPadding: number = 500;

    otherInsuranceFieldValue: any = '';
    //ingen calculate kald mod bytelab før true
    calculateFlag: boolean = false;

    //range setup start
    sliders: calcSlider = null;
    periodData: string[] = [];
    mileageData: string[] = [];
    useCustomLabels: boolean = true;
    styles: string = "ab__range__basic";
    //range setup end

    //tilvalgte services/udstyr/farve til visning i indkøbs kurven
    basketSelectedItems: Array<any> = [];
    basketCompletePayment: number = 0;
    basketEquipmentPriceTotal: number = 0;
    basketMonthlyPaymentWithoutExtra: number = 0;
    colorSelected: any = null;


    //styrer reveal more comp til udstyr
    revealMoreCount: number = 12;
    revealMoreCountIncluded: number = 4;

    //søgning tekst mod forsikrings beregner
    insuranceSearchTxt: string = "";
    opendoInsuranceData: any = null;
    opendoInsuranceTermsChecked: boolean = false;
    opendoInsuranceYearsShowError: boolean = false;
    opendoDynamicInsuranceAllowedIfSelected: boolean = true;


    //New parameters
    taxBasePrice: number = 0;
    monthlyPrice: number = 0;
    firstTimePrice: number = 0;


    submitting: boolean = false;
    recalculating: boolean = false;
    expandedSummary: boolean = true;
    addedCoApplicant: boolean = false;


    opendoInsuranceConfigurationId: any = null;
    opendoInsuranceConfigurationOptions: any = null;
    opendoInsuranceYears: string = '';
    opendoInsurancePriceCalculating: boolean = true;
    opendoInsurancePriceLabelCalculating: string = 'Calculating...';
    opendoInsurancePriceLabel: string = 'Calculating...';

    // OPENDO forsikring / insurance:
    // insuranceTypes: Array<any> = [{'value':'opendo-insurance', 'name':'Opendo Forsikring'},{'value':'alka', 'name':'Alka'},{'value':'other', 'name':'Andet forsikringsselskab'}];
    // selectedInsuranceType: string = 'opendo-insurance';

    // Other / normal forsikring:
    insuranceTypes: Array<any> = [{'value':'alka', 'name':'Alka'},{'value':'other', 'name':'Andet forsikringsselskab'}];
    selectedInsuranceType: string = 'Købstædernes Forsikring';

    selectedInsuranceTypeOpendo: string = '';

    standardEquipmentJoined: string = '';

    showSuccessView: boolean = false;
    currentStep: number = 1;
    steps: Array<any> = [{'label': 'Generelt'}, {'label': 'Udstyr'}, {'label': 'Tilvalg og dæk'}, {'label': 'Forsikring'}, {'label': 'Dine oplysninger'} ];
    likedCarIDs: Array<any> = [];
    likedCarIDsPrivat: Array<any> = [];
    likedCarIDsErhverv: Array<any> = [];
    overviewTabSwitchState: string = "gallery";
    isCheckedPin: boolean = false;
    // summaryData: Array<any> = [
    //     {'title': 'Generelt', 'items': [
    //         {'title': 'Leasingperiode', 'price': '36 måneder'},
    //         {'title': 'Årligt kilometerforbrug', 'price': '15.000 km'},
    //         {'title': 'Farve: Celestial blå 278 - tricoat (5CH)', 'price': '271,00 kr.'},
    //     ]},
    //     {'title': 'Ekstraudstyr', 'items': [
    //         {'title': 'Alarm (9Z2)', 'price': '80,00 kr.'},
    //         {'title': 'Fast glastag - Icon HB, Icon 3+1, La Prima 3+1 (59E)', 'price': '250,00 kr.'},
    //         {'title': 'Måttesæt', 'price': '15,00 kr.'},
    //     ]},
    //     {'title': 'Øvrige', 'items': [
    //         {'title': 'Anslået ejerafgift', 'price': '57,00 kr.'},
    //     ]},
    // ];
    // economyData: Array<any> = [
    //     {'title': 'Førstegangydelse, som betales ved levering af bilen', 'items': [
    //         {'title': 'Ekstraordinær leasingydelse', 'price': '13.150,00 kr.'},
    //         {'title': 'Forsikringsadministration', 'price': '1.150,00 kr.'},
    //         {'title': 'Stiftelsesomkostninger', 'price': '3.195,00 kr.'},
    //         {'title': 'I alt til betaling ved levering', 'price': '17.495,00 kr.'},
    //     ]},
    //     {'title': 'Leasingydelse pr. måned', 'price': '6060,00 kr.'},
    //     {'title': 'Øvrige omkostninger', 'items': [
    //         {'title': 'Tilbageleveringsgebyr', 'price': '635,00 kr.'},
    //         {'title': 'Månedlig rateadministration', 'price': '25,00 kr.'},
    //     ]},
    // ];
    // totalData: {} = {'title': 'Totalomkostninger i leasingperioden', 'price': '237.190,00 kr.'};

    summaryData: Array<any> = [
        {'title': 'Generelt', 'items': [
            {'title': 'Processing', 'price': '...'},
        ]},
        {'title': 'Ekstraudstyr', 'items': [
            {'title': 'Processing', 'price': '...'},
        ]},
        {'title': 'Øvrige', 'items': [
            {'title': 'Processing', 'price': '...'},
        ]},
    ];


    economyData: Array<any> = [
        {'title': 'Førstegangydelse, som betales ved levering af bilen', 'items': [
            {'title': 'Processing', 'price': '...'},
        ]},
        {'title': 'Processing', 'price': '...'},
        {'title': 'Øvrige omkostninger', 'items': [
            {'title': 'Processing', 'price': '...'},
        ]},
    ];

    totalData: {} = {'title': 'Processing', 'price': '...'};



    statsBoxes: Array<any> = [
        {'icon': 'clean-car-gas', 'label': 'Drivmiddel', 'value': 'Benzin'},
        {'icon': 'gear', 'label': 'Gear', 'value': 'Manuelt'},
        {'icon': 'doors', 'label': 'Døre', 'value': '5'},
        {'icon': 'sparks', 'label': 'Ny eller brugt', 'value': 'Ny'},
        {'icon': 'motor', 'label': 'Motor', 'value': '1.6 L'},
        {'icon': 'speed', 'label': 'Ydelse', 'value': '95 HK'},
        {'icon': 'distance', 'label': 'Forbrug', 'value': '15,4 km/l'},
        {'icon': 'leaves', 'label': 'Energiklasse', 'value': 'B'},
        {'icon': 'co2', 'label': 'CO2', 'value': '86 g/km'},
    ];


    colorOptions: Array<any> = [];
    annualMilageOptions: Array<any> = [];
    leasingPeriodOptions: Array<any> = [];

    preFittedOptions: Array<any> = [];
    retroFittedOptions: Array<any> = [];

    serviceOptions: Array<any> = [];
    tireAgreementOptions: Array<any> = [];

    colorValue: number = 0;
    colorTitle: string = "Vælg farve";
    annualMilageValue: number = 0;
    leasingPeriodValue: number = 0;

    preFittedValues: Array<any> = [];
    retroFittedValues: Array<any> = [];
    serviceValues: Array<any> = [];
    tireAgreementValues: Array<any> = [];

    pinCode: string = "";

    haveElAbonoment:boolean = false;
    haveElAbonomentPrice:number = 0;




    //New functions
    overviewTabSwitchHandler(e){
        e.preventDefault();
        if (this.overviewTabSwitchState == "gallery"){
            this.overviewTabSwitchState = "overview";
        } else if (this.overviewTabSwitchState == "overview"){
            this.overviewTabSwitchState = "gallery";
        }
    }
    toggleLikeCar(id:any, e:any){
        e.preventDefault();
        this.loadLikes();
        if (this.likedCarIDs.includes(id)){
          var index = this.likedCarIDs.indexOf(id);
          if (index !== -1) {
            this.likedCarIDs.splice(index, 1);
          }
        }else{
          this.likedCarIDs.push(id);
        }

        if (!this.isCorporate){
            if (this.likedCarIDsPrivat.includes(id)){
                var index = this.likedCarIDsPrivat.indexOf(id);
                if (index !== -1) {
                this.likedCarIDsPrivat.splice(index, 1);
                }
            }else{
                this.likedCarIDsPrivat.push(id);
            }
        }

        if (this.isCorporate){
            if (this.likedCarIDsErhverv.includes(id)){
                var index = this.likedCarIDsErhverv.indexOf(id);
                if (index !== -1) {
                this.likedCarIDsErhverv.splice(index, 1);
                }
            }else{
                this.likedCarIDsErhverv.push(id);
            }
        }

        this.saveLikes();

        this.$emit('toggleLikeCar', id);

        eventBus.$emit("car-likes-update");
    }
    saveLikes(){
        localStorage.setItem('carLikes', JSON.stringify(this.likedCarIDs));
        localStorage.setItem('carLikesPrivat', JSON.stringify(this.likedCarIDsPrivat));
        localStorage.setItem('carLikesErhverv', JSON.stringify(this.likedCarIDsErhverv));
    }
    loadLikes(){
        var likes = localStorage.getItem('carLikes');
        if (likes){
            this.likedCarIDs = JSON.parse(likes);
        }
        likes = localStorage.getItem('carLikesPrivat');
        if (likes){
            this.likedCarIDsPrivat = JSON.parse(likes);
        }
        likes = localStorage.getItem('carLikesErhverv');
        if (likes){
            this.likedCarIDsErhverv = JSON.parse(likes);
        }
    }
    prevStep() {
        this.currentStep--;
        if (this.$refs.infoSide){
            this.stepToggleScroll(this.$refs.infoSide);
        }
    }
    nextStep() {
        if (this.$refs.infoSide){
            this.stepToggleScroll(this.$refs.infoSide);
        }

        if (this.currentStep==3){

            // this.getInsurancesViaRest(this.getInsuranceServiceUrl);
            // this.getOpendoInsurancesViaRest(this.getOpendoInsuranceUrl);
            this.getOpendoInsurancesViaContract();
        }
        if (this.currentStep == 4){
            if (this.selectedInsuranceType == "other"){
                if (this.$refs.otherInsuranceField.validate()){
                    this.otherInsuranceFieldValue = this.$refs.otherInsuranceField.liveValue;
                }else{
                    this.$refs.otherInsuranceField.showError("Du skal udfylde dette felt");
                    return;
                }
            }
            if (this.selectedInsuranceType == "opendo-insurance"){
                if (!this.opendoInsuranceYears && !this.userData.isCorporate){
                    this.opendoInsuranceYearsShowError = true;
                    return;
                }
                if (!this.opendoInsuranceTermsChecked){
                    if (this.userData.isCorporate){
                        this.$refs.opendoInsuranceAcceptTermsE.showError("Du skal acceptere vilkårene");
                    }else{
                        this.$refs.opendoInsuranceAcceptTermsP.showError("Du skal acceptere vilkårene");
                    }
                    return;
                }
                if (this.opendoDynamicInsuranceAllowedIfSelected == false) {
                    return;
                }
            }
        }

        if (this.currentStep < 5){
            this.currentStep++;
            if (this.currentStep==4){
                this.overviewTabSwitchState="overview";
            }
        }else{
            this.submitForm();
        }
    }
    onLeasingSubmitFailed(){
        this.submitting = false;
    }
    onLeasingSubmitSuccess(){
        // alert("success");
        this.submitting = false;
        this.currentStep = 6;
        this.showSuccessView = true;
    }
    submitForm(){

        // console.log(this.$store.getters.getCreatedContract.Contract);


            var ok = this.validateFields();
            if (ok){

            if (!this.submitting){


                var formHelper = new FormHelper();
                var person = {
                    Cvr: "",
                    FirstName: "",
                    LastName: "",
                    Address: "",
                    ZipCode: "",
                    City: "",
                    CellPhone: "",
                    Email: "",
                    Comments: "",
                    Consent: false,
                };
                var spouse = {
                    Cvr: "",
                    FirstName: "",
                    LastName: "",
                    Address: "",
                    ZipCode: "",
                    City: "",
                    CellPhone: "",
                    Email: "",
                    Comments: "",
                    Consent: false,
                };

                if (this.$refs.formFieldCVRCorp) {person.Cvr = this.$refs.formFieldCVRCorp.liveValue;}
                if (this.$refs.formFieldFirmNameCorp) {person.FirstName = this.$refs.formFieldFirmNameCorp.liveValue;}
                if (this.$refs.formFieldKontaktPersonCorp) {person.LastName = this.$refs.formFieldKontaktPersonCorp.liveValue;}
                // if (this.$refs.formFieldDriverNameCorp) {person.LastName = this.$refs.formFieldDriverNameCorp.liveValue;}
                if (this.$refs.formFieldAdresseCorp) {person.Address = this.$refs.formFieldAdresseCorp.liveValue;}
                if (this.$refs.formFieldByCorp) {person.City = this.$refs.formFieldByCorp.liveValue;}
                if (this.$refs.formFieldPostnummerCorp) {person.ZipCode = this.$refs.formFieldPostnummerCorp.liveValue;}
                if (this.$refs.formFieldTelefonCorp) {person.CellPhone = this.$refs.formFieldTelefonCorp.liveValue;}
                if (this.$refs.formFieldEmailCorp) {person.Email = this.$refs.formFieldEmailCorp.liveValue;}
                if (this.$refs.formFieldFirstName) {person.FirstName = this.$refs.formFieldFirstName.liveValue;}
                if (this.$refs.formFieldLastName) {person.LastName = this.$refs.formFieldLastName.liveValue;}
                if (this.$refs.formFieldPostNr) {person.ZipCode = this.$refs.formFieldPostNr.liveValue;}
                if (this.$refs.formFieldBy) {person.City = this.$refs.formFieldBy.liveValue;}
                // if (this.$refs.formFieldBy) {person.Address = this.$refs.formFieldBy.liveValue;}
                if (this.$refs.formFieldTelefon) {person.CellPhone = this.$refs.formFieldTelefon.liveValue;}
                if (this.$refs.formFieldEmail) {person.Email = this.$refs.formFieldEmail.liveValue;}
                if (this.$refs.formFieldFirstNameCo) {spouse.FirstName = this.$refs.formFieldFirstNameCo.liveValue;}
                if (this.$refs.formFieldLastNameCo) {spouse.LastName = this.$refs.formFieldLastNameCo.liveValue;}
                if (this.$refs.formFieldPostNrCo) {spouse.ZipCode = this.$refs.formFieldPostNrCo.liveValue;}
                if (this.$refs.formFieldByCo) {spouse.City = this.$refs.formFieldByCo.liveValue;}
                if (this.$refs.formFieldByCo) {spouse.Address = this.$refs.formFieldByCo.liveValue;}
                if (this.$refs.formFieldTelefonCo) {spouse.CellPhone = this.$refs.formFieldTelefonCo.liveValue;}
                if (this.$refs.formFieldEmailCo) {spouse.Email = this.$refs.formFieldEmailCo.liveValue;}


                if (this.$refs.formFieldComment) {
                    person.Comments = this.$refs.formFieldComment.value;
                    spouse.Comments = this.$refs.formFieldComment.value;
                }
                person.Consent = true;
                spouse.Consent = true;


                this.$store.dispatch('setInsuranceCompany', this.selectedInsuranceType);
                if (this.selectedInsuranceType == "other"){
                    this.$store.dispatch('setInsuranceCompany', this.otherInsuranceFieldValue);

                }
                if (this.selectedInsuranceType == "opendo-insurance"){
                    this.$store.dispatch('setInsuranceCompany', this.selectedInsuranceTypeOpendo);
                }



                this.$store.dispatch('fromBuyerToContract', person);
                if (this.addedCoApplicant) {
                    this.$store.dispatch('fromCoBuyerToContract', spouse);
                }


                formHelper.createOfferViaRest();
                // alert("yes");

                this.submitting = true;
                // console.log(this.$store.getters.getCreatedContract.Contract);

            }
        }else{
            this.currentStep = 5;
        }

    }
    validateFields(){

        var ok = true;

        if (this.isCorporate){


            var fields = [
                this.$refs.formFieldCVRCorp,
                this.$refs.formFieldFirmNameCorp,
                this.$refs.formFieldKontaktPersonCorp,
                this.$refs.formFieldDriverNameCorp,
                this.$refs.formFieldAdresseCorp,
                this.$refs.formFieldByCorp,
                this.$refs.formFieldPostnummerCorp,
                this.$refs.formFieldTelefonCorp,
                this.$refs.formFieldEmailCorp,
            ];
            for(var i=0; i<fields.length; i++){
                if (fields[i]){
                    if(!fields[i].validate()){
                        fields[i].showError("Du skal udfylde dette felt");
                        ok = false;
                    }
                }
            }


        }else{
            var fields = [
                this.$refs.formFieldFirstName,
                this.$refs.formFieldLastName,
                this.$refs.formFieldPostNr,
                this.$refs.formFieldBy,
                this.$refs.formFieldTelefon,
                this.$refs.formFieldEmail,
            ];

            for (const element of fields) {
                if (element && !element.validate()) {
                    if (element.type === "email") {
                        element.showError("Du skal udfylde dette felt eller e-mail adressen er forkert indtastet");
                    } else {
                        element.showError("Du skal udfylde dette felt");
                    }

                    ok = false;
                }
            }

            if (this.addedCoApplicant){
                var fields = [
                    this.$refs.formFieldFirstNameCo,
                    this.$refs.formFieldLastNameCo,
                    this.$refs.formFieldPostNrCo,
                    this.$refs.formFieldByCo,
                    this.$refs.formFieldTelefonCo,
                    this.$refs.formFieldEmailCo,
                ];

                for (const element of fields) {
                    if (element && !element.validate()) {
                        if (element.type === "email") {
                            element.showError("Du skal udfylde dette felt eller e-mail adressen er forkert indtastet");
                        } else {
                            element.showError("Du skal udfylde dette felt");
                        }

                        ok = false;
                    }
                }
            }
        }

        if(!this.$refs.formFieldAcceptTerms.checked){
            ok = false;
            this.$refs.formFieldAcceptTerms.showError("Du skal acceptere vilkårene");
        }

        return ok;
    }
    checkedPin(e, option){
        // alert(option.value);
        this.isCheckedPin = e.checked;
        this.onServiceOptionsChange(e);
    }
    expandSummaryClick(e){
        e.preventDefault();
        this.expandedSummary = !this.expandedSummary;
    }
    addCoApplicant(e){
        e.preventDefault();
        this.addedCoApplicant = !this.addedCoApplicant;
    }
    opendoInsuranceYearsClick(years){

        this.opendoInsuranceYears = years;

        this.opendoInsuranceYearsShowError = false;
        this.opendoDynamicInsuranceAllowedIfSelected = true;
        // if (years == "under26"){
            // this.opendoInsurancePriceLabel = '620 kr.';
        // }else{
            // this.opendoInsurancePriceLabel = '520 kr.';
        // }

        this.calculateOpendoInsurance();
        this.somethingChanged();
    }
    onOpendoInsuranceTermsChanged(e){
        // alert(JSON.stringify(e));
        this.opendoInsuranceTermsChecked = e.checked;
    }
    onInsuranceTypeSelected(e){
        this.selectedInsuranceType = e;
        this.opendoInsuranceYearsClick('');
    }

    prepareCarData(){
        var th = this;


        //Sets Opendo insurance ID
        let insurancesData = th.$store.getters.getCreatedContract;
        if (insurancesData && insurancesData.Contract){
            if (insurancesData.Contract.Leasing){
                if (insurancesData.Contract.Leasing.Insurance){
                    if (insurancesData.Contract.Leasing.Insurance.ConfigurationId){
                        th.opendoInsuranceConfigurationId = insurancesData.Contract.Leasing.Insurance.ConfigurationId;
                    }
                }
            }
        }
        if (insurancesData && insurancesData.AvailableInsuranceOptions){
            if (insurancesData.AvailableInsuranceOptions[0]){
                if (insurancesData.AvailableInsuranceOptions[0].CriteriaValues){
                    th.opendoInsuranceConfigurationOptions = insurancesData.AvailableInsuranceOptions[0].CriteriaValues;
                }
            }
        }
        /*
        setTimeout(function(){
            // alert("a");
            let insurancesData = th.$store.getters.getInsurancesData;
            console.log(insurancesData);

            th.$store.dispatch('setInsuranceCompany', "aaaaaa");
            th.$store.dispatch('setInsurance', {
                "UseInsurance": true,
                "ConfigurationId": th.opendoInsuranceConfigurationId,
                // "ConfigurationId": 123,
                // "Values": [
                //     // {"Id": 181}
                //     {"Id": 184}
                // ]
            });

        },3000);
        */


        var contract = this.$store.getters.getCreatedContract;
        var availableOptions = contract.AvailableOptions;
        var availableTireDeals = contract.AvailableTireDeals;
        var availableInsurances = contract.AvailableInsuranceCompanies;

        // console.log(contract);

        if (availableInsurances){
            this.insuranceTypes = [];

            // Opendo insurance as option + make it default selected:
            // this.insuranceTypes.push({'value':'opendo-insurance', 'name':'Opendo Forsikring'});
            // this.selectedInsuranceType = 'opendo-insurance';

            for(var i=0; i<availableInsurances.length; i++){
                var option = availableInsurances[i];
                this.insuranceTypes.push({'value': option, 'name': option});
            }
            this.insuranceTypes.push({'value':'other', 'name':'Andet forsikringsselskab'});

            // Sort insurance types alphabetically
            this.insuranceTypes.sort(function(a, b) {
                var nameA = a.name.toUpperCase();
                var nameB = b.name.toUpperCase();
                return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
            });
        }

        if (availableOptions){
            this.colorOptions = [];
            this.colorValue = null;

            this.preFittedOptions = [];
            this.preFittedValues = [];
            this.retroFittedOptions = [];
            this.retroFittedValues = [];

            this.serviceOptions = [];
            this.serviceValues = [];
            var serviceGroup1 = [];
            var serviceGroup2 = [];
            var serviceGroup3 = [];
            var serviceGroup4 = [];


            for(var i=0; i<availableOptions.length; i++){
                var option = availableOptions[i];

                //COLOR
                if (option.OptionType == 1){
                    // console.log(option);
                    if (!this.colorValue){
                        this.colorValue = option.Id;
                        this.colorTitle = option.Name;
                    }
                    if (option.Standard){
                        this.colorValue = option.Id;
                        this.colorTitle = option.Name;
                    }
                    this.colorOptions.push({"value":option.Id, "name":option.Name, "price": option.PriceIncVat });
                }


                if (option.OptionType == 4){

                    var checked = option.Standard;
                    if (option.ServiceOptionType == 2){


                        serviceGroup1.push({"value":option.Id, "name": option.Name, "monthly_price": this.formatPrice(option.PriceIncVat), "hide_price": option.HidePrice, "checked": option.Standard, "default": option.Standard});
                    }else if (option.ServiceOptionType == 3){

                        if (!this.isCorporate){
                            if (option.Name == "Erstatningsbil ved skade" || option.Name == "Hente-/bringe service"){
                                continue;
                            }
                        }


                        serviceGroup2.push({"value":option.Id, "name": option.Name, "monthly_price": this.formatPrice(option.PriceIncVat), "hide_price": option.HidePrice, "checked": option.Standard, "default": option.Standard})
                        // serviceGroup2.push({"value":option.id, "name":option.name, "default": option.default, "checked": checked, "price": option.monthly_price});
                    }else if (option.ServiceOptionType == 1){

                        if (!this.isCorporate){
                            if (option.Name != "Shell"){
                                continue;
                            }
                        }
                        // havePin
                        if (option.Name == "Shell"){
                            serviceGroup3.push({"value":option.Id, "name": option.Name, "monthly_price": this.formatPrice(option.PriceIncVat), "hide_price": option.HidePrice, "checked": option.Standard, "default": option.Standard, "havePin": true})
                        }else{
                            serviceGroup3.push({"value":option.Id, "name": option.Name, "monthly_price": this.formatPrice(option.PriceIncVat), "hide_price": option.HidePrice, "checked": option.Standard, "default": option.Standard, "havePin": false})
                        }

                        // serviceGroup3.push({"value":option.id, "name":option.name, "default": option.default, "checked": checked, "price": option.monthly_price});
                    }else if (option.ServiceOptionType == 8){
                        if (!this.isCorporate){
                            continue;
                        }
                        serviceGroup4.push({"value":option.Id, "customStyle":'radio-style', "name": option.Name, "monthly_price": this.formatPrice(option.PriceIncVat), "hide_price": option.HidePrice, "checked": option.Standard, "default": option.Standard})


                        // serviceGroup4.push({"value":option.id, "name":option.name, "default": option.default, "checked": checked, "price": option.monthly_price});
                    }else{
                        serviceGroup1.push({"value":option.Id, "name": option.Name, "monthly_price": this.formatPrice(option.PriceIncVat), "hide_price": option.HidePrice, "checked": option.Standard, "default": option.Standard})
                        // serviceGroup1.push({"value":option.id, "name":option.name, "default": option.default, "checked": checked, "price": option.monthly_price});
                    }
                    if (option.Standard){
                        this.serviceValues.push(option.Id);
                    }

                }

                //OPTIONS
                if (option.OptionType == 2){
                    const optionName : string = (option.DisplayName != null && option.DisplayName.length > 0) ? option.DisplayName : option.Name;
                    this.preFittedOptions.push({"value":option.Id, "name": optionName, "monthly_price": this.formatPrice(option.PriceIncVat), "hide_price": option.HidePrice, "checked": option.Standard, "default": option.Standard})
                    if (option.Standard){
                        this.preFittedValues.push(option.Id);
                    }
                }

                if (option.OptionType == 3){
                    this.retroFittedOptions.push({"value":option.Id, "name": option.Name, "monthly_price": this.formatPrice(option.PriceIncVat), "hide_price": option.HidePrice, "checked": option.Standard, "default": option.Standard})
                    if (option.Standard){
                        this.retroFittedValues.push(option.Id);
                    }
                }





            }

            if (this.userData.isCorporate){
                this.serviceOptions = [
                    {'title':'', 'options': serviceGroup1},
                    {'title':'Erstatningsbil', 'options': serviceGroup2, "info": "Få en lånebil, når din bil er til service eller hvis din bil får en skade."},
                    {'title':'Brændstofkort', 'options': serviceGroup3, "info": "Få et brændstofkort, som du kan bruge til at tanke, vaske og købe kør-videre-produkter."},
                    {'title':'El-abonnement', 'options': serviceGroup4, "info": "Få et el-abonnement, så du kan lade din plug-in hybrid- eller elbil derhjemme."},
                ];
            }else{

                this.serviceOptions = [
                    {'title':'', 'options': serviceGroup1},
                    {'title':'Erstatningsbil', 'options': serviceGroup2, "info": "Få en lånebil, når din bil er til service eller hvis din bil får en skade."},
                    {'title':'Brændstofkort', 'options': serviceGroup3, "info": "Få et brændstofkort, som du kan bruge til at tanke, vaske og købe kør-videre-produkter."},
                    {'title':'El-abonnement', 'options': serviceGroup4, "info": "Få et el-abonnement, så du kan lade din plug-in hybrid- eller elbil derhjemme."},
                ];
            }

            //SET DEFAULTS
            // console.log("aa");
            // console.log(this.colorOptions);
            if (this.colorOptions[0] && this.colorOptions[0].value){
                this.colorValue = this.colorOptions[0].value;
            }else{
                this.colorValue = 0;
            }
        }

        if (availableTireDeals){
            this.tireAgreementValues = [];
            this.tireAgreementOptions = [];

            for(var i=0; i<availableTireDeals.length; i++){
                var option = availableTireDeals[i];

                //TIRE AGREEMENTS
                var checked = option.default;
                this.tireAgreementOptions.push({"value": option.TireDealTypes, "name": option.Name, "monthly_price": this.formatPrice(option.Price), "checked": false, "default": false});
            }
        }


        if (this.detailedCar){
            this.taxBasePrice = 0;
            this.monthlyPrice = 0;
            this.firstTimePrice = 0;

            this.taxBasePrice = this.detailedCar.vehicle.price.tax.monthly;
            this.firstTimePrice = this.detailedCar.vehicle.price.downpayment.total;
            this.monthlyPrice = this.detailedCar.vehicle.price.monthly_payment.total;

            if (this.detailedCar.vehicle.standard_equipment) {
                this.standardEquipmentJoined = this.detailedCar.vehicle.standard_equipment.join(', ');
            }

            if (this.detailedCar.options){
                // if (this.detailedCar.options.colour_options){
                //     this.colorOptions = [];
                //     for(var i=0; i<this.detailedCar.options.colour_options.length; i++){
                //         var option = this.detailedCar.options.colour_options[i];
                //         this.colorOptions.push({"value":option.id, "name":option.name});
                //     }
                //     this.colorValue = this.colorOptions[0].value;
                // }

                // this.serviceValues = [];
                // if (this.detailedCar.options.service_options){
                //     // var group0 = [];
                //     var group1 = [];
                //     var group2 = [];
                //     var group3 = [];
                //     var group4 = [];
                //     for(var i=0; i<this.detailedCar.options.service_options.length; i++){
                //         var option = this.detailedCar.options.service_options[i];

                //         var checked = option.default;
                //         if (option.service_option_type == 2){
                //             group1.push({"value":option.id, "name":option.name, "default": option.default, "checked": checked, "price": option.monthly_price});
                //         }else if (option.service_option_type == 3){
                //             group2.push({"value":option.id, "name":option.name, "default": option.default, "checked": checked, "price": option.monthly_price});
                //         }else if (option.service_option_type == 1){
                //             group3.push({"value":option.id, "name":option.name, "default": option.default, "checked": checked, "price": option.monthly_price});
                //         }else if (option.service_option_type == 8){
                //             group4.push({"value":option.id, "name":option.name, "default": option.default, "checked": checked, "price": option.monthly_price});
                //         }else{
                //             group1.push({"value":option.id, "name":option.name, "default": option.default, "checked": checked, "price": option.monthly_price});
                //         }
                //         if (option.default){
                //             this.serviceValues.push(option.id);
                //         }
                //     }
                //     this.serviceOptions = [
                //         {'title':'', 'options': group1},
                //         {'title':'Erstatningsbil', 'options': group2},
                //         {'title':'Brændstofkort', 'options': group3},
                //         {'title':'El-abonnement', 'options': group4},
                //     ];

                // }
                // this.tireAgreementValues = [];
                // if (this.detailedCar.options.tire_agreement){
                //     for(var i=0; i<this.detailedCar.options.tire_agreement.length; i++){
                //         var option = this.detailedCar.options.tire_agreement[i];
                //         var checked = option.default;
                //         this.tireAgreementOptions.push({"value":option.id, "name":option.name, "default": option.default, "checked": checked, "price": option.monthly_price});
                //     }
                // }


                // this.preFittedValues = [];
                // this.retroFittedValues = [];
                // if (this.detailedCar.options.pre_fitted_options){
                //     this.preFittedOptions = this.detailedCar.options.pre_fitted_options;
                // }
                // if (this.detailedCar.options.retro_fitted_options){
                //     this.retroFittedOptions = this.detailedCar.options.retro_fitted_options;
                // }
            }

            if (this.detailedCar.period_milages){
                this.annualMilageOptions = [];
                this.leasingPeriodOptions = [];

                for (const months in this.detailedCar.period_milages) {
                    var mileages = this.detailedCar.period_milages[months];
                    var mileagesOptions = [];

                    for(var i=0; i<mileages.length; i++){
                        mileagesOptions.push({"value":mileages[i], "name": this.thousandFormat(mileages[i])+' km'});
                    }
                    this.leasingPeriodOptions.push({"value":months, "name": months+' måneder', 'mileages': mileagesOptions});
                }

                if (this.detailedCar.vehicle.price.period){
                    this.leasingPeriodValue = this.detailedCar.vehicle.price.period;
                } else {
                    this.leasingPeriodValue = this.leasingPeriodOptions[0].value;
                }

                if (this.detailedCar.vehicle.price.yearly_mileage){
                    this.annualMilageValue = this.detailedCar.vehicle.price.yearly_mileage;
                    // this.annualMilageValue = 60000;
                    // alert(this.annualMilageValue);
                }


                // if (this.leasingPeriodOptions.length == 1){
                this.onLeasingPeriodChange(this.leasingPeriodValue);
                // }else{
                //     this.onLeasingPeriodChange(this.leasingPeriodOptions[2].value);
                //     this.leasingPeriodValue = this.leasingPeriodOptions[2].value;
                // }
            }


            this.statsBoxes = [];

            var ftype = this.detailedCar.vehicle.fuel_type;
            if (ftype == "ElBenzinPlugin"){
                ftype = "Plug in hybrid benzin";
            } else if (ftype == "ElDieselPlugin"){
                ftype = "Plug in hybrid diesel";
            }

            if (this.detailedCar.vehicle.fuel_type){ this.statsBoxes.push({'icon': 'clean-car-gas', 'label': 'Drivmiddel', 'value': ftype}); }
            if (this.detailedCar.vehicle.transmission_type){ this.statsBoxes.push({'icon': 'gear', 'label': 'Gear', 'value': this.detailedCar.vehicle.transmission_type}); }
            if (this.detailedCar.vehicle.doors){ this.statsBoxes.push({'icon': 'doors', 'label': 'Døre', 'value': this.detailedCar.vehicle.doors}); }
            if (this.detailedCar.vehicle.new_car){ this.statsBoxes.push({'icon': 'sparks', 'label': 'Ny eller brugt', 'value': 'Ny'}); }
            if (this.detailedCar.vehicle.used){ this.statsBoxes.push({'icon': 'sparks', 'label': 'Ny eller brugt', 'value': 'Brugt'}); }
            if (this.detailedCar.vehicle.engine_size){ this.statsBoxes.push({'icon': 'motor', 'label': 'Motor', 'value': this.detailedCar.vehicle.engine_size+" L"}); }
            if (this.detailedCar.vehicle.horse_power){ this.statsBoxes.push({'icon': 'speed', 'label': 'Ydelse', 'value': this.detailedCar.vehicle.horse_power+" HK"}); }
            if (this.detailedCar.vehicle.fuel_economy){ this.statsBoxes.push({'icon': 'distance', 'label': 'Forbrug', 'value': this.detailedCar.vehicle.fuel_economy+" km/l"}); }
            if (this.detailedCar.vehicle.electricRange){ this.statsBoxes.push({'icon': 'distance', 'label': 'Rækkevide', 'value': this.detailedCar.vehicle.electricRange+" km"}); }
            if (this.detailedCar.vehicle.energy){ this.statsBoxes.push({'icon': 'leaves', 'label': 'Energiklasse', 'value': this.detailedCar.vehicle.energy}); }
            if (this.detailedCar.vehicle.co2){ this.statsBoxes.push({'icon': 'co2', 'label': 'CO2', 'value': this.detailedCar.vehicle.co2+" g/km"}); }
            if (this.detailedCar.vehicle.co2 === 0){ this.statsBoxes.push({'icon': 'co2', 'label': 'CO2', 'value': this.detailedCar.vehicle.co2+" g/km"}); }

            // if (this.detailedCar.vehicle.co2 === 0){ this.statsBoxes.push({'icon': 'co2', 'label': 'CO2', 'value': this.detailedCar.vehicle.co2+" g/km"}); }
        }

        this.$nextTick(function(){
            eventBus.$emit("handlePageAnimations");

            this.initializedView();

        });
        //lav første beregning med ny oprettet kontrakt og preudfyldt udstyr/service
        // this.calculateContractViaRest(this.calculateContractServiceUrl);
    }

    initializedView(){
        this.onResize();
    }

    recalculateOptionsPrices(){
        this.recalculating = false;
        // this.calculatedContract;
        //console.log("recalculator");
        //console.log(this.calculatedContract);

        this.prepareSummaryData();
    }
    thousandFormat(x) {
        // return x;
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    valueExistsInOptionArray(options, val){
        for(var i=0; i<options.length; i++){
            if (options[i].value == val){
                return true;
            }
        }
        return false;
    }
    getOptionByValue(options, val){
        for(var i=0; i<options.length; i++){
            if (options[i].value == val){
                return options[i];
            }
        }
        return null;
    }

    onLeasingPeriodChange(val){
        this.leasingPeriodValue = val;
        for(var i=0; i<this.leasingPeriodOptions.length; i++){
            var option = this.leasingPeriodOptions[i];
            if (option.value == val){
                this.annualMilageOptions = this.leasingPeriodOptions[i].mileages;
                if (!this.annualMilageValue || !this.valueExistsInOptionArray(this.annualMilageOptions, this.annualMilageValue)){
                    if (this.annualMilageOptions[1]){
                        this.annualMilageValue = this.annualMilageOptions[1].value;
                    }else{
                        this.annualMilageValue = this.annualMilageOptions[0].value;
                    }
                }
            }
        }
        this.somethingChanged();
    }
    onAnnualMileageChange(val){
        this.annualMilageValue = val;
        this.somethingChanged();
    }
    onColorChange(val){
        this.colorValue = val;
        this.somethingChanged();

    }
    onPreEquipmentChange(val){
        var index = this.preFittedValues.indexOf(val.value);
        if (index !== -1) {
            this.preFittedValues.splice(index, 1);
        }
        if (val.checked){
            this.preFittedValues.push(val.value);
        }
        this.somethingChanged();
    }
    onRetroEquipmentChange(val){
        var index = this.retroFittedValues.indexOf(val.value);
        if (index !== -1) {
            this.retroFittedValues.splice(index, 1);
        }
        if (val.checked){
            this.retroFittedValues.push(val.value);
        }


        this.somethingChanged();
    }
    onServiceOptionsChange(val){

        //RadioButton Clean
        for (var i=0; i<this.serviceOptions[3].options.length; i++){
            var options = this.serviceOptions[3].options;
            options[i].checked = false;

            var index = this.serviceValues.indexOf(options[i].value);
            if (index !== -1) {
                this.serviceValues.splice(index, 1);
            }

            if (options[i].value == val.value){
                options[i].checked = true;
            }
        }

        var index = this.serviceValues.indexOf(val.value);
        if (index !== -1) {
            this.serviceValues.splice(index, 1);
        }
        if (val.checked){
            this.serviceValues.push(val.value);
        }
        this.somethingChanged();

    }
    onTireAgreementOptionsChange(val){

        //RadioButton Clean
        this.tireAgreementValues = [];
        for (var i=0; i<this.tireAgreementOptions.length; i++){
            this.tireAgreementOptions[i].checked = false;
            if (this.tireAgreementOptions[i].value == val.value){
                this.tireAgreementOptions[i].checked = val.checked;
            }
        }

        var index = this.tireAgreementValues.indexOf(val.value);

        if (index !== -1) {
            this.tireAgreementValues.splice(index, 1);
        }
        if (val.checked){
            this.tireAgreementValues.push(val.value);
        }

        // console.log("tires");
        // console.log(val);
        // console.log(this.tireAgreementOptions);

        this.somethingChanged();
    }
    onPinFieldChanged(val){
        // alert(val)
        this.pinCode = val;
        this.$store.dispatch('setFuelCardPincode', this.pinCode);
    }
    somethingChanged(){


        // alert("somethingChanged");
        if (this.$store.getters.getCreatedContract.Contract){

            // alert("GET DATAAA");
            console.log("recalculate");

            // var _availableOptions = this.calculatedContract.AvailableOptions;
            // console.log(JSON.stringify(this.calculatedContract.Leasing));


            console.log(this.preFittedValues);
            console.log(this.retroFittedValues);
            console.log(this.serviceValues);
            console.log(this.tireAgreementValues);

            console.log("contract");
            console.log(this.$store.getters.getCreatedContract.Contract);



            this.handleAvailableOptionsSelection();
            // alert("color "+this.colorValue);
            // alert("period "+this.leasingPeriodValue);
            // alert("mileage "+this.annualMilageValue);

            var color = { Id: this.colorValue }
            this.$store.dispatch('availableColorSelected', color);
            this.$store.dispatch('availablePeriodsSelected', this.leasingPeriodValue);
            this.$store.dispatch('availableMileagesSelected', this.annualMilageValue);





            // let payload = JSON.stringify(this.$store.getters.getCreatedContract.Contract);



            this.calculateContractViaRest(this.calculateContractServiceUrl);



            // this.$store.dispatch('availablePeriodsSelected', 12);
            // console.log(this.$store.getters.getCreatedContract.AvailableCol);
            // this.$store.dispatch('availableMileagesSelected', _mileage);
        }
    }
    // extraEquipmentChange(e){
    //     if (e.checked){
    //         document.querySelector("input[name='"+e.value+"']").closest(".item").classList.add("checked");
    //     }else{
    //         document.querySelector("input[name='"+e.value+"']").closest(".item").classList.remove("checked");
    //     }
    // }

    getCalculatedTireDeal(val){
        var contract = this.calculatedContract;
        var availableTireDeals = contract.TireOptions;
        if (availableTireDeals){
            for(var i=0; i<availableTireDeals.length; i++){
                if (availableTireDeals[i].TireDealType == val){
                    return availableTireDeals[i];
                }
            }
        }

        return null;
    }
    getCalculatedOption(val){
        var contract = this.calculatedContract;
        var availableOptions = contract.AvailableOptions;
        // var availableInsurances = contract.AvailableInsuranceCompanies;
        if (availableOptions){
            for(var i=0; i<availableOptions.length; i++){
                if (availableOptions[i].Id == val){
                    return availableOptions[i];
                }
            }
        }
        return null;
    }
    getCalculatedOptionPrice(val){
        var v = this.getCalculatedOption(val);
        if (v){
            return v.MonthlyPrice;
        }
        return null
    }



    prepareSummaryData(){

        var contract = this.calculatedContract;


        var colorOption = this.getOptionByValue(this.colorOptions, this.colorValue);

        var summaryItems = [
            {'title': 'Leasingperiode', 'price': this.leasingPeriodValue+' måneder'},
            {'title': 'Årligt kilometerforbrug', 'price': this.thousandFormat(this.annualMilageValue)+' km'}

        ]
        if (colorOption){
            summaryItems.push(
                {'title': 'Farve: '+colorOption.name, 'price': this.formatPrice(colorOption.price)+' kr.'}
            );
        }
        this.summaryData = [
            {'title': 'Generelt', 'items': summaryItems},
        ];


        //Equipment

        var groupItems = [];
        for(var i=0; i<this.preFittedOptions.length; i++){
            var item = this.getCalculatedOption(this.preFittedOptions[i].value);
            if (item){
                this.preFittedOptions[i].monthly_price = this.formatPrice(item.MonthlyPrice);
            }
            if (this.preFittedValues.includes(this.preFittedOptions[i].value)){
                if (item){
                    groupItems.push({'title': this.preFittedOptions[i].name, 'price': this.formatPrice(item.MonthlyPrice)+' kr.'});
                }
            }
        }
        this.summaryData.push({'title': 'Ekstraudstyr', 'items': groupItems});

        //Extra Equipment

        var groupItems = [];
        for(var i=0; i<this.retroFittedOptions.length; i++){
            var item = this.getCalculatedOption(this.retroFittedOptions[i].value);
            if (item){
                this.retroFittedOptions[i].monthly_price = this.formatPrice(item.MonthlyPrice);
            }
            if (this.retroFittedValues.includes(this.retroFittedOptions[i].value)){
                if (item){
                    groupItems.push({'title': this.retroFittedOptions[i].name, 'price': this.formatPrice(item.MonthlyPrice)+' kr.'});
                }
            }
        }
        this.summaryData.push({'title': 'Eftermonteret ekstraudstyr', 'items': groupItems});






        //Services
        this.haveElAbonoment = false;
        console.log("dd");
        console.log(this.serviceOptions);

        for(var i=0; i<this.serviceOptions.length; i++){
            var group = this.serviceOptions[i];
            var groupItems = [];
            for(var j=0; j<group.options.length; j++){
                var item = this.getCalculatedOption(group.options[j].value);
                if (item){
                    this.serviceOptions[i].options[j].monthly_price = this.formatPrice(item.MonthlyPrice);
                }
                if (this.serviceValues.includes(group.options[j].value)){
                    if (item){
                        var p = this.formatPrice(item.MonthlyPrice)+' kr.';
                        if (group.options[j].default && group.options[j].hide_price){
                            p = "Inkluderet";
                        }
                        groupItems.push({'title': group.options[j].name, 'price': p});


                        if (group.title && group.title == "El-abonnement"){
                            this.haveElAbonoment = true;
                            this.haveElAbonomentPrice = item.MonthlyPrice;
                        }
                    }
                }
            }
            var title = "Tilvalg";
            if (group.title){
                title = group.title;
            }
            this.summaryData.push({'title': title, 'items': groupItems});


        }

        //Tires
        var groupItems = [];
        for(var i=0; i<this.tireAgreementOptions.length; i++){
            var item = this.getCalculatedTireDeal(this.tireAgreementOptions[i].value);
            if (item){
                this.tireAgreementOptions[i].monthly_price = this.formatPrice(item.Price);
            }
            if (this.tireAgreementValues.includes(this.tireAgreementOptions[i].value)){
                if (item){
                    groupItems.push({'title': this.tireAgreementOptions[i].name, 'price': this.formatPrice(item.Price)+' kr.'});
                }
            }
        }
        this.summaryData.push({'title': 'Dækaftale', 'items': groupItems, "info": ""});

        //Insurance
        for(var i=0; i<this.insuranceTypes.length; i++){
            if (this.insuranceTypes[i].value == this.selectedInsuranceType){

                this.$store.dispatch('setInsuranceCompany', this.insuranceTypes[i].name);
                this.summaryData.push({'title': 'Forsikring', 'items': [{'title': 'Forsikringsselskab', 'price': this.insuranceTypes[i].name}]});
            }
        }
        if (this.selectedInsuranceType == "other"){
            this.$store.dispatch('setInsuranceCompany', this.$refs.otherInsuranceField.liveValue);
        }
        if (this.selectedInsuranceType !== "opendo-insurance"){
            this.$store.dispatch('setInsurance', null);
        }




        //Other
        this.summaryData.push({'title': 'Øvrige', 'items': []});


        this.economyData = [

        ];



        if (this.isCorporate){
            //Corporate
            var items = [];
            var price1 = 0;
            if (contract.Leasing.DownPayment) {
                if (contract.Leasing.DownPayment.IncVat) {
                    price1+=contract.Leasing.DownPayment.IncVat;
                    items.push({'title': '1. ekstraordinære leasingafgift', 'price': this.formatPrice(contract.Leasing.DownPayment.IncVat)+" kr."});
                }
            }
            // if (contract.Leasing.InsuranceAdministration) {
            //     price1+=contract.Leasing.InsuranceAdministration;
            //     items.push({'title': 'Forsikringsadministration', 'price': this.formatPrice(contract.Leasing.InsuranceAdministration)+' kr.'});
            // }
            // if (contract.Leasing.InitialFee) {
            //     price1+=contract.Leasing.InitialFee*1.25;
            //     items.push({'title': 'Stiftelsesomkostninger', 'price': this.formatPrice(contract.Leasing.InitialFee*1.25)+' kr.'});
            // }
            // if (price1) { items.push({'title': 'I alt til betaling ved levering', 'price': this.formatPrice(price1)+' kr.'}); }

            this.economyData.push({'title': 'Økonomi', 'items': items});



            // this.economyData.push({'title': 'Leasingydelse pr. måned', 'price': 5});

            var items = [];

                // items.push({'title': 'Skattemæssig værdi ved indreg. (med forbehold for genberegning)', 'price': "-"});
                if (contract.Leasing.TaxValueInclVat){
                    items.push({'title': 'Beskatningsgrundlag ved indreg. (med forbehold for genberegning)', 'price': this.formatPrice(contract.Leasing.TaxValueInclVat)+" kr."});
                }
                // items.push({'title': 'Beskatningsgrundlag ved indreg. (med forbehold for genberegning)', 'price': this.formatPrice(this.detailedCar.vehicle.price.tax.taxable_value)+" kr."});



            // if (contract.Leasing.AarligtMiljoetillaeg) {
                items.push({'title': 'Miljøtillæg (årligt)', 'price': this.formatPrice(contract.Leasing.AarligtMiljoetillaeg)+" kr."});
            // }
            // if (contract.Leasing.TaxValueInclVatMonthly) {
                items.push({'title': 'Månedlig beskatning inkl. miljøtillæg', 'price': this.formatPrice(contract.Leasing.TaxValueInclVatMonthly)+" kr."});
            // }
            this.economyData.push({'title': 'Beskatning ', 'items': items});


            var items = [];





            items.push({'title': 'Leasingydelse', 'price': this.formatPrice(this.monthlyPrice)+" kr."});
            // if (contract.Leasing.TcOFuelUsageMonthly) {


                if (this.detailedCar.vehicle.fuel_type == "El"){
                    //Electric
                    if(this.haveElAbonoment){
                        items.push({'title': 'Anslået elomkostninger', 'price': this.formatPrice(this.haveElAbonomentPrice)+" kr."});
                    }
                }else if (this.detailedCar.vehicle.fuel_type == "ElBenzinPlugin" || this.detailedCar.vehicle.fuel_type == "ElDieselPlugin"){
                    //Hybrid
                    items.push({'title': 'Anslået brændstofforbrug', 'price': this.formatPrice(contract.Leasing.TcOFuelUsageMonthly)+" kr."});
                    if(this.haveElAbonoment){
                        items.push({'title': 'Anslået elomkostninger', 'price': this.formatPrice(this.haveElAbonomentPrice)+" kr."});
                    }
                }else{
                    //Other
                    items.push({'title': 'Anslået brændstofforbrug', 'price': this.formatPrice(contract.Leasing.TcOFuelUsageMonthly)+" kr."});
                }




            // }
            // if (contract.Leasing.AarligtMiljoetillaeg) {
            //     items.push({'title': 'Anslået elomkostninger', 'price': this.formatPrice(contract.Leasing.AarligtMiljoetillaeg)+" kr."});
            // }
            // if (contract.Leasing.TcoOwnerFeeMonthly) {
                // items.push({'title': 'Anslået ejerafgift (momsfri)', 'price': this.formatPrice(contract.Leasing.TcoOwnerFeeMonthly)+" kr."});
                items.push({'title': 'Anslået ejerafgift (momsfri)', 'price': this.formatPrice(contract.Leasing.YearlyOwnerFee/12)+" kr."});
            // }
            // if (contract.Leasing.TcOInsuranceEstimateMonthly) {
                items.push({'title': 'Forsikring (momsfri)', 'price': this.formatPrice(contract.Leasing.TcOInsuranceEstimateMonthly)+" kr."});
            // }
            // if (contract.Leasing.AverageMonthlyVatDeduction) {
                items.push({'title': 'Momsfradrag', 'price': this.formatPrice(contract.Leasing.AverageMonthlyVatDeduction)+" kr."});
            // }

            this.economyData.push({'title': 'Månedlig totalomkostningsberegning (TCO) ', 'items': items});

            // if (contract.Leasing.MonthlyPaymentAdministrationFee) {
            //     if (contract.Leasing.MonthlyPaymentAdministrationFee.IncVat) {
            //         items.push({'title': 'Månedlig rateadministration', 'price': this.formatPrice(contract.Leasing.MonthlyPaymentAdministrationFee.IncVat)+" kr."});
            //     }
            // }
            if (contract.Leasing.CompletePaymentWithoutInsuranceAndOwnerfee) { this.totalData = {'title': 'TCO pr. måned inkl. nettomoms.', 'price': this.formatPrice(contract.Leasing.TcOMonthly)+' kr.'}; }

        }else{
            //Private
            var items = [];
            var price1 = 0;
            if (contract.Leasing.DownPayment) {
                // if (contract.Leasing.DownPayment.IncVat) {
                    price1+=contract.Leasing.DownPayment.IncVat;
                    items.push({'title': 'Ekstraordinær leasingydelse', 'price': this.formatPrice(contract.Leasing.DownPayment.IncVat)+" kr."});
                // }
            }
            // if (contract.Leasing.InsuranceAdministration) {
                price1+=contract.Leasing.InsuranceAdministration;
                items.push({'title': 'Forsikringsadministration', 'price': this.formatPrice(contract.Leasing.InsuranceAdministration)+' kr.'});
            // }
            // if (contract.Leasing.InitialFee) {
                price1+=contract.Leasing.InitialFee*1.25;
                items.push({'title': 'Stiftelsesomkostninger', 'price': this.formatPrice(contract.Leasing.InitialFee*1.25)+' kr.'});
            // }
            if (price1) { items.push({'title': 'I alt til betaling ved levering', 'price': this.formatPrice(price1)+' kr.'}); }


            this.economyData.push({'title': 'Førstegangydelse, som betales ved levering af bilen', 'items': items});

            // this.economyData.push({'title': 'Leasingydelse pr. måned', 'price': 5});

            var items = [];
            if (contract.Leasing.VehicleReturnFee) {
                // if (contract.Leasing.VehicleReturnFee.IncVat) {
                    items.push({'title': 'Tilbageleveringsgebyr', 'price': this.formatPrice(contract.Leasing.VehicleReturnFee.IncVat)+" kr."});
                // }
            }

            // if (contract.Leasing.AdministrationFee) {
                items.push({'title': 'Månedlig rateadministration', 'price': this.formatPrice(contract.Leasing.AdministrationFee * 1.25)+" kr."});
            // }
            // if (contract.Leasing.MonthlyPaymentAdministrationFee) {
            //     if (contract.Leasing.MonthlyPaymentAdministrationFee.IncVat) {
            //         items.push({'title': 'Månedlig rateadministration', 'price': this.formatPrice(contract.Leasing.MonthlyPaymentAdministrationFee.IncVat)+" kr."});
            //     }
            // }

            this.economyData.push({'title': 'Øvrige omkostninger', 'items': items});

            if (contract.Leasing.CompletePaymentWithoutInsuranceAndOwnerfee) { this.totalData = {'title': 'Totalomkostninger i leasingperioden', 'price': this.formatPrice(contract.Leasing.CompletePaymentWithoutInsuranceAndOwnerfee)+' kr.'}; }
        }




    }









    calculateOpendoInsurance(){
        var th = this;
        this.opendoInsuranceConfigurationOptions;
        var values = [];


        if (this.isCorporate){
            // this.opendoInsurancePriceLabel = this.formatPrice(businessP/12)+" kr.";
            // this.selectedInsuranceTypeOpendo = nameErhverv;
            this.selectedInsuranceTypeOpendo = "KF - Erhverv - Website";
        }else{
            if (this.opendoInsuranceConfigurationOptions){
                if (this.opendoInsuranceYears == "under26"){
                    values.push({"Id": this.opendoInsuranceConfigurationOptions[0].Id});
                    // this.opendoInsurancePriceLabel = this.formatPrice(les26P/12)+" kr.";
                    // this.selectedInsuranceTypeOpendo = namePrivUnder26;
                    this.selectedInsuranceTypeOpendo = "KF - Privat - Under26 - Website";
                }else{
                    values.push({"Id": this.opendoInsuranceConfigurationOptions[1].Id});
                    // this.opendoInsurancePriceLabel = this.formatPrice(over26P/12)+" kr.";
                    // this.selectedInsuranceTypeOpendo = namePrivOver26;
                    this.selectedInsuranceTypeOpendo = "KF - Privat - Over26 - Website";
                }
            }
        }
        if (this.selectedInsuranceType == 'opendo-insurance'){

            th.$store.dispatch('setInsuranceCompany', this.selectedInsuranceTypeOpendo);
            th.$store.dispatch('setInsurance', {
                "UseInsurance": true,
                "ConfigurationId": th.opendoInsuranceConfigurationId,
                // "ConfigurationId": 123,
                "Values": values
            });
            this.opendoInsurancePriceLabel = this.opendoInsurancePriceLabelCalculating;
            // this.opendoInsuranceYearsClick('');

            this.calculateContractViaRest(this.calculateContractServiceUrl);



        }
    }
    handleOpendoInsuranceValues(){

        // alert(this.calculatedContract.Leasing.TcOInsuranceEstimateMonthly);
        console.log(this.calculatedContract.Leasing.VehiclePriceIncOptionsIncRegFeeIncVAT);
        console.log(this.calculatedContract.Leasing);
        // if (this.calculatedContract.Leasing.TcOInsuranceEstimateMonthly === 0) {
        //     alert("NO PRICE");
        // } else {
        //     alert("Price: " + this.calculatedContract.Leasing.TcOInsuranceEstimateMonthly);
        // }
        this.opendoInsurancePriceLabel = this.formatPrice(this.calculatedContract.Leasing.TcOInsuranceEstimateMonthly)+" kr.";
        // this.selectedInsuranceTypeOpendo = namePrivUnder26;
    }

    calculateOpendoInsuranceOld(){

        var carPrice = 0;
        // var carPrice2 = 0;
        // if (this.calculatedContract && this.calculatedContract.Leasing && this.calculatedContract.Leasing.VehiclePriceIncOptionsIncRegFeeIncVAT){
        //     carPrice2 = this.calculatedContract.Leasing.VehicleTotalPriceIncVat;
        // }
        if (this.calculatedContract && this.calculatedContract.Leasing && this.calculatedContract.Leasing.TaxValueInclVat){
            carPrice = this.calculatedContract.Leasing.TaxValueInclVat;
        }
        // alert(carPrice+" "+carPrice2);


        var maxInsurance = (this.opendoInsuranceData.priceCriterias[this.opendoInsuranceData.priceCriterias.length-1].key_val);

        if (carPrice > maxInsurance){
            this.showOpendoInsuranceDisclaimer = true;
            this.opendoInsuranceDisclaimerPrice = this.formatPrice(maxInsurance);
        }else{

        }


        // this.$refs.insuranceSelectField.showError("Opendo kan ikke tilbyde forsikringer på biler med en værdi over 600.000 kr. inklusiv ekstraudstyr og tilvalg.");

        var les26P = 0;
        var over26P = 0;
        var businessP = 0;

        for (var i=0; i<this.opendoInsuranceData.priceCriterias.length; i++){
            var item = this.opendoInsuranceData.priceCriterias[i];
            if (item['<26']){
                les26P = item['<26'];
                if (les26P && carPrice<item.key_val){
                    break;
                }
            }
        }

        for (var i=0; i<this.opendoInsuranceData.priceCriterias.length; i++){
            var item = this.opendoInsuranceData.priceCriterias[i];
            if (item['>26']){
                over26P = item['>26'];
                if (over26P && carPrice<item.key_val){
                    break;
                }
            }
        }

        for (var i=0; i<this.opendoInsuranceData.priceCriterias.length; i++){
            var item = this.opendoInsuranceData.priceCriterias[i];
            if (item['business']){
                businessP = item['business'];
                if (businessP && carPrice<item.key_val){
                    break;
                }
            }
        }

        // var breakFlag = false;
        // for (var i=0; i<this.opendoInsuranceData.priceCriterias.length; i++){
        //     var item = this.opendoInsuranceData.priceCriterias[i];
        //     if (item['<26']){
        //         if (!les26P){
        //             les26P = item['<26'];
        //         }

        //         if (les26P && carPrice>item.key_val){
        //             les26P = item['<26'];
        //         }
        //         if (les26P && carPrice<=item.key_val){
        //             break;
        //         }
        //     }
        // }
        // breakFlag = false;
        // for (var i=0; i<this.opendoInsuranceData.priceCriterias.length; i++){
        //     var item = this.opendoInsuranceData.priceCriterias[i];
        //     if (item['>26']){
        //         if (!over26P){
        //             over26P = item['>26'];
        //         }
        //         if (over26P && carPrice>item.key_val){
        //             over26P = item['>26'];
        //         }
        //         if (over26P && carPrice<=item.key_val){

        //             break;
        //         }
        //     }
        // }
        // breakFlag = false;
        // for (var i=0; i<this.opendoInsuranceData.priceCriterias.length; i++){
        //     var item = this.opendoInsuranceData.priceCriterias[i];
        //     if (item['business']){
        //         if (!businessP){
        //             businessP = item['business'];
        //         }

        //         if (businessP && carPrice>item.key_val){
        //             businessP = item['business'];
        //         }
        //         if (businessP && carPrice<=item.key_val){
        //             break;
        //         }

        //     }
        // }

        var namePrivUnder26 = "";
        var namePrivOver26 = "";
        var nameErhverv = "";
        for (var i=0; i<this.opendoInsuranceData.priceConfigs.length; i++){
            var item = this.opendoInsuranceData.priceConfigs[i];
            if (item.id == 120){ namePrivUnder26 = item.name; }
            if (item.id == 121){ namePrivOver26 = item.name; }
            if (item.id == 122){ nameErhverv = item.name; }
            if (item.id == 123){ namePrivOver26 = item.name; }
            if (item.id == 123){ namePrivUnder26 = item.name; }


        }



        if (this.isCorporate){
            this.opendoInsurancePriceLabel = this.formatPrice(businessP/12)+" kr.";
            this.selectedInsuranceTypeOpendo = nameErhverv;
        }else{
            if (this.opendoInsuranceYears == "under26"){
                this.opendoInsurancePriceLabel = this.formatPrice(les26P/12)+" kr.";
                this.selectedInsuranceTypeOpendo = namePrivUnder26;
            }else{
                this.opendoInsurancePriceLabel = this.formatPrice(over26P/12)+" kr.";
                this.selectedInsuranceTypeOpendo = namePrivOver26;
            }
        }

        if (this.selectedInsuranceTypeOpendo == ""){
            this.selectedInsuranceTypeOpendo = "KF - Unknown - Website";
        }

        this.opendoInsurancePriceCalculating = false;

        // console.log("aaaa");
        // console.log(this.opendoInsuranceData);
        // console.log(les26P);
        // console.log(over26P);
        // console.log(businessP);
    }

    formatDate(date: string){
        var d = new Date(date);
        var day = d.getDate();
        var month = d.getMonth()+1;
        var day_s = ""+day;
        var month_s = ""+month;
        if (day<10){
            day_s = "0"+day;
        }
        if (month<10){
            month_s = "0"+month;
        }

        return (day_s+"-"+month_s+"-"+d.getFullYear());
    }

    getOpendoInsurancesViaContract() {
        var th = this;

        th.calculateOpendoInsurance();
        // this.opendoInsuranceConfigurationOptions;
        // th.$store.dispatch('setInsuranceCompany', "aaaaaa");
        // th.$store.dispatch('setInsurance', {
        //     "UseInsurance": true,
        //     "ConfigurationId": th.opendoInsuranceConfigurationId,
        //     // "ConfigurationId": 123,
        //     // "Values": [
        //     //     // {"Id": 181}
        //     //     {"Id": 184}
        //     // ]
        // });

        // this.calculateContractViaRest(this.calculateContractServiceUrl);

    }
    async getOpendoInsurancesViaRest(serviceUrl: string) {




        // https://api-test.opendo.dk/leasing/insurance/prices?configIds=120,121,122

        let self = this;
            this.abAxios.abGetWithToken(serviceUrl, false, function successCallback(response: any) {

                self.opendoInsuranceData = response.data;

                var crit = self.opendoInsuranceData.priceCriterias;
                var arr = [];
                for (var key in crit) {
                    // skip loop if the property is from prototype
                    if (!crit.hasOwnProperty(key)) continue;
                    var obj = crit[key];
                    obj.key = key;
                    obj.key_val = obj.key.replaceAll("<", "");
                    obj.key_val = obj.key_val.replaceAll(">", "");
                    obj.key_val = obj.key_val.replaceAll(".", "");
                    obj.key_val = parseFloat(obj.key_val);

                    arr.push(obj);
                }
                arr.sort((a, b) => a.key_val - b.key_val);
                self.opendoInsuranceData.priceCriterias = arr;


                self.calculateOpendoInsurance();



            }, function errorCallback(error: any) {

                console.log(error);


            });
    }








    /*USER SELECTION START*/
    handleAvailableOptionsSelection() {


        var _availableOptions = this.calculatedContract.AvailableOptions;
        var _availableTireOptions = this.calculatedContract.TireOptions;

        if (_availableOptions === undefined) {
            return;
        }

        //start med at tømme array med valgte udstyr/service
        this.$store.dispatch('clearOptions');
        //tøm indkøbskurv for valgte udstyr/services/farve
        this.basketSelectedItems = [];
        //tøm indkøbskurv total udstyr pris
        this.basketEquipmentPriceTotal = 0;

        //hvis farve sat, tilføj pris til totalpris
        if (this.colorSelected !== null) {
            this.basketEquipmentPriceTotal += this.colorSelected.monthly_price;
        }
        //løb alle valgte udstyr/service Id'er igennem og find AvailableOptions object
        var _selectedOptions = [];

        //
        var _allOptions = [];
        _allOptions = _allOptions.concat(this.preFittedValues);
        _allOptions = _allOptions.concat(this.retroFittedValues);
        // _allOptions = _allOptions.concat(this.tireAgreementValues);
        _allOptions = _allOptions.concat(this.serviceValues);

        // var serviceValues = [];
        // for(var i=0; i<this.serviceOptions.length; i++){
        //     this.serviceOptions[i].options;
        //     for(var j=0; j<this.serviceOptions.length; i++){
        //     }
        // }

        if (_availableTireOptions && this.tireAgreementValues){
            this.$store.dispatch('availableTireDealSelected', null);
            for (var i = 0; i < _availableTireOptions.length; i++) {
                if (this.tireAgreementValues.includes(_availableTireOptions[i].TireDealType)){
                    this.$store.dispatch('availableTireDealSelected', _availableTireOptions[i].TireDealType);
                }
            }
        }



        for (var i = 0; i < _allOptions.length; i++) {
            for (var j = 0; j < _availableOptions.length; j++) {
                if (_availableOptions[j].Id === _allOptions[i]) {
                    //berig contract model med valgte AvailableOptions inden REST request mod bytelab
                    _selectedOptions.push(_availableOptions[j]);
                    //hvis valgte AvailableOption er service, tilfoej den til indkøbskurven
                    if (_availableOptions[j].OptionType === 4) {
                        this.basketSelectedItems.push(_availableOptions[j]);
                    }
                    //beregn total pris for fabriksmonteret og eftermonteret udstyr til visning i indkøbskurv
                    else if (_availableOptions[j].OptionType === 2 || _availableOptions[j].OptionType === 3) {
                        this.basketEquipmentPriceTotal += _availableOptions[j].MonthlyPrice;
                    }
                    break;
                }
            }
        }
        //tilføj udstyr og service valg til kontrakt
        this.$store.dispatch('addOptions', _selectedOptions);
    }
    /*USER SELECTION END*/


    //Old stuff

    handleSingleColorChoice() {
        //hvis der er én og kun én farvemulighed, så er vi nødt til at sætte værdien i this.colorSelected eksplicit, da vores @watch så ikke bliver ramt, som følge af valg i farvedropdown listen.
        if (this.detailedCar.options.colour_options.length === 1) {
            //colorSelected er nødvendig at sætte, for at kunne komme igennem valideringen i Form.vue
            this.colorSelected = this.detailedCar.options.colour_options[0].name;
            return true;
        }
        return false;

    }


    stepToggleScroll(classElement:Element) {
        setTimeout(() => {
            VueScrollTo.scrollTo(classElement);
        }, 100);
    }

    kmStepToggleFunc() {
        this.kmStepToggle = !this.kmStepToggle;

        if(this.kmStepToggle) {
            this.stepToggleScroll(this.$refs.scrollKm);
        }

        //kollaps alle øvrige trin
//        this.kmStepToggle = false;
        this.farveStepToggle = false;
        this.fabrikStepToggle = false;
        this.efterStepToggle = false;
        this.serviceStepToggle = false;
        this.daekStepToggle = false;
        this.forsikringStepToggle = false;
    }

    farveStepToggleFunc() {
        this.farveStepToggle = !this.farveStepToggle;

        if(this.farveStepToggle) {
            this.stepToggleScroll(this.$refs.scrollColor);
        }

        this.kmStepToggle = false;
//        this.farveStepToggle = false;
        this.fabrikStepToggle = false;
        this.efterStepToggle = false;
        this.serviceStepToggle = false;
        this.daekStepToggle = false;
        this.forsikringStepToggle = false;

    }

    fabrikStepToggleFunc() {
        this.fabrikStepToggle = !this.fabrikStepToggle;

        if(this.fabrikStepToggle) {
            this.stepToggleScroll(this.$refs.scrollFabrik);
        }

        this.kmStepToggle = false;
        this.farveStepToggle = false;
 //       this.fabrikStepToggle = false;
        this.efterStepToggle = false;
        this.serviceStepToggle = false;
        this.daekStepToggle = false;
        this.forsikringStepToggle = false;

    }

    efterStepToggleFunc() {
        this.efterStepToggle = !this.efterStepToggle;

        if(this.efterStepToggle) {
            this.stepToggleScroll(this.$refs.scrollEfterStep);
        }

        this.kmStepToggle = false;
        this.farveStepToggle = false;
        this.fabrikStepToggle = false;
//        this.efterStepToggle = false;
        this.serviceStepToggle = false;
        this.daekStepToggle = false;
        this.forsikringStepToggle = false;

    }

    serviceStepToggleFunc() {
        this.serviceStepToggle = !this.serviceStepToggle;

        if(this.serviceStepToggle) {
            this.stepToggleScroll(this.$refs.scrollService);
        }

        this.kmStepToggle = false;
        this.farveStepToggle = false;
        this.fabrikStepToggle = false;
        this.efterStepToggle = false;
//        this.serviceStepToggle = false;
        this.daekStepToggle = false;
        this.forsikringStepToggle = false;

    }

    daekStepToggleFunc() {
        this.daekStepToggle = !this.daekStepToggle;

        if(this.daekStepToggle) {
            this.stepToggleScroll(this.$refs.scrollDaek);
        }

        this.kmStepToggle = false;
        this.farveStepToggle = false;
        this.fabrikStepToggle = false;
        this.efterStepToggle = false;
        this.serviceStepToggle = false;
 //       this.daekStepToggle = false;
        this.forsikringStepToggle = false;

    }

    forsikringStepToggleFunc() {
        this.forsikringStepToggle = !this.forsikringStepToggle;

        if(this.forsikringStepToggle) {
            this.stepToggleScroll(this.$refs.scrollForsikring);
        }

        this.kmStepToggle = false;
        this.farveStepToggle = false;
        this.fabrikStepToggle = false;
        this.efterStepToggle = false;
        this.serviceStepToggle = false;
        this.daekStepToggle = false;
//        this.forsikringStepToggle = false;

    }

    formularStepToggleFunc() {
        this.formularStepToggle = true; //ingen egentlig toggle her. Hvis vi har åbnet formularområdet, jamen så forbliver det åbent, også selvom du går tilbage til forrige trin i stepflowet.

        this.kmStepToggle = false;
        this.farveStepToggle = false;
        this.fabrikStepToggle = false;
        this.efterStepToggle = false;
        this.serviceStepToggle = false;
        this.daekStepToggle = false;
        this.forsikringStepToggle = false;
    }


    skjulFaerdigKnap() {
        let valgtForsikringsselskabIDropdown: any = this.$store.getters.getEksInsurancesCompany;
        let isCorporate: any = this.isCorporate;
        let insurancesData = this.$store.getters.getInsurancesData;


        // console.log("Valgt forsikringsselskab: " + valgtForsikringsselskabIDropdown);
        // console.log("isCorporate " + isCorporate);
        // console.log("InsurancesData checkbox: " + insurancesData.almbrandInsurancesCheckBox);
        // console.log("Insurances price: " + insurancesData.insurancePrice);
        // console.log("-------------------------------------------------------------");


        if (!isCorporate && valgtForsikringsselskabIDropdown === null && insurancesData.almbrandInsurancesCheckBox === false && insurancesData.insurancePrice === 0) {
            return false
        } else if (!isCorporate && valgtForsikringsselskabIDropdown === null && insurancesData.almbrandInsurancesCheckBox === true && insurancesData.insurancePrice === 0) {
            return false
        } else if (!isCorporate && valgtForsikringsselskabIDropdown === "" && insurancesData.almbrandInsurancesCheckBox === true && insurancesData.insurancePrice === 0) {
            return false
        } else if (!isCorporate && valgtForsikringsselskabIDropdown === "" && insurancesData.almbrandInsurancesCheckBox === false && insurancesData.insurancePrice === 0) {
            return false
        } else {
            return true
        }
    }

    //Stepflowet er lidt kompliceret, da bilvalget (valgt inden at carCalc startes) er afgørende for, hvilke markupchunks, der skal være synlige i brugergrænsefladen for slutbrugeren.
    //Dvs. at det ikke er de samme markupchunks der ALTID skal vises. Det varierer på baggrund af bilvalget.
    //Hvis en markupchunk ikke er relevant, så bliver den ikke vist til slutbrugeren, men den vil stadig være i markuppen.
    //Dette komplicerer dog stepflowet, da den så skal beregne hvilke markupchunk et klik på "Næste"-knappen skal medføre.
    //Der er hensyntagen til rækkefølgen i markuppen i CarCalc.vue, så vær opmærksom på hvad der står (ex. if step===3 osv.).


    //KM og leasingperiode: step==1
    //Farve: step==2
    //Fabriksmonteret udstyr: step==3
    //Eftermonteret udstyr: step==4
    //Service: step==5
    //Dæk: step==6
    //Forsikring: step==7

    getValgtFabriksUdstyr() {
        let valgtUdstyr: string = "";

        if (this.valgtFabriksUdstyr != null) {

            for (let i = 0; i < this.valgtFabriksUdstyr.length; i++) {
                valgtUdstyr += this.valgtFabriksUdstyr[i] + ", ";
            }
        }

        //fjern sidste komma den opbyggede streng
        let sidsteKommaIndex = valgtUdstyr.lastIndexOf(",");
        let valgtUdstyrUdenSlutKomma: string = "";
        valgtUdstyrUdenSlutKomma = valgtUdstyr.substring(0, sidsteKommaIndex);

        return valgtUdstyrUdenSlutKomma;
    }

    getValgtEkstraUdstyr() {
        let valgtUdstyr: string = "";

        if (this.valgtEkstraUdstyr != null) {

            for (let i = 0; i < this.valgtEkstraUdstyr.length; i++) {
                valgtUdstyr += this.valgtEkstraUdstyr[i] + ", ";
            }
        }

        //fjern sidste komma den opbyggede streng
        let sidsteKommaIndex = valgtUdstyr.lastIndexOf(",");
        let valgtUdstyrUdenSlutKomma: string = "";
        valgtUdstyrUdenSlutKomma = valgtUdstyr.substring(0, sidsteKommaIndex);

        return valgtUdstyrUdenSlutKomma;
    }

    getValgtDaek() {
        return this.valgtDaek;
    }

    getValgtService() {
        let valgtUdstyr: string = "";

        if (this.valgtService != null) {

            for (let i = 0; i < this.valgtService.length; i++) {
                valgtUdstyr += this.valgtService[i] + ", ";
            }
        }

        //fjern sidste komma den opbyggede streng
        let sidsteKommaIndex = valgtUdstyr.lastIndexOf(",");
        let valgtUdstyrUdenSlutKomma: string = "";
        valgtUdstyrUdenSlutKomma = valgtUdstyr.substring(0, sidsteKommaIndex);

        return valgtUdstyrUdenSlutKomma;
    }

    public handleFabriksUdstyr(e, data: any): void {
        //tilføj kun en værdi, hvis den er checked, og at den ikke allerede findes i arrayet.
        if (e.checkedValue && this.valgtFabriksUdstyr.indexOf(data.name) < 0) {
            this.valgtFabriksUdstyr.push(data.name);
        }

        //fjern kun en værdi, hvis den er unchecked, og findes i arrayet
        if (e.checkedValue === false && this.valgtFabriksUdstyr.indexOf(data.name) >= 0) {
            let index: number = this.valgtFabriksUdstyr.indexOf(data.name);
            this.valgtFabriksUdstyr.splice(index, 1);
        }

        //sortér slutteligt arrayet alfabetisk
        this.valgtFabriksUdstyr.sort();
    }

    public handleEkstraUdstyr(e, data: any): void {
        //tilføj kun en værdi, hvis den er checked, og at den ikke allerede findes i arrayet.
        if (e.checkedValue && this.valgtEkstraUdstyr.indexOf(data.name) < 0) {
            this.valgtEkstraUdstyr.push(data.name);
        }

        //fjern kun en værdi, hvis den er unchecked, og findes i arrayet
        if (e.checkedValue === false && this.valgtEkstraUdstyr.indexOf(data.name) >= 0) {
            let index: number = this.valgtEkstraUdstyr.indexOf(data.name);
            this.valgtEkstraUdstyr.splice(index, 1);
        }

        //sortér slutteligt arrayet alfabetisk
        this.valgtEkstraUdstyr.sort();

    }

    public handleService(e, data: any): void {
        console.log(data);

        //tilføj kun en værdi, hvis den er checked, og at den ikke allerede findes i arrayet.
        if (e.checkedValue && this.valgtService.indexOf(data.name) < 0) {
            this.valgtService.push(data.name);
        }

        //fjern kun en værdi, hvis den er unchecked, og findes i arrayet
        if (e.checkedValue === false && this.valgtService.indexOf(data.name) >= 0) {
            let index: number = this.valgtService.indexOf(data.name);
            this.valgtService.splice(index, 1);
        }

        //sortér slutteligt arrayet alfabetisk
        this.valgtService.sort();
}

    public handleDaek(e, data: any): void {
        //Denne her er en special case. Læg mærke til, at du IKKE både kan vælge "Dækaftale" og "Dækaftale med vinterdæk". Det er enten eller...eller slet ingen af delene.

        //hvis togglen er blevet slået til, så undersøg om vi allerede har sat den værdi i vores string.
        if (e.checkedValue && this.valgtDaek.localeCompare(data.Name) !== 0) {
            //hvis ikke vi har lagret den værdi, så lagr den
            this.valgtDaek = data.Name;
        }

        //hvis togglen er blevt slået fra, så nulstil værdien af vores string
        if (e.checkedValue === false) {
            this.valgtDaek = "";
        }
    }

    calculateNextStepFromKM() {
        this.kmStepHasBeenFilledOut = true;


        //vi undersøger hvor langt frem i step-flowet vi skal steppe.
        let skipFarve: boolean = false;
        let skipFabrik: boolean = false;
        let skipEfter: boolean = false;
        let skipService: boolean = false;
        let skipDaek: boolean = false;

        //vi undersøger, om der er data for "Farve"
        if (this.detailedCar.options === undefined || this.detailedCar.options === null || this.detailedCar.options.colour_options.length <= 1) {
            //vi skal have sat id på den valgte farve. Casen er her, at brugeren ikke har haft dropdown listen at vælge i, og derfor er vi nødt til manuelt at trigge et kald til konktrakten.
            //der er lagt en ref ind ift. farvemarkuppen i Carcalc.vue. Hvis markuppen er defineret i DOM, så betyder det, at vores case med at der kun var/er 1 mulig farve, er i spil.

            //Vi sanitychecker først lige på, om vi har 1 element i colour arrayet
            if (this.detailedCar.options.colour_options.length === 1) {
                let coloridmarkup: any = this.$refs.singlecolorid;
                if (coloridmarkup !== undefined) {
                    let color = {
                        //her hentes id på farven
                        Id: this.detailedCar.options.colour_options[0].id
                    }
                    //her sendes farve-id til kontrakten
                    this.$store.dispatch('availableColorSelected', color);
                    this.handleAvailableOptionsSelection();
                    this.calculateContractViaRest(this.calculateContractServiceUrl);
                }
            }

            //vi har ingen data for "Farve", ergo skal vi hoppe den over
            skipFarve = true;
        }

        //vi undersøger, om der er data for "Fabriksmonteret udstyr"
        if (this.detailedCar.options.pre_fitted_options === undefined || this.detailedCar.options.pre_fitted_options === null || this.detailedCar.options.pre_fitted_options.length <= 0) {
            //vi har ingen data for "Fabriksmonteret udstyr", ergo skal vi hoppe den over
            skipFabrik = true;
        }

        //vi undersøger, om der er data for "Eftermonteret udstyr"
        if (this.detailedCar.options.retro_fitted_options === undefined || this.detailedCar.options.retro_fitted_options === null || this.detailedCar.options.retro_fitted_options.length <= 0) {
            //vi har ingen data for "Eftermonteret udstyr", ergo skal vi hoppe den over
            skipEfter = true;
        }

        //vi undersøger, om der er data for "Service"
        if (this.detailedCar.options.service_options === undefined || this.detailedCar.options.service_options === null || this.detailedCar.options.service_options.length <= 0) {
            //vi har ingen data for "Service", ergo skal vi hoppe den over
            skipService = true;
        }

        if (this.tireDealOptions == undefined) {
            skipDaek = true;
        }

        if (skipFarve && skipFabrik && skipEfter && skipService && skipDaek) {
            this.forsikringStepHasBeenActivated = true;
            this.forsikringStepToggleFunc();
//            this.step = 7; //hop ned til forsikring
        }

        else if (skipFarve && skipFabrik && skipEfter && skipService) {
            this.daekStepHasBeenActivated = true;
            this.daekStepToggleFunc();
//            this.step = 6; //hop til dæk
        }

        else if (skipFarve && skipFabrik && skipEfter) {
            this.serviceStepHasBeenActivated = true;
            this.serviceStepToggleFunc();
//            this.step = 5; //hop til service
        }

        else if (skipFarve && skipFabrik) {
            this.efterStepHasBeenActivated = true;
            this.efterStepToggleFunc();
//            this.step = 4; //hop til eftermonteret udstyr
        }

        else if (skipFarve) {
            this.fabrikStepHasBeenActivated = true;
            this.fabrikStepToggleFunc();
//            this.step = 3; //hop til fabriksmonteret udstyr
        }

        else {
            this.farveStepHasBeenActivated = true;
            this.farveStepToggleFunc();
//            this.step = 2; //hop til Farve
        }
    }

    calculateNextStepFromFarve() {
        this.farveStepHasBeenFilledOut = true;
        //vi undersøger hvor langt frem i step-flowet vi skal steppe.
        let skipFabrik: boolean = false;
        let skipEfter: boolean = false;
        let skipService: boolean = false;
        let skipDaek: boolean = false;


        //vi undersøger, om der er data for "Fabriksmonteret udstyr"
        if (this.detailedCar.options.pre_fitted_options === undefined || this.detailedCar.options.pre_fitted_options === null || this.detailedCar.options.pre_fitted_options.length <= 0) {
            //vi har ingen data for "Fabriksmonteret udstyr", ergo skal vi hoppe den over
            skipFabrik = true;
        }


        //vi undersøger, om der er data for "Eftermonteret udstyr"
        if (this.detailedCar.options.retro_fitted_options === undefined || this.detailedCar.options.retro_fitted_options === null || this.detailedCar.options.retro_fitted_options.length <= 0) {
            //vi har ingen data for "Eftermonteret udstyr", ergo skal vi hoppe den over
            skipEfter = true;
        }

        //vi undersøger, om der er data for "Service"
        if (this.detailedCar.options.service_options === undefined || this.detailedCar.options.service_options === null || this.detailedCar.options.service_options.length <= 0) {
            //vi har ingen data for "Service", ergo skal vi hoppe den over
            skipService = true;
        }

        if (this.tireDealOptions == undefined) {
            skipDaek = true;
        }



        if (skipFabrik && skipEfter && skipService && skipDaek) {
            this.forsikringStepHasBeenActivated = true;
            this.forsikringStepToggleFunc();
//            this.step = 7; //hop ned til forsikring
        }

        else if (skipFabrik && skipEfter && skipService) {
            this.daekStepHasBeenActivated = true;
            this.daekStepToggleFunc();
//            this.step = 6; //hop til dæk
        }

        else if (skipFabrik && skipEfter) {
            this.serviceStepHasBeenActivated = true;
            this.serviceStepToggleFunc();
//            this.step = 5; //hop til service
        }

        else if (skipFabrik) {
            this.efterStepHasBeenActivated = true;
            this.efterStepToggleFunc();
//            this.step = 4; //hop til eftermonteret udstyr
        }

        else {
            this.fabrikStepHasBeenActivated = true;
            this.fabrikStepToggleFunc();
//            this.step = 3; //hop til fabriksmonteret udstyr
        }
    }

    calculateNextStepFromFabriksmonteret() {
        this.fabrikStepHasBeenFilledOut = true;
        //vi undersøger hvor langt frem i step-flowet vi skal steppe.
        let skipEfter: boolean = false;
        let skipService: boolean = false;
        let skipDaek: boolean = false;

        //vi undersøger, om der er data for "Eftermonteret udstyr"
        if (this.detailedCar.options.retro_fitted_options === undefined || this.detailedCar.options.retro_fitted_options === null || this.detailedCar.options.retro_fitted_options.length <= 0) {
            //vi har ingen data for "Eftermonteret udstyr", ergo skal vi hoppe den over
            skipEfter = true;
        }

        //vi undersøger, om der er data for "Service"
        if (this.detailedCar.options.service_options === undefined || this.detailedCar.options.service_options === null || this.detailedCar.options.service_options.length <= 0) {
            //vi har ingen data for "Service", ergo skal vi hoppe den over
            skipService = true;
        }

        if (this.tireDealOptions == undefined) {
            skipDaek = true;
        }

        if (skipEfter && skipService && skipDaek) {
            this.forsikringStepHasBeenActivated = true;
            this.forsikringStepToggleFunc();
//            this.step = 7; //hop ned til forsikring
        }

        else if (skipEfter && skipService) {
            this.daekStepHasBeenActivated = true;
            this.daekStepToggleFunc();
//            this.step = 6; //hop til dæk
        }

        else if (skipEfter) {
            this.serviceStepHasBeenActivated = true;
            this.serviceStepToggleFunc();
//            this.step = 5; //hop til service
        }

        else {
            this.efterStepHasBeenActivated = true;
            this.efterStepToggleFunc();
//            this.step = 4; //hop til eftermonteret udstyr
        }
    }

    calculateNextStepFromEftermonteret() {
        this.efterStepHasBeenFilledOut = true;
        //vi undersøger hvor langt frem i step-flowet vi skal steppe.
        let skipService: boolean = false;
        let skipDaek: boolean = false;


        //vi undersøger, om der er data for "Service"
        if (this.detailedCar.options.service_options === undefined || this.detailedCar.options.service_options === null || this.detailedCar.options.service_options.length <= 0) {
            //vi har ingen data for "Service", ergo skal vi hoppe den over
            skipService = true;
        }

        if (this.tireDealOptions == undefined) {
            skipDaek = true;
        }

        if (skipService && skipDaek) {
            this.forsikringStepHasBeenActivated = true;
            this.forsikringStepToggleFunc();
//            this.step = 7; //hop ned til forsikring
        }

        else if (skipService) {
            this.daekStepHasBeenActivated = true;
            this.daekStepToggleFunc();
//            this.step = 6; //hop til dæk
        }

        else {
            this.serviceStepHasBeenActivated = true;
            this.serviceStepToggleFunc();
//            this.step = 5; //hop til service
        }
    }

    calculateNextStepFromService() {
        this.serviceStepHasBeenFilledOut = true;
        //vi undersøger hvor langt frem i step-flowet vi skal steppe.
        let skipDaek: boolean = false;


        if (this.tireDealOptions == undefined) {
            skipDaek = true;
        }

        if (skipDaek) {
            this.forsikringStepHasBeenActivated = true;
            this.forsikringStepToggleFunc();
//            this.step = 7; //hop ned til forsikring
        }

        else {
            this.daekStepHasBeenActivated = true;
            this.daekStepToggleFunc();
//            this.step = 6; //hop til dæk
        }
    }

    calculateNextStepFromDaek() {
        this.daekStepHasBeenFilledOut = true;
        this.forsikringStepHasBeenActivated = true;

        this.forsikringStepToggleFunc();
//        this.step = 7; //hop ned til forsikring

    }

    calculateNextStepFromForsikring(skip: Boolean) {
        if (skip) {
            this.daekStepHasBeenFilledOut = true;
        }
        this.forsikringStepHasBeenFilledOut = true;
        this.formularStepToggleFunc();
//        this.step = ; //hop til Formularen

    }

    resetColorError() {
        this.userData.colorErrLabel = "";
    }

    /**Callback fra toggles under udstyr/service*/
    public switchToggleChange(item: any): void {
        if (item === null || item === undefined) return;
//        console.log("switchToggleChange init");

        //dæk service bruger typer ISTEDET for Id'er ligesom udstyr/service
        if (item.type === 'DAEKAFTALE') {
            if (item.checkedValue) {
                this.$store.dispatch('availableTireDealSelected', item.id);
            } else {
                this.$store.dispatch('availableTireDealSelected', 0);
            }
            this.handleAvailableOptionsSelection();
            this.calculateContractViaRest(this.calculateContractServiceUrl);
            return;
        }
        if (item.type === "BR1") {
            if (item.checkedValue) {
                this.isShellCardChosen = true;
            } else {
                this.isShellCardChosen = false;
                this.pincodeA = ""; this.pincodeB = ""; this.pincodeC = ""; this.pincodeD = "";
                this.pincodeKeyUp();
            }
        }

        //hvis valgt tilføj id
        if (item.checkedValue) {
            this.checkedAvailableOptions.push(item.id);
        }
        //hvis IKKE valgt fjern id
        else {
            this.checkedAvailableOptions.splice(this.checkedAvailableOptions.indexOf(item.id), 1);
        }
    }

    /**Der er listener på udstyr/service valg og de bliver fanget her*/
    // @Watch('checkedAvailableOptions')
    // checkedAvailableOptionsChanged(newValue: any, oldValue: any) {
    //     if (this.calculateFlag) {
    //         this.handleAvailableOptionsSelection();
    //         this.calculateContractViaRest(this.calculateContractServiceUrl);
    //     }
    // }

    /**Callback fra slider*/
    // periodChanged(data: any): void {
    //     if (this.calculateFlag) {
    //         let index = this.periodData.indexOf(data.value);
    //         let _period = this.$store.getters.getCreatedContract.AvailablePeriods[index];
    //         this.$store.dispatch('availablePeriodsSelected', _period);
    //         this.calculateContractViaRest(this.calculateContractServiceUrl).then(() => {
    //             this.rebuildMileageFromMatrix();
    //         })
    //     }
    // }

    /**
     * dynamisk genopbyg antal km. range med data opsætning fra CC pris matrix
     * */
    rebuildMileageFromMatrix(): void {
        let _configContract = this.$store.getters.getCreatedContract;

        //opbyg perioder med keys
        this.findAvailablePeriodsMileages(_configContract);
        //vælg altid den første så det passer i pris matrix
        //ellers kan man risikere at km. range ikke findes i matrix
        //i forhold til antal leasing måneder
        let mileageIndex = 0;
        //hvis km. antal findes i pris matrix lad vær med at resette den.
        if (_configContract.AvailableMileages.includes(_configContract.Contract.Leasing.AnnualMileage)) {
            mileageIndex = _configContract.AvailableMileages.indexOf(_configContract.Contract.Leasing.AnnualMileage);
        }

        //opdater kontrakt med første index
        _configContract.Contract.Leasing.AnnualMileage = _configContract.AvailableMileages[mileageIndex];
        //gem manipulationer i kontrakt
        this.$store.dispatch('setCreatedContract', _configContract);

        //genopbyg km. range udfra pris matrix
        let _mileageData: string[] = [];
        _configContract.AvailableMileages.forEach((val: number, index: number) => {
            _mileageData.push(this.abAmount.format(val) + " km");
        });

        this.mileageData = _mileageData;
        this.sliders.mileage = mileageIndex;
        //this.$nextTick(() => {
        //    //sæt rette index og value på km. range
        //    this.$refs.mileageRange.setIndex(mileageIndex);
        //    this.$refs.mileageRange.setTheValue(this.mileageData[this.sliders.mileage]);
        //});

    }

    /**Callback fra slider*/
    mileageChanged(data: any): void {
        if (this.calculateFlag) {
            let index = this.mileageData.indexOf(data.value);
            let _mileage = this.$store.getters.getCreatedContract.AvailableMileages[index];
            this.$store.dispatch('availableMileagesSelected', _mileage);
            this.calculateContractViaRest(this.calculateContractServiceUrl);
        }
    }

    @Watch('colorSelected')
    colorOptionsSelected(newValue: any, oldValue: any) {
        if (newValue === 0 || newValue === oldValue) {
            return;
        }
        let color = {
            Id: newValue.id
        }


        this.$store.dispatch('availableColorSelected', color);
        this.handleAvailableOptionsSelection();
        this.calculateContractViaRest(this.calculateContractServiceUrl);
    }







    isChecked(id: number) {
        //return this.checkedAvailableOptions.includes(id);
        return this.checkedAvailableOptions.indexOf(id) !== -1;
    }

    //shell kort håndtering
    isShellCardChosen: boolean = false;
    pincodeA: string = ""; pincodeB: string = ""; pincodeC: string = ""; pincodeD: string = "";

    pincodeKeyPressed($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

        if ((keyCode < 48 || keyCode > 57) || $event.target.value.length > 0) {
            $event.preventDefault();
        }

    }

    pincodeKeyUp() {

        let pincode = this.pincodeA + this.pincodeB + this.pincodeC + this.pincodeD;
        this.$store.dispatch('setFuelCardPincode', pincode);
    }




    /*REST SERVICE START*/
    async getInsurancesViaRest(serviceUrl: string) {
        let self = this;
            this.abAxios.abGetWithToken(serviceUrl, false, function successCallback(response: any) {
                // self.carIsLoaded = 1;
                // if(response.data.data === 0) {
                //     self.carIsLoaded = 2;
                // }
                let configContract = response.data.Data;

                // alert("Insurance part");

            }, function errorCallback(error: any) {

                console.log(error);
                if (!isNullOrUndefined(error.response)) {
                    let errorRes = error.response,
                        statusCode = errorRes.status,
                        statusText = errorRes.statusText,
                        errorMsg = "CreateConfigurableLeasingContract fejlede. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
                    self.abGtm.triggerGtmEvent("fejl", "Beregnpris", '[' + self.abGtm.getUtcTimestamp() + '] ' + errorMsg);
                }

                if (error.response.status == 401) {
                    var url = window.location.href.substring(0, window.location.href.indexOf("#") + 1)
                    url = url + "/log-paa";
                    window.location.href = url;
                    self.abLightbox.standardLightText("Du er ikke længere logget ind.", "<p>Du er blevet logget ud, og bedes derfor logge ind igen.</p>");
                }

            });
    }

    async createConfigurableLeasingContractViaRest(serviceUrl: string) {
        let self = this;
        // if(true){
        //     let data = require("../../json/createContract.json");
        //     self.carIsLoaded = 1;
        //     if(data === 0) {
        //         self.carIsLoaded = 2;
        //     }
        //     let configContract = data.Data;

        //     self.configurableLeasingContractSetup(configContract);

        //         self.enrichRanges(configContract);

        //         self.enrichTireDeals(configContract);

        //         self.userData.insurances = configContract.AvailableInsuranceCompanies;

        //         self.buildInsurancesTxt();

        //         // sæt initial kontrakt i store med alle ovenstående manipulationer
        //         self.$store.dispatch('setCreatedContract', configContract);

        //         var _options = self.$store.getters.getCreatedContract.Contract.Vehicle.Options;
        //         //opdater UI med allerede tilvalgte udstyr/service
        //         for (var i = 0; i < _options.length; i++) {
        //             self.checkedAvailableOptions.push(_options[i].Id)
        //         }

        //         //releasing farve er forud defineret
        //         if (self.detailedCar.vehicle.used && !isNullOrUndefined(self.detailedCar.options.color_options)) {
        //             self.colorSelected = self.detailedCar.options.color_options[0];
        //         }



        //         //lav første beregning med ny oprettet kontrakt og preudfyldt udstyr/service
        //         self.calculateContractViaRest(self.calculateContractServiceUrl);
        // }else{

            let payload = JSON.stringify({

            });
            // this.abAxios.abPostWithToken(serviceUrl, payload, "application/json", false, function successCallback(response:any) {
            this.abAxios.abGetWithToken(serviceUrl, false, function successCallback(response: any) {
                self.carIsLoaded = 1;
                if(response.data.data === 0) {
                    self.carIsLoaded = 2;
                }
                let configContract = response.data.Data;

                /*TEST
                configContract.ValidAvailablePeriodsMileagesForholdsmaessigAfgift =
                    {
                        36: [15000, 20000, 25000, 30000],
                        48: [20000, 25000, 30000, 35000, 100000]
                    }*/

                self.configurableLeasingContractSetup(configContract);

                self.enrichRanges(configContract);

                self.enrichTireDeals(configContract);

                self.userData.insurances = configContract.AvailableInsuranceCompanies;

                self.buildInsurancesTxt();

                // sæt initial kontrakt i store med alle ovenstående manipulationer
                self.$store.dispatch('setCreatedContract', configContract);

                var _options = self.$store.getters.getCreatedContract.Contract.Vehicle.Options;
                //opdater UI med allerede tilvalgte udstyr/service
                for (var i = 0; i < _options.length; i++) {
                    self.checkedAvailableOptions.push(_options[i].Id)
                }

                //releasing farve er forud defineret
                if (self.detailedCar.vehicle.used && !isNullOrUndefined(self.detailedCar.options.color_options)) {
                    self.colorSelected = self.detailedCar.options.color_options[0];
                }


                self.prepareCarData();

            }, function errorCallback(error: any) {
                self.carIsLoaded = 2;
                console.log(error);
                if (!isNullOrUndefined(error.response)) {
                    let errorRes = error.response,
                        statusCode = errorRes.status,
                        statusText = errorRes.statusText,
                        errorMsg = "CreateConfigurableLeasingContract fejlede. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
                    self.abGtm.triggerGtmEvent("fejl", "Beregnpris", '[' + self.abGtm.getUtcTimestamp() + '] ' + errorMsg);
                }
                //rediret til fejl side efter 3 forsøg
                if (error.response.status == 401) {
                    var url = window.location.href.substring(0, window.location.href.indexOf("#") + 1)
                    url = url + "/log-paa";
                    window.location.href = url;
                    self.abLightbox.standardLightText("Du er ikke længere logget ind.", "<p>Du er blevet logget ud, og bedes derfor logge ind igen.</p>");
                }
                // else {
                //     self.abLightbox.standardLightText("Service kald fejlede", "Noget gik galt.. </br></br>" + error + "</br></br> Ring til os på Tlf. <a class='popup__content__phone-link' href='tel: 70607010'> 70 60 70 10</a>");
                // }
            });
        }

    async calculateContractViaRest(serviceUrl: string) {
        let self = this;
        let payload = JSON.stringify(this.$store.getters.getCreatedContract.Contract);



        this.recalculating = true;

        console.log(this.$store.getters.getCreatedContract.Contract);
        // alert("calculateContractViaRest - "+serviceUrl);
        // if(true){
        //     let data = require("../../json/calculateContract.json")
        //     self.calculatedContract = data.Data;

        //         if (!data.Success || isNullOrUndefined(data.Data)) {
        //             self.abGtm.triggerGtmEvent("fejl", "calculateContract", '[' + self.abGtm.getUtcTimestamp() + '] ' + payload);
        //             self.abLightbox.standardLightText("Tilbud beregning fejlede", "Noget gik galt.. </br></br> Ring til os på Tlf. <a class='popup__content__phone-link' href='tel: 70607010'> 70 60 70 10</a> eller prøv igen.");
        //             return;
        //         }

        //         self.calculateBasketCompletePayment().then(payment =>{
        //             self.basketCompletePayment = payment;
        //             self.basketMonthlyPaymentWithoutExtra = self.calculateBasketMonthlyPaymentWithoutExtra();
        //         });

        //         //map priser over på lister vist i UI
        //         self.enrichOptions();
        //         //opdater dæk priser med nye priser
        //         self.updateTireDeals(self.calculatedContract.TireOptions);
        //         //synkronisere
        //         self.handleAvailableOptionsSelection();
        //         //vis indkøbskurv i bunden
        //         //self.hideBasket = false;
        //         //først kald lavet, åben op for yderligere beregninger

        //         self.calculateFlag = true;
        // }else{


            await this.abAxios.abPostWithToken(serviceUrl, payload, "application/json", self.calculateFlag, function successCallback(response:any) {
                self.calculatedContract = response.data.Data;

                if (!response.data.Success || isNullOrUndefined(response.data.Data)) {
                    self.abGtm.triggerGtmEvent("fejl", "calculateContract", '[' + self.abGtm.getUtcTimestamp() + '] ' + payload);
                    //self.abLightbox.standardLightText("Tilbud beregning fejlede", "Noget gik galt.. </br></br> Ring til os på Tlf. <a class='popup__content__phone-link' href='tel: 70607010'> 70 60 70 10</a> eller prøv igen.");
                    return;
                }

                self.calculateBasketCompletePayment().then(payment =>{
                    self.basketCompletePayment = payment;
                    self.basketMonthlyPaymentWithoutExtra = self.calculateBasketMonthlyPaymentWithoutExtra();
                });

                // self.opendoDynamicInsuranceAllowedIfSelected = self.calculatedContract.Leasing.DynamicInsuranceAllowedIfSelected == null ||
                //                                                     self.calculatedContract.Leasing.DynamicInsuranceAllowedIfSelected == true ? true : false;

                //map priser over på lister vist i UI
                self.enrichOptions();
                //opdater dæk priser med nye priser
                self.updateTireDeals(self.calculatedContract.TireOptions);
                //synkronisere
                self.handleAvailableOptionsSelection();
                //vis indkøbskurv i bunden
                //self.hideBasket = false;
                //først kald lavet, åben op for yderligere beregninger
                self.recalculateOptionsPrices();
                self.handleOpendoInsuranceValues();

                self.calculateFlag = true;

                //console.log(self.$store.getters.getCreatedContract.Contract.Period + " - " + self.$store.getters.getCreatedContract.Contract.Leasing.AnnualMileage);
            }, function errorCallback(error: any) {
                //console.log(error);
                if (!isNullOrUndefined(error.response)) {
                    let errorRes = error.response,
                        statusCode = errorRes.status,
                        statusText = errorRes.statusText,
                        errorMsg = "calculateContract fejlede. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
                    self.abGtm.triggerGtmEvent("fejl", "calculateContract", '[' + self.abGtm.getUtcTimestamp() + '] ' + errorMsg);

                    if (error.response.status == 401) {
                        var url = window.location.href.substring(0, window.location.href.indexOf("#") + 1)
                        url = url + "/log-paa";
                        window.location.href = url;
                        self.abLightbox.standardLightText("Du er ikke længere logget ind.", "<p>Du er blevet logget ud, og bedes derfor logge ind igen.</p>");
                    } else {
                        self.abLightbox.standardLightText("Tilbud beregning fejlede", "Noget gik galt.. </br></br>" + error + "</br></br> Ring til os på Tlf. <a class='popup__content__phone-link' href='tel: 70607010'> 70 60 70 10</a>");

                    }
                }
            });
        }


    async calculateBasketCompletePayment() {
        let totalMonthlyPayment: number = 0;
        let ratePayment: number = 0;
        let firstTimePayment: number = 0;
        let period: number = 0;
        let deliveryFee: number = 0;

        firstTimePayment = this.calculatedContract.Leasing.PrePayment;
        period = this.$store.getters.getCreatedContract.Contract.Period;

        if (!this.isCorporate) {
            totalMonthlyPayment = this.calculatedContract.Leasing.TotalMonthlyPaymentInclVat;
            ratePayment = this.calculatedContract.Leasing.MonthlyPaymentAdministrationFee.IncVat;
            deliveryFee = this.calculatedContract.Leasing.VehicleReturnFee.IncVat;
        } else if (this.isCorporate) {
            totalMonthlyPayment = this.calculatedContract.Leasing.TotalMonthlyPayment;
            ratePayment = this.calculatedContract.Leasing.MonthlyPaymentAdministrationFee.ExVat;
        }

        //round amount
        totalMonthlyPayment = Math.round(totalMonthlyPayment);


        // alert("prep2");

        this.firstTimePrice = Math.round(firstTimePayment);
        this.monthlyPrice = totalMonthlyPayment;
        this.taxBasePrice = Math.round(this.calculatedContract.Leasing.TaxValueInclVatMonthly);

        return await ((totalMonthlyPayment + ratePayment) * period) + firstTimePayment + deliveryFee;
    }

    calculateBasketMonthlyPaymentWithoutExtra() {
        let totalMonthlyPaymentVatPrice = 0;
        if (!this.isCorporate) {
            totalMonthlyPaymentVatPrice = this.calculatedContract.Leasing.TotalMonthlyPaymentVatPrice.IncVat;
        } else {
            totalMonthlyPaymentVatPrice = this.calculatedContract.Leasing.TotalMonthlyPaymentVatPrice.ExVat;
        }
        let monthlyPaymentWithoutExtra = totalMonthlyPaymentVatPrice - this.basketEquipmentPriceTotal;
        for (let item of this.basketSelectedItems) {
            monthlyPaymentWithoutExtra = monthlyPaymentWithoutExtra - item.MonthlyPrice;
        }
        return monthlyPaymentWithoutExtra;
    }

    getVehicleViaRest(serviceUrl: string, vehicleId: number) {


        let self = this;

        let finalUrl = serviceUrl + "/" + vehicleId;

            this.abAxios.abGetWithToken(finalUrl, false, function successCallback(response: any) {
                self.handleGetVehicleResponse(response.data);
            }, function errorCallback(error: any) {
                console.log(error);

                let errorRes = error.response,
                    statusCode = errorRes.status,
                    statusText = errorRes.statusText,
                    errorMsg = "Bilen kunne ikke hentes. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
                    self.abGtm.triggerGtmEvent("fejl", "getVehicles", '[' + self.abGtm.getUtcTimestamp() + '] ' + errorMsg);

                    if (error.response.status){
                        self.carIsLoaded = 3;
                    }

                    if (error.response.status == 401) {


                        var url = window.location.href.substring(0, window.location.href.indexOf("#") + 1)
                        url = url + "/log-paa";
                        window.location.href = url;
                        self.abLightbox.standardLightText("Du er ikke længere logget ind.", "<p>Du er blevet logget ud, og bedes derfor logge ind igen.</p>");
                    }
            });
        }

    handleGetVehicleResponse(response: any) {
        this.detailedCar = response;

        // console.log(this.detailedCar);
        // this.detailedCar.vehicle.images = Array(1).fill('https://api-test.opendo.dk/leasing/images/32296')

        if (this.isCorporate) {
            this.createContractServiceUrl = this.createContractServiceUrl +
                "?vehicleId=" + this.detailedCar.vehicle.id +
                "&customerType=Corporate"
        } else {
            this.createContractServiceUrl = this.createContractServiceUrl +
                "?vehicleId=" + this.detailedCar.vehicle.id +
                "&customerType=Consumer"
        }
        // alert("handleGetVehicleResponse & createConfigurableContract");
        this.createConfigurableLeasingContractViaRest(this.createContractServiceUrl);
    }
    /*REST SERVICE END*/

    formatPrice(value: any): string {
        if (value === undefined) {
            return "0";
        }

        // return value.toFixed(2);
        var cents = Math.round(value%1*100);
        var sCents = "";
        if (cents>0 && cents<10) { sCents = ",0"+cents; }
        if (cents>=10) { sCents = ","+cents; }

        return this.abAmount.format(value)+"";
    }

    configurableLeasingContractSetup(configContract: any) {
        //bytelab hack
        configContract.Contract.ContractType = "Leasing";
        if (!this.isCorporate) {
            configContract.Contract.Buyer.CustomerType = 0;
            configContract.Contract.CoBuyer.CustomerType = 0;
        } else if (this.isCorporate) {
            configContract.Contract.Buyer.CustomerType = 1;
            configContract.Contract.CoBuyer.CustomerType = 1;
        }
    }

    enrichTireDeals(configContract: any) {
        //tøm
        this.tireDealOptions = [];
        //berig dæk aftale struktur
        let tiredeals = configContract.AvailableTireDeals;
        tiredeals.forEach((deal: any) => {
            //wrap data over så det har samme format som andre udstyr
            let _deal = { Id: deal.TireDealTypes, Name: deal.Name, MonthlyPrice: deal.Price }
            this.tireDealOptions.push(_deal);
        });
    }

    updateTireDeals(calculatedContractTireOptions: any) {
        for (let calculatedOption of calculatedContractTireOptions) {
            for (let existOption of this.tireDealOptions) {
                if (existOption.Id === calculatedOption.TireDealType) {
                    existOption.MonthlyPrice = calculatedOption.Price;
                }
            }
        }
    }

    buildInsurancesTxt() {
        this.insuranceSearchTxt =
        this.detailedCar.vehicle.make.name +" "+
        this.detailedCar.vehicle.model.name + " "+
        this.detailedCar.vehicle.model_year + " "+
        this.detailedCar.vehicle.fuel_type + " "+
        this.detailedCar.vehicle.doors + "-døre " +
        //this.detailedCar.engine_size + " " +
        this.detailedCar.vehicle.horse_power + "HK " +
        this.detailedCar.vehicle.variant
    }

    enrichRanges(configContract: any) {
        //dynamisk opbyg antal måneder range med keys => opsætning fra CC pris matrix
        this.findAvailablePeriodsFromKeys(configContract);

        let periodIndex = 0;
        let _periodData: string[] = [];
        configContract.AvailablePeriods.forEach((val: number, index: number) => {
            //berig data valgmuligheder fra kontrakt
            _periodData.push(val + this.userData.epiData.step3.leasingMonthText);
            if (val == configContract.Contract.Period) {
                periodIndex = index;
            }
        });

        //dynamisk opbyg antal km. range med data => opsætning fra CC pris matrix
        this.findAvailablePeriodsMileages(configContract);

        let mileageIndex = 0;
        let _mileageData: string[] = [];
        configContract.AvailableMileages.forEach((val: number, index: number) => {
            //berig data valgmuligheder fra kontrakt
            _mileageData.push(this.abAmount.format(val) + " km");
            if (val == configContract.Contract.Leasing.AnnualMileage) {
                mileageIndex = index;
            }
        });

        this.sliders = {
            period: periodIndex,
            mileage: mileageIndex
        };
        this.periodData = _periodData;
        this.mileageData = _mileageData;
    }

    findAvailablePeriodsFromKeys(configContract) {
        let periodData = {};
        if (configContract.Contract.Leasing.L64) {
            periodData = configContract.ValidAvailablePeriodsMileagesForholdsmaessigAfgift;
        } else{
            periodData = configContract.ValidAvailablePeriodsMileages;
        }
        //find alle keys
        configContract.AvailablePeriods =  Object.keys(periodData);
    }

    findAvailablePeriodsMileages(configContract:any) {
        if (configContract.Contract.Leasing.L64) {
            configContract.AvailableMileages = configContract.ValidAvailablePeriodsMileagesForholdsmaessigAfgift[configContract.Contract.Period];
        } else {
            configContract.AvailableMileages = configContract.ValidAvailablePeriodsMileages[configContract.Contract.Period];
        }
    }

    //berig beregninger fra calculatedContract over i detailedCar
    enrichOptions() {
        let _colorOption = this.detailedCar.options.colour_options;
        let _preFittedOption = this.detailedCar.options.pre_fitted_options;
        let _retroFittedOption = this.detailedCar.options.retro_fitted_options;
        let _serviceOptions = this.detailedCar.options.service_options;

        let _availableOptions = this.calculatedContract.AvailableOptions;
        _availableOptions.forEach((_option: any) => {
            if (_colorOption != null) {
                for (var i = 0; i < _colorOption.length; i++) {
                    if (_colorOption[i].id === _option.Id) {
                        _colorOption[i].monthly_price = _option.MonthlyPrice;
                        break;
                    }
                }
            }
            if (_preFittedOption != null) {
                for (var i = 0; i < _preFittedOption.length; i++) {
                    if (_preFittedOption[i].id === _option.Id) {
                        _preFittedOption[i].monthly_price = _option.MonthlyPrice;
                        break;
                    }
                }
            }
            if (_retroFittedOption != null) {
                for (var i = 0; i < _retroFittedOption.length; i++) {
                    if (_retroFittedOption[i].id === _option.Id) {
                        _retroFittedOption[i].monthly_price = _option.MonthlyPrice;
                        break;
                    }
                }
            }
            if (_serviceOptions != null) {
                for (var i = 0; i < _serviceOptions.length; i++) {
                    if (_serviceOptions[i].id === _option.Id) {
                        //Vejhjælp skal sættes til 0 kr og beløb vises
                        /*
                        if (_serviceOptions[i].Name === 'Vejhjælp') {
                            _serviceOptions[i].monthly_price = 0;
                            _option.MonthlyPrice = 0;
                            _serviceOptions[i].HidePrice = true;
                        } else {
                            */
                            _serviceOptions[i].monthly_price = _option.MonthlyPrice;
                        //}
                        break;
                    }
                }
            }
        });
    }
    scrollStart() {
        let scrollStart = document.getElementById("scrollStart");
        if (!isNullOrUndefined(scrollStart)) {
            VueScrollTo.scrollTo(scrollStart, 1000);
        }
    }

    disableInspectionRepport() {
        return this.userData?.epiData?.step3?.disableInspectionRepport ?? false;
    }

    resetCachedData(){
        // this.$store.getters.setCreatedContract(null);
        this.carIsLoaded = 0;
        this.colorTitle = "Vælg farve";
        this.$store.dispatch('setCreatedContract', null);
    }

    created() {

        if(this.$route.params.vehicleId == "login"){
            this.$router.push({"name": "ForhandlerLogin"});
            return;
        }
        if(this.$route.params.vehicleId == "logout"){
            this.$router.push({"name": "logins"});
            return;
        }

        let _vehicleId = parseInt(this.$route.params.vehicleId);

        if (_vehicleId !== undefined) {
            this.resetCachedData();

            if (this.isCorporate) {
                this.getVehicleViaRest(this.getCorporateVehicleServiceUrl, _vehicleId);
            } else {
                this.getVehicleViaRest(this.getConsumerVehicleServiceUrl, _vehicleId);
            }

            //reset error labels
            this.userData.colorErrLabel = "";
            this.userData.insurancesErrLabel = "";
        }
    }


    beforeDestroy(){
        window.removeEventListener("resize", this.onResize);
    }

    mounted() {
        var th = this;
        this.loadLikes();
        // window.scrollTo(0,0);
        this.userData.removeMiniFormFunc(this.userData.epiData.hideElementId);

        eventBus.$on("LeasingSubmitSuccess", this.onLeasingSubmitSuccess);
        eventBus.$on("LeasingSubmitFailed", this.onLeasingSubmitFailed);

        eventBus.$on("bannerHeight", this.onBannerHeight);
        th.cmsGlobalData = th.$store.getters.getCMSGlobalData;
        // th.initPreFilterGroups();
        eventBus.$on("cmsGlobalDataSet", function(e){
            th.cmsGlobalData = th.$store.getters.getCMSGlobalData;
            // th.initPreFilterGroups();
        });

        window.addEventListener("resize", this.onResize);
    }

    onBannerHeight(){
        this.$nextTick(function(){
            this.onResize();
        });
    }

    onResize(){
        var bottomBar = this.$refs.carStepsBar;
        var headerSpacer = document.querySelector(".header-spacer") as HTMLElement;

        var forhandlerBanner = document.querySelector(".forhandler-banner") as HTMLElement;
        // alert(headerSpacer.offsetHeight);
        if (bottomBar && headerSpacer){
            this.scrollContainerTopPadding = headerSpacer.offsetHeight + bottomBar.offsetHeight;

            if(forhandlerBanner){
                this.scrollContainerTopPadding = this.scrollContainerTopPadding + forhandlerBanner.offsetHeight+12;
            }
        }
    }

}
