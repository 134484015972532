import { Component, Vue } from 'vue-property-decorator';

import template from './NewsSlider.vue';

@Component({
  mixins: [template],
  props: ['articles', 'propNewsSlug', 'styleVersion', 'direction', 'whiteSideGradient', 'noSideGradient'],

})
export default class NewsSlider extends Vue {

}