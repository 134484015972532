<template>
        <span class="ab-tooltip" :class="{'item-selected': toolTip}">
            <img :src="require('@/components/leasing/Showroom/public/ui/assets/svg/systemicon_lightblue_Question.svg')" style="height: 20px" role="button"
                 @click="toolTip?toolTip=false:toolTip=true" />
            <span class="ab-tooltiptext" style="width: 310px">
                <img :src="require('@/components/leasing/Showroom/public/ui/assets/svg/systemicon_darkblue_Cancel.svg')" style="height: 20px" role="button"
                     @click="toolTip?toolTip=false:toolTip=true" class="float-right" />
                <b v-html="header"></b>
                <p v-html="body"></p>
            </span>
        </span>
</template>

<script lang="ts">

       /*  SETUP
        //I typsescript
        import Tooltipcomponent from '../../../../shared/tooltip/Tooltip.vue';
        @Component({
        name: 'temp name',
        components: {
            'tooltip-component': Tooltipcomponent
        }
        })

        //I html
        <span class="secondary-font__large__bold">Totalomkostninger</span>
        <tooltip-component
            :header="'Totalomkostninger'"
            :body="'Summen udregnes sådan: Md. ydelse og administrationshonorar ganget med leasingperioden (antal md.), plus førstegangsydelse og tilbageleveringsgebyr.'">
        </tooltip-component>

        */


    import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from "vue-property-decorator";

    @Component({
        name: 'tooltip-component',
        components: {

        }
    })

    export default class TooltipComponent extends Vue {
        @Prop() header?: string;
        @Prop() body?: string;

        toolTip: boolean = false;

        mounted() {
            let self = this;
        }
    }
    
</script>

<style lang="scss" scoped>
    .float-right {
        float: right !important;
    }
</style>
