import { Vue, Component } from "vue-property-decorator";
import template from "./Newbanking.vue"
import FAQList from "../../components/ui/faq-list/faq-list"




@Component({
    mixins:[template],
    props: ['cmsGlobalData', 'cmsPageData'],
    components: {
        'faq-list' : FAQList,
    }
})
export default class Newbanking extends Vue {

}
