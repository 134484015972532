<template>
    <div v-if="modelLoaded">
        <component :is="getComponentTypeForPage(model)" :model="model"></component>
    </div>
</template>

<script lang="ts" >
    import { Vue, Component } from "vue-property-decorator";
    import { mapState } from "vuex";
    import ConfigModel from "@/components/leasingpro/store/configModel";
    import getComponentTypeForContent from '@/components/leasingpro/episerver/api/contentComponentSelector';

    @Component({
        name: 'PageComponentSelector',
        computed: mapState<ConfigModel>({
            model: state => state.epiContent.model,
            modelLoaded: state => state.epiContent.modelLoaded
        })
    })

    export default class PageComponentSelector extends Vue {
        getComponentTypeForPage(model) {
            // this.$options.components will contain all globally registered components from main.js
            return getComponentTypeForContent(model, this.$options.components);
        }  
    }
</script>