<template>
  <div class="gallery-section noselect">
    <div class="single-image" v-if="images.length<=1">
      <div class="big-image">
        <div v-for="(image, index) in images" :key="'car-gallery-big-image-'+index">
          <img :src="image" alt="image" />
        </div>
      </div>
    </div>
    <div class="gallery" v-else>
      <div class="big-image">

        <swiper
            ref="carGallerySlider"
            :slides-per-view="slidesPerView"
            :space-between="slidesSpacing"
            :loop="false"
            slideVisibleClass = "visible-slide"
            :watchSlidesVisibility = "true"

            :navigation="{
              prevEl: '.prev',
              nextEl: '.next',
            }"
            :pagination="{
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true
            }"

            @swiper="onSwiper"
            @slideChange="onSlideChange"
        >
          <swiper-slide
              v-for="(image, index) in images" :key="'car-gallery-big-image-'+index"
              class="slide"
              :class="{test_2: false}"
          >
            <div class="image">
              <img :src="image" alt="image" />
            </div>

          </swiper-slide>

          <div class="prev" >

          </div>
          <div class="next" >

          </div>
          <div class="swiper-pagination"></div>

          <div class="mask mask-right"></div>
          <div class="mask mask-left"></div>
        </swiper>



      </div>
      <div class="thumbnails-wrap flex my-flex-row flex-s4">
          <span class="prev-button" v-on:click="prevClick($event)">
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24.3994 28.5L16.8244 22.374C16.7715 22.3271 16.7292 22.2695 16.7002 22.205C16.6712 22.1406 16.6563 22.0707 16.6563 22C16.6563 21.9293 16.6712 21.8594 16.7002 21.795C16.7292 21.7305 16.7715 21.6729 16.8244 21.626L24.3994 15.5" stroke="#69BDB6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
        <div class="thumbnails" ref="thumbnails">
          <div class="inner" ref="thumbnailsInner" :style="
              'transform: translateX('+thumbnailSlidePX+'px); '+
              '-webkit-transform: translateX('+thumbnailSlidePX+'px); '
            " >
            <div :class="'thumbnail' + (currentPage == index ? ' active' : '')" v-for="(image, index) in images" :key="'car-gallery-image-'+index" v-on:click="thumbnailClick(index, $event)">
              <div class="img">
                <img :src="image" alt="image" />
              </div>
            </div>
          </div>
        </div>
        <span class="next-button" v-on:click="nextClick($event)">
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.6006 15.5L27.1756 21.626C27.2285 21.6729 27.2708 21.7305 27.2998 21.795C27.3288 21.8594 27.3438 21.9293 27.3438 22C27.3438 22.0707 27.3288 22.1406 27.2998 22.205C27.2708 22.2695 27.2285 22.3271 27.1756 22.374L19.6006 28.5" stroke="#69BDB6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
      </div>
    </div>
  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';
import { Navigation, Pagination, Scrollbar } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

// Import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination, Scrollbar])

export default {
  props: ['images'],
  components: {
    Swiper,
    SwiperSlide,
    Scrollbar
  },
  setup() {
    return {
      modules: [Pagination, Scrollbar]
    }
  },
  data() {
    return {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      slidesPerView: 1,
      slidesSpacing: 0,
      currentPage: 0,
      thumbnailSlidePX: 0,
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  mounted(){

    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
    var th = this;

    var timeout = setTimeout(function(){
      th.$nextTick(() => {
        th.updateGallertSlider();
      });
      clearTimeout(timeout);
    },1000);

  },
  methods: {
    updateGallertSlider(){
      if (this.$refs.carGallerySlider){
        this.$refs.carGallerySlider.$el.swiper.update();
      }
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;


      this.repositionThumbnails(this.currentPage, this.currentPage-1);
      // this.slidesPerView = 3;
      // if(this.width< 1000){
      //   this.slidesPerView = 2;
      // }
      // if(this.width< 700){
      //   this.slidesPerView = 1.2;
      // }
      // if ()
    },
    thumbnailClick(index, e){
      this.currentPage = index;
    },
    prevClick(e){
      if (this.currentPage == 0){
        this.currentPage = this.images.length-1;
      }else{
        this.currentPage--;
      }
    },
    nextClick(e){
      if (this.currentPage+1 == this.images.length){
        this.currentPage = 0;
      }else{
        this.currentPage++;
      }
    },
    onSwiper (swiper) {
      // console.log(swiper)
    },
    onSlideChange () {
      // console.log('slide change')

      this.currentPage = this.$refs.carGallerySlider.$el.swiper.realIndex;
    },
    onNewsThumbLoad (){
      // this.thumbnailsLoaded++;
      // if (this.logos){
      //   if (this.thumbnailsLoaded >= this.logos.length){
      var th = this;

      th.updateGallertSlider();
      // th.$refs.partnersSlider.$el.swiper.update();

      //   }
      // }
    },
    repositionThumbnails (toParams, previousParams){

      var th = this;

      if (th.$refs.carGallerySlider){
        th.$refs.carGallerySlider.$el.swiper.slideTo(toParams);
      }

      if (th.$refs.thumbnailsInner && th.$refs.thumbnails){

        var wi = th.$refs.thumbnailsInner.scrollWidth;
        var wt = th.$refs.thumbnails.offsetWidth;

        var thumbWidth = th.$refs.thumbnails.querySelector(".thumbnail").offsetWidth;

        var slidepx = toParams*thumbWidth;

        if (previousParams<toParams){
          //Next
          slidepx-=2*thumbWidth;
        }else{
          //Prev
          slidepx-=thumbWidth;
        }

        if (slidepx > wi-wt){
          slidepx = wi-wt;
        }
        if (slidepx < 0){
          slidepx = 0;
        }
        th.thumbnailSlidePX = -slidepx;
      }
    }
  },
  created() {
    var th = this;
    this.$watch(
        () => this.images,
        (toParams, previousParams) => {
          th.$nextTick(() => {
            th.updateGallertSlider();
          });
        }
    );

    this.$watch(
        () => this.currentPage,
        (toParams, previousParams) => {

          this.repositionThumbnails(toParams, previousParams);

        }
    );

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.gallery-section{
  margin-bottom: 32px;
  img{
    width: 100%;
  }
  .big-image{
    border-radius: 20px;
    overflow: hidden;

    .swiper-container{
      border-radius: 20px;
      overflow: hidden;
    }
  }
  .thumbnails-wrap{
    text-align: center;
    margin-top: 40px;
    white-space: nowrap;
    margin-bottom: 80px;
    .inner{
      transition: all 300ms;
      -webkit-transition: all 300ms;
    }
    .thumbnails{
      display: inline-block;
      vertical-align: top;
      margin-right: 20px;
      margin-left: 20px;

      white-space: nowrap;
      overflow: hidden;
      max-width: 400px;

      width: calc(100% - 128px);
    }
    .thumbnail{
      width: 92px;
      width: 25%;

      box-sizing: border-box;
      line-height: 0px;
      display: inline-block;
      vertical-align: top;
      padding-left: 4px;
      padding-right: 4px;
      border-radius: 10px;
      opacity: 0.5;
      cursor: pointer;

      transition: all 300ms;
      -webkit-transition: all 300ms;

      .img{
        position: relative;
        width: 100%;
        height: 0px;
        padding-top: 100%;
      }
      &.active{
        opacity: 1;
      }
      img{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }


    .prev-button,
    .next-button{
      cursor: pointer;
      width: 44px;
      height: 44px;
      display: inline-block;
      vertical-align: top;
      border-radius: 24px;
      border: 2px solid #69BDB6;
      box-sizing: border-box;
      // margin-top: 24px;
      svg{
        width: 100%;
        height: 100%;
        // margin-top: -2px;
        // margin-left: -2px;
      }
      circle,path{
        transition: all 300ms;
        -webkit-transition: all 300ms;
        stroke: #69BDB6;
      }
      &:hover{

        circle{
          opacity: 1;
          stroke: #1F4743;
        }
        path{
          opacity: 1;
          stroke: #1F4743;
        }
      }
    }



    .prev-button{

    }
    .next-button{

    }
  }
  .swiper-pagination{
    display: none;
  }

}



@media (max-width: #{$screen-mobile}) {




  .gallery-section{
    margin-bottom: 20px;
    .thumbnails-wrap{
      margin-top: 24px;
      margin-bottom: 44px;
      .thumbnails{
        width: calc(100% - 104px);
        .thumbnail{
          padding-left: 2px;
          padding-right: 2px;
        }
      }
      .prev-button,
      .next-button{
        border-width: 1.5px;
        width: 32px;
        height: 32px;
      }
    }


  }
}




</style>