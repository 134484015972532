//når ny almbrand forsikring bliver valgt opbygges følgende
//objekt som bliver sendt til en intern mail box
export default class InsurancesData {
    //beregnet forsikrings pris
    insurancePrice: number = 0;
    //beregnet selvrisiko
    excessAmount: number = 0;
    almbrandInsurancesCheckBox: boolean = false;
    mileage: number = 0;
    damageFreeDriving: string = "";
    foundTiaCarName: string = "";
    foundTiaVariantId: number = 0;

    reset() {
        this.insurancePrice = 0;
        this.excessAmount = 0;
        this.damageFreeDriving = "";
        this.almbrandInsurancesCheckBox = false;
        this.mileage = 0;
    }
}
