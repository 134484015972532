<template>
  <div class="ask-popup" v-if="isOpen" v-on:click="clickOnPopup">
    <div class="bg"></div>
    <div class="popup-content">
      <div class="flex flex-column flex-s9">
        <div class="wrap">
          <div class="popup-inner">
            <privat-erhverv-select-block :title="interestedBlock.title" :buttons="interestedBlock.buttons" :childURL="childURL" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../eventBus';

export default {
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      thisSlug: "",
      childURL: null,
      isOpen: false,
      isCarsPopup: false,
      isFAQPopup: false,
      buttons: null,
      interestedBlock: null,
      
    }
  },
  beforeMount(){
    // alert(footerData.fields.copyright);
    // this.copyright = "b";
  },
  created(){
    var th = this;
    eventBus.$on('open-ask-popup', (e) => {
      var deeplink = "";
      if (e.deeplink){
        deeplink = "#"+e.deeplink;
      }
      if (e.type == "cars"){
        th.isOpen = true;
        th.childURL = "/showroom"+deeplink;
        th.isCarsPopup = true;
        th.isFAQPopup = false;
        th.isLikedPopup = false;
      }
      if (e.type == "faq"){
        th.isOpen = true;
        th.childURL = "/faq"+deeplink;
        th.isCarsPopup = false;
        th.isFAQPopup = true;
        th.isLikedPopup = false;
      }
      if (e.type == "liked"){
        th.isOpen = true;
        th.childURL = "/liked"+deeplink;
        th.isCarsPopup = false;
        th.isFAQPopup = false;
        th.isLikedPopup = true;
      }
    })


    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        
        this.buttons = this.cmsGlobalData.header.switch;

        this.interestedBlock = this.cmsGlobalData.privat_erhverv_select;
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {

        // this.buttons = this.cmsGlobalData.header.switch;
        // this.thisSlug = this.cmsPageData.page.url;
        
      }
    );

    this.$watch(
      () => this.$route.name,
      (toParams, previousParams) => {
        if (th.isOpen){
          th.closePopup();
        }
      }
    )

    this.$watch(
      () => this.isOpen,
      (toParams, previousParams) => {

        if (th.isOpen){
          
          this.$nextTick(() => {
            gsap.to(".ask-popup", { duration: 0, opacity: 0});
            gsap.to(".ask-popup", { duration: 0.3, opacity: 1});
            document.querySelector(".ask-popup").addEventListener('click', function (event) {

              if (!event.target.matches('.close-popup')) return;
              th.closePopup();

            });
          })
          
        }
        
      }
    );
  },
  mounted(){
    
  },
  methods: {
    clickOnPopup: function(e){
      var th = this;
      if (e.target.classList.contains("popup-inner") || e.target.closest(".popup-inner")){

      }else{
        th.closePopup();
      }
    },
    closePopup: function(){
      var th = this;
      gsap.to(".ask-popup", { duration: 0.3, opacity: 0, onComplete: function(){
        th.isOpen = false;
        th.isCarsPopup = false;
        th.isFAQPopup = false;
        th.isLikedPopup = false;
      }});
    }
  },
 
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.ask-popup{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;

  .bg{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.8);
    z-index: 1;
  }
  .popup-content{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    z-index: 10;

    .flex{
      height: 100%
    }
  }

  .popup-inner{
    background: #FFFFFF;
    box-shadow: 0px 32px 64px -24px rgba(31, 72, 68, 0.16);
    border-radius: 40px;
  }
}

</style>