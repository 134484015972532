<template>
  <div :class="'pin-field '+(customClass ? customClass+' ': '')">
    <input :class="'input-p-'+i" v-for="i in size" :key="'pin-input-'+i" :data-i="i" :type="'text'" :name="name+'-p-'+i" :value="''" v-on:keyup="keyUp" v-on:keydown="keyDown"  v-on:change="changeVal" >
    <input type="hidden" ref="fullPin" :value="inputValue" :name="name" />
  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';


export default {
  props: ['size', 'name', 'value', 'customClass'],
  data() {
    return {
      inputValue: ''
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  mounted(){
    // this.inputValue = this.value;
    this.init();
  },
  created(){
    this.$watch(
      () => this.value,
      (toParams, previousParams) => {
        this.init();
      }
    );
  },
  methods: {
    init: function(){
      if (this.value){
        var pin = this.value+"";
        for(var i=0; i<pin.length; i++){
          this.$el.querySelector(".input-p-"+(i+1)).value = pin[i];
        }
      }
    },
    keyUp: function(e){
      var val = e.target.value;
      if (val){
        val = parseInt(val);
        if (isNaN(val)){
          e.preventDefault();
          return;
          // e.target.value = 0;  
        }else{
          e.target.value = val;
        }
        if (val>9){
          e.target.value = val % 10;
        }
        
        // alert("a");
        this.setFullPin();

        // this.tabToNext(e);
      }
    },
    keyDown: function(e){
      
      if(!["0","1","2","3","4","5","6","7","8","9","Backspace","Tab"].includes(e.key)){
        e.preventDefault();
      }else{
        if(["0","1","2","3","4","5","6","7","8","9"].includes(e.key)){
          e.preventDefault();
          e.target.value = e.key;
          this.tabToNext(e);
        }
      }
    },
    changeVal: function(e){
      this.keyUp(e);
    },

    tabToNext: function(e){
      if (parseInt(e.target.value)>=0){
        var n = e.target.getAttribute("data-i");
        n = parseInt(n);
        if (n<this.size){
          this.$el.querySelector(".input-p-"+(n+1)).focus();
        }
      }
    },

    setFullPin: function(){
      var  val = "";
      
      for (var i=1; i<=this.size; i++){
        val+=""+this.$el.querySelector(".input-p-"+i).value;
      }
      this.$refs.fullPin.value = val;

      this.$emit("changed", val);
    }
  },
 
}
</script>
<style lang="scss">
@import "~@/styles/variables.scss";
.pin-field{
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;

  input{
    background: transparent;
    border-radius: 6px;
    box-sizing: border-box;
    border: 1px solid #C7E5E4;
    padding: 12px 12px 14px;
    padding-left: 0px;
    padding-right: 0px;
    height: 48px;
    width: 40px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #1F4743;

    margin-left: 8px;
    &:first-child{
      margin-left: 0px;
    }
  }

}
</style>