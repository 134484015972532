<template>
  <div class="bottom-info-fixed" v-if="isOpen">
    <div class="bottom-info" ref="bottomInfo">
      <p v-html="text"></p>
      <a class="close-button transition-all" href="#" v-on:click="closeBottomInfo">OK</a>
    </div>
  </div>
</template>

<script>

import {gsap, Quad, Back} from 'gsap';
import eventBus from '../../../eventBus';

export default {
  
  props: ['open', 'text'],
  data() {
    return {
      isOpen: false
    }
  },
  beforeMount(){
    
  },
  mounted(){
    this.$watch(
      () => this.open,
      (toParams, previousParams) => {
        this.isOpen = this.open;
      }
    );

    this.$watch(
      () => this.isOpen,
      (toParams, previousParams) => {



        if (toParams == true){
          
          gsap.to(this.$refs.bottomInfo, { duration: 0, y: this.$refs.bottomInfo.offsetHeight+32, opacity: 0});
          if (this.isInViewport(document.querySelector('#footer'))){
            this.$refs.bottomInfo.classList.add('to-light');
          }
          this.$nextTick(function(){
            gsap.to(this.$refs.bottomInfo, { ease: Back.easeOut, duration: 0.4, y: 0, opacity: 1, onComplete: function(){}});
          })

        }else{
          this.$emit("onClose",'');
        }
      }
    );
  },
  created(){
    this.$nextTick(function(){
      this.handleWhiteBar();
    });
  },
  methods: {
    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      console.log(rect);
      return (
          window.innerHeight-rect.top-32 >= 0 &&
          rect.left >= 0 //&&
          // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          // rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    handleWhiteBar(){
      var th = this;
      const observer = new IntersectionObserver(entries => {
        // Loop over the entries
        entries.forEach(entry => {
          // If the element is visible
          
          if (entry.isIntersecting && th.$refs.bottomInfo) {
            // Add the animation class
            th.$refs.bottomInfo.classList.add('to-light');
          }
          if (!entry.isIntersecting && th.$refs.bottomInfo) {
            // Add the animation class
            th.$refs.bottomInfo.classList.remove('to-light');
          }
        });
      }, {rootMargin: '-32px'});
      const viewbox = document.querySelectorAll('#footer');
      viewbox.forEach(image => {
        observer.observe(image);
      });
    },
    closeBottomInfo: function(e){
      e.preventDefault();
      var th = this;
      gsap.to(this.$refs.bottomInfo, { ease: Quad.easeIn, duration: 0.4, y: this.$refs.bottomInfo.offsetHeight+32, opacity: 1, onComplete: function(){
        th.isOpen = false;
      }});
    }
  },
 
}
</script>
<style lang="scss">
@import "~@/styles/variables.scss";
.bottom-info-fixed{
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 1000;
  padding-right: 16px;
  padding-left: 16px;
  
}
.bottom-info{
  background: #1F4743;
  border-radius: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 133%;
  color: #FFFFFF;
  padding: 48px;
  padding-top: 18px;
  padding-bottom: 22px;
  padding-right: 96px;
  box-sizing: border-box;
  max-width: 1296px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  position: relative;

  transition: color 300ms, background 300ms;
  -webkit-transition: color 300ms, background 300ms;

  .close-button{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 133%;
    color: #C7E5E4;
    position: absolute;
    top: 50%;
    right: 48px;
    margin-top: -12px;
  }

  &.to-light{
    background: #E1F2F0;
    color: #1F4743;
    .close-button{
      color: #1F4743;
    }
  }
}
</style>