import { Component, Vue } from "vue-property-decorator";

import FAQList from "../../components/ui/faq-list/faq-list"
import LongSlider from "../../components/ui/long-slider/long-slider"
import template from "./PrivatFAQ.vue"

@Component({
    mixins:[template],
    props: ['cmsGlobalData', 'cmsPageData'],
    components: {
        'faq-list' : FAQList,
        'long-slider' : LongSlider
    }
})
export default class PrivatFAQ extends Vue {


}
