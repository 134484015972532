<template>
  <div class="single-post-page">
    <section class="top-hero fade-on-enter">
      <div class="wrap">
        <span class="date">{{date}}</span>
        <h1>{{title}}</h1>
        <div class="item-tags">
          <div class="tag" v-for="tag in tags" :key="tag.term_id" >
            {{tag.name}}
          </div>
        </div>
        <div v-if="thumbnail && !hideThumbnail" class="image">
          <img :src="thumbnail" :alt="thumbnailAlt" />
        </div>
      </div>
    </section>
    <div class="page-content fade-on-enter">

      <div class="wrap">
        <div class="textual-content" v-html="content"></div>
      </div>

      <span v-if="cmsPageData && !cmsPageData.loaded"></span>
    </div>

    <template v-if="addConversionBlock">
      <articleConversionBlock :block="cmsGlobalData && cmsGlobalData.article_conversion ? cmsGlobalData.article_conversion : null"/>
    </template>

    <div class="related-green-section" v-if="relatedArticles && relatedArticles.length>0">
      <news-section :articles="relatedArticles" :button="'Alle nyheder'" :title="'Relaterede artikler'"  />
    </div>


  </div>
</template>

<script>
export default {
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      thisSlug: "",
      date: null,
      title: null,
      content: null,
      tags: null,
      relatedArticles: null,
      thumbnail: null,
      thumbnailAlt: null,
      relatedArticles: null,
      hideThumbnail: false,
      addConversionBlock: false,
    }
  },
  beforeMount(){
    // alert(footerData.fields.copyright);
    // this.copyright = "b";
  },
  created(){
    
    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {
        
        this.prepareData();
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.prepareData();
  },
  mounted(){
    
  },
  methods: {
    prepareData: function(){
      if (this.cmsPageData){
        this.thisSlug = this.cmsPageData.page.url;
        this.title = this.cmsPageData.page.news_article.post_title;
        this.content = this.cmsPageData.page.news_article.content_html;
        this.date = this.cmsPageData.page.news_article.date2;
        this.tags = this.cmsPageData.page.news_article.tags;
        this.thumbnail = this.cmsPageData.page.news_article.thumbnail;
        this.relatedArticles = this.cmsPageData.page.news_article.related_articles;
        this.addConversionBlock = this.cmsPageData.page.news_article.addConversionBlock;
        
        if (this.thumbnail && this.cmsPageData.page.news_article.thumbnail_info){
          this.thumbnailAlt = this.cmsPageData.page.news_article.thumbnail_info.alt;
        }
        if (this.cmsPageData.page.news_article.hideFeatured === true){
          this.hideThumbnail = true;
        }
      }
    }
  },
 
}
</script>

<style lang="scss" scoped>
@import "~@/style.scss";

.top-hero{
  margin-top: 80px;
  text-align: center;
  margin-bottom: 60px;

  .date{
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #4C6C69;

    margin-bottom: 14px;
  }
  h1{
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 72px;
    text-align: center;
    color: #1F4743;
    max-width: 789px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 48px;
  }
  .item-tags{
    margin-bottom: 48px;
  }
  .image{
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    img{
      width: 100%;
      border-radius: 20px;
    }
  }
}
.page-content{
  padding:0px;
  margin-top: 60px;
  margin-bottom: 160px;
}

.related-green-section{
  padding-top: 96px;
  padding-bottom: 140px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  background: $color-super-light-green;
  border-radius: 40px;
}

@media (max-width: #{$screen-tablet}) {
  .related-green-section{
    padding-bottom: 60px;
    padding-top: 48px;
  }

  .top-hero{

    .date{
     margin-bottom: 14px;
    }
    h1{
     font-weight: 700;
      font-size: 48px;
      line-height: 120%;
    }
    .item-tags{
      
    }
    .image{
     
    }
  }
}

@media (max-width: #{$screen-mobile}) {
  .related-green-section{
    padding-bottom: 60px;
    padding-top: 48px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .top-hero{
    .date{
     margin-bottom: 12px;
    }
    h1{
     font-weight: 700;
      font-size: 32px;
      line-height: 130%;
    }
    .item-tags{
      
    }
    .image{
      margin-left: -20px;
      margin-right: -20px;
      width: calc(100% + 40px);
    }
  }
}

</style>
