import { Component, Vue } from 'vue-property-decorator';

import CarItem from '../car-item/CarItem';
import template from './CarList.vue';

@Component({
  mixins: [template],
  props: ['cmsGlobalData', 'cmsPageData', 'leasingType', 'carCount'],
  components: {
    'CarItem': CarItem
  }
})
export default class CarList extends Vue {

}