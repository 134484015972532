<template>
  <div class="main showroom-main">
    <ShowRoom v-bind:cmsPageData="cmsPageData" v-bind:cmsGlobalData="cmsGlobalData"/>
    <span v-if="cmsPageData && !cmsPageData.loaded"></span>
  </div>
</template>
<script>
export default {
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      thisSlug: "",
      heroBlock: { title: "Privat Cars", image: {url: null} },
    }
  },
  beforeMount(){
    // alert(footerData.fields.copyright);
    // this.copyright = "b";
  },
  created(){
    this.thisSlug = window.location.pathname;
    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {

        // this.thisSlug = this.cmsPageData.page.url;
        // this.heroBlock = this.cmsPageData.page.about.hero;
        // this.historyBlock = this.cmsPageData.page.about.history;
        // this.partnersBlock = this.cmsPageData.page.about.partners;
        // this.jobsBlock = this.cmsPageData.page.about.jobs;
        // this.patnersLogos = this.cmsPageData.page.about.partners_logos;

        // this.jobs = this.cmsPageData.page.about.jobs.jobs_list;

      }
    );
  },
  mounted(){

  },
  methods: {

  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

</style>
