import { Component, Vue } from "vue-property-decorator";

import CalculatorBlock from "../../components/ui/calculator-block/CalculatorBlock"
import FAQList from "../../components/ui/faq-list/faq-list"
import LongSlider from "../../components/ui/long-slider/long-slider"
import template from "./ErhvervFAQ.vue"

@Component({
    mixins:[template],
    props: ['cmsGlobalData', 'cmsPageData'],
    components: {
        'faq-list' : FAQList,
        'long-slider' : LongSlider,
        'calculator-block' : CalculatorBlock
    }
})
export default class ErhvervFAQ extends Vue {


}
