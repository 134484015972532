import Vue from "vue";
import store from '@/store';
import { Component, Emit, Inject, Model, Prop, Provide, Watch } from "vue-property-decorator";
import template from './CarInsurance.vue';
import AbAxios from "../../../common/ab_axios_leasing";
import Utils from '../../providers/Utils';
import AbAmount from '../../../common/ab_amount';
import ToggleLineComponent from '../../../shared/toggleline/ToggleLineComponent.vue';
import AutocompleteComponent from '../../../shared/inputs/autocomplete/Autocomplete.vue';
import UserData from '../../providers/UserData';
import InsurancesData from '../../model/Insurances';
import AbLightbox from '../../../common/ab_lightbox';
import stringSimilarity from 'string-similarity';
import AbGtm from '../../../common/ab_gtm';

@Component({
    name: 'leasing-car-insurance-component',
    mixins: [template],
    components: {
        'toggleline-component': ToggleLineComponent,
        'autocomplete-component': AutocompleteComponent
    }
})
export default class CarInsurance extends Vue {

    @Prop() model!: string;
    @Prop() brand!: string;
    @Prop() variantYear!: number;
    @Prop() yearlyDrivingInKm: number;
    @Prop() searchText!: string;

    $refs: {
        postNumComp: any,
    }

    years: Array<any> = [
        ["Har ikke haft bil før", "0"],
        ["1 år", "1"],
        ["2 år", "2"],
        ["3 år", "3"],
        ["4 år", "4"],
        ["5 år","5"],
        ["6 år", "6"],
        ["7 år eller mere", "7"]];

    abAxios = new AbAxios(this.$store.getters.getUserData.epiServiceUrl);
    vehicleSearchServiceUrl: string = "/CarInsurance/CarSearch?";
    calculatePriceServiceUrl: string = "/CarInsurance/Prices?";

    utils = new Utils();
    abAmount = new AbAmount();
    userData: UserData = this.$store.getters.getUserData;
    abLightbox = new AbLightbox();
    abGtm = new AbGtm();

    almbrandInsurancesCheckBox: boolean = false;
    //insurancePrice: number = 0;
    excessAmount: number = 0;
    statutoryFee: number = 0;
    eksInsurancesCompanySelected = "";
    toolTip1: boolean = false;

    matchStrArr: string[] = [];
    similarityMatch: any;
    showCalculator: boolean = false;
    //max match rating for visning af forsikring beregner
    maxRating: number = -1;
    //tilbud
    discountProcent: number = -1;
    //miljø bidrag
    environmentalContribution: number = -1;
    //max bil gruppe for visning af forsikring beregner
    readonly maxVehicleGroup: number = 8; //TODO get this from cms preload data!!!

    createQueryParams = params =>
        Object.keys(params)
            .map(k => `${k}=${encodeURI(params[k])}`)
            .join('&');

    //parametrer til at finde bil varianter
    vehicleSearchParams = {
        brandModelSelected: "true",
        searchType: "model",
        //Brand, Model, Year, Fuel, DoorCount, horsepower, variant
        searchText: this.searchText
    }

    //parametrer til at lave en beregning på fundet bil variant
    calculatePriceParams = {
        zipCode: "",
        customerAge: "",
        carOwnerYears: "",
        variantYear: this.variantYear,
        variantName: "",
        variantId: -1,
        vehicleGroup: -1,
        kid: 0,
        carWeight: 0,
        model: this.model,
        brand: this.brand,
        calculatorBlockId: "5d813551-2d20-4466-b09c-3526e7258ad1",
        //excessId: 64,
        excessId: -1,
        //kasko eller ej
        extendedPackage: 1,
        yearlyDrivingInKm: 0,
        //månedlig(1) eller årlig(0) pris
        monthlyPrices: 1
    }

    yearsWithCarErrLabel: string = "";
    ageErrLabel: string = "";
    postNumErrLabel: string = "";

    resetYearsWithCarError() {
        this.yearsWithCarErrLabel = "";
        this.resetInsurancesError();
    }

    resetAgeError() {
        this.ageErrLabel = "";
        this.resetInsurancesError();
    }

    resetPostNumError() {
        this.postNumErrLabel = "";
        this.resetInsurancesError();
    }

    resetLabels() {
        this.yearsWithCarErrLabel = '';
        this.ageErrLabel = '';
        this.postNumErrLabel = '';
        this.resetInsurancesError();
    }

    resetFields() {
        this.almbrandInsurancesCheckBox = false;
        //this.insurancePrice = 0;
        this.excessAmount = 0;

        this.calculatePriceParams.zipCode = "";
        this.calculatePriceParams.customerAge = "";
        this.calculatePriceParams.carOwnerYears = "";
    }

    validateSubmit() {
        this.resetLabels();
        let validated: boolean = true;

        if ((this.calculatePriceParams.carOwnerYears === null || this.calculatePriceParams.carOwnerYears === 'undefined') || this.calculatePriceParams.carOwnerYears === "") {
            this.yearsWithCarErrLabel = "Du mangler at angive Antal år med egen bil";
            validated = false;
        }

        if ((this.calculatePriceParams.customerAge === null || this.calculatePriceParams.customerAge === 'undefined')|| this.calculatePriceParams.customerAge === "") {
            this.ageErrLabel = "Du mangler at angive din alder";
            validated = false;
        } else if (parseInt(this.calculatePriceParams.customerAge) < 18) {
            this.ageErrLabel = "Du skal minimum være 18 år for at købe en forsikring";
            validated = false;
        }

        if ((this.calculatePriceParams.zipCode === null || this.calculatePriceParams.zipCode === 'undefined') || this.calculatePriceParams.zipCode === "") {
            this.postNumErrLabel = "Du mangler at angive dit postnummer";
            validated = false;
        }
        else if (this.calculatePriceParams.zipCode.length !== 4) {
            this.postNumErrLabel = "Dit postnummer skal være 4 tal";
            validated = false;
        }


        return validated;
    }

    calculate() {
        if (this.validateSubmit()) {
            this.calculatePriceParams.yearlyDrivingInKm = this.yearlyDrivingInKm;
            let queryParams = this.createQueryParams(this.calculatePriceParams);
            let _url = this.calculatePriceServiceUrl + queryParams;
            this.calculatePriceViaRest(_url);
        }
    }

    formatPrice(value: any): string {
        if (value === undefined) {
            return "0";
        }

        return this.abAmount.format(value) + "";
    }
    /**
     * Søger leasing bil i TIA og finder det bedste match ud fra listen
     * @param url
     */
    vehicleSearchViaRest(url: string) {
        // console.log("Test calculator visning")
        var tempshowCalculator = true;
        var self = this;

        if(true){
            let data = require("../../../json/carSearch.json");
            let tiaMatchList = data.carMatches;
    
                tiaMatchList.forEach(match => {
                    self.matchStrArr.push(match.matchText);
                })
    
                self.similarityMatch = stringSimilarity.findBestMatch(self.searchText, self.matchStrArr);
                //console.log(self.similarityMatch);
                if (self.similarityMatch.bestMatch.rating < self.maxRating) {
                    //rating er for lav, vis ikke forsikrings beregner
                    console.log("rating er for lav, ingen calculator")
                    tempshowCalculator = false;
                }
        }else{
            this.abAxios.abGet(url, false, function successCallback(response: any) {
                let tiaMatchList = response.data.carMatches;
    
                tiaMatchList.forEach(match => {
                    self.matchStrArr.push(match.matchText);
                })
    
                self.similarityMatch = stringSimilarity.findBestMatch(self.searchText, self.matchStrArr);
                //console.log(self.similarityMatch);
                if (self.similarityMatch.bestMatch.rating < self.maxRating) {
                    //rating er for lav, vis ikke forsikrings beregner
                    console.log("rating er for lav, ingen calculator")
                    tempshowCalculator = false;
                }
                else {
                    tiaMatchList.forEach(match => {
                        if (self.similarityMatch.bestMatch.target === match.matchText) {
                            self.calculatePriceParams.variantId = match.car.variantId;
                            self.calculatePriceParams.variantName = match.car.variantName;
                            self.calculatePriceParams.vehicleGroup = match.car.vehicleGroup;
                            self.calculatePriceParams.kid = match.car.kid;
                            self.calculatePriceParams.carWeight = match.car.careWeight;
    
                            self.calculatePriceParams.model = match.car.model;
                            self.calculatePriceParams.brand = match.car.brand;
                        }
                    })
    
                    if (self.calculatePriceParams.vehicleGroup >= self.maxVehicleGroup) {
                        //bil gruppe er for høj, vis ikke forsikrings beregner
                        console.log("bil gruppe er for høj, ingen calculator")
    
                        tempshowCalculator = false;
                    } else {
                        let insurancesData = new InsurancesData();
                        insurancesData.foundTiaCarName = self.similarityMatch.bestMatch.target;
                        insurancesData.foundTiaVariantId = self.calculatePriceParams.variantId;
                        self.$store.dispatch('setInsurancesData', insurancesData);
                    }
                }
                self.showCalculator = tempshowCalculator;
    
            }, function errorCallback(error: any) {
                //console.log(error);
                if (!(error.response === null || error.response === 'undefined')) {
                    let errorRes = error.response,
                        statusCode = errorRes.status,
                        statusText = errorRes.statusText,
                        errorMsg = "Forsikring bil søgning fejlede. Noget gik galt.. " + "[" + statusCode + " - " + statusText + " - " + url + "]";
                    self.abGtm.triggerGtmEvent("fejl", "vehicleInsurancesSearch", '[' + self.abGtm.getUtcTimestamp() + '] ' + errorMsg);
                } else {
                    self.abGtm.triggerGtmEvent("fejl", "vehicleInsurancesSearch", '[' + self.abGtm.getUtcTimestamp() + '] ' + error);
                }
                //TIA fejler, vis ikke forsikrings beregner
                    console.log("fejl ingen calculator")
            });
        }

        
    }
    /**
     * Laver beregning på fundet bil variant samt oplysninger fra både valgte leasing bil og forsikring beregner form
     * @param url
     */
   calculatePriceViaRest(url: string) {
        var self = this;
        if(true){
            let data = require("../../../json/calculateContract.json");
            if (data.excessList === undefined) {
                self.abLightbox.standardLightText("Vi kan desværre ikke give dig en pris <em>online</em>", "Noget gik galt.. </br></br>" + data.warningMessage + "</br></br> Ring til os på Tlf. <a class='popup__content__phone-link' href='tel: 70607010'> 70 60 70 10</a>");
                return;
            }
            let insurancesData = self.$store.getters.getInsurancesData;
            insurancesData.mileage = self.yearlyDrivingInKm;
            insurancesData.damageFreeDriving = self.calculatePriceParams.carOwnerYears;
            insurancesData.insurancePrice = data.totalPrice;
            data.excessList.forEach(excess => {
                if (data.excessId === excess.id) {
                    insurancesData.excessAmount = excess.amount;
                }
            });

            //self.insurancePrice = insurancesData.insurancePrice;
            self.excessAmount = insurancesData.excessAmount;
            self.statutoryFee = data.statutoryFee;

            let newPrice = self.calculateDiscount(insurancesData.insurancePrice);
            insurancesData.insurancePrice = self.abAmount.format(newPrice);
            self.abAmount.animateNumberCount('insurancePriceWithOffer', 0, newPrice, true);

            self.$store.dispatch('setInsurancesData', insurancesData);

            let insurancesDataStr = JSON.stringify(insurancesData);
            self.abGtm.triggerGtmEvent("LeasingBeregning-Gennemfoert", "LeasingBeregning-Gennemfoert", '[' + self.abGtm.getUtcTimestamp() + '] ' + insurancesDataStr);
        }
        else{
            this.abAxios.abGet(url, true, function successCallback(response: any) {
                //console.log(response.data);
    
                if (response.data.excessList === undefined) {
                    self.abLightbox.standardLightText("Vi kan desværre ikke give dig en pris <em>online</em>", "Noget gik galt.. </br></br>" + response.data.warningMessage + "</br></br> Ring til os på Tlf. <a class='popup__content__phone-link' href='tel: 70607010'> 70 60 70 10</a>");
                    return;
                }
                let insurancesData = self.$store.getters.getInsurancesData;
                insurancesData.mileage = self.yearlyDrivingInKm;
                insurancesData.damageFreeDriving = self.calculatePriceParams.carOwnerYears;
                insurancesData.insurancePrice = response.data.totalPrice;
                response.data.excessList.forEach(excess => {
                    if (response.data.excessId === excess.id) {
                        insurancesData.excessAmount = excess.amount;
                    }
                });
    
                //self.insurancePrice = insurancesData.insurancePrice;
                self.excessAmount = insurancesData.excessAmount;
                self.statutoryFee = response.data.statutoryFee;
    
                let newPrice = self.calculateDiscount(insurancesData.insurancePrice);
                insurancesData.insurancePrice = self.abAmount.format(newPrice);
                self.abAmount.animateNumberCount('insurancePriceWithOffer', 0, newPrice, true);
    
                self.$store.dispatch('setInsurancesData', insurancesData);
    
                let insurancesDataStr = JSON.stringify(insurancesData);
                self.abGtm.triggerGtmEvent("LeasingBeregning-Gennemfoert", "LeasingBeregning-Gennemfoert", '[' + self.abGtm.getUtcTimestamp() + '] ' + insurancesDataStr);
    
            }, function errorCallback(error: any) {
                //console.log(error);
                let errorRes = error.response,
                    statusCode = errorRes.status,
                    statusText = errorRes.statusText,
                    errorMsg = "Forsikring bil beregning fejlede. Noget gik galt.. " + "[" + statusCode + " - " + statusText + " - " + url + "]";
                self.abGtm.triggerGtmEvent("fejl", "calculateInsurancesPrice", '[' + self.abGtm.getUtcTimestamp() + '] ' + errorMsg);
                self.abLightbox.standardLightText("Vi kan desværre ikke give dig en pris <em>online</em>", "Noget gik galt.. </br></br>" + error + "</br></br> Ring til os på Tlf. <a class='popup__content__phone-link' href='tel: 70607010'> 70 60 70 10</a>");
            });
        }
        
    }

    calculateDiscount(insurancePrice: number) {
        if (this.discountProcent === 0) {
            return insurancePrice;
        }
        const insurancePriceWithoutEnvironmentalContribution = insurancePrice - this.environmentalContribution;
        const discount = insurancePriceWithoutEnvironmentalContribution * this.discountProcent / 100;
        let priceSum = insurancePrice - discount;
        return priceSum;
    }

    almbrandInsurancesHandle() {
        if (this.almbrandInsurancesCheckBox) {
            this.almbrandInsurancesCheckBox = false
        } else {
            this.almbrandInsurancesCheckBox = true
        }
    }


    handleInsurancesChecked() {
        this.almbrandInsurancesHandle();
        this.resetInsurancesError();

        if (!this.almbrandInsurancesCheckBox) {
            this.resetAll();
        } else {
            //fjern valg på eks. forsikring dropdown
            this.eksInsurancesCompanySelected = "";
            let insurancesData = this.$store.getters.getInsurancesData;
            insurancesData.almbrandInsurancesCheckBox = true;
            this.$store.dispatch('setInsurancesData', insurancesData);
        }
        //this.utils.doAnimate(50, "#animate-controller-insurances", ".animate-content-insurances");
    }

    resetAll() {
        //this.utils.closeAnimate("#animate-controller-insurances");
        this.resetLabels();
        this.resetFields();
        let insurancesData = this.$store.getters.getInsurancesData;
        insurancesData.reset();
        this.$store.dispatch('setInsurancesData', insurancesData);
    }

    @Watch('yearlyDrivingInKm')
    yearlyDrivingInKmChanged(newValue: string, oldValue: string) {
        if (newValue === oldValue) {
            return;
        }
        if (this.almbrandInsurancesCheckBox) {
            this.calculate();
        }
    }

    @Watch('eksInsurancesCompanySelected')
    insurancesCompanyOptionsSelected(newValue: string, oldValue: string) {
        if (newValue === oldValue) {
            return;
        }
        if (newValue.length > 0) {
            this.resetAll();
        }
        //sæt valgte forsikring fra dropdown i kontrakten
        this.$store.dispatch('setInsuranceCompany', newValue);
    }

    resetInsurancesError() {
        this.userData.insurancesErrLabel = "";
    }
    //callback fra adresse component
    postnumChanged(internalValue: string): void {
        this.calculatePriceParams.zipCode = internalValue;
        this.resetPostNumError();
    }
    //callback fra adresse component
    postnumSelected(internalValue: any): void {
        this.calculatePriceParams.zipCode = internalValue.postnummer.nr;
        this.resetPostNumError();
    }

    mounted() {
        let queryParams = this.createQueryParams(this.vehicleSearchParams);
        let _url = this.vehicleSearchServiceUrl + queryParams;
        //søg tia og find det bedste bil variant match
        // console.log(_url);
        this.vehicleSearchViaRest(_url);

        this.discountProcent = this.userData.epiData.insurrance.insuranceDiscount;
        this.environmentalContribution = this.userData.epiData.insurrance.insuranceContribution;
        this.maxRating = this.userData.epiData.insurrance.leasingTiaMatchRating;

        //ny forsikrings beregner object
        let insurancesData = new InsurancesData();
        this.$store.dispatch('setInsurancesData', insurancesData);
    }

}
