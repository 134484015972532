import flatpickr from "flatpickr";
import { Danish } from "flatpickr/dist/l10n/da.js"

/** This class is for adding datepicker to input fields
    using https://flatpickr.js.org/
*/
class AbDatePickr {
    private _dateFieldAttribute: string;
    private today: string;
    private globalOptions = {
        disableMobile: true,
        allowInput: false,
        dateFormat: 'd-m-Y',
    }

    constructor(datefieldAttribute: string = null) {
        if (datefieldAttribute) {
            const d: Date = new Date();
            this.today = d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();
            this._dateFieldAttribute = datefieldAttribute;
            flatpickr.localize(Danish);
            this.init(Array.from(document.querySelectorAll('[' + datefieldAttribute + ']')));
        }
    }

    private init(dateFields: Array<HTMLFormElement> = null): void {
        let flatPickr;
        if (dateFields && dateFields.length > 0) {
            dateFields.forEach((dateField: HTMLFormElement) => {
                let options: any = this.getOptions(dateField);
                flatPickr = flatpickr(dateField, Object.assign({}, this.globalOptions, options));
                //elem.changeMonth(0, false); // go to January
            });
        }
    }

    private getOptions(dateField: HTMLFormElement): any {
        const options: any = {};
        let rules = dateField.getAttribute(this._dateFieldAttribute);
        if (rules) {
            rules = rules.toLowerCase().trim();
            if (rules.indexOf('fromtoday') > -1) {
                options.minDate = this.today;
            }
            if (rules.indexOf('untiltoday') > -1) {
                options.maxDate = this.today;
            }
            if (rules.indexOf('showinline') > -1) {
                options.inline = true;
            }
            if (rules.indexOf('defaultdate') > -1) {
                options.defaultDate = new Date();
            }
            
        }
        return options;
    }
}

export default AbDatePickr;


const abDatePickr = new AbDatePickr('ab-date-pickr');
