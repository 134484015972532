<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import SsoFormComponent from "@/components/forms/SsoFormComponent.vue";

@Component({
  components: {SsoFormComponent}
})
export default class DebitorChangeForm extends Vue {

}
</script>

<template>
  <div>
    <sso-form-component requestId="Bestillingsark-Debitorskifte" title="Debitorskifte - finansiel leasing"></sso-form-component>
  </div>
</template>

<style scoped lang="scss">

</style>