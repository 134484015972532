import AbAxios from '../../common/ab_axios_leasing';
import AbGtm from '../../common/ab_gtm';
import AbLightbox from "../../common/ab_lightbox";
import UserData from '../providers/UserData';
import Vue from "vue";
import eventBus from '../../../../../../eventBus';
import { isNullOrUndefined } from "is-what";
import router from "@/router";
import store from "../../store";

export default class FormHelper extends Vue{

    savedContract: any = {}
    userData: UserData = store.getters.getUserData;

    abAxios = new AbAxios(this.userData.serviceUrl, "application/json; charset=utf-8", this.userData.csrftoken);

    createOfferServiceUrl: string = "/leasing/api/Contract/SaveOffer";
    //changeOfferStatusServiceUrl: string = "/leasing/api/Contract/ChangeOfferStatus?" + store.getters.getUserData.apiKey;
    //mailServiceUrl: string = "/MailService/Send";

    abLightbox = new AbLightbox();
    abGtm = new AbGtm();

    createOfferViaRest() {
        let _contract = store.getters.getCreatedContract.Contract;
        _contract.ContractType = "Leasing";
        //_contract.Recaptcha = captcha;

        //tilføj valgte almbrand forsikring til kommentar
        let insurancesStr = this.handleInsurances(_contract);

        let payload = JSON.stringify(_contract);

        let self = this;
        this.abAxios.abPostWithToken(this.createOfferServiceUrl, payload, "application/json", true,
            function successCallback(response: any) {
                self.savedContract = response.data.Data.ContractDto;

                /*sæt status på tilbud så det er klar til kreditvurdering
                self.changeOfferStatusViaRest(
                    self.changeOfferStatusServiceUrl,
                    self.savedContract.PublicAuthToken,
                    self.savedContract.ContractId,
                    self.savedContract.SalesChannelGuid);*/

                //log tilbud bestilling
                let desc = "PublicAuthToken: " + self.savedContract.PublicAuthToken + " - ContractId: " + self.savedContract.ContractId;
                self.abGtm.triggerGtmEvent("tilbud bestilt", "createOffer", '[' + self.abGtm.getUtcTimestamp() + '] ' + desc);
                //log forsikring bestilling
                if (!isNullOrUndefined(store.getters.getInsurancesData.almbrandInsurancesCheckBox) && store.getters.getInsurancesData.almbrandInsurancesCheckBox) {
                    self.abGtm.triggerGtmEvent("leasing forsikring tilbud bestilt", "calculateInsurancesPrice", insurancesStr);
                }

                //send mail omkring valgte almbrand forsikring 
                //self.sendMail(recaptchaComponent, captcha, response.data.Data.ContractDto);


                eventBus.$emit("LeasingSubmitSuccess");
                /*
                //tilbud oprettet og status sat. Send videre til rette side.
                if (_contract.Vehicle.IsUsed && self.userData.epiData.formData.redirectPageAfterReleasingSubmit !== "") {
                    //hvis releasing og urlen er sat
                    router.push({ name: 'confirmation', params: { state: 'true', savedContract: self.savedContract}})
                    // window.location.href = "/" + self.userData.epiData.formData.redirectPageAfterReleasingSubmit;
                } else if (self.userData.epiData.formData.redirectPageAfterSubmit !== "") {
                    //kvitterings side
                    router.push({ name: 'confirmation', params: { state: 'true', savedContract: self.savedContract}})
                    // window.location.href = "/" + self.userData.epiData.formData.redirectPageAfterSubmit;
                } else {
                    router.push({ name: 'home'})
                    // window.location.href = "https://www.opendo.dk";
                }
                */
            },
            function errorCallback(error: any) {
                //console.log(error);
                let errorRes = error.response,
                    statusCode = errorRes.status,
                    statusText = errorRes.statusText,
                    errorMsg = "Tilbud til kunden kunne ikke oprettes. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
                    self.abGtm.triggerGtmEvent("fejl", "createOffer", '[' + self.abGtm.getUtcTimestamp() + '] ' + errorMsg);

                //recaptchaComponent.resetRecaptcha();
                self.abLightbox.standardLightText("Vi kan desværre ikke oprette tilbud <em>online</em>", "Noget gik galt.. </br></br>" + error + "</br></br> Ring til os på Tlf. <a class='popup__content__phone-link' href='tel: 70607010'> 70 60 70 10</a>");
                eventBus.$emit("LeasingSubmitFailed");
            });
    }

    handleInsurances(_contract: any): string {
        let insurancesData = store.getters.getInsurancesData;
        //hvis ikke almbrand forsikring er valgt returnere uden at berige kommentar felt
        if (isNullOrUndefined(insurancesData.almbrandInsurancesCheckBox) || !insurancesData.almbrandInsurancesCheckBox) {
            return "";
        }

        let _insurancesStr = "";
        _insurancesStr = _insurancesStr + "Tilbud pris: " + insurancesData.insurancePrice + " kr." + ", selvrisiko: " + insurancesData.excessAmount + " kr." + ", skadesfri kørsel: " + insurancesData.damageFreeDriving + ", kørsels behov: " + insurancesData.mileage + " km." + "\n\r\n\r";
        _insurancesStr = _insurancesStr + "Fundet TIA bil: " + insurancesData.foundTiaCarName + ", variant id: " + insurancesData.foundTiaVariantId;
        _contract.ContractComment = " #### " + _insurancesStr + " #### " + "\n\r\n\r" + _contract.ContractComment;
        _contract.Leasing.InsuranceCompany = "Opendo";

        return _insurancesStr;
    }

    /* P.t. er det besluttet at Tinus håndtere mail til forretning
     * sendMail(recaptchaComponent: any, captcha: string, savedContract: any) {
        if (captcha !== undefined && captcha.length > 0) {

            let insurancesData = store.getters.getInsurancesData;
            //hvis ikke almbrand forsikring er valgt returnere uden at berige kommentar felt
            if (isNullOrUndefined(insurancesData.almbrandInsurancesCheckBox) || !insurancesData.almbrandInsurancesCheckBox) {
                return;
            }

            let ansoeger = savedContract.Buyer.Person;
            let medAnsoeger = savedContract.CoBuyer.Person;

            let _body = "Ansøger " + ansoeger.FirstName + " " + ansoeger.LastName + " Telefon: " + ansoeger.Phone + " Email: " + ansoeger.Email + " Postnr: " + ansoeger.ZipCode + "\n\r\n\r";
            if (!isNullOrUndefined(medAnsoeger)) {
                _body = _body + "Med ansøger " + medAnsoeger.FirstName + " " + medAnsoeger.LastName + " Telefon: " + medAnsoeger.Phone + " Email: " + medAnsoeger.Email + " Postnr: " + medAnsoeger.ZipCode + "\n\r\n\r";
            }
            _body = _body + "Alm. Brand leasingforsikring, pris: " + insurancesData.insurancePrice + " kr." + ", selvrisiko: " + insurancesData.excessAmount + " kr." + ", skadesfri kørsel: " + insurancesData.damageFreeDriving + ", kørsels behov: " + insurancesData.mileage +" km."+ "\n\r\n\r";
            _body = _body + "Fundet TIA bil: " + insurancesData.foundTiaCarName + ", variant id: " + insurancesData.foundTiaVariantId;

           let payload =  {
               fromEmailAddress: "leasing@opendo.dk",
                toPostBoxName : "leasing-forsikring",
                body: _body,
                gRecaptchaResponse: captcha,
                subject: "Almbrand forsikring valgt, tilbud ref: " + savedContract.ContractId
            }

            //console.log(payload);

            let abMailAxios = new AbAxios(store.getters.getUserData.epiServiceUrl);
            abMailAxios.abPost(this.mailServiceUrl, payload, "application/json", false,
                function successCallback(response: any) {
                    console.log(response);

                    if (!response.success) {
                        recaptchaComponent.resetRecaptcha();
                    }
                },
                function errorCallback(error: any) {
                    console.log(error);
                    recaptchaComponent.resetRecaptcha();
                });
        }
    }

    changeOfferStatusViaRest(serviceUrl: string, ContractAuthToken: string, ContractId: string, salesChannelGuid: string) {
        let payload = {
            ContractAuthToken: ContractAuthToken,
            ContractId: ContractId,
            NewStatus: 2,
            SalesChannelGuid: salesChannelGuid
        };

        let self = this;

        this.abAxios.abPostWithToken(serviceUrl, payload, "application/json", false,
            function successCallback(response: any) {
                self.savedContract = response.data.Data;
            },
            function errorCallback(error: any) {
                console.log(error);
            });
    }*/

    printPage() {
        window.print();
    }

}
