<template>
    <div :class="'quick-search-bar type-'+type">
        <div class="wrap">
            <h1 class="h1-1">Find din næste bil</h1>
        </div>
        <div class="wrap">

            <div :class="'content-wrapper '+(loading ? ' loading-prefilter':'')">

            <div class="mobile-prefilter-button" v-on:click="onMobilePrefilterButtonClick">
                <span class="label">Filter efter behov</span>
                <svg class="icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#69BDB6"/>
                    <path d="M19.375 24.375C22.1364 24.375 24.375 22.1364 24.375 19.375C24.375 16.6136 22.1364 14.375 19.375 14.375C16.6136 14.375 14.375 16.6136 14.375 19.375C14.375 22.1364 16.6136 24.375 19.375 24.375Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M25.625 25.625L22.9062 22.9062" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="prefilter-bar">
                <PrefilterField :type="'multiselect'" :label="'Type'" :placeholder="'Vælg karosserityper'" :name="'type'" :options="typeOptions" :align="filter1Align" icon="brand" @changed="typeFieldChanged"/>
                <PrefilterField :type="'multiselect'" :label="'Mærke'" :placeholder="'Vælg bilmærker'" :name="'maerke'" :options="maerkeOptions" :align="filter2Align" icon="car-retro" @changed="maerkeFieldChanged" />
                <PrefilterField :type="'multiselect'" :label="'Brændstof'" :placeholder="'Vælg drivmiddel'" :name="'engine'" :options="engineOptions" :align="filter3Align" icon="clean-car-gas" @changed="engineFieldChanged" />
                <PrefilterField :type="'interval'" :label="'Månedlig ydelse'" :placeholder="'Sæt et maks. og min.'" :name="'interval'" :from="monthlyFrom" :to="monthlyTo" :align="filter4Align" icon="monthly" @changed="monthlyFieldChanged" />
                <div class="big-search-button-wrap flex my-flex-row flex-s1">
                    <div class="big-search-button transition-all" v-on:click="onSearch">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M21.0004 21.0004L16.6504 16.6504" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>

            </div>
        </div>

    </div>

</template>


<script>
export default {
  props: ['type', 'allOptions', 'monthlyIntervalFrom', 'monthlyIntervalTo', 'loading'],
  data() {
    return {

        filter1Align: 'left',
        filter2Align: 'left',
        filter3Align: 'left',
        filter4Align: 'right',

        typeOptions: [
            {'value': 'stationcar', 'name': 'Stationcar', 'icon': 'stationcar'},
            {'value': 'cabriolet', 'name': 'Cabriolet', 'icon': 'cabriolet'},
            {'value': 'suv', 'name': 'SUV', 'icon': 'suv'},
            {'value': 'hatchback', 'name': 'Hatchback', 'icon': 'hatchback'},
            {'value': 'coupe', 'name': 'Coupé', 'icon': 'coupe'},
            {'value': 'sedan', 'name': 'Sedan', 'icon': 'sedan'},
            {'value': 'mini', 'name': 'Mini', 'icon': 'mini'},
            {'value': 'varebil', 'name': 'Varebil', 'icon': 'varebil'},
          
        ],
        maerkeOptions: [
            {'value': 'audi', 'name': 'Audi'},
            {'value': 'bmw', 'name': 'BMW'},
            {'value': 'fiat', 'name': 'Fiat'},
            {'value': 'jeep', 'name': 'Jeep'},
            {'value': 'nissan', 'name': 'Nissan'},
            {'value': 'peugeot', 'name': 'Peugeot'},

        ],
        engineOptions: [
            {'value': 'el', 'name': 'El'},
            {'value': 'elbenzin', 'name': 'ElBenzinPlugin'},
            {'value': 'benzin', 'name': 'Benzin'}
        ],

        returnData: {
            "type": [],
            "maerke": [],
            "engine": [],
            "monthly": [],
        },
        monthlyFrom: 20,
        monthlyTo: 1000,
        dump: "",
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  mounted(){
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
    this.init();
  },
  created(){
    
    this.$watch(
      () => this.monthlyIntervalFrom,
      (toParams, previousParams) => {
        this.monthlyFrom = toParams;
      }
    );
    this.$watch(
      () => this.monthlyIntervalTo,
      (toParams, previousParams) => {
        this.monthlyTo = toParams;
      }
    );
    this.$watch(
      () => this.allOptions,
      (toParams, previousParams) => {
        this.prepareAllOptions();
      }
    );
    this.init();
    this.prepareAllOptions();
    // userData: UserData = this.$store.getters.getUserData;
  },
  methods: {
    init: function(){
      this.monthlyFrom = this.monthlyIntervalFrom;
      this.monthlyTo = this.monthlyIntervalTo;
    },
    prepareAllOptions(){
        if (this.allOptions['typeOptions']){
            this.typeOptions = this.allOptions['typeOptions'];
        }
        if (this.allOptions['maerkeOptions']){
            this.maerkeOptions = this.allOptions['maerkeOptions'];
        }
        if (this.allOptions['engineOptions']){
            this.engineOptions = this.allOptions['engineOptions'];
        }
    },
    getDimensions() {
        this.width = document.documentElement.clientWidth;
        this.height = document.documentElement.clientHeight;


        

        
        if(this.width < 1121){
            this.filter1Align = 'left';
            this.filter2Align = 'right';
            this.filter3Align = 'left';
            this.filter4Align = 'right';
        }else{
            this.filter1Align = 'left';
            this.filter2Align = 'left';
            this.filter3Align = 'left';
            this.filter4Align = 'right';
        }
        
        // if ()
    },
    typeFieldChanged: function(val){
        this.returnData.type = val;
        this.dump = JSON.stringify(this.returnData);

        this.somethingChanged();
    },
    maerkeFieldChanged: function(val){
        this.returnData.maerke = val;
        this.dump = JSON.stringify(this.returnData);

        this.somethingChanged();
    },
    engineFieldChanged: function(val){
        this.returnData.engine = val;
        this.dump = JSON.stringify(this.returnData);

        this.somethingChanged();
    },
    monthlyFieldChanged: function(val){
        if (val.from){
            this.returnData.monthly = {"from": val.from, "to": val.to};
            this.dump = JSON.stringify(this.returnData);
            this.somethingChanged();
        }

    },
    onMobilePrefilterButtonClick: function(e){
        e.preventDefault();
        this.$emit("onMobileFilterClick");
    },
    somethingChanged: function(){
        this.$emit("changed", this.returnData);
    },
    onSearch: function(e){
        e.preventDefault();
        this.$emit("onSearch", this.returnData);
    }
  },
 
}
</script>

<style lang="scss" scoped>

@import "~@/styles/variables.scss";


.quick-search-bar{
    background: rgba(105, 189, 182, 0.09);
    border-radius: 40px;
    padding-top: 80px;
    padding-bottom: 120px;
    margin-left: 20px;
    margin-right: 20px;
    .h1-1{
        text-align: center;
        margin-bottom: 48px;
    }
    .wrap{
        padding-left: 28px;
        padding-right: 28px;
        max-width: 1256px;
    }
}

.mobile-prefilter-button{
    display: none;
    background: #FFFFFF;
    box-shadow: 0px 32px 64px -24px rgba(31, 72, 68, 0.16);
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #1F4743;
    height: 56px;
    padding: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;

    .label{

    }
    .icon{
        position: absolute;
        right: 8px;
        top: 50%;
        margin-top: -20px;
    }
}

.content-wrapper{
    opacity: 1;
    -webkit-transition: all 0.6s ease;
	-moz-transition: all 0.6s ease;
	-o-transition: all 0.6s ease;
	-ms-transition: all 0.6s ease;
	transition: all 0.6s ease;
    &.loading-prefilter{

        transform: scale(1.03);
        -webkit-transform: scale(1.03);
        pointer-events: none;
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
        opacity: 0.6;

    }
}


.prefilter-bar{
    background: #FFFFFF;
    box-shadow: 0px 32px 64px -24px rgba(31, 72, 68, 0.16);
    border-radius: 20px;
    padding: 8px;

    display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: nowrap;
   my-flex-wrap: nowrap;
    -ms-flex-direction: row;
    flex-direction: row;

    -ms-flex-align: flex-start;
    align-items: stretch;
    justify-content: flex-start;
	

    .prefilter-field{
        flex: 1;
        margin-left: 8px;
        &:first-child{
            margin-left: 0px;
        }
    }
    .big-search-button-wrap{
        width: 64px;
        margin-left: 8px;
    }
    .big-search-button{
        background: #69BDB6;
        width: 64px;
        height: 64px;
        display: block;
        border-radius: 36px;
        position: relative;
        cursor: pointer;
        svg{
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            margin-left: -12px;
            margin-top: -12px;
        }
        &:hover{
            background: #1F4743;
        }
    }
}
::v-deep .prefilter-bar{
    .prefilter-field{
        .field{
            .selected-option{
                white-space: nowrap;
            }
        }
    }
}



@media (max-width: 1120px) {
    .quick-search-bar{
        padding-top: 64px;
        padding-bottom: 96px;
    }
    ::v-deep .prefilter-bar{
        display: block;
        // -ms-flex-wrap: wrap;
        //my-flex-wrap: wrap;
        .prefilter-field{
            display: inline-block;
            width: 50%;
            width: calc(50% - 4px);
            margin-left: 0px;
            &:nth-of-type(2n){
                margin-left: 8px;
            }
            &:nth-of-type(2n+1){
                
                &:after,
                &:before{
                    display: none;
                }    
            }
            &:after,
            &:before{
                // display: none;
            }
        }
    }
    .prefilter-bar{
        .big-search-button-wrap{
            // margin-top: 24px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: -40px;
        }
    }

}

@media (max-width: #{$screen-mobile}) {
    .prefilter-bar{
        display: none;
    }    
    .mobile-prefilter-button{
        display: block;
        margin-top: 32px;
    }
    .quick-search-bar{
        margin-left: 0px;
        margin-right: 0px;
        padding-bottom: 48px;
        padding-top: 32px;
        border-radius: 20px;
        .wrap{
            padding-left: 20px;
            padding-right: 20px;   
        }
        .h1-1{
            font-size: 32px;
            line-height: 130%;
            margin-bottom: 0px;
        }
    }
}

</style>
