/**
 * Wraps the calls to the ContentDeliveryAPI. It's used by the vuex
 * `epiDataModel` module
 */
import { IContent } from "@/components/leasingpro/definitions/episerver/content";
import { LeasingProServicePage } from "@/components/leasingpro/definitions/episerver/content-types";
import axios, { AxiosPromise } from "axios";

function get<T extends IContent | Array<IContent>>(
    baseURL: string,
    url: string,
    parameters: any,
    headers = {}
) {
    return axios({
        method: "get",
        baseURL: baseURL,
        url: url,
        params: parameters,
        headers: Object.assign({}, headers)
    }) as AxiosPromise<T>;
}

const applicationPath = "/"; //document.documentElement.dataset.applicationPath;

/**
 * Getting content with the content link is the default way of calling the ContentDeliveryAPI.
 * It is used in MusicFestival to get:
 *  - block data
 *  - updated data after a `beta/contentSaved` message, which has the content link
 */
async function getContentByContentLink<T extends IContent>(
    contentLink: string,
    parameters: any
): Promise<T> {
    let result = await get<T>(
        `${applicationPath}api/episerver/v2.0/`,
        `content/${contentLink}`,
        parameters,
        { "Accept-Language": "da" }
    );
    return result.data;
}

async function getContentByGuids<T extends IContent[]>(
    guids: string[],
    parameters = {}
): Promise<T> {
    let result = await get<T>(
        `${applicationPath}api/episerver/v2.0/`,
        `content?guids=${guids.join()}`,
        parameters
    );
    return result.data;
}

/**
 * Getting data from ContentDeliveryAPI through regular routing (friendly URLs) was added in ContentDeliveryAPI 2.3.0.
 * It is used in MusicFestival to get:
 *  - page data, through the vuex `epiDataModel` module
 */
async function getContentByFriendlyUrl<T extends IContent>(
    friendlyUrl: string,
    parameters: any
): Promise<T> {
    let result = await get<T>("/", friendlyUrl, parameters, {
        Accept: "application/json"
    });
    return result.data;
}

async function getMockData<LeasingProServicePage extends IContent>(
    friendlyUrl: string,
    parameters: any
): Promise<LeasingProServicePage> {
    let data = null;
    
    let array = [
        "/leasingpro",
        "/leasingpro/admin",
        "/leasingpro/braendstof",
        "/leasingpro/biler",
        "/leasingpro/flaade",
        "/leasingpro/co2",
        "/leasingpro/km",
    ]
    const url = friendlyUrl.toLowerCase();
    if(array.includes(url)){
        data = require("@/components/leasingpro/json/pages"+url+".json");
    }
    else{
        const data = require("@/components/leasingpro/json/pages/leasingpro.json");
    }
    return data as LeasingProServicePage
}



/**
 * Getting the children of the page with ContentDeliveryAPI is enabled by
 * the extensions in Infrastructure/ContentDeliveryExtendedRouting.
 * It is used in MusicFestival to get:
 *  - artist list in ArtistContainerPage.vue
 */
async function getChildren(
    friendlyUrl: string,
    parameters: any
): Promise<IContent[]> {
    // Split URL into path and queries
    const urlPieces = friendlyUrl.split("?");
    // In View mode we might visit the URL with or without a trailing / (i.e. "http://localhost:56312/en/artists" or "http://localhost:56312/en/artists/")
    const pathname = urlPieces[0].endsWith("/")
        ? urlPieces[0]
        : urlPieces[0] + "/";
    // In Edit mode we'll have URL queries (i.e. "/EPiServer/CMS/Content/en/artists,,6/?epieditmode=True")
    const queries = urlPieces[1] ? "?" + urlPieces[1] : "";

    // Concatenate the friendly URL with "/children" for the Content API
    const callUrl = pathname + "children" + queries;
    let result = await get<IContent[]>("/", callUrl, parameters, {
        Accept: "application/json"
    });
    return result.data;
}

export default {
    /**
     * Getting content with the content link is the default way of calling the ContentDeliveryAPI.
     * It is used in MusicFestival to get:
     *  - block data
     *  - updated data after a `beta/contentSaved` message, which has the content link
     */
    getContentByContentLink,

    /**
     * Getting data from ContentDeliveryAPI through regular routing (friendly URLs) was added in ContentDeliveryAPI 2.3.0.
     * It is used in MusicFestival to get:
     *  - page data, through the vuex `epiDataModel` module
     */
    getContentByFriendlyUrl,

    getMockData,

    /**
     * Getting the children of the page with ContentDeliveryAPI is enabled by
     * the extensions in Infrastructure/ContentDeliveryExtendedRouting.
     * It is used in MusicFestival to get:
     *  - artist list in ArtistContainerPage.vue
     */
    getContentChildren: getChildren
};
