<template>

  
  <section class="logo-slider-block" :class="green_background? 'green-background': ''">
    <div class="wrap">
      <div class="block" :class="image ? ' with-image': ''">
        
        <div class="block-inner">
          <div class="side-image" v-if="image"> 
            <img :src="image" :alt="imageAlt" />
          </div>
          <div class="side-content">
            <h2 class="title h2-1">{{title}}</h2>
            <div class="description" v-html="content"></div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <partners-slider v-if="patnersLogos && patnersLogos.length>0" :logos="patnersLogos" :logosInSlide="logosPerSlide" :withPagination="true" :smallButtons="true" />
    </div>
  </section>

</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';
import ParntersSlider from '../../../components/ui/partners-slider/partners-slider';

export default {
  props: ['block'],
  components: {
    'partners-slider': ParntersSlider
  },
  data() {
    return {
      image: null,
      imageAlt: null,
      title: null,
      content: null,
      patnersLogos: null,
      green_background: false,
      logosPerSlide: 10
    }
  },

  mounted() {
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  beforeMount(){
    
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();

  },
  methods: {
    prepareContent: function(){
      // console.log(this.block);
      this.title = this.block.title.value;
      this.content = this.block.content.value;
      this.image = this.block.image.value.sizes && this.block.image.value.sizes.large ? this.block.image.value.sizes.large.url : null;
      if (this.image && this.block.image.value.info){
        this.imageAlt = this.block.image.value.info.alt;
      }

      if (this.image){
        this.imageAlt = this.block.image.value.info.alt;
      }
      if(this.block.green_background){
        this.green_background = this.block.green_background.value === "1" ? true : false;
      }

      if (this.block.partners_logos){
        this.patnersLogos = this.block.partners_logos;
      }else{

        var patnersLogos = [];
        if (this.block.logos){
          
          // console.log(this.block.faq);
          var catCount = this.block.logos.value;
          var c = 0;
          for (var i=0; i<catCount; i++){
            var link = this.block['logos_'+i+'_link'].value;
            var logo = this.block['logos_'+i+'_logo'].value;
            var logoGreen = this.block['logos_'+i+'_logo_green'].value;
            logo.ID = i;
            patnersLogos.push({"link": link, "logo": logo, "logo_green": logoGreen});
          }
          this.patnersLogos = patnersLogos;
        }
      }

    },
    getDimensions() {
      
      if (window.innerWidth<=576){
        this.logosPerSlide = 9;
      } else if (window.innerWidth<=768){
        this.logosPerSlide = 8;
      }else{
        this.logosPerSlide = 10;
      }
    
    },

  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";



.logo-slider-block{
  margin-top: 122px;
  margin-bottom: 122px;
  padding-top: 80px;
  padding-bottom: 80px;
  &.green-background{
    padding-top: 96px;
    padding-bottom: 96px;
    background: rgba(105, 189, 182, 0.09);
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
  }
  .block{
    // display: flex;
    // flex-direction: row;
    // justify-content: flex-start;

    .block-inner{
      .side-content{
        .title{
          margin-bottom: 20px;
        }
        .description{
          column-count: 2;
          break-inside: avoid-column;
          column-gap: 64px;

          color: #4C6C69;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;

          ::v-deep p{
            margin-bottom: 1em;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }

    
    &.with-image{
      .block-inner{
        display: flex;
        // justify-content: space-between;
        align-items: flex-end;
        .side-content{
          width: 50%;
          .description{
            column-count: 1;
            ::v-deep p{
              display: block;
            }
          }
        }
      }
    }
  }
  .side-image{
    display: block;
    width: 32%;
    border-radius: 20px;
    overflow: hidden;
    margin-right: 60px;
    line-height: 0px;
  }
  ::v-deep .partners-slider{
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 53px;
    .navigation-wrap{
      max-width: 1264px;
      box-sizing: border-box;
      margin-left: auto;
      margin-right: auto;

      padding-left: 32px;
      padding-right: 32px;

      position: relative;
      margin-top: 28px;

      text-align: right;
      .pagination{
        position: absolute;
        margin: 0px;
        width:100%;
        top: 0%;
        // margin-top: -5px;
        text-align: center;

        .swiper-pagination-bullet{
          background: #C7E5E4;
          margin-left: 8px;
          margin-right: 8px;
          width: 10px;
          height: 10px;
        }
        .swiper-pagination-bullet-active{
          background: #69BDB6;
        }
      }
      .prev{
        margin: 0px;
      }
      .next{
        margin: 0px;
        margin-left: 16px;
      }
    }
  }
  ::v-deep .swiper-slide{
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
  }
  
  ::v-deep .swiper-slide .logo-slide{
    background: #FFF;
    border-radius: 20px;
    box-shadow: 0px 32px 64px -24px rgba(31, 72, 68, 0.16);
    max-width: 1200px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding: 48px;
    padding-bottom: 24px;

    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: 20px;
    justify-content: space-between;
    .logo-item{
      width: auto;    
    }
  }
}

@media (max-width: #{$screen-tablet}) {
  .logo-slider-block{
    &.green-background{
      padding-top: 60px;
      padding-bottom: 60px;
    }

    .block{
      &.with-image{
        .block-inner{
          display: block;
          .side-content{
            width: 100%;
          }
        }
      }
      .block-inner{
          .side-content{
            .description{
              font-size: 1rem;
              line-height: 135%;
            }  
          }
        }
    }
    .block{
      .block-inner{
        .side-content{
          .description{
            column-count: 1;
            column-gap: 64px;
          }
        }
      }
    }
    .side-image{
      width: 100%;
      margin-right: 0px;
      margin-bottom: 20px;
    }
    ::v-deep .partners-slider{
      margin-top: 32px;
    }
    ::v-deep .swiper-slide .logo-slide{
      padding: 32px;
      padding-bottom: 32px;
      grid-template-columns: repeat(4, auto);
      .logo-item{
        margin-bottom: 0px;
      }
    }
  }
}
@media (max-width: #{$screen-mobile}) {
  .logo-slider-block{
    
    &.green-background{
      padding-top: 48px;
      padding-bottom: 48px;
    }
    

    ::v-deep .partners-slider{
      margin-top: 32px;
    }
    ::v-deep .swiper-slide .logo-slide{
      padding: 20px;
      padding-top: 30px;
      padding-bottom: 30px;
      grid-template-columns: repeat(3, auto);
    }
  }
}

</style>