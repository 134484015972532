import { Component, Vue } from 'vue-property-decorator';

import template from './PrivatErhvervSelectBlock.vue';

@Component({
  mixins: [template],
  props: ['title', 'buttons', 'childURL', 'bg']
})
export default class PrivatErhvervSelectBlock extends Vue {

}