<template>
    <div :id="id"
         :class="[!postUiState ? 'abToggleLine abToggleLineActive' : '',
             selected ? 'abToggleLine abToggleLineActive' : 'abToggleLine abToggleLineDeactive',
             included ? 'abToggleLine__isBaseCoverage' : '',
             excluded ? 'abToggleLine__isExcludedCoverage' : '']">
        <div class="abToggleLine__headerWrapper">
            <div  class="abToggleLine__headerLeft " v-bind:class="{ banan: !description }" v-on:click="toggleDescription($event)">
                <div class="abToggleLine__headerLeft__inner">
                    <div id="arrowIcon" class="abToggleLine__icon__arrowRight abToggleLine__svg__arrowIcon" v-if="!hideArrow">
                        <svg><use xlink:href="/ui/assets/svg/arrow_darkblue_right.svg#Lag_1"></use></svg>
                    </div>
                    <div id="arrowIcon" class="abToggleLine__icon__arrowRight abToggleLine__svg__arrowIcon" v-if="hideArrow && useSpacing">
                        <svg><use xlink:href=""></use></svg>
                    </div>
                    <span id="abToggleLineTitle" :class="[selected ? 'abToggleLine__title abToggleLine__title__bold' : 'abToggleLine__title',
                              postUiState ? '' : 'abToggleLine__title__post']">
                        {{ name }}
                    </span>
                </div>
                <div v-if="showPrice && postUiState" class="abToggleLine__priceInfo__mobile">
                    <span>{{ priceFormatted }}</span>
                    <span id="priceBillingType" :class="[monthlyPrice ? 'currencyPerMonth' : 'currencyPerYear']"> kr.</span>
                </div>
            </div>
            <div class="abToggleLine__headerRight">

                <div v-if="disabled && postUiState" class="disabledDescription">
                    {{ disabledText }}
                </div>

                <div v-if="showPrice && postUiState" class="abToggleLine__priceInfo__desktop">
                    <span>{{ priceFormatted }}</span>
                    <span id="priceBillingType" :class="[monthlyPrice ? 'currencyPerMonth' : 'currencyPerYear']"> kr.</span>
                </div>
                <div v-if="showSwitch && postUiState && !optional" class="abToggleLine__switch__wrapper">
                    <div v-if="!disableSwitch">
                        <label :for="name+'-id-'+id" class="toggle__primary-color__small">
                            <input type="checkbox" :id="name+'-id-'+id" :checked="selected" @change="switchToggleChanged($event)" :disabled="disableSwitch">
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <div v-else class="abToggleLine__icon__checkMark">
                        <svg><use xlink:href="/ui/assets/svg/systemicon_darkblue_Check.svg#icon-checkmark"></use></svg>
                    </div>
                </div>
                <div class="abToggleLine__optional" v-if="optional && !postUiState">
                    Tilvalg
                </div>
                <div v-if="included  && !showSwitch" class="abToggleLine__icon__checkMark">
                    <svg><use xlink:href="/ui/assets/svg/systemicon_darkblue_Check.svg#icon-checkmark"></use></svg>
                </div>
                <div v-if="excluded && !showSwitch" class="abToggleLine__icon__minus">
                    <svg><use xlink:href="/ui/assets/svg/systemicons_red_Minus.svg#Lag_1"></use></svg>
                </div>
                <div v-if="downloadFile !== undefined">
                    <a :href="downloadFile.Url" :download="downloadFile.FileName" target="_blank" class="link__text">
                        <span>{{downloadFile.Title}}</span>
                    </a>
                </div>
            </div>
        </div>
        <div id="abToggleLineDesc" class="abToggleLine__description hiddenAni">
            <div v-if="subHeaders !== undefined" class="abOverview__bodyWrapper">

                <div class="abOverview__row__Wrapper" v-for="item in subHeaders">
                    <div class="abOverview__row">
                        <div class="abOverview__bodyRowsLeft">
                            <span>{{item.title}}</span>
                        </div>
                        <div class="abOverview__bodyRowsRight rigthAligned">
                            <span>{{item.value}}</span>
                        </div>
                    </div>
                </div>

            </div>
            <span v-else v-html="description"></span>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
    import SwitchChange from '../../calculators/core/models/common/SwitchChangeModel';
    import { DownloadFile } from './downloadFile';
    import SubHeader from './subHeader';

    @Component({
        name: 'toggleline-component',
        components: { }
    })
    export default class ToggleLine extends Vue {

        @Prop() private id: number;
        @Prop() private name: string;
        @Prop() private description: string;
        @Prop() private type: string;
        @Prop() private price: number;
        @Prop() private isHidden: boolean;
        @Prop() private priceFormatted: string;
        @Prop() private optional: boolean;
        @Prop() private excluded: boolean;
        @Prop() private included: boolean;
        @Prop() private showPrice: boolean;
        @Prop() private showSwitch: boolean;
        @Prop() private selected: boolean;
        @Prop() private disabledText: string;
        @Prop() private disabled: boolean;
        @Prop() private index: number;
        @Prop() private postUiState: boolean;
        @Prop() private monthlyPrice: boolean;
        @Prop() private hideArrow: boolean;
        @Prop() private useSpacing: boolean;
        @Prop() private downloadFile: DownloadFile;
        @Prop() private disableSwitch: boolean;
        @Prop() private subHeaders: Array<SubHeader>;


        /**
         * Toggling description text visibility
         * @param e
         */
        public toggleDescription(e): void {
            const arrowIcon: HTMLElement = this.$el.querySelector('#arrowIcon');
            if (arrowIcon !== null) {
                arrowIcon.classList.contains('toggle-down') === true ? arrowIcon.classList.remove('toggle-down') : arrowIcon.classList.add('toggle-down');
            }
            const descriptionElm: HTMLElement = this.$el.querySelector('#abToggleLineDesc');
            descriptionElm.classList.contains('hiddenAni') === true ? descriptionElm.classList.remove('hiddenAni') : descriptionElm.classList.add('hiddenAni');
        }

        /**
         * Emitting a change when switch checked value changes
         * @param e
         */
        public switchToggleChanged(e): void {
            const elm: HTMLInputElement = e.target;

            const data: SwitchChange = {
                index: this.index,
                id: this.id,
                type: this.type,
                checkedValue: elm.checked
            };

            //console.log(data);
            this.$emit('switchToggleChange', data);
        }

        mounted() {
            //let subHeaderBody = document.querySelector(".abToggleLine__description");
            //subHeaderBody.classList.add("style", "margin-right: 2px !important;");
        }
    }

</script>

<style lang="scss" scoped>
    .abOverview__bodyWrapper{
        margin-left: 0px;
    }
    .banan {
        cursor:default;
    }

</style>
