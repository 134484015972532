<template>

  
  <section class="two-column-block" :class="green_background? 'green-background': ''">
    <div class="wrap">
      <div class="block">
        <h2 class="title h2-1">{{title}}</h2>
        <div class="content">
          <div class="description" v-html="column_1"></div>
          <div class="description" v-html="column_2"></div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';

export default {
  props: ['block'],
  components: {

  },
  data() {
    return {
      title: null,
      column_1: null,
      column_2: null,
      green_background: false,
    }
  },

  mounted() {
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  beforeMount(){
    
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();

  },
  methods: {
    prepareContent: function(){
      
      this.title = this.block.title.value;
      this.column_1 = this.block.column_1.value;
      this.column_2 = this.block.column_2.value;
      // this.content = this.block.content.value;
      if(this.block.green_background){
        this.green_background = this.block.green_background.value === "1" ? true : false;
      }
    },
    getDimensions() {
      
    
    },

  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";



.two-column-block{
  margin-top: 80px;
  margin-bottom: 80px;

  // padding-top: 80px;
  // padding-bottom: 52px;
  &.green-background{
    padding-top: 80px;
    padding-bottom: 52px;
    background: rgba(105, 189, 182, 0.09);
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
  }
  .block{
    // display: flex;
    // flex-direction: row;
    // justify-content: flex-start;
  }
  .content{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // margin-bottom: -28px;
    .description{
      width: calc( 50% - 31px);

      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4em;
      color: #4C6C69;

      ::v-deep p{
        margin-bottom: 1.4em;
        display: block;
      }
      ::v-deep a{
        color: #69BDB6;
        font-weight: 700;
        text-decoration-line: underline;
      }
    }
  }
  
  
}

@media (max-width: #{$screen-tablet}) {
    
  .two-column-block{
    // padding-top: 60px;
    // padding-bottom: 32px;
    &.green-background{
      padding-top: 60px;
      padding-bottom: 32px;
    }
    .title{
      font-size: 2rem;
    }
    .content{
      display: block;
      .description{
        width: 100%;

      }
    }
    
    
  }
}
@media (max-width: #{$screen-mobile}) {
  
  .two-column-block{
    // padding-top: 48px;
    // padding-bottom: 36px;
    &.green-background{
      padding-top: 48px;
      padding-bottom: 36px;
      // margin-left: 0px;
      // margin-right: 0px;
    
    }
    .title{
      font-size: 2rem;
    }
    .content{
      display: block;
      .description{
        width: 100%;
        font-size: 1rem;
        ::v-deep p{
          margin-bottom: 12px;
        }
      }
    }
    
    
  }
}

</style>