<template>
  <div v-if="loggedIn">
    <BLHeader/>
    <div class="hero-content">
      <div class="wrap fade-on-enter op-fade-in-animation">
        <div class="bank-logo">
          <img :src="bankLogo" :alt="bankName" />
        </div>
        <h1 class="h1-1">Velkommen, {{userName}}</h1>
      </div>
    </div>

    <div class="hero-textual-content" v-if="hero.content && false">
      <div class="wrap fade-on-enter op-fade-in-animation">
        <div class="hero-textual">
          <div class="hero-text" v-html="hero.content"></div>
          <div class="contacts">
            <a :href="hero.phone.url" class="phone"><span class="icon"><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1167_935)">
              <path d="M12.8705 22.5393L12.8805 22.5463C13.7446 23.0965 14.7705 23.3354 15.7887 23.2235C16.8069 23.1117 17.7565 22.6559 18.4805 21.9313L19.1095 21.3023C19.2489 21.163 19.3595 20.9976 19.4349 20.8156C19.5104 20.6335 19.5492 20.4384 19.5492 20.2413C19.5492 20.0442 19.5104 19.8491 19.4349 19.6671C19.3595 19.485 19.2489 19.3196 19.1095 19.1803L16.4565 16.5293C16.3172 16.3899 16.1518 16.2793 15.9698 16.2039C15.7877 16.1285 15.5926 16.0896 15.3955 16.0896C15.1985 16.0896 15.0033 16.1285 14.8213 16.2039C14.6392 16.2793 14.4738 16.3899 14.3345 16.5293C14.0532 16.8105 13.6718 16.9685 13.274 16.9685C12.8763 16.9685 12.4948 16.8105 12.2135 16.5293L7.97153 12.2863C7.69033 12.005 7.53235 11.6236 7.53235 11.2258C7.53235 10.8281 7.69033 10.4466 7.97153 10.1653V10.1653C8.11092 10.026 8.2215 9.8606 8.29694 9.67855C8.37238 9.4965 8.41121 9.30137 8.41121 9.1043C8.41121 8.90724 8.37238 8.71211 8.29694 8.53006C8.2215 8.34801 8.11092 8.1826 7.97153 8.0433L5.31953 5.3923C5.03824 5.1111 4.65678 4.95312 4.25903 4.95312C3.86129 4.95312 3.47982 5.1111 3.19853 5.3923L2.56853 6.0213C1.84412 6.74548 1.38845 7.69513 1.27681 8.71334C1.16518 9.73154 1.40425 10.7574 1.95453 11.6213L1.96053 11.6313C4.86671 15.9311 8.57021 19.6339 12.8705 22.5393V22.5393Z" stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.75 8.25C12.9435 8.25 14.0881 8.72411 14.932 9.56802C15.7759 10.4119 16.25 11.5565 16.25 12.75" stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.75 4.5C13.938 4.5 16.0365 5.36919 17.5836 6.91637C19.1308 8.46354 20 10.562 20 12.75" stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.75 0.75C14.9326 0.75 17.9848 2.01428 20.2353 4.26472C22.4857 6.51516 23.75 9.5674 23.75 12.75" stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_1167_935">
              <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
              </clipPath>
              </defs>
              </svg>
              </span><span class="label">{{hero.phone.title}}</span>
            </a>
            <a :href="hero.email.url" class="email"><span class="icon"><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 4.75H23V19.75H2V4.75Z" stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22.6618 5.30078L14.5178 11.5648C13.9396 12.0096 13.2304 12.2508 12.5008 12.2508C11.7713 12.2508 11.0621 12.0096 10.4838 11.5648L2.33984 5.30078" stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </span><span class="label">{{hero.email.title}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>


    <div class="navigation-content" v-if="navigation.title">
      <div class="wrap fade-on-enter op-fade-in-animation">
        <div class="block">
          <div class="left-side">
            <h2 class="title">{{navigation.title}}</h2>
            <div class="content" v-html="navigation.text"></div>
            <div class="navigation">
              <div class="card-style links-list">
                <div class="buttons">
                  <div class="item link-button-item" v-for="link in links" :key="'login-link-'+link.title" v-if="link.show===true">
                    <router-link class="" :to="link.url" v-if="!link.external" >
                      <span class="label">
                        {{link.title}}
                      </span><span class="icon">
                        <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.4766 1L30.4766 7L24.4766 13" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M30 7H2" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </span>
                    </router-link>
                    <a class="" :href="link.url" target="_blank" v-if="link.external" >
                      <span class="label">
                        {{link.title}}
                      </span><span class="icon">
                        <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.4766 1L30.4766 7L24.4766 13" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M30 7H2" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div><div class="right-side" v-if="navigation.image && navigation.image.sizes">
            <div class="image">
              <img :src="navigation.image.sizes.large" :alt="navigation.image.info ? navigation.image.info.alt : ''" />
            </div>
          </div>
        </div>
      </div>
    </div>


    <section v-if="contactsBlock" class="section fade-on-enter benefits-section">
      <div class="wrap">
        <div class="container flex my-flex-row my-flex-nowrap flex-s3">
          <h2 class="h2-1 big-title">{{contactsBlock.title}}</h2>
          <div class="benefits flex my-flex-row my-flex-wrap flex-s3">
            <div class="benefit" v-for="(item, index) in contactsBlock.contacts" :key="'benefits-'+index" >
              <p class="title">{{item.title}}</p>
              <div class="conent">
                <div v-if="item.phone">
                  <a class="email" :href="'mailto:'+item.email">{{item.email}}</a>
                </div>
                <div v-if="item.phone">
                  <a class="phone" :href="'tel:'+item.phone">{{item.phone}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section fade-on-enter news-section" v-if="articles">
      <div class="wrap">
        <h2 class="h2-1">{{newsBlockTitle}}</h2>
        <news-section :articles="articles" :button="newsButtonTitle" :tagCount="-1" :propNewsSlug="'pengeinstitutter/nyheder'" :noSideGradient="true" :title="newsBlockTitle"  />
        <div class="button-wrap">
          <router-link class="button" :to="'pengeinstitutter/nyheder'">
            {{newsButtonTitle}}
          </router-link>
        </div>
      </div>
    </section>




    <BottomInfoBar :open="openBotomBar" :text="bottomBarText" @onClose="onCloseBottomBar" />


    <div class="banklogin-popup" v-if="isPopupOpen && isPopupOpenSession" v-on:click="clickOnPopup">
      <div class="bg"></div>
      <div class="popup-content">
        <div class="flex flex-column flex-s9">
          <div class="popup-inner">
            <div class="content" v-html="popup.text"></div>

            <div class="button-wrap">
              <a class="button" v-on:click="clickClosePopup" :href="'#'">LUK</a>
            </div>

            <span class="close-popup" v-on:click="clickClosePopup">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 17L17 7" stroke="#4C6C69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17 17L7 7" stroke="#4C6C69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
          </div>
        </div>



      </div>
    </div>

  </div>

</template>
<script>

import * as localForage from "localforage";
import gsap from 'gsap';
import BLHeader from "./BLHeader.vue";
import BottomInfoBar from "@/components/ui/bottom-info-bar/BottomInfoBar.vue";
import NewsSection from '@/components/ui/news-slider/news-slider';
import bankloginData from "../BankloginData";

export default {
  components: {
    BottomInfoBar : BottomInfoBar,
    BLHeader : BLHeader,
    'news-section' : NewsSection,
    // InputField: InputField
  },
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      loggedIn: false,
      openBotomBar: false,

      newsButtonTitle: 'Alle nyheder',
      newsBlockTitle: 'Nyheder',

      cmsPageData2: null,

      isPopupOpen: false,
      isPopupOpenSession: false,
      bottomBarText: '',
      hero: {
        content : null
      },
      popup: {},
      navigation: {},

      contactsBlock: {
        contacts: null
      },

      links: [
        {"external": false, "title": "Henvisningsmodul", "url": {'name': 'BankloginReferal'}, "show": false},
        {"external": true, "title": "Beregn erhvervsleasing", "url": "https://fleet.opendo.dk/Default.aspx", "show": true},
        {"external": false, "title": "Udfyld formular", "url": {'path': '/pengeinstitutter/dokumenter'}, "show": true},
        {"external": false, "title": "Uddannelse", "url": {'path': '/pengeinstitutter/uddannelse'}, "show": true},
        {"external": false, "title": "Spørgsmål og svar", "url": {'name': 'BankloginDokumenter'}, "show": true},
        // {"external": false, "title": "c", "url": {'name': 'BankloginNews'}, "show": true}
      ],

      loading: false,
      articles: null,

      userName: null,
      bankName: null,
      bankLogo: null,
      bankloginTokenKey: "BankloginToken",
    }
  },
  async beforeMount(){
    const token = await localForage.getItem(this.bankloginTokenKey);
    let show_referral_module = false
    if (token["bank_show_referral_module"] != undefined && token["bank_show_referral_module"]) {
      show_referral_module = true;
    }

    this.links[0]["show"] = show_referral_module;
  },
  created(){
    var th = this;
    th.loggedIn = bankloginData.loggedIn;



    if (!bankloginData.loggedIn){
      this.loading = true;
      bankloginData.recoverFromToken(function(){


        th.isPopupOpenSession = !bankloginData.homePopupClosed;

        th.loggedIn = bankloginData.loggedIn;
        if (bankloginData.pageData){
          th.cmsPageData2 = {"loaded": true, "page": bankloginData.pageData};
        }

      },function(){
        th.loggedIn = bankloginData.loggedIn;
        th.$router.replace({ 'name': "logins" });

      });
    }


  },
  mounted(){
    var th = this;

    if (!this.loading){
      this.authRefresh();
    }

    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {

        this.hero = this.cmsPageData.page.pengeinstitutterr.hero;
        this.popup = this.cmsPageData.page.pengeinstitutterr.popup;
        this.navigation = this.cmsPageData.page.pengeinstitutterr.navigation;
        this.contactsBlock = this.cmsPageData.page.pengeinstitutterr.contact;

        if (this.popup && this.popup.show_popup ){
          this.isPopupOpen = true;
        }


        // this.content.right_side[1].content = this.content.right_side[1].content + this.content.right_side[1].content;
    });
   this.$watch(
      () => this.cmsPageData2,
      (toParams, previousParams) => {
        if (this.cmsPageData2 && this.cmsPageData2.page && this.cmsPageData2.page.home_news){
          // console.log(this.cmsPageData2.page.home_news);
          // console.log(this.cmsPageData2.page);
          // if (this.cmsPageData2.page.contact){
          //   this.contactsBlock = this.cmsPageData2.page.contact;
          // }
          this.articles = this.cmsPageData2.page.home_news;
        }
      }
    );
    this.$watch(
      () => this.$route,
      (toParams, previousParams) => {
        // console.log(this.$route);
        this.loading = false;
        this.authRefresh();
        // this.prepareActiveTags();
        // this.preparePagiantion();
      }
    );

    localForage.getItem(th.bankloginTokenKey, function(err, token) {
      if (token !== null && token.token){
        th.userName = token.user_name;
        th.bankName = token.bank_name;
        th.bankLogo = token.bank_logo;
      }else{

      }
  });




  },
  beforeDestroy() {

  },
  methods: {
    clickOnPopup: function(e){
      var th = this;
      if (e.target.classList.contains("popup-inner") || e.target.closest(".popup-inner")){

      }else{
        th.closePopup();
      }
    },
    clickClosePopup: function(e){
      e.preventDefault();
      this.closePopup();
    },
    closePopup: function(){
      var th = this;

      bankloginData.closeHomePopup();

      gsap.to(".banklogin-popup", { duration: 0.3, opacity: 0, onComplete: function(){
        th.isPopupOpen = false;
      }});
    },
    authRefresh(){
      var th = this;

      bankloginData.recoverFromToken(function(){
        // th.loggedIn = bankloginData.loggedIn;
        if (bankloginData.pageData){
          th.cmsPageData2 = {"loaded": true, "page": bankloginData.pageData};


        }
      },function(){
        // th.loggedIn = bankloginData.loggedIn;
        // th.$router.replace({ 'name': "Banklogin" });
      });
    },

    onCloseBottomBar: function(e){
      var th = this;
      this.openBotomBar = false;
    },



  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


.hero-content{
  background: rgba(105, 189, 182, 0.09);
  border-radius: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 0px;
  padding-top: 48px;
  padding-bottom: 48px;

  hr.line{
    margin-top: 62px;
    margin-bottom: 68px;
    display: block;
    border: none;
    height: 1px;
    width: 100%;
    background: #C7E5E4;
  }
  .bank-logo{
    margin-bottom: 8px;
    max-width: 200px;
    width: 100%;
    max-height: 48px;
    height: 48px;
    img{
      object-fit: contain;
      object-position: 0% 50%;
      height: 100%;
      width: 100%;
    }
  }
}
.hero-textual-content{
  margin-top: 80px;
  margin-bottom: 120px;
}
.hero-textual{
  text-align: center;
  max-width: 710px;
  margin-left: auto;
  margin-right: auto;

  .hero-text{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #4C6C69;
    margin-top: 0px;
    ::v-deep p{
      margin-top: 0rem;
      margin-bottom: 1.4em;
    }
  }
  .contacts{
    margin-top: 40px;

    a{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1F4743;
    text-decoration: none;
    margin-left: 24px;
    margin-right: 24px;
    display: inline-block;

    .icon{
      display: inline-block;
      vertical-align: top;
      margin-right: 12px;
    }
    .label{
      display: inline-block;
      vertical-align: top;
    }
    }
    .phone{

    }
    .email{

    }
  }
}

.navigation-content{
  margin-top: 80px;
  margin-bottom: 56px;

  .block{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }
  .left-side{
    width: 60%;
    padding-right: 80px;
    box-sizing: border-box;
    padding-top: 64px;
  }
  .right-side{
    width: 40%;
  }

  .card-style{
    padding: 40px;
    padding-top: 12px;
    padding-bottom: 24px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 60px;
  }

  .title{
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #1F4743;
    margin-bottom: 24px;
  }
  .content{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #4C6C69;
  }
  .image{
    border-radius: 20px;
    line-height: 0px;
    img{
      border-radius: 20px;
    }
  }
}

.news-section{
  background: rgba(105, 189, 182, 0.09);
  border-radius: 20px 20px 0px 0px;
  margin: 20px;
  margin-top: 0px;
  margin-bottom: 0px;


  padding-top: 100px;
  padding-bottom: 140px;

  h2{
    text-align: center;
  }

  .button-wrap{
    margin-top: 40px;
    text-align: center;
  }
}



.banklogin-popup{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;

  .bg{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(241, 249, 248, 0.9);
    z-index: 1;
  }
  .popup-content{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    z-index: 10;

    .flex{
      height: 100%
    }
  }
  .close-popup{
    position: absolute;
    display: block;
    top: 28px;
    right: 28px;
    cursor: pointer;
  }
  .popup-inner{
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 32px 64px -24px rgba(31, 72, 68, 0.16);
    border-radius: 20px;
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding: 0px;
    padding-top: 48px;
    padding-bottom: 24px;
    text-align: center;

    .content{
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      color: #4C6C69;

      padding-right: 80px;
      padding-left: 54px;

      ::v-deep p{
        margin-bottom: 1em;

      }
    }
  }
  .button-wrap{
    margin-top: 50px;
  }
}


.benefits-section{
  margin-bottom: 80px;
  margin-top: 80px;
  .container{
    // background: $color-super-light-green;
    margin: 0px;
    background: rgba(105, 189, 182, 0.09);
    border-radius: 20px;  
    padding: 60px;
    padding-top: 80px;
    padding-bottom: 32px;
    padding-right: 40px;

    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .big-title{
    max-width: 283px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #1F4743;
    width: 283px;
  }
  .benefits{
    width: 100%;
    width: calc(100% - 283px);
    .benefit{
      width: 50%;
      padding-left: 65px;
      padding-right: 20px;
      box-sizing: border-box;
      margin-bottom: 48px;
    }
    .title{
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: #1F4743;
      margin-bottom: 16px;
    }
    .content{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      color: #1F4743;
    }
    .content ::v-deep p{
      margin-top: 16px;
    }
    a{
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 135%;
      /* or 22px */

      /* Opendo/Brand darkest */
      color: #1F4743;

    }
  }
}
@media (max-width: 1000px) {
  .benefits-section{

    .container{
      display: block;
      padding-bottom: 60px;
    }
    .big-title{
      max-width: none;
      margin-bottom: 40px;
      width: auto;
    }
    .benefits{
      width: 100%;
      .benefit{
        padding-right: 56px;
        padding-left: 0px;
      }
      .title{
      
      }
      .content{
      
      }
      .content ::v-deep p{
      
      }
    }
  }
}
@media (max-width: 968px) {

}

@media (max-width: 668px) {

}

@media (max-width: 768px) {
  .navigation-content{
    .block{
      display: block;
    }
    .left-side{
      width: 100%;
      padding: 0px;
    }
    .right-side{
      width: 100%;
      padding: 0px;
      display: none;
    }
  }
}

@media (max-width: #{$screen-mobile}) {
  .hero-textual-content{
    margin-top: 40px;
    margin-bottom: 64px;
  }
  .hero-textual{
    .hero-text{
      font-size: 16px;
      line-height: 130%;
      ::v-deep p{

      }
    }
  }
  .navigation-content{
    margin-top: 64px;
    .block{
      display: block;
    }
    .left-side{
      width: 100%;
      padding: 0px;
      .title{
        font-size: 32px;
      }
      .content{
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
      }
    }
  }
  .news-section{
    padding-top: 40px;
    padding-bottom: 64px;
    .wrap{
      padding: 0px;
    }
    .h2-1{
      margin-bottom: 12px;
    }
  }

  .benefits-section{

    .container{
      display: block;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 40px;
    }
    .big-title{
      max-width: none;
      margin-bottom: 0px;
      width: auto;

      font-weight: 700;
      font-size: 28px;
      line-height: 120%;
    }
    .benefits{
      display: block;
      .benefit{
        padding-right: 0px;
        margin-top: 40px;
        width: 100%;
      }
      .title{
      
      }
      .content{
      
      }
      .content ::v-deep p{
      
      }
    }
  }
}

@media (max-width: 1000px) {

}

@media (max-width: #{$screen-mobile}) {

}

</style>
