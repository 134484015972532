<template>

  
  <div class="scroll-bar" :style="!visible ? 'display: none;' : ''" ref="slider">
    <div class="scroller"  :style="'width: '+percentsWidth+'%; transform: translateX('+scrollLeft+'px)'"></div>
  </div>

</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';

export default {
  props: ['elem'],
  components: {
    
  },
  data() {
    return {
      width: 0,
      height: 0,
      visible: true,
      percentsWidth: 100,
      scrollLeft: 0,
      selectedBar: false,
      offsetX: 0,
      offsetXFirst: 0,
      scrollLeftFirst: 0,
    }
  },

  mounted() {
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
    this.elem.removeEventListener("scroll", this.scrollFunction, false);

    this.$refs.slider.removeEventListener("mousedown", this.onMouseDownDot);
    this.$refs.slider.removeEventListener("touchstart", this.onMouseDownDot);
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("touchmove", this.onMouseMove);
    window.removeEventListener("mouseup", this.onMouseUp);
    window.removeEventListener("touchend", this.onMouseUp);
    window.removeEventListener("resize", this.onWindowResize);

  },
  beforeMount(){
    
  },
  mounted(){
    window.addEventListener('resize', this.resize);
    if (this.elem){
      this.$nextTick(function(){
        this.prepareScrollBar();
      });
    }


    this.$refs.slider.addEventListener("mousedown", this.onMouseDownDot);
    this.$refs.slider.addEventListener("touchstart", this.onMouseDownDot);
    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("touchmove", this.onMouseMove);
    window.addEventListener("mouseup", this.onMouseUp);
    window.addEventListener("touchend", this.onMouseUp);
    window.addEventListener("resize", this.onWindowResize);  


    

  },
  methods: {
    
    resize() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;  


      this.visible = this.isHScrollable();

      this.percentsWidth = this.percentsWidthCalc();

      this.scrollFunction(null);

    },
    isHScrollable () {
      if (this.elem){
        
        return this.elem.scrollWidth-1 > this.elem.clientWidth;
      }
      return false;
    },
    percentsWidthCalc () {
      if (this.elem){
        // return (this.$refs.slider.clientWidth) / this.elem.scrollWidth *100;

        

        return ( this.elem.clientWidth ) / this.elem.scrollWidth * 100;
        // this.$refs.slider.offsetLeft
      }
      return 0;
    },
    scrollFunction(e){
      var a = this.$refs.slider.clientWidth / this.elem.clientWidth;

      this.scrollLeft = this.elem.scrollLeft * (this.percentsWidth/100 * a);
    },
    prepareScrollBar(){

      if (this.elem){
        this.elem.addEventListener("scroll", this.scrollFunction, false);
      }

      this.resize();
    },


    onMouseMove(e){
      if (this.selectedBar){
        this.calculate(e);
      }
    },
    onMouseUp(e){
      this.selectedBar = false;
    },
    calculate(e){
      
      this.offsetX = this.getMouseXFromEvent(e);
      // var percents = ((this.offsetX - this.$refs.slider.offsetLeft)/this.$refs.slider.offsetWidth);
      // if (percents>1) percents = 1;
      // if (percents<0) percents = 0;
      // this.percents = percents*100;
      

      var dif = this.offsetX - this.$refs.slider.offsetLeft- this.offsetXFirst;
      console.log(dif);
      this.elem.scrollLeft = this.scrollLeftFirst+dif;

    },
    onMouseDownDot(e){
      e.preventDefault();
      e.stopPropagation();

      this.selectedBar = true;
      this.offsetXFirst = this.getMouseXFromEvent(e) - this.$refs.slider.offsetLeft;
      this.scrollLeftFirst = this.elem.scrollLeft;
      this.calculate(e);
     
    },
    getMouseXFromEvent: function(e){
      var touch = null;
      if (e.originalEvent && e.originalEvent.touches){
        touch = e.originalEvent.touches[0];
      }
      
      if (touch && touch.pageX){
        return touch.pageX;
      }
      return e.pageX;

    }

  },
  created() {
    var th = this;
    this.$watch(
      () => this.elem,
      (toParams, previousParams) => {
        this.$nextTick(function(){
          this.prepareScrollBar();
        });
      }
    );

    
  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.scroll-bar{
  position: relative;
  display: block;

  box-sizing: border-box;
  transition: all 300ms;
  -webkit-transition: all 300ms;
  margin: 0px;
  height: 2px;
  background: #C7E5E4;
  border-radius: 0px;
  opacity: 1;
  left: auto;
  right: auto;
  width: 100%;
  margin: 0px;

  .scroller{
    display: block;
    height: 24px;
    top: -11px;
    width: 100%;
    position: relative;
    
    border-radius: 10px;
    left: 0;
    

    cursor: pointer;

    &:after{
      content: "";
      display: block;
      height: 2px;
      margin-top: 0px;
      width: 100%;
      position: relative;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      left: 0;
      top: 11px;
    }

  }
}

</style>