import AbAmount from '../../common/ab_amount';
import AbAxios from '../../common/ab_axios_leasing';
import AbGtm from '../../common/ab_gtm';
import AbLightbox from '../../common/ab_lightbox';
import AppHeader from "../../AppHeader.vue";
import { Component } from "vue-property-decorator";
import RevealBlockComponent from '../../shared/revealblock/RevealBlock.vue';
import Tooltipcomponent from '../../shared/tooltip/Tooltip.vue';
import UserData from '../providers/UserData';
import { Vehicle } from '../model/apimodels';
import { VehicleType } from '../model/car';
import Vue from 'vue'
import { isNullOrUndefined } from 'is-what';
import router from '@/router'
import template from './CarDetail.vue'

@Component({
    name: 'leasing-cardetail-component',
    mixins: [template],
    props: ['query'],
    components: {
        AppHeader,
        'reveal-block-component': RevealBlockComponent,
        'tooltip-component': Tooltipcomponent
    }
})
export default class CarDetail extends Vue {

    abAmount = new AbAmount();
    userData: UserData = this.$store.getters.getUserData;
    abGtm = new AbGtm();
    abLightbox = new AbLightbox();
    carIsLoaded: number = 0;

    abAxios = new AbAxios(this.userData.serviceUrl, "application/json; charset=utf-8", this.userData.csrftoken);

    getConsumerVehiclesServiceUrl: string = "/leasing/Consumer/vehicles";
    getReleasingVehiclesServiceUrl: string = "/leasing/Consumer/vehicles?used=true";
    getCorporateVehiclesServiceUrl: string = "/leasing/Corporate/vehicles";
 
    vehicleList: Array<Vehicle> = [];
    gearCheckbox: Array<string> = [];
    fuelCheckbox: Array<string> = [];
    usedCheckbox: Array<string> = [];

    vehicleTitle: string = "";
    vehicleImage: string = "";

    isCorporate: boolean = this.userData.isCorporate;
    isDealer: boolean = this.userData.isDealer;
    isReleasing: boolean = this.userData.isUsed;
    filterLabel: string = this.userData.epiData.step2.filterLabel;
    autmatLabel: string = "Automat Gear"
    manuelLabel: string = "Manuel Gear"
    usedLabel: string = this.userData.epiData.step2.usedCarLabel;
    newLabel: string = this.userData.epiData.step2.newCarLabel;
    fuelLabel: string = this.userData.epiData.step2.fuelLabel;
    milesLiterLabel: string = this.userData.epiData.step2.milesLiterLabel;
    horsepowerLabel: string = this.userData.epiData.step2.horsepowerLabel;
    motorLabel: string = this.userData.epiData.step2.motorLabel;
    doorsLabel: string = this.userData.epiData.step2.doorsLabel;
    energyLabel: string = this.userData.epiData.step2.energyLabel;
    towCapacityLabel: string = this.userData.epiData.step2.towCapacityLabel;
    ncapLabel: string = this.userData.epiData.step2.ncapLabel;
    showMoreLabel: string = this.userData.epiData.showMoreLabel;
    extraEquipmentHeading: string = this.userData.epiData.extraEquipmentHeading;
    btnStyle: string = this.userData.epiData.step2.buttonStyle;
    btnText: string = this.userData.epiData.step2.buttonTekst;

    infoTxt: string = "Vent venligst...";

    //styrer reveal more comp til udstyr
    revealMoreCount: number = 12;



    fuelCheck(e) {
        this.fuelCheckbox = [];
        if (e.target.checked) {
            this.fuelCheckbox.push(e.target.value);
        }
    }

    gearCheck(e) {
        this.gearCheckbox = [];
        if (e.target.checked) {
            this.gearCheckbox.push(e.target.value);
        }
    }

    usedCheck(e) {
        this.usedCheckbox = [];
        if (e.target.checked) {
            this.usedCheckbox.push(e.target.value);
        }
    }

    getVehiclesViaRest(url: string) {
            var self = this;
            
                this.abAxios.abGetWithToken(url, false, function successCallback(response: any) {
                    self.carIsLoaded = 1;
                    if(response.data.data.length === 0) {
                        self.carIsLoaded = 2;
                    }
                    self.handleGetVehicleResponse(response.data);
                }, function errorCallback(error: any) {
                    self.carIsLoaded = 2;
                    if (!isNullOrUndefined(error.response)) {
                        let errorRes = error.response,
                            statusCode = errorRes.status,
                            statusText = errorRes.statusText,
                            errorMsg = "Bilen kunne ikke hentes. Noget gik galt.. " + "[" + statusCode + " - " + statusText + "]";
                        self.abGtm.triggerGtmEvent("fejl", "getVehicles", '[' + self.abGtm.getUtcTimestamp() + '] ' + errorMsg);
                        }
        
                        if (error.response.status == 401) {
                            var url = window.location.href.substring(0, window.location.href.indexOf("#") + 1)
                            url = url + "/log-paa";
                            window.location.href = url;
                            self.abLightbox.standardLightText("Du er ikke længere logget ind.", "<p>Du er blevet logget ud, og bedes derfor logge ind igen.</p>");
                        }
        
                });
            }
            
    handleGetVehicleResponse(response: any) {
        this.infoTxt = "Ingen tilgængelige varianter";

        this.vehicleList = response.data;
        if (this.vehicleList.length > 0) {
            let firstVehicle = this.vehicleList[0];
            this.vehicleTitle = firstVehicle.make.name + " " + firstVehicle.model.name;
            this.vehicleImage = firstVehicle.images[0];
        }
    }

    formatPrice(value: any): any {
        if (value === undefined) {
            return
        }
        return this.abAmount.format(value);
    }

    carSelected(car: Vehicle) {
        router.push({
            name: this.isCorporate ? 'ErhvervCarCalc' : this.isReleasing ? 'ReleasingCarCalc' : this.isDealer ? 'DealerCarCalc' : 'CarCalc',
            params: {
                make: car.make.slug,
                model: car.model.slug,
                vehicleId: car.id.toString()
            }
        })
    }

    showCar(car: Vehicle) {
        this.abLightbox.standardLightText("Billede af releasing bil", "<img src = " + car.images[0]+"  />");
    }

    showShowMoreLabel(item: Vehicle): boolean {
        //standard_equipment kan indeholde tomme værdier i arrayet, så vi er nødt til eksplicit at se om der står noget relevant i arrayet.
        //checker vi ikke på nedenstående, kan der være en "se mere" knap, som blot åbner en tom lightbox.
        let showLabelFlag: boolean = false;

        for (let i = 0; i < item.standard_equipment.length; i++) {
            if (item.standard_equipment[i] != "") {
                showLabelFlag = true;
            }
        }
        return showLabelFlag;
    }


    getValuesAsMarkup(item: Vehicle) {
        let markup: string = "";
        for (let i = 0; i < item.standard_equipment.length; i++) {
            markup += "<div>";
            markup += item.standard_equipment[i];
            markup += "</div>";
            
        }
        return markup;
    }

    showEquipment(item: Vehicle) {
        this.abLightbox.standardLightText(this.userData.epiData.extraEquipmentHeading, "" + this.getValuesAsMarkup(item) + "");
    }

    showModulus(itemNumber) {
        let carsInArrayDividedByFour: number = 0;
        if (itemNumber === 3) {
            carsInArrayDividedByFour = 1;
        }
        else if (itemNumber === 5) {
            carsInArrayDividedByFour = 3;
        }
        else if (itemNumber === 7) {
            carsInArrayDividedByFour = 1;
        }
        else if (itemNumber === 9) {
            carsInArrayDividedByFour = 3;
        }
        else if (itemNumber === 11) {
            carsInArrayDividedByFour = 1;
        }
        else if (itemNumber === 13) {
            carsInArrayDividedByFour = 3;
        }
        else if (itemNumber === 17) {
            carsInArrayDividedByFour = 3;
        }
        else if (itemNumber === 21) {
            carsInArrayDividedByFour = 3;
        }
        else if (itemNumber === 23) {
            carsInArrayDividedByFour = 1;
        }
        else {
            carsInArrayDividedByFour = itemNumber % 4;
        }

        return carsInArrayDividedByFour;
    }

    showVariableLabel() {
        let list = this.filteredList
        return this.userData.epiData.step2.showVariantsLabel.replace("{{count}}",list.length+"")
    }

    getInitialPayment(item: any) {
        let price = this.formatPrice(item.price.downpayment.total);
        let text = this.userData.epiData.step2.initialPaymentText2;
        return text.replace("{{price}}", price + "");
    }

    getTaxValue(item: any) {
        let price = this.formatPrice(item.price.tax.taxable_value);
        let text = this.userData.epiData.taxValueText;
        return text.replace("{{price}}", price + "");
    }

    getMonthlyPayment(item: any) {
        let price = this.formatPrice(item.price.monthly_payment.total);
        let span = '{{price}}'
        span = span.replace("{{price}}", price + "");
        let text = this.userData.epiData.monthlyPaymentText;
        return text.replace("{{price}}", span);
    }

    useTax(item: any) {
        return item.plate_type === VehicleType.HVID_PLADE
    }

    filterList() {
        return this.vehicleList.filter(vehicle => {
            let useFuel: boolean = false;
            let fuel: boolean = false;

            if (this.fuelCheckbox.length > 0) {
                useFuel = true;
                fuel = !isNullOrUndefined(vehicle.fuel_type) && ((vehicle.fuel_type).indexOf(this.fuelCheckbox[0]) !== -1);
            }

            let useGear: boolean = false;
            let gear: boolean = false;

            if (this.gearCheckbox.length > 0) {
                useGear = true;
                gear = !isNullOrUndefined(vehicle.transmission_type) && ((vehicle.transmission_type).indexOf(this.gearCheckbox[0]) !== -1);
            }

            let useUsed: boolean = false;
            let used = false;
            if (this.usedCheckbox.length > 0) {
                if (this.usedCheckbox[0] === "newCar") {
                    used = (vehicle.used === false);
                    useUsed = true
                } else if (this.usedCheckbox[0] === "usedCar") {
                    used = (vehicle.used === true);
                    useUsed = true
                }
                
            }

            return ((useFuel === fuel) && (useGear === gear) && (useUsed === used));
        });
    }

    created() {
        let make = this.$route.params.make;
        let model = this.$route.params.model;
        let used = this.$props.query;

        let finalUrl: string = null;

        if (this.isCorporate) {
            finalUrl = this.getCorporateVehiclesServiceUrl + "?make=" + make + "&model=" + model;
        } else if (this.userData.isUsed || used === 'true') {
            finalUrl = this.getReleasingVehiclesServiceUrl + "&make=" + make + "&model=" + model;
        } else {
            finalUrl = this.getConsumerVehiclesServiceUrl + "?make=" + make + "&model=" + model;
        }

        this.getVehiclesViaRest(finalUrl);
    }

    mounted() {
        let ele = (document.querySelector("#scroll-start") as HTMLElement);
        if (!isNullOrUndefined(ele)) {
            let VueScrollTo = require('vue-scrollto');
            VueScrollTo.scrollTo(ele);
        }
        // Removing mini Form
        this.userData.removeMiniFormFunc(this.userData.epiData.hideElementId);
    }

    get filteredList() {

        return this.filterList();
    }

}
