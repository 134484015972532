import { Vue, Component } from "vue-property-decorator";
import template from "./Proplus.vue"

@Component({
    mixins:[template],
  
})
export default class LeasingPROPLUS extends Vue {

faq = [
    {
        header: 'Hvornår er din bil endelig bestilt?',
        text: 'Når du har konfigureret din firmabil og sendt den til intern godkendelse, har du ikke bestilt bilen. Den bestillingsansvarlige i din virksomhed skal godkende din beregning. Når jeres bestillingsansvarlige godkender, har I anmodet om et leasingforslag på den konfigurerede bil, og vi vender tilbage til dig.'
    },
    {
        header: 'Risikerer jeg at konfigurere en bil som ikke er korrekt konfigureret?',
        text: "Opendo's rådgivere optimerer altid din bil i forhold til jeres Bilpolitik, kampagner, fordelagtige udstyrspakker og evt. i forhold til fordelagtige tilbud og leveringstider. Finder vi mangler eller direkte fejl i dine konfigurationsønsker, vil vi gøre dig opmærksom på det, inden vi sender et leasingforslag til dig."
    },
    {
        header: 'Hvad gør jeg, hvis jeg ønsker bilmodeller eller udstyr, som ikke findes i Leasing Pro+s udstyrsliste?',
        text: 'Vi har valgt det mest efterspurgte og mest økonomiske fordelagtige udstyr på de enkeltstående biler på forhånd. Hvis du ønsker andet udstyr, er du velkomment til at skrive det til os i kommentarfeltet, når du sender din anmodning. Så tilføjer vi det i det leasingforslag, vi sender til dig. (Nogle bilmærker, modeller og udstyr kan være fravalgt af din arbejdsgiver, hvorfor det ikke er tilgængeligt)',
    },
    {
        header: 'Kan jeg selv vælge den forhandler, der skal levere min firmabil?',
        text: 'For at sikre den bedst mulige proces, kundeservice og indkøbspris, der gør de fordelagtige leasingpriser i Leasing Pro+ mulige, vælger Opendo den forhandler, som står for leveringen af din nye firmabil. Vi vælger altid en autoriseret forhandler, tættest på din bopæls- eller firmaadresse. Aftale om leveringssted med forhandleren efter dit ønske er uden merpris.',
    },
    {
        header: 'Hvorfor skal jeg oplyse registreringsnr. på min nuværende firmabil?',
        text: 'Når vi kender registreringsnr. på din nuværende bil, kan vi bedre hjælpe dig, hvis den ønskede leveringsdato på din nye bil ikke kan bekræftes af forhandleren. Så kan vi evt. hjælpe dig med at forlænge aftalen på din nuværende bil, eller hjælpe dig med en lejebil. Hvis din nye bil ikke erstatter en ”gammel” firmabil, behøver du ikke at udfylde feltet med registreringsnr.',
    },
    {
        header: 'Hvad betyder ”Ønsket leveringsdato”?',
        text: '”Ønsket leveringsdato” er den dato, du ønsker at få bilen leveret og stillet til rådighed. Forhandlerens leveringssituation kan variere, så vi kan ikke på forhånd garantere leveringsdatoen.',
    },
    {
        header: 'Hvad betyder TCO?',
        text: 'Total Cost of Ownership (TCO), svarer til alle driftsrelaterede omkostninger til din bil. Typisk leasingafgift, ejerafgift, forsikring, dækaftale, service, vejhjælp og anslået brændstofforbrug.',
    },
]


}