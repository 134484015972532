<template>
  <div v-if="loggedIn">
    <BLHeader/>
    <div class="page-content">
      <div class="wrap fade-on-enter op-fade-in-animation">
        <div class="bank-logo">
          <img :src="bankLogo" :alt="bankName" />
        </div>
        <h1 class="h1-1">Velkommen, {{userName}}</h1>
        <hr class="line"/>
        <div class="form-wrap flex flex-s1 flex-row">
          <div class="left-side">
            <h2>Henvisningsmodul</h2>
            <p>Her kan du henvise dine privatkunder til Opendo</p>
          </div>
          <div class="right-side">
            <form action="" method="POST" v-on:submit="onFormSubmit">
              <div class="form-inner flex flex-s1 flex-row">
                <InputField ref="refName" :name="'ref-name'" :placeholder="'Kundens fulde navn'" :value="''"/>
                <InputField ref="refEmail" :name="'ref-email'" :placeholder="'Kundens e-mail'" :value="''"/>
                <input :class="'button'" type="submit" name="" value="Send" />
                
              </div>
              <p v-if="mailchimpResponseVisible" :class="'mailchimp-response '+mailchimpResponseType">{{mailchimpResponse}}</p>
            </form>



    <!-- Begin Mailchimp Signup Form -->
    
    
<div id="mc_embed_signup_banklogin" style="display: none;">
  
  <form action="https://opendo.us1.list-manage.com/subscribe/post-json?u=b83fc74923d4150ebb2826300&id=4a59a0a66c&f_id=000a78e2f0&c=?" method="post" id="mc-embedded-subscribe-form-banklogin" name="mc-embedded-subscribe-form-banklogin" class="validate" target="_blank" novalidate>
      <div id="mc_embed_signup_banklogin_scroll">
      
          <input type="hidden" name="u" value="b83fc74923d4150ebb2826300">
          <input type="hidden" name="id" value="4a59a0a66c">
          <input type="hidden" name="f_id" value="000a78e2f0">

      <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
  <div class="mc-field-group">
    <label for="mce-EMAIL">Email  <span class="asterisk">*</span>
  </label>
    <input type="email" :value="refEmail" name="EMAIL" class="required email" id="mce-EMAIL" required>
    <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
  </div>
  <div class="mc-field-group">
    <label for="mce-FNAME">Fornavn </label>
    <input type="text" :value="refName" name="FNAME" class="" id="mce-FNAME">
    <span id="mce-FNAME-HELPERTEXT" class="helper_text"></span>
  </div>
  <div class="mc-field-group">
    <label for="mce-ADVISER">Adviser </label>
    <input type="text" :value="userName" name="ADVISER" class="" id="mce-ADVISER">
    <span id="mce-ADVISER-HELPERTEXT" class="helper_text"></span>
  </div>
  <div class="mc-field-group">
    <label for="mce-BANK">Bank </label>
    <input type="text" :value="bankName" name="BANK" class="" id="mce-BANK">
    <span id="mce-BANK-HELPERTEXT" class="helper_text"></span>
  </div>
    <div id="mce-responses" class="clear">
      <div class="response" id="mce-error-response" style="display:none"></div>
      <div class="response" id="mce-success-response" style="display:none"></div>
    </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
      <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b83fc74923d4150ebb2826300_4a59a0a66c" tabindex="-1" value=""></div>
      <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe-banklogin" class="button"></div>
      <input type="hidden" name="c" value="mailchimpBankloginResponse">  
      </div>
  </form>
</div>

<!--End mc_embed_signup-->



          </div>
        </div>
      </div>
    </div>


    <div v-if="false" class="wrap fade-on-enter op-fade-in-animation steps-content style-2 flex flex-s10 my-flex-wrap flex-row">
      <div class="left-side">
        <div class="image">
          <img v-if="stepsContent.image" :src="stepsContent.image.sizes.large" :alt="stepsContent.image.info ? stepsContent.image.info.alt : ''" />
        </div>
      </div>
      <div class="right-side">
        <h2 class="title" v-if="stepsContent.title">{{stepsContent.title}}</h2>
        <div class="steps-block">
          <div class="item" v-if="stepsContent.steps" v-for="(item, index) in stepsContent.steps" :key="index" >
                <h2 class="step-title">{{item.title}}</h2>
                <p class="description" v-html="item.description"></p>
          </div>
        </div>
      </div>
    </div>

    <div class="wrap fade-on-enter op-fade-in-animation steps-content style-1">
      <h2 class="title" v-if="stepsContent.title">{{stepsContent.title}}</h2>
      <div class="steps-block flex flex-s10 my-flex-wrap flex-row">
        <div class="item" v-if="stepsContent.steps" v-for="(item, index) in stepsContent.steps" :key="index" >
              <div class="icon" v-if="item.icon.inline_svg" v-html="item.icon.inline_svg"></div>
              <div class="icon" v-else-if="item.icon.url">
                <img :src="item.icon.url" alt="icon" />
              </div>
              <h2 class="step-title">{{item.title}}</h2>
              <p class="description" v-html="item.description"></p>
        </div>
      </div>
    </div>


    <div class="wrap fade-on-enter op-fade-in-animation">
      <div class="grid-block flex flex-s6 flex-row">
        <div class="left-side" v-if="content && content.left_side">
          <h2 class="title">{{content.left_side.title}}</h2>
          <div v-html="content.left_side.content"></div>
        </div>

        <div class="right-side" v-if="content && content.right_side">
          <div class="boxes flex flex-s6 flex-row flex-wrap">
            <div class="item" v-for="(item, index) in content.right_side" :key="index" >
              <h2 class="title">{{item.title}}</h2>
              <p v-html="item.content"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    
      
    
    <BottomInfoBar :open="openBotomBar" :text="bottomBarText" @onClose="onCloseBottomBar" />
  </div>


  


</template>
<script>

import * as localForage from "localforage";

import BLHeader from "./BLHeader.vue";
import BottomInfoBar from "@/components/ui/bottom-info-bar/BottomInfoBar.vue";
import InputField from "@/components/ui/input-field/input-field";
import bankloginData from "../BankloginData";

export default {
  components: {
    BottomInfoBar : BottomInfoBar,
    BLHeader : BLHeader,
    InputField: InputField
  },
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      loggedIn: false,
      openBotomBar: false,
      bottomBarText: '',
      content: {},
      stepsContent: {},
      userName: null,
      bankName: null,
      bankLogo: null,
      refName: null,
      refEmail: null,
      mailchimpResponseVisible: false,
      mailchimpResponseType: null,
      mailchimpResponse: null,
      bankloginTokenKey: "BankloginToken",
    }
  },
  beforeMount(){
    
  },
  created(){
    var th = this;
    th.loggedIn = bankloginData.loggedIn;



    if (!bankloginData.loggedIn){
      bankloginData.recoverFromToken(function(){
        th.loggedIn = bankloginData.loggedIn;
      },function(){
        th.loggedIn = bankloginData.loggedIn;
        th.$router.replace({ 'name': "logins" });
      });
    }

    window.addEventListener('mailchimpBankloginVueResponse', this.onMailchimpResponse);
  },
  mounted(){
    var th = this;
    setTimeout(function(){
      // console.log("z");
      th.$watch(() => th.$refs.refName.liveValue,
        (toParams, previousParams) => {
          th.refName = toParams
      });
      th.$watch(() => th.$refs.refEmail.liveValue,
        (toParams, previousParams) => {
          th.refEmail = toParams
      });
      th.refName = th.$refs.refName.liveValue;
      th.refEmail = th.$refs.refEmail.liveValue;

    },1000);
    
    
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {

        this.content = this.cmsPageData.page.pengeinstitutterr.content;

        this.stepsContent = this.cmsPageData.page.pengeinstitutterr.steps;

        // this.content.right_side[1].content = this.content.right_side[1].content + this.content.right_side[1].content;
    });


    localForage.getItem(th.bankloginTokenKey, function(err, token) {
      if (token !== null && token.token){
        th.userName = token.user_name;
        th.bankName = token.bank_name;
        th.bankLogo = token.bank_logo;
      }else{
         
      }
  });


    
    
  },
  methods: {
    onMailchimpResponse: function(e){

      this.loading = false;

      if(window.mailchimpBankloginVueResponse){
        var result = window.mailchimpBankloginVueResponse.result;
        var msg = window.mailchimpBankloginVueResponse.msg;



        // a1@a.com is an invalid email address and cannot be imported.

        if (msg.indexOf("Please enter a value")>=0){
          this.mailchimpResponse = "Please fill out fields";
        }else if (msg.indexOf("This email cannot be added to this list")>=0){
          this.mailchimpResponse = "This email cannot be added to this list";
        }else{
          this.mailchimpResponse = msg;
        }

        this.mailchimpResponseVisible = true;
        
        this.mailchimpResponseType = result;
        // alert(result+" "+msg);
        if (result == "error"){
          
          // this.$refs.emailField.showError(msg);
          // this.success = true;
        }
        if (result == "success"){

          this.mailchimpResponse = "E-mail sendt.  Kunden vil modtage en samtykkemail, hvori der er et link som kunden skal klikke på, såfremt kunden ønsker at acceptere samtykket. Kunden vil derefter blive sendt videre  til vores hjemmeside.";
          this.success = true;
        }
      }
      // alert(window.mailchimpBankloginVueResponse);
      // alert("yas");
    },
    beforeDestroy() {
      window.removeEventListener('mailchimpBankloginVueResponse', this.onMailchimpResponse);
    },
    onCloseBottomBar: function(e){
      var th = this;
      this.openBotomBar = false;
    },

    onFormSubmit: function(e){
      e.preventDefault();

      var button = document.querySelector("#mc_embed_signup_banklogin #mc-embedded-subscribe-banklogin");
      button.click();


      this.refName = this.$refs.refName.liveValue;
      this.refEmail = this.$refs.refEmail.liveValue;

      this.mailchimpResponseVisible = "true";
      this.mailchimpResponseType = "info";
      this.mailchimpResponse = "Sending...";

    }
    
  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.form-wrap{
  .left-side{
    display: inline-block;
    width: 42%;

    h2{
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 130%;
      color: #1F4743;
      margin-bottom: 8px;
    }
    p{
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 133%;
      color: #4C6C69;
    }
    
  }
  .right-side{
    width: 58%;

     .input-field-wrap {
      margin-right: 16px;
    }
  }
}

.page-content{
  background: rgba(105, 189, 182, 0.09);
  border-radius: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-bottom: 100px;

  hr.line{
    margin-top: 62px;
    margin-bottom: 68px;
    display: block;
    border: none;
    height: 1px;
    width: 100%;
    background: #C7E5E4;
  }
  .bank-logo{
    margin-bottom: 8px;
    max-width: 200px;
    width: 100%;
    max-height: 48px;
    height: 48px;
    img{
      object-fit: contain;
      object-position: 0% 50%;
      height: 100%;
      width: 100%;
    }
  }
}

.grid-block{
  margin-top: 80px;
  margin-bottom: 80px;
  .left-side{
    width: 45%;
    max-width: 435px;
    padding-right: 48px;
    box-sizing: border-box;
  
    .title{
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 130%;
      color: #1F4743;
      margin-bottom: 22px;
      max-width: 300px;
    }
    ::v-deep p{
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 133%;
      color: #4C6C69;
    }
  }
  .right-side{
    width: 55%;
  }
  .boxes{
    .item{
      width: calc(50% - 24px);
      margin-bottom: 48px;
      // display: inline-block;
      // vertical-align: top;
      .title{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 130%;
        color: #1F4743;
        margin-bottom: 8px;
      }
      p{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        color: #4C6C69;
      }
    }
  }
}


.steps-content.style-1{
  margin-top: 80px;
  margin-bottom: 100px;
  .title{
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    text-align: center;
    color: #1F4743;
    margin-bottom: 48px;
  }
  .steps-block{
    .item{
      background: rgba(105, 189, 182, 0.09);
      border-radius: 20px;
      width: calc(33.33% - 13px);
      margin-bottom: 20px;
      padding: 48px;
      text-align: center;
      .icon{
        margin-bottom: 32px;
      }
      .step-title{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 130%;
        color: #1F4743;
        margin-bottom: 8px;
      }
      .description{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        color: #4C6C69;
      }
    }
  }
}


.steps-content.style-2{
  margin-top: 80px;
  margin-bottom: 120px;

  .left-side{
    width: 35%;
    .image{
      border-radius: 20px;
      overflow: hidden;
      width: 100%;
      img{
        border-radius: 20px;
        width: 100%;
      }
    }
  }
  .right-side{
    width: 65%;
    padding-left: 123px;
    box-sizing: border-box;
  }
  .title{
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #1F4743;
    margin-bottom: 48px;
    max-width: 386px;
  }
  .steps-block{
    .item{
      width: 100%;
      // margin-bottom: 20px;
      max-width: 466px;
      margin-top: 40px;
      .step-title{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 130%;
        color: #1F4743;
        margin-bottom: 8px;
      }
      .description{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        color: #4C6C69;
      }
    }
  }
}

.mailchimp-response{
  &.error{
    color: #DF4343;
  }
  &.success{
    color: #69BDB6;
  }
  &.info{
    color: #69BDB6;
  }
  max-width: 570px;
  font-size: 12px;
  padding-left: 4px;
  display: block;
  text-align: left;
  margin-top: 4px;
}

@media (max-width: 968px) {

  .steps-content.style-2{
    .left-side{
      width: 40%;
    }
    .right-side{
      width: 60%;
      padding-left: 48px;
    }
  }
}

@media (max-width: 668px) {

  .steps-content.style-2{
    display: block;
    .left-side{
      width: 100%;
      max-width: 400px;
      margin-bottom: 48px;
    }
    .right-side{
      width: 100%;
      padding-left: 0px;
    }
  }
}


@media (max-width: 768px) {
  .steps-content.style-1{
    .steps-block{
      // display: block;
      .item{
        width: 50%;
        width: calc(50% - 20px);
        // max-width: 400px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
@media (max-width: #{$screen-mobile}) {
  .steps-content.style-1{
    .steps-block{
      // display: block;
      .item{
        width: 100%;
      }
    }
  }
}




@media (max-width: 1000px) {
  .grid-block{
    display: block;
    .left-side{
      width: 100%;
      max-width: 600px;
      margin-bottom: 48px;
    }
    .right-side{
      width: 100%;
    }
  }

  .form-wrap{
    display: block;

    .left-side{
      width: 100%;
      margin-bottom: 48px;
    }
    .right-side{
      width: 100%;
    }
  }

}




@media (max-width: #{$screen-mobile}) {
  .grid-block{
    .right-side{
      .boxes{
        .item{
          width: 100%;
          padding: 0px;
        }
      }
    }
  }

  .form-wrap{
    .left-side{
      width: 100%;
      margin-bottom: 48px;
    }
    .right-side{
      
      .form-inner{
        display: block;
      }
      .input-field-wrap{
        display: block;
        margin: 0px;
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }
}

</style>