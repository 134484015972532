import Vue from "vue";

Vue.component("BilerBlock", () =>
    import(
        /* webpackChunkName: "BilerBlock" */ "@/components/leasingpro/components/overview/leasing/components/bilerBlock/BilerBlock.vue"
    )
);
Vue.component("FlaadeBlock", () =>
    import(
        /* webpackChunkName: "FlaadeBlock" */ "@/components/leasingpro/components/overview/leasing/components/flaadeBlock/FlaadeBlock.vue"
    )
);
Vue.component("RapporterBlock", () =>
    import(
        /* webpackChunkName: "RapporterBlock" */ "@/components/leasingpro/components/overview/leasing/components/rapporterBlock/RapporterBlock.vue"
    )
);
Vue.component("ProgressChartBlock", () =>
    import(
        /* webpackChunkName: "ProgressChartBlock" */ "@/components/leasingpro/components/overview/leasing/components/progressChartBlock/ProgressChartBlock.vue"
    )
);
Vue.component("SpreadSheetBlock", () =>
    import(
        /* webpackChunkName: "SpreadSheetBlock" */ "@/components/leasingpro/components/overview/leasing/components/spreadSheetBlock/SpreadSheetBlock.vue"
    )
);
// Vue.component("PieChartBlock", () =>
//     import(
//         /* webpackChunkName: "PieChartBlock" */ "@/components/leasingpro/components/overview/leasing/components/pieChartBlock/PieChartBlock.vue"
//     )
// );
Vue.component("LeasingAdminTableBlock", () =>
    import(
        /* webpackChunkName: "LeasingAdminTableBlock" */ "@/components/leasingpro/components/overview/leasing/components/leasingAdminTableBlock/LeasingAdminTableBlock.vue"
    )
);
Vue.component("LeasingCarTableBlock", () =>
    import(
        /* webpackChunkName: "LeasingCarTableBlock" */ "@/components/leasingpro/components/overview/leasing/components/leasingCarTableBlock/LeasingCarTableBlock.vue"
    )
);

Vue.component("Co2Block", () =>
    import(
        /* webpackChunkName: "Co2Block" */ "@/components/leasingpro/components/overview/leasing/components/co2Block/Co2Block.vue"
    )
);

Vue.component("LeasingFuelTableBlock", () =>
    import(
        /* webpackChunkName: "LeasingFuelTableBlock" */ "@/components/leasingpro/components/overview/leasing/components/leasingFuelTableBlock/LeasingFuelTableBlock.vue"
    )
);

Vue.component("LeasingKmTableBlock", () =>
    import(
        /* webpackChunkName: "LeasingKmTableBlock" */ "@/components/leasingpro/components/overview/leasing/components/leasingKmTableBlock/LeasingKmTableBlock.vue"
    )
);

// Vue.component("ChartBlock", () =>
//     import(
//         /* webpackChunkName: "ChartBlock" */ "@/components/leasingpro/components/overview/leasing/components/chartBlock/ChartBlock.vue"
//     )
// );

Vue.component("ApiPendingComponent", () =>
    import(
        /* webpackChunkName: "ApiPendingComponent" */ "@/components/leasingpro/components/overview/leasing/components/apiPendingComponent/ApiPendingComponent.vue"
    )
);
