<template>
  <span class="info-button" v-if="info" v-on:mouseover="hover = true" v-on:mouseleave="hover = false">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 8C16 12.4114 12.4113 16 8 16C3.58865 16 0 12.4113 0 8C0 3.58865 3.58865 0 8 0C12.4113 0 16 3.58849 16 8ZM14.6285 8C14.6285 4.34278 11.657 1.37143 7.99992 1.37143C4.34269 1.37143 1.37135 4.3429 1.37135 8C1.37135 11.6572 4.34282 14.6286 7.99992 14.6286C11.6571 14.6514 14.6285 11.6571 14.6285 8Z" fill="#69BDB6"/>
      <path d="M7.99984 6.83447C7.61129 6.83447 7.31409 7.13166 7.31409 7.52023V11.0174C7.31409 11.4059 7.61128 11.7031 7.99984 11.7031C8.38841 11.7031 8.6856 11.4059 8.6856 11.0174V7.52023C8.6856 7.13168 8.38841 6.83447 7.99984 6.83447Z" fill="#69BDB6"/>
      <path d="M7.99985 4.31983C7.81697 4.31983 7.63409 4.38839 7.51976 4.52551C7.38265 4.63982 7.31409 4.8227 7.31409 5.00542C7.31409 5.05118 7.31409 5.09678 7.33689 5.14254C7.33689 5.1883 7.35969 5.2339 7.38265 5.27966C7.40545 5.32542 7.4284 5.34822 7.4512 5.39398C7.474 5.43974 7.49696 5.46254 7.54256 5.5083C7.794 5.75973 8.25109 5.75973 8.50256 5.5083C8.52536 5.4855 8.57112 5.43974 8.59392 5.39398C8.61672 5.34822 8.63968 5.32542 8.66248 5.27966C8.68528 5.2339 8.68528 5.1883 8.70824 5.14254C8.70824 5.09678 8.73104 5.05118 8.73104 5.00542C8.73104 4.82255 8.66248 4.63967 8.52536 4.52534C8.3656 4.38838 8.18273 4.31982 7.99985 4.31982L7.99985 4.31983Z" fill="#69BDB6"/>
    </svg>
    <span ref="infoElem" class="info-text" :style="' transform: translateX('+shift+'px); -webkit-transform: translateX('+shift+'px) '">{{info}}</span>
  </span>
</template>
<script>

export default {
  props: ['info'],
  data() {
    return {
      hover: false,
      shift: 0,
    }
  },
  beforeMount(){
  },
  mounted(){
    this.$watch(
      () => this.hover,
      (toParams, previousParams) => {
        if (this.hover){
          let left = this.getElementLeft(this.$refs.infoElem);
          let right = window.innerWidth - (left + this.$refs.infoElem.offsetWidth);
          this.shift = 0;
          if (left < 10){
            this.shift = -(left-10);
          }
          if (right < 10){
            this.shift = (right-10);
          }
        }
      }
    )
  },
  created(){
  },
  methods: {
    init: function(){
    },
    getElementLeft: function (element) {
      let left = element.offsetLeft;
      let parent = element.offsetParent;
      while (parent != null) {
        left += parent.offsetLeft;
        parent = parent.offsetParent;
      }
      return left;
    },
    getElementRight: function (element) {
      let left = element.offsetLeft;
      let parent = element.offsetParent;
      while (parent != null) {
        left += parent.offsetLeft;
        parent = parent.offsetParent;
      }
      return left;
    }
    
    
  },
 
}
</script>
<style lang="scss">
@import "~@/styles/variables.scss";

.info-button, .info-button-corporate {
  display: inline-block;
  vertical-align: top;
  position: relative;
  cursor: pointer;

  path{
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .info-text{
    z-index: 100;
    line-height: 1.2rem;
    display: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #FFFFFF;

    background: #1F4743;
    border-radius: 4px;
    padding: 12px 22px;
    padding-bottom: 16px;
    box-sizing: border-box;
    
    position: absolute;
    top: 28px;
    width: 293px;
    left: 50%;
    margin-left: -146px;

    white-space: normal;
  }
  &:hover{
    path{
      fill: #1F4743;
    }
    .info-text{
      display: block;
    }
  }
}

.info-button-corporate {
  margin-left: 5px;
  margin-top: 10px;
}
</style>