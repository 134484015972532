import { Component, Vue } from 'vue-property-decorator';

import template from './InputField.vue';

@Component({
  mixins: [template],
  props: ['theme', 'size', 'name', 'value', 'placeholder', 'type', 'customClass']
})
export default class InputField extends Vue {

}