<template>
    <div>
        <div class="upload secondary-font__regular">

            <div style="padding: 0px 0px 20px">
                <div class='container' v-for="(file) in files" :key="file.id">
                    <div style='float: left;' class="align_center">
                        <img src="/ui/assets/svg/document_darkblue.svg" style="width:16px" />
                        <span>{{file.name}}</span>
                        <!--
    <span>{{file.size}} KB</span>
    <span v-if="file.error">{{file.error}}</span>
    <span v-else-if="file.success">Success</span>
    <span v-else-if="file.active">Aktiv</span>
    <span v-else></span>
                            -->
                    </div>
                    <div style='float: right;'>
                        <a href="#" @click.prevent="remove(file)" class="url__link">
                            <span>Fjern</span>
                        </a>
                    </div>
                </div>
            </div>

            <div class='container'>
                <div style='float: left;'>
                    <file-upload class="button__primary__blue__outline"
                        :post-action="uploadPostUrl"
                        extensions="gif,jpg,jpeg,png,webp,pdf"
                        accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
                        :multiple="true"
                        :size="1024 * 1024 * 100"
                        v-model="files"
                        @input-filter="inputFilter"
                        @input-file="inputFile"
                        ref="upload">
                        <span class="iconLink__attach--dark py-1">{{uploadBtnText}}</span>
                    </file-upload>
                </div>
                <div style='float: right;'>
                    <span v-if="showSendButton">
                        <button type="button" class="button__primary__blue__outline" v-if="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true">
                            {{sendBtnText}}
                        </button>
                        <button type="button" class="button__primary__blue__outline" v-else @click.prevent="$refs.upload.active = false">
                            Stop Upload
                        </button>
                    </span>
                </div>
            </div>

        </div>
    </div>
</template>

<script lang="ts">

    import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from "vue-property-decorator";
    import FileUpload from 'vue-upload-component';    //docs https://github.com/lian-yue/vue-upload-component

    @Component({
        name: 'simple-upload-component',
        components: {
            FileUpload
        }
    })

    export default class SimpleUpload extends Vue {

        @Prop() uploadPostUrl: string;
        @Prop() uploadBtnText: string;
        @Prop() sendBtnText: string;
        @Prop() showSendButton: boolean;

        @Provide() files: any[] = [];

        inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                // Before adding a file
                // Filter system files or hide files
                if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                    return prevent()
                }
                // Filter php html js file
                if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                    return prevent()
                }
            }

            /* Create a blob field 
            newFile.blob = ''
            let URL = window.URL //|| window.webkitURL
            if (URL && URL.createObjectURL) {
                newFile.blob = URL.createObjectURL(newFile.file)
            }*/
        }

        inputFile(newFile, oldFile) {
            if (newFile && !oldFile) {
                // add
                //console.log('add', newFile)
            }
            if (newFile && oldFile) {
                // update
                //console.log('update', newFile)
            }
            if (!newFile && oldFile) {
                // remove
                //console.log('remove', oldFile)
            }
        }

        remove(file) {
            (this.$refs.upload as any).remove(file)
        }

        @Watch('files', {
            immediate: false,
            deep: true
        })
        filesChanged(newValue: any, oldValue: any) {
            if (newValue === undefined || newValue === oldValue) {
                return;
            }
            this.$emit('uploadChanged', newValue);
        }
    }

</script>


<style lang="scss" scoped>
    @import "../../../abdk/styles/legacy/button.scss";
    @import "../../../abdk/styles/legacy/link.scss";
    @import "../../../abdk/styles/legacy/font.scss";

    .container {
        width: 100%;
        display: inline-block;
        white-space: nowrap;
        margin: 0px auto;
    }
</style>
