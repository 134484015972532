<template>
<div v-if="
    (GetCurrentRouteName !== 'leasingpro' || (GetCurrentRouteName === 'leasingpro' && !leasingProUserLoggedIn)) &&
    (GetCurrentRouteName !== 'BankloginHome' && GetCurrentRouteName !== 'BankloginReferal' && GetCurrentRouteName !== 'BankloginVarebiler' && GetCurrentRouteName !== 'BankloginDokumenter' && GetCurrentRouteName !== 'BankloginDynamic' && GetCurrentRouteName !== 'BankloginNews' && GetCurrentRouteName !== 'BankloginNewsSingle'  )
    " :class="'site-header '+siteHeaderOpenClass+''+(forhandlerLoggedIn ? ' dealer-header ' : '') + (GetCurrentRouteName === 'debitorskifte' || GetCurrentRouteName === 'indfrielsestilbud' || GetCurrentRouteName === 'forlaengelse' ? 'header-hidden' : '')  " :style="'margin-top:'+bannerHeight+'px'">
    <div class="wrap">

      <div class="flex my-flex-nowrap flex-s1">
        <div class="left-side flex my-flex-nowrap flex-s1">
          <router-link :to="homeURL" class="logo-wrap" >
            <img :src="require('@/assets/logo.svg')" class="logo" alt="" />
          </router-link>

          <div v-if="have_switch" v-bind:class="[ 'switch',  { 'selected': switchSelected } ]">

            <router-link :class="menu.selectedClass" v-for="menu in switch_menu" :key="'switch-menu-item-'+menu.link.url" :to="menu.link.url" >{{menu.link.title}}</router-link>

          </div>
        </div>

        <div class="right-side flex my-flex-nowrap flex-s1">
          <nav class="navigation">


            <template v-if="forhandlerLoggedIn">
              <span class="item" v-for="menu in main_menu" :key="'menu-item-'+menu.item.url"  :class="(menu.submenu ? ' has-submenu' : '') + (menu.hovered ? ' hovered' : '')">
                <span v-if="(menu.item.url!=='/privat/faq' && menu.item.url!=='/privat/faq')">
                  <router-link class="nav-item" v-if="!menu.item.popupLink" :to="menu.item.url.indexOf('showroom')>=0 ? '/privat/leasing/forhandler' : menu.item.url" >{{menu.item.title}}</router-link>
                  <a :href="menu.item.url" v-else class="nav-item popup-link" >{{menu.item.title}}</a>
                </span>
              </span>
            </template>
            <template v-else>
              <span class="item" v-for="menu in main_menu" :key="'menu-item-'+menu.item.url" :class="(menu.submenu ? ' has-submenu' : '') + (menu.hovered ? ' hovered' : '')"  @mouseover="menuItemMouseOver(menu, $event)" @mouseleave="menuItemMouseLeave(menu, $event)" v-on:click.capture="menuItemClick(menu, $event)" >

                <span>
                  <router-link class="nav-item" v-if="!menu.item.popupLink" :to="menu.item.url" >{{menu.item.title}}</router-link>
                  <a :href="menu.item.url" v-else class="nav-item popup-link"  >{{menu.item.title}}</a>

                  <svg class="submenu-icon" v-if="menu.submenu" xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6" fill="none">
                    <path d="M10 1L5.75892 4.91314C5.72644 4.94045 5.68658 4.96231 5.64194 4.97729C5.59731 4.99226 5.54893 5 5.5 5C5.45107 5 5.40269 4.99226 5.35806 4.97729C5.31342 4.96231 5.27355 4.94045 5.24108 4.91314L1 1" stroke="#4C6C69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
                <div class="dropdown" v-if="menu.submenu">
                  <div class="dropdown-inner">
                    <span class="item" v-for="menu2 in menu.submenu" :key="'menu-2-item-'+menu2.item.url">
                      <span>
                        <router-link class="nav-item" v-if="!menu2.item.popupLink" :to="menu2.item.url" >{{menu2.item.title}}</router-link>
                        <a :href="menu2.item.url" v-else class="nav-item popup-link" >{{menu2.item.title}}</a>
                      </span>
                    </span>
                  </div>
                </div>

              </span>
            </template>
            <span class="item heart-item" v-if="this.switchState == 'privat'">
              <span>
                <router-link :class="'nav-item'" :to="'/privat/liked'" >
                  <svg class="heart-icon" width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0004 19L2.47805 9.85662C1.72905 9.08675 1.23469 8.09383 1.0651 7.0187C0.8955 5.94356 1.05928 4.84079 1.5332 3.86679C1.89057 3.13177 2.41261 2.49462 3.05629 2.00784C3.69998 1.52105 4.4469 1.19857 5.23551 1.06695C6.02411 0.935336 6.83184 0.998357 7.59212 1.25082C8.35241 1.50329 9.0435 1.93797 9.60845 2.51906L11.0004 3.95L12.3923 2.51906C12.9573 1.93797 13.6484 1.50329 14.4087 1.25082C15.169 0.998357 15.9767 0.935336 16.7653 1.06695C17.5539 1.19857 18.3008 1.52105 18.9445 2.00784C19.5882 2.49462 20.1102 3.13177 20.4676 3.86679C20.9409 4.84042 21.1044 5.94253 20.935 7.01705C20.7655 8.09157 20.2718 9.08403 19.5236 9.85388L11.0004 19Z" fill="transparent" stroke="#1F4743" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <span v-if="likedCarIDsPrivat.length>0" class='heart-count'>{{likedCarIDsPrivat.length}}</span>
                </router-link>
              </span>
            </span>
            <span class="item heart-item" v-if="this.switchState == 'erhverv'">
              <span>
                <router-link :class="'nav-item'" :to="'/erhverv/liked'" >
                  <svg class="heart-icon" width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0004 19L2.47805 9.85662C1.72905 9.08675 1.23469 8.09383 1.0651 7.0187C0.8955 5.94356 1.05928 4.84079 1.5332 3.86679C1.89057 3.13177 2.41261 2.49462 3.05629 2.00784C3.69998 1.52105 4.4469 1.19857 5.23551 1.06695C6.02411 0.935336 6.83184 0.998357 7.59212 1.25082C8.35241 1.50329 9.0435 1.93797 9.60845 2.51906L11.0004 3.95L12.3923 2.51906C12.9573 1.93797 13.6484 1.50329 14.4087 1.25082C15.169 0.998357 15.9767 0.935336 16.7653 1.06695C17.5539 1.19857 18.3008 1.52105 18.9445 2.00784C19.5882 2.49462 20.1102 3.13177 20.4676 3.86679C20.9409 4.84042 21.1044 5.94253 20.935 7.01705C20.7655 8.09157 20.2718 9.08403 19.5236 9.85388L11.0004 19Z" fill="transparent" stroke="#1F4743" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <span v-if="likedCarIDsErhverv.length>0" class='heart-count'>{{likedCarIDsErhverv.length}}</span>
                </router-link>
              </span>
            </span>
          </nav>



          <div class="login-button-wrap" v-if="forhandlerLoggedIn">
              <a class="login-button login-button-style noselect" v-on:click="logoutDealerClick" href="#"><div class="icon"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.6654 6.66602H4.33203C3.77974 6.66602 3.33203 7.11375 3.33203 7.66602V12.9993C3.33203 13.5516 3.77974 13.9993 4.33203 13.9993H11.6654C12.2176 13.9993 12.6654 13.5516 12.6654 12.9993V7.66602C12.6654 7.11375 12.2176 6.66602 11.6654 6.66602Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <rect x="7" y="9" width="2" height="2" rx="1" fill="currentColor"/>
              <path d="M10 6.58333H10.75V5.83333V4.5C10.75 2.98122 9.51878 1.75 8 1.75C6.48122 1.75 5.25 2.98122 5.25 4.5V5.83333V6.58333H6H10Z" stroke="currentColor" stroke-width="1.5"/>
              </svg>
              </div>Log ud</a>
          </div>
          <div class="login-button-wrap" v-else>
            <router-link to="/log-paa" class="login-button login-button-style noselect"><div class="icon"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.6654 6.66602H4.33203C3.77974 6.66602 3.33203 7.11375 3.33203 7.66602V12.9993C3.33203 13.5516 3.77974 13.9993 4.33203 13.9993H11.6654C12.2176 13.9993 12.6654 13.5516 12.6654 12.9993V7.66602C12.6654 7.11375 12.2176 6.66602 11.6654 6.66602Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <rect x="7" y="9" width="2" height="2" rx="1" fill="currentColor"/>
              <path d="M10 6.58333H10.75V5.83333V4.5C10.75 2.98122 9.51878 1.75 8 1.75C6.48122 1.75 5.25 2.98122 5.25 4.5V5.83333V6.58333H6H10Z" stroke="currentColor" stroke-width="1.5"/>
              </svg>
              </div>{{login_button_title}}</router-link>
          </div>


        </div>

        <div class="menu-burger-wrap">
          <span class="menu-burger noselect" v-on:click="onMenuBurgerClick">
            <span class="l1"></span>
            <span class="l2"></span>
            <span class="l3"></span>
            <span class="label">Menu</span>
          </span>
        </div>
      </div>
    </div>


</div>
</template>

<script>
import * as localForage from "localforage";
import eventBus from '../../../eventBus';
import router from '@/router';

const StorageType = {
    USERNAME : "leasing_userName",
    CUST_TYPE : "leasing_cust_type",
    CUST_SPEC : "leasing_cust_spec",
    TOKEN : "token",
    LOGIN_REQUIRED : "loginRequired"
}

export default {
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      homeURL: "/",
      switchState: "",
      switchSelected: false,

      have_switch: true,
      switch_menu: [{link: {url: "#1", title: ""}}, {link: {url: "#2", title: ""}}],
      main_menu: null,
      main_menu_all: null,
      login_button_title: "Log på",
      logout_button_title: "Log out",
      siteHeaderOpenClass: "",

      likedCarIDs: 0,
      likedCarIDsPrivat: 0,
      likedCarIDsErhverv: 0,

      bannerHeight: 0,
      leasingProUserLoggedIn: false,

      forhandlerLoggedIn: false,

    }
  },
  beforeMount(){
    this.forhandlerLoggedIn = (this.GetCurrentRouteName.indexOf('DealerCar')>=0);
    this.checkDealerLoggedIn();
  },
  beforeDestroy(){

  },
  mounted(){
    this.checkSwitch(this.$route.name);
    this.addSwitchDividers();

    this.checkLoggedIn();

    var th = this;
    this.$nextTick(() => {
      if (document.querySelector(".navigation")){
        document.querySelector(".navigation").addEventListener('click', function (event) {

          // If the clicked element does not have the .click-me class, ignore it
          if (!event.target.matches('.nav-item')) return;
          var href = event.target.getAttribute("href");
            if (href){

              event.preventDefault();
              if (href=="/showroom"){
                event.preventDefault();
                eventBus.$emit('open-ask-popup', {"type": "cars"});
                th.closeMobileMenu();
              }
              if (href=="/faq"){
                event.preventDefault();
                eventBus.$emit('open-ask-popup', {"type": "faq"});
                th.closeMobileMenu();
              }


            }

        });
      }

    });


  },
  methods: {

    async hasLoggedInDealer() {
        return await localForage.getItem(StorageType.TOKEN).then((value) => {
            if (value !== null) {
              return true;
            }
            return false;
        }).catch((err) => {
            console.log(err);
            return false;
        });
    },



    menuItemMouseOver: function(menu, e){
      if (window.innerWidth>1270){
        menu.hovered = true;
        this.filterMenu();
      }
    },
    menuItemMouseLeave: function(menu, e){
      if (window.innerWidth>1270){
        menu.hovered = false;
        this.filterMenu();
      }
    },
    menuItemClick: function(menu, e){

      var go = true;
      if (menu.submenu){
        if (!menu.hovered){
          go = false;
          menu.hovered = true;
          this.filterMenu();
        }
      }

      var url = e.target.getAttribute("href");
      // alert(e.target.hasClass("popup-link"));
      // console.log(this.$router.history.current.path);
      if (go){
        if (!menu.item.popupLink || menu.item.url !== url){
          this.$router.push({path: url});
          // this.$router.push({path: menu.item.url});
        }
      }

      e.preventDefault();
      e.stopPropagation();
    },


    checkDealerLoggedIn: function(){
      this.hasLoggedInDealer().then(value => {
          this.forhandlerLoggedIn = value;
          // this.hasLoggedIn = value;
          // if (value) {
          //     this.userData.getUsername().then(name => {
          //         this.loggedInUser = name;
          //     })
          // }
      });
    },
    logoutDealerClick: function(e){
      e.preventDefault();
      let th = this;
      if (document.querySelector(".log-off-dealer")){
        document.querySelector(".log-off-dealer").click();
      } else {
        Promise.all(
            [
                localForage.removeItem(StorageType.USERNAME),
                localForage.removeItem(StorageType.CUST_TYPE),
                localForage.removeItem(StorageType.CUST_SPEC),
                localForage.removeItem(StorageType.TOKEN)
            ]).then(() => {
            router.push({
                name: 'logins',
            });
            window.location.reload();
        });
      }
    },
    checkLoggedIn: function(){
      var th = this;

      // alert(localForage.getItem(StorageType.TOKEN));
      // alert(store.getters.getUserData.loginRequired);
      // th.loggedIn = true;
      // localForage.getItem(StorageType.TOKEN).then((value) => {
      //   if (value !== null) {
      //     th.loggedIn = true;
      //   }else{
      //     th.loggedIn = false;
      //   }
      // }).catch((err) => {
      //   th.loggedIn = false;
      // });
    },
    onLikedPageClick: function(e){
      e.preventDefault();
      eventBus.$emit('open-ask-popup', {"type": "liked"});
      th.closeMobileMenu();
    },
    navigationClick: function(event){

    },
    set_cookie: function(name, value, days=30, path='/') {
      let expire = new Date()
      expire.setDate(expire.getDate() + days)
      document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value) + '; expires=' + expire.toUTCString() + '; path=' + path;
      try {
        window['do_policy']()
      } catch (e){
        console.log(e)
      }
    },

    get_cookie: function(cname){
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    checkSwitch: function(routeName){
      var key = "menuSwitch";
      var savedSwitchState = sessionStorage.getItem(key);
      var savedSwitchStateCookie = this.get_cookie(key);


      // console.log(routeName);
      if (savedSwitchStateCookie){
        savedSwitchState = savedSwitchStateCookie;
      }

      if (savedSwitchState){
        this.switchState = savedSwitchState;
      }
      if (routeName == "CarCalcErhverv" || routeName == "ErhvervLeasing" || routeName == "ErhvervFAQ" || routeName == "ErhvervCars" || routeName == "LikedCarsErhverv"){
        this.switchState = "erhverv";
        this.switchSelected = true;
        // this.homeURL = "/erhverv";
        this.homeURL = "/";

      } else if (routeName == "CarCalcPrivat" || routeName == "PrivatLeasing" || routeName == "PrivatFAQ" || routeName == "PrivatCars" || routeName == "LikedCarsPrivat" || routeName == "ForhandlerLogin" || routeName == "DealerCarList" || routeName == "ReferalLanding"){
        this.switchState = "privat";
        this.switchSelected = true;
        if (this.switch_menu[0]){
          this.switch_menu[0].selectedClass = "selected";
        }
        // this.homeURL = "/privat";
        this.homeURL = "/";
      }else{
        if (this.switchState != ""){
          this.switchSelected = true;
        }
        // this.switchState = "";
        // this.switchSelected = false;
      }
      sessionStorage.setItem(key, this.switchState);
      this.set_cookie(key, this.switchState);

      if (this.switchState == "erhverv"){
        if (this.switch_menu[1]){
          this.switch_menu[1].selectedClass = "selected";
        }
      }
      if (this.switchState == "privat"){
        if (this.switch_menu[0]){
          this.switch_menu[0].selectedClass = "selected";
        }
      }
      // for(var i=0; i<this.switch_menu.length; i++){
      //   var item = this.switch_menu[i];
      //   if (item.link.url
      // }

    },

    addSwitchDividers: function(){
      var items = document.querySelectorAll(".site-header .switch .divider");
      for (var i=0; i<items.length; i++){
        items[0].remove();
      }
      items = document.querySelectorAll(".site-header .switch a");
      for (var i=1; i<items.length; i++){
        items[i].insertAdjacentHTML('beforebegin','<span class="divider"></span>');
      }
    },

    filterMenu: function(){
      var flteredMenu = [];
      for(var i=0; i<this.main_menu_all.length; i++){
        var item = this.main_menu_all[i];

        if (item && item.item){
          item.ID = i+1;
          if (item.item.privatFAQ || item.item.privatCars){
            if (this.switchState == "privat"){
              flteredMenu.push(item);
            }
            continue;
          }
          if (item.item.erhvervFAQ || item.item.erhvervCars){
            if (this.switchState == "erhverv"){
              flteredMenu.push(item);
            }
            continue;
          }
          if (item.item.choseFAQ || item.item.choseCars){
            if (this.switchState == ""){
              flteredMenu.push(item);
            }
            continue;
          }

          flteredMenu.push(item);
        }
      }
      this.main_menu = flteredMenu;
    },

    clone: function (obj) {
      return JSON.parse(JSON.stringify(obj));
      // let buf; // the cloned object
      // if (obj instanceof Array) {
      //   buf = []; // create an empty array
      //   var i = obj.length;
      //   while (i --) {
      //     buf[i] = obj(obj[i]); // recursively clone the elements
      //   }
      //   return buf;
      // } else if (obj instanceof Object) {
      //   buf = {}; // create an empty object
      //   for (const k in obj) {
      //     if (obj.hasOwnProperty(k)) { // filter out another array's index
      //       buf[k] = this.clone(obj[k]); // recursively clone the value
      //     }
      //   }
      //   return buf;
      // } else {
      //   return obj;
      // }
    },
    onMenuBurgerClick: function(e){
      e.preventDefault();
      if (!this.siteHeaderOpenClass){
        this.openMobileMenu();
      }else{
        this.closeMobileMenu();
      }
    },
    closeMobileMenu: function(e){
      this.siteHeaderOpenClass = "";
      document.querySelector("html").classList.remove("scroll-lock");
      document.querySelector("body").classList.remove("scroll-lock");
    },
    openMobileMenu: function(e){
      this.siteHeaderOpenClass = "open";
      document.querySelector("html").classList.add("scroll-lock");
      document.querySelector("body").classList.add("scroll-lock");
    },
    checkStyling: function(){
      var root = document.getElementsByTagName( 'html' )[0]; // '0' to assign the first (and only `HTML` tag)
      if (this.$route.name == "leasingpro" && this.leasingProUserLoggedIn){
        // root.setAttribute( 'class', 'leasingpro-styling' );
        root.setAttribute( 'class', 'leasingpro-styling-new' );
      }
      else{
        // root.removeAttribute( 'class', 'leasingpro-styling' );
        root.removeAttribute( 'class', 'leasingpro-styling-new' );
      }
    },
    loadLikes: function(){
      var likes = localStorage.getItem('carLikes');
      if (likes){
        this.likedCarIDs = JSON.parse(likes);
      }
      likes = localStorage.getItem('carLikesPrivat');
      if (likes){
        this.likedCarIDsPrivat = JSON.parse(likes);
      }
      likes = localStorage.getItem('carLikesErhverv');
      if (likes){
        this.likedCarIDsErhverv = JSON.parse(likes);
      }
    },

  },
  created() {
    var th = this;

    eventBus.$on("bannerHeight", function(e){
      th.bannerHeight = e;
    });

    eventBus.$on("leasingpro-loggedin", function(e){
      th.leasingProUserLoggedIn = true;
    });

    eventBus.$on("car-likes-update", function(e){
      th.loadLikes();
    });






    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {

        var count = 0;
        if (this.cmsGlobalData.header.switch){
          for(var i=0; i<this.cmsGlobalData.header.switch.length; i++){
            var s = this.cmsGlobalData.header.switch[i];
            if (s.link){
              count++;
            }
          }
        }
        this.have_switch = count>0;

        this.switch_menu = this.cmsGlobalData.header.switch;

        this.checkSwitch(this.$route.name);

        var menu = this.cmsGlobalData.header.menu;
        var flteredMenu = [];
        for(var i=0; i<menu.length; i++){
          var item = menu[i];
          if (item.item.url == "/*/faq"){
            var item1 = th.clone(item);
            item1.item.url = "/privat/faq";
            item1.item.privatFAQ = true;
            flteredMenu.push(item1);
            var item2 = th.clone(item);
            item2.item.url = "/erhverv/faq";
            item2.item.erhvervFAQ = true;
            flteredMenu.push(item2);

            item.item.url = "/faq";
            item.item.choseFAQ = true;
            item.item.popupLink = true;
          }
          if (item.item.url == "/*/showroom"){
            var item1 = th.clone(item);
            item1.item.url = "/privat/showroom";
            item1.item.privatCars = true;
            flteredMenu.push(item1);
            var item2 = th.clone(item);
            item2.item.url = "/erhverv/showroom";
            item2.item.erhvervCars = true;
            flteredMenu.push(item2);

            item.item.url = "/showroom";
            item.item.choseCars = true;
            item.item.popupLink = true;
          }
          if (item.item.url == "/*/liked"){
            var item1 = th.clone(item);
            item1.item.url = "/privat/liked";
            item1.item.privatCars = true;
            flteredMenu.push(item1);
            var item2 = th.clone(item);
            item2.item.url = "/erhverv/liked";
            item2.item.erhvervCars = true;
            flteredMenu.push(item2);

            item.item.url = "/showroom";
            item.item.choseCars = true;
            item.item.popupLink = true;
          }
          flteredMenu.push(item);
        }
        this.main_menu_all = flteredMenu;
        this.filterMenu();

        this.login_button_title = this.cmsGlobalData.header.login_button_title;

        this.$nextTick(() => {
          th.addSwitchDividers();
        })

        this.checkStyling();

        this.loadLikes();

      }
    );




    this.$watch(
      () => this.leasingProUserLoggedIn,
      (toParams, previousParams) => {
        this.checkStyling();
      }
    );

    this.$watch(
      () => this.$route.name,
      (toParams, previousParams) => {

        this.checkSwitch(toParams);

        this.closeMobileMenu();

        this.checkStyling();

        this.loadLikes();

        this.checkLoggedIn();

        this.checkDealerLoggedIn();

        this.leasingProUserLoggedIn = false;

        // alert("change-route- " + this.GetLeasingProUserLoggedIn);
        // gsap.to(document.querySelector(".site-header"), { duration: 0.5, opacity: 0.1});
        // alert(toParams);
      }
    )
  },
}
</script>
<style lang="scss">
@import "~@/styles/variables.scss";

.site-header{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;

  padding-top: 20px;
  padding-bottom: 20px;
  .left-side{

  }
  .right-side{

  }
  .logo-wrap{
    width: 116px;
    max-width: 116px;
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    img{
      width: 100%;
    }
  }
  .login-button{
    margin-left: 64px;
    white-space: nowrap;
  }
  .menu-burger-wrap{
    display: none;
  }
  .menu-burger{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #1F4743;
    padding: 20px;
    padding-top: 11px;
    padding-bottom: 15px;
    padding-left: 52px;
    background: rgba(105, 189, 182, 0.09);
    border-radius: 40px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 44px;
    box-sizing: border-box;
    .label{
      display: inline-block;
      // overflow: hidden;
      width: auto;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      width: 35px;
    }
    .l1,
    .l2,
    .l3{
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      display: block;
      position: absolute;
      left: 20px;
      width: 20px;
      height: 2px;
      background: #1F4743;
      border-radius: 2px;
      top: 15px;
    }
    .l2{
      top: 21px;
    }
    .l3{
      top: 27px;
    }
  }
  .navigation{
    a{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height */
      text-align: center;
      letter-spacing: -0.02em;
      color: rgba(28, 28, 27, 0.7);
      text-decoration: none;

      margin-left: 44px;

      -webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			transition: all 0.3s ease;
      position: relative;

      &:after{
        content: "";
        display: block;
        width: 4px;
        height: 4px;
        background: #69BDB6;
        position: absolute;
        left: 50%;
        margin-left: -2px;
        border-radius: 2px;
        bottom: -14px;
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
      .heart-icon{
        path{
           -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
      }
      &:hover,
      &.selected,
      &.router-link-active{
        color: #1F4743;
        &:after{
          opacity: 1;
          bottom: -9px;
        }
        .heart-icon{
          path{
            fill: #1F4743;
          }
        }
      }
      .heart-count{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1F4743;
        margin-left: 7px;
      }

      &:hover{
        color: #1F4743;
      }
    }


    .item{
      position: relative;
    }
    .submenu-icon{
      display: inline-block;
      vertical-align: top;
      margin-top: 8px;
      margin-left: 10px;
      // position: absolute;
      // top: 50%;
      // right: 0px;
      // 4C6C69
      // 1F4743
      transition: all 300ms;
    }
    .item.hovered{
      .dropdown{
        display: block;
      }
      .submenu-icon{
        transform: rotate(180deg);
        path{
          stroke: #1F4743;
        }
      }
    }
    .item.has-submenu{
      // padding-right: 18px;
      &:hover{
        a{
          color: #1F4743;
          &:after{
            display: none;
          }
        }
      }
    }
    .dropdown{
      display: none;
      position: absolute;
      left: 20px;
      top: 100%;
      z-index: 1111;
    }

    .dropdown-inner{
      position: relative;
      display: block;
      border-radius: 10px;
      background: #FFF;
      box-shadow: 0px 20px 40px -20px rgba(31, 72, 68, 0.24);
      padding: 8px;
      margin-top: 9px;

      .item{
        display: block;
        a{
          text-align: left;
          margin: 0px;
          white-space: nowrap;
          padding: 12px 16px;
          display: block;
          border-radius: 6px;

          &.selected,
          &.router-link-active,
          &:hover{
            background: rgba(105, 189, 182, 0.09);
            &:after{
              display: none;
            }
          }
        }
      }
    }

  }
  .switch{
    margin-left: 90px;
    height: 36px;
    box-sizing: border-box;
    background: rgba(105, 189, 182, 0.1);
    border-radius: 40px;
    padding: 4px;

    white-space: nowrap;

    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &.selected{
      .divider{
        opacity: 0;
        transform: scaleY(0);
        -webkit-transform: scaleY(0);
      }
    }

    .divider{
      display: inline-block;
      vertical-align: top;
      box-sizing: border-box;
      opacity: 0.5;
      background: #C7E5E4;
      width: 1px;
      height: 20px;
      margin-top: 4px;

      transform: scaleY(1);
      -webkit-transform: scaleY(1);

      -webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			transition: all 0.3s ease;

    }
    a{
      display: inline-block;
      vertical-align: top;
      box-sizing: border-box;
      text-decoration: none;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #1F4743;
      opacity: 0.7;
      height: 28px;
      padding: 3px 16px;
      border-radius: 40px;

      margin-left: -3px;
      margin-right: -3px;


      -webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			transition: all 0.3s ease;

      position: relative;
      z-index: 1;

      &.selected,
      &.router-link-active{
        background: #FFFFFF;
        box-shadow: 0px 12px 20px -8px rgba(31, 72, 68, 0.24);
        color: #1C1C1B;
        opacity: 1;
      }

      &:first-of-type{
        margin-left: 0px;
      }
      &:last-of-type{
        margin-right: 0px;
      }

      &:hover{
        color: #1F4743;
        opacity: 1;
      }

    }
  }

  &.open{
    .menu-burger{
      padding-left: 22px;
      padding-right: 22px;
      .l1{
        top: 21px;
        left: 12px;
        transform: rotate(45deg);
      }
      .l2{
        opacity: 0;
      }
      .l3{
        top: 21px;
        left: 12px;
        transform: rotate(-45deg);
      }
      .label{
        width: 0px;
        opacity: 0;
      }
    }
  }
  .heart-item{
    white-space: nowrap;
  }
}

.site-header.dealer-header{
  .left-side{
    .switch{
      display: none;
    }
  }
}

.header-hidden {
  display: none;
  border: solid 1px red;
}

@media (max-width: 1270px) {
  .site-header{
    padding-top: 14px;
    .navigation{
      display: none;

      .submenu-icon{
        margin-top: 12px;
      }

      .dropdown{
        position: relative;
        left: 0px;
        padding: 0px;
          margin: 0px;
        .dropdown-inner{
          box-shadow: none;
          background: transparent;
          padding: 0px;
          margin: 0px;
          .item{
            display: block;
            padding: 0px;
            margin: 0px;
            a{
              padding: 0px;
              text-align: center;

              color: #4C6C69;
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              margin-top: 18px;

              &.selected,
              &.router-link-active,
              &:hover{
                background: transparent;
                &:after{
                  display: block;
                  opacity: 1;
                }
              }
            }
          }
        }
      }

    }
    .login-button-wrap{
      display: none;
    }
    .menu-burger-wrap{
      display: block;
    }
    .switch{
      position: absolute;
      left: 205px;
      top: 14px;
      margin-left: 0px;
      height: 44px;
      box-sizing: border-box;
      a{
        height: 36px;
        padding: 7px 16px;
      }
    }

    &.open{
      position: fixed;
      bottom: 0px;
      z-index: 10000;
      background: #FFF;
      .switch{
        left: 24px;
        display: block;
      }
      .logo-wrap{
        opacity: 0;
      }
      .right-side{
        position: absolute;
        display: block;
        padding-top: 130px;
        padding-bottom: 120px;
        overflow-y: scroll;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;

        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
            display: none;  /* Safari and Chrome */
        }
      }
      .navigation{
        display: block;
        .item{
          display: block;
          text-align: center;
          margin: 0px;
          margin-bottom: 40px;
          a{
            margin-left: 0px;
            font-weight: 400;
            font-size: 20px;
            line-height: 130%;
          }
        }
      }
      .login-button-wrap{
        display: block;
        text-align: center;
        .login-button{
          margin-left: 0px;
          margin-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 700px) {
   .site-header{
    .switch{
      display:none;
    }
    &.open{
       .switch{
        display:block;
      }
    }
   }
}
</style>
