import { Component, Vue } from "vue-property-decorator";

import SelectField from "../../components/ui/select-field/select-field"
import template from "./Contact.vue"

@Component({
    mixins:[template],
    props: ['cmsGlobalData', 'cmsPageData'],
    components: {
        'select-field': SelectField,
    },
})
export default class Contact extends Vue {
}