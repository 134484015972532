<template>
  <div v-if="loggedIn">
    <BLHeader/>
    
    <div class="single-post-page">
      <section class="top-hero fade-on-enter">
        <div class="wrap">
          <span class="date">{{date}}</span>
          <h1>{{title}}</h1>
          <div class="item-tags">
            <div class="tag" v-for="tag in tags" :key="tag.term_id" >
              {{tag.name}}
            </div>
          </div>
          <div v-if="thumbnail && !hideThumbnail" class="image">
            <img :src="thumbnail" alt="thumbnail" />
          </div>
        </div>
      </section>
      <div class="page-content fade-on-enter">

        <div class="wrap">
          <div class="textual-content" v-html="content"></div>
        </div>

        <span v-if="cmsPageData && !cmsPageData.loaded"></span>
      </div>

      <div class="related-green-section" v-if="relatedArticles && relatedArticles.length>0">
        <news-section :articles="relatedArticles" :button="'Alle nyheder'" :propNewsSlug="'pengeinstitutter/nyheder'" :title="'Relaterede artikler'"  />
      </div>


    </div>
    
      
    
    
  </div>


  


</template>
<script>

import * as localForage from "localforage";

import BLHeader from "./BLHeader.vue";
import BottomInfoBar from "@/components/ui/bottom-info-bar/BottomInfoBar.vue";
import InputField from "@/components/ui/input-field/input-field";
import bankloginData from "../BankloginData";
import VueScrollTo from "vue-scrollto";
import Axios from "axios";
import eventBus from '../../../eventBus';
import NewsSection from '../../../components/ui/news-section/news-section';

export default {
  components: {
    BLHeader : BLHeader,
    'news-section': NewsSection,
  },
  props: ['cmsGlobalData'],
  data() {
    return {
      userName: null,
      bankName: null,
      bankLogo: null,
      loggedIn: false,
      bankloginTokenKey: "BankloginToken",

      cmsPageData: {"loaded": true, "page": null},
      thisSlug: "",
      date: null,
      title: null,
      content: null,
      tags: null,
      relatedArticles: null,
      thumbnail: null,
      relatedArticles: null,
      hideThumbnail: false
    }
  },
  beforeMount(){
    
  },
  created(){
    var th = this;
    th.loggedIn = bankloginData.loggedIn;

    if (!bankloginData.loggedIn){
      bankloginData.recoverFromToken(function(){
        th.loggedIn = bankloginData.loggedIn;


        // if (bankloginData.pageData){
        //   window.cmsPageData = {"loaded": true, "page": {"dynamic_page": bankloginData.pageData}};
          
        //   if (typeof cmsLoadedPageDataEvent === 'undefined') {
        //     var cmsLoadedPageDataEvent = new Event('loaded-cms-page-data');
        //   }
        //   window.dispatchEvent(cmsLoadedPageDataEvent);
        // }

      },function(){
        th.loggedIn = bankloginData.loggedIn;
        th.$router.replace({ 'name': "Banklogin" });
      });
    }
    
    
  },
  mounted(){
    var th = this;


    this.authRefresh();

    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {
        
        this.thisSlug = this.cmsPageData.page.url;
        this.title = this.cmsPageData.page.news_article.post_title;
        this.content = this.cmsPageData.page.news_article.content_html;
        this.date = this.cmsPageData.page.news_article.date2;
        this.tags = this.cmsPageData.page.news_article.tags;
        this.thumbnail = this.cmsPageData.page.news_article.thumbnail;
        this.relatedArticles = this.cmsPageData.page.news_article.related_articles;
        
        if (this.cmsPageData.page.news_article.hideFeatured === true){
          this.hideThumbnail = true;
        }
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.$watch(
      () => this.$route,
      (toParams, previousParams) => {
        // console.log(this.$route);
        this.authRefresh();
        // this.prepareActiveTags();
        // this.preparePagiantion();
      }
    );


    localForage.getItem(th.bankloginTokenKey, function(err, token) {
      if (token !== null && token.token){
        th.userName = token.user_name;
        th.bankName = token.bank_name;
        th.bankLogo = token.bank_logo;
      }else{
         
      }
  });


    
    
  },
  methods: {
    authRefresh(){
      var th = this;

      bankloginData.recoverFromToken(function(){
        th.loggedIn = bankloginData.loggedIn;
        if (bankloginData.pageData){
          th.cmsPageData = {"loaded": true, "page": bankloginData.pageData};
        }
      },function(){
        th.loggedIn = bankloginData.loggedIn;
        th.$router.replace({ 'name': "Banklogin" });
      });
    },
    beforeDestroy() {
      
    }
    
  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/style.scss";

.top-hero{
  margin-top: 80px;
  text-align: center;
  margin-bottom: 60px;

  .date{
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #4C6C69;

    margin-bottom: 14px;
  }
  h1{
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 72px;
    text-align: center;
    color: #1F4743;
    max-width: 789px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 48px;
  }
  .item-tags{
    margin-bottom: 48px;
  }
  .image{
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    img{
      width: 100%;
      border-radius: 20px;
    }
  }
}
.page-content{
  padding:0px;
  margin-top: 60px;
  margin-bottom: 160px;
}

.related-green-section{
  padding-top: 96px;
  padding-bottom: 140px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  background: $color-super-light-green;
  border-radius: 40px;
}

@media (max-width: #{$screen-tablet}) {
  .related-green-section{
    padding-bottom: 60px;
    padding-top: 48px;
  }

  .top-hero{

    .date{
     margin-bottom: 14px;
    }
    h1{
     font-weight: 700;
      font-size: 48px;
      line-height: 120%;
    }
    .item-tags{
      
    }
    .image{
     
    }
  }
}

@media (max-width: #{$screen-mobile}) {
  .related-green-section{
    padding-bottom: 60px;
    padding-top: 48px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .top-hero{
    .date{
     margin-bottom: 12px;
    }
    h1{
     font-weight: 700;
      font-size: 32px;
      line-height: 130%;
    }
    .item-tags{
      
    }
    .image{
      margin-left: -20px;
      margin-right: -20px;
      width: calc(100% + 40px);
    }
  }
}

</style>
