<template>
  <div :class="'checkbox-with-price' + (checked ? ' checked' : '')">
    <CheckboxField :customClass="customClass" :theme="theme" :locked="locked" :disabled="disabled" :size="size" :inputName="inputName" :name="name" :value="value" :isChecked="isChecked" @changed="checkboxChange" :children="children"/>
    <span class="price">{{priceLabel}}</span>
  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';

export default {
  name: 'CheckboxField',
  props: ['theme', 'size', 'name', 'inputName', 'value', 'children', 'isChecked', 'customClass', 'priceLabel', 'disabled', 'locked'],
  data() {
    return {
      checked: false 
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  mounted(){
    this.init();
  },
  created(){
    this.checked = this.isChecked === true;
    this.$watch(
      () => this.isChecked,
      (toParams, previousParams) => {
        this.checked = toParams;
      }
    );
    
  },
  methods: {
    init: function(){
      
    },
    checkboxChange(e){
        this.checked = e.checked;
        this.$emit("changed", {'value': this.value, 'checked': this.checked});
    }
    // change:function(e){
    //   // console.log(this.checked);
    //   var th = this;

    //   if (th.$el.classList.contains("has-children")){
    //     if (!this.checked){

    //     }
    //   }

    //   this.$emit("changed", {'value': this.value, 'checked': this.checked});
    // }
  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.checkbox-with-price{

  display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

  -ms-flex-wrap: nowrap;
 my-flex-wrap: nowrap;

  -ms-flex-direction: row;
  flex-direction: row;

  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;

  .price{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    text-align: right;
    color: #1F4743;
    opacity: 0.25;
  }
  &.checked{
    

    .price{
      font-weight: 700;
      opacity: 1;
    }
  }
}
</style>