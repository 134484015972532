import { Component, Vue } from 'vue-property-decorator';

import IntervalField from '../interval-field/IntervalField.vue';
import template from './PrefilterField.vue';

@Component({
  mixins: [template],
  props: ['options', 'type', 'from', 'to', 'name', 'customClass'],
  components: {
    'IntervalField' : IntervalField
  }
})
export default class PrefilterField extends Vue {

}