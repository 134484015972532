<template>
    <section>
        <div v-if="showShowMoreLabel()" class="secondary-font__large__bolder padding">
            INKLUDERET UDSTYR:
            <span class="secondary-font__regular">{{getValues()}}</span>

            <a @click.prevent="showAllIncludedEquipment()" class="secondary-font__regular" href="#">se mere</a>
        </div>

        <div v-if="this.usedcar" class="secondary-font__large__bolder padding">
            RELEASING DOKUMENTATION:
            <span class="secondary-font__regular">{{getValuesForReleasingCar()}}</span>
            <a target="_blank" class="secondary-font__regular" v-bind:href="this.downloadFile.Url">Skaderapport med billeder</a>
        </div>
    </section>
                
</template>



<script lang="ts">
    import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";
    import AbLightbox from '../../common/ab_lightbox';



    @Component({
        name: 'inkluderetudstyr-block-component',
        components: {

        }
    })

    export default class InkluderetUdstyrComponent extends Vue {
        @Prop() values: any[];
        @Prop() registered_firsttime: string;
        @Prop() km_driven: any;
        @Prop() downloadFile: { FileName: string, Title: string, Url: string };
        @Prop() usedcar: boolean;
        @Prop() showmorelabel: string;
        @Prop() extraequipmentheading: string;


        abLightbox = new AbLightbox();


        mounted() {
            let self = this;
        }

        getValuesForReleasingCar() {
            let releasingString: string = "";
            releasingString += "Reg. første gang: " + this.registered_firsttime + ". ";
            releasingString += "Km. kørt: " + this.km_driven + ". ";
            return releasingString;
        }

        getReleasingReportURL() {
            return this.downloadFile.Url;
        }

        showShowMoreLabel(): boolean {
            let showLabelFlag: boolean = false;

            for (let i = 0; i < this.values.length; i++) {
                if (this.values[i] != "") {
                    showLabelFlag = true;
                }
            }
            return showLabelFlag;

        }

        getValues() {
            let allValues: string = "";
            let limitedString: string = "";

            //først henter vi alle værdier, og sammensætter dem til én streng.
            for (let i = 0; i < this.values.length; i++) {
                if (this.values[i] != '') {
                    allValues += this.values[i] + ", ";
                }
            }

            //vi vil lige begrænse hvor meget tekst der vises på forsiden i "Inkluderet udstyr" chunken.
            if (allValues.length > 100) {
                limitedString = allValues.substring(0, 100);
                limitedString += "...";
            } else {
                limitedString = allValues + "...";
            }

            return limitedString;
        }

        getValuesAsMarkup() {
            let markup: string = "";

            markup += "<div class=col-12>";
            markup += "<div class=abToggleLine>";

            for (let i = 0; i < this.values.length; i++) {
                markup += "<div>";
                markup += this.values[i];
                markup += "</div>";

            }

            //Kun indsæt i markup til lightbox, hvis det er en releasing bil
            if (this.usedcar) {
                markup += "<div class=abToggleLine__headerWrapper>";
                    markup += "<div class=abToggleLine__headerLeft>Reg. første gang:</div>";
                    markup += "<div class=abToggleLine__headerRight>" + this.registered_firsttime + "</div>";
                markup += "</div>";

                markup += "<div class=abToggleLine__headerWrapper>";
                    markup += "<div class=abToggleLine__headerLeft>Km. kørt:</div>";
                    markup += "<div class=abToggleLine__headerRight>" + this.km_driven + "</div>";
                markup += "</div>";

                markup += "<div class=abToggleLine__headerWrapper>";
                    markup += "<div class=abToggleLine__headerLeft>Skaderapport med billeder:</div>";
                    markup += "<div class=abToggleLine__headerRight>";
                        markup += "<a href=" + this.downloadFile.Url + " target=_blank>" + this.downloadFile.Title + "</a>";
                    markup += "</div>";
                markup += "</div>";
            }



            markup += "</div>";
            markup += "</div>";

            return markup;
        }

        showAllIncludedEquipment() {
            let self = this;
            self.abLightbox.standardLightText(this.extraequipmentheading, this.getValuesAsMarkup());

    }


    }

</script>

<style lang="scss" scoped>
    /*@import "../../../theme/style.scss";*/

    .padding {
        padding-top: 20px;
    }


</style>
