import { Component, Vue } from 'vue-property-decorator';

import template from './ScrollBar.vue';

@Component({
  mixins: [template],
  props: ['elem'],
})
export default class ScrollBar extends Vue {

}



