import { Component, Vue } from "vue-property-decorator";

import PrivatErhvervSelectBlock from '../../components/ui/privat-erhverv-select-block/privat-erhverv-select-block';
import template from "./AskPopup.vue"

@Component({
    mixins:[template],
    props: ['cmsGlobalData', 'cmsPageData'],
    components: {
        'privat-erhverv-select-block': PrivatErhvervSelectBlock,
    },
})
export default class AskPopup extends Vue {
}