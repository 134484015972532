<template>
    <div>
        <div class="bgcolor-secondary__blue-light form-container">
            <div class="p-4">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 no-gutters">
                        <div class="grid-1-1">
                            <p class="primary-font__bold__large text-center">{{userData.epiData.formData.heading}}</p>
                        </div>
                        <div class="grid-1-1">
                            <p class="secondary-font__regular">{{userData.epiData.formData.infoText}}</p>
                        </div>
                        <form class="form__fields__container EPiServerForms LeasingForm">
                            <div class="Form__MainBody">
                                <div class="grid grid__inner Form__Element FormStep Form__Element--NonData">
                                    <div class="grid-1-2">
                                        <div class="Form__Element FormTextbox" data-f-type="textbox">
                                            <div class="caption__wrapper">
                                                <label for="abdate" class="Form__Element__Caption">{{userData.epiData.formData.dateLabel}}</label>
                                            </div>
                                            <span class="date-wrapper">
                                                <input name="abdate" id="abdate" type="text" class="FormTextbox__Input flatpickr-input" friendly-name="Dato" v-model="sharedData.Date" placeholder="" data-f-datainput="" ab-date-pickr="'fromtoday', 'defaultdate'" readonly="readonly" @focus="resetDateError()">
                                                <img class="calendar-icon" src="/ui/assets/svg/systemicon_lightblue_calender.svg" width="24" height="24">
                                            </span>
                                            <p class="secondary-font__regular color-primary__red">{{dateErrLabel}}</p>
                                        </div>
                                    </div>

                                    <div id="scroll-from" class="pt-2"></div>

                                    <aside class="grid-1-1" v-if="!isCorporate">
                                        <p class="secondary-font__large__bolder text-left pt-2">{{userData.epiData.formData.subHeading}}</p>
                                    </aside>
                                    <div class="grid-1-2">
                                        <div class="Form__Element FormTextbox">
                                            <div class="caption__wrapper">
                                                <label for="firstName">{{userData.epiData.formData.firstnameLabel}}</label>
                                            </div>
                                            <input id="firstName" ref="firstName" class="form-control" type="text" v-model="person.FirstName" placeholder="" @focus="resetFirstNameError()" />
                                            <p class="secondary-font__regular color-primary__red">{{firstNameErrLabel}}</p>
                                        </div>
                                    </div>
                                    <div class="grid-1-2">
                                        <div class="Form__Element FormTextbox">
                                            <div class="caption__wrapper">
                                                <label for="lastName">{{userData.epiData.formData.lastnameLabel}}</label>
                                            </div>
                                            <input id="lastName" ref="lastName" class="form-control" type="text" v-model="person.LastName" placeholder="" @focus="resetLastNameError()" />
                                            <p class="secondary-font__regular color-primary__red">{{lastNameErrLabel}}</p>
                                        </div>
                                    </div>
                                    <div class="grid-1-2">
                                        <div class="Form__Element FormTextbox">
                                            <div class="caption__wrapper">
                                                <label for="cpr">{{userData.epiData.formData.cprLabel}}</label>
                                            </div>
                                            <input id="cpr" ref="cpr" class="form-control" type="text" v-model="person.Cpr" placeholder="" @focus="resetCprError()" />
                                            <p class="secondary-font__regular color-primary__red">{{cprErrLabel}}</p>
                                        </div>
                                    </div>
                                    <div class="grid-1-2">
                                        <div class="Form__Element FormTextbox">
                                            <div class="caption__wrapper">
                                                <label for="mobile">{{userData.epiData.formData.phoneLabel}}</label>
                                            </div>
                                            <input id="mobile" ref="mobile" class="form-control" type="text" v-model="person.CellPhone" placeholder="" @focus="resetMobileError()" />
                                            <p class="secondary-font__regular color-primary__red">{{mobileErrLabel}}</p>
                                        </div>
                                    </div>
                                    <div class="grid-1-2">
                                        <div class="Form__Element FormTextbox">
                                            <div class="caption__wrapper">
                                                <label for="email">{{userData.epiData.formData.emailLabel}}</label>
                                            </div>
                                            <input id="email" ref="email" class="form-control" type="email" v-model="person.Email" placeholder="" @focus="resetEmailError()" />
                                            <p class="secondary-font__regular color-primary__red">{{emailErrLabel}}</p>
                                        </div>
                                    </div>

                                    <div id="scroll-to" class="pt-3"></div>

                                    <div class="animate-height" id="animate-controller">
                                        <div class="animate-content grid grid__inner mt-2">
                                            <div class="grid-1-1 pt-3">
                                                <p class="secondary-font__large__bolder text-left">{{userData.epiData.formData.extraSubHeading}}</p>
                                                <div v-show="spouseAdded">
                                                    <div class="url__menu text-nowrap float-right" style="margin-bottom: 1rem" @click="toggleSpouse" role="button" v-scroll-to="'#scroll-from'">
                                                        <img src="/ui/assets/svg/bullet-negative_blue.svg" class="mr-1" style="width:15px" />
                                                        <span>Fjern medansøger</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="grid-1-2">
                                                <div class="Form__Element FormTextbox">
                                                    <div class="caption__wrapper">
                                                        <label for="spouse-firstName">{{userData.epiData.formData.firstnameLabel}}</label>
                                                    </div>
                                                    <input id="spouse-firstName" ref="spouse-firstName" class="form-control" type="text" v-model="spouse.FirstName" placeholder="" @focus="resetSpouseFirstNameError()" />
                                                    <p class="secondary-font__regular color-primary__red">{{spouseFirstNameErrLabel}}</p>
                                                </div>
                                            </div>
                                            <div class="grid-1-2">
                                                <div class="Form__Element FormTextbox">
                                                    <div class="caption__wrapper">
                                                        <label for="spouse-lastName">{{userData.epiData.formData.lastnameLabel}}</label>
                                                    </div>
                                                    <input id="spouse-lastName" ref="spouse-lastName" class="form-control" type="text" v-model="spouse.LastName" placeholder="" @focus="resetSpouseLastNameError()" />
                                                    <p class="secondary-font__regular color-primary__red">{{spouseLastNameErrLabel}}</p>
                                                </div>
                                            </div>
                                            <div class="grid-1-2">
                                                <div class="Form__Element FormTextbox">
                                                    <div class="caption__wrapper">
                                                        <label for="spouse-cpr">{{userData.epiData.formData.cprLabel}}</label>
                                                    </div>
                                                    <input id="spouse-cpr" ref="spouse-cpr" class="form-control" type="text" v-model="spouse.Cpr" placeholder="" @focus="resetSpouseCprError()" />
                                                    <p class="secondary-font__regular color-primary__red">{{spouseCprErrLabel}}</p>
                                                </div>
                                            </div>
                                            <div class="grid-1-2">
                                                <div class="Form__Element FormTextbox">
                                                    <div class="caption__wrapper">
                                                        <label for="spouse-mobile">{{userData.epiData.formData.phoneLabel}}</label>
                                                    </div>
                                                    <input id="spouse-mobile" ref="spouse-mobile" class="form-control" type="text" v-model="spouse.CellPhone" placeholder="" @focus="resetSpouseMobileError()" />
                                                    <p class="secondary-font__regular color-primary__red">{{spouseMobileErrLabel}}</p>
                                                </div>
                                            </div>
                                            <div class="grid-1-2">
                                                <div class="Form__Element FormTextbox">
                                                    <div class="caption__wrapper">
                                                        <label for="spouse-email">{{userData.epiData.formData.emailLabel}}</label>
                                                    </div>
                                                    <input id="spouse-email" ref="spouse-email" class="form-control" type="email" v-model="spouse.Email" placeholder="" @focus="resetSpouseEmailError()" />
                                                    <p class="secondary-font__regular color-primary__red">{{spouseEmailErrLabel}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid-1-1">
                                        <div class="Form__Element FormTextbox FormTextbox--Textarea Comment">
                                            <div class="caption__wrapper">
                                                <label for="comments">{{userData.epiData.formData.commentLabel}}</label>
                                            </div>
                                            <textarea id="comments" class="form-control FormTextbox__Input" type="text" v-model="sharedData.Comments" placeholder="" maxlength="1200">
                                            </textarea>
                                        </div>
                                    </div>

                                    <div class="grid-1-1 text-left" v-if="!isCorporate" v-show="!spouseAdded">
                                        <div class="url__menu text-nowrap" style="margin-bottom: 1rem" @click="toggleSpouse" role="button" v-scroll-to="'#scroll-to'">
                                            <img src="/ui/assets/svg/bullet_plus_blue.svg" class="mr-1" style="width:15px" />
                                            <span>Tilføj evt. medansøger</span>
                                        </div>
                                    </div>

                                    <!--<vue-recaptcha ref="almbrandRecaptcha"
                                                :almbrand-sitekey="$store.getters.getUserData.siteKey"
                                                v-on:RecaptchaChanged="recaptchaChanged">
                                    </vue-recaptcha>-->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer__nav"></div>

        <div class="form-container">
            <div class="row mt-4 mb-5 align_center text-center">
                <div class="col-12 col-md-2 mb-2 text-nowrap">
                    <a href="#" @click="$router.go(-1)" class="url__link">
                        <img :src="require('@/components/leasing/Showroom/public/ui/assets/svg/arrow_darkblue_left.svg')" style="width:16px" />
                        <span>Tilbage til oversigt</span>
                    </a>
                </div>
                <div class="col-12 col-md-10 text-md-right">
                    <button class="button__cta mb-2 mr-2" v-bind:class="userData.epiData.formData.printButtonStyle" type="button" @click="printPage()">{{userData.epiData.formData.printButtonText}}</button>
                    <button class="button__cta mb-2 mr-2" v-bind:class="userData.epiData.formData.submitButtonStyle" type="button" :disabled="submitting" @click="createOffer()">{{userData.epiData.formData.submitButtonText}}</button>
                </div>
            </div>
        </div>


    </div>

</template>

<script lang="ts">
    import Vue from "vue";
    import { Component, Emit, Inject, Model, Prop, Provide, Watch } from "vue-property-decorator";
    import AbValidator from '../../common/ab_validator';
    import UserData from '../providers/UserData';
    import Utils from '../providers/Utils';
    import FormHelper from './FormHelper';
    import AbDatePickr from '../../common/ab_date_pickr';
    import { PersonDealer, SharedDealer } from '../model/person';
    import { isNullOrUndefined } from "is-what";
    import RecaptchaComponent from "../../shared/inputs/recaptcha/RecaptchaInvisibleComponent.vue";
    import AbCpr from '../../common/ab_cpr';

    import 'flatpickr/dist/flatpickr.css';

    @Component({
        name: 'leasing-form-component',
        components: {
            'vue-recaptcha': RecaptchaComponent,
        }
    })
    export default class FormDealer extends Vue {
        @Prop() isCorporate: string;
        @Prop() colorSelected: any;

        //reference til Recaptcha component
        $refs: {
            almbrandRecaptcha: any,
        }
        captcha: string;

        formHelper = new FormHelper();
        abValidator = new AbValidator();
        abCpr = new AbCpr();
        userData: UserData = this.$store.getters.getUserData;
        spouseAdded: boolean = false;

        submitting: boolean = false;

        utils = new Utils();

        sharedData: SharedDealer = {
            //Dealer: "",
            Date: null,
            Comments: ""
        }

        person: PersonDealer = {
            Cpr: "",
            FirstName: "",
            LastName: "",
            CellPhone: "",
            Email: ""
        }

        spouse: PersonDealer = {
            Cpr: "",
            FirstName: "",
            LastName: "",
            CellPhone: "",
            Email: ""
        }

        //customer
        cprErrLabel: string = '';
        firstNameErrLabel: string = '';
        lastNameErrLabel: string = '';
        mobileErrLabel: string = '';
        emailErrLabel: string = '';
        //spouse
        spouseCprErrLabel: string = '';
        spouseFirstNameErrLabel: string = '';
        spouseLastNameErrLabel: string = '';
        spouseMobileErrLabel: string = '';
        spouseEmailErrLabel: string = '';
        //shared
        //dealerErrLabel: string = '';
        dateErrLabel: string = '';

        //customer
        resetCprError() {
            this.cprErrLabel = "";
        }

        resetFirstNameError() {
            this.firstNameErrLabel = "";
        }

        resetLastNameError() {
            this.lastNameErrLabel = "";
        }

        resetMobileError() {
            this.mobileErrLabel = "";
        }

        resetEmailError() {
            this.emailErrLabel = "";
        }

        //spouse
        resetSpouseCprError() {
            this.spouseCprErrLabel = "";
        }

        resetSpouseFirstNameError() {
            this.spouseFirstNameErrLabel = "";
        }

        resetSpouseLastNameError() {
            this.spouseLastNameErrLabel = "";
        }

        resetSpouseMobileError() {
            this.spouseMobileErrLabel = "";
        }

        resetSpouseEmailError() {
            this.spouseEmailErrLabel = "";
        }

        //shared
        /*resetDealerError() {
            this.dealerErrLabel = "";
        }*/
        resetDateError() {
            this.dateErrLabel = "";
        }

        printPage(): void {
            this.formHelper.printPage();
        }
        resetLabels(): void {
            //customer
            this.cprErrLabel = '';
            this.firstNameErrLabel = '';
            this.lastNameErrLabel = '';
            this.mobileErrLabel = '';
            this.emailErrLabel = '';
            //spouse
            this.spouseCprErrLabel = '';
            this.spouseFirstNameErrLabel = '';
            this.spouseLastNameErrLabel = '';
            this.spouseMobileErrLabel = '';
            this.spouseEmailErrLabel = '';
            //shared
            //this.dealerErrLabel = '';
            this.dateErrLabel = '';

            this.userData.colorErrLabel = "";
            this.userData.insurancesErrLabel = "";
        }

        validateSubmit() {
            this.resetLabels();
            let validated: boolean = true;
            let colorIsMissing = false;
            let insurancesMissing = false;

            if (isNullOrUndefined(this.colorSelected)) {
                this.userData.colorErrLabel = "Du mangler at angive en farve på leasing bil";
                validated = false;
                colorIsMissing = true;
            }

            if (!this.isCorporate) {
                let insurancesData = this.$store.getters.getInsurancesData;
                if (insurancesData.almbrandInsurancesCheckBox) {
                    if (insurancesData.insurancePrice === 0) {
                        this.userData.insurancesErrLabel = "Du har valgt Almbrand forsikring, men mangler at beregne din forsikrings pris";
                        validated = false;
                        insurancesMissing = true;
                    }
                } else if (isNullOrUndefined(this.$store.getters.getEksInsurancesCompany) || this.$store.getters.getEksInsurancesCompany === "") {
                    this.userData.insurancesErrLabel = "Du mangler at angive en forsikring på leasing bil";
                    validated = false;
                    insurancesMissing = true;
                }
            }


            //customer
            if (this.sharedData.Date === undefined || this.sharedData.Date === null) {
                this.dateErrLabel = "Ansøger mangler at angive dato";
                validated = false;
            }

            if (!this.abCpr.isValidCpr(this.person.Cpr)) {
                this.cprErrLabel = "Ansøger cpr nummer er ikke valid";
                validated = false;
            }

            if (this.person.FirstName === undefined || this.person.FirstName === "") {
                this.firstNameErrLabel = "Ansøger mangler at angive sit fornavn";
                validated = false;
            }

            if (this.person.LastName === undefined || this.person.LastName === "") {
                this.lastNameErrLabel = "Ansøger mangler at angive sit efternavn";
                validated = false;
            }

            if (this.person.CellPhone.length < 8) {
                this.mobileErrLabel = "Ansøger telefonnummer skal minimum være 8 tal";
                validated = false;
            }

            if (!this.validEmail(this.person.Email)) {
                this.emailErrLabel = "Der er noget galt med ansøger mail";
                validated = false;
            }
            //spouse
            if (this.spouseAdded) {
                if (!this.abCpr.isValidCpr(this.spouse.Cpr)) {
                    this.spouseCprErrLabel = "Medansøger cpr nummer er ikke valid";
                    validated = false;
                }

                if (this.spouse.FirstName === undefined || this.spouse.FirstName === "") {
                    this.spouseFirstNameErrLabel = "Medansøger mangler at angive sit fornavn";
                    validated = false;
                }

                if (this.spouse.LastName === undefined || this.spouse.LastName === "") {
                    this.spouseLastNameErrLabel = "Medansøger mangler at angive sit efternavn";
                    validated = false;
                }


                if (this.spouse.CellPhone.length < 8) {
                    this.spouseMobileErrLabel = "Medansøger telefonnummer skal minimum være 8 tal";
                    validated = false;
                }

                if (!this.validEmail(this.spouse.Email)) {
                    this.spouseEmailErrLabel = "Der er noget galt med medansøger mail";
                    validated = false;
                }
            }

            //shared
            /*if (this.sharedData.Dealer === undefined || this.sharedData.Dealer === "") {
                this.dealerErrLabel = "Du mangler at angive forhandler";
                validated = false;
            }*/

            let VueScrollTo = require('vue-scrollto');
            if (colorIsMissing) {
                VueScrollTo.scrollTo(document.querySelector('#scroll-color-options'));
            } else if (insurancesMissing) {
                VueScrollTo.scrollTo(document.querySelector('#scroll-insurances-options'));
            }

            return validated;
        }

        validEmail(email) {
            return this.abValidator.validEmail(email);
        }

        toggleSpouse(): void {
            this.spouseAdded ? this.spouseAdded = false : this.spouseAdded = true;
            this.utils.doAnimate(24, "#animate-controller", ".animate-content");
        }

        mounted() {
            const abDatePickr = new AbDatePickr('ab-date-pickr');
        }


        /**
         * Opret tilbud.
         * 1. Felter valideres
         * 2. Google Recaptcha eksekveres
         * 3. ansøger og medansøger mappes over i kontrakten
         * 4. Opret tilbud(createOffer) kaldes hos bytelab via rest
         *
         * */
        createOffer() {
            if (this.validateSubmit()) {
                this.submitting = true;
                this.recaptchaChanged(null);
                //(this.$refs.almbrandRecaptcha as any).onSubmit();
            }
        }
        //callback fra recaptcha component
        recaptchaChanged(data: any): void {
            //this.captcha = data;

            if (!this.spouseAdded) {
                this.spouse = {
                    Cpr: "",
                    FirstName: "",
                    LastName: "",
                    CellPhone: "",
                    Email: ""
                }
            }
            let payload = [this.person, this.spouse, this.sharedData];
            this.$store.dispatch('fromDealerToContract', payload);
            //this.formHelper.createOfferViaRest(this.salesChannelGuid, this.$refs.almbrandRecaptcha, this.captcha);
            this.formHelper.createOfferViaRest();
        }

    }
</script>


<style lang="scss">
    @import "../../abdk/styles/legacy/style.scss";
    @import "../../leasingstyle.scss";
</style>
