<template>
    <div>
    <div v-if="false">
        <p v-if="!$store.getters.getUserData.isDealer && !$store.getters.getUserData.isCorporate">Vælg kategori: <router-link to="/privat/leasing">Privatleasing</router-link> | <router-link to="/privat/releasing">Releasing Privat</router-link></p>
        <p v-if="!$store.getters.getUserData.isDealer && $store.getters.getUserData.isCorporate">Allerede erhvervskunde? <a href="https://fleet.opendo.dk" target="_blank">Gå til Leasing Pro+</a></p>

        <div class="bgcolor-secondary__blue-lighter mb-4 dealer-header"
            v-if="$store.getters.getUserData.loginRequired">

            <div class="row no-gutters text-center align-top">
                <div class="col-12 col-sm-4 col-md-4 mb-2 mb-sm-0 text-sm-left">
                    <h3>Forhandler</h3>
                </div>
                <div class="col-12 col-sm-5 col-md-6 mb-2 text-sm-right pt-sm-2">
                    <span class="secondary-font__large">{{loggedInUser}}</span>
                </div>
                <div class="col-12 col-sm-3 col-md-2 text-sm-right align-top" v-show="hasLoggedIn">
                <button type="button " @click="logOff()" class="button__blue-lighter__outline ml-sm-3">Log af</button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="userData.epiData.leasingType === 'Forhandler' && hasLoggedIn">
        <div class="forhandler-banner">
            <div class="wrap">
                <a class="button log-off-dealer login-button-style small" href="#" v-on:click="logOff">Log af</a>
            </div>
        </div>
    </div>
    </div>

</template>

<script lang="ts">
    //Header vises kun for kunder der er logget ind
    import Vue from "vue";
    import router from "@/router";
    import { Component, Emit, Inject, Model, Prop, Provide, Watch } from "vue-property-decorator";
    import UserData from './components/providers/UserData';


    @Component({
        name: 'leasing-header-component'
    })
    export default class AppHeader extends Vue {

        userData = this.$store.getters.getUserData;
        hasLoggedIn: boolean = false;
        loggedInUser: string = "";

        beforeMount(): void {

            this.userData.hasLoggedIn().then(value => {
                this.hasLoggedIn = value;
                if (value) {
                    this.userData.getUsername().then(name => {
                        this.loggedInUser = name;
                    })
                }
            })
        }

        logOff() {
            UserData.logout().then(() => {
                router.push({
                    name: 'logins',
                });
                window.location.reload();
            });
        }
    }
</script>


<style lang="scss" scoped>
    @import "./abdk/styles/legacy/style.scss";

    p {
        font-size: 18px;
    }
    .dealer-header {
        width: 100%;
        padding: 30px 5%;

        @media (max-width: $medium-width) {
            padding: 16px 5%;
        }
    }

    .ab-logo {
       width: 90px;
    }


    .readline, .abbreadcrumb{
        display: none;
    }

    .forhandler-banner{
        text-align: right;
        padding-top: 32px;
        margin-bottom: 12px;
        display: none;
    }


</style>
