<template>
  <div :class="'newsletter-element'+(['home', 'ErhvervLeasing', 'PrivatLeasing'].includes(GetCurrentRouteName) ? '' : ' invis')" >
    <div class="newsletter-popup" v-if="popupOpen">
      <div v-if="success" class="success-wrap flex flex-column flex-s9">
        <h3 class="title">Success</h3>
      </div>
      <div v-else class="content-wrap">
        <h3 class="title">Få seneste nyheder og tilbud som den første</h3>
        <form action="" method="POST" v-on:submit="onSubmit">
          <!--
          <div class="field">
            <select-field :options="selectOptions" name="select" :theme="'nr1'" v-on:selected="changedSelect" />
          </div> -->
           <div class="field flex flex-row flex-s1">
            <CheckboxField ref="privatCheckField" :name="'Privat'" :inputName="'type'"  :value="'privat'" :isChecked="selectedType == 'privat'" @changed="changeType" />
            <CheckboxField ref="erhvervCheckField" :name="'Erhverv'" :inputName="'type'"  :value="'erhverv'" :isChecked="selectedType == 'erhverv'" @changed="changeType" />
          </div>
          <div class="error-info" v-if="showCheckboxError">Please select business type</div>
          <div class="field" v-if="selectedType == 'privat' || selectedType == 'erhverv'" >
            <input-field ref="nameField" name="name" value="" placeholder="Navn" type="text" :theme="'nr1'" />
          </div>
          <div class="field" v-if="selectedType == 'erhverv'"  >
            <input-field ref="companyField" name="company" value="" placeholder="Firmanavn" type="text" :theme="'nr1'" />
          </div>
          <div class="field">
            <input-field ref="emailField" name="email" value="" placeholder="Mailadresse" type="email" :theme="'nr1'" />
          </div>

          
          <button class="button" href="#" type="submit" :disabled="loading" v-html="(!loading ? 'Tilmeld nyhedsbrev' : 'Submiting...')"></button>
        </form>



        





      </div>
      <span class="close-button" v-on:click="buttonClick">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 11L11 1" stroke="#4C6C69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M11 11L1 1" stroke="#4C6C69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </span>

      
      
    </div>
    <div class="newsletter-button" v-on:click="buttonClick">
      <svg width="90" height="36" viewBox="0 0 90 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.80469 0.0175781C1.9081 14.0301 4.20948 19.4742 11.6875 23.3281C18.4189 24.6455 22.2793 23.8867 23.6973 19.2031C25.1152 14.5195 21.5091 11.2075 17.2734 11.168C11.4822 11.7451 9.625 20.2344 12.2461 25.7988C14.8672 31.3633 17.6881 32.7589 24.0625 33.8555C28.4286 34.2594 30.8145 33.8796 34.998 32.3086C38.9598 29.7442 41.392 26.9717 43.4629 23.7149M48.6621 11.6406L82.9941 4.95899L55.5586 15.5938L54.9407 18.666L48.6621 11.6406ZM48.6621 11.6406L42.7109 2.91797L88.1074 3.34766L53.2383 30.3535L54.9407 18.666L48.6621 11.6406ZM48.6621 11.6406L48.0176 21.0078L54.9407 18.666L48.6621 11.6406Z" stroke="#C7E5E4" stroke-width="2" stroke-linejoin="round"/>
      </svg>
    </div>

    
  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';
import Axios from "axios";

export default {
  props: [],
  data() {
    return {
      axios: null,
      success: false,
      loading: false,
      popupOpen: false,
      showCheckboxError: false,
      selectOptions: [{"name": "Type", "value": 0}, {"name": "Something", "value": 1}],
      selectedType: '',
      switchState: null

    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  mounted(){
    

    
    // selectedType

  },
 
  methods: {
    getSwitchState(){
      var key = "menuSwitch";
      var savedSwitchState = sessionStorage.getItem(key);
      var savedSwitchStateCookie = this.get_cookie(key);

      if (savedSwitchStateCookie){
        savedSwitchState = savedSwitchStateCookie;
      }

      if (savedSwitchState){
        this.switchState = savedSwitchState;
      }

      if (this.switchState){
        this.selectedType = this.switchState;
      }
      
    },
    changeType: function (e){
      this.showCheckboxError = false;
      if (e.checked){
        this.selectedType = e.value;
      }else{
        this.selectedType = "";
      }
    },
    buttonClick: function(e){
      this.popupOpen = !this.popupOpen;
      if (this.popupOpen){
        this.getSwitchState();
      }
    },
    
    changedSelect: function(e){
      
    },
    onMailchimpResponse: function(e){

      this.loading = false;

      if(window.mailchimpVueResponse){
        var result = window.mailchimpVueResponse.result;
        var msg = window.mailchimpVueResponse.msg;

        if (result == "error"){
          this.$refs.emailField.showError(msg);
          // this.success = true;
        }
        if (result == "success"){
          this.success = true;
        }
      }
      // alert(window.mailchimpVueResponse);
      // alert("yas");
    },
    beforeDestroy() {
      window.removeEventListener('mailchimpVueResponse', this.onMailchimpResponse);
    },
    onSubmit: function(e){
      e.preventDefault();
      
      var formData = new FormData(e.target);
      var type = formData.get('select');
      var email = formData.get('email');
      var name = formData.get('name');
      var company = formData.get('company');
      var ok = true;
      if (!this.selectedType){
        this.showCheckboxError = true;
        ok = false;
      }else{
        
      
        if (email){        
          var form = document.querySelector("#mc_embed_signup #mc-embedded-subscribe-form");
          if (form){
            
            var e_inp = form.querySelector("input[name='EMAIL']");
            if (e_inp){
              e_inp.value=email;
            }

            var n_inp = form.querySelector("input[name='FNAME']");
            var c_inp = form.querySelector("input[name='MMERGE5']");

            var p_checkbox = form.querySelector("input[name='group[384117][1]']");
            var e_checkbox = form.querySelector("input[name='group[384117][2]']");
            

            if (this.selectedType == "privat"){
              if (name){
                if (n_inp){ n_inp.value=name; }
                if (c_inp){ c_inp.value=""; }
                if (p_checkbox){ p_checkbox.checked=true; }
                if (e_checkbox){ e_checkbox.checked=false; }
              }else{
                ok = false;
                this.$refs.nameField.showError("Du skal udfylde dette felt");
              }
            }
            if (this.selectedType == "erhverv"){
              // if (company){
                // if (n_inp){ n_inp.value=""; }
                if (n_inp){ n_inp.value=name; }
                if (c_inp){ c_inp.value=company; }
                if (p_checkbox){ p_checkbox.checked=false; }
                if (e_checkbox){ e_checkbox.checked=true; }
              // }else{
                // ok = false;
                // this.$refs.companyField.showError("Du skal udfylde dette felt");
              // }
            }
            

           
            
            
            
            


            // form.querySelector("input[name='NAME']").value=email;
            // form.submit();
          }
        }else{
          ok = false;
          this.$refs.emailField.showError("Du skal udfylde dette felt");
        }
      
        // var finalUrl = "https://opendo.us1.list-manage.com/subscribe/post-json?u=b83fc74923d4150ebb2826300&id=cd867b1276&EMAIL="+email+"&FNAME=&b_b83fc74923d4150ebb2826300_cd867b1276=&subscribe=Subscribe&_=1652886314212";
        
        // this.axios.get(finalUrl).then((response) => {
        //   // this.data = response.data;
        //   // this.carlist = this.data.data;
        //   // console.log(this.carlist);
        //   alert(response);
        //   this.loading = false;

        //   this.success = true;
        // })
      }
      if (ok){
        this.loading = true;
        form.querySelector("#mc_embed_signup #mc-embedded-subscribe").click();
      }
    },
    get_cookie: function(cname){
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
   
  },
  created() {
    if (this.axios == null){
      this.axios = Axios.create({
          timeout: 60000,
      });
    }
    var th = this;
    eventBus.$on("openNewsletterForm", function(){
      th.popupOpen = true;
    });

    window.addEventListener('mailchimpVueResponse', this.onMailchimpResponse);
    

  },
}
</script>
<style lang="scss">
@import "~@/styles/variables.scss";

.newsletter-element{
  &.invis{
    .newsletter-button{
      display:none;
    }
  }
}
.newsletter-popup{
  padding: 40px;
  box-sizing: border-box;
  position: fixed;
  width: 380px;
  max-width: 100%;
  max-width: calc(100% - 40px);
  box-shadow: 0px 20px 40px -8px rgba(31, 72, 68, 0.24);
  border-radius: 20px;
  background: #FFF;
  bottom: 20px;
  margin-bottom: 50px;
  right: 32px;
  z-index: 10000;
  text-align: center;

  .field{
    margin-bottom: 12px;
    text-align: left;
    .checkbox-field{
      width: calc(50% - 6px);
    }
  }
  .success-wrap{
    height: 100%;
    .title{
      margin-bottom: 0px;
    }
  }
  .title{
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #1F4743;
    margin-bottom: 48px;
  }
  .button{
    border: none;
    margin: 0px;
    margin-top: 36px;
  }
  .close-button{
    display: block;
    width: 56px;
    height: 56px;
    position: absolute;
    top: 0px;
    right: 0px;
    padding-top: 23px;
    box-sizing: border-box;
    cursor: pointer;
  }
}
.error-info{
  text-align: left;
  font-size: 12px;
  padding-left: 4px;
  display: block;
  text-align: left;
  color: #E1232D;
  margin-bottom: 12px;
}
.newsletter-button{
  position: fixed;
  width: 66px;
  height: 66px;
  border-radius: 33px;
  background: #1F4743;
  z-index: 101;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  svg{
    position: absolute;
    top: 15px;
    right: 12px;
  }
  &:hover{
    background: #69BDB6;
  }
}

@media (max-width: #{$screen-tablet}) {
  .newsletter-button{
    display: none;
  }
}
@media (max-width: #{$screen-mobile}) {
  .newsletter-popup{
    max-width: calc(100% - 40px);
    right: 20px;
  }

}

@media (max-height: 450px) {
  .newsletter-popup{
    top: 0px;
    overflow-y: scroll;
    .content-wrap{
      // height: 100%;
    }
  }
}

</style>