<template>
  <div :class="'interval-field'+(customClass ? ' '+customClass: '')+(type ? ' type-'+type: '')" v-on:click="toggleFilter" v-click-outside="hideFilter">

    <div class="inner" ref="lineLength">
      <span class="bg-line"></span>
      <span class="inner-line" :style="'left:'+fromPX+'px; '+'width:'+(toPX - fromPX)+'px;'"></span>
      <span class="from-circle" ref="fromCircle" :style="
        'transform: translateX('+fromPX+'px); '+
        '-webkit-transform: translateX('+fromPX+'px); '
      ">
        <span class="number" ref="fromNumber" :style="
          'transform: translateX('+collisionShiftFrom+'px); '+
          '-webkit-transform: translateX('+collisionShiftFrom+'px); '
        ">{{formatValue(fromValue)}}</span>
      </span>
      <span class="to-circle" ref="toCircle" :style="
        'transform: translateX('+toPX+'px); '+
        '-webkit-transform: translateX('+toPX+'px); '
      ">
        <span class="number" ref="toNumber" :style="
          'transform: translateX('+collisionShiftTo+'px); '+
          '-webkit-transform: translateX('+collisionShiftTo+'px); '
        ">{{formatValue(toValue)}}</span>
      </span>
    </div>

    
      <input v-if="name && fromValue!=null" type="hidden" :name="name+'-from'" :value="fromValue"/>
      <input v-if="name && toValue!=null" type="hidden" :name="name+'-to'" :value="toValue"/>
    

  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';
import AbAmount from '../../leasing/Showroom/src/common/ab_amount';


export default {
  props: ['type', 'from', 'to', 'name', 'customClass', 'updateTrigger', 'initFrom', 'initTo'],
  data() {
    return {
      fromPerc: 0,
      toPerc: 100,

      lineLengthPX: 0,

      fromPX: 0,
      toPX: 0,
      lastFromPX: 0,
      lastToPX: 0,

      fromValue: null,
      toValue: null,

      valueStepPerPX: 0,

      offsetX: 0, 
      selectedCircle: "",

      fromAtEdge: false,
      toAtEdge: false,

      collisionDistance: 10,
      collisionShiftFrom: 0,
      collisionShiftTo: 0,


      changeTrigger:"",
      abAmount: null
      // firstInit = true;
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  beforeDestroy(){
    
    this.$refs.fromCircle.removeEventListener("mousedown", this.onMouseDownCircleFrom);
    this.$refs.toCircle.removeEventListener("mousedown", this.onMouseDownCircleTo);

    this.$refs.fromCircle.removeEventListener("touchstart", this.onMouseDownCircleFrom);
    this.$refs.toCircle.removeEventListener("touchstart", this.onMouseDownCircleTo);

    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("touchmove", this.onMouseMove);
    window.removeEventListener("mouseup", this.onMouseUp);
    window.removeEventListener("touchend", this.onMouseUp);
    window.removeEventListener("resize", this.onWindowResize);
  },

  mounted(){
    
    this.$refs.fromCircle.addEventListener("mousedown", this.onMouseDownCircleFrom);
    this.$refs.toCircle.addEventListener("mousedown", this.onMouseDownCircleTo);

    this.$refs.fromCircle.addEventListener("touchstart", this.onMouseDownCircleFrom);
    this.$refs.toCircle.addEventListener("touchstart", this.onMouseDownCircleTo);

    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("touchmove", this.onMouseMove);
    window.addEventListener("mouseup", this.onMouseUp);
    window.addEventListener("touchend", this.onMouseUp);
    window.addEventListener("resize", this.onWindowResize);

    this.init();
    this.$watch(
      () => this.updateTrigger,
      (toParams, previousParams) => {
        this.recalculate();
        this.$nextTick(() => {
          this.recalculate();
        });
      }
    );

    

    this.$nextTick(() => {
      
      this.setInitPos();
      this.recalculate();
      this.setInitValues();

    });
     
    // var th = this;
    // setTimeout(function(){
    //   th.init();
    // },4000);

    this.$watch(
      () => this.fromValue,
      (toParams, previousParams) => {
        this.setFromNumberPos();
        if (this.fromValue && this.toValue){
          this.changeTrigger = this.fromValue+"_"+this.toValue;
        }
      }
    );
    this.$watch(
      () => this.toValue,
      (toParams, previousParams) => {
        this.setToNumberPos();
        if (this.fromValue && this.toValue){
          this.changeTrigger = this.fromValue+"_"+this.toValue;
        }
      }
    );
    this.$watch(
      () => this.changeTrigger,
      (toParams, previousParams) => {
        
        //This will change data realtime (could be too heavy)
        //this.emitChanged();
      }
    );
    this.$watch(
      () => this.initFrom,
      (toParams, previousParams) => {
        if (this.initFrom){
          this.setInitPos();
          this.recalculate();
          this.setInitValues();
        }
      }
    );
    this.$watch(
      () => this.initTo,
      (toParams, previousParams) => {
        if (this.initTo){
          this.setInitPos();
          this.recalculate();
          this.setInitValues();
        }
      }
    );
  },
  created(){
    this.init();
    this.abAmount = new AbAmount();
  },
  methods: {
    init: function(){

      this.setInitValues();
      // this.recalculate(); 
      
     
    },
    setInitPos: function(){

      var distance =  this.to - this.from;

      if (this.initFrom){
        this.fromPerc = (this.initFrom - this.from) / distance *100;
      }
      if (this.initTo){
        this.toPerc = (this.initTo - this.from) / distance * 100;
      }
    },
    setInitValues: function(){
      if (this.initFrom){
        this.fromValue = this.initFrom;
      }
      if (this.initTo){
        this.toValue = this.initTo;
      }
    },
    setFromNumberPos: function(){
      var w = this.$refs.fromNumber.offsetWidth;
      var cw = this.$refs.fromCircle.offsetWidth;
      var pos = (-w/2);
      this.fromAtEdge = false;
      if ( 2*(this.fromPX + pos) + cw <0 ){
        pos = (-cw/2)-this.fromPX;
        this.fromAtEdge = true;
      }
      this.$refs.fromNumber.style.marginLeft = pos+"px";
    },
    setToNumberPos: function(){
      var w = this.$refs.toNumber.offsetWidth;
      var cw = this.$refs.toCircle.offsetWidth;
      var pos = (-w/2);
      this.toAtEdge = false;

      if ( this.toPX > cw/2 + pos + this.lineLengthPX){
      // if ( this.toPX - pos > this.lineLengthPX + cw/2 ){
        // pos = pos + (this.toPX-this.lineLengthPX);
        pos = cw/2 - w- this.toPX + this.lineLengthPX;
        this.toAtEdge = true;
      }
      this.$refs.toNumber.style.marginLeft = pos+"px";
    },
    colideNumbers: function(){
      var fw = this.$refs.fromNumber.offsetWidth;
      var tw = this.$refs.toNumber.offsetWidth;

      var fl = parseFloat(this.$refs.fromNumber.style.marginLeft);
      var tl = parseFloat(this.$refs.toNumber.style.marginLeft);

      var cw = this.$refs.toCircle.offsetWidth;

      var fromSide = this.fromPX + fl + fw;
      var toSide = this.toPX + tl;
      
      var dist = toSide - fromSide

      if (dist < this.collisionDistance){
        if (this.fromAtEdge){
          this.collisionShiftFrom = 0;
          this.collisionShiftTo = ((this.collisionDistance-dist) );
        }else if (this.toAtEdge){
          this.collisionShiftFrom = -((this.collisionDistance-dist) );  
          this.collisionShiftTo = 0;
        }else{
          this.collisionShiftFrom = -((this.collisionDistance-dist) )/2;
          this.collisionShiftTo = ((this.collisionDistance-dist) )/2;
        }
      }else{
        this.collisionShiftFrom = 0;
        this.collisionShiftTo = 0;
      }

    },
    toggleFilter: function(e){
      
    },
    hideFilter: function(){
      
    },
    getMouseXFromEvent: function(e){
      var touch = null;
      if (e.originalEvent && e.originalEvent.touches){
        touch = e.originalEvent.touches[0];
      }
      
      if (touch && touch.pageX){
        return touch.pageX;
      }
      return e.pageX;

    },
    
    recalculate: function(){

      
      if (this.$refs.lineLength){
        
        var maxValueDistance = this.to - this.from;
        this.lineLengthPX = this.$refs.lineLength.offsetWidth;
        this.valueStepPerPX = maxValueDistance / this.lineLengthPX;


          
        this.lastToPX = this.lineLengthPX * (this.toPerc / 100);
        this.toPX = this.lineLengthPX * (this.toPerc / 100);

        this.lastFromPX = this.lineLengthPX * (this.fromPerc / 100);
        this.fromPX = this.lineLengthPX * (this.fromPerc / 100);
        

        this.fromValue = this.valueStepPerPX * this.fromPX + this.from;
        this.toValue = this.valueStepPerPX * this.toPX + this.from;
        // var from = 

        // fromValue


        // var valueDistance = this.toValue - this.fromValue;
        this.setFromNumberPos();
        this.setToNumberPos();

        this.colideNumbers();

      }
      
    },
    onWindowResize: function(e){
      this.recalculate();
    },
    onMouseDownCircleFrom: function(e){
      e.preventDefault();
      e.stopPropagation();
      this.recalculate();
      this.selectedCircle = "from";
      this.offsetX = this.getMouseXFromEvent(e);

      //Fix for outside click
      var pf = this.$el.closest(".prefilter-field");
      if (pf){
        pf.classList.add("skip-outside-click");
      }
    },
    onMouseDownCircleTo: function(e){
      e.preventDefault();
      e.stopPropagation();
      this.recalculate();
      this.selectedCircle = "to";
      this.offsetX = this.getMouseXFromEvent(e);

      //Fix for outside click
      var pf = this.$el.closest(".prefilter-field");
      if (pf){
        pf.classList.add("skip-outside-click");
      }
    },
    onMouseMove: function(e){

      if (this.selectedCircle == "from"){
        e.preventDefault();
        e.stopPropagation();

        var x = this.getMouseXFromEvent(e) - this.offsetX + this.lastFromPX;
        if (x < 0){ x = 0; }
        if (x > this.toPX){ x = this.toPX; }
        this.fromPX = x;

        this.fromPerc = this.fromPX/this.lineLengthPX*100;
        this.fromValue = this.valueStepPerPX * this.fromPX + this.from;

        this.colideNumbers();
      }
      if (this.selectedCircle == "to"){
        e.preventDefault();
        e.stopPropagation();
        
        var x = this.getMouseXFromEvent(e) - this.offsetX + this.lastToPX;
        if (x>=this.lineLengthPX){ x = this.lineLengthPX; }
        if (x < this.fromPX){ x = this.fromPX; }
        this.toPX = x;

        this.toPerc = this.toPX/this.lineLengthPX*100;
        this.toValue = this.valueStepPerPX * this.toPX + this.from;

        this.colideNumbers();
      }

      // if (this.$refs.fromCircle){
      //   this.$refs.fromCircle.style.left = 200+"px";
      // }
    },
    formatValue: function(val){
      if (val!=null){
        return this.formatPrice(val);
      }
      return null;
    },
    onMouseUp: function(e){
      if (this.selectedCircle){
        e.preventDefault();
        e.stopPropagation();

        this.lastFromPX = this.fromPX * 1;
        this.lastToPX = this.toPX * 1;

        this.selectedCircle = null;

        this.emitChanged();
      }

      var pf = this.$el.closest(".prefilter-field");
      if (pf){
        var t = setTimeout(function(){
          pf.classList.remove("skip-outside-click");
          clearTimeout(t);
        },10);
      }
    
      
    },
    emitChanged: function(){
      
      this.$emit('changed', {'from': this.fromValue, 'to': this.toValue});
    },
    formatPrice(value){
        if (value === undefined) {
            return "0";
        }
        
        return this.abAmount.format(value)+"";
    }
    
  },
 
}
</script>
<style lang="scss">
@import "~@/styles/variables.scss";

.interval-field{
  height: 54px;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  cursor: default;

  margin-top: 24px;
  .inner{
    position: relative;
    height: 100%;
    display: block;
    margin-left: 0px;
    margin-right: 0px;
  }
  .number{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #4C6C69;
    position: absolute;
    left: 50%;
    top: 35px;

    font-variant-numeric: lining-nums;

    // &:after{
    //   content: "";
    //   font-family: monospace;
    // }

    // -webkit-transition: all 0.04s linear;
    // -moz-transition: all 0.04s linear;
    // -o-transition: all 0.04s linear;
    // -ms-transition: all 0.04s linear;
    // transition: all 0.04s linear;
  }
  .from-circle,
  .to-circle{
    cursor: pointer;
    display: block;
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    margin-left: -12px;
    margin-top: -12px;
    background: #69BDB6;
    top: 0px;
    left: 0px;

    // -webkit-transition: all 0.06s linear;
    // -moz-transition: all 0.06s linear;
    // -o-transition: all 0.06s linear;
    // -ms-transition: all 0.06s linear;
    // transition: all 0.06s linear;

  }
  .from-circle{

  }
  .to-circle{
    left: 0%;
  }
  .bg-line{
    display: block;
    position: absolute;
    height: 3px;
    
    margin-top: -1.5px;
    top: 0px;
    left: -12px;
    right: -12px;
    border-radius: 3px;
    background: #C7E5E4;

    
  }
  .inner-line{
    display: block;
    display: block;
    position: absolute;
    height: 3px;
    margin-top: -1.5px;
    top: 0px;
    border-radius: 3px;
    background: #69BDB6;
  }

}
.interval-field.dark{
  .number{
    color: #1F4743;
  }
  .from-circle,
  .to-circle{
    background: #4C6C69;
  }
  
  .bg-line{
    // background: #4C6C69;
  }
  .inner-line{
    background: #4C6C69;
  }

}
.interval-field.small{
  margin-top: 16px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  height: 36px;
  .number{
    top: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
    letter-spacing: -0.01em;
  }
  .from-circle,
  .to-circle{
    height: 16px;
    width: 16px;
    border-radius: 8px;
    margin-left: -8px;
    margin-top: -8px;
  }
  
  .bg-line{
    height: 2px;
    margin-top: -1px;
    left: -8px;
    right: -8px;
  }
  .inner-line{
    height: 2px;
    margin-top: -1px;
  }
}
// @media (max-width: 1500px) {
//   .interval-field{
//     width: 200px;
//   }
// }

</style>