import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import Actions from './actions';
import ConfigEpi from './configEpi';
import ConfigModel from './configModel';
import Getters from './getters';
import Mutations from './mutations';
import epiContent from "@/components/leasingpro/episerver/store/modules/epiContent";
import epiContext from "@/components/leasingpro/episerver/store/modules/epiContext";
import dataSource from "@/components/leasingpro/episerver/store/modules/dataSource";
import leasingContext from "@/components/leasingpro/components/overview/leasing/store/modules/leasingContext";
import authenticationContext from "@/components/leasingpro/episerver/store/modules/authenticationContext";

let store;
let storeOptions: StoreOptions<ConfigModel>;

Vue.use(Vuex)

storeOptions = ({
    state: {
        name: "Opendo",
        //indlæser epi opsætning
        configEpi: new ConfigEpi(),
        adminData: {},
        adminDashboard: false,
        userPresent: false,
        businessCustomer: {},
        initialCarSearch: '',
        epiRouting: [],
        usabilla: { count: 0, mounted: 0},
        appReplaceRoute: false,
        consentSetInApp: false
    },
    mutations: Mutations,
    actions: Actions,
    getters: Getters,
    modules: {
        epiContent,
        epiContext,
        dataSource,
        authenticationContext,
        leasingContext,
    }
});


store = new Vuex.Store<ConfigModel>(storeOptions)


export default store
