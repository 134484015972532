import { Component, Vue } from 'vue-property-decorator';

import PrefilterField from '../../../../../../ui/prefilter-field/PrefilterField';
import UserData from '../../providers/UserData';
import Utils from '../../providers/Utils';
import template from './QuickSearchBar.vue';

@Component({
  mixins: [template],
  props: ['type', 'allOptions', 'monthlyIntervalFrom', 'monthlyIntervalTo'],
  components: {
      'PrefilterField' : PrefilterField
  }
})
export default class SelectField extends Vue {
    userData: UserData = this.$store.getters.getUserData;
    utils = new Utils();
}