<template>
  <div :class="'checkbox-field '+(customClass ? customClass+' ': '')+(theme ? ' theme-'+theme : '')+(locked ? ' locked' : '')+(disabled ? ' disabled' : '')+(size ? ' size-'+size : '')+(checked ? ' checked' : '')+(children ? ' has-children' : '')">
    <label class="inner"><span class="check-icon">
      <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.4465 0.266666L4.3035 6.40964L1.55374 3.65987C1.19818 3.30432 0.622139 3.30432 0.266666 3.65987C-0.0888885 4.01543 -0.0888885 4.59147 0.266666 4.94695L3.65987 8.34016C3.83692 8.51721 4.07091 8.6072 4.3034 8.6072C4.53591 8.6072 4.7684 8.51867 4.94692 8.34016L11.7333 1.55374C12.0889 1.19818 12.0889 0.62214 11.7333 0.266666C11.3779 -0.0888885 10.8018 -0.0888885 10.4463 0.266666H10.4465Z" fill="white"/>
      </svg>
    </span><input :type="'checkbox'" :disabled="(forceDisabled)" v-model="checked" :name="(inputName ? inputName : value)"  :value="value" v-on:change="change" /><span class="label">{{name}}</span>
    <span class="arrow transition-all" v-if="children && children.length>0">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 7.99799L10.248 12.4998C10.2011 12.5527 10.1435 12.595 10.079 12.624C10.0146 12.653 9.94468 12.668 9.874 12.668C9.80332 12.668 9.73344 12.653 9.66897 12.624C9.6045 12.595 9.54691 12.5527 9.5 12.4998L5.00402 7.99799" stroke="#1F4743" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span></label>
    <div class="checkbox-children" v-if="children && children.length>0" ref="childrenDropdown">
      <div :class="'child-item'+(item.disabled ? ' disabled' : '')" v-for="(item, index) in children" :key="'checkbox-item-'+value+'-children-'+index">
        <CheckboxField :name="item.name+(item.count ? ' ('+item.count+')' : '')" :inputName="item.inputName" :disabled="(item.disabled || (item.count<=0))" :locked="item.locked" :value="item.value" :isChecked="item.checked" @changed="change" />
      </div>
    </div>

    <span class="error-text" v-if="visibleError && errorText" >{{errorText}}</span>
  </div>
</template>

<script>

import gsap from 'gsap';
import eventBus from '../../../eventBus';

export default {
  name: 'CheckboxField',
  props: ['theme', 'size', 'name', 'inputName', 'value', 'children', 'isChecked', 'customClass', 'locked', 'disabled', 'forceDisabled'],
  data() {
    return {
      visibleError: false,
      errorText: null,
      checked: false 
    }
  },
  beforeMount(){
    // alert(GetCurrentRouteName());
    
  },
  mounted(){
    this.init();
  },
  created(){
    this.$watch(
      () => this.isChecked,
      (toParams, previousParams) => {
        this.handleIsChecked();
      }
    );
    this.$watch(
      () => this.checked,
      (toParams, previousParams) => {
        this.handleChildrenDropdown();
      }
    );
    
  },
  methods: {
    init: function(){
      this.handleIsChecked();
    },
    showError: function(text){
      if (text){
        this.errorText = text;
      }
      this.visibleError = true;
    },
    handleIsChecked: function(){
      if (this.isChecked){
        this.checked = true;
      }else{
        this.checked = false;
      }
    },
    handleChildrenDropdown: function(){
      if (this.$refs.childrenDropdown){
        var th = this;
        if (this.checked){
          this.$nextTick(() => {
            var h = this.$refs.childrenDropdown.offsetHeight;
            gsap.to(this.$refs.childrenDropdown, { duration: 0, opacity: 0, height: 0});
            gsap.to(this.$refs.childrenDropdown, { duration: 0.3, opacity: 1, height: h, onComplete:function(){
              th.$refs.childrenDropdown.style.height = "";
            }});
          });
        }else{
          var th = this;
          var h = this.$refs.childrenDropdown.offsetHeight;
          this.$nextTick(() => {
            gsap.to(this.$refs.childrenDropdown, { duration: 0, opacity: 1, height: h});
            gsap.to(this.$refs.childrenDropdown, { duration: 0.3, opacity: 0, height: 0, onComplete:function(){
              th.$refs.childrenDropdown.style.height = "";
            }});
          });
        }
      }
    },
    change:function(e){
      // console.log(this.checked);
      var th = this;

      if (th.$el.classList.contains("has-children")){
        if (!this.checked){

        }
      }
      this.visibleError = false;

      this.$emit("changed", {'value': this.value, 'checked': this.checked});
    }
  },
 
}
</script>
<style lang="scss">
@import "~@/styles/variables.scss";
.checkbox-field{

  cursor: pointer;

  margin-top: 4px;

  input{
    display: none;
  }
  .inner{
    border-radius: 8px;
    min-height: 36px;
    padding: 8px;
    box-sizing: border-box;

    position: relative;
    display: block;
    cursor: pointer;
  }
  .label{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: #4C6C69;
    vertical-align: top;
    display: inline-block;

    width: calc(100% - 32px);
  }
  .arrow{
    display: inline-block;
    position: absolute;
    right: 8px;
    top: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;

    -webkit-transform: rotate(0deg) scale(0.7);
    -moz-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
    opacity: 0;
  }
  .checkbox-children{
    height: 0px;
    overflow: hidden;
    padding-left: 24px;
  }
  .check-icon{
    display: inline-block;
    vertical-align: top;
    border: 1.5px solid #4C6C69;
    box-sizing: border-box;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    margin-right: 12px;
    text-align: center;
    svg{
      display: inline-block;
      vertical-align: top;
      margin-top: 4px;
      opacity: 0;
    }
  }

  &.radio-style{
    .check-icon{
      border-radius: 10px;
    }
  }

  &.disabled{
    pointer-events: none;
    opacity: 0.5;
  }
  &.locked{
    pointer-events: none;
    &.checked{
      > .inner{
        background: none;
        .label{
          color: #1F4743;
        }
        .check-icon{
          background: #1F4743;
          border: 1.5px solid #1F4743;
          svg{
            opacity: 1;
          }
        }
      }
    }
  }

  &:not(.locked):not(.disabled):hover{
    > .inner{
      background: rgba(105, 189, 182, 0.09);
      .label{
        color: #1F4743;
      }
    }
  }
  &.has-children{
    &:not(.locked):not(.disabled):hover{
      
      .arrow{
        -webkit-transform: rotate(0deg) scale(1);
        -moz-transform: rotate(0deg) scale(1);
        transform: rotate(0deg) scale(1);
        opacity: 1;
      }
    }
  }
  &.checked{
    .checkbox-children{
      height: auto;
    }
    > .inner{
      background: rgba(105, 189, 182, 0.09);
      .label{
        color: #1F4743;
      }
      .check-icon{
        background: #69BDB6;
        border: 1.5px solid #69BDB6;
        svg{
          opacity: 1;
        }
      }
    }
    &:not(.locked):not(.disabled):hover .arrow,
    .arrow{
      -webkit-transform: rotate(180deg) scale(1);
      -moz-transform: rotate(180deg) scale(1);
      transform: rotate(180deg) scale(1);
      opacity: 1;
    }
    
  }

  .error-text{
    font-size: 12px;
    padding-left: 4px;
    display: block;
    text-align: left;
    color: #DF4343;
    margin-top: 4px;
  }
}
</style>