<template>

    <article class="reveal__block">
        <div class="reveal__block__wrap">
            <section class="reveal -line -inline">
                <input type="checkbox" class="state" aria-hidden="true" :id="id">
                <div :class="{'reveal__before': values.length >= revealBlockShowCount}">
                    <div class="row" :class="{'justify-content-center': contentCenter}" v-for="(data, index) in values">
                        <div :class="rowClass" v-show="index < revealBlockShowCount">
                            <p v-if="index===0" class="secondary-font__large__bolder">{{header}}</p>
                            <toggleline-component :id="1"
                                                  :index="index"
                                                  :name="data"
                                                  :selected="selected"
                                                  :included="included"
                                                  :hideArrow="hideDescArrow">
                            </toggleline-component>
                        </div>
                    </div>
                </div>
                <label :for="id" class="reveal__label" v-show="values.length >= revealBlockShowCount">
                    <span class="reveal__label__text">
                        <span class="reveal__label__text--more secondary-font__regular">Se flere</span>
                        <span class="reveal__label__text--less">&nbsp;</span>
                    </span>
                    <span class="reveal__toggle">
                        <svg viewBox="0 0 30 30" class="reveal__label__icon"><path d="M10,14 L15,18 L20,14"></path></svg>
                    </span>
                </label>
                <div class="reveal__panel" v-show="values.length >= revealBlockShowCount">
                    <div class="reveal__panel__inner">
                        <div class="row" :class="{'justify-content-center': contentCenter}" v-for="(data, index) in values">
                            <div :class="rowClass" v-show="index >= revealBlockShowCount">
                                <toggleline-component :description="description"
                                                      :id="1"
                                                      :index="index"
                                                      :name="data"
                                                      :selected="selected"
                                                      :included="included"
                                                      :hideArrow="hideDescArrow">
                                </toggleline-component>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </article>


</template>

<script lang="ts">

        /*  SETUP
        //I typsescript
        import RevealBlockComponent from '../../../../shared/revealblock/RevealBlockToggle.vue';
        @Component({
        name: 'temp name',
        components: {
            'reveal-block-component': RevealBlockComponent,
        }
        })

        //I html
        <reveal-block-component
                            :header="'INKLUDERET UDSTYR'"
                            :rowClass="'col-12 col-md-7'"
                            :revealBlockShowCount="4"
                            :id="detailedCar.BasicVehicleInformation.Id"
                            :values="detailedCar.BasicVehicleInformation.Specifications">
       </reveal-block-component>

        */


    import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from "vue-property-decorator";
    import ToggleLineComponent from '../toggleline/ToggleLineSimpleComponent.vue';

    @Component({
        name: 'reveal-block-toggle-component',
        components: {
            'toggleline-component': ToggleLineComponent
        }
    })

    export default class RevealBlockToggleComponent extends Vue {
        @Prop() values: any[];
        @Prop() id: number;
        @Prop({ default: 12 }) revealBlockShowCount: number;
        @Prop({ default: 'col-12 col-sm-6' }) rowClass: string;
        @Prop() header: string;
        @Prop() description: string;
        @Prop({ default: true }) contentCenter: boolean;
        @Prop({ default: true }) selected: boolean;
        @Prop({ default: true }) included: boolean;
        @Prop({ default: true }) hideDescArrow: boolean;

        mounted() {
            let self = this;
        }
    }
    
</script>

<style lang="scss" scoped>
    @import "../../abdk/styles/legacy/style.scss";

    .reveal {
        text-align: left;
    }

    .reveal__label {
        text-align: center;
        margin: 0px;
    }

    .reveal__toggle {
        background-color: $color-grey-1;
    }

    .reveal__block__wrap {
        max-width: none;
    }

    .reveal__before::before {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #F9F9F9 100%);
    }

</style>
