<template>
  <div v-if="loggedIn">
    <BLHeader/>
    <DynamicPage v-if="bl_cmsPageData" v-bind:cmsGlobalData="cmsGlobalData" v-bind:cmsPageData="bl_cmsPageData"/>
  </div>
</template>

<script>

import * as localForage from "localforage";

import DynamicPage from '@/views/dynamic_page/DynamicPage';
import BLHeader from "./BLHeader.vue";
import bankloginData from "../BankloginData";

export default {
  components: {
    BLHeader : BLHeader,
    DynamicPage: DynamicPage
  },
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      userName: null,
      bankName: null,
      bankLogo: null,
      loggedIn: false,
      bankloginTokenKey: "BankloginToken",
      bl_cmsPageData: null
      
    }
  },
  beforeMount(){
    
  },
  created(){
    var th = this;

    th.loggedIn = bankloginData.loggedIn;

    if (!bankloginData.loggedIn){
      th.getBankloginData();
    }
    
    this.$watch(
      () => this.$route.path,
      (toParams, previousParams) => {
        // alert("a"+loadingData);
        th.getBankloginData();
    });
  },
  mounted(){
    var th = this;

    var loadingData = true;
    
    
    th.getBankloginData();

    
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {


        // this.pageUrl = this.cmsPageData.page.url;
        // console.log(this.pageUrl);
        
        // this.content = this.cmsPageData.page.pengeinstitutterr.content;

        // this.stepsContent = this.cmsPageData.page.pengeinstitutterr.steps;

        // this.content.right_side[1].content = this.content.right_side[1].content + this.content.right_side[1].content;
    });


    localForage.getItem(th.bankloginTokenKey, function(err, token) {
      if (token !== null && token.token){
        th.userName = token.user_name;
        th.bankName = token.bank_name;
        th.bankLogo = token.bank_logo;
      }else{
         
      }
  });


    
    
  },
  methods: {
    
    getBankloginData(){
      var th = this;
      console.log("getting");
      bankloginData.recoverFromToken(function(){
        th.loggedIn = bankloginData.loggedIn;
        if (bankloginData.pageData){
          window.cmsPageData = {"loaded": true, "page": {"dynamic_page": bankloginData.pageData}};
          th.bl_cmsPageData = {"loaded": true, "page": {"dynamic_page": bankloginData.pageData}};
          if (typeof cmsLoadedPageDataEvent === 'undefined') {
            var cmsLoadedPageDataEvent = new Event('loaded-cms-page-data');
          }
          window.dispatchEvent(cmsLoadedPageDataEvent);
        }

      },function(){
        th.loggedIn = bankloginData.loggedIn;
        th.$router.replace({ 'name': "Banklogin" });
      });
    },
    beforeDestroy() {
      
    }
    
  },
 
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


@media (max-width: 968px) {
 
}

@media (max-width: 668px) {
  
}

@media (max-width: 768px) {
  
}

@media (max-width: #{$screen-mobile}) {
  
}

@media (max-width: 1000px) {
 
}

@media (max-width: #{$screen-mobile}) {
 
}

</style>