<template>
    <div class="card-hover">
        <div class="p-2" role="button" v-on:click="carSelected(item)">
            <progressive-img v-if="item.image !== undefined && item.image !== null" class="card-img-top -loading" :src="item.image" alt="" />
            <img v-else class="card-img-top" src="../assets/image/270x200.png" alt="" />
            <div>
                <p class="primary-font__bold__large" style="margin-bottom: 0;">{{item.make.name}} {{item.model.name}}</p>
                <p class="secondary-font__large my-1">
                    <span v-on:click="carSelected(item)" class="spanLink" role="button" v-if="item.number_of_vehicles > 1">{{getVariants()}}</span>
                    <span v-else>&nbsp;</span>
                </p>
                <p v-if="isCorporate && useTax()" class="secondary-font__large mb-0 font-family-work-sans-medium">{{getTaxValue()}}</p>
                <span v-html="getMonthlyPayment()" class="secondary-font__large font-family-work-sans-medium"></span>

                <p v-if="formatPrice(item.price.downpayment.total) === '0'" class="secondary-font__large my-1 font-family-work-sans-medium" style="color:#E51147;">{{getInitialPayment()}}</p>
                <p v-else class="secondary-font__large my-1 font-family-work-sans-medium">{{getInitialPayment()}}</p>

                <div v-if="showReleasingBanner()" v-on:click.stop="bannerSelected(item)" class="secondary-font__regular bgcolor-secondary__blue-light isUsed__size">{{getReleasingBanner()}}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
    import AbAmount from '../../common/ab_amount';
    import router from '@/router';
    import UserData from '../providers/UserData';
    import { VehicleGroup, Vehicle } from '../model/apimodels';
    import { VehicleType } from '../model/car';

    @Component({
        name: 'leasing-carcard-component',
        components: {
        }
    })
    export default class CarCard extends Vue {
        userData: UserData = this.$store.getters.getUserData;
        @Prop() item: VehicleGroup;
        @Prop() isCorporate: boolean;
        @Prop() isReleasing: boolean;
        isDealer = this.$store.getters.getUserData.isDealer;


        abAmount = new AbAmount();

        formatPrice(value: any) {
            if (value === undefined) {
                return null;
            }
            return this.abAmount.format(value);
        }

        getInitialPayment() {
            let price = this.formatPrice(this.item.price.downpayment.total);
            let text = this.userData.epiData.step1.initialPaymentText;
            return text.replace("{{price}}", price+"");
        }

        getTaxValue() {
            let price = this.formatPrice(this.item.price.tax.taxable_value);
            let text = this.userData.epiData.taxValueText;
            return text.replace("{{price}}", price+"");
        }

        getMonthlyPayment() {
            let price = this.formatPrice(this.item.price.monthly_payment.total);
            let span = '{{price}}'
            span = span.replace("{{price}}", price+"");
            let text = this.userData.epiData.monthlyPaymentText;
            return text.replace("{{price}}", span);
        }

        getReleasingBanner() {
            return this.userData.epiData.step1.releasingBannerText
        }

        getVariants() {
            let count = this.isCorporate ? this.item.number_of_vehicles : this.isReleasing ? this.item.number_of_used_vehicles : this.item.number_of_new_vehicles;
            if (!count) {
                count = this.item.number_of_vehicles;
            }

            return this.userData.epiData.step1.seeVariantText.replace("{{count}}", count+"")
        }

        useTax() {
            return this.item.plate_type === VehicleType.HVID_PLADE
        }



        showReleasingBanner() {
            //hvis det IKKE er en releasing epi block
            let isNotUsedCarSite = !this.$store.getters.getUserData.isUsed;

            //hvis bilen ER sat som releasing i Car commerce
            let isUsedCar = this.item.used;

            return isNotUsedCarSite && isUsedCar && !this.isReleasing;
        }

        showHasVariantsBanner() {
            //bilen har flere varianter og derved vil mellem siden blive vist ved valg
            return this.item.number_of_vehicles > 1;
        }

        bannerSelected(car: VehicleGroup) {
            //hvis der kun er en model under gruppen, hop direkte ind i bil beregneren
                router.push({
                    name: this.isCorporate ? 'ErhvervCarDetail' : this.isReleasing ? 'ReleasingDetail' : this.isDealer ? 'DealerDetail' : 'CarDetail',
                    params: {
                        make: car.make.slug,
                        model: car.model.slug,
                    },
                    query: { used: 'true' }
                })

        }

        carSelected(car: VehicleGroup) {
            //hvis der kun er en model under gruppen, hop direkte ind i bil beregneren
            let count = this.isCorporate ? this.item.number_of_vehicles : this.isReleasing ? this.item.number_of_used_vehicles : this.item.number_of_new_vehicles;

            if (!count) {
                count = this.item.number_of_vehicles;
            }

            if (count === 1) {
                router.push({
                    name: this.isCorporate ? 'ErhvervCarCalc' : this.isReleasing ? 'ReleasingCarCalc' : this.isDealer ? 'DealerCarCalc' : 'CarCalc',
                    params: {
                        make: car.make.slug,
                        model: car.model.slug,
                        vehicleId: car.vehicle_ids[0].toString()
                    }
                })
            } else if (this.isReleasing) {
                router.push({
                    name: this.isCorporate ? 'ErhvervCarDetail' : this.isReleasing ? 'ReleasingDetail' : this.isDealer ? 'DealerDetail' : 'CarDetail',
                    params: {
                        make: car.make.slug,
                        model: car.model.slug,
                    },
                    query: { used: 'true' }
                })
            } else {
                router.push({
                    name: this.isCorporate ? 'ErhvervCarDetail' : this.isReleasing ? 'ReleasingDetail' : this.isDealer ? 'DealerDetail' : 'CarDetail',
                    params: {
                        make: car.make.slug,
                        model: car.model.slug,
                    }
                })
            }
        }

    }

</script>

<style lang="scss" scoped>
    @import "../../abdk/styles/legacy/style.scss";

    .card-img-top {
        min-height: 240px;
        max-height: 200px;
    }

    .isUsed__size{
        padding: 5px 0;
        margin: 10px 25%;
        &:hover {
                    background-color: #A2DAF4
                }
    }

    .card-hover {
        min-height: 400px;
        padding: 3px !important;
    }

    .card-hover:hover {
        border-style: solid;
        border-color: #D5EDFA;
        border-width: 3px;
        padding: 0px !important;
    }
    /*
    .hero__content__pluskunde-badge {
        width: 5em;
        height: 5em;
        right: 15px;
        top: 15px;
    }*/

</style>
