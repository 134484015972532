import { Component, Vue } from 'vue-property-decorator';

import template from './InfoButton.vue';

@Component({
  mixins: [template],
  props: ['info'],
})
export default class InfoButton extends Vue {

}