import { Component, Vue } from "vue-property-decorator";

import template from "./JobsSingle.vue"

@Component({
    mixins:[template],
    props: ['cmsGlobalData', 'cmsPageData']
})
export default class JobsSingle extends Vue {

    

}
