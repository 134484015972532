class AbValidator {

    _forms: HTMLCollectionOf<HTMLFormElement>;
    _i: number;


    constructor(forms: HTMLCollectionOf<HTMLFormElement> = null) {
        if (forms===null) {
            return;
        }
        this._forms = forms;
        this._i = this._forms.length;
        let arr: Array<HTMLFormElement> = [];

        while (this._i--) {
            let formElm = this._forms[this._i];
            formElm.noValidate === false ? arr.push(formElm) : false;
        }

        let j = arr.length;
        while (j--) {
            this.applyValidationToFormInputs(arr[j]);
        }
    }

    /**
     * Blur event that checks if input is valid
     * @param event
     */
    controlInputValidity(event: Event) {
        event.preventDefault();
        let _hiddenVis: string = "hiddenVis";
        const _hiddenDis: string = "hiddenDis";
        let _inputError: string = "inputError";
        let formControl = event.target as HTMLInputElement;
        function findAncestor(elm: HTMLElement, cssClass: string) {
            while ((elm = elm.parentElement) && !elm.classList.contains(cssClass));
            return elm;
        }
        let formItem = findAncestor(formControl, "form__item");

        if (formItem !== null) {
            let validationErrorTextElm = formItem.querySelector("[data-js=validation-error-text]");
            if (validationErrorTextElm !== null) {
                let isValid = formControl.checkValidity();
                if (isValid) {
                    if (formControl.classList.contains(_inputError)) {
                        formControl.classList.remove(_inputError);
                    }
                    if (!validationErrorTextElm.classList.contains(_hiddenVis)) {
                        validationErrorTextElm.classList.add(_hiddenVis)
                    }
                } else {
                    if (!formControl.classList.contains(_inputError)) {
                        formControl.classList.add(_inputError);
                    }
                    if (validationErrorTextElm.classList.contains(_hiddenVis)) {
                        validationErrorTextElm.classList.remove(_hiddenVis);
                    }
                    if (validationErrorTextElm.classList.contains(_hiddenDis)) {
                        validationErrorTextElm.classList.remove(_hiddenDis);
                    }
                }
            }
        }    
    }

    /**
     * invalid event on form submit
     * @param event
     */
    controlFormValidity(event: Event) {
        event.preventDefault();
        let _hiddenVis: string = "hiddenVis";
        let _inputError: string = "inputError";
        let formControl = event.target as HTMLInputElement;
        function findAncestor(elm: HTMLElement, cssClass: string) {
            while ((elm = elm.parentElement) && !elm.classList.contains(cssClass));
            return elm;
        }
        let formItem = findAncestor(formControl, "form__item");
        if (formItem !== null) {
            let validationErrorTextElm = formItem.querySelector("[data-js=validation-error-text]");
            if (validationErrorTextElm !== null) {
                if (!formControl.classList.contains(_inputError)) {
                    formControl.classList.add(_inputError);
                }
                if (validationErrorTextElm.classList.contains(_hiddenVis)) {
                    validationErrorTextElm.classList.remove(_hiddenVis);
                }
            }
        }
    }

    /**
     * Apply event handler on control elements
     * @param formElm
     */
    applyValidationToFormInputs(formElm: HTMLFormElement) {
        let i: number = formElm.elements.length;

        let requiredFormControls: Array<HTMLInputElement> = [];
        while (i--) {
            let formControl = formElm[i] as HTMLInputElement;
            if (formControl.id === "customerEmail") requiredFormControls.push(formControl); //Customer emails must be validated eventhough the field is not required 
            formControl.required === true ? requiredFormControls.push(formControl) : false;
        }
        let j: number = requiredFormControls.length;
        while (j--) {
            let requiredFormControl: HTMLInputElement = requiredFormControls[j];
            requiredFormControl.addEventListener("blur", this.controlInputValidity);
            requiredFormControl.addEventListener("invalid", this.controlFormValidity);
            if (requiredFormControl.type === "radio") {
                requiredFormControl.addEventListener("change", this.controlInputValidity);
            }
        }
    }

    validEmail(email: string) {
        /* tslint:disable-next-line */
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    /**
     * display none for default errors, when customerror is shown.
     * Default errors take up empty space if class '.hiddenDis' isn't applied
     * @param input customErrorInput
     */
    private displayNoneDefaultError(input: HTMLElement): void {
        const defaultError = input.closest('.form__item').querySelector('[data-js="validation-error-text"]');
        if(defaultError && !defaultError.classList.contains('hiddenDis')) {
            defaultError.classList.add('hiddenDis');
        }
    }

    public addCustomFormControlError(id: string, errorText: string) {
        let input = document.getElementById(id);
        input.classList.contains("inputError") ? null : input.classList.add("inputError");
        let customVal = document.getElementById(id + '-customError');
        customVal.innerHTML = errorText;
        customVal.classList.contains("hiddenVis") ? customVal.classList.remove("hiddenVis") : null;
        customVal.classList.contains("hiddenDis") ? customVal.classList.remove("hiddenDis") : null;
        this.displayNoneDefaultError(input);

    }

    public clearCustomFormControlsError() {
        let customErrors = document.querySelectorAll("[data-js='custom-validation-error-text']"),
            i = customErrors.length;
        while (i--) {
            customErrors[i].classList.contains("hiddenDis") ? null : customErrors[i].classList.add("hiddenDis");
        }
        let forms = document.forms,
            j = forms.length;
        while (j--) {
            let k = forms[j].elements.length;
            while (k--) {
                forms[j].elements[k].classList.contains("inputError") ? forms[j].elements[k].classList.remove("inputError") : null;
            }
        }
        // reset defaultErrors, if they are not active, they will still be .hiddenVis
        const defaultErrors = document.querySelectorAll('[data-js="validation-error-text"]');
        defaultErrors.forEach(defaultError => {
            if(defaultError.classList.contains('hiddenDis')) {
                defaultError.classList.remove('hiddenDis');
            }
        });
    }
}

export default AbValidator;
