

class AbCpr {

    private cprRegex: string = "^[0-9]{10}$";
    private daysInMonth: number[] = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    isValidCpr(value) {
        if (value === null || value === undefined) {
            return false;
       }
        if (value.match(this.cprRegex)) {
           //06 bliver til 6
           let month = parseInt(value.substring(2, 4));
           //måneder ikke over 12
            if (month === 0 || month > 12) return false; 

           let day = parseInt(value.substring(0, 2));
           //antal måneds dage skal passe med ovensteånde array
            if (day > this.daysInMonth[month - 1]) return false;

            return true;
        }

        return false;
    }

    testCprValidator() {
    //de sidste 4 er ikke valide
    let validCprNumbers: Array<string> = [
        "0104909995",
        "0104909989",
        "0107729995",
        "0108589995",
        "0108629996",
        "0201609995",
        "0201609996",
        "0201919990",
        "0201919995",
        "0201919996",
        "0211223989",
        "0212159995",
        "0504909989",
        "0504909995",
        "0506889996",
        "1007059995",
        "1110109996",
        "1310169995",
        "1310169996",
        "1502779995",
        "1502799995",
        "1502829995",
        "1509819996",
        "2103009996",
        "2311143995",
        "2509479989",
        "2512489996",
        "2911829996",
        "3001749995",
        "3103979995",
        "0113909995",
        "3204909989",
        "31039799950",
        "310397999",
        ]
        console.log("test cpr count: " + validCprNumbers.length);
        for (let i = 0; i < validCprNumbers.length; i++) {
            console.log(i +" - "+this.isValidCpr(validCprNumbers[i]));
        }

        return false;
    }
}

export default AbCpr;
