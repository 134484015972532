<template>

<div id="app-showroom">
    <router-view :key="$route.fullPath"></router-view>
</div>

</template>

<script lang="ts">
    import Vue from 'vue';
    import { Component } from 'vue-property-decorator';

    import VueProgressiveImage from 'vue-progressive-image';
    import leasingStore from './store';
    import UserData from "./components/providers/UserData";
    import VueScrollTo from "vue-scrollto";
    import eventBus from '../../../../eventBus';

    Vue.use(VueScrollTo)

    Vue.use(VueProgressiveImage, {
        //placeholder: '/Areas/CarListBlock/assets/img/car.jpg',
        //blur: 30
    })

    const VueUploadComponent = require('vue-upload-component');
    Vue.component('file-upload', VueUploadComponent);


    // If something else is using Vue.router a router property that is null will throw an error. Therefor make sure to only add the router property to the application if it is not null.
    // if (router) {
    //     (leasingApp as any).router = router;
    // }
    // import { sync } from "vuex-router-sync";
    // sync(leasingStore, leasingRouter);
    // const CMSProps = Vue.extend({
    //     props: ['cmsGlobalData', 'cmsPageData'],
    // });



    @Component({
        name: "leasing-component",
        components: {
        },
        store: leasingStore,

        methods:{
        },
        beforeMount(){
            leasingStore.dispatch('setUserData', new UserData());

            if ((window as any).cmsGlobalData){
                leasingStore.dispatch('setCMSGlobalData', (window as any).cmsGlobalData);
            }
            eventBus.$on("cmsGlobalDataSet", function(e){
               leasingStore.dispatch('setCMSGlobalData', (window as any).cmsGlobalData);
            });

        }
    })

    export default class App extends Vue {


    }
</script>

<style scoped lang="scss" src="./style.scss"></style>

