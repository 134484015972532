import { Component, Vue } from "vue-property-decorator";

import ArticleConversionBlock from '../../components/ui/article-conversion-block/ArticleConversionBlock';
import NewsSection from '../../components/ui/news-section/news-section';
import template from "./NewsSingle.vue"

@Component({
    mixins:[template],
    props: ['cmsGlobalData', 'cmsPageData'],
    components: {
        'news-section': NewsSection,
        'articleConversionBlock': ArticleConversionBlock
    }
})
export default class NewsSingle extends Vue {

    

}
