<template>
  <div class="single-jobs-page">


    <section class="top-hero fade-on-enter">
      <div class="wrap">
        <span v-if="date" class="date">{{date}}</span>
        <h1>{{title}}</h1>
        <div v-if="jobInfo" class="info">
          <p><span class="name">Lokation</span><span class="value">{{jobInfo.location}}</span></p>
          <p><span class="name">Kontakt</span><span class="value">{{jobInfo.contact}}</span></p>
          <p><span class="name">Deadline</span><span class="value">{{jobInfo.deadline}}</span></p>
          <p><span class="name">Type</span><span class="value">{{jobInfo.type}}</span></p>
        </div>


        <!-- <router-link class="button" :to="jobInfo.apply_button.url" >{{jobInfo.apply_button.title}}</router-link>-->
        <!-- <a class="button" :href="jobInfo.apply_button.url">{{jobInfo.apply_button.title}}</a> -->

        <!-- 
        <div v-if="thumbnail" class="image">
          <img :src="thumbnail" alt="thumbnail" />
        </div>
        -->
      </div>
    </section>

    <div class="page-content fade-on-enter">
      
      <div class="wrap">

        <div class="textual-content" v-html="content"></div>

      </div>
      <span v-if="cmsPageData && !cmsPageData.loaded"></span>
    </div>

    <section class="section fade-on-enter jobs-section" v-if="jobs">
      <div class="wrap">
        <div class="card-style links-list">
          <h2 class="title">Andre ledige stillinger</h2>
          <div class="buttons">
            <router-link class="item link-button-item" :to="'/'+jobsSlug+'/'+job.post_name" v-for="job in jobs" :key="job.ID" >
              <span class="label">
                {{job.post_title}}
              </span><span class="icon">
                <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.4766 1L30.4766 7L24.4766 13" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M30 7H2" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      thisSlug: "",
      jobsSlug: "jobs",
      date: null,
      title: null,
      content: null,
      relatedJobs: null,
      jobInfo: null,
      jobs: null
    }
  },
  beforeMount(){
    // alert(footerData.fields.copyright);
    // this.copyright = "b";
  },
  created(){
    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {
        
        this.thisSlug = this.cmsPageData.page.url;
        this.title = this.cmsPageData.page.job.post_title;
        this.content = this.cmsPageData.page.job.content_html;
        this.jobInfo = this.cmsPageData.page.job.info;
        this.date = this.cmsPageData.page.job.date;
        this.jobs = this.cmsPageData.page.job.related_jobs;
        
        
        // this.buttons = this.cmsGlobalData.footer.buttons;
      }
    );
  },
  mounted(){
    
  },
  methods: {

  },
 
}
</script>

<style lang="scss" scoped>
@import "~@/style.scss";
.top-hero{
  margin-top: 80px;
  text-align: center;
  margin-bottom: 60px;

  .date{
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #4C6C69;

    margin-bottom: 14px;
  }
  h1{
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 72px;
    text-align: center;
    color: #1F4743;
    max-width: 789px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 48px;
  }
  .item-tags{
    margin-bottom: 48px;
  }
  .image{
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    img{
      width: 100%;
      border-radius: 20px;
    }
  }
  .info{
    background: rgba(105, 189, 182, 0.09);
    border-radius: 20px;
    max-width: 690px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    padding: 40px;
    padding-bottom: 24px;
    padding-top: 20px;
    margin-bottom: 88px;

    p{
      padding-top: 16px;
      padding-bottom: 20px;
      border-top: 2px solid #E1F2F0;

      &:first-child{
        border-top: none;
      }
    }
    .name{
      width: 150px;
      display: inline-block;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #1F4743;
    }
    .value{
      display: inline-block;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: #1F4743;
    }
  }
}
.page-content{
  padding:0px;
  margin-top: 60px;
  margin-bottom: 160px;
}



.jobs-section{
  margin-bottom: 160px;
  margin-top: 160px;
  
  .section-title{
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #1F4743;
    margin-bottom: 20px;
  }
  .description{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #4C6C69;
    max-width: 390px;
    ::v-deep p{
      margin-top: 16px;
    }
  }
  .links-list{
    padding: 48px;
    padding-bottom: 33px;

    box-sizing: border-box;
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;

    .title{
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 130%;
      color: #1F4743;
      margin-bottom: 28px;
    }
  }
}

@media (max-width: #{$screen-tablet}) {
  .related-green-section{
    padding-bottom: 60px;
    padding-top: 48px;
  }

  .top-hero{

    .date{
     margin-bottom: 14px;
    }
    h1{
     font-weight: 700;
      font-size: 48px;
      line-height: 120%;
    }
    .item-tags{
      
    }
    .image{
     
    }
  }
}

@media (max-width: #{$screen-mobile}) {
  .related-green-section{
    padding-bottom: 60px;
    padding-top: 48px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .top-hero{
    .date{
     margin-bottom: 12px;
    }
    h1{
     font-weight: 700;
      font-size: 32px;
      line-height: 130%;
    }
    .item-tags{
      
    }
    .image{
      margin-left: -20px;
      margin-right: -20px;
      width: calc(100% + 40px);
    }
  }
}
</style>
