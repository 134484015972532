import { Component, Vue } from 'vue-property-decorator';

import template from './CheckboxField.vue';

@Component({
  mixins: [template],
  props: ['theme', 'size', 'name', 'value', 'children', 'customClass'],
})
export default class CheckboxField extends Vue {

}