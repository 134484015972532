import { Component, Vue } from 'vue-property-decorator';

import CarItem from '../car-item/CarItem';
import template from './CarSlider.vue';

@Component({
  mixins: [template],
  props: ['cars'],
  components: {
    'CarItem': CarItem
  }
})
export default class CarSlider extends Vue {

}