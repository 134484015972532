import { IContent } from "@/components/leasingpro/definitions/episerver/content"

/**
 * Will check the content's content type against the inheritance chain list in
 * components.
 *
 * Used to get the Vue component matching the loaded content's type by
 * `ContentComponentSelector` and `PageComponentSelector`.
 *
 * @param {*} content The content object that has a contentType property, which
 * holds the inheritance chain from the C# models for the content with the last
 * item being the actual implementation.
 * @param {Array} components The list of registered Vue components.
 * @returns The matching content type, or `null`.
 */
export default function getComponentTypeForContent(content: IContent, components: any): string {
    // Here we will try to find a component that matches the content type name.
    const parentContentType = content?.contentType.parent
    // const contentType = content?.contentType.current;
    const contentType = content?.contentType.current;
    if (contentType) {
        if (components[contentType]) {
            return contentType;
        }
        else {
            console.warn("could not find component for content type", contentType, parentContentType);
            return "GenericContent";
        }
    }
    console.warn("content was not valid");
    return null;
}
